import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import FXFDReturnsRateRow from "./FXFDReturnsRateRow"

const useStyles = makeStyles({
    FXFDGroup: {
        borderTop: "solid 2px #969696",
    },
})

export default function FXFDReturnsRates({
    rateBuckets,
    selections,
    isSharing,
    setIsSelected,
    selectRate,
    canViewRateAmounts,
    expired,
    shippedRateQuote,
    shipped,
}) {
    const classes = useStyles()

    return (
        <Grid item container>
            {Object.keys(rateBuckets).map((serviceLevel, serviceLevelIndex) => {
                const { label, title, rates } = rateBuckets[serviceLevel]

                return (
                    <Grid
                        item
                        container
                        className={classes.FXFDGroup}
                        alignItems="center"
                    >
                        <Grid item container xs={4} alignItems="center">
                            <Grid item container direction="column">
                                <Typography variant="subheading">
                                    {title}
                                </Typography>
                                <Typography variant="caption">
                                    {label}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={8}>
                            {rates.map(
                                (rate = { rateQuoteDetail: {} }, rateIndex) => {
                                    const isShippedRate =
                                        shippedRateQuote?._id === rate._id

                                    return (
                                        <FXFDReturnsRateRow
                                            rate={rate}
                                            rateIndex={rateIndex}
                                            canViewRateAmounts={
                                                canViewRateAmounts
                                            }
                                            isSharing={isSharing}
                                            setIsSelected={setIsSelected}
                                            selections={selections}
                                            selectRate={selectRate}
                                            serviceLevelIndex={
                                                serviceLevelIndex
                                            }
                                            expired={expired}
                                            isShippedRate={isShippedRate}
                                            shipped={shipped}
                                        />
                                    )
                                }
                            )}
                        </Grid>
                    </Grid>
                )
            })}
        </Grid>
    )
}
