import React, { Component } from "react"
import PropTypes from "prop-types"
import GlobalSpinner from "./spinner"
import { Button, Checkbox } from "react-bootstrap"
import { connect } from "react-redux"
import ReactMarkdown from "react-markdown"
import { acceptTerms, getTerms } from "../../actions/terms"
import { injectIntl } from "react-intl"

export class TermsAndConditionsPresentation extends Component {
    componentDidMount() {
        this.props.onInit()
    }

    render() {
        const {
            version,
            description,
            onAccept,
            containerClassName,
            onCancel,
            omitButtons,
        } = this.props
        return !description ? (
            <GlobalSpinner />
        ) : (
            <div className="column" style={{ padding: "15px" }}>
                <div className="terms-title">
                    Terms and Conditions v{version}
                </div>
                <div className={containerClassName} style={{ padding: "15px" }}>
                    <ReactMarkdown className="terms-content">
                        {description}
                    </ReactMarkdown>
                </div>
                {!omitButtons && (
                    <div className="row-right">
                        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
                        <Button onClick={onAccept}>Accept</Button>
                    </div>
                )}
            </div>
        )
    }
}

TermsAndConditionsPresentation.propTypes = {
    version: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onInit: PropTypes.func.isRequired,
    onAccept: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    containerClassName: PropTypes.string,
}

TermsAndConditionsPresentation.defaultProps = {
    containerClassName: "terms-container",
    onCancel: undefined,
}

const mapStateToProps = ({ terms }) => terms

const mapDispatchToProps = (dispatch, props) => ({
    onInit: async () => {
        dispatch(getTerms(props?.match?.params?.lang))
    },
})

export const TermsAndConditions = connect(
    mapStateToProps,
    mapDispatchToProps
)(TermsAndConditionsPresentation)

export const TermsAndConditionsCheckboxPresentation = props => {
    const { value, version, onChange, showModal } = props
    const setValue = checked =>
        onChange({ target: { value: checked ? version : "" } })
    return (
        <div className="row">
            <Checkbox
                checked={!!value}
                onChange={e => setValue(e.target.checked)}
            >
                I accept the
                <Button onClick={() => showModal(setValue)} bsStyle="link">
                    Terms and Conditions
                </Button>
                .
            </Checkbox>
        </div>
    )
}

TermsAndConditionsCheckboxPresentation.propTypes = {
    value: PropTypes.string.isRequired,
    version: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
}

const panelMapDispatchToProps = dispatch => ({
    onAccept: () => dispatch(acceptTerms()),
})

export const TermsAndConditionsPanel = injectIntl(
    connect(undefined, panelMapDispatchToProps)(TermsAndConditions)
)
