import React, { Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch } from "react-redux"
import { get } from "lodash"
import { Field, FormSection, formValues, resetSection } from "redux-form"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import FormField from "../form/form-field"
import FormSelect from "../form/form-select"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import FormZipCode from "../form/form-zip-code"
import FormCheckbox from "../form/form-checkbox"
import { FormattedMessage } from "react-intl"
import { supportedCountryCodes } from "../../misc"
import formName from "../util/form-name"

const styles = theme => ({})

const BillingInformation = ({
    classes,
    cities,
    sameAsShippingAddress,
    isThirdParty,
    isShipper,
    country,
    form,
    locationTypes,
}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            resetSection(
                form,
                "location.address.postalCode",
                "location.address.state",
                "location.address.city",
                "location.locationType"
            )
        )
    }, [dispatch, country, form])

    return (
        <Grid container>
            <Grid item container>
                <Typography className={classes.push__up} variant="title">
                    <FormattedMessage
                        id="signUp.billing__title"
                        defaultMessage="Billing Address"
                    />
                </Typography>
            </Grid>
            {isShipper && (
                <Field
                    name="location.sameAsShippingAddress"
                    component={FormCheckbox}
                    label={
                        <FormattedMessage
                            id="addLocation.billing__sameAsShippingAddress"
                            defaultMessage="Same as shipping address"
                        />
                    }
                    id="sameAsShippingAddress"
                />
            )}
            {!sameAsShippingAddress && (
                <FormSection name="location" container={Fragment}>
                    <Grid item container>
                        <Grid item xs={12}>
                            <Field
                                name="billingContact.companyName"
                                component={FormField}
                                label={
                                    <FormattedMessage
                                        id="generalTerms__companyName"
                                        defaultMessage="Company Name"
                                    />
                                }
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.street1"
                                component={FormField}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="generalTerms__address"
                                        defaultMessage="Address"
                                    />
                                }
                                maxLength="30"
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.street2"
                                component={FormField}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="generalTerms__address2"
                                        defaultMessage="Address 2"
                                    />
                                }
                                maxLength="30"
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.country"
                                component={FormSelect}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="generalTerms__country"
                                        defaultMessage="Country"
                                    />
                                }
                                options={supportedCountryCodes}
                                maxLength="30"
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.postalCode"
                                component={FormZipCode}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="generalTerms__zipPostalCode"
                                        defaultMessage="Zip/Postal Code"
                                    />
                                }
                                country={country}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.city"
                                component={FormSelectAutocomplete}
                                label={
                                    <FormattedMessage
                                        id="generalTerms__city"
                                        defaultMessage="City"
                                    />
                                }
                                customValue
                                options={cities.map(c => ({
                                    value: c,
                                    label: c,
                                }))}
                                required
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name="address.state"
                                component={FormField}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="generalTerms__stateProvince"
                                        defaultMessage="State/Province"
                                    />
                                }
                                maxLength="30"
                                disabled
                                required
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            )}
            {isThirdParty && isShipper && (
                <Grid item xs={12}>
                    <Field
                        name="location.fedexBillToAccount"
                        label={
                            <FormattedMessage
                                id="addLocation.billing__fedExThirdPartyAccountNumber"
                                defaultMessage="FedEx Third Party Account #:"
                            />
                        }
                        component={FormField}
                        required
                    />
                </Grid>
            )}

            {isThirdParty && !isShipper && (
                <React.Fragment>
                    <Grid item xs={6}>
                        <Field
                            name="location.fedexBillToAccount"
                            label={
                                <FormattedMessage
                                    id="addLocation.billing__fedExThirdPartyAccountNumber"
                                    defaultMessage="FedEx Third Party Account #:"
                                />
                            }
                            component={FormField}
                            required
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Field
                            name="location.locationType"
                            component={FormSelectAutocomplete}
                            label={
                                <FormattedMessage
                                    id="addLocation.shipping_locationType"
                                    defaultMessage="FedEx Account Type :"
                                />
                            }
                            options={locationTypes}
                            required
                        />
                    </Grid>
                </React.Fragment>
            )}
        </Grid>
    )
}

BillingInformation.propTypes = {
    classes: PropTypes.object.isRequired,
    stateFieldPrefix: PropTypes.string,
    cities: PropTypes.array.isRequired,
    sameAsShippingAddress: PropTypes.bool.isRequired,
}

BillingInformation.defaultProps = {
    stateFieldPrefix: "",
}

const mapStateToProps = (state, { postalCode, country }) => ({
    cities: get(state, `postalCode[${country}-${postalCode}].cities`, []),
})

export default withStyles(styles)(
    formValues({
        postalCode: "location.address.postalCode",
        country: "location.address.country",
        sameAsShippingAddress: "location.sameAsShippingAddress",
    })(connect(mapStateToProps)(formName(BillingInformation)))
)
