import React from "react"
import PropTypes from "prop-types"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import PersonIcon from "@material-ui/icons/Person"
import { highlightTerm } from "../util"

const ContactSearchItem = ({
    address,
    contact,
    name,
    onClick,
    term,
    classes,
}) => {
    const { postalCode, city, state } = address || {}
    const { name: contactName, id } = contact || {}
    return (
        <ListItem button onClick={() => onClick(id)} className="contact-item">
            <ListItemIcon>
                <PersonIcon />
            </ListItemIcon>
            <ListItemText>
                {name && <Typography>{highlightTerm(term, name)}</Typography>}
                {contactName && (
                    <Typography>{highlightTerm(term, contactName)}</Typography>
                )}
                <Typography variant="caption">
                    {highlightTerm(term, `${city}, ${state} ${postalCode}`)}
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

ContactSearchItem.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
}

export default ContactSearchItem
