import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../../form/form-field"
import normalizePhone from "../../../util/normalizePhone"
import FormSwitch from "../../../form/form-switch"
import FormSelectAutocompleteNew from "../../../form/form-select-autocomplete-new"
import { generateTimeList } from "../../../book/pickup/form"
import TerminalInfo from "../../../util/terminal-info"
import PickupWarning from "../../../../components/book/pickup/warning"

export default function PickUpForm({
    country,
    pickupLater,
    terminal,
    isPastPickup,
    carrierCode,
    rateMode,
    isFreightDirectReturns,
}) {
    return (
        <Grid xs={12} item>
            <Grid>
                <Grid item container justify="center">
                    <Typography variant="subheading">
                        <FormattedMessage
                            id="orderDetails.pickup__requirements"
                            defaultMessage="Pickup Requirements"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    <PickupWarning
                        isPastPickup={isPastPickup}
                        pickupLater={pickupLater}
                        carrierCode={carrierCode}
                        rateMode={rateMode}
                        country={country}
                    />

                    <Grid item container>
                        <Field
                            name="pickupLater"
                            label={
                                <FormattedMessage
                                    id="orderDetails.label__bolOnly"
                                    defaultMessage="Schedule a pickup later (BOL Only)"
                                />
                            }
                            component={FormSwitch}
                        />
                    </Grid>
                    {!pickupLater && (
                        <Grid item container>
                            <Grid xs={6} item container>
                                <Field
                                    name="origin.readyTime"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms.pickUpInformation__readyTime"
                                            defaultMessage="Ready Time"
                                        />
                                    }
                                    component={FormSelectAutocompleteNew}
                                    required={!pickupLater}
                                    options={generateTimeList()}
                                    disabled={isFreightDirectReturns}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <Field
                                    name="origin.closeTime"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms.pickUpInformation__closeTime"
                                            defaultMessage="Close Time"
                                        />
                                    }
                                    component={FormSelectAutocompleteNew}
                                    required={!pickupLater}
                                    options={generateTimeList()}
                                    disabled={isFreightDirectReturns}
                                />
                            </Grid>
                            <Grid item container>
                                <Field
                                    name="origin.pickupContact.name"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickukpContactName"
                                            defaultMessage="Pickup Contact Name"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container xs={4}>
                                <Field
                                    name="origin.pickupContact.phone.phone_number"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickupContactPhone"
                                            defaultMessage="Pickup Contact Phone"
                                        />
                                    }
                                    component={FormField}
                                    normalize={normalizePhone(country)}
                                />
                            </Grid>
                            <Grid item container xs={2}>
                                <Field
                                    name="origin.pickupContact.phone.extension"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__extension"
                                            defaultMessage="Extension"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container xs={6}>
                                <Field
                                    name="origin.pickupContact.email.email_address"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__pickupContactEmail"
                                            defaultMessage="Pickup Contact Email"
                                        />
                                    }
                                    component={FormField}
                                />
                            </Grid>
                            <Grid item container>
                                <Field
                                    name="origin.note"
                                    label={
                                        <FormattedMessage
                                            id="orderDetails.pickup__pickupRemarks"
                                            defaultMessage="Pickup Remarks"
                                        />
                                    }
                                    component={FormField}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                    )}
                    {pickupLater && terminal && (
                        <TerminalInfo terminal={terminal} />
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}
