import React from "react"
import { connect } from "react-redux"
import { get } from "lodash"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { itemSearchHash } from "../../../actions/item"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    paper: {
        maxHeight: 500,
        minWidth: 800,
        overflow: "scroll",
        padding: "12px",
    },
    suggestion__item: {
        cursor: "pointer",
        padding: "3px 0px",
        "&:hover": {
            "background-color": theme.palette.secondary.light,
        },
    },
    suggestion__header: {
        padding: "3px 0px",
        outline: "none",
        "border-bottom": "solid 1px black",
    },
    suggestion__line: {
        margin: "0 7px",
    },
})

class QuoteItemSuggestionBoxContainer extends React.Component {
    render = () => {
        const {
            term,
            classes,
            items,
            searchInProgress,
            onItemClick,
        } = this.props

        if (term < 2) return null

        return (
            <Paper className={classes.paper}>
                <Grid container direction="column">
                    <Grid item container direction="row">
                        {searchInProgress && (
                            <Grid item xs={2}>
                                {" "}
                                <CircularProgress color="secondary" />{" "}
                            </Grid>
                        )}
                        {!searchInProgress && !get(items, "length") && (
                            <Grid item xs={6}>
                                <Typography color="secondary">
                                    <FormattedMessage
                                        id="generalTerms__noResults"
                                        defaultMessage="No results found"
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    {items && items.length > 0 && (
                        <Grid item container xs={12}>
                            <Grid
                                item
                                container
                                direction="row"
                                justify="flex-start"
                                className={classes.suggestion__header}
                                tabIndex="-1"
                            >
                                <Grid item container justify="center" xs={4}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__description"
                                            defaultMessage="Description"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={1}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__class"
                                            defaultMessage="Class"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={1}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__weight"
                                            defaultMessage="Weight"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={3}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__nmfc"
                                            defaultMessage="NMFC"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={1}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__unNumber"
                                            defaultMessage="ID Numbers"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={1}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__hazClass"
                                            defaultMessage="Haz Class"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container justify="center" xs={1}>
                                    <Typography>
                                        <FormattedMessage
                                            id="items__pkgGrp"
                                            defaultMessage="Pkg Grp"
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                            {items.map((item, i) => (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    className={classes.suggestion__item}
                                    key={i}
                                    role="button"
                                    tabIndex="-1"
                                    onClick={() => onItemClick(item)}
                                >
                                    <Grid
                                        item
                                        container
                                        justify="flex-start"
                                        xs={4}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.description}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={1}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.freightClass}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={1}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.weight}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={3}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.nmfc1 && `${item.nmfc1}`}
                                            {item.nmfc2 && `-${item.nmfc2}`}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={1}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.unNumber}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={1}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.hazClass}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        xs={1}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item.pkgGrp}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            </Paper>
        )
    }
}

const mapStateToProps = (state, { term, origin, originAddress }) => ({
    items: state.item.search[itemSearchHash(term, origin, originAddress)],
    searchInProgress: state.item.searchInProgress,
})

export const SuggestionBox = withStyles(styles)(
    connect(mapStateToProps)(QuoteItemSuggestionBoxContainer)
)
