import React from "react"
import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import QuoteIcon from "@material-ui/icons/Schedule"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import LocationFragment from "./locationFragment"
import { highlightTerm } from "../util"
import { FormattedMessage } from "react-intl"

const SearchQuoteItem = props => {
    const { term, search, identifiers, onClick } = props
    const { internalTrackingNumber: shipmentId, bolNumber, referenceNumber } =
        identifiers || {}

    return (
        <ListItem
            button
            onClick={() => onClick(shipmentId)}
            className="quote-item"
        >
            <ListItemIcon>
                <QuoteIcon />
            </ListItemIcon>
            <ListItemText>
                <Grid container direction="row">
                    <LocationFragment
                        data={search}
                        location="origin"
                        term={term}
                    />
                    <ArrowForwardIcon />
                    &nbsp;
                    <LocationFragment
                        data={search}
                        location="destination"
                        term={term}
                    />
                </Grid>
                <Typography variant="caption">
                    <FormattedMessage
                        id="generalTerms__BOL"
                        defaultMessage="BOL"
                    />
                    #:
                    {highlightTerm(term, bolNumber)}
                </Typography>
                {referenceNumber && (
                    <Typography variant="caption">
                        <FormattedMessage
                            id="generalTerms__referenceId"
                            defaultMessage="Reference ID"
                        />
                        #:
                        {highlightTerm(term, referenceNumber)}
                    </Typography>
                )}
            </ListItemText>
        </ListItem>
    )
}

export default SearchQuoteItem
