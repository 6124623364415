export const weightUnitPluralCapital = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "KGS" : "LBS"

export const weightUnitSingularCapital = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "KG" : "LB"

export const weightUnit = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "kg" : "lbs"

export const lengthUnit = preferredSystemOfMeasurement =>
    preferredSystemOfMeasurement === "METRIC" ? "cm" : "in"
