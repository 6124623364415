import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import FontAwesome from "react-fontawesome"
import moment from "moment"
import {
    Button,
    Overlay,
    OverlayTrigger,
    Tooltip,
    Popover,
} from "react-bootstrap"
import { sortRateCharges, getCarrierServiceDisplayName } from "../../../misc"
import { calcDeliveryTime } from "../../util"
import { currencyLabel } from "../../util/currencyLabel"

export const QuoteDeliveryDate = ({ date, className }) => (
    <div className={className}>{date.format("dddd MMM-Do")}</div>
)

QuoteDeliveryDate.propTypes = {
    date: PropTypes.object.isRequired,
    className: PropTypes.string,
}

QuoteDeliveryDate.defaultProps = {
    className: "",
}

export const QuoteDeliveryTimePresentation = ({
    date,
    className,
    guaranteed,
}) => (
    <div className={`quote-card-delivery-container ${className}`}>
        <div>
            {date.format("H") === "23" ? (
                <div className="quote-card-delivery-cob">
                    Close of
                    <br />
                    Business
                </div>
            ) : (
                <div className="quote-card-delivery-time">
                    {date.format("LT")}
                </div>
            )}
            {guaranteed && (
                <span className="quote-card-delivery-guaranteed">
                    {" "}
                    Guaranteed{" "}
                </span>
            )}
        </div>
    </div>
)

QuoteDeliveryTimePresentation.propTypes = {
    date: PropTypes.object.isRequired,
    guaranteed: PropTypes.bool.isRequired,
    className: PropTypes.string,
}

QuoteDeliveryTimePresentation.defaultProps = {
    className: "",
}

export const isGuaranteedQuote = (serviceCode, guaranteedList) =>
    guaranteedList.find(item => item.value === serviceCode)

const quoteMapStateToProps = (state, props) => ({
    guaranteed: !!isGuaranteedQuote(
        props.serviceCode,
        state.accessorials.list.guaranteed
    ),
})

export const QuoteDeliveryTime = connect(quoteMapStateToProps)(
    QuoteDeliveryTimePresentation
)

QuoteDeliveryTime.propTypes = {
    serviceCode: PropTypes.string,
}

QuoteDeliveryTime.defaultProps = {
    serviceCode: null,
}

const toFixed = num => Number(num).toFixed(2)

export const QuotePricePresentation = props => {
    const {
        markupPrice,
        price,
        originalText,
        displayMarkup,
        className,
        itemClassName,
        originalClassName,
        preferredCurrencyCode,
    } = props
    const shouldDisplayBoth = markupPrice
    if (!shouldDisplayBoth || !displayMarkup) {
        return (
            <div className={itemClassName}>
                {currencyLabel(preferredCurrencyCode, price)}
            </div>
        )
    }
    return (
        <div className={className}>
            <div className={itemClassName}>
                {currencyLabel(
                    preferredCurrencyCode,
                    markupPrice || price || 0
                )}
            </div>
            {shouldDisplayBoth && (
                <div className={`${originalClassName} ${itemClassName}`}>
                    {originalText}
                    {currencyLabel(preferredCurrencyCode, toFixed(price))}
                </div>
            )}
        </div>
    )
}

QuotePricePresentation.propTypes = {
    markupPrice: PropTypes.number,
    price: PropTypes.number.isRequired,
    originalText: PropTypes.string,
    displayMarkup: PropTypes.bool,
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    originalClassName: PropTypes.string,
}

QuotePricePresentation.defaultProps = {
    markupPrice: null,
    originalText: "Originally: ",
    displayMarkup: true,
    className: "",
    itemClassName: "",
    originalClassName: "",
}

export const calculateMarkupPrice = (price, markup) => {
    if (!markup || !markup.markupAmount) return price
    if (!markup.markupType) {
        return Number(price) + Number(markup.markupAmount)
    }
    return Number(price) * (1 + Number(markup.markupAmount) / 100)
}

const markupMapStateToProps = (state, props) => ({
    markupPrice: calculateMarkupPrice(props.price, state.markup),
})

export const QuotePrice = connect(markupMapStateToProps)(QuotePricePresentation)

export const QuoteInfoPopover = ({
    quoteInfo,
    onClickClose,
    onClickBook,
    viewMode,
}) => {
    const { rateQuoteDetail, capacityProviderQuoteNumber: quoteId } = quoteInfo
    const { charges, total } = rateQuoteDetail
    const orderedDetails = [...(charges || [])].sort(sortRateCharges)

    return (
        <div className="quote-breakdown-popover">
            {orderedDetails.map((detail, index) => (
                <div key={index} className="row">
                    <span className="colummn">
                        {`$ ${detail.amount < 0 ? "-" : "\u00a0"}`}
                    </span>
                    <span className="column quote-breakdown-amount-column">
                        {Math.abs(detail.amount || 0).toFixed(2)}
                    </span>
                    <span className="column flex-one quote-breakdown-description-column">
                        {detail.description.replace("_", " ")}
                    </span>
                </div>
            ))}
            <div className="line-separator thin" />
            <div className="row">
                <span className="colummn">
                    {`$ ${total < 0 ? "-" : "\u00a0"}`}
                </span>
                <span className="column quote-breakdown-amount-column">
                    <strong>{`${total.toFixed(2)}`}</strong>
                </span>
                <span className="column quote-breakdown-description-column">
                    <strong>Total Charges</strong>
                </span>
            </div>
            {quoteId && (
                <div>
                    <p>Quote ID: {quoteId}</p>
                </div>
            )}
            {onClickClose && (
                <div className="row-stretch">
                    <Button onClick={onClickClose}>Close</Button>
                    {!viewMode && (
                        <Button className="btn-indigo" onClick={onClickBook}>
                            Book Shipment
                        </Button>
                    )}
                </div>
            )}
        </div>
    )
}

QuoteInfoPopover.propTypes = {
    onClickBook: PropTypes.func,
    quoteInfo: PropTypes.object.isRequired,
    viewMode: PropTypes.bool,
    onClickClose: PropTypes.func,
}

QuoteInfoPopover.defaultProps = {
    onClickBook: undefined,
    onClickClose: undefined,
    viewMode: false,
}

export class QuoteInfoBreakdownContainer extends React.Component {
    render() {
        const { rateQuoteDetail, deliveryDateTime } = this.props.quoteInfo
        const deliveryDate = moment(deliveryDateTime)
        const popoverTitle = (
            <div>
                <div className="row-stretch">
                    <span>{this.props.carrierName}</span>
                    <FontAwesome
                        className="quote-breakdown-close-icon"
                        name="times"
                        onClick={this.props.onClickInfo}
                    />
                </div>
                <span> Delivery On: {deliveryDate.format("dddd MMM-Do")} </span>
                <br />
                <span>
                    {" "}
                    {this.props.guaranteed ? "Guaranteed by" : "By"}{" "}
                    {calcDeliveryTime(deliveryDate)}
                </span>
            </div>
        )
        if (!rateQuoteDetail || !rateQuoteDetail.charges) return null
        return (
            <div>
                <OverlayTrigger
                    delayShow={2000}
                    trigger={["hover", "focus"]}
                    overlay={<Tooltip>{"Rate details"}</Tooltip>}
                >
                    <FontAwesome
                        name="info-circle"
                        onClick={this.props.onClickInfo}
                    />
                </OverlayTrigger>
                <Overlay
                    show={this.props.isOpen}
                    placement="right"
                    target={this}
                >
                    <Popover
                        title={popoverTitle}
                        className="quote-breakdown-popover"
                    >
                        <QuoteInfoPopover
                            quoteInfo={this.props.quoteInfo}
                            carrierCode={this.props.carrierCode}
                            onClickClose={this.props.onClickInfo}
                            onClickBook={this.props.onClickBookShipment}
                            viewMode={this.props.viewMode}
                        />
                    </Popover>
                </Overlay>
            </div>
        )
    }
}

QuoteInfoBreakdownContainer.propTypes = {
    onClickBookShipment: PropTypes.func.isRequired,
    quoteInfo: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClickInfo: PropTypes.func.isRequired,
    carrierCode: PropTypes.string.isRequired,
    viewMode: PropTypes.bool.isRequired,
    guaranteed: PropTypes.bool.isRequired,
    carrierName: PropTypes.string.isRequired,
}

const quoteBreakDownMapStateToProps = (state, props) => ({
    guaranteed: !!isGuaranteedQuote(
        props.quoteInfo.serviceLevel.code,
        state.accessorials.list.guaranteed
    ),
    carrierName: getCarrierServiceDisplayName(
        props.carrierCode,
        props.quoteInfo.serviceLevel,
        props.quoteInfo.mode,
        state.carriers.carriers
    ),
})

export const QuoteInfoBreakdown = connect(quoteBreakDownMapStateToProps)(
    QuoteInfoBreakdownContainer
)
