import React, { useState, Fragment } from "react"
import { UploadSuccessDialog } from "./UploadSuccessDialog"
import { UploadErrorDialog } from "./UploadErrorDialog"
import { _arrayBufferToBase64 } from "../../track/result/attachments/attachmentsCard"
import { GenerateDocumentDialog } from "./GenerateDocumentDialog"
import { ChooseDocumentMethodDialog } from "./ChooseDocumentMethodDialog"
import { attachmentOptionsMessages } from "../../../misc"
import { FilenameCollisionCheckStep } from "../checks/FilenameCollisionCheckStep"
import { usmcaFormGenerateInitialValues } from "../USMCA/selectors"
import {
    documentFieldValidation,
    usmcaFormValidation,
} from "../USMCA/validation"
import { combineValidators } from "revalidate"
import { mergeValidations } from "../../../actions/validation"
import { reduxForm } from "redux-form"
import { connect } from "react-redux"
import { UploadProcessingDialog } from "./UploadProcessingDialog"

export const attachmentOptionList = [
    {
        value: "USMCA",
        label: attachmentOptionsMessages["USMCA"],
    },
    {
        value: "certificateOfOrigin",
        label: attachmentOptionsMessages["certificateOfOrigin"],
    },
]

let AddDocumentWizard = ({
    initialStep = "chooseMethod",
    onGenerate,
    onUpload,
    locationId,
    shipmentId,
    onClose,
    handleSubmit,
    enableProfileOverwrite = true,
    enableShipmentOverwrite = true,
    customShipmentList,
    profileDocs = false,
}) => {
    const [previousStep, setPreviousStep] = useState(null)
    const [step, setStep] = useState(initialStep)
    const [stagedFile, setStagedFile] = useState({})

    const changeStep = newStep => {
        setPreviousStep(step)
        setStep(newStep)
    }

    const backToPreviousStep = () => {
        setStep(previousStep)
        setPreviousStep(null)
    }

    const onStageFile = file => {
        if (file) {
            const reader = new FileReader()
            reader.onabort = () => console.log("file reading was aborted")
            reader.onerror = () => console.log("file reading has failed")
            reader.onload = () => {
                const result = reader.result
                const stagedFile = {
                    content: _arrayBufferToBase64(result),
                    mimeType: file.type,
                    originalFileName: file.name,
                }
                setStagedFile(stagedFile)
            }
            reader.readAsArrayBuffer(file)
            changeStep("uploadStaged")
        } else {
            changeStep("uploadError")
        }
    }

    const submitFile = async values => {
        changeStep("processing")
        onUpload(values, stagedFile)
    }

    const generateFile = async values => {
        changeStep("processing")
        onGenerate(values)
    }

    return (
        <Fragment>
            {step === "chooseMethod" && (
                <form
                    onSubmit={handleSubmit(() =>
                        changeStep("generateDocument")
                    )}
                >
                    <ChooseDocumentMethodDialog
                        onStage={onStageFile}
                        profileDocs={profileDocs}
                    />
                </form>
            )}
            {step === "uploadStaged" && (
                <form
                    onSubmit={handleSubmit(() =>
                        changeStep("filenameCollisionCheckUpload")
                    )}
                >
                    <UploadSuccessDialog
                        onClose={onClose}
                        stagedFile={stagedFile}
                        attachmentOptionList={attachmentOptionList}
                    />
                </form>
            )}
            {step === "uploadError" && <UploadErrorDialog onClose={onClose} />}
            {step === "generateDocument" && (
                <form
                    onSubmit={handleSubmit(() =>
                        changeStep("filenameCollisionCheckGenerate")
                    )}
                >
                    <GenerateDocumentDialog
                        onClose={onClose}
                        locationId={locationId}
                        shipmentId={shipmentId}
                    />
                </form>
            )}
            {step === "filenameCollisionCheckUpload" && (
                <form onSubmit={handleSubmit(submitFile)}>
                    <FilenameCollisionCheckStep
                        onBack={backToPreviousStep}
                        onNoCollision={submitFile}
                        shipmentId={shipmentId}
                        enableProfileOverwrite={enableProfileOverwrite}
                        enableShipmentOverwrite={enableShipmentOverwrite}
                        customShipmentList={customShipmentList}
                    />
                </form>
            )}
            {step === "filenameCollisionCheckGenerate" && (
                <form onSubmit={handleSubmit(generateFile)}>
                    <FilenameCollisionCheckStep
                        onBack={backToPreviousStep}
                        onNoCollision={generateFile}
                        shipmentId={shipmentId}
                        enableProfileOverwrite={enableProfileOverwrite}
                        enableShipmentOverwrite={enableShipmentOverwrite}
                        customShipmentList={customShipmentList}
                    />
                </form>
            )}
            {step === "processing" && <UploadProcessingDialog />}
        </Fragment>
    )
}

const mapStateToProps = (state, props) => {
    return {
        initialValues: {
            documentCategory: "USMCA",
            usmca: usmcaFormGenerateInitialValues(state, props),
        },
    }
}

const fullFormValidation = mergeValidations(
    documentFieldValidation,
    combineValidators({
        usmca: usmcaFormValidation,
    })
)

AddDocumentWizard = connect(mapStateToProps)(
    reduxForm({
        form: "newCustomsDocument",
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
        validate: fullFormValidation,
    })(AddDocumentWizard)
)

export { AddDocumentWizard }
