import React, { Fragment, Component } from "react"
import { get } from "lodash"
import { Field, FieldArray } from "redux-form"
import Grid from "@material-ui/core/Grid"
import Popper from "@material-ui/core/Popper"
import CloseIcon from "@material-ui/icons/Close"
import FormField from "../../form/form-field"
import FormSelectAutocompleteDark from "../../form/form-select-autocomplete-dark"
import {
    freightClasses,
    hazMatPkgGrp,
    provideHazMatClasses,
    provideHazMatContainers,
} from "../../item/line-item"
import FormSwitch from "../../form/form-switch"
import { mapItemToHUItem } from "../../../actions/quote"
import { SuggestionBox } from "./suggestionBox"
import FreightDirectPiece from "./freightDirectPiece"
import { FormattedMessage, injectIntl } from "react-intl"
import { weightUnit } from "../../util/units"

class QuoteItem extends Component {
    state = {
        showItemOverlayUN: false,
        showItemOverlayDescription: false,
        anchorEl: null,
    }

    mapItemToFields = item => mapItemToHUItem(item)

    onItemClick = item => {
        const {
            isMultiClass,
            prefix,
            changeField,
            resetSection,
            huIndex,
            packageType,
            length,
            width,
            height,
        } = this.props

        const huPrefix = `handlingUnits[${huIndex}]`
        const huUntouched =
            packageType === "" && length === "" && width === "" && height === ""

        this.setState(() => ({
            showItemOverlayDescription: false,
            showItemOverlayUN: false,
            anchorEl: null,
        }))

        resetSection("quote", prefix)

        const huItem = this.mapItemToFields(item, isMultiClass)

        Object.keys(huItem).forEach(key => {
            changeField("quote", `${prefix}[${key}]`, huItem[key])
        })

        if (!isMultiClass || (isMultiClass && huUntouched)) {
            changeField("quote", `${huPrefix}.packageType`, item.packageType)
            changeField("quote", `${huPrefix}.length`, item.length)
            changeField("quote", `${huPrefix}.width`, item.width)
            changeField("quote", `${huPrefix}.height`, item.height)
        }
    }

    componentDidMount = () => {
        this.clickedInside = false
        this.clickTimeout = null
        this.closeTimeout = null
    }

    componentWillUnmount = () => {
        clearTimeout(this.clickTimeout)
        clearTimeout(this.closeTimeout)
    }

    handleContainerMouseDown() {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleContainerMouseUp() {
        this.closeTimeout = setTimeout(() => {
            this.setState({
                showItemOverlayUN: false,
                showItemOverlayDescription: false,
                anchorEl: null,
            })
        })
    }

    handleInputFocus(e, fieldName) {
        if (fieldName === "UN") {
            this.setState({
                showItemOverlayUN: true,
                anchorEl: e.currentTarget,
            })
        } else if (fieldName === "Description") {
            this.setState({
                showItemOverlayDescription: true,
                anchorEl: e.currentTarget,
            })
        }
    }

    handleInputBlur(e, fieldName) {
        const showOverlay = this.clickedInside

        // Force input's focus if blur event was caused by clicking on dropdown
        if (!showOverlay) {
            if (fieldName === "UN") {
                this.setState({
                    showItemOverlayUN: false,
                    anchorEl: null,
                })
            } else if (fieldName === "Description") {
                this.setState({
                    showItemOverlayDescription: false,
                    anchorEl: null,
                })
            }
        }
    }

    render() {
        const {
            lastItem,
            itemIndex,
            item,
            classes,
            inputHelperProps,
            inputSelectAutocompleteProps,
            inputLabelProps,
            selectClasses,
            inputProps,
            multilineProps,
            formLabelProps,
            doSearch,
            origin,
            originAddress,
            prefix,
            preferredSystemOfMeasurement,
            onRemoveItemClick,
            isMultiClass,
            isFreightDirect,
            isFreightDirectReturns,
            intraMexicoShipment,
            intl,
        } = this.props
        const {
            anchorEl,
            showItemOverlayUN,
            showItemOverlayDescription,
        } = this.state
        const piecesLabel = isMultiClass ? (
            <FormattedMessage
                id="getRates.items__piecesPerHU"
                defaultMessage="Pieces per H/U"
            />
        ) : (
            <FormattedMessage
                id="getRates.items__totalPieces"
                defaultMessage="Total Pieces"
            />
        )

        return (
            <Grid
                item
                container
                direction="row"
                className={
                    lastItem
                        ? classes.lastIndividualItem
                        : classes.individualItem
                }
            >
                <Grid item container xs={6} alignItems="center">
                    <Field
                        component={FormSwitch}
                        name={`${prefix}.isHazMat`}
                        label={
                            <FormattedMessage
                                id="items__hazMat"
                                defaultMessage="HazMat"
                            />
                        }
                        dataTestId="isHazMat"
                        FormLabelProps={formLabelProps}
                    />
                </Grid>
                <Grid item xs={3} />
                <Grid
                    item
                    container
                    xs={3}
                    justify="flex-end"
                    alignItems="center"
                >
                    {isMultiClass && (
                        <CloseIcon
                            className={classes.item__icon}
                            onClick={() => onRemoveItemClick(itemIndex)}
                            dataTestId="removeItem"
                        />
                    )}
                </Grid>
                {item.isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormField}
                            name={`${prefix}.hazMatUnNumber`}
                            label={
                                <FormattedMessage
                                    id="items__unNumber"
                                    defaultMessage="ID Numbers"
                                />
                            }
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            onFocus={e => this.handleInputFocus(e, "UN")}
                            onBlur={e => this.handleInputBlur(e, "UN")}
                            onChange={e => doSearch(e)}
                            inputProps={{ maxLength: 6 }}
                        />
                        <Popper
                            onMouseDown={() => this.handleContainerMouseDown()}
                            onMouseUp={() => this.handleContainerMouseUp()}
                            open={showItemOverlayUN}
                            anchorEl={anchorEl}
                            placement="bottom-end"
                            disablePortal
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                },
                            }}
                            className={classes.popper}
                        >
                            {get(this.props, "item.hazMatUnNumber.length") >
                                2 && (
                                <SuggestionBox
                                    term={item.hazMatUnNumber}
                                    origin={origin}
                                    originAddress={originAddress}
                                    onItemClick={this.onItemClick}
                                />
                            )}
                        </Popper>
                    </Grid>
                )}
                {item.isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormSelectAutocompleteDark}
                            name={`${prefix}.hazMatPkgGrp`}
                            label={
                                <FormattedMessage
                                    id="items__pkgGroup"
                                    defaultMessage="Pkg Group"
                                />
                            }
                            options={hazMatPkgGrp}
                            InputProps={inputSelectAutocompleteProps}
                            InputLabelProps={inputLabelProps}
                            selectClasses={selectClasses}
                        />
                    </Grid>
                )}
                {item.isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormSelectAutocompleteDark}
                            name={`${prefix}.hazMatContainer`}
                            label={
                                <FormattedMessage
                                    id="items__containerType"
                                    defaultMessage="Container Type"
                                />
                            }
                            options={provideHazMatContainers(intl)}
                            InputProps={inputSelectAutocompleteProps}
                            InputLabelProps={inputLabelProps}
                            selectClasses={selectClasses}
                        />
                    </Grid>
                )}
                {item.isHazMat && (
                    <Grid item container xs={12}>
                        <Field
                            component={FormSelectAutocompleteDark}
                            name={`${prefix}.hazMatClass`}
                            label={
                                <FormattedMessage
                                    id="items__hazClass"
                                    defaultMessage="Haz Class"
                                />
                            }
                            options={provideHazMatClasses(intl)}
                            InputProps={inputSelectAutocompleteProps}
                            InputLabelProps={inputLabelProps}
                            selectClasses={selectClasses}
                        />
                    </Grid>
                )}

                <Grid item container xs={12}>
                    <Field
                        component={FormField}
                        name={`${prefix}.description`}
                        label={[
                            "* ",
                            <FormattedMessage
                                id="items__description"
                                defaultMessage="Description"
                            />,
                        ]}
                        InputProps={multilineProps}
                        InputLabelProps={inputLabelProps}
                        InputHelperProps={inputHelperProps}
                        onFocus={e => this.handleInputFocus(e, "Description")}
                        onBlur={e => this.handleInputBlur(e, "Description")}
                        onChange={e => doSearch(e)}
                    />
                    <Popper
                        onMouseDown={() => this.handleContainerMouseDown()}
                        onMouseUp={() => this.handleContainerMouseUp()}
                        open={showItemOverlayDescription}
                        anchorEl={anchorEl}
                        placement="bottom-end"
                        disablePortal
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent",
                            },
                        }}
                        className={classes.popper}
                    >
                        {get(this.props, "item.description", "").length > 2 && (
                            <SuggestionBox
                                term={item.description}
                                origin={origin}
                                originAddress={originAddress}
                                onItemClick={this.onItemClick}
                            />
                        )}
                    </Popper>
                </Grid>

                <Grid item container xs={4}>
                    <Field
                        component={FormSelectAutocompleteDark}
                        name={`${prefix}.freightClass`}
                        label={[
                            <FormattedMessage
                                id="items__class"
                                defaultMessage="Class"
                            />,
                        ]}
                        dataTestId="itemClass"
                        options={freightClasses}
                        InputProps={inputSelectAutocompleteProps}
                        InputLabelProps={inputLabelProps}
                        InputHelperProps={inputHelperProps}
                        selectClasses={selectClasses}
                        required={!intraMexicoShipment}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        component={FormField}
                        name={`${prefix}.nmfcCode`}
                        placeholder={"xxxxxx"}
                        label={
                            <FormattedMessage
                                id="items__nmfc"
                                defaultMessage="NMFC"
                            />
                        }
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        component={FormField}
                        label={
                            <FormattedMessage
                                id="items__subclass"
                                defaultMessage="Subclass"
                            />
                        }
                        name={`${prefix}.nmfcSubclass`}
                        placeholder={"xx"}
                        InputProps={inputProps}
                        InputLabelProps={inputLabelProps}
                    />
                </Grid>
                {!isFreightDirect && !isFreightDirectReturns ? (
                    <Grid item xs={4}>
                        <Field
                            component={FormField}
                            name={`${prefix}.pieces`}
                            label={piecesLabel}
                            value="1"
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                        />
                    </Grid>
                ) : (
                    <Fragment>
                        <FieldArray
                            name={`${prefix}.freightDirectPieces`}
                            component={FreightDirectPiece}
                            prefix={prefix}
                            classes={classes}
                            inputLabelProps={inputLabelProps}
                            inputProps={inputProps}
                            weightUnit={weightUnit(
                                preferredSystemOfMeasurement
                            )}
                        />
                    </Fragment>
                )}
                <Grid item container xs={8}>
                    {isMultiClass && (
                        <Field
                            component={FormField}
                            name={`${prefix}.weight`}
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="items__itemWeightPerHU"
                                    defaultMessage="Item Weight per H/U ({weightUnit})"
                                    values={{
                                        weightUnit: weightUnit(
                                            preferredSystemOfMeasurement
                                        ),
                                    }}
                                />,
                            ]}
                            InputProps={inputProps}
                            InputLabelProps={inputLabelProps}
                            InputHelperProps={inputHelperProps}
                        />
                    )}
                </Grid>
            </Grid>
        )
    }
}

export default injectIntl(QuoteItem)
