import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import { FormattedMessage } from "react-intl"
import {
    formattedItemName,
    formattedPurposeOfShipment,
    formattedTaxIdentificationType,
} from "./SupportDocumentationForm"
import { Field, formValues } from "redux-form"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import FormSelectCity from "../form/form-select-city"
import FormField from "../form/form-field"
import FormSwitch from "../form/form-switch"
import FormSelect from "../form/form-select"
import { supportedCountryCodes } from "../../misc"
import { Typography, withStyles } from "@material-ui/core"
import FormZipCode from "../form/form-zip-code"
import ManageSignature from "../documents/ManageSignature"

const styles = theme => ({
    card: {
        height: "100%",
        overflow: "visible",
    },
    content: {
        "margin-left": "60px",
    },
    fields__switches: {
        marginTop: "12px",
    },
    fields__switches_below: {
        marginTop: "6px",
    },
    signature_button: {
        marginTop: "12px",
    },
})

export const formattedDutiesPayableType = [
    {
        value: "EXPORTER",
        label: (
            <FormattedMessage
                id="orderDetails.documents__EXPORTER"
                defaultMessage="EXPORTER"
            />
        ),
    },
    {
        value: "CONSIGNEE",
        label: (
            <FormattedMessage
                id="orderDetails.documents__CONSIGNEE"
                defaultMessage="CONSIGNEE"
            />
        ),
    },
    {
        value: "OTHER",
        label: (
            <FormattedMessage
                id="orderDetails.documents__OTHER"
                defaultMessage="OTHER"
            />
        ),
    },
]

export const formattedTermsOfSale = [
    {
        value: "DAP",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DAP"
                defaultMessage="DAP"
            />
        ),
    },
    {
        value: "DAT",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DAT"
                defaultMessage="DAT"
            />
        ),
    },
    {
        value: "EXW",
        label: (
            <FormattedMessage
                id="orderDetails.documents__EXW"
                defaultMessage="EXW"
            />
        ),
    },
    {
        value: "DDU",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DDU"
                defaultMessage="DDU"
            />
        ),
    },
    {
        value: "DDP",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DDP"
                defaultMessage="DDP"
            />
        ),
    },
]

export const formattedNaftaStatusType = [
    {
        value: "PRODUCER",
        label: (
            <FormattedMessage
                id="orderDetails.documents__PRODUCER"
                defaultMessage="PRODUCER"
            />
        ),
    },
    {
        value: "EXPORTER",
        label: (
            <FormattedMessage
                id="orderDetails.documents__EXPORTER"
                defaultMessage="EXPORTER"
            />
        ),
    },
]

export const formattedUnitOfMeasuresType = [
    {
        value: "AR",
        label: (
            <FormattedMessage
                id="orderDetails.documents__CARAT"
                defaultMessage="CARAT"
            />
        ),
    },
    {
        value: "CM",
        label: (
            <FormattedMessage
                id="orderDetails.documents__CENTIMETER"
                defaultMessage="CENTIMETER"
            />
        ),
    },
    {
        value: "CFT",
        label: (
            <FormattedMessage
                id="orderDetails.documents__CUBIC__FOOT"
                defaultMessage="CUBIC FOOT"
            />
        ),
    },
    {
        value: "DOZ",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DOZEN"
                defaultMessage="DOZEN"
            />
        ),
    },
    {
        value: "DPR",
        label: (
            <FormattedMessage
                id="orderDetails.documents__DOZEN__PAIR"
                defaultMessage="DOZEN PAIR"
            />
        ),
    },
    {
        value: "EA",
        label: (
            <FormattedMessage
                id="orderDetails.documents__EACH"
                defaultMessage="EACH"
            />
        ),
    },
    {
        value: "LFT",
        label: (
            <FormattedMessage
                id="orderDetails.documents__FOOT"
                defaultMessage="FOOT"
            />
        ),
    },
    {
        value: "G",
        label: (
            <FormattedMessage
                id="orderDetails.documents__GROSS"
                defaultMessage="GROSS"
            />
        ),
    },
    {
        value: "GR",
        label: (
            <FormattedMessage
                id="orderDetails.documents__GRAM"
                defaultMessage="GRAM"
            />
        ),
    },
    {
        value: "KG",
        label: (
            <FormattedMessage
                id="orderDetails.documents__KILOGRAM"
                defaultMessage="KILOGRAM"
            />
        ),
    },
    {
        value: "LTR",
        label: (
            <FormattedMessage
                id="orderDetails.documents__LITER"
                defaultMessage="LITER"
            />
        ),
    },
    {
        value: "LB",
        label: (
            <FormattedMessage
                id="orderDetails.documents__POUND"
                defaultMessage="POUND"
            />
        ),
    },
    {
        value: "LNM",
        label: (
            <FormattedMessage
                id="orderDetails.documents__LINEAR__METER"
                defaultMessage="LINEAR METER"
            />
        ),
    },
    {
        value: "M",
        label: (
            <FormattedMessage
                id="orderDetails.documents__METER"
                defaultMessage="METER"
            />
        ),
    },
    {
        value: "M2",
        label: (
            <FormattedMessage
                id="orderDetails.documents__M2"
                defaultMessage="SQUARE METER(M2)"
            />
        ),
    },
    {
        value: "M3",
        label: (
            <FormattedMessage
                id="orderDetails.documents__CUBIC_METER"
                defaultMessage="CUBIC METER"
            />
        ),
    },
    {
        value: "MG",
        label: (
            <FormattedMessage
                id="orderDetails.documents__MILLIGRAM"
                defaultMessage="MILLIGRAM"
            />
        ),
    },
    {
        value: "ML",
        label: (
            <FormattedMessage
                id="orderDetails.documents__MILLILITER"
                defaultMessage="MILLILITER"
            />
        ),
    },
    {
        value: "NO",
        label: (
            <FormattedMessage
                id="orderDetails.documents__NUMBER"
                defaultMessage="NUMBER"
            />
        ),
    },
    {
        value: "OZ",
        label: (
            <FormattedMessage
                id="orderDetails.documents__OUNCE"
                defaultMessage="OUNCE"
            />
        ),
    },
    {
        value: "PR",
        label: (
            <FormattedMessage
                id="orderDetails.documents__PAIR"
                defaultMessage="PAIR"
            />
        ),
    },
    {
        value: "PCS",
        label: (
            <FormattedMessage
                id="orderDetails.documents__PIECE"
                defaultMessage="PIECE"
            />
        ),
    },
    {
        value: "SFT",
        label: (
            <FormattedMessage
                id="orderDetails.documents__SQUARE FOOT"
                defaultMessage="SQUARE FOOT"
            />
        ),
    },
    {
        value: "SYD",
        label: (
            <FormattedMessage
                id="orderDetails.documents__SQUARE__YARD"
                defaultMessage="SQUARE YARD"
            />
        ),
    },
    {
        value: "YD",
        label: (
            <FormattedMessage
                id="orderDetails.documents__YARD"
                defaultMessage="YARD"
            />
        ),
    },
]

const CommercialInvoiceForm = formValues({
    importerCountry: "commercialInvoice.importerOfRecord.address.country",
    importerPostalCode:
        "commercialInvoice.importerOfRecord.address.postal_code",
    importerIsNotConsignee: "commercialInvoice.importerIsNotConsignee",
    naftaDeclaration: "commercialInvoice.naftaDeclaration",
    originTaxIdentification: "origin.taxIdentification",
    destinationTaxIdentification: "destination.taxIdentification",
    importerTaxIdentification:
        "commercialInvoice.importerOfRecord.taxIdentification",
    handlingUnits: "handlingUnits",
    signature: "signatureImage",
})(
    ({
        classes,
        importerCountry,
        importerPostalCode,
        importerIsNotConsignee,
        naftaDeclaration,
        originTaxIdentification,
        destinationTaxIdentification,
        importerTaxIdentification,
        handlingUnits,
    }) => (
        <Grid xs={12} container item alignContent="center" alignItems="center">
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Typography variant="headline">
                            <FormattedMessage
                                id="orderDetails.commercial__invoice__title"
                                defaultMessage="Commercial Invoice"
                            />
                        </Typography>
                    }
                />
                <CardContent className={classes.content}>
                    <Grid container>
                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"purposeOfShipment"}
                                options={formattedPurposeOfShipment}
                                required={true}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__select_purpose"
                                        defaultMessage="Select Purpose Of Shipment"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name={`itemComments[0]`}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__item__special__instruction"
                                        defaultMessage="Items Special Instructions"
                                    />
                                }
                                component={FormField}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name={`itemComments[1]`}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__item__special__instruction2"
                                        defaultMessage="Items Special Instructions 2"
                                    />
                                }
                                component={FormField}
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"origin.taxIdentification.type"}
                                options={formattedTaxIdentificationType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__exporter__select__tax__type"
                                        defaultMessage="Select  Exporter Tax Identification Type"
                                    />
                                }
                                required={originTaxIdentification?.number}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="origin.taxIdentification.number"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__exporter__tax__identification__number"
                                        defaultMessage=" Exporter Tax Identification Number"
                                    />
                                }
                                required={originTaxIdentification?.type}
                                component={FormField}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                component={FormSwitch}
                                name="commercialInvoice.partiesRelated"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__parties"
                                        defaultMessage="Parties to Transaction Related"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.invoiceNumber"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__invoice__number"
                                        defaultMessage="Invoice #"
                                    />
                                }
                                component={FormField}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.paymentTerms"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__payment__terms"
                                        defaultMessage="Payment Terms"
                                    />
                                }
                                component={FormField}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                component={FormSwitch}
                                name="commercialInvoice.importerIsNotConsignee"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__importer"
                                        defaultMessage="Importer Is Not Consignee"
                                    />
                                }
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"destination.taxIdentification.type"}
                                options={formattedTaxIdentificationType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__consignee__select__tax__type"
                                        defaultMessage="Select  Consignee  Tax Identification Type"
                                    />
                                }
                                required={destinationTaxIdentification?.number}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="destination.taxIdentification.number"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__consignee__tax__identification__number"
                                        defaultMessage=" Consignee Tax Identification Number"
                                    />
                                }
                                required={destinationTaxIdentification?.type}
                                component={FormField}
                            />
                        </Grid>
                        {importerIsNotConsignee && (
                            <Fragment>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.name"
                                        required={true}
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__companyName"
                                                defaultMessage=" Importer Company Name"
                                            />
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        component={FormSelectAutocomplete}
                                        name={
                                            "commercialInvoice.importerOfRecord.taxIdentification.type"
                                        }
                                        required={
                                            importerTaxIdentification?.number
                                        }
                                        options={formattedTaxIdentificationType}
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_select__tax__type"
                                                defaultMessage="Select  Importer Tax Identification Type"
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.taxIdentification.number"
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__tax__identification__number"
                                                defaultMessage=" Importer Tax Identification Number"
                                            />
                                        }
                                        required={
                                            importerTaxIdentification?.type
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.country"
                                        required={true}
                                        component={FormSelect}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__country"
                                                defaultMessage="Importer Country"
                                            />,
                                        ]}
                                        options={supportedCountryCodes}
                                        maxLength="30"
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.postalCode"
                                        required={true}
                                        component={FormZipCode}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_zipPostalCode"
                                                defaultMessage="Importer Zip/Postal Code"
                                            />,
                                        ]}
                                        country={importerCountry}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.city"
                                        required={true}
                                        component={FormSelectCity}
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__city"
                                                defaultMessage="Importer City"
                                            />,
                                        ]}
                                        country={importerCountry}
                                        postalCode={importerPostalCode}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.state"
                                        required={true}
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer__state"
                                                defaultMessage="Importer State/Province"
                                            />,
                                        ]}
                                        disabled
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.street1"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            "* ",
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_address1"
                                                defaultMessage="Importer Address 1"
                                            />,
                                        ]}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="commercialInvoice.importerOfRecord.address.street2"
                                        component={FormField}
                                        type="text"
                                        label={[
                                            <FormattedMessage
                                                id="orderDetails.support__documentation__importer_address2"
                                                defaultMessage="Importer Address 2"
                                            />,
                                        ]}
                                    />
                                </Grid>
                            </Fragment>
                        )}
                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name="commercialInvoice.dutiesPaidBy"
                                options={formattedDutiesPayableType}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__select__duties_payable_by"
                                        defaultMessage="Duties Payable By"
                                    />
                                }
                                required={true}
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={"commercialInvoice.termsOfSale"}
                                options={formattedTermsOfSale}
                                required={true}
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__termsOfSale"
                                        defaultMessage="Terms Of Sale"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.insuranceCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__insurance_charge"
                                        defaultMessage="Insurance Charge"
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.freightCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__freight_charge"
                                        defaultMessage="Freight Charge"
                                    />,
                                ]}
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.packingCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__packing__charge"
                                        defaultMessage="Packing Charge"
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.handlingCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__handling__charge"
                                        defaultMessage="Handling Charge"
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.otherCharge"
                                type="number"
                                component={FormField}
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__other__charge"
                                        defaultMessage="Other Charge"
                                    />,
                                ]}
                            />
                        </Grid>

                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.specialInstructions"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__special__instructions"
                                        defaultMessage="Special Instructions"
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                className={classes.fields__switches_below}
                                component={FormSwitch}
                                name="commercialInvoice.naftaDeclaration"
                                label={
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__naftaDeclaration"
                                        defaultMessage="USMCA Low Value Declaration Statement"
                                    />
                                }
                            />
                        </Grid>
                        {naftaDeclaration && (
                            <Grid item container xs={4}>
                                <Field
                                    component={FormSelectAutocomplete}
                                    name={"commercialInvoice.naftaLowValueRole"}
                                    options={formattedNaftaStatusType}
                                    label={
                                        <FormattedMessage
                                            id="orderDetails.support__documentation__naftaLowValueRole"
                                            defaultMessage="USMCA Status"
                                        />
                                    }
                                    required={naftaDeclaration}
                                />
                            </Grid>
                        )}
                        <Grid item container xs={4}>
                            <Field
                                name="commercialInvoice.customOriginator"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="orderDetails.support__documentation__originator"
                                        defaultMessage="Originator (If Not Shipper)"
                                    />,
                                ]}
                            />
                        </Grid>

                        {handlingUnits.map((hu, index) =>
                            hu.items.map((item, itemIndex) => {
                                const prefix = `handlingUnits[${index}].items[${itemIndex}]`
                                return (
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        key={`invoice${index}${itemIndex}${item?.description}`}
                                    >
                                        <Grid item container xs={4}>
                                            <Field
                                                name={`${prefix}.unit`}
                                                type="number"
                                                required={true}
                                                component={FormField}
                                                label={
                                                    <FormattedMessage
                                                        id="orderDetails.support__documentation__No__of__units"
                                                        defaultMessage=" {itemName} No. Of Units"
                                                        values={{
                                                            itemName: formattedItemName(
                                                                item
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item container xs={4}>
                                            <Field
                                                component={
                                                    FormSelectAutocomplete
                                                }
                                                name={`${prefix}.unitOfMeasure`}
                                                required={true}
                                                options={
                                                    formattedUnitOfMeasuresType
                                                }
                                                label={
                                                    <FormattedMessage
                                                        id="orderDetails.support__documentation__unit__of__measure"
                                                        defaultMessage=" {itemName} Unit of Measure"
                                                        values={{
                                                            itemName: formattedItemName(
                                                                item
                                                            ),
                                                        }}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            })
                        )}
                        <Grid
                            item
                            container
                            xs={4}
                            className={classes.signature_button}
                        >
                            <ManageSignature
                                fieldName="signatureImage"
                                previewDimensions={{
                                    width: 326,
                                    height: 26,
                                }}
                                addTitle={
                                    <FormattedMessage
                                        id="locations.signatureManagment__addSignature"
                                        defaultMessage="Add Signature"
                                    />
                                }
                                replaceTitle={
                                    <FormattedMessage
                                        id="locations.signatureManagment__replaceSignature"
                                        defaultMessage="Replace Signature"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
)

export default withStyles(styles)(CommercialInvoiceForm)
