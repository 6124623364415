import {
    Button,
    Chip,
    Grid,
    Paper,
    Typography,
    withStyles,
} from "@material-ui/core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { composeHandlingUnitSummary } from "./quickItems/HandlingUnit"
import { weightUnit } from "../../util/units"

const styles = theme => {
    return {
        quickRateSummaryContainer: {
            padding: "0 15%",
        },
        paper: {
            width: "100%",
            display: "flex",
            padding: "15px",
            flexWrap: "wrap",
        },
        header: {
            paddingBottom: "15px",
        },
        section: {
            paddingTop: "15px",
        },
        underline: {
            textDecoration: "underline",
        },
        chip: {
            margin: "5px 10px 5px 0px",
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    }
}

const QuickRateSummary = ({
    classes,
    handleEdit,
    formValues,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    intl,
}) => {
    const {
        origin,
        destination,
        handlingUnits = [],
        pickupAccessorials = [],
        deliveryAccessorials = [],
        isAdvancedItems,
        preferredSystemOfMeasurement,
        isFreightDirect,
        isFreightDirectReturns,
    } = formValues

    let pickupSelectedArr = []
    if (typeof pickupAccessorials === "string") {
        pickupSelectedArr = pickupAccessorials.split(",")
    } else {
        pickupSelectedArr = pickupAccessorials ?? []
    }

    let deliverySelectedArr = []
    if (typeof deliveryAccessorials === "string") {
        deliverySelectedArr = deliveryAccessorials.split(",")
    } else {
        deliverySelectedArr = deliveryAccessorials ?? []
    }

    const composeShortHandlingUnitSummary = (hu, weightUnit, intl) => {
        const firstItemWeight = hu?.items[0]?.weight
            ? `${hu?.items[0]?.weight}${weightUnit}`
            : ""
        const firstItemClass = hu?.items[0]?.freightClass
            ? intl.formatMessage(
                  {
                      id: "items.handlingUnit__class",
                      defaultMessage: "Class {class}",
                  },
                  { class: hu?.items[0]?.freightClass }
              )
            : ""

        let itemInfo = `${firstItemWeight ? `${firstItemWeight}` : ""}${
            firstItemClass ? `, ${firstItemClass}` : ""
        }`
        hu.items.forEach((item, index) => {
            const itemWeight = item.weight ? `${item.weight}${weightUnit}` : ""
            const itemClass = item?.freightClass
                ? intl.formatMessage(
                      {
                          id: "items.handlingUnit__class",
                          defaultMessage: "Class {class}",
                      },
                      { class: item?.freightClass }
                  )
                : ""

            if (index !== 0) {
                itemInfo += `  |  ${itemWeight ? `${itemWeight}` : ""}${
                    itemClass ? `, ${itemClass}` : ""
                }`
            }
        })

        return itemInfo
    }

    return (
        <Grid item container>
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.quickRateSummary__title"
                            defaultMessage="Summary"
                        />
                    </Typography>
                    <Button
                        id="editRateSummary"
                        color="primary"
                        variant="outlined"
                        onClick={() => handleEdit()}
                    >
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    </Button>
                </Grid>
                <Grid item container>
                    <Grid item container direction="column" xs={4}>
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="generalTerms__origin"
                                defaultMessage="Origin"
                            />
                        </Typography>
                        <Typography>{`${origin?.shippingAddress?.address?.city}, ${origin?.shippingAddress?.address?.state} ${origin?.shippingAddress?.address?.postalCode}`}</Typography>
                        <Grid item container>
                            {!isFreightDirect &&
                                !isFreightDirectReturns &&
                                pickupSelectedArr.map((accessorial, i) => (
                                    <Chip
                                        label={
                                            (
                                                pickupAccessorialsList.find(
                                                    it =>
                                                        it?.value ===
                                                        accessorial
                                                ) || {}
                                            ).label
                                        }
                                        key={i}
                                        className={classes.chip}
                                        tabIndex={-1}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={4}>
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="generalTerms__destination"
                                defaultMessage="Destination"
                            />
                        </Typography>
                        <Typography>{`${destination?.shippingAddress?.address?.city}, ${destination?.shippingAddress?.address?.state} ${destination?.shippingAddress?.address?.postalCode}`}</Typography>
                        <Grid item container>
                            {!isFreightDirect &&
                                !isFreightDirectReturns &&
                                deliverySelectedArr.map((accessorial, i) => (
                                    <Chip
                                        label={
                                            (
                                                deliveryAccessorialsList.find(
                                                    it =>
                                                        it?.value ===
                                                        accessorial
                                                ) || {}
                                            ).label
                                        }
                                        key={i}
                                        className={classes.chip}
                                        tabIndex={-1}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                    <Grid item container direction="column" xs={4}>
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="generalTerms__lineItems"
                                defaultMessage="Line Items"
                            />
                        </Typography>
                        {handlingUnits.map((hu, index) => (
                            <Grid
                                item
                                container
                                className={
                                    index === 0
                                        ? classes.huRowTop
                                        : classes.huRow
                                }
                            >
                                {isAdvancedItems ? (
                                    <Typography variant="caption">
                                        {composeHandlingUnitSummary(
                                            hu,
                                            weightUnit(
                                                preferredSystemOfMeasurement
                                            ),
                                            intl
                                        )}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {composeShortHandlingUnitSummary(
                                            hu,
                                            weightUnit(
                                                preferredSystemOfMeasurement
                                            ),
                                            intl
                                        )}
                                    </Typography>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(QuickRateSummary)
