import React from "react"
import { Grid, Modal, Snackbar, Typography } from "@material-ui/core"
import { ErrorSnackbarContentWrapper } from "../../errorSnackbar"
import UploadDiscountModal from "./UploadDiscountModal"
import { FormattedMessage } from "react-intl"

const DiscountsModalAndSnackbar = ({
    sbOpen,
    classes,
    handleSnackbarClose,
    handleModal,
    uploadModalOpen,
    requestDiscounts,
    sbVariant,
    sbMessage,
}) => (
    <>
        <Grid
            item
            container
            justify="center"
            className={classes.discount__title}
        >
            <Typography variant="title" align="center">
                <FormattedMessage
                    id="discounts.discounts__list"
                    defaultMessage="Freight Direct Discounts"
                />
            </Typography>
        </Grid>
        <Grid item container className={classes.upload__button}>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={sbOpen}
                autoHideDuration={1000}
                onClose={handleSnackbarClose}
            >
                <ErrorSnackbarContentWrapper
                    variant={sbVariant}
                    onClose={handleSnackbarClose}
                    message={
                        <span>
                            <Typography>{sbMessage}</Typography>
                        </span>
                    }
                />
            </Snackbar>
            <Modal open={uploadModalOpen} onClose={() => handleModal(false)}>
                <div className={classes.modal__container}>
                    <UploadDiscountModal
                        {...{ onCloseClick: handleModal, requestDiscounts }}
                    />
                </div>
            </Modal>
        </Grid>
    </>
)

export default DiscountsModalAndSnackbar
