import React from "react"
import PropTypes from "prop-types"
import { reset, change } from "redux-form"
import {
    Drawer,
    IconButton,
    Grid,
    Typography,
    Toolbar,
    AppBar,
    withStyles,
    Card,
    Button,
    Modal,
    SnackbarContent,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import ListIcon from "@material-ui/icons/List"
import ContactsIcon from "@material-ui/icons/Contacts"
import ShipmentIcon from "@material-ui/icons/LocalShipping"
import DashboardIcon from "@material-ui/icons/Dashboard"
import BusinessIcon from "@material-ui/icons/Business"
import Badge from "@material-ui/core/Badge"
import Popover from "@material-ui/core/Popover"
import NotificationsIcon from "@material-ui/icons/Notifications"
import { get } from "lodash"
import Iframe from "react-iframe"
import { connect } from "react-redux"
import DrawerListItem from "./drawerListItem"
import UserMenu from "./userMenu"
import NavAlert from "../alerts/navAlert"
import GlobalSearch from "../search"
import { withToken } from "../util"
import GlobalSpinner from "../util/spinner"
import { changePath, checkAPIConnection, pushPath } from "../../actions"
import { dismissAlert, fetchAlerts } from "../../actions/alerts"
import { FormattedMessage } from "react-intl"
import { withRouter } from "react-router-dom"
import { trackGAEventRR } from "../../actions/user"

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    )
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    hidden: {
        display: "none",
    },
    appFrame: {
        marginTop: "64px",
        paddingTop: "8px",
    },
    appBar: {
        paddingLeft: "10px",
    },
    nav_tabs: {
        marginLeft: "10px",
    },
    logo_tab: {
        opacity: "1 !important",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 px",
        ...theme.mixins.toolbar,
    },
    quote_text: {
        color: "white",
    },
    rate__button: {
        marginLeft: "18px",
    },
    notifications__icon: {
        fontSize: "33px",
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
        "&:active": {
            color: theme.palette.secondary.light,
            cursor: "pointer",
        },
    },
    impersonation_warning: {
        backgroundColor: theme.palette.error.dark,
        position: "absolute",
        top: "42px",
        left: "35%",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    alerts__Popover: {
        width: "520px",
        maxHeight: "605px",
        "overflow-y": "scroll",
    },
    hide: {
        visibility: "hidden",
    },
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 1,
        top: "50%",
        left: "50%",
        width: "80%",
        height: "80%",
    },
})

class LTLSelectNavigation extends React.Component {
    state = {
        drawerOpen: false,
        anchorEl: null,
        systemStatusModalOpen: false,
        systemStatusLoaderShow: false,
        apiConnected: true,
    }

    handleAlertsOpen = event => {
        this.setState({
            anchorEl: event.currentTarget,
        })
    }

    handleAlertsClose = () => {
        this.setState({
            anchorEl: null,
        })
    }

    handleDrawerOpen = () => {
        this.setState({
            drawerOpen: true,
        })
    }

    handleDrawerClose = () => {
        this.setState({
            drawerOpen: false,
        })
    }

    systemStatusOpen = () => {
        this.setState({
            systemStatusModalOpen: true,
            systemStatusLoaderShow: true,
        })
    }

    systemStatusClose = () => {
        this.setState({
            systemStatusModalOpen: false,
            systemStatusLoaderShow: false,
        })
    }

    statusLoaderHide = () => {
        this.setState({ systemStatusLoaderShow: false })
    }

    mapUrlToValue = url => {
        if (/^\/home/.test(url)) {
            return 0
        }
        if (/^\/book/.test(url)) {
            return 1
        }
        if (/^\/quickRate/.test(url)) {
            return 2
        }

        if (/^\/items/.test(url)) {
            return 3
        }
        if (/^\/address/.test(url)) {
            return 4
        }
        if (/^\/locations/.test(url)) {
            return 5
        }
        return false
    }

    async componentDidMount() {
        if (this.props.shouldDisplay) {
            const apiConnected = await this.props.onDisplay()
            this.setState({ apiConnected })
        }
    }

    async componentWillReceiveProps(newProps) {
        if (!this.props.shouldDisplay && newProps.shouldDisplay) {
            const apiConnected = await this.props.onDisplay()
            this.setState({ apiConnected })
        }
    }

    handleAlertClick = shipmentId => {
        this.props.onAlert(shipmentId)
        this.handleAlertsClose()
    }

    render() {
        const {
            classes,
            onHome,
            path,
            dismissAlert,
            alertList,
            profile,
            shouldDisplay,
            isAccountActive,
            impersonatedEmail,
            onQuickRate,
            onBookShipment,
            onRateAndShip,
        } = this.props
        const {
            anchorEl,
            systemStatusModalOpen,
            systemStatusLoaderShow,
            apiConnected,
        } = this.state
        if (!shouldDisplay) return null
        const open = Boolean(anchorEl)
        const selectedIndex = this.mapUrlToValue(path)
        const locations = get(profile, "locations", [])
        const canCreateRates =
            isAccountActive &&
            (locations || []).filter(
                loc =>
                    get(loc, "users[0].permissions.createRates.value") &&
                    !get(loc, "users[0].permissions.suspended.value")
            ).length > 0

        const canCreateShipments =
            isAccountActive &&
            (locations || []).filter(
                loc =>
                    get(loc, "users[0].permissions.createShipments.value") &&
                    !get(loc, "users[0].permissions.suspended.value")
            ).length > 0

        const drawerList = [
            {
                label: (
                    <FormattedMessage
                        id="navBar.menu__dashboard"
                        defaultMessage="Dashboard"
                    />
                ),
                renderIcon: () => <DashboardIcon />,
                url: "/home",
                onClick: this.handleDrawerClose,
            },
            {
                label: (
                    <FormattedMessage
                        id="navBar.menu__bookShipment"
                        defaultMessage="Book Shipment"
                    />
                ),
                disabled: !canCreateRates || !canCreateShipments,
                renderIcon: () => <ShipmentIcon />,
                url: "/book",
                onClick: () => {
                    this.handleDrawerClose()
                    this.props.onBookShipment(false)
                },
            },
            {
                label: (
                    <FormattedMessage
                        id="navBar__quickRate"
                        defaultMessage="Quick Rate"
                    />
                ),
                disabled: !canCreateRates,
                renderIcon: () => <ShipmentIcon />,
                url: "/quickRate",
                onClick: () => {
                    this.handleDrawerClose()
                    this.props.onQuickRate(false)
                },
            },
            {
                label: (
                    <FormattedMessage
                        id="navBar.menu__items"
                        defaultMessage="Items"
                    />
                ),
                renderIcon: () => <ListIcon />,
                url: "/items",
                onClick: this.handleDrawerClose,
            },
            {
                label: (
                    <FormattedMessage
                        id="navBar.menu__contacts"
                        defaultMessage="Contacts"
                    />
                ),
                renderIcon: () => <ContactsIcon />,
                url: "/address",
                onClick: this.handleDrawerClose,
            },
            {
                label: (
                    <FormattedMessage
                        id="navBar.menu__locations"
                        defaultMessage="Locations"
                    />
                ),
                renderIcon: () => <BusinessIcon />,
                url: "/locations",
                onClick: this.handleDrawerClose,
            },
        ]

        return (
            <div>
                <div className={classes.root}>
                    {!apiConnected && (
                        <Grid xs={12} item className={classes.appFrame}>
                            <Typography
                                variant="headline"
                                color="error"
                                align="center"
                            >
                                <FormattedMessage
                                    id="navBar__error"
                                    defaultMessage="The website is not able to connect to the server. Please check your Internet connection or contact your administrator."
                                />
                            </Typography>
                        </Grid>
                    )}
                    {path !== "/user/signup" && (
                        <AppBar position="fixed" className={classes.appBar}>
                            <Toolbar>
                                {isAccountActive > 0 && (
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        id="navigationMenu"
                                        onClick={this.handleDrawerOpen}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                )}
                                <Drawer
                                    onClose={this.handleDrawerClose}
                                    open={this.state.drawerOpen}
                                >
                                    <div
                                        onClick={this.handleDrawerClose}
                                        onKeyDown={this.handleDrawerClose}
                                    >
                                        <div className={classes.drawerHeader}>
                                            <Card
                                                tyle={{
                                                    width: "100%",
                                                }}
                                            />
                                        </div>
                                        {drawerList.map((item, index) => {
                                            if (item.disabled) {
                                                return <div key={index} />
                                            }
                                            return (
                                                <DrawerListItem
                                                    key={item.label}
                                                    renderIcon={item.renderIcon}
                                                    label={item.label}
                                                    url={item.url}
                                                    onClick={item.onClick}
                                                    selected={
                                                        selectedIndex === index
                                                    }
                                                />
                                            )
                                        })}
                                    </div>
                                </Drawer>
                                <Grid container>
                                    <Grid item container xs={2}>
                                        <Button
                                            onClick={onHome}
                                            id="navigation__homeButton"
                                        >
                                            <img
                                                alt="LTL Select"
                                                src="static/LTLselect-logo-type-new.png"
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={5}
                                        alignItems="center"
                                    >
                                        {canCreateRates && canCreateShipments && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    onBookShipment(true)
                                                }
                                                className={classes.rate__button}
                                                id="bookShipmentButton"
                                            >
                                                <FormattedMessage
                                                    id="navBar__bookShipment"
                                                    defaultMessage="Book Shipment"
                                                />
                                            </Button>
                                        )}
                                        {canCreateRates && (
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    onQuickRate(true)
                                                }
                                                className={classes.rate__button}
                                                id="quickRateButton"
                                            >
                                                <FormattedMessage
                                                    id="navBar__quickRate"
                                                    defaultMessage="Quick Rate"
                                                />
                                            </Button>
                                        )}
                                        {canCreateRates && (
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                onClick={() =>
                                                    onRateAndShip(true)
                                                }
                                                className={classes.rate__button}
                                                id="getRatesButton"
                                            >
                                                <FormattedMessage
                                                    id="navBar__legacyRates"
                                                    defaultMessage="Get Rates"
                                                />
                                            </Button>
                                        )}
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        {isAccountActive && <GlobalSearch />}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={1}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Badge
                                            badgeContent={alertList.length}
                                            color="secondary"
                                            classes={{
                                                badge: classes.badge,
                                            }}
                                            className={
                                                !(alertList.length > 0) &&
                                                classes.hide
                                            }
                                        >
                                            <NotificationsIcon
                                                className={
                                                    classes.notifications__icon
                                                }
                                                onClick={e =>
                                                    this.handleAlertsOpen(e)
                                                }
                                            />
                                        </Badge>
                                        <Popover
                                            open={open && alertList.length > 0}
                                            onClose={this.handleAlertsClose}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                        >
                                            <Grid
                                                className={
                                                    classes.alerts__Popover
                                                }
                                            >
                                                {alertList.map(
                                                    (alert, index) => (
                                                        <NavAlert
                                                            dismissAlert={
                                                                dismissAlert
                                                            }
                                                            onClick={
                                                                this
                                                                    .handleAlertClick
                                                            }
                                                            alert={alert}
                                                        />
                                                    )
                                                )}
                                            </Grid>
                                        </Popover>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={1}
                                        alignItems="center"
                                        justify="flex-end"
                                    >
                                        {isAccountActive && (
                                            <UserMenu
                                                systemStatusOpen={
                                                    this.systemStatusOpen
                                                }
                                            />
                                        )}
                                    </Grid>
                                    {impersonatedEmail && (
                                        <SnackbarContent
                                            className={
                                                classes.impersonation_warning
                                            }
                                            message={
                                                <FormattedMessage
                                                    id="warning__impersonation"
                                                    defaultMessage="Impersonating {impersonatedEmail}, please take extra care when performing actions with side effects"
                                                    values={{
                                                        impersonatedEmail,
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                </Grid>
                            </Toolbar>
                        </AppBar>
                    )}
                    <Modal
                        open={systemStatusModalOpen}
                        onClose={this.systemStatusClose}
                    >
                        <div className={classes.paper}>
                            {systemStatusLoaderShow && <GlobalSpinner />}
                            <Grid item container justify="flex-end">
                                <Grid item>
                                    <IconButton
                                        onClick={this.systemStatusClose}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Iframe
                                url="https://p.datadoghq.com/sb/808b01da2-6f6347decae93e056eaca36c5115c488"
                                id="systemHealth"
                                display="initial"
                                onLoad={() => this.statusLoaderHide()}
                                position="relative"
                                allowFullScreen
                            />
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

LTLSelectNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    path: get(props, "history.location.pathname"),
    alertList: get(state, "alerts.alertList"),
    impersonatedEmail: get(state, "impersonation.email"),
    profile: get(state, "user.profile", null),
    shouldDisplay:
        !get(state, "authorization.initFinished") ||
        get(state, "authorization.isFinished") ||
        get(state, "shareStatus.shareKey"),
    isAccountActive: get(state, "user.preferences.accountActive"),
})

const mapDispatchToProps = (dispatch, props) => ({
    onRateAndShip: isNavBar => {
        dispatch(changePath("/rate"))
        if (props.path === "/rate") dispatch(reset("rate"))
        if (isNavBar) {
            dispatch(trackGAEventRR("Legacy Rates", "Nav Button Click"))
        }
    },
    onQuickRate: isNavBar => {
        dispatch(pushPath("/quickRate"))
        if (props?.history?.location?.pathname === "/quickRate") {
            dispatch(reset("quickRate"))
            dispatch(change("bookShipment", "shouldReset", true))
            dispatch(change("quickRate", "shouldReset", true))
        }
        dispatch(reset("bookShipment"))
        if (isNavBar) {
            dispatch(trackGAEventRR("Quick Rate Flow", "Nav Button Click"))
        } else {
            dispatch(trackGAEventRR("Quick Rate Flow", "Drawer Menu Click"))
        }
    },
    onBookShipment: isNavBar => {
        dispatch(pushPath("/book"))
        if (props?.history?.location?.pathname === "/book") {
            dispatch(reset("bookShipment"))
            dispatch(change("bookShipment", "shouldReset", true))
            dispatch(change("quickRate", "shouldReset", true))
        }
        dispatch(reset("quickRate"))
        if (isNavBar) {
            dispatch(trackGAEventRR("Book Shipment Flow", "Nav Button Click"))
        } else {
            dispatch(trackGAEventRR("Book Shipment Flow", "Drawer Menu Click"))
        }
    },
    onItems: () => {
        dispatch(changePath("/items"))
    },
    onContacts: () => {
        dispatch(changePath("/address"))
    },
    onHome: () => {
        dispatch(changePath("/home"))
    },
    onAlert: shipmentId => {
        dispatch(changePath(`/track/${shipmentId}`))
    },
    onDisplay: async () => {
        dispatch(fetchAlerts())
        return dispatch(checkAPIConnection())
    },
    dismissAlert: (shipmentId, alertId) =>
        dispatch(dismissAlert(shipmentId, alertId)),
})

export const NavAppBar = withStyles(styles, { withTheme: true })(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(withToken(LTLSelectNavigation))
    )
)
