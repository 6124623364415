import React, { useState, useContext, createContext } from "react"

const Context = createContext({
    openSnackbar: () => {},
    closeSnackbar: () => {},
    open: false,
    variant: "",
    message: "",
    autoHideDuration: 6000,
})

export const useSnackbarContext = () => {
    const snackbarContext = useContext(Context)
    if (!snackbarContext) {
        throw new Error(
            "Cannot use snackbar context ouside of SnackbarProvider"
        )
    }
    return snackbarContext
}

const SnackbarProviderContext = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [variant, setVariant] = useState("success")
    const [message, setMessage] = useState("")
    const [autoHideDuration, setAutoHideDuration] = useState(6000)

    const openSnackbar = (variant, message, autoHideDuration) => {
        setOpen(true)
        setVariant(variant)
        setMessage(message)
        setAutoHideDuration(autoHideDuration)
    }

    const closeSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setOpen(false)
        setMessage("")
    }

    return (
        <Context.Provider
            value={{
                openSnackbar,
                closeSnackbar,
                open,
                variant,
                message,
                autoHideDuration,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const SnackbarProvider = SnackbarProviderContext
