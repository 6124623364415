import createSelector from "re-reselect"
import moment from "moment"

export const createGoodInitialValues = (handlingUnits = []) =>
    handlingUnits.flatMap(hu =>
        hu.items.map(item => ({
            description: item?.description,
            harmonizedCode: item?.harmonizedCode,
            countryOfOrigin: item?.countryOfManufacture,
        }))
    )

export const usmcaFormGenerateInitialValues = createSelector(
    (state, props) =>
        state.shipment.list?.[props.shipmentId] ?? props?.newflow
            ? state.form?.bookShipment?.values
            : state.form?.bolEntry?.values,
    (state, props) => props.previousDocument,
    state => state.user?.profile?.locations,
    (state, props) => props.locationId,
    (shipment, previousDocument, locations, providedLocationId) => {
        if (previousDocument?.documentFields) {
            return previousDocument.documentFields
        }

        let locationId = providedLocationId

        if (!locationId) {
            locationId = locations.find(
                x => shipment?.query?.cpg === x?.cpgCode
            )?._id
        }

        if (!shipment) {
            return {
                locationId,
                goods: [{}],
                signatureInfo: {
                    date: moment(),
                },
            }
        }

        const goods = createGoodInitialValues(shipment?.handlingUnits)

        return {
            documentCategory: "USMCA",
            internalTrackingNumber:
                shipment?.identifiers?.internalTrackingNumber,
            locationId,
            goods,
            signatureInfo: {
                date: moment(),
            },
        }
    }
)((state, props) => props?.shipmentId ?? "")
