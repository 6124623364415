import React from "react"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Popover from "@material-ui/core/Popover"
import FormBillToLocationOption from "../form/form-bill-to-location-option"
import Select from "react-select"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    paper: {
        maxHeight: "520px",
        padding: "6px 6px ",
        zIndex: 10000,
        overflow: "visible",
    },
    select: {
        color: "black",
    },
})

class BillToLocationPicker extends React.Component {
    customFilter = (option, match) => {
        return (
            option.label
                .toString()
                .toLowerCase()
                .includes(match) ||
            get(option, "fedexBillToAccount", "")
                .toString()
                .includes(match) ||
            get(option, "fedexFreightAccount", "")
                .toString()
                .includes(match)
        )
    }

    render() {
        const {
            classes,
            options,
            onBillToLocationChange,
            anchorEl,
            handleClose,
            showBillToOverlay,
        } = this.props

        return (
            <Popover
                id="billToLocationMenu"
                anchorEl={anchorEl}
                open={showBillToOverlay}
                classes={{
                    paper: classes.paper,
                }}
                onClose={() => handleClose()}
            >
                <Select
                    options={options}
                    optionComponent={FormBillToLocationOption}
                    openOnFocus
                    autoFocus
                    className={classes.select}
                    onChange={option => {
                        onBillToLocationChange(option)
                        handleClose()
                    }}
                    inputProps={{
                        id: "bill_to_location_picker",
                        dataTestId: "billToLocation",
                    }}
                    style={{
                        minWidth: get(anchorEl, "clientWidth", "200px"),
                    }}
                    noResultsText={
                        <Typography>
                            <FormattedMessage
                                id="generalTerms__noResults"
                                defaultMessage="No results found"
                            />
                        </Typography>
                    }
                    filterOption={this.customFilter}
                />
            </Popover>
        )
    }
}

export default withStyles(styles)(BillToLocationPicker)
