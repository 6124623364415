import React from "react"
import { FormattedMessage } from "react-intl"

export default {
    id: "quickQuote",
    steps: [
        {
            title: "Welcome to Quick Quote.",
            target: "#quick_quote_form",
            content: (
                <FormattedMessage
                    id="tutorial.quickQuote__title"
                    defaultMessage="Welcome to Quick Quote, a faster way to get quotes for your shipments! Designed to help you get your quotes faster."
                />
            ),
            disableBeacon: true,
        },
        {
            title: "Use Quick Quote",
            target: "#quick_quote_fields",
            content: (
                <FormattedMessage
                    id="tutorial.quickQuote.fields__content"
                    defaultMessage="All that's needed for quick quote is Origin, Destination, Item Class and Item Weight. If there are multiple item classes to be shipped, they can be added by Clicking  'Add Another'"
                />
            ),
        },
        {
            title: "Need full rates?",
            target: "#finalize_btn",
            content: (
                <FormattedMessage
                    id="tutorial.quickQuote.finalize__content"
                    defaultMessage="If you are still looking to get full rates, simply click 'Finalize Rates' at the bottom of the screen to get to the rate page."
                />
            ),
        },
    ],
}
