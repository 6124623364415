import { Button, Grid, Typography, withStyles } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import FormCheckbox from "../../form/form-checkbox"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { supportedTiles } from "./constants"
import { Field, reduxForm } from "redux-form"
import { updateUserTileSettings } from "../../../actions/user"
import { connect } from "react-redux"
import Spinner from "../../util/spinner"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import DraggableTileList from "./DraggableTileList"
import { arrayMove } from "react-sortable-hoc"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        top: 0,
        bottom: 0,
        width: "900px",
        maxHeight: "700px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    },
    form: {
        width: "100%",
        display: "flex",
    },
    checkboxes__container: {
        borderBottom: "solid 1px #D4D4D4",
        paddingBottom: "15px",
    },
    reorganizeContainer: {
        paddingTop: "15px",
    },
})

const ManageTiles = ({
    classes,
    onClose,
    saveTileSettings,
    handleSubmit,
    activateTile,
    tileSettings = [],
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [currentTileOrder, setCurrentTileOrder] = useState([])

    useEffect(() => {
        setCurrentTileOrder(tileSettings)
    }, [tileSettings])

    const { openSnackbar } = useSnackbarContext()

    const onConfirm = async values => {
        setIsLoading(true)
        try {
            await saveTileSettings(currentTileOrder)

            await activateTile(null)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="dashboard.tiles__updateSuccess"
                    defaultMessage="Dashboard settings successfully updated"
                />,
                4000
            )
            onClose()
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="dashboard.tiles__updateError"
                    defaultMessage="Error updating dashboard settings"
                />,
                3000
            )
        } finally {
            setIsLoading(false)
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        setCurrentTileOrder(arrayMove(currentTileOrder, oldIndex, newIndex))

        document.body.className = ""
    }

    const handleCheckboxChange = (selectedTileValue, fieldValue) => {
        if (fieldValue) {
            setCurrentTileOrder([...currentTileOrder, selectedTileValue])
        } else {
            setCurrentTileOrder(
                currentTileOrder.filter(tile => tile !== selectedTileValue)
            )
        }
    }

    return (
        <form onSubmit={handleSubmit(onConfirm)} className={classes.form}>
            {isLoading && <Spinner isGlobal />}
            <Grid item container className={classes.paper}>
                <Grid item container alignContent="flex-start">
                    <Grid item container className={classes.header}>
                        <Grid
                            item
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Typography variant="title">
                                <FormattedMessage
                                    id="dashboard.tilesModal__title"
                                    defaultMessage="Manage Dashboard Tiles"
                                />
                            </Typography>
                            <IconButton onClick={() => onClose()}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.checkboxes__container}
                    >
                        <Grid item container xs={4} direction="column">
                            {supportedTiles.slice(0, 5).map(tile => {
                                return (
                                    <Field
                                        name={tile.value}
                                        label={tile.label}
                                        component={FormCheckbox}
                                        onChange={(a, fieldValue) => {
                                            handleCheckboxChange(
                                                tile.value,
                                                fieldValue
                                            )
                                        }}
                                    />
                                )
                            })}
                        </Grid>
                        <Grid item container xs={4} direction="column">
                            {supportedTiles.slice(5, 10).map(tile => {
                                return (
                                    <Field
                                        name={tile.value}
                                        label={tile.label}
                                        component={FormCheckbox}
                                        onChange={(a, fieldValue) => {
                                            handleCheckboxChange(
                                                tile.value,
                                                fieldValue
                                            )
                                        }}
                                    />
                                )
                            })}
                        </Grid>
                        <Grid item container xs={4} direction="column">
                            {supportedTiles.slice(10, 15).map(tile => {
                                return (
                                    <Field
                                        name={tile.value}
                                        label={tile.label}
                                        component={FormCheckbox}
                                        onChange={(a, fieldValue) => {
                                            handleCheckboxChange(
                                                tile.value,
                                                fieldValue
                                            )
                                        }}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.reorganizeContainer}
                    >
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="dashboard.manage__reorganize"
                                defaultMessage="Reorganize Tiles"
                            />
                        </Typography>
                        <DraggableTileList
                            onSortEnd={onSortEnd}
                            currentTileOrder={currentTileOrder}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-end"
                    alignItems="flex-end"
                >
                    <Button variant="contained" color="primary" type="submit">
                        <FormattedMessage
                            id="generalTerms__confirm"
                            defaultMessage="Confirm"
                        />
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
const mapStateToProps = state => {
    const tileSettings = state?.user?.profile?.preferences?.tileSettings ?? []

    let initialValues = {}

    tileSettings.forEach(tile => {
        if (
            supportedTiles.find(supportedTile => supportedTile.value === tile)
        ) {
            initialValues[tile] = true
        }
    })
    return {
        initialValues,
        tileSettings,
    }
}

const mapDispatchToProps = dispatch => ({
    saveTileSettings: async tileSettings =>
        await dispatch(updateUserTileSettings(tileSettings)),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "manageDashboardTiles", // a unique identifier for this form
            enableReinitialize: true,
        })(ManageTiles)
    )
)
