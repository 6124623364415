import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import MuiTableCell from "@material-ui/core/TableCell"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { lengthUnit, weightUnit } from "../../util/units"

const TableCell = withStyles({
    root: {
        border: "none",
        paddingRight: "20px",
    },
})(MuiTableCell)

const styles = {
    description: {
        width: "40%",
    },
    section__title: {
        textDecoration: "underline",
    },
    card: {
        width: "100%",
    },
    topTableRow: {
        borderTop: "solid 1px #D4D4D4",
    },
}

const HandlingUnitsTable = ({
    preferredSystemOfMeasurement,
    handlingUnits,
    classes,
}) => {
    return (
        <Grid item container>
            <Table size="small" aria-label="Items Table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage
                                id="items__pkgType"
                                defaultMessage="Pkg Type"
                            />
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage
                                id="items__dims"
                                defaultMessage="Dims"
                            />
                            {`(${lengthUnit(preferredSystemOfMeasurement)})`}
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage
                                id="items__individualHUWeight"
                                defaultMessage="Individual H/U Weight"
                            />
                            {`(${weightUnit(preferredSystemOfMeasurement)})`}
                        </TableCell>
                        <TableCell align="right">
                            <FormattedMessage
                                id="items__HUCount"
                                defaultMessage="H/U Count"
                            />
                        </TableCell>
                        <TableCell className={classes.description}>
                            <FormattedMessage
                                id="items__itemDescription"
                                defaultMessage="Item Description"
                            />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage
                                id="items__freightClass"
                                defaultMessage="Freight Class"
                            />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage
                                id="items__itemWeight"
                                defaultMessage="Item Weight"
                            />
                            {` (${weightUnit(preferredSystemOfMeasurement)})`}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {handlingUnits.map((hu, index) =>
                        hu.items.map((item, itemIndex) => {
                            return (
                                <Fragment>
                                    {itemIndex === 0 ? (
                                        <TableRow
                                            key={`${item.description} ${index +
                                                1}`}
                                            className={classes.topTableRow}
                                        >
                                            <TableCell>
                                                {hu.packageType}
                                            </TableCell>
                                            <TableCell align="right">{`${hu.length}x${hu.width}x${hu.height}`}</TableCell>
                                            <TableCell align="right">
                                                {hu.handlingUnitWeight}
                                            </TableCell>
                                            <TableCell align="right">
                                                {hu.count}
                                            </TableCell>
                                            <TableCell
                                                className={classes.description}
                                            >
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {item.freightClass}
                                            </TableCell>
                                            <TableCell>
                                                {hu.isIndividualHUWeight ||
                                                hu.isMultiClass
                                                    ? item.weight
                                                    : Math.ceil(
                                                          item.weight / hu.count
                                                      )}
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow
                                            key={`${item.description} ${index +
                                                1}`}
                                        >
                                            <TableCell />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell align="right" />
                                            <TableCell
                                                className={classes.description}
                                            >
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {item.freightClass}
                                            </TableCell>
                                            <TableCell>
                                                {hu.isIndividualHUWeight ||
                                                hu.isMultiClass
                                                    ? item.weight
                                                    : Math.ceil(
                                                          item.weight / hu.count
                                                      )}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Fragment>
                            )
                        })
                    )}
                </TableBody>
            </Table>
        </Grid>
    )
}

export default withStyles(styles)(HandlingUnitsTable)
