import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { AccessorialsDisplay } from "../../util/accessorialsDisplay"
import Chip from "@material-ui/core/Chip"

const styles = theme => ({
    card: {
        height: "100%",
        minHeight: "260px",
    },
    content: {
        "margin-left": "60px",
        "padding-top": "0px !important",
    },
})

const DeliveryRequirementsCard = ({
    list,
    classes,
    note,
    gridSize,
    shipmentId,
    shipment,
    isInBondShipment,
}) => (
    <Grid xs={gridSize} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="title">
                        <FormattedMessage
                            id="orderResults.delivery__deliveryRequirements"
                            defaultMessage="Delivery Requirements"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                {note && (
                    <Typography>
                        <FormattedMessage
                            id="orderResults.delivery__notes"
                            defaultMessage="Notes"
                        />
                        {`: ${note}`}
                    </Typography>
                )}
                {shipment?.rate?.mode !== "LTL_DIRECT" &&
                shipment?.rate?.mode !== "LTL_DIRECT_RETURNS" ? (
                    <Grid item container>
                        <Typography variant={"subheading"}>
                            <FormattedMessage
                                id="orderResults.delivery__deliveryAccessorials"
                                defaultMessage="Delivery Accessorials"
                            />
                        </Typography>
                        <Grid container>
                            <AccessorialsDisplay
                                shipmentId={shipmentId}
                                isInBondShipment={isInBondShipment}
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid item container>
                        <Typography variant={"subheading"}>
                            <FormattedMessage
                                id="orderResults.delivery__deliveryServiceLevel"
                                defaultMessage="Delivery Service Level"
                            />
                        </Typography>
                        <Grid container>
                            <Typography variant={"subheading"} color="primary">
                                <Chip
                                    label={
                                        shipment?.rate?.serviceLevel
                                            ?.description
                                    }
                                    color="primary"
                                    tabIndex={-1}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    </Grid>
)

DeliveryRequirementsCard.propTypes = {
    classes: PropTypes.object,
    note: PropTypes.string,
}

DeliveryRequirementsCard.defaultProps = {
    classes: {},
    note: "",
}

export default withStyles(styles)(DeliveryRequirementsCard)
