import moment from "moment"
import {
    REMOVE_ITEM,
    SET_QUOTE_MODE,
    SEARCH_SAVE_SUCCESS,
    QUOTE_RESET,
    SEARCH_SAVE_ERROR,
    SEARCH_LOAD,
    INIT_QUOTE,
    SET_EDIT_MODE,
    SET_SHIPPING_ADDRESSES,
} from "../actions/quote"
import {
    QUOTE_RESULT,
    QUOTE_FIELD_VALIDATION,
    MAP_FORM_TO_SEARCH,
} from "../actions/quote-request"
import {
    UPDATE_QUOTE_ITEM_FIELD,
    QUOTE_ITEM_FIELD_VALIDATION,
    UPDATE_LINE_ITEM,
    SEARCH_ITEMS_START,
    SEARCH_TEXT_CHANGE,
} from "../actions/quote-item"
import { USER_DISAUTHENTICATED } from "../actions/user"
import { ADD_ACCESSORIAL, REMOVE_ACCESSORIAL } from "../actions/accessorial"
import { removeItemFromArray } from "./index"
import { filterSearchItems } from "../components/dashboard/search/util"
import { get } from "lodash"

export function newItem() {
    return {
        count: 1,
        countryOfManufacture: null,
        description: "",
        freightClass: "",
        harmonizedCode: null,
        hazMatClass: null,
        hazMatPkgGrp: null,
        hazMatUnNumber: null,
        height: "",
        isHazMat: false,
        length: "",
        nmfcCode: "",
        nmfcSubclass: "",
        packageType: "",
        pieces: 1,
        unitPrice: null,
        weight: "",
        width: "",
    }
}

export function newFreightDirectItem() {
    return {
        count: 1,
        countryOfManufacture: null,
        description: "",
        freightClass: "",
        harmonizedCode: null,
        hazMatClass: null,
        hazMatPkgGrp: null,
        hazMatUnNumber: null,
        height: "",
        isHazMat: false,
        length: "",
        nmfcCode: "",
        nmfcSubclass: "",
        packageType: "",
        pieces: 1,
        freightDirectPieces: [
            {
                count: 1,
                weight: null,
            },
        ],
        unitPrice: null,
        weight: "",
        width: "",
    }
}

export function newHandlingUnit(
    state = {
        count: 1,
        height: "",
        items: [newItem()],
        length: "",
        name: "",
        packageType: "",
        stackable: true,
        doNotStack: false,
        totalWeight: "",
        width: "",
        isMultiClass: false,
        isIndividualHUWeight: false,
    },
    action = {}
) {
    switch (action.type) {
        case UPDATE_QUOTE_ITEM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
                isDefault: false,
                ...(action.field === "packageType" && {
                    sizeLocked: !!action.size,
                    ...action.size,
                }),
            }
        case QUOTE_ITEM_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        case SEARCH_LOAD:
        default:
            return state
    }
}

export function newFreightDirectHandlingUnit(
    state = {
        count: 1,
        height: "",
        items: [newFreightDirectItem()],
        length: "",
        name: "",
        packageType: "",
        stackable: true,
        doNotStack: false,
        totalWeight: "",
        width: "",
        isMultiClass: false,
        isIndividualHUWeight: false,
    },
    action = {}
) {
    switch (action.type) {
        case UPDATE_QUOTE_ITEM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
                isDefault: false,
                ...(action.field === "packageType" && {
                    sizeLocked: !!action.size,
                    ...action.size,
                }),
            }
        case QUOTE_ITEM_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        case SEARCH_LOAD:
        default:
            return state
    }
}

export function newQuickQuoteHandlingUnit(
    state = {
        count: 1,
        height: "12",
        items: [newItem()],
        length: "48",
        name: "",
        packageType: "PLT4840",
        stackable: true,
        doNotStack: false,
        totalWeight: "",
        width: "40",
        isMultiClass: false,
    },
    action = {}
) {
    switch (action.type) {
        case UPDATE_QUOTE_ITEM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
                isDefault: false,
                ...(action.field === "packageType" && {
                    sizeLocked: !!action.size,
                    ...action.size,
                }),
            }
        case QUOTE_ITEM_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        case SEARCH_LOAD:
        default:
            return state
    }
}

export function newQuickQuoteItem(
    state = {
        stackable: true,
        doNotStack: false,
        freightClass: "",
        weight: "",
        length: "48",
        width: "40",
        height: "12",
        packageType: "PLT4840",
        pieces: "1",
        count: "1",
        description: "New Item",
        isDefault: true,
        isHazMat: false,
        pkgGrp: "",
        unNumber: "",
        hazClass: "",
        nmfc1: "",
        nmfc2: "",
        countryOfManufacture: "",
        unitPrice: "",
        harmonizedCode: "",
    },
    action = {}
) {
    switch (action.type) {
        case UPDATE_QUOTE_ITEM_FIELD:
            return {
                ...state,
                [action.field]: action.value,
                isDefault: false,
                ...(action.field === "packageType" && {
                    sizeLocked: !!action.size,
                    ...action.size,
                }),
            }
        case QUOTE_ITEM_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        case SEARCH_LOAD:
        default:
            return state
    }
}

function defaultDate() {
    const value = moment().format("YYYY-MM-DD")
    return moment.utc(value)
}

export function search(
    state = {
        mode: "LTL",
        pickupDate: defaultDate(),
        origin: "",
        originCity: "",
        originState: "",
        originCountry: "",
        originAddress: null,
        originContact: null,
        destination: "",
        destinationCity: "",
        destinationState: "",
        destinationCountry: "",
        destinationAddress: null,
        destinationContact: null,
        items: [newItem()],
        commodityAccessorials: [],
        pickupAccessorials: [],
        deliveryAccessorials: [],
        isOverLength: false,
        isExtremeLength: false,
        totalLinearFeet: 0,
        userProvidedTotalLinearFeet: false,
        capLoadTotalLinearFeet: 0,
        volumeTotalLinearFeet: 0,
        isOverLTLLimit: false,
        referenceNumber: "",
        validation: {
            list: {},
            strict: false,
        },
        statusMessage: "",
        markupAmount: "",
        markupType: "markupAmount",
        volumeType: "NORMAL",
        isPrefilled: false,
        contactLoaded: false,
        includeThirdParty: true,
        isQuickQuote: false,
    },
    action = {}
) {
    switch (action.type) {
        case QUOTE_RESET:
        case USER_DISAUTHENTICATED:
            return search()
        case INIT_QUOTE:
            if (state.contactLoaded) {
                return {
                    ...state,
                    ...action.data,
                    contactLoaded: false,
                }
            }
            return { ...search(), ...action.data }
        case QUOTE_FIELD_VALIDATION:
            return {
                ...state,
                validation: {
                    list: action.validation,
                    strict: action.strict || state.validation.strict,
                },
            }
        case MAP_FORM_TO_SEARCH:
            return {
                ...state,
                preferredSystemOfMeasurement:
                    action.newQuoteForm.preferredSystemOfMeasurement,
                preferredCurrencyCode:
                    action.newQuoteForm.preferredCurrencyCode,
                pickupAccessorials:
                    action.formattedAccessorialsForm.pickupAccessorials,
                deliveryAccessorials:
                    action.formattedAccessorialsForm.deliveryAccessorials,
                commodityAccessorials:
                    action.formattedAccessorialsForm.commodityAccessorials,
                cpg: action.newQuoteForm.billToLocation,
                origin: action.newQuoteForm.origin,
                originCity: action.newQuoteForm.originCity,
                originCountry: action.newQuoteForm.originCountry,
                originState: action.newQuoteForm.originState,
                originContact: action.newQuoteForm.originContact,
                originContactInfo: action.newQuoteForm.originContactInfo,
                originAddress: action.newQuoteForm.originAddress,
                originAddressInfo: action.newQuoteForm.originAddressInfo,
                destination: action.newQuoteForm.destination,
                destinationCity: action.newQuoteForm.destinationCity,
                destinationCountry: action.newQuoteForm.destinationCountry,
                destinationState: action.newQuoteForm.destinationState,
                destinationContact: action.newQuoteForm.destinationContact,
                destinationContactInfo:
                    action.newQuoteForm.destinationContactInfo,
                destinationAddress: action.newQuoteForm.destinationAddress,
                destinationAddressInfo:
                    action.newQuoteForm.destinationAddressInfo,
                pickupDate: moment.utc(action.newQuoteForm.pickupDate),
                includeThirdParty: action.newQuoteForm.includeThirdParty,
                isQuickQuote: action.newQuoteForm.isQuickQuote,
                volumeType: action.newQuoteForm.volumeLTL
                    ? "NORMAL_AND_VOLUME"
                    : "NORMAL",
                totalLinearFeet: action.newQuoteForm.totalLinearFeet,
                userProvidedTotalLinearFeet:
                    action.newQuoteForm.userProvidedTotalLinearFeet,
                capLoadTotalLinearFeet:
                    action.newQuoteForm.capLoadTotalLinearFeet,
                volumeTotalLinearFeet:
                    action.newQuoteForm.volumeTotalLinearFeet,
                isOverLTLLimit: action.newQuoteForm.isOverLTLLimit,
            }
        case ADD_ACCESSORIAL:
            if (
                state[action.field].find(
                    item => item.value === action.value.value
                )
            )
                return state
            return {
                ...state,
                [action.field]: [...state[action.field], action.value],
            }
        case REMOVE_ACCESSORIAL:
            return {
                ...state,
                [action.field]: state[action.field].filter(
                    item => item.value !== action.value.value
                ),
            }
        case REMOVE_ITEM: {
            const result = removeItemFromArray(state, "items", action.index)
            return result
        }
        case UPDATE_QUOTE_ITEM_FIELD:
            return { ...state, items: [...state.items] }
        case QUOTE_RESULT:
            const initialDeliveryAccessorials = get(
                action,
                "search.deliveryAccessorials",
                []
            )
            const initialPickupAccessorials = get(
                action,
                "search.pickupAccessorials",
                []
            )
            let pickupAccessorials = initialPickupAccessorials
            let deliveryAccessorials = initialDeliveryAccessorials
            const mutuallyExclusiveAccessorials = [
                "LGPU",
                "RESPU",
                "LTDPU",
                "LGDEL",
                "RESDEL",
                "LTDDEL",
                "DOCKPU",
                "DOCKDEL",
            ]
            if (
                !mutuallyExclusiveAccessorials.some(el =>
                    initialPickupAccessorials.map(el => el.value).includes(el)
                )
            ) {
                pickupAccessorials = [
                    { value: "DOCKPU" },
                    ...initialPickupAccessorials,
                ]
            }
            if (
                !mutuallyExclusiveAccessorials.some(el =>
                    initialDeliveryAccessorials.map(el => el.value).includes(el)
                )
            ) {
                deliveryAccessorials = [
                    { value: "DOCKDEL" },
                    ...initialDeliveryAccessorials,
                ]
            }
            return Object.assign({}, state, action.search, {
                origin: get(action, "search.origin", "").toString(),
                destination: get(action, "search.destination", "").toString(),
                pickupDate: moment.utc(get(action, "search.pickupDate")),
                referenceNumber: get(action, "identifiers.referenceNumber"),
                items: get(action, "items", []).map(item =>
                    newItem(item, action)
                ),
                isPrefilled: true,
                displayPickupAccessorials: pickupAccessorials,
                displayDeliveryAccessorials: deliveryAccessorials,
            })
        case SET_SHIPPING_ADDRESSES:
            return { ...state, ...action.payload }
        case SET_QUOTE_MODE:
            return { ...state, mode: action.mode }
        case SET_EDIT_MODE: {
            if (!action.mode) return state
            return { ...state, items: state.items }
        }
        case SEARCH_LOAD: {
            const newItems = action.settings.items.map(item =>
                newItem(item, action)
            )
            return { ...state, ...action.settings, items: newItems }
        }
        case SEARCH_SAVE_SUCCESS:
            return { ...state, statusMessage: "Saved." }
        case SEARCH_SAVE_ERROR:
            return { ...state, statusMessage: `${action.error}` }
        case UPDATE_LINE_ITEM: {
            const items = [...state.items]
            items[action.index] = {
                ...newItem(),
                ...action.item,
                isDefault: false,
                editMode: true,
                _id: undefined,
                parentId: action.item._id,
            }
            return { ...state, items }
        }
        default:
            return state
    }
}

export function searchItem(
    state = {
        searchText: "",
        items: [],
        allItems: [],
    },
    action = {}
) {
    switch (action.type) {
        case SEARCH_ITEMS_START:
            return {
                searchText: "",
                items: action.items,
                allItems: action.items,
            }
        case SEARCH_TEXT_CHANGE:
            return {
                ...state,
                searchText: action.text,
                ...(action.text
                    ? {
                          items: filterSearchItems(action.text, state.allItems),
                      }
                    : { items: state.allItems }),
            }
        default:
            return state
    }
}
