import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import GlobalSpinner from "../../../util/spinner"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { Chip, Avatar } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import FormCheckbox from "../../../form/form-checkbox"
import { Field, reduxForm, change } from "redux-form"
import { attachmentOptions } from "../../../../misc"
import { requestContactAddresses } from "../../../../actions/address"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import AddIcon from "@material-ui/icons/Add"
import CancelIcon from "@material-ui/icons/Cancel"
import EmailIcon from "@material-ui/icons/Email"
import { emailValidator } from "../../../../actions/validation"
import { combineValidators } from "revalidate"
import { goFetch } from "../../../../http"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import { preferenceLanguageOptions } from "../../../constants/preferenceLanguageOptions"
import FormSelect from "../../../form/form-select"
import { trackGAEventNew } from "../../../../actions/user"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "550px",
        outline: "none",
    },
    chip: {
        margin: "5px",
        backgroundColor: `${theme.palette.primary.light} !important`,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    actions: {},
    actions__button: {
        paddingLeft: "15px",
    },
    header: {
        paddingBottom: "15px",
        borderBottom: "solid 1px #D4D4D4",
    },
    attachmentRow: {
        padding: "0px 10px",
    },
    attachmentRow__selectAll: {
        padding: "5px 10px",
        borderBottom: "solid 1px #D4D4D4",
    },
    attachments: {
        overflowY: "scroll",
        maxHeight: "376px",
    },
    contactsSelect: {
        width: "100%",
    },
    recipients__container: {
        padding: "22px 10px 10px 10px",
    },
    input__label: {
        fontSize: "21px",
    },
    or__text: {
        paddingTop: "8px",
    },
    recipient__chips: {
        paddingTop: "10px",
        overflowX: "scroll",
    },
    addRecipients__title: {
        paddingBottom: "14px",
        paddingLeft: "4px",
    },
})

const DocumentCheckBox = ({ name, label }) => (
    <Field name={name} label={label} component={FormCheckbox} />
)

const ShareAttachmentsPresentation = ({
    classes,
    onClose,
    attachments = [],
    requestContactAddresses,
    contacts = [],
    newEmailRecipient,
    newEmailRecipientError,
    changeField,
    formDocuments = {},
    originContactEmail,
    shipmentId,
    shareLanguage,
    GAEvent,
    pickUpContactEmail,
}) => {
    const [selectAllChecked, setSelectAllChecked] = useState(false)
    const [contactsLoaded, setIsContactsLoaded] = useState(contacts?.length > 0)

    const recipientList = []
    if (pickUpContactEmail) {
        recipientList.push(pickUpContactEmail)
    }
    if (originContactEmail) {
        recipientList.push(originContactEmail)
    }
    const [recipients, setRecipients] = useState(recipientList)
    const [isLoading, setIsLoading] = useState(false)

    const { openSnackbar } = useSnackbarContext()

    const addRecipient = value => {
        changeField("contactsRecipients", null)
        changeField("newEmailRecipient", "")
        setRecipients(recipients.concat(value))
    }

    const selectAll = value => {
        attachments.forEach(attachment => {
            const key = attachment.isSystem
                ? attachment.attachmentFileType
                : attachment.attachmentFileName

            changeField(`documents["${key}"]`, value)
        })
    }

    useEffect(() => {
        let anyFalse = false

        attachments.forEach(attachment => {
            if (
                !formDocuments[attachment.attachmentFileName] &&
                !formDocuments[attachment.attachmentFileType]
            ) {
                anyFalse = true
            }
        })
        if (anyFalse) {
            setSelectAllChecked(false)
        } else {
            setSelectAllChecked(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDocuments])

    useEffect(() => {
        let current = true
        const fetchData = async current => {
            try {
                await requestContactAddresses()
                if (current) {
                    setIsContactsLoaded(true)
                }
            } catch (error) {}
        }

        if (!contactsLoaded) {
            fetchData(current)
        }

        return () => (current = false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const FormLabelProps = {
        classes: {
            label: classes.input__label,
        },
    }

    const sendEmail = async () => {
        if (formDocuments?.labels) {
            GAEvent("attachments", `share-labelsIncluded`)
        }
        if (formDocuments?.bol) {
            GAEvent("attachments", `share-bolIncluded`)
        }
        if (formDocuments?.labels && !formDocuments.bol) {
            GAEvent("attachments", `share-labelsIncludedAndNoBol`)
        }
        if (formDocuments?.bol && !formDocuments.labels) {
            GAEvent("attachments", `share-bolIncludedAndNoLabels`)
        }

        let selectedDocuments = []

        Object.keys(formDocuments).forEach(document => {
            if (formDocuments[document]) {
                let pushed = false
                for (let i = 0; i < attachments.length; i++) {
                    if (attachments[i].attachmentFileName === document) {
                        selectedDocuments.push({
                            filename: document,
                            type: attachments[i].attachmentFileType,
                        })
                        pushed = true
                        break
                    }
                }
                if (!pushed) {
                    selectedDocuments.push({
                        type: document,
                    })
                }
            }
        })

        let adjustedRecipients = [...recipients]

        if (newEmailRecipient && !newEmailRecipientError) {
            adjustedRecipients = [...adjustedRecipients, newEmailRecipient]
            setRecipients(recipients.concat(newEmailRecipient))
        }

        const payload = {
            selectedDocuments,
            emails: adjustedRecipients.map(recipient => ({
                emailAddress: recipient,
                language: shareLanguage,
            })),
        }

        changeField("contactsRecipients", null)
        changeField("newEmailRecipient", "")

        setIsLoading(true)
        try {
            await goFetch(
                `/documents/email/${shipmentId}`,
                {
                    method: "POST",
                    responseType: "blob",
                    data: payload,
                },
                true
            )
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.share__success"
                    defaultMessage="Documents successfully shared"
                />
            )
            onClose()
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.share__error"
                    defaultMessage="Unable to share documents"
                />
            )
        }
        setIsLoading(false)
    }

    const deleteRecipient = index => {
        const temp = [...recipients]

        temp.splice(index, 1)
        setRecipients(temp)
    }

    const disabled =
        (formDocuments &&
            !Object.keys(formDocuments).some(entry => formDocuments[entry])) ||
        (recipients?.length === 0 &&
            newEmailRecipient &&
            newEmailRecipientError) ||
        (recipients?.length === 0 && !newEmailRecipient)

    return (
        <Grid item container className={classes.paper}>
            {isLoading && <GlobalSpinner />}
            <Grid item container className={classes.header}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Typography variant="h4">
                        <FormattedMessage
                            id="documents.share__title"
                            defaultMessage="Share Attachments"
                        />
                    </Typography>
                    <IconButton onClick={() => onClose("share")}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container xs={4} alignContent="flex-start">
                <Grid
                    item
                    container
                    className={classes.attachmentRow__selectAll}
                >
                    <Field
                        name={"selectAll"}
                        label={
                            <FormattedMessage
                                id="documents.share__selectDocuments"
                                defaultMessage="Select Documents"
                            />
                        }
                        component={FormCheckbox}
                        FormLabelProps={FormLabelProps}
                        onChange={(e, value) => {
                            setSelectAllChecked(value)
                            selectAll(value)
                        }}
                        checked={selectAllChecked}
                    />
                </Grid>
                <Grid item container className={classes.attachments}>
                    {attachments.map(attachment => {
                        const categoryLabel = attachmentOptions.find(
                            entry =>
                                attachment.attachmentFileType === entry.value
                        )
                        const label = attachment?.userFileName ? (
                            attachment.userFileName
                        ) : categoryLabel ? (
                            <FormattedMessage {...categoryLabel.label} />
                        ) : (
                            attachment?.label
                        )
                        return (
                            <Grid
                                item
                                container
                                className={classes.attachmentRow}
                            >
                                <DocumentCheckBox
                                    name={`documents["${attachment.attachmentFileName ||
                                        attachment.attachmentFileType}"]`}
                                    label={label}
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid
                item
                container
                direction="column"
                justify="space-between"
                xs={8}
                className={classes.recipients__container}
            >
                <Grid item container alignContent="flex-start">
                    <Grid
                        item
                        container
                        className={classes.addRecipients__title}
                    >
                        <Typography variant="title">
                            <FormattedMessage
                                id="documents.share__recipients"
                                defaultMessage="Add Recipients"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={10}>
                        <Field
                            id="addUser__addNewEmail"
                            component={FormField}
                            name="newEmailRecipient"
                            label={
                                <FormattedMessage
                                    id="locations.addRecipients__addNewEmail"
                                    defaultMessage="Add New Email Address"
                                />
                            }
                            onKeyPress={e => {
                                if (
                                    e.key === "Enter" &&
                                    !newEmailRecipientError
                                ) {
                                    addRecipient(e.target.value)
                                }
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        justify="center"
                        alignItems="center"
                    >
                        <IconButton
                            aria-label="Add"
                            onClick={() => {
                                addRecipient(newEmailRecipient)
                            }}
                            disabled={
                                !newEmailRecipient || newEmailRecipientError
                            }
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                    <Grid item container>
                        <Typography className={classes.or__text}>
                            <FormattedMessage
                                id="generalTerms__or"
                                defaultMessage="Or"
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.contactsSelect}
                        xs={12}
                    >
                        <Field
                            className={classes.contactsSelect}
                            component={FormSelectAutocomplete}
                            name="contactsRecipients"
                            label={
                                <FormattedMessage
                                    id="locations.addRecipients__selectFromContact"
                                    defaultMessage="Select From Contact"
                                />
                            }
                            isLoading={!contactsLoaded}
                            options={contacts}
                            onChange={(e, value) => addRecipient(value)}
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <Grid item container>
                            <Field
                                name="language"
                                label={
                                    <FormattedMessage
                                        id="userPreferences.generalInformation__language"
                                        defaultMessage="Language"
                                    />
                                }
                                component={FormSelect}
                                className={classes.textField}
                                options={preferenceLanguageOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container xs={8}>
                        <Grid
                            item
                            container
                            className={classes.recipient__chips}
                        >
                            {recipients.map((recipient, index) => (
                                <Chip
                                    tabIndex={-1}
                                    avatar={
                                        <Avatar
                                            className={classes.chip__avatar}
                                        >
                                            <EmailIcon />
                                        </Avatar>
                                    }
                                    label={recipient}
                                    className={classes.chip}
                                    deleteIcon={<CancelIcon />}
                                    onDelete={() => {
                                        deleteRecipient(index)
                                    }}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    className={classes.actions}
                    justify="flex-end"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={values => sendEmail(values)}
                        disabled={disabled}
                    >
                        <FormattedMessage
                            id="documents.share__send"
                            defaultMessage="Send"
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

const validation = combineValidators({
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = (state, props) => {
    const contacts = []
    const { addresses = [] } = state?.addressBook
    const pickUpContactEmail =
        state?.shipment?.list[props.shipmentId]?.shipment?.origin?.appointment
            ?.email?.email_address

    addresses.forEach(contact => {
        const contactInfo = contact?.contact
        contacts.push({
            value: contactInfo?.email?.emailAddress || "",
            label: contactInfo?.name,
        })
    })
    return {
        pickUpContactEmail,
        contacts,
        newEmailRecipient:
            state?.form?.shareAttachments?.values?.newEmailRecipient,
        newEmailRecipientError:
            state?.form?.shareAttachments?.syncErrors?.newEmailRecipient,
        contactsRecipients:
            state?.form?.shareAttachments?.values?.contactsRecipients,
        formDocuments: state?.form?.shareAttachments?.values?.documents,
        originContactEmail:
            state?.shipment?.list[props.shipmentId]?.shipment?.origin?.contact
                ?.email?.email_address,
        shareLanguage: state?.form?.shareAttachments?.values?.language,
    }
}

const mapDispatchToProps = dispatch => ({
    requestContactAddresses: () => dispatch(requestContactAddresses()),
    changeField: (field, value) =>
        dispatch(change("shareAttachments", field, value)),
    GAEvent: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export const ShareAttachments = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        reduxForm({
            form: "shareAttachments", // a unique identifier for this form
            enableReinitialize: true,
            validate: validation,
        })(ShareAttachmentsPresentation)
    )
)
