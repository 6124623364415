import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Modal, withStyles, Grid, Button, Typography } from "@material-ui/core"
import { reloadApp } from "../../misc"
import { logOut } from "../../actions/authorization"

const styles = theme => ({
    modal__container: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: "500px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
})

export const ReloadNotificationPresentation = ({
    type,
    isOpen,
    onClick,
    classes,
}) => (
    <Modal open>
        <div
            className={classes.modal__container}
            onClick={e => e.stopPropagation()}
        >
            <Grid container justify="space-between" alignItems="center">
                <Grid
                    item
                    container
                    xs={9}
                    alignItems="center"
                    justify="center"
                >
                    <Typography variant="subheading" color="primary">
                        {type === "inactivity"
                            ? "Your Session Has Expired"
                            : "There's a new app version"}
                    </Typography>
                    <Typography variant="caption">
                        {
                            "Any confirmed transactions are saved, but you'll need to restart any searches or unfinished transactions."
                        }
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={3}
                    alignItems="center"
                    justify="flex-end"
                >
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onClick()}
                    >
                        Reload
                    </Button>
                </Grid>
            </Grid>
        </div>
    </Modal>
)

ReloadNotificationPresentation.propTypes = {
    type: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
}

const logOutDispatchToProps = (dispatch, props) => ({
    onClick: () => {
        dispatch(logOut())
        reloadApp()
    },
})

export const ReloadNotificationContainer = withStyles(styles)(
    connect(undefined, logOutDispatchToProps)(ReloadNotificationPresentation)
)
