import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { Button } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import { FormattedMessage } from "react-intl"

const styles = {
    description: {
        width: "40%",
    },
    section__title: {
        textDecoration: "underline",
    },
    card: {
        width: "100%",
    },
    topTableRow: {
        borderTop: "solid 1px #D4D4D4",
    },
}

const TradeDocumentsCard = props => {
    const { classes, history, shipmentId } = props

    const documentRedirect = documentType => {
        history.push({
            pathname: `/track/${shipmentId}/document/${documentType}`,
        })
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="title">
                        <FormattedMessage
                            id="orderDetails__createTradeDocument"
                            defaultMessage="Create Trade Document"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                <Grid item container spacing={16}>
                    <Grid item justify="flex-start">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                documentRedirect("commercial-invoice")
                            }
                        >
                            <FormattedMessage
                                id="orderDetails__generateCommercialInvoice"
                                defaultMessage="Generate Commercial Invoice"
                            />
                        </Button>
                    </Grid>
                    <Grid item justify="flex-start">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                documentRedirect("certificate-of-origin")
                            }
                        >
                            <FormattedMessage
                                id="orderDetails__generateCertificateOfOrigin"
                                defaultMessage="Generate Certificate of Origin"
                            />
                        </Button>
                    </Grid>
                    <Grid item justify="flex-start">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => documentRedirect("usmca")}
                        >
                            <FormattedMessage
                                id="orderDetails__generateUSMCA"
                                defaultMessage="Generate USMCA"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default withStyles(styles)(TradeDocumentsCard)
