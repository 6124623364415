import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../../form/form-field"
import normalizePhone from "../../../util/normalizePhone"

export default function HazMatForm({ country }) {
    return (
        <Grid item container>
            <Grid item container justify="center">
                <Typography variant="subheading">
                    <FormattedMessage
                        id="orderDetails.hazmat__title"
                        defaultMessage="HazMat Information"
                    />
                </Typography>
            </Grid>
            <Grid item container>
                <Grid item container xs={6}>
                    <Field
                        name="hazmatEmergency.name"
                        label={
                            <FormattedMessage
                                id="generalTerms__contactName"
                                defaultMessage="Contact Name"
                            />
                        }
                        required
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={4}>
                    <Field
                        name="hazmatEmergency.phone.phone_number"
                        label={
                            <FormattedMessage
                                id="generalTerms__contactPhone"
                                defaultMessage="Contact Phone"
                            />
                        }
                        required
                        component={FormField}
                        normalize={normalizePhone(country)}
                    />
                </Grid>
                <Grid item container xs={2}>
                    <Field
                        name="hazmatEmergency.phone.extension"
                        label={
                            <FormattedMessage
                                id="generalTerms__extension"
                                defaultMessage="Extension"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Field
                    name="hazmatEmergency.email"
                    label={
                        <FormattedMessage
                            id="generalTerms__contactEmail"
                            defaultMessage="Contact Email"
                        />
                    }
                    required
                    component={FormField}
                />
            </Grid>
        </Grid>
    )
}
