import React, { Component } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { MuiPickersUtilsProvider } from "material-ui-pickers"
import MomentUtils from "material-ui-pickers/utils/moment-utils"
import QuotesPage from "./components/quotesPage"
import BookShipment from "./components/redesign/bookShipment"
import { connect } from "react-redux"
import moment from "moment"
import "moment/locale/fr-ca"
import "moment/locale/es"
import { SignUpForm, UserPreferences } from "./components/user"
import Locations from "./components/locations"
import AddLocation from "./components/locations/addLocation"
import QuickQuotesPage from "./components/quickQuote"
import { TrackContainer } from "./components/track"
import { DashboardContainer } from "./components/dashboard"
import { AuthorizationContainer } from "./components/authorization"
import {
    SignupRoute,
    PrivateRoute,
    CatchAllRoute,
    LandingRoute,
} from "./components/route"
import {
    DeploymentReloadContainer,
    InactivityTimer,
    Modal,
    FooterRow,
} from "./components/util"
import { NavAppBar } from "./components/navigation"
import { AddressBook } from "./components/address"
import { IntercomContainer } from "./components/chat/intercom"
import { ItemPage } from "./components/item"
import { Statistics } from "./components/statistics"
import DiscountsTable from "./components/freightdirect/discounts/DiscountsTable"
import UnsavedChanges from "./components/locations/components/unsavedChanges"
import { TermsAndConditionsPanel } from "./components/util/terms-and-conditions"
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import { IntlProvider } from "react-intl"
import messages_frca from "./translations/fr-ca.json"
import messages_esmx from "./translations/es-mx.json"
import messages_enus from "./translations/en-us.json"
import { AttachmentsProvider } from "./context/providers/attachmentsProvider"
import { AttachmentsModalsWrapper } from "./context/components/attachmentModalsWrapper"
import { BookedShipmentProvider } from "./context/providers/BookedShipmentProvider"
import { BookedShipmentModalWrapper } from "./context/components/BookedShipmentModalWrapper"
import { SnackbarProvider } from "./context/providers/snackbarProvider"
import { SnackbarWrapper } from "./context/components/snackbar"
import MandatoryPrompt from "./components/mandatoryPrompt/MandatoryPrompt"
import { DocumentContainer } from "./components/documents/Index"
import history from "./actions/history"
import QuickRate from "./components/redesign/quickRate"
import { CustomsProfileProvider } from "./context/providers/CustomsProfileProvider"

export const theme = createMuiTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: "#4d148c",
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contast with palette.primary.main
        },
        secondary: {
            // light: '#0066ff',
            main: "#ff6200",
            // dark: will be calculated from palette.secondary.main,
            // contrastText: '#ffcc00',
        },
        // error: will use the default color
    },
    typography: {
        fontFamily: "Roboto",
    },
    overrides: {
        MuiTypography: {
            body2: {
                fontWeight: "600",
            },
            caption: {
                color: "rgba(0, 0, 0, 0.64)",
            },
        },
        MuiCollapse: {
            entered: {
                height: "auto",
                overflow: "visible",
            },
        },
        MuiButton: {
            root: {
                fontWeight: "700",
            },
        },
        MuiFormLabel: {
            root: {
                color: "#636363",
            },
        },
    },
})

class AppComponent extends Component {
    state = {
        unsavedChangesOpen: false,
        callback: null,
        message: null,
        LPLanguage: localStorage.getItem("language") || "en-us",
    }

    getUserConfirmation = (message, callback) => {
        callback(false)
        this.setState({ unsavedChangesOpen: true, message, callback })
    }

    handleClose = () => {
        this.setState({
            unsavedChangesOpen: false,
            message: null,
            callback: null,
        })
    }

    handleContinue = () => {
        this.state.callback(true)
        this.handleClose()
    }

    setLanguage = LPLanguage => {
        this.setState({ LPLanguage })
    }

    render() {
        const { unsavedChangesOpen, message, LPLanguage } = this.state
        const { language } = this.props
        const landingPageLanguage = localStorage.getItem("language")
        const adjustedLanguage = language || landingPageLanguage || "en-us"

        moment.locale(
            adjustedLanguage === "en-us"
                ? "en"
                : adjustedLanguage === "es-mx"
                ? "es"
                : adjustedLanguage
        )

        const messages = {
            "fr-ca": messages_frca,
            "es-mx": messages_esmx,
            "en-us": messages_enus,
        }

        return (
            <IntlProvider
                locale={adjustedLanguage}
                messages={messages[adjustedLanguage]}
            >
                <SnackbarProvider>
                    <AttachmentsProvider>
                        <CustomsProfileProvider>
                            <BookedShipmentProvider>
                                <UnsavedChanges
                                    message={message}
                                    handleClose={this.handleClose}
                                    handleContinue={() => this.handleContinue()}
                                    open={unsavedChangesOpen}
                                />
                                <Router
                                    basename="/"
                                    history={history}
                                    getUserConfirmation={
                                        this.getUserConfirmation
                                    }
                                >
                                    <div
                                        id="app"
                                        style={{
                                            "background-color": "#F2F2F2",
                                            display: "flex",
                                        }}
                                    >
                                        <MuiThemeProvider theme={theme}>
                                            <AuthorizationContainer
                                                setLanguage={this.setLanguage}
                                                language={LPLanguage}
                                            />
                                            <DeploymentReloadContainer />
                                            <InactivityTimer />
                                            <IntercomContainer
                                                appID="gvf3zf71"
                                                vertical_padding={80}
                                            />
                                            <MuiPickersUtilsProvider
                                                utils={MomentUtils}
                                            >
                                                <AttachmentsModalsWrapper />
                                                <BookedShipmentModalWrapper />
                                                <SnackbarWrapper />
                                                <MandatoryPrompt />
                                                <NavAppBar />
                                                <main
                                                    style={{
                                                        "padding-top": "64px",
                                                        flex: "1",
                                                        backgroundColor:
                                                            "rgb(242, 242, 242)",
                                                    }}
                                                >
                                                    <ToastContainer />
                                                    <Switch>
                                                        <LandingRoute
                                                            exact
                                                            path="/"
                                                        />
                                                        <SignupRoute
                                                            exact
                                                            path="/user/signup"
                                                            component={
                                                                SignUpForm
                                                            }
                                                        />
                                                        <Route
                                                            exact
                                                            path="/terms/:lang?"
                                                            render={props => (
                                                                <TermsAndConditionsPanel
                                                                    {...props}
                                                                    omitButtons
                                                                />
                                                            )}
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/home"
                                                            component={
                                                                DashboardContainer
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/rate/:shipmentId?/:mode?"
                                                            component={
                                                                QuotesPage
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/qrate/:shipmentId?"
                                                            component={
                                                                QuickQuotesPage
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            component={
                                                                QuotesPage
                                                            }
                                                            path="/rate/:shipmentId/:shipMode/:direction/:carrierCode/:alternateQuote?/book"
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            component={
                                                                BookShipment
                                                            }
                                                            path="/book"
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/book/:shipmentId?/:mode?"
                                                            component={
                                                                BookShipment
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            component={
                                                                BookShipment
                                                            }
                                                            path="/book/:shipmentId?"
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            component={
                                                                QuickRate
                                                            }
                                                            path="/quickRate"
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            component={
                                                                QuickRate
                                                            }
                                                            path="/quickRate/:shipmentId?"
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/track/:shipmentId?"
                                                            component={
                                                                TrackContainer
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/user"
                                                            component={
                                                                UserPreferences
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/locations"
                                                            component={
                                                                Locations
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/locations/add"
                                                            component={
                                                                AddLocation
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/address/:contactId?"
                                                            component={
                                                                AddressBook
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/items"
                                                            component={ItemPage}
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path="/statistics"
                                                            component={
                                                                Statistics
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                "/freightdirect/discounts"
                                                            }
                                                            component={
                                                                DiscountsTable
                                                            }
                                                        />
                                                        <PrivateRoute
                                                            exact
                                                            path={
                                                                "/track/:shipmentId/document/:documentType"
                                                            }
                                                            component={
                                                                DocumentContainer
                                                            }
                                                        />
                                                        <CatchAllRoute />
                                                    </Switch>
                                                    <Modal />
                                                </main>
                                            </MuiPickersUtilsProvider>
                                        </MuiThemeProvider>
                                        <div
                                            id="footer"
                                            style={{
                                                "background-color": "#F2F2F2",
                                            }}
                                        >
                                            <FooterRow />
                                        </div>
                                    </div>
                                </Router>
                            </BookedShipmentProvider>
                        </CustomsProfileProvider>
                    </AttachmentsProvider>
                </SnackbarProvider>
            </IntlProvider>
        )
    }
}

const mapStateToProps = state => {
    return {
        language:
            state?.form?.signup?.values?.preferences?.language ||
            state?.user?.profile?.preferences?.language,
    }
}

export const App = connect(mapStateToProps)(AppComponent)
