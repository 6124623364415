import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { MARKUP_FIELD_CHANGE } from "../actions/quote"
import { QUOTE_RESULT } from "../actions/quote-request"
import {
    CARRIER_LOAD,
    CARRIER_LOAD_ERROR,
    CARRIER_RESULT,
} from "../actions/carrier"
import {
    ACCESSORIALS_LOAD,
    ACCESSORIALS_LOAD_ERROR,
    ACCESSORIALS_RESULT,
    END_TIMER,
    REGISTER_REQUEST,
    RELEASE_REQUEST,
    START_TIMER,
    FLAG_NEW_VERSION_AVAILABLE,
} from "../actions"
import {
    BOL_DOCUMENT_ERROR,
    BOL_DOCUMENT_FETCH,
    BOL_DOCUMENT_RESULT,
    SHIPPING_LABEL_RESULT,
    SHIPPING_LABEL_FETCH,
    SHIPPING_LABEL_ERROR,
} from "../actions/modal"
import {
    TERMS_LOAD,
    TERMS_LOAD_ERROR,
    TERMS_RECEIVED,
    TERMS_VERSION_RECEIVED,
} from "../actions/terms"
import {
    IMPERSONATION_WARNING,
    IMPERSONATION_ENABLED,
} from "../actions/authorization"
import { addressModal } from "./address"
import { book } from "./book"
import { cpg } from "./cpg"
import { quotes } from "./quote"
import { shipment } from "./shipment"
import { chat } from "./chat"
import { user } from "./user"
import { modal } from "./modal"
import { share } from "./share"
import { alerts } from "./alerts"
import { shareStatus } from "./share-status"
import { search, searchItem } from "./search"
import { postalCode } from "./postal-code"
import { addressBook } from "./address-book"
import { discounts } from "./freightdirect"
import { redesign } from "./redesign"
import { dashboard } from "./dashboard"
import { authorization } from "./authorization"
import { error } from "./error"
import { item } from "./item"
import { email } from "./email-shipment-document"
import { emailImage } from "./email-image"
import { contact } from "./contact"
import { vagueCommodities } from "./vague-commodity"
import { get } from "lodash"
import {
    RESET_VALIDATION_SCROLL,
    SCROLLED_TO_VALIDATION,
} from "../actions/validation"
import { USER_DISAUTHENTICATED } from "../actions/user"

export const removeItemFromArray = (from, name, index) => ({
    ...from,
    [name]: from[name].filter((_, i) => i !== index),
})

export function fieldValidationReducer(state, action, form) {
    if (action.form !== form) return state
    return { ...state, validation: action.validation }
}

export function carriers(
    state = {
        isFetching: false,
        isLoaded: false,
        isError: false,
        carriers: {},
    },
    action
) {
    switch (action.type) {
        case CARRIER_LOAD:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
            })
        case CARRIER_RESULT:
            return Object.assign({}, state, {
                carriers: action.carriers,
                isFetching: false,
                isLoaded: true,
                isError: false,
            })
        case CARRIER_LOAD_ERROR:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
            })
        default:
            return state
    }
}

export function accessorials(
    state = {
        isFetching: false,
        isLoaded: false,
        list: {
            pickup: [],
            delivery: [],
            nonspecific: [],
            // TODO: this should be loaded from DB
            excessiveLength: [
                {
                    value: "ELS",
                    label: "Excessive Length Shipment",
                },
                {
                    value: "ELS12",
                    label: "Excessive Length, Over 12ft",
                },
                {
                    value: "ELS12_20",
                    label: "Excessive Length, 12 to 20ft",
                },
                {
                    value: "ELS12_24",
                    label: "Excessive Length, 12 to 24ft",
                },
                {
                    value: "ELS13_27",
                    label: "Excessive Length, 13 to 27ft",
                },
                {
                    value: "ELS14",
                    label: "Excessive Length, Over 14ft",
                },
                {
                    value: "ELS14_24",
                    label: "Excessive Length, 14 to 24ft",
                },
                {
                    value: "ELS14_26",
                    label: "Excessive Length, 14 to 26ft",
                },
                {
                    value: "ELS15",
                    label: "Excessive Length, Over 15ft",
                },
                {
                    value: "ELS15_27",
                    label: "Excessive Length, 15 to 27ft",
                },
                {
                    value: "ELS20",
                    label: "Excessive Length, Over 20ft",
                },
                {
                    value: "ELS20_28",
                    label: "Excessive Length, 20 to 28ft",
                },
                {
                    value: "ELS21",
                    label: "Excessive Length, Over 21ft",
                },
                {
                    value: "ELS24",
                    label: "Excessive Length, Over 24ft",
                },
                {
                    value: "ELS27",
                    label: "Excessive Length, Over 27ft",
                },
                {
                    value: "ELS28",
                    label: "Excessive Length, Over 28ft",
                },
                {
                    value: "ELS30",
                    label: "Excessive Length, Over 30ft",
                },
                {
                    value: "ELS8_12",
                    label: "Excessive Length, 8 to 12ft",
                },
            ],
            guaranteed: [
                {
                    value: "EXPEDITE17",
                    time: { hour: 17, minute: 0, second: 0 },
                },
                {
                    value: "EXPEDITEAM",
                    time: { hour: 12, minute: 0, second: 0 },
                },
                {
                    value: "GS8",
                    time: { hour: 8, minute: 0, second: 0 },
                },
                {
                    value: "GS9",
                    time: { hour: 9, minute: 0, second: 0 },
                },
                {
                    value: "GS10",
                    time: { hour: 10, minute: 0, second: 0 },
                },
                {
                    value: "GS11",
                    time: { hour: 11, minute: 0, second: 0 },
                },
                {
                    value: "GSAM",
                    time: { hour: 12, minute: 0, second: 0 },
                },
                {
                    value: "GS14",
                    time: { hour: 14, minute: 0, second: 0 },
                },
                {
                    value: "GS15",
                    time: { hour: 15, minute: 0, second: 0 },
                },
                {
                    value: "GS1530",
                    time: {
                        hour: 15,
                        minute: 30,
                        second: 0,
                    },
                },
                // NOTE: what time values should we use for these?
                {
                    value: "EXPEDITE",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GURWIN",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSSING",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSMUL",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GSFM",
                    time: { hour: 0, minute: 0, second: 0 },
                },
                {
                    value: "GUR",
                    time: { hour: 0, minute: 0, second: 0 },
                },
            ],
        },
    },
    action
) {
    switch (action.type) {
        case ACCESSORIALS_LOAD:
            return Object.assign({}, state, { isFetching: true })
        case ACCESSORIALS_RESULT:
            return Object.assign({}, state, {
                list: Object.assign(
                    {},
                    state.list,
                    action.accessorials.reduce((obj, element) => {
                        const innerObj = obj
                        if (!obj[element.category])
                            innerObj[element.category] = []
                        innerObj[element.category].push({
                            label: element.name,
                            value: element.key,
                        })
                        return innerObj
                    }, {})
                ),
                isFetching: false,
                isLoaded: true,
            })
        case ACCESSORIALS_LOAD_ERROR:
            return Object.assign({}, state, { isFetching: false })
        default:
            return state
    }
}

export function identifiers(
    state = {
        bolNumber: "",
        referenceNumber: "",
        proNumber: "",
        orderNumber: "",
        internalTrackingNumber: "",
    },
    action
) {
    switch (action.type) {
        case QUOTE_RESULT:
            return { ...state, ...action.identifiers }
        default:
            return state
    }
}

export function markup(
    state = { markupAmount: "", markupType: "markupAmount" },
    action
) {
    switch (action.type) {
        case QUOTE_RESULT:
            return {
                markupAmount: get(action, "search.markupAmount") || 0,
                markupType:
                    get(action, "search.markupType") || "markupPercentage",
            }
        case MARKUP_FIELD_CHANGE:
            return { ...state, [action.field]: action.value }
        default:
            return state
    }
}

export function bolDocument(state = {}, action) {
    switch (action.type) {
        case BOL_DOCUMENT_RESULT:
            return {
                ...state,
                [action.shipmentId]: {
                    data: action.data,
                    isError: false,
                    isFetching: false,
                },
            }
        case BOL_DOCUMENT_ERROR:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isError: true,
                    isFetching: false,
                },
            }
        case BOL_DOCUMENT_FETCH:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isFetching: true,
                },
            }
        default:
            return state
    }
}

export function shippingLabelDocument(state = {}, action) {
    switch (action.type) {
        case SHIPPING_LABEL_RESULT:
            return {
                ...state,
                [action.shipmentId]: {
                    data: action.data,
                    isError: false,
                    isFetching: false,
                },
            }
        case SHIPPING_LABEL_ERROR:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isError: true,
                    isFetching: false,
                },
            }
        case SHIPPING_LABEL_FETCH:
            return {
                ...state,
                [action.shipmentId]: {
                    ...state[action.shipmentId],
                    isFetching: true,
                },
            }
        default:
            return state
    }
}

export function terms(
    state = {
        version: "",
        description: "",
        isFetching: false,
        isLoaded: false,
        isError: false,
    },
    action
) {
    switch (action.type) {
        case TERMS_VERSION_RECEIVED:
            return { ...state, version: action.version }
        case TERMS_LOAD:
            return { ...state, isFetching: true }
        case TERMS_LOAD_ERROR:
            return { ...state, isFetching: false, isError: true }
        case TERMS_RECEIVED:
            return {
                ...state,
                description: action.description,
                version: action.version,
                language: action.language,
                isFetching: false,
                isError: false,
                isLoaded: true,
            }
        default:
            return state
    }
}

export function validation(state = { needScroll: true }, action) {
    switch (action.type) {
        case RESET_VALIDATION_SCROLL:
            return { needScroll: true }
        case SCROLLED_TO_VALIDATION:
            return { needScroll: false }
        default:
            return state
    }
}

export function timers(state = {}, action) {
    switch (action.type) {
        case START_TIMER:
            return { ...state, [action.id]: action.time }
        case END_TIMER:
            delete state[action.id]
            return { ...state }
        default:
            return state
    }
}

export function requests(state = {}, action) {
    switch (action.type) {
        case REGISTER_REQUEST:
            return { ...state, [action.id]: action.emitter }
        case RELEASE_REQUEST:
            delete state[action.id]
            return { ...state }
        default:
            return state
    }
}

export function newVersionAvailable(state = false, action) {
    switch (action.type) {
        case FLAG_NEW_VERSION_AVAILABLE:
            return true
        default:
            return state
    }
}

export function impersonation(state = { enabled: false }, action) {
    switch (action.type) {
        case USER_DISAUTHENTICATED:
            return { enabled: false }
        case IMPERSONATION_WARNING:
            return { ...state, email: action.email }
        case IMPERSONATION_ENABLED:
            return { ...state, enabled: action.enabled }
        default:
            return state
    }
}

const dashboardPersistConfig = {
    key: "dashboard",
    storage,
    whitelist: ["filters", "dashboardFilters"],
}

export const rootReducer = combineReducers({
    quotes,
    search,
    searchItem,
    carriers,
    cpg,
    user,
    shipment,
    book,
    chat,
    modal,
    accessorials,
    share,
    alerts,
    shareStatus,
    dashboard: persistReducer(dashboardPersistConfig, dashboard),
    identifiers,
    addressModal,
    postalCode,
    bolDocument,
    redesign,
    impersonation,
    shippingLabelDocument,
    addressBook,
    discounts,
    terms,
    authorization,
    markup,
    error,
    item,
    validation,
    email,
    emailImage,
    contact,
    timers,
    requests,
    newVersionAvailable,
    vagueCommodities,
    form: formReducer,
})
