import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormControl } from "react-bootstrap"
import { connect } from "react-redux"
import { FormattedMessage } from "react-intl"
import { searchContacts } from "../../../actions/contact"

const EmailContactSuggestions = ({ suggestions, onSelect }) => (
    <div className="suggestion-box">
        <div className="column">
            <div>
                <div className="row">
                    <FormattedMessage
                        id="generalTerms__suggestions"
                        defaultMessage="Suggestions"
                    />
                </div>
                {suggestions.map((item, i) => (
                    <div
                        className="suggestion-item"
                        onClick={() => onSelect(item.contact)}
                        key={i}
                        role="button"
                        tabIndex="-1"
                    >
                        <span>{item.contact.name}</span>
                        <br />
                        <span>{item.contact.email.emailAddress}</span>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    </div>
)

EmailContactSuggestions.propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSelect: PropTypes.func.isRequired,
}

class InputFieldPresentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOverlay: false,
        }
        this.input = null
        this.clickedInside = false
        this.clickTimeout = null
        this.changed = false
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimeout)
    }

    handleContainerMouseDown() {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputFocus() {
        this.setState({
            showOverlay: true,
        })
    }

    handleInputBlur() {
        const showOverlay = this.clickedInside

        this.setState({
            showOverlay,
        })

        // Force input's focus if blur event was caused by clicking on dropdown
        if (showOverlay) {
            this.input.focus()
        }
    }

    handleSelect(contact) {
        this.input.blur()
        this.setState({ showOverlay: false })
        this.props.onSelect(contact)
    }

    render() {
        const {
            name,
            className,
            placeholder,
            value,
            tabIndex,
            autoFocus,
            disabled,
            onChange,
            contacts,
            onKeyDown,
            performContactSearch,
        } = this.props
        return (
            <div
                className={className}
                tabIndex="-1"
                role="button"
                onMouseDown={() => this.handleContainerMouseDown()}
            >
                <FormControl
                    placeholder={placeholder}
                    value={value}
                    inputRef={el => {
                        this.input = el
                    }}
                    onChange={e => {
                        onChange(e)
                        performContactSearch(e.target.value)
                    }}
                    tabIndex={tabIndex}
                    autoFocus={autoFocus}
                    onFocus={() => this.handleInputFocus()}
                    onBlur={() => this.handleInputBlur()}
                    onKeyDown={onKeyDown}
                    disabled={disabled}
                    name={name}
                />
                {this.state.showOverlay && contacts.length > 0 && (
                    <div>
                        <EmailContactSuggestions
                            suggestions={contacts}
                            onSelect={contact => this.handleSelect(contact)}
                        />
                    </div>
                )}
            </div>
        )
    }
}

InputFieldPresentation.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    performContactSearch: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    contacts: PropTypes.array,
    className: PropTypes.string,
    name: PropTypes.string,
}

InputFieldPresentation.defaultProps = {
    tabIndex: 0,
    autoFocus: false,
    disabled: false,
    contacts: [],
    className: "",
    name: "",
    placeholder: "",
    onKeyDown: () => {},
}

const mapStateToProps = (state, props) => ({
    contacts: state.contact.search[`email_${props.field}`][props.value],
})

const mapDispatchToProps = (dispatch, props) => ({
    performContactSearch: value =>
        dispatch(searchContacts(value, `email_${props.field}`)),
})

export const ContactSmart = connect(
    mapStateToProps,
    mapDispatchToProps
)(InputFieldPresentation)
