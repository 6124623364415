import { goFetch } from "../http"

export const FETCH_ALERTS = "FETCH_ALERTS"

export function dismissAlert(shipmentId, alertId) {
    return async () => {
        try {
            goFetch(
                `/alert/${shipmentId}/event/${alertId}/dismiss`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
        } catch (error) {}
    }
}

export function fetchAlerts() {
    return async dispatch => {
        try {
            const { data: alerts, status } = await goFetch(
                "/alert",
                {
                    method: "GET",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            dispatch({
                type: FETCH_ALERTS,
                payload: status === 200 ? alerts : [],
            })
        } catch (error) {}
    }
}
