import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    chip: {
        margin: "20px 10px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    section__title: {
        textDecoration: "underline",
    },
})

const AccessorialsDisplay = ({
    classes,
    accessorials: { pickup, delivery },
    isFreightDirect,
    serviceLevel,
    isFreightDirectReturns,
    isInBondShipment,
    id,
}) => (
    <Grid item container>
        {!isFreightDirectReturns && (
            <Grid container>
                <Grid item container className={classes.title__container}>
                    <Typography
                        variant="subheading"
                        className={classes.section__title}
                        id={`${id}__pickup`}
                    >
                        <FormattedMessage
                            id="dashboard__pickupAccessorials"
                            defaultMessage="Pickup Accessorials"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    {pickup.map((label, i) => (
                        <Chip
                            id={`${id}__${i}__pickup__${label}`}
                            label={label}
                            key={i}
                            className={classes.chip}
                            tabIndex={-1}
                        />
                    ))}
                </Grid>
                {isInBondShipment && (
                    <Chip
                        id={`${id}__isInBondShipment__pickup`}
                        tabIndex={-1}
                        label={
                            <FormattedMessage
                                id="accessorials.panel__freightMovingInBond"
                                defaultMessage="Freight Moving In-Bond"
                            />
                        }
                        className={classes.chip}
                        backgroundColor="#F4F4F4"
                    />
                )}
            </Grid>
        )}
        {!isFreightDirect && !isFreightDirectReturns && (
            <Grid container>
                <Grid item container className={classes.title__container}>
                    <Typography
                        variant="subheading"
                        className={classes.section__title}
                        id={`${id}__delivery`}
                    >
                        <FormattedMessage
                            id="dashboard__deliveryAccessorials"
                            defaultMessage="Delivery Accessorials"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    {delivery.map((label, i) => (
                        <Chip
                            id={`${id}__${i}__delivery__${label}`}
                            label={label}
                            key={i}
                            className={classes.chip}
                            tabIndex={-1}
                        />
                    ))}
                </Grid>
                {isInBondShipment && (
                    <Chip
                        id={`${id}__isInBondShipment__delivery`}
                        tabIndex={-1}
                        label={
                            <FormattedMessage
                                id="accessorials.panel__freightMovingInBond"
                                defaultMessage="Freight Moving In-Bond"
                            />
                        }
                        className={classes.chip}
                        backgroundColor="#F4F4F4"
                    />
                )}
            </Grid>
        )}
        {(isFreightDirect || isFreightDirectReturns) && serviceLevel && (
            <Grid item container>
                <Grid item container className={classes.title__container}>
                    <Typography
                        variant="subheading"
                        className={classes.section__title}
                    >
                        <FormattedMessage
                            id="dashboard__deliveryServiceLevel"
                            defaultMessage="Delivery Service Level"
                        />
                    </Typography>
                </Grid>
                <Grid item container>
                    <Chip
                        id={`${id}__serviceLevel`}
                        label={serviceLevel?.description || serviceLevel?.code}
                        key={"chip__serviceLevel"}
                        className={classes.chip}
                        tabIndex={-1}
                    />
                </Grid>
            </Grid>
        )}
    </Grid>
)

export default withStyles(styles)(AccessorialsDisplay)
