import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import IconButton from "@material-ui/core/IconButton"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    pagination: {
        "margin-top": "8px",
        display: "flex",
        "min-width": "870px",
        "align-items": "center",
        "justify-content": "flex-end",
    },
    pagination__item: {
        margin: "5px 15px",
    },
    pagination__arow: {
        margin: "5px 5px",
    },
})

const defaultButton = props => (
    <IconButton {...props}>{props.children}</IconButton>
)

defaultButton.propTypes = {
    children: PropTypes.node.isRequired,
}

export const NavigationSection = ({
    Component,
    getIcon,
    disabled,
    changePage,
    newPage,
}) => (
    <div>
        <Component
            onClick={() => {
                if (disabled) return
                changePage(newPage)
            }}
            disabled={disabled}
        >
            {getIcon()}
        </Component>
    </div>
)

NavigationSection.propTypes = {
    Component: PropTypes.func.isRequired,
    getIcon: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    changePage: PropTypes.func.isRequired,
    newPage: PropTypes.number.isRequired,
}

export const PageSizeOptions = ({ options, pageSize, onChange }) => (
    <span className="select-wrap -pageSizeOptions">
        <Select
            onChange={e => onChange(Number(e.target.value))}
            value={pageSize}
        >
            {options.map((option, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <MenuItem key={i} value={option}>
                    <Typography>
                        <FormattedMessage
                            id="pagination__perPage"
                            defaultMessage="{option} per page"
                            values={{ option }}
                        />
                    </Typography>
                </MenuItem>
            ))}
        </Select>
    </span>
)

PageSizeOptions.propTypes = {
    options: PropTypes.arrayOf(PropTypes.number).isRequired,
    pageSize: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

export const PageInfo = ({ currentPage, totalPages }) => (
    <span className="pageInfo">
        <Typography variant="caption">
            <FormattedMessage
                id="pagination__pageNumber"
                defaultMessage="Page {currentPage} of {totalPages}"
                values={{ currentPage, totalPages: totalPages || 1 }}
            />
        </Typography>
    </span>
)

PageInfo.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
}

class PaginationControls extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: this.getSafePage(this.props.page),
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ page: nextProps.page })
    }

    getSafePage(page) {
        if (isNaN(page)) {
            page = this.props.page
        }
        const pages = Math.ceil(this.props.totalElements / this.props.pageSize)
        return Math.min(Math.max(page, 1), pages)
    }

    changePage(page) {
        page = this.getSafePage(page)
        this.setState({ page })
        if (this.props.page !== page) {
            this.props.onPageChange(page)
        }
    }

    render() {
        const pages = Math.ceil(this.props.totalElements / this.props.pageSize)
        const canPrevious = this.state.page - 1 >= 1
        const canNext = this.state.page + 1 <= pages

        const {
            pageSize,
            PreviousComponent,
            NextComponent,
            showPageSizeOptions,
            pageSizeOptions,
            onPageSizeChange,
            classes,
        } = this.props

        return (
            <Grid item container justify="flex-end">
                <div className={classes.pagination}>
                    <div className={classes.pagination__item}>
                        {showPageSizeOptions && (
                            <PageSizeOptions
                                pageSize={pageSize}
                                options={pageSizeOptions}
                                onChange={onPageSizeChange}
                            />
                        )}
                    </div>
                    <div className={classes.pagination__item}>
                        <PageInfo
                            currentPage={this.state.page}
                            totalPages={pages}
                        />
                    </div>
                    <div className="navigation-previous">
                        <NavigationSection
                            Component={PreviousComponent}
                            disabled={!canPrevious}
                            getIcon={() => <KeyboardArrowLeft />}
                            changePage={page => this.changePage(page)}
                            newPage={this.state.page - 1}
                        />
                    </div>
                    <div className="navigation-next">
                        <NavigationSection
                            Component={NextComponent}
                            disabled={!canNext}
                            getIcon={() => <KeyboardArrowRight />}
                            changePage={page => this.changePage(page)}
                            newPage={this.state.page + 1}
                        />
                    </div>
                </div>
            </Grid>
        )
    }
}

PaginationControls.propTypes = {
    page: PropTypes.number.isRequired,
    totalElements: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onPageSizeChange: PropTypes.func,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    showPageSizeOptions: PropTypes.bool,
    PreviousComponent: PropTypes.func,
    NextComponent: PropTypes.func,
    classes: PropTypes.object.isRequired,
}

PaginationControls.defaultProps = {
    showPageSizeOptions: true,
    onPageSizeChange: undefined,
    pageSizeOptions: [5, 10, 20, 25, 50, 100],
    PreviousComponent: defaultButton,
    NextComponent: defaultButton,
}

export default withStyles(styles)(PaginationControls)
