import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Spinner from "../util/spinner"
import { requestItems } from "../../actions/item"
import { FormattedMessage } from "react-intl"
import { packageTypesMessages } from "../item/line-item"

const styles = theme => ({
    items__container: {
        maxHeight: "380px",
        overflow: "scroll",
    },
    items__container__freightDirect: {
        maxHeight: "768px",
        overflow: "scroll",
    },
    suggestion__item: {
        cursor: "pointer",
        padding: "3px 0",
        "&:hover": {
            "background-color": theme.palette.primary.main,
        },
        "&:active": {
            "background-color": theme.palette.primary.dark,
        },
        "&:hover $suggestion__line": {
            color: "white",
        },
        outline: "none",
        backgroundColor: "white",
        margin: "3px 0",
        minHeight: "46px",
    },
    suggestion__header: {
        padding: "3px 0px",
        outline: "none",
    },
    suggestion__line: {
        margin: "0 7px",
        outline: "none",
        overflow: "auto",
    },
})

class FavoriteItems extends React.Component {
    componentDidMount() {
        this.props.loadItems()
    }

    render() {
        const {
            classes,
            items,
            isLoaded,
            onItemClick,
            isFreightDirect,
        } = this.props

        if (!isLoaded) {
            return <Spinner isGlobal={false} />
        }
        return (
            <Grid container>
                {items && items.length > 1 ? (
                    <Grid
                        item
                        container
                        direction="row"
                        xs={12}
                        justify="flex-start"
                        className={classes.suggestion__header}
                        tabIndex="-1"
                    >
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="flex-start"
                            xs={4}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__description"
                                    defaultMessage="Description"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="center"
                            xs={1}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__class"
                                    defaultMessage="Class"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="center"
                            xs={1}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__count"
                                    defaultMessage="Count"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="center"
                            xs={2}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__totalWeight"
                                    defaultMessage="Total Weight"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="center"
                            xs={2}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__packageType"
                                    defaultMessage="Package Type"
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="center"
                            xs={2}
                        >
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="items__hazMat"
                                    defaultMessage="HazMat"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <Typography color="primary" variant="subheading">
                        <FormattedMessage
                            id="getRates.favoriteItems__noItems"
                            defaultMessage="Items that are saved to your account will appear here to make it faster and easier to get rates. All items that you use to get rates will be saved automatically, but you can also add them manually from the items page."
                        />
                    </Typography>
                )}
                <Grid
                    item
                    container
                    xs={12}
                    className={
                        !isFreightDirect
                            ? classes.items__container
                            : classes.items__container__freightDirect
                    }
                >
                    {items.slice(1).map((item, i) => (
                        <Grid
                            id={`getRates__favoriteItems__${i}`}
                            item
                            container
                            direction="row"
                            justify="flex-start"
                            className={classes.suggestion__item}
                            key={i}
                            role="button"
                            tabIndex="-1"
                            onClick={() => onItemClick(item)}
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="flex-start"
                                xs={4}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                >
                                    {item.description}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="center"
                                xs={1}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                >
                                    {item.freightClass}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="center"
                                xs={1}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                >
                                    {item.count}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="center"
                                xs={2}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                >
                                    {item.isIndividualWeight
                                        ? item.weight * item.count
                                        : item.weight}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="center"
                                xs={2}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                >
                                    {packageTypesMessages[item.packageType] ? (
                                        <FormattedMessage
                                            {...packageTypesMessages[
                                                item.packageType
                                            ]}
                                        />
                                    ) : (
                                        item.packageType
                                    )}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                alignItems="center"
                                justify="center"
                                xs={2}
                            >
                                <Typography
                                    className={classes.suggestion__line}
                                    color="secondary"
                                >
                                    {item.isHazMat ? (
                                        <FormattedMessage
                                            id="items__hazMat"
                                            defaultMessage="HazMat"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        )
    }
}

FavoriteItems.propTypes = {
    classes: PropTypes.object.isRequired,
    addRow: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    items: state.item.elements,
    isLoaded: state.item.isLoaded,
})

const mapDispatchToProps = dispatch => ({
    loadItems: () => dispatch(requestItems()),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(FavoriteItems)
)
