import React, { Fragment } from "react"
import { Grid, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    copy__container: {
        "& > *": {
            padding: "5px",
            borderBottom: "solid 1px #D2D2D2",
        },
    },
})

function RoleCopy(props) {
    const { currentRole, classes, isRequested } = props

    return (
        <Fragment>
            {currentRole === "placeholder" && isRequested && (
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.copy__container}
                >
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__grantOrDeny"
                            defaultMessage="Grant or Deny access to this user - you must choose a role for them from the dropdown menu before granting access"
                        />
                    </Typography>
                </Grid>
            )}
            {currentRole === "owner" && (
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.copy__container}
                >
                    <Typography>
                        <FormattedMessage
                            id="locations.roleCopy__ownerHeading"
                            defaultMessage="An Owner is able to: "
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner1"
                            defaultMessage="Add and manage carriers for this location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner2"
                            defaultMessage="Add new users to the location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner3"
                            defaultMessage="Set views/permissions per user"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner4"
                            defaultMessage="Change users' roles"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner5"
                            defaultMessage="Suspend users' access"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner6"
                            defaultMessage="Fully remove location access for company users or third party users"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__owner7"
                            defaultMessage="Receive alerts/emails from users requesting access to this location"
                        />
                    </Typography>
                </Grid>
            )}
            {currentRole === "member" && (
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.copy__container}
                >
                    <Typography>
                        <FormattedMessage
                            id="locations.roleCopy__companyUserHeading"
                            defaultMessage="A Company User will: "
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__companyUser1"
                            defaultMessage="See carriers accociated with this location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__companyUser2"
                            defaultMessage="See their views/permissions"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__companyUser3"
                            defaultMessage="Be able to adjust views/permissions that an Owner role allows"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__companyUser4"
                            defaultMessage="Not see users granted access to this location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__companyUser5"
                            defaultMessage="Not be able to manage carriers"
                        />
                    </Typography>
                </Grid>
            )}
            {currentRole === "thirdParty" && (
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.copy__container}
                >
                    <Typography>
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUserHeading"
                            defaultMessage="A Third Party User will: "
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUser1"
                            defaultMessage="See carriers accociated with this location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUser2"
                            defaultMessage="See their views/permissions"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUser3"
                            defaultMessage="Be able to adjust views/permissions that an Owner role allows"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUser4"
                            defaultMessage="Not see users granted access to this location"
                        />
                    </Typography>
                    <Typography variant="caption">
                        <FormattedMessage
                            id="locations.roleCopy__thirdPartyUser5"
                            defaultMessage="Not be able to manage carriers"
                        />
                    </Typography>
                </Grid>
            )}
        </Fragment>
    )
}

export default withStyles(styles)(RoleCopy)
