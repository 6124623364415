import React, { useState, useEffect } from "react"
import { Grid } from "@material-ui/core"
import { connect } from "react-redux"
import { goFetch } from "../../http"
import history from "../../actions/history"
import CertificateOfOriginForm from "./certificateOfOrigin/CertificateOfOriginForm"
import CommercialInvoiceForm from "./commercialInvoice/CommercialInvoiceForm"
import TitleCard from "./TitleCard"
import { defineMessages, useIntl } from "react-intl"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"
import { documentSaveSuccess } from "../../messages/confirmation-constants"
import { documentSaveError } from "../../messages/error-constants"
import { attachmentOptionsMessages } from "../../misc"
import { USMCAForm } from "./USMCA/USMCAForm"
import { useCustomsProfileContext } from "../../context/providers/CustomsProfileProvider"

const DocumentForm = props => {
    const {
        bol,
        trackingNumber,
        documentType,
        shipmentId,
        formTitle,
        locationId,
    } = props

    const { setLocationId } = useCustomsProfileContext()

    const [previousDocument, setPreviousDocumentValues] = useState(null)

    const intl = useIntl()
    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        setLocationId(locationId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId, locationId])

    useEffect(() => {
        if (shipmentId && documentType) {
            const fetchPreviousDocumentValues = async () => {
                setPreviousDocumentValues(null)
                try {
                    const { data } = await goFetch(
                        `documents/versioned/${documentType?.urlPrefix}/${shipmentId}`,
                        { method: "GET" },
                        true
                    )
                    setPreviousDocumentValues(data)
                } catch (error) {
                    return error
                }
            }
            fetchPreviousDocumentValues()
        }
    }, [shipmentId, documentType, setPreviousDocumentValues])

    const submit = async values => {
        if (!documentType) return

        const displayName = intl.formatMessage(
            attachmentOptionsMessages?.[documentType?.documentCategory]
        )
        try {
            await goFetch(
                `/documents/generated/${documentType?.urlPrefix}`,
                {
                    data: values,
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            openSnackbar("success", documentSaveSuccess(displayName), 4000)
            history.replace({ pathname: `/track/${shipmentId}` })
        } catch (error) {
            openSnackbar("error", documentSaveError(displayName), 4000)
        }
    }

    const onBackClick = () => {
        history.goBack()
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [documentType])

    return (
        <Grid container direction="row" spacing={16}>
            <Grid item container xs={12}>
                <TitleCard
                    bol={bol}
                    trackingNumber={trackingNumber}
                    formTitle={intl.formatMessage(formTitle)}
                />
            </Grid>
            <Grid item container xs={12}>
                {documentType?.documentCategory === "commercialInvoice" && (
                    <CommercialInvoiceForm
                        shipmentId={shipmentId}
                        onSubmit={submit}
                        onBackClick={onBackClick}
                        previousDocument={previousDocument}
                        formTitle={intl.formatMessage(formTitle)}
                    />
                )}
                {documentType?.documentCategory === "certificateOfOrigin" && (
                    <CertificateOfOriginForm
                        shipmentId={shipmentId}
                        onSubmit={submit}
                        onBackClick={onBackClick}
                        previousDocument={previousDocument}
                        formTitle={intl.formatMessage(formTitle)}
                    />
                )}
                {documentType?.documentCategory === "USMCA" && (
                    <USMCAForm
                        shipmentId={shipmentId}
                        locationId={locationId}
                        onSubmit={submit}
                        onBackClick={onBackClick}
                        previousDocument={previousDocument}
                        formTitle={intl.formatMessage(formTitle)}
                    />
                )}
            </Grid>
        </Grid>
    )
}

const documentTitles = defineMessages({
    commercialInvoice: {
        id: "documents.commercialInvoice__name",
        defaultMessage: "Commercial Invoice",
    },
    certificateOfOrigin: {
        id: "documents.certificateOfOrigin__name",
        defaultMessage: "Certificate of Origin",
    },
    USMCA: {
        id: "documents.usmca",
        defaultMessage: "USMCA",
    },
})

const mapStateToProps = (state, { shipmentId, documentType }) => {
    const shipment = state.shipment.list[shipmentId]
    return {
        bol: shipment?.identifiers?.bolNumber,
        locationId: state?.user?.profile?.locations?.find(
            x => x.cpgCode === shipment?.query?.cpg
        )?._id,
        trackingNumber: shipment?.identifiers?.proNumber,
        formTitle: documentTitles[documentType?.documentCategory],
    }
}

export default connect(mapStateToProps)(DocumentForm)
