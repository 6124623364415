import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
    container: {
        padding: "5px",
    },
})

export default function FreightBoxRestrictionDialog({ open, handleClose }) {
    const classes = useStyles()

    return (
        <Dialog
            className={classes.container}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                    id="book.freightBox__dialogTitle"
                    defaultMessage="Notice"
                />
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage
                        id="book.freightBox__dialogTitle"
                        defaultMessage="FedEx Freight Box only supports US domestic shipments."
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={handleClose}
                    autoFocus
                    variant="contained"
                >
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    )
}
