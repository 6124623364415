import DestinationSummary from "../summaryCards/DestinationSummary"
import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { Field, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import { supportedCountryCodes } from "../../../../misc"
import { Map } from "../../../util"
import Accessorials from "../atoms/Accessorials"
import { Popper } from "@material-ui/core"
import { SuggestionBox } from "../atoms/SuggestionBox"
import { Element, scroller } from "react-scroll"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import FormSelectAutocompleteNew from "../../../form/form-select-autocomplete-new"
import FormZipCode from "../../../form/form-zip-code"
import { resiAddressRequest } from "../../../../actions/quote-request"
import ResidentialAddressDialog from "../../../quotesPage/dialog/resiCheckDialog"
import { union } from "lodash"
import { bookShipmentFormValidation } from "../index"
import InBondDialog from "../atoms/InBondDialog"
import normalizePhone from "../../../util/normalizePhone"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
        maxHeight: "388px",
    },
    formContainer: {
        paddingRight: "8px",
    },
    mainInformation: {},
    deliveryInformation: {},
})

const Destination = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = {},
    performContactSearch,
    changeField,
    initialValues,
    contactSearchInProgress,
    cities = [],
    deliveryAccessorialsList,
    setIsLoading,
    textInputProps,
    isNotAllowedInBondCountryModal,
    proccedWithOutInbondModal,
    cancelInbondModal,
    invalid,
    invalidInBondCountry,
    isBookingQuickRate,
    isControlledFlow,
    handleSubmit,
    setIsFreightBoxDialogOpen,
}) => {
    const {
        destination,
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        mode,
        shouldDisPlayContact,
    } = formValues
    const [showResiModal, setShowResiModal] = useState(false)

    const beforeNextStep = async () => {
        await checkAddressValidation()
    }

    const checkAddressValidation = async () => {
        const { deliveryAccessorials } = formValues
        let needsResiModal = false

        if (
            !deliveryAccessorials.includes("RESDEL") &&
            !formValues?.isFreightDirect
        ) {
            setIsLoading(true)
            try {
                const addr = formValues?.destination?.shippingAddress?.address

                needsResiModal = await resiAddressRequest(addr)
            } catch (err) {
                //err for resi check
            } finally {
                setIsLoading(false)
            }
        }

        if (needsResiModal) {
            setShowResiModal(true)
        } else {
            if (editMode) {
                handleUpdate()
            } else {
                handleComplete()
            }
        }
    }

    const classes = useStyles()

    const [showOverlay, setShowOverlay] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const [shouldShowForm, setShouldShowForm] = useState(!!isBookingQuickRate)

    useEffect(() => {
        if (currentStep === 2) {
            scroller.scrollTo("destinationTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (
            mode === "inbound" ||
            isBookingQuickRate ||
            !isControlledFlow ||
            shouldDisPlayContact
        ) {
            setShouldShowForm(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, isBookingQuickRate, isControlledFlow])

    const handleInputFocus = (field, e) => {
        if (field === "searchContact") {
            setShowOverlay(true)
            setAnchorEl(e.currentTarget)
        }
    }
    const handleOptionSelect = (option = {}) => {
        if (isFreightBox && option?.address.country !== "US") {
            setIsFreightBoxDialogOpen(true)
            changeField("destination", {
                searchContact: "",
                shippingAddress: {
                    address: {
                        country:
                            initialValues?.destination?.shippingAddress?.address
                                ?.country,
                    },
                },
            })
            setShouldShowForm(true)
            return
        }
        setShowOverlay(false)
        setAnchorEl(null)
        changeField("destination", {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.destination?.shippingAddress?.address
                            ?.country,
                },
            },
        })
        setShouldShowForm(true)
        changeField("destination", {
            shippingAddress: {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
            },
            contact: {
                name: option?.contact?.name,
                phone: {
                    phone_number: option?.contact?.phone?.phoneNumber,
                    extension: option?.contact?.phone?.extension,
                },
                email: {
                    email_address: option?.contact?.email?.emailAddress,
                },
            },
        })
        if (option?.deliveryAccessorials?.length) {
            changeField(
                "deliveryAccessorials",
                option?.deliveryAccessorials.map(entry => entry.value) ?? []
            )
        } else {
            changeField("deliveryAccessorials", [])
        }
    }

    const handleClickAway = () => {
        if (showOverlay) {
            setShowOverlay(false)
            setAnchorEl(null)
        }
    }

    const disabledNextButton =
        invalidInBondCountry || invalid || !formValues?.destination?.contact
    if (currentStep === 2) {
        return (
            <Grid item container>
                <InBondDialog
                    isDialogOpen={isNotAllowedInBondCountryModal}
                    proccedWithOutInbondModal={proccedWithOutInbondModal}
                    cancelInbondModal={cancelInbondModal}
                    invalidCountry={
                        destination?.shippingAddress?.address?.country
                    }
                />
                <Popper
                    open={showOverlay}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    className={classes.popper}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => handleClickAway()}
                    >
                        <div>
                            <SuggestionBox
                                searchInProgress={contactSearchInProgress}
                                addressesNeeded
                                field={"destination"}
                                term={destination?.searchContact ?? ""}
                                handleAddressSelect={handleOptionSelect}
                                noCities
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>
                <ResidentialAddressDialog
                    isResiDialogOpen={showResiModal}
                    getRateSubmit={() => {
                        let deliveryAccessorials =
                            formValues?.deliveryAccessorials

                        if (typeof deliveryAccessorials === "string") {
                            deliveryAccessorials = deliveryAccessorials.split(
                                ","
                            )
                        }

                        changeField(
                            "deliveryAccessorials",
                            union(
                                deliveryAccessorials,
                                formValues?.selectedResiAccessorials.split(",")
                            )
                        )
                        setShowResiModal(false)
                        handleComplete()
                    }}
                    onIgnore={() => {
                        setShowResiModal(false)
                        handleComplete()
                    }}
                    deliveryAccessorials={formValues?.deliveryAccessorials}
                    currentForm={"bookShipment"}
                    resiChips={formValues?.selectedResiAccessorials}
                    resiChipsPrefix={"selectedResiAccessorials"}
                />
                <Grid item container justify="center">
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.basicInfo__destination"
                            defaultMessage="Destination Information"
                        />
                    </Typography>
                    <Element name="destinationTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.formContainer}
                    >
                        <Grid
                            item
                            container
                            className={classes.mainInformation}
                            alignContent="flex-start"
                        >
                            {formValues?.mode !== "inbound" && (
                                <Grid item container alignItems="flex-end">
                                    <Grid
                                        item
                                        container
                                        xs={9}
                                        className={classes.searchContact}
                                    >
                                        <Field
                                            name="destination.searchContact"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__mainSearch"
                                                    defaultMessage="Search for a Contact"
                                                />
                                            }
                                            autoFocus
                                            component={FormField}
                                            onChange={e => {
                                                setShowOverlay(true)
                                                setAnchorEl(e.currentTarget)
                                                performContactSearch(
                                                    e.target.value,
                                                    destination?.shippingAddress
                                                        ?.address?.postalCode
                                                )
                                            }}
                                            onFocus={e =>
                                                handleInputFocus(
                                                    "searchContact",
                                                    e
                                                )
                                            }
                                            InputProps={textInputProps}
                                            disableBrowserAutocomplete
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justify="center"
                                        alignItems="flex-start"
                                    >
                                        <Button
                                            id={"destinationAddNewContact"}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                changeField("destination", {
                                                    shippingAddress: {
                                                        address: {
                                                            country:
                                                                initialValues
                                                                    ?.destination
                                                                    ?.shippingAddress
                                                                    ?.address
                                                                    ?.country,
                                                        },
                                                    },
                                                })
                                                changeField(
                                                    "deliveryAccessorials",
                                                    []
                                                )
                                                setShouldShowForm(true)
                                            }}
                                        >
                                            <FormattedMessage
                                                id="generalTerms__addNew"
                                                defaultMessage="Add New"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            {shouldShowForm && (
                                <Grid item container>
                                    <Grid item container>
                                        <Field
                                            name="destination.shippingAddress.name"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__companyName"
                                                    defaultMessage="Company Name"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "inbound"
                                            }
                                            component={FormField}
                                        />
                                    </Grid>

                                    <Grid item container xs={8}>
                                        <Field
                                            name="destination.shippingAddress.address.street1"
                                            required
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address1"
                                                    defaultMessage="Address 1"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "inbound"
                                            }
                                            component={FormField}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="destination.shippingAddress.address.street2"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__address2"
                                                    defaultMessage="Address 2"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "inbound"
                                            }
                                            component={FormField}
                                        />
                                    </Grid>
                                    <Grid item container xs={3}>
                                        <Field
                                            name="destination.shippingAddress.address.postalCode"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__zipPostalCode"
                                                    defaultMessage="Zip/Postal Code"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "inbound"
                                            }
                                            component={FormZipCode}
                                            form="bookShipment"
                                            country={
                                                formValues?.destination
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            }
                                        />
                                    </Grid>
                                    <Grid item container xs={5}>
                                        <Field
                                            name="destination.shippingAddress.address.city"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__city"
                                                    defaultMessage="City"
                                                />
                                            }
                                            component={
                                                FormSelectAutocompleteNew
                                            }
                                            options={cities.map(c => ({
                                                value: c,
                                                label: c,
                                            }))}
                                            customValue
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="destination.shippingAddress.address.state"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__state"
                                                    defaultMessage="State"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode === "inbound"
                                            }
                                            component={FormField}
                                            inputProps={{ maxLength: 2 }}
                                        />
                                    </Grid>

                                    <Grid item container xs={2}>
                                        <Field
                                            component={
                                                FormSelectAutocompleteNew
                                            }
                                            name="destination.shippingAddress.address.country"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                            disabled={
                                                formValues?.mode ===
                                                    "inbound" || isFreightBox
                                            }
                                            options={supportedCountryCodes}
                                        />
                                    </Grid>
                                    <Grid item container>
                                        <Field
                                            name="destination.contact.name"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactName"
                                                    defaultMessage="Contact Name"
                                                />
                                            }
                                            component={FormField}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={4}>
                                        <Field
                                            name="destination.contact.phone.phone_number"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactPhone"
                                                    defaultMessage="Contact Phone"
                                                />
                                            }
                                            component={FormField}
                                            normalize={normalizePhone(
                                                formValues?.destination
                                                    ?.shippingAddress?.address
                                                    ?.country
                                            )}
                                            required={true}
                                        />
                                    </Grid>
                                    <Grid item container xs={2}>
                                        <Field
                                            name="destination.contact.phone.extension"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__extension"
                                                    defaultMessage="Extension"
                                                />
                                            }
                                            component={FormField}
                                        />
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <Field
                                            name="destination.contact.email.email_address"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__contactEmail"
                                                    defaultMessage="Contact Email"
                                                />
                                            }
                                            component={FormField}
                                            required={true}
                                        />
                                    </Grid>
                                    {!isFreightDirect &&
                                        !isFreightDirectReturns && (
                                            <Grid
                                                item
                                                container
                                                alignContent="flex-start"
                                            >
                                                <Accessorials
                                                    list={
                                                        deliveryAccessorialsList ??
                                                        []
                                                    }
                                                    name={
                                                        "deliveryAccessorials"
                                                    }
                                                    formName="bookShipment"
                                                    formValues={formValues}
                                                />
                                            </Grid>
                                        )}
                                    <Grid
                                        item
                                        container
                                        className={classes.deliveryInformation}
                                    >
                                        {!isFreightDirect &&
                                            !isFreightDirectReturns && (
                                                <Field
                                                    name="destination.note"
                                                    label={
                                                        <FormattedMessage
                                                            id="orderDetails.delivery__specialInstructions"
                                                            defaultMessage="Special Instructions"
                                                        />
                                                    }
                                                    component={FormField}
                                                    multiline
                                                />
                                            )}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={5}
                        className={classes.accessorialsContainer}
                    >
                        <Grid item container className={classes.mapContainer}>
                            <Map
                                addresses={[
                                    destination?.shippingAddress?.address,
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            beforeNextStep()
                        }}
                        invalid={disabledNextButton}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                setShouldShowForm(true)
                                handleComplete(isInvalidNextButton)
                            } else {
                                beforeNextStep()
                            }
                        }}
                        invalid={disabledNextButton}
                        handleSubmit={handleSubmit}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 2) {
        return (
            <DestinationSummary
                handleEdit={handleEdit}
                formValues={formValues}
                deliveryAccessorialsList={deliveryAccessorialsList}
            />
        )
    }
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Destination)
