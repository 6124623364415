import moment from "moment"
import createSelector from "re-reselect"
import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import qs from "qs"

const shipmentIdSelector = (state, props) =>
    props?.match?.params?.shipmentId ?? ""

const returnModeSelector = (state, props) =>
    props?.match?.params?.mode === "return" ?? false

const quoteSelector = createSelector(
    state => state.redesign.activeRates,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const shipmentSelector = createSelector(
    state => state.shipment.list,
    shipmentIdSelector,
    (list, shipmentId) => list[shipmentId]
)(shipmentIdSelector)

const contactIdSelector = (state, props) => {
    const { to: contactId } = qs.parse(props?.location?.search, {
        ignoreQueryPrefix: true,
    })
    return contactId ?? ""
}

const contactSelector = createSelector(
    contactIdSelector,
    state => state?.addressBook?.addresses,
    (contactId, contacts) => {
        if (!contactId || !contacts || !contacts.length) return undefined
        return contacts.find(item => item?.contact?.id === contactId)
    }
)(contactIdSelector)

export const billToLocationSelector = createSelector(
    quoteSelector,
    state => state?.user?.profile?.locations,
    state => state?.user?.profile?.preferences?.defaultLocationId,
    returnModeSelector,
    (quote, locations = [], defaultLocationId, isReturnMode) => {
        if (isReturnMode) {
            return locations.find(
                loc => loc.locationType === "FEDEX_DIRECT_RETURNS"
            )
        } else {
            return (
                locations.find(
                    loc => (loc?.cpgCode ?? "") === quote?.search?.cpg
                ) ||
                locations.find(
                    loc =>
                        loc?._id === defaultLocationId &&
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                ) ||
                locations.find(
                    loc =>
                        loc?.users?.[0]?.permissions?.createRates?.value &&
                        !loc?.users?.[0]?.permissions?.suspended?.value &&
                        !loc.isPending
                )
            )
        }
    }
)(shipmentIdSelector)

export const nextBusinessDay = () => {
    if (moment().day() === 5) {
        return moment.utc(
            moment()
                .add(3, "days")
                .format("YYYY-MM-DD")
        )
    } else if (moment().day() === 6) {
        return moment.utc(
            moment()
                .add(2, "days")
                .format("YYYY-MM-DD")
        )
    } else {
        return moment.utc(
            moment()
                .add(1, "days")
                .format("YYYY-MM-DD")
        )
    }
}

export function lookupContact(address, id) {
    const contact = (address?.contacts ?? []).find(item => item._id === id)
    return transformContact(contact)
}

const transformContact = contact => ({
    _id: contact?._id,
    name: contact?.name,
    phone: contact?.phone,
    email: contact?.email?.email_address,
})

export const formSelector = createSelector(
    quoteSelector,
    shipmentIdSelector,
    billToLocationSelector,
    contactSelector,
    returnModeSelector,
    shipmentSelector,
    state => state?.user?.profile,
    (
        quote,
        shipmentId,
        billToLocation,
        contact,
        isReturnMode,
        shipment,
        user
    ) => {
        const shippingContact = {
            name: [user?.firstname, user?.lastname].filter(x => x).join(" "),
            phone: user?.phone_number,
            email: {
                email_address: user?.email?.email_address,
            },
        }

        const isThirdPartyOnly =
            !billToLocation?.isShipperEnabled &&
            billToLocation?.is3rdPartyEnabled

        const isFreightDirectReturns =
            billToLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        if (!shipmentId) {
            const isShipperEnabled = billToLocation?.isShipperEnabled

            const pickupAccessorials = ["DOCKPU"]
            const deliveryAccessorials = ["DOCKDEL"]

            const hazmatEmergency =
                user?.preferences?.hazmatEmergencyContact ?? {}
            const exportCustomsBrokerageInfo = { address: { country: "US" } }
            const importCustomsBrokerageInfo = { address: { country: "US" } }

            const destinationContact = contact
                ? {
                      shippingAddress: {
                          address: { ...contact?.address },
                          name: contact?.name,
                      },
                      contact: {
                          name: contact?.contact?.name,
                          phone: {
                              phone_number:
                                  contact?.contact?.phone?.phoneNumber,
                              extension: contact?.contact?.phone?.extension,
                          },
                          email: {
                              email_address:
                                  contact?.contact?.email?.emailAddress,
                          },
                      },
                  }
                : {
                      shippingAddress: {
                          address: {
                              country: "US",
                          },
                      },
                  }

            return {
                shouldDisPlayContact: !!destinationContact?.address?.postalCode,
                includeThirdParty: true,
                shouldReset: false,
                alerts: {
                    user: user?.preferences?.alerts,
                },
                hazmatEmergency,
                pickupAccessorials,
                deliveryAccessorials,
                shippingContact,
                selectedLocation: billToLocation,
                mode: isFreightDirectReturns
                    ? "inbound"
                    : isThirdPartyOnly
                    ? "thirdParty"
                    : "outbound",
                origin:
                    isShipperEnabled && !isFreightDirectReturns
                        ? { ...billToLocation, contact: shippingContact }
                        : null,
                destination: isFreightDirectReturns
                    ? { ...billToLocation, contact: shippingContact }
                    : destinationContact,
                pickupDate: isFreightDirectReturns
                    ? nextBusinessDay()
                    : moment.utc(moment().format("YYYY-MM-DD")),
                billToLocation: billToLocation?.cpgCode,
                cpg: billToLocation?.cpgCode,
                isFreightDirect:
                    billToLocation?.locationType === "FEDEX_DIRECT",
                isFreightBox:
                    billToLocation?.locationType === "FEDEX_FREIGHT_BOX",
                isFreightDirectReturns,
                handlingUnits: [newFreightDirectHandlingUnit()],
                isQuickQuote: false,
                totalLinearFeet: 0,
                userProvidedTotalLinearFeet: false,
                capLoadTotalLinearFeet: 0,
                volumeTotalLinearFeet: 0,
                isOverLTLLimit: false,
                preferredCurrencyCode: "USD",
                preferredSystemOfMeasurement: "IMPERIAL",
                exportCustomsBrokerageInfo,
                importCustomsBrokerageInfo,
            }
        }

        const isQuickRate = quote?.search?.isQuickRate
        const shipmentDestination = shipment?.shipment?.destination
        const destination = {
            shippingAddress: {
                address: { ...shipmentDestination?.address },
                name: shipmentDestination?.name,
            },
            contact: shipmentDestination?.contact,
            readyTime: isFreightDirectReturns ? "7:00 AM" : null,
            closeTime: isFreightDirectReturns ? "8:00 PM" : null,
        }
        let handlingUnits =
            (isReturnMode ? shipment?.handlingUnits : quote?.handlingUnits) ??
            []
        const mode =
            (isReturnMode || isFreightDirectReturns
                ? "inbound"
                : quote?.search?.mode) ?? "outbound"
        return {
            ...quote?.search,
            mode,
            includeThirdParty: true,
            hideThirdPartyRates: false,
            hideLTLRates: false,
            hideVolumeRates: false,
            isQuickRate,
            isReturnMode,
            proNumber: shipment?.identifiers?.proNumber,
            handlingUnits,
            pickupDate: isReturnMode
                ? nextBusinessDay()
                : moment.utc(quote?.search?.pickupDate),
            origin: isReturnMode
                ? destination
                : mode === "outbound"
                ? {
                      ...quote?.search?.origin,
                      readyTime: billToLocation?.readyTime,
                      closeTime: billToLocation?.closeTime,
                      pickupContact: billToLocation?.pickupContact,
                  }
                : {
                      ...quote?.search?.origin,
                  },
            destination: isReturnMode
                ? { ...billToLocation, contact: shippingContact }
                : mode === "inbound"
                ? {
                      ...quote?.search?.destination,
                      shippingAddress: billToLocation?.shippingAddress,
                  }
                : quote?.search?.destination,
            cpg: isReturnMode ? billToLocation?.cpgCode : quote?.search?.cpg,
            billToLocation: isReturnMode
                ? billToLocation?.cpgCode
                : quote?.search?.cpg,
            thirdPartyOnly: !billToLocation?.isShipperEnabled,
            isFreightBox: !quote?.result?.rateQuotes?.length
                ? billToLocation?.locationType === "FEDEX_FREIGHT_BOX"
                : quote.result.rateQuotes.some(
                      x => x.mode === "LTL_FREIGHT_BOX"
                  ),
            isFreightDirect: !quote?.result?.rateQuotes?.length
                ? billToLocation?.locationType === "FEDEX_DIRECT"
                : quote.result.rateQuotes.some(x => x.mode === "LTL_DIRECT"),
            isFreightDirectReturns: isReturnMode
                ? true
                : !quote?.result?.rateQuotes?.length
                ? billToLocation?.locationType === "FEDEX_DIRECT_RETURNS"
                : quote.result.rateQuotes.some(
                      x => x.mode === "LTL_DIRECT_RETURNS"
                  ),
            preferredCurrencyCode: quote?.search?.preferredCurrencyCode,
            preferredSystemOfMeasurement:
                quote?.search?.preferredSystemOfMeasurement,
            selectedLocation: billToLocation,
        }
    }
)(shipmentIdSelector)

export const locationPermissionSelector = createSelector(
    billToLocationSelector,
    billToLocation => ({
        canCreateRates: !!billToLocation?.users?.[0]?.permissions?.createRates
            ?.value,
        canCreateShipments: !!billToLocation?.users?.[0]?.permissions
            ?.createShipments?.value,
        canViewRateAmounts: !!billToLocation?.users?.[0]?.permissions
            ?.viewRateAmounts?.value,
    })
)(shipmentIdSelector)
