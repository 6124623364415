import React from "react"
import TextField from "@material-ui/core/TextField"

const DiscountsSearchBar = ({
    setSearchValue,
    onSearch,
    searchValue,
    handleResetDiscounts,
}) => {
    return (
        <form>
            <TextField
                style={{ width: "300px", paddingBottom: "10px" }}
                onChange={e => {
                    setSearchValue(e.target.value.toString())
                    if (e.target.value.length > 3) {
                        onSearch(e.target.value.toString())
                    } else {
                        handleResetDiscounts(e.target.value.toString())
                    }
                }}
                label="Search by account number"
                value={searchValue}
                helperText={
                    searchValue.length < 4 && searchValue.length > 0
                        ? "Please input at least 4 characters"
                        : ""
                }
            />
        </form>
    )
}

export default DiscountsSearchBar
