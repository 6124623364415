import React, { Fragment } from "react"
import FormField from "../form/form-field"
import FormSelectAutocompleteDark from "../form/form-select-autocomplete-dark"
import { Field } from "redux-form"
import Grid from "@material-ui/core/Grid"
import DeleteIcon from "@material-ui/icons/Delete"
import { freightClasses } from "../item/line-item"

const QuoteItem = ({
    classes,
    inputHelperProps,
    inputLabelProps,
    selectClasses,
    inputProps,
    multilineProps,
    formLabelProps,
    onRemoveClick,
    prefix,
    inputSelectAutocompleteProps,
}) => (
    <Grid
        item
        container
        className={classes.quote__form__container__item}
        xs={12}
    >
        <Grid item container>
            <Grid item container xs={5}>
                <Field
                    component={FormSelectAutocompleteDark}
                    name={`${prefix}.freightClass`}
                    label="* Class"
                    options={freightClasses}
                    InputProps={inputSelectAutocompleteProps}
                    InputLabelProps={inputLabelProps}
                    InputHelperProps={inputHelperProps}
                    selectClasses={selectClasses}
                />
            </Grid>
            <Grid item container xs={5}>
                <Field
                    component={FormField}
                    name={`${prefix}.weight`}
                    label="* Weight (lbs)"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    InputHelperProps={inputHelperProps}
                />
            </Grid>
            <Grid item container justify="center" alignItems="center" xs={2}>
                <DeleteIcon
                    onClick={e => onRemoveClick(e)}
                    className={classes.item__icon}
                />
            </Grid>
        </Grid>
    </Grid>
)

export const QuoteItems = ({ fields, ...props }) => (
    <Fragment>
        {fields.map((hu, index) => (
            <QuoteItem
                {...props}
                prefix={`${hu}.items[0]`}
                key={index}
                onRemoveClick={e => {
                    e.stopPropagation()
                    fields.remove(index)
                }}
            />
        ))}
    </Fragment>
)
