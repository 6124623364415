import React, { Fragment } from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import "react-select/dist/react-select.css"
import "react-day-picker/lib/style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-table/react-table.css"
import "react-viewer/dist/index.css"
import "dc/dc.css"
import { create } from "jss"
import compose from "jss-compose"
import JssProvider from "react-jss/lib/JssProvider"
import { PersistGate } from "redux-persist/integration/react"
import { jssPreset, createGenerateClassName } from "@material-ui/core/styles"

import { configureStore } from "./configure-store"
import { App } from "./app"
import { PrintableContent, ErrorBoundary } from "./components/util"
import { initPurifier, attachGoogleAnalytics } from "./misc"
import "./main.css"

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, compose()] })

const generateClassName = createGenerateClassName({
    productionPrefix: "muijss",
})

initPurifier()
attachGoogleAnalytics()

const { store, persistor } = configureStore()
window.store = store

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <JssProvider jss={jss} generateClassName={generateClassName}>
                    <Fragment>
                        <App store={store} />
                        <PrintableContent />
                    </Fragment>
                </JssProvider>
            </PersistGate>
        </Provider>
    </ErrorBoundary>,
    document.getElementById("root")
)
