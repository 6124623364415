import React from "react"
import { useSnackbarContext } from "../providers/snackbarProvider"
import classNames from "classnames"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import green from "@material-ui/core/colors/green"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
}

const styles = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: theme.palette.secondary.dark,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
})

const SnackbarWrapperPresentation = ({ classes }) => {
    const {
        open,
        variant,
        message,
        autoHideDuration = 6000,
        closeSnackbar,
    } = useSnackbarContext()
    const Icon = variantIcon[variant]

    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={closeSnackbar}
        >
            <SnackbarContent
                className={classNames(classes[variant])}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={classNames(
                                classes.icon,
                                classes.iconVariant
                            )}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={closeSnackbar}
                    >
                        <CloseIcon
                            className={classes.icon}
                            onClick={() => closeSnackbar()}
                        />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    )
}

export const SnackbarWrapper = withStyles(styles)(SnackbarWrapperPresentation)
