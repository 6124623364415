import { Grid } from "@material-ui/core"
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { change, getFormValues } from "redux-form"
import {
    AccessorialsSelect,
    defaultAccessorialLogic,
    SelectedAccessorials,
} from "../../../quotesPage/accessorials"

const Accessorials = ({
    selected,
    name,
    changeField,
    language,
    isInBondShipment,
    list = [],
    quickRate,
    country,
}) => {
    useEffect(() => {
        defaultAccessorialLogic(name, selected, changeField, country)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, selected, country])

    const onAccessorialSelect = (e = {}, value, name) => {
        let accessorialArray

        if (typeof value === "string") {
            accessorialArray = value.split(",")
        } else {
            accessorialArray = value
        }

        changeField(name, accessorialArray)
    }

    if (quickRate) {
        return (
            <Grid item container>
                <Grid item container xs={6}>
                    <AccessorialsSelect
                        name={name}
                        onAccessorialSelect={onAccessorialSelect}
                        accessorials={list}
                        bookShipmentWorkflow={true}
                        bookShipmentLabel={
                            name === "pickupAccessorials" ? (
                                <FormattedMessage
                                    id="generalTerms__selectPickup"
                                    defaultMessage="Select Pickup Accessorials"
                                />
                            ) : (
                                <FormattedMessage
                                    id="generalTerms__selectDelivery"
                                    defaultMessage="Select Delivery Accessorials"
                                />
                            )
                        }
                    />
                </Grid>
                <Grid item container xs={6} alignItems="center">
                    <SelectedAccessorials
                        selected={selected}
                        accessorials={list}
                        name={name}
                        changeField={changeField}
                        language={language}
                        isInBondShipment={isInBondShipment}
                    />
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid item container xs={12} justify="flex-start">
            <AccessorialsSelect
                name={name}
                onAccessorialSelect={onAccessorialSelect}
                accessorials={list}
                bookShipmentWorkflow={true}
                bookShipmentLabel={
                    name === "pickupAccessorials" ? (
                        <FormattedMessage
                            id="generalTerms__selectPickup"
                            defaultMessage="Select Pickup Accessorials"
                        />
                    ) : (
                        <FormattedMessage
                            id="generalTerms__selectDelivery"
                            defaultMessage="Select Delivery Accessorials"
                        />
                    )
                }
            />
            <SelectedAccessorials
                selected={selected}
                accessorials={list}
                name={name}
                changeField={changeField}
                language={language}
                isInBondShipment={isInBondShipment}
            />
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues(props.formName)(state)

    const isPickup = props.name === "pickupAccessorials"

    const language = state?.user?.profile?.preferences?.language

    return {
        ...state.search,
        accessorialsLoaded: state.accessorials.isLoaded,
        nonspecificAccessorialsList: state.accessorials.list.nonspecific,
        commodityOptionsAccessorials: state.search.commodityOptionsAccessorials,
        language,
        isInBondShipment: formValues?.isInBondShipment,
        selected: isPickup
            ? formValues?.pickupAccessorials
            : formValues?.deliveryAccessorials,
        country:
            formValues?.country ??
            formValues?.shippingAddress?.address?.country ??
            (isPickup ? formValues?.origin : formValues?.destination)
                ?.shippingAddress?.address?.country,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    changeField: (field, value) =>
        dispatch(change(props.formName, field, value)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Accessorials)
