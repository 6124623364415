import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"
import { closeModal } from "../../actions/modal"

const ContactSelectionPresentation = ({ close, yes, no }) => (
    <div className="column">
        The contact information changed. Do you want to save it as a new contact
        in your address book?
        <div className="right-align">
            <Button bsStyle="success" onClick={yes}>
                Create New Contact
            </Button>
            <Button bsStyle="warning" onClick={no}>
                Update Existing Contact
            </Button>
            <Button className="hide" bsStyle="danger" onClick={close}>
                Cancel
            </Button>
        </div>
    </div>
)

ContactSelectionPresentation.propTypes = {
    close: PropTypes.func.isRequired,
    yes: PropTypes.func.isRequired,
    no: PropTypes.func.isRequired,
}

const dispatchToProps = dispatch => ({
    close: () => dispatch(closeModal()),
})

export const ContactSelection = connect(
    undefined,
    dispatchToProps
)(ContactSelectionPresentation)
