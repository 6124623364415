import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import PersonIcon from "@material-ui/icons/Person"
import CircularProgress from "@material-ui/core/CircularProgress"
import { FormattedMessage } from "react-intl"
import { highlightTerm } from "../util"
import { getContactKey } from "../../actions/contact"

const styles = theme => ({
    paper: {
        maxHeight: 500,
        minWidth: 450,
        padding: "12px",
        zIndex: 10000,
    },
    suggestion__item__address: {
        cursor: "pointer",
        padding: "3px 0px",
        "&:hover": {
            "background-color": theme.palette.secondary.light,
        },
    },
    suggestion__line: {
        margin: "0 7px",
    },
    suggestion__line__last: {
        "margin-left": "auto",
        "margin-right": "3px",
    },
})

const SuggestionItem = ({ item, onClick, prefix, classes }) => {
    const { postalCode, city, state } = item.address || {}
    const { name } = item
    const { name: contactName } = item.contact || {}
    return (
        <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justify="flex-start"
            className={classes.suggestion__item__address}
            onClick={() => {
                onClick(item)
            }}
            role="button"
            tabIndex="-1"
        >
            <Grid item container alignItems="center" xs={1}>
                <PersonIcon />
            </Grid>
            <Grid item container alignItems="center" xs={11}>
                {name && (
                    <Typography className={classes.suggestion__line}>
                        {highlightTerm(prefix, name)}
                    </Typography>
                )}
                {contactName && (
                    <Typography className={classes.suggestion__line}>
                        {highlightTerm(prefix, contactName)}
                    </Typography>
                )}
                <Typography
                    className={classes.suggestion__line__last}
                    variant="caption"
                >
                    {city}, {state} {highlightTerm(prefix, postalCode)}
                </Typography>
            </Grid>
        </Grid>
    )
}

SuggestionItem.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
}

export class SuggestionBoxPresentation extends Component {
    render() {
        const {
            addresses,
            handleAddressSelect,
            term,
            classes,
            searchInProgress,
            postalCode,
        } = this.props

        if (!term || term.length < 3) return null
        return (
            <Paper className={classes.paper}>
                <Grid container direction="column" wrap="nowrap">
                    <Grid item container direction="row">
                        <Grid item xs={10}>
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="orderDetails.suggestion__title"
                                    defaultMessage="Showing Results for {term}"
                                    values={{
                                        term,
                                    }}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {searchInProgress && (
                                <CircularProgress size={20} color="secondary" />
                            )}
                        </Grid>
                    </Grid>
                    {addresses.length > 0 && (
                        <div>
                            {addresses.map((item, i) => (
                                <SuggestionItem
                                    key={i}
                                    item={item}
                                    onClick={handleAddressSelect}
                                    prefix={term}
                                    classes={classes}
                                />
                            ))}
                        </div>
                    )}
                    {!addresses.length && !searchInProgress && (
                        <Typography color="secondary">
                            <FormattedMessage
                                id="orderDetails.suggestion__noResult"
                                values={{
                                    postalCode,
                                }}
                                defaultMessage="Please ensure you enter a valid, existing contact with postal code {postalCode}"
                            />
                        </Typography>
                    )}
                </Grid>
            </Paper>
        )
    }
}

SuggestionBoxPresentation.propTypes = {
    addresses: PropTypes.array,
    handleAddressSelect: PropTypes.func.isRequired,
    handleCitySelect: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired,
}

SuggestionBoxPresentation.defaultProps = {
    addresses: [],
}

const mapStateToProps = (state, { term, postalCode, country }) => {
    const key = getContactKey(term, country)
    const addresses = get(state, `contact.search.bolEntry.${key}`, []).filter(
        entry => get(entry, "address.postalCode") === postalCode
    )

    return { addresses }
}

export const SuggestionBox = withStyles(styles)(
    connect(mapStateToProps)(SuggestionBoxPresentation)
)

SuggestionBox.propTypes = {
    term: PropTypes.string.isRequired,
    addressesNeeded: PropTypes.bool,
}

SuggestionBox.defaultProps = {
    addressesNeeded: true,
}
