import { Grid } from "@material-ui/core"
import React from "react"
import Tile from "./Tile"
import { withStyles } from "@material-ui/core/styles"
import { supportedTiles } from "./constants"

const styles = theme => ({
    tileGroupContainer: {
        padding: "5px 0",
    },
    tileSection: {
        padding: "10px",
    },
})

const TileGroup = ({
    tileSettings = [],
    classes,
    activeTile,
    activateTile,
}) => {
    return (
        <Grid item container className={classes.tileGroupContainer}>
            {tileSettings.map(tile => {
                const { label, icon, value } = supportedTiles.find(
                    supportedTile => tile === supportedTile.value
                )
                return (
                    <Grid item container xs={2} className={classes.tileSection}>
                        <Tile
                            label={label}
                            icon={icon}
                            value={value}
                            activateTile={activateTile}
                            active={activeTile === value}
                        />
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default withStyles(styles)(TileGroup)
