import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactGA from "react-ga"
import { get } from "lodash"
import Divider from "@material-ui/core/Divider"
import { Field, reduxForm, change, getFormValues } from "redux-form"
import createSelector from "re-reselect"
import { connect } from "react-redux"
import { composeValidators, combineValidators } from "revalidate"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Snackbar from "@material-ui/core/Snackbar"
import { Grid, withStyles, Typography, Button } from "@material-ui/core"
import FormCheckbox from "../form/form-checkbox"
import { formValidation } from "../locations/validation"
import GlobalSpinner from "../util/spinner"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import {
    signupSubmit,
    requestAccessToLocation,
    trackGAEventNew,
} from "../../actions/user"
import { logOut } from "../../actions/authorization"
import {
    countryPhoneValidator,
    acceptValidator,
    emailValidator,
    isRequired,
    isNumeric,
} from "../../actions/validation"
import RequestAccess from "../locations/request-access"
import {
    locationAddSuccess,
    requestAccessSuccess,
} from "../../messages/confirmation-constants"
import { requestAccessError } from "../../messages/error-constants"
import PickUpInformation from "./pick-up-information.js"
import ShippingInformation from "./shipping-information.js"
import BillingInformation from "./billing-information.js"
import GeneralInformation from "./general-information.js"
import { FormattedMessage, injectIntl } from "react-intl"
import { supportedLanguagesCountryMap } from "../../misc"
import {
    shipperLocationTypes,
    thirdPartyLocationTypes,
} from "./../locations/addLocation.js"

const termsLabel = language => (
    <div>
        <span>
            <FormattedMessage
                id="signUp__iAcceptThe"
                defaultMessage="I accept the "
            />
        </span>
        <span
            style={{ color: "blue" }}
            onClick={() => {
                window.open(`/#/terms/${language}`, "_blank")
            }}
        >
            <FormattedMessage
                id="signUp__termsAndConditions"
                defaultMessage="Terms And Conditions"
            />
        </span>
    </div>
)

const styles = theme => ({
    form__container: {
        padding: theme.spacing.unit * 3,
    },
    login__information: {
        marginBottom: "40px",
    },
    signup__form: {
        padding: "15px",
        marginTop: "-64px",
        height: "100%",
        alignContent: "flex-start",
    },
    signup__button: {
        marginLeft: "10px",
    },
    decision__container: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        color: "white",
        padding: "20px",
    },
    input__label: {
        color: "white !important",
    },
    cards__container: {
        paddingTop: "20px",
        width: "100%",
    },
    signup__decision__text: {
        paddingRight: "20px",
    },
    signup__section: {
        padding: "1%",
    },
    signup__card: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
        minHeight: "330px",
    },
    signup__card__variableSize: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    signup__card__top: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    terms__container: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "10px 20px",
    },
    acceptTerms__text: {
        paddingLeft: "40px",
    },
    checkbox: {
        color: "white",
    },
    error__entry: {
        padding: "15px",
    },
    bottom__push: {
        flexGrow: 1,
    },
    bottom__push__container: {
        height: "calc(100% - 20px)",
    },
    decision__divider: {
        height: "20px",
        borderTop: "solid 1px white",
        width: "100%",
    },
    decision__titleContainer: {
        padding: "20px",
    },
    decision__fedexContainer: {
        padding: "20px",
    },
    decision__fedexText: {
        color: "white",
    },
    field__root: {
        "background-color": theme.palette.primary.light,
    },
    input__field: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        minHeight: "20px",
        fontFamily: theme.typography.fontFamily,
    },
})

class SignUpForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            requestAccessOpen: false,
            duplicateLocation: null,
            sbOpen: false,
            sbMessage: "",
            sbVariant: "",
            open: false,
            modalContent: "",
            loading: false,
        }
    }

    componentDidMount() {
        ReactGA.pageview("/sign-up")
    }

    handleRequestAccessClose() {
        this.setState({
            requestAccessOpen: false,
            duplicateLocation: null,
        })
    }

    handleClickOpen = () => {
        this.setState({ open: true })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    async handleRequestAccess() {
        try {
            await this.props.requestAccessToLocation(
                this.state.duplicateLocation
            )
            this.setState({
                sbOpen: true,
                sbMessage: requestAccessSuccess,
                sbVariant: "success",
            })
        } catch (error) {
            this.setState({
                sbOpen: true,
                sbMessage: requestAccessError,
                sbVariant: "error",
            })
        } finally {
            this.handleRequestAccessClose()
        }
    }

    async handleSignup(values) {
        let duplicateLocation
        this.setState({
            loading: true,
        })
        try {
            duplicateLocation = await this.props.onSignUpSubmit(values)
            if (duplicateLocation) {
                this.setState({
                    duplicateLocation,
                    requestAccessOpen: !!duplicateLocation,
                })
            } else {
                this.setState({
                    sbOpen: true,
                    sbMessage: locationAddSuccess,
                    sbVariant: "success",
                })
            }
        } catch (error) {
            this.setState({
                modalContent: error,
            })
            this.handleClickOpen()
            throw error
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        this.setState({ sbOpen: false })
    }

    handleCheckboxClick = () => {
        const {
            accountType,
            locationType,
            formValues = {},
            changeField,
            reset,
        } = this.props

        const { firstname, lastname, phone_number, language } = formValues

        reset()
        changeField("signup", "isShipper")
        changeField("signup", "isThirdParty", accountType.isThirdParty)
        changeField("signup", "firstname", firstname)
        changeField("signup", "lastname", lastname)
        changeField(
            "signup",
            "phone_number.phone_number",
            phone_number?.phone_number
        )
        changeField("signup", "phone_number.extension", phone_number?.extension)
        changeField("signup", "phone_number.mobile", phone_number?.mobile)
        changeField("signup", "language", language)
        changeField("signup", "location.locationType", locationType)
    }

    determineRequestedInfo = () => {
        const { locations } = this.props
        let accountNumbers = ""

        if (!get(locations, "length")) return undefined
        const { is3rdPartyEnabled, isShipperEnabled } = locations[0]

        if (is3rdPartyEnabled && !isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__thirdPartyAccount"
                    defaultMessage="third party account number {number}"
                    values={{
                        number: locations[0].fedexBillToAccount,
                    }}
                />
            )
        } else if (!is3rdPartyEnabled && isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__shipperAccount"
                    defaultMessage="shipper account number {number}"
                    values={{
                        number: locations[0].fedexFreightAccount,
                    }}
                />
            )
        } else if (is3rdPartyEnabled && isShipperEnabled) {
            accountNumbers = (
                <FormattedMessage
                    id="locations.requestAccess__shipperAndThirdPartyAccount"
                    defaultMessage="shipper account number {shipperNumber} and third party account number {thirdPartyNumber}"
                    values={{
                        shipperNumber: locations[0].fedexFreightAccount,
                        thirdPartyNumber: locations[0].fedexBillToAccount,
                    }}
                />
            )
        } else return undefined

        return (
            <FormattedMessage
                id="locations.requestAccess__alreadyRequested"
                defaultMessage="You have already requested access to the location associated with {accountNumbers}, and your access is pending. Please reach out to the location owner to be granted access to this location or sign up with a different account number."
                values={{ accountNumbers }}
            />
        )
    }

    render() {
        const {
            handleSubmit,
            submitting,
            pristine,
            invalid,
            onLogout,
            classes,
            isInvited,
            accountType,
            trackGA,
            locations,
            location,
            intl,
            preferences,
            changeField,
            locationType,
        } = this.props

        const {
            open,
            loading,
            modalContent,
            requestAccessOpen,
            duplicateLocation,
        } = this.state

        const isRequested = get(locations, "[0].users[0].access.requested.date")
        const requestedInfo = this.determineRequestedInfo()

        const FormLabelProps = {
            classes: {
                root: classes.input__label,
                label: classes.input__label,
            },
        }
        const CheckboxProps = {
            classes: {
                root: classes.checkbox,
                colorSecondary: classes.checkbox,
                checked: classes.checkbox,
            },
        }

        return (
            <Grid item container className={classes.signup__form}>
                {loading && <GlobalSpinner />}
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <Typography>{this.state.sbMessage}</Typography>
                        }
                    />
                </Snackbar>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage
                            id="signUp__invalidCredentials"
                            defaultMessage="Invalid Credentials"
                        />
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Grid item container>
                            {get(
                                modalContent,
                                "errors.error.response.validationErrors",
                                []
                            ).map(
                                errorEntry =>
                                    errorEntry && (
                                        <Typography
                                            variant="subheading"
                                            className={classes.error__entry}
                                        >
                                            {errorEntry}
                                        </Typography>
                                    )
                            )}
                            {get(
                                modalContent,
                                "errors.error.response.message"
                            ) && (
                                <Typography
                                    variant="subheading"
                                    className={classes.error__entry}
                                >
                                    {get(
                                        modalContent,
                                        "errors.error.response.message"
                                    )}
                                </Typography>
                            )}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            onClick={this.handleClose}
                            color="primary"
                            autoFocus
                        >
                            <FormattedMessage
                                id="generalTerms__close"
                                defaultMessage="Close"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
                {!isInvited && (
                    <RequestAccess
                        open={requestAccessOpen}
                        data={duplicateLocation}
                        handleClose={() => this.handleRequestAccessClose()}
                        handleRequestAccess={() => this.handleRequestAccess()}
                    />
                )}
                <Grid item container className={classes.decision__container}>
                    <Grid item container alignItems="center" justify="center">
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.decision__titleContainer}
                        >
                            <img
                                alt="LTL Select"
                                src="static/LTLselect-logo-type-new.png"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.decision__fedexContainer}
                        >
                            <a
                                href="https://www.fedexfreight.fedex.com/openacctemail.jsp"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    trackGA(
                                        "Signup",
                                        "Needs FedEx Account - Text Click"
                                    )
                                }
                            >
                                <Grid item container>
                                    <Typography
                                        variant="caption"
                                        className={classes.decision__fedexText}
                                    >
                                        <FormattedMessage
                                            id="signUp__accountPrompt1"
                                            defaultMessage="Don't have a FedEx Freight account?"
                                        />
                                    </Typography>{" "}
                                    &nbsp;
                                    <Typography
                                        variant="caption"
                                        color="secondary"
                                    >
                                        <FormattedMessage
                                            id="signUp__accountPrompt2"
                                            defaultMessage="Sign up here."
                                        />
                                    </Typography>
                                </Grid>
                            </a>
                        </Grid>
                        <Grid
                            item
                            justify="center"
                            className={classes.decision__divider}
                        />
                        {!isInvited && (
                            <Grid
                                item
                                container
                                justify="center"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    container
                                    xs={4}
                                    justify="center"
                                    alignItems="center"
                                    className={classes.signup__decision__text}
                                >
                                    <Typography variant="title" color="inherit">
                                        <FormattedMessage
                                            id="signUp__title"
                                            defaultMessage="I want to sign up with: "
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} container justify="center">
                                    <Field
                                        CheckboxProps={CheckboxProps}
                                        onClick={() =>
                                            this.handleCheckboxClick()
                                        }
                                        FormLabelProps={FormLabelProps}
                                        component={FormCheckbox}
                                        label={
                                            <FormattedMessage
                                                id="signUp__fedExShipperAccount"
                                                defaultMessage="FedEx Shipper Account"
                                            />
                                        }
                                        id="isShipper__signup"
                                        name="isShipper"
                                    />
                                </Grid>
                                <Grid item xs={4} container justify="center">
                                    <Field
                                        CheckboxProps={CheckboxProps}
                                        onClick={() =>
                                            this.handleCheckboxClick()
                                        }
                                        FormLabelProps={FormLabelProps}
                                        component={FormCheckbox}
                                        disabled={
                                            locationType === "FEDEX_FREIGHT_BOX"
                                        }
                                        label={
                                            <FormattedMessage
                                                id="signUp__fedExThirdPartyAccount"
                                                defaultMessage="FedEx Third Party Account"
                                            />
                                        }
                                        id="isThirdParty__signup"
                                        name="isThirdParty"
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <form
                    onSubmit={handleSubmit(values => this.handleSignup(values))}
                    className={classes.cards__container}
                >
                    <Grid item container>
                        <Grid item xs={6} className={classes.signup__section}>
                            <Grid
                                item
                                container
                                className={classes.signup__card}
                            >
                                <GeneralInformation
                                    country={
                                        supportedLanguagesCountryMap[
                                            preferences?.language
                                        ] || "US"
                                    }
                                />
                            </Grid>
                        </Grid>
                        {accountType.isShipper && !isInvited && (
                            <Grid
                                item
                                xs={6}
                                className={classes.signup__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.signup__card}
                                >
                                    <ShippingInformation
                                        locationTypes={shipperLocationTypes}
                                        changeField={changeField}
                                        formName={"signup"}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {!isInvited && accountType.isShipper && (
                            <Grid
                                item
                                xs={6}
                                className={classes.signup__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.signup__card}
                                >
                                    <PickUpInformation
                                        country={
                                            location?.shippingAddress?.address
                                                ?.country
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {!isInvited &&
                            (accountType.isShipper ||
                                accountType.isThirdParty) && (
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.signup__section}
                                >
                                    <Grid
                                        item
                                        container
                                        className={
                                            classes.signup__card__variableSize
                                        }
                                    >
                                        <BillingInformation
                                            isShipper={accountType.isShipper}
                                            isThirdParty={
                                                accountType.isThirdParty
                                            }
                                            locationTypes={
                                                thirdPartyLocationTypes
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>
                    {isRequested && (
                        <Grid item container justify="center">
                            <Typography variant="subheading">
                                {" "}
                                {requestedInfo}{" "}
                            </Typography>
                        </Grid>
                    )}
                    {(accountType.isShipper ||
                        accountType.isThirdParty ||
                        isInvited) && (
                        <Grid item className={classes.signup__section}>
                            <Grid
                                item
                                container
                                className={classes.terms__container}
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={8}
                                    className={classes.acceptTerms__text}
                                >
                                    <Field
                                        component={FormCheckbox}
                                        label={termsLabel(intl?.locale)}
                                        name="termsVersion"
                                        id="termsVersion"
                                    />
                                </Grid>
                                <Grid item container xs={4} justify="flex-end">
                                    <Button
                                        id="cancel__button"
                                        variant="outlined"
                                        onClick={() => onLogout()}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            pristine || invalid || submitting
                                        }
                                        className={classes.signup__button}
                                        id="signUp__button"
                                    >
                                        <FormattedMessage
                                            id="signUp__signUp"
                                            defaultMessage="Sign Up"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {!accountType.isShipper &&
                        !accountType.isThirdParty &&
                        !isInvited && (
                            <Grid item container direction="column">
                                <Grid item className={classes.bottom__push} />
                                <Grid
                                    item
                                    container
                                    className={classes.terms__container}
                                    justify="flex-end"
                                >
                                    <Button
                                        variant="outlined"
                                        onClick={() => onLogout()}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                </form>
            </Grid>
        )
    }
}

SignUpForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSignUpSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    invalid: PropTypes.bool,
    onLogOut: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    onSameAsShipping: PropTypes.func.isRequired,
    onSameAsUserInfo: PropTypes.func.isRequired,
    error: PropTypes.string,
}

SignUpForm.defaultProps = {
    submitting: false,
    pristine: false,
    invalid: true,
    error: "",
}

const userSelector = createSelector(
    state => state.user.profile,
    getFormValues("signup"),
    (state, props) => props,
    (user, form, props) => {
        let { locations, ...innerUser } = user || {}
        locations = get(user, "locations", [])
        const isInvited = locations.some(item =>
            get(item, "users[0].access.invited")
        )

        const defaultLanguage = props?.intl?.locale
        const defaultCountry =
            supportedLanguagesCountryMap[defaultLanguage] || "US"

        return {
            isInvited,
            initialValues: {
                ...innerUser,
                notificationType: "none",
                preferences: {
                    language: defaultLanguage ? defaultLanguage : "en-us",
                },
                location: isInvited
                    ? undefined
                    : {
                          address: {
                              country: defaultCountry,
                          },
                          shippingAddress: {
                              address: {
                                  country: defaultCountry,
                              },
                          },
                          sameAsShippingAddress: false,
                      },
            },
            accountType: {
                isShipper: get(form, "isShipper"),
                isThirdParty: get(form, "isThirdParty"),
            },
            locationType: form?.location?.locationType,
            formValues: form,
            locations,
        }
    }
)(state => get(state, "user.profile._id", ""))

const mapStateToProps = userSelector

const mapDispatchToProps = dispatch => ({
    onSignUpSubmit: values => dispatch(signupSubmit(values)),
    onLogout: () => {
        dispatch(trackGAEventNew("Signup", "Cancel Button Click"))
        dispatch(logOut())
    },
    requestAccessToLocation: location =>
        dispatch(requestAccessToLocation(location)),
    changeField: (form, field, value) => dispatch(change(form, field, value)),
    trackGA: (category, action) => dispatch(trackGAEventNew(category, action)),
})

const userValidation = combineValidators({
    firstname: isRequired({
        field: {
            id: "userPreferences.generalInformation__firstName",
            defaultMessage: "First Name",
        },
    }),
    lastname: isRequired({
        field: {
            id: "userPreferences.generalInformation__lastName",
            defaultMessage: "Last Name",
        },
    }),
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "userPreferences.generalInformation__email",
                defaultMessage: "Email Address",
            },
        }),
    },
    phone_number: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(undefined, "preferences.language")
        )({
            field: {
                id: "userPreferences.generalInformation__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "userPreferences.generalInformation__extension",
                defaultMessage: "Extension",
            },
        }),
        mobile: countryPhoneValidator(
            undefined,
            "preferences.language"
        )({
            field: {
                id: "userPreferences.generalInformation__mobileNumber",
                defaultMessage: "Mobile Number",
            },
        }),
    },
    termsVersion: acceptValidator({
        field: {
            id: "signUp__termsAndConditions",
            defaultMessage: "Terms and Conditions",
        },
    }),
    "preferences.language": isRequired({
        field: {
            id: "userPreferences.generalInformation__language",
            defaultMessage: "Language",
        },
    }),
    location: formValidation,
})

export default injectIntl(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                form: "signup", // a unique identifier for this form
                validate: userValidation,
            })(SignUpForm)
        )
    )
)
