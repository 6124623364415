import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Input from "@material-ui/core/Input"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import {
    Grid,
    FormControl,
    InputLabel,
    FormHelperText,
} from "@material-ui/core"
import Select from "react-select"
import "react-select/dist/react-select.css"
import FormattedError from "./formatted-error"
import { FormattedMessage } from "react-intl"
import FormItemSearchOption from "./form-item-search-option"
import { connect } from "react-redux"
import { itemSearchHash } from "../../actions/item"
import Spinner from "../util/spinner"

function SelectWrapped(props) {
    const {
        classes,
        options = [],
        onItemSearchType,
        searchedItems,
        searchInProgress,
        ...other
    } = props

    const customFilter = (option, match) => {
        return option.description
            .toString()
            .toLowerCase()
            .includes(match)
    }

    const customStyles = {
        input: (provided, state) => ({
            // none of react-select's styles are passed to <Control />
            backgroundColor: "red",
            ...provided,
        }),
    }

    return (
        <Select
            {...other}
            styles={customStyles}
            options={options.map(option => ({
                value: option._id,
                ...option,
            }))}
            optionComponent={FormItemSearchOption}
            onInputChange={a => {
                onItemSearchType(a, undefined, undefined)
            }}
            onChange={id => {
                props.onChange(options.find(opt => opt?._id === id))
            }}
            noResultsText={
                searchInProgress ? (
                    <Spinner isGlobal={false} />
                ) : (
                    <Typography>
                        <FormattedMessage
                            id="generalTerms__noResults"
                            defaultMessage="No results found"
                        />
                    </Typography>
                )
            }
            arrowRenderer={arrowProps =>
                arrowProps.isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
            }
            style={{
                minWidth: "200px",
            }}
            filterOption={customFilter}
        />
    )
}
const ITEM_HEIGHT = 48

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    chip: {
        margin: theme.spacing.unit / 4,
    },
    form__container: {
        padding: "3px",
        width: "100%",
    },
    "@global": {
        ".Select-control": {
            display: "flex",
            alignItems: "center",
            border: 0,
            height: "auto",
            background: "transparent",
            "&:hover": {
                boxShadow: "none",
            },
            paddingLeft: "0px",
        },
        ".Select-multi-value-wrapper": {
            flexGrow: 1,
            display: "flex",
            flexWrap: "wrap",
        },
        ".Select--multi .Select-input": {
            margin: 0,
        },
        ".Select.has-value.is-clearable.Select--single > .Select-control .Select-value": {
            padding: 0,
        },
        ".Select-noresults": {
            padding: theme.spacing.unit * 2,
        },
        ".Select-input": {
            display: "inline-flex !important",
            padding: 0,
            height: "auto",
        },
        ".Select--single > .Select-control .Select-value": {
            paddingLeft: "0px",
        },
        ".Select--single": {
            paddingBottom: "6px",
            paddingTop: "5px",
        },
        ".Select-input input": {
            background: "transparent",
            border: 0,
            padding: 0,
            cursor: "default",
            display: "inline-block",
            fontFamily: "inherit",
            fontSize: "inherit",
            margin: 0,
            outline: 0,
        },
        ".Select-placeholder, .Select--single .Select-value": {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.pxToRem(16),
            padding: 0,
        },
        ".Select-placeholder": {
            opacity: 0.42,
            color: theme.palette.common.black,
        },
        ".Select-menu-outer": {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[2],
            position: "absolute",
            left: 0,
            top: `calc(100% + ${theme.spacing.unit}px)`,
            width: "100%",
            zIndex: 2,
            maxHeight: ITEM_HEIGHT * 4.5,
        },
        ".Select-value": {
            paddingLeft: "0px",
        },
        ".Select.is-focused:not(.is-open) > .Select-control": {
            boxShadow: "none",
            backgroundColor: "transparent",
        },
        ".Select.is-focused > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select-menu": {
            maxHeight: ITEM_HEIGHT * 4.5,
            overflowY: "auto",
        },
        ".Select-menu div": {
            boxSizing: "content-box",
        },
        ".Select-arrow-zone, .Select-clear-zone": {
            color: theme.palette.action.active,
            cursor: "pointer",
            height: 21,
            width: 21,
            zIndex: 1,
        },
        // Only for screen readers. We can't use display none.
        ".Select-aria-only": {
            position: "absolute",
            overflow: "hidden",
            clip: "rect(0 0 0 0)",
            height: 1,
            width: 1,
            margin: -1,
        },
        ".Select.is-disabled > .Select-control": {
            backgroundColor: "transparent",
        },
        ".Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label": {
            color: "currentColor",
        },
    },
})

const FormSelectItemSearch = ({
    input,
    label,
    options,
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    selectClasses,
    customValue,
    meta: { touched, error },
    required,
    onItemSearchType,
    searchedItems,
    term,
    searchInProgress,
    ...rest
}) => {
    return (
        <Grid
            item
            className={`${classnames({
                "form-field": true,
                "form-field-touched": touched,
                "form-field-invalid": touched && error,
                "form-field-valid": !error,
            })} ${classes.form__container}`}
        >
            <Grid item container direction="column">
                <FormControl error={!!(touched && error)}>
                    <InputLabel
                        required={required}
                        {...InputLabelProps}
                        htmlFor={input.name}
                        id={`${input.name}-label`}
                    >
                        {label}
                    </InputLabel>
                    <Input
                        {...rest}
                        placeholder=""
                        inputComponent={SelectWrapped}
                        classes={selectClasses}
                        id={input.name}
                        inputProps={{
                            id: input.name,
                            classes,
                            name: input.name,
                            instanceId: input.name,
                            simpleValue: true,
                            clearable: false,
                            searchedItems,
                            onItemSearchType,
                            searchInProgress,
                            options:
                                term.toString().length >= 3
                                    ? searchedItems
                                    : options,
                        }}
                        {...InputProps}
                        {...input}
                        onBlur={() => input.onBlur(input.value)}
                    />
                    {touched && error && (
                        <FormHelperText {...InputHelperProps}>
                            <FormattedError error={error} />
                        </FormHelperText>
                    )}
                </FormControl>
            </Grid>
        </Grid>
    )
}

FormSelectItemSearch.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { term }) => ({
    searchedItems:
        state.item.search[itemSearchHash(term, undefined, undefined)],
    searchInProgress: state.item.searchInProgress,
})

export default connect(mapStateToProps)(
    withStyles(styles)(FormSelectItemSearch)
)
