import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import {
    FormControl,
    Input,
    InputLabel,
    InputAdornment,
    FormHelperText,
} from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormattedError from "./formatted-error"

const styles = theme => ({
    form__container: {
        padding: "3px",
        width: "100%",
    },
    form__control: {
        width: "100%",
    },
})

const FormField = ({
    input,
    label,
    type,
    autoComplete,
    meta: { touched, error, asyncValidating },
    placeholder,
    maxLength,
    classes,
    InputProps,
    InputLabelProps,
    InputHelperProps,
    helperText,
    required,
    dataTestId,
    disableBrowserAutocomplete,
    ...rest
}) => (
    <Grid
        item
        container
        className={`${classnames({
            "form-field": true,
            "form-field-touched": touched,
            "form-field-invalid": touched && error,
            "form-field-valid": !error,
        })} ${classes.form__container}`}
    >
        <Grid item container direction="column">
            <FormControl
                error={!!(touched && error)}
                className={classes.form__control}
            >
                <InputLabel
                    htmlFor={input.name}
                    {...InputLabelProps}
                    required={required}
                    id={`${input.name}-label`}
                >
                    {label}
                </InputLabel>
                <Input
                    placeholder={placeholder}
                    id={input.name}
                    type={type}
                    inputProps={{
                        dataTestId,
                        autocomplete: disableBrowserAutocomplete ? "off" : null,
                    }}
                    margin="none"
                    autoComplete={autoComplete}
                    maxLength={maxLength || undefined}
                    endAdornment={
                        asyncValidating && (
                            <InputAdornment position="end">
                                <CircularProgress size={24} color="secondary" />
                            </InputAdornment>
                        )
                    }
                    {...InputProps}
                    {...input}
                    {...rest}
                />
                {helperText && (
                    <FormHelperText {...InputHelperProps}>
                        {helperText}
                    </FormHelperText>
                )}
                {touched && error && (
                    <FormHelperText {...InputHelperProps}>
                        <FormattedError error={error} />
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>
    </Grid>
)

FormField.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    type: PropTypes.oneOf([
        "text",
        "email",
        "file",
        "number",
        "tel",
        "checkbox",
        "password",
        "date",
    ]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoComplete: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    classes: PropTypes.object,
    helperText: PropTypes.string,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputHelperProps: PropTypes.object,
    required: PropTypes.bool,
}

FormField.defaultProps = {
    meta: {},
    autoComplete: undefined,
    maxLength: undefined,
    placeholder: undefined,
    label: undefined,
    type: undefined,
    input: undefined,
    classes: {},
    helperText: undefined,
    required: false,
}

export default withStyles(styles)(FormField)
