import React, { useState } from "react"
import { ClickAwayListener, Grid, Popper, Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../form/form-field"
import { makeStyles } from "@material-ui/styles"
import { SuggestionBox } from "../bookShipment/atoms/SuggestionBox"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import { supportedCountryCodes } from "../../../misc"
import Accessorials from "../bookShipment/atoms/Accessorials"
import FormSwitch from "../../form/form-switch"
import FormZipCode from "../../form/form-zip-code"
import FormSelectAutocompleteNew from "../../form/form-select-autocomplete-new"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
    },
    title: {
        paddingBottom: "5px",
    },
    accessorials: {
        paddingTop: "10px",
    },
})

export default function Origin({
    textInputProps,
    performContactSearch,
    contactSearchInProgress,
    formValues = {},
    changeField,
    initialValues,
    onPostalCodeChange,
    pickupAccessorialsList,
    cities = [],
}) {
    const classes = useStyles()
    const [showOverlay, setShowOverlay] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const {
        origin,
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
    } = formValues

    const handleInputFocus = (field, e) => {
        if (field === "searchContact") {
            setShowOverlay(true)
            setAnchorEl(e.currentTarget)
        }
    }

    const handleClickAway = () => {
        if (showOverlay) {
            setShowOverlay(false)
            setAnchorEl(null)
        }
    }

    const handleOptionSelect = (option = {}) => {
        setShowOverlay(false)
        setAnchorEl(null)
        resetOrigin()
        changeField("origin", {
            shippingAddress: {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
            },
            readyTime: option?.contact?.pickupContact?.readyTime,
            closeTime: option?.contact?.pickupContact?.closeTime,
            pickupContact: {
                name: option?.contact?.pickupContact?.name,
                phone: {
                    phone_number:
                        option?.contact?.pickupContact?.phone?.phoneNumber,
                    extension: option?.contact?.pickupContact?.phone?.extension,
                },
                email: {
                    email_address:
                        option?.contact?.pickupContact?.email?.emailAddress,
                },
            },
            contact: {
                name: option?.contact?.name,
                phone: {
                    phone_number: option?.contact?.phone?.phoneNumber,
                    extension: option?.contact?.phone?.extension,
                },
                email: {
                    email_address: option?.contact?.email?.emailAddress,
                },
            },
        })
        if (option?.pickupAccessorials?.length) {
            changeField("origin.showAccessorials", true)
            changeField(
                "pickupAccessorials",
                (option?.pickupAccessorials ?? []).map(entry => entry.value)
            )
        } else {
            changeField("origin.showAccessorials", false)
            changeField("pickupAccessorials", initialValues?.pickupAccessorials)
        }
    }

    const handleCitySelect = (city, state, country, postalCode) => {
        setShowOverlay(false)
        setAnchorEl(null)
        resetOrigin()
        changeField("origin", {
            shippingAddress: {
                address: {
                    city,
                    state,
                    country,
                    postalCode,
                },
            },
        })
    }

    const resetOrigin = () => {
        changeField("origin", {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.origin?.shippingAddress?.address
                            ?.country,
                },
            },
        })
        changeField("pickupAccessorials", initialValues?.pickupAccessorials)
    }

    return (
        <Grid item container alignContent="flex-start">
            <Popper
                open={showOverlay}
                anchorEl={anchorEl}
                placement="bottom-end"
                modifiers={{
                    flip: {
                        enabled: true,
                    },
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                }}
                className={classes.popper}
            >
                <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClickAway()}
                >
                    <div>
                        <SuggestionBox
                            searchInProgress={contactSearchInProgress}
                            addressesNeeded
                            field={"origin"}
                            term={origin?.searchContact ?? ""}
                            handleAddressSelect={handleOptionSelect}
                            country={origin?.shippingAddress?.address?.country}
                            handleCitySelect={handleCitySelect}
                        />
                    </div>
                </ClickAwayListener>
            </Popper>
            <Grid item container className={classes.title}>
                <Typography variant="subheading">
                    <FormattedMessage
                        id="bookShipment.basicInfo__origin"
                        defaultMessage="Origin Information"
                    />
                </Typography>
            </Grid>
            <Grid item container>
                <Grid item container xs={2}>
                    <Field
                        component={FormSelectAutocomplete}
                        name="origin.shippingAddress.address.country"
                        label={
                            <FormattedMessage
                                id="generalTerms__country"
                                defaultMessage="Country"
                            />
                        }
                        disabled={isFreightBox}
                        options={supportedCountryCodes}
                        onChange={(e, value) => {
                            changeField("origin", {
                                shippingAddress: {
                                    address: {
                                        country: value,
                                    },
                                },
                            })
                            changeField(
                                "pickupAccessorials",
                                initialValues?.pickupAccessorials
                            )
                        }}
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Field
                        name="origin.searchContact"
                        label={
                            <FormattedMessage
                                id="bookShipment.form__zipOrCompanyName"
                                defaultMessage="Search Zip or Company Name"
                            />
                        }
                        component={FormField}
                        onChange={e => {
                            setShowOverlay(true)
                            setAnchorEl(e.currentTarget)
                            performContactSearch(
                                e.target.value,
                                origin?.shippingAddress?.address?.country
                            )
                            onPostalCodeChange(
                                e.target.value,
                                "origin.shippingAddress.address",
                                origin?.shippingAddress?.address?.country,
                                true
                            )
                        }}
                        autoFocus
                        onFocus={e => handleInputFocus("searchContact", e)}
                        InputProps={textInputProps}
                        disableBrowserAutocomplete
                    />
                </Grid>
                <Grid item container>
                    <Grid item container xs={2}>
                        <Field
                            name="origin.shippingAddress.address.postalCode"
                            label={
                                <FormattedMessage
                                    id="generalTerms__zipPostalCode"
                                    defaultMessage="Zip/Postal Code"
                                />
                            }
                            required
                            component={FormZipCode}
                            form="bookShipment"
                            country={
                                formValues?.origin?.shippingAddress?.address
                                    ?.country
                            }
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <Field
                            name="origin.shippingAddress.address.city"
                            label={
                                <FormattedMessage
                                    id="generalTerms__city"
                                    defaultMessage="City"
                                />
                            }
                            required
                            component={FormSelectAutocompleteNew}
                            options={cities.map(c => ({
                                value: c,
                                label: c,
                            }))}
                            customValue
                        />
                    </Grid>
                    <Grid item container xs={2}>
                        <Field
                            name="origin.shippingAddress.address.state"
                            label={
                                <FormattedMessage
                                    id="generalTerms__state"
                                    defaultMessage="State"
                                />
                            }
                            required
                            component={FormField}
                            inputProps={{ maxLength: 2 }}
                        />
                    </Grid>
                    {!isFreightDirect && !isFreightDirectReturns && (
                        <Grid
                            item
                            container
                            xs={4}
                            alignItems="flex-end"
                            justify="flex-end"
                        >
                            <Field
                                name={`origin.showAccessorials`}
                                component={FormSwitch}
                                label={
                                    <FormattedMessage
                                        id="bookShipment.basicInfo__addPickupAccessorials"
                                        defaultMessage="Add Pickup Accessorials"
                                    />
                                }
                                onChange={(e, value) => {
                                    if (!value) {
                                        changeField("pickupAccessorials", [
                                            "DOCKPU",
                                        ])
                                    }
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item container>
                {!isFreightDirect &&
                    !isFreightDirectReturns &&
                    origin?.showAccessorials && (
                        <Grid
                            item
                            container
                            alignContent="flex-start"
                            className={classes.accessorials}
                        >
                            <Accessorials
                                list={pickupAccessorialsList ?? []}
                                name={"pickupAccessorials"}
                                quickRate
                                formName="quickRate"
                                formValues={formValues}
                            />
                        </Grid>
                    )}
            </Grid>
        </Grid>
    )
}
