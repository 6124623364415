import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field, formValues } from "redux-form"
import { makeStyles } from "@material-ui/styles"
import FormField from "../../../form/form-field"
import Button from "@material-ui/core/Button"
import { weightUnit } from "../../../util/units"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    info__container: {
        marginTop: "8px",
        paddingBottom: "8px",
    },

    resetButton: {
        marginTop: "35px",
        marginLeft: "5px",
    },
})

const HazMatDetails = formValues({
    handlingUnits: "handlingUnits",
    hazmatEmergency: "hazmatEmergency",
    preferredSystemOfMeasurement: "preferredSystemOfMeasurement",
})(
    ({
        hazmatEmergency,
        handlingUnits = [],
        changeField,
        preferredSystemOfMeasurement,
        hazMatContact,
    }) => {
        const buildDescription = (item, hazmatEmergency) => {
            return `${item?.hazMatContainer},${item?.hazMatUnNumber},${item?.description},${item?.hazMatClass},PG${item?.hazMatPkgGrp},Emergency Contact:${hazmatEmergency?.phone?.phone_number},ERI Provider: ${hazmatEmergency?.name}`
        }

        const setItemsHazMatDescription = hazmatEmergency => {
            handlingUnits.forEach((hu, index) =>
                hu.items.forEach((item, itemIndex) => {
                    const field = `handlingUnits[${index}].items[${itemIndex}].hazMatDescription`
                    changeField(field, buildDescription(item, hazmatEmergency))
                })
            )
        }
        useEffect(() => {
            setItemsHazMatDescription(hazMatContact)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        const classes = useStyles()
        return (
            <React.Fragment>
                <Grid xs={12} item>
                    <Grid>
                        <Grid item container justify="center">
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="orderDetails.hazmat__info"
                                    defaultMessage="Review and Confirm HazMat Description"
                                />
                            </Typography>
                        </Grid>
                        <Grid container>
                            <Grid item container xs={12}>
                                <Typography variant="caption">
                                    <Typography variant="caption">
                                        <FormattedMessage
                                            id="orderDetails.hazmat__details"
                                            defaultMessage="Please verify that the below hazardous materials shipment description is compliant with DOT/PHMSA regulations. Make edits by adding other required information, including but not limited to, subsidiary hazard class(es), chemical group/technical name(s), hazard zone, DOT special permit number, EX or FC number, net explosive weight, and reportable quantity or marine pollutant or inhalation hazard designation."
                                        />
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    direction="row"
                                    className={classes.info__container}
                                >
                                    {handlingUnits.map((hu, index) =>
                                        hu.items.map((item, itemIndex) => {
                                            const prefix = `handlingUnits[${index}].items[${itemIndex}]`
                                            if (item.isHazMat) {
                                                return (
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                        justify="flex-start"
                                                        className={
                                                            classes.info__container
                                                        }
                                                    >
                                                        <Grid item xs={11}>
                                                            <Typography variant="subheading">
                                                                {`${
                                                                    item.description
                                                                }, Class ${
                                                                    item.freightClass
                                                                }, ${
                                                                    item.weight
                                                                }${weightUnit(
                                                                    preferredSystemOfMeasurement
                                                                )}`}
                                                            </Typography>
                                                            <Field
                                                                name={`${prefix}.hazMatDescription`}
                                                                label={[
                                                                    <FormattedMessage
                                                                        id="orderDetails.hazmat__info__item"
                                                                        defaultMessage="Adjust HazMat Descriptions"
                                                                    />,
                                                                ]}
                                                                type="textarea"
                                                                component={
                                                                    FormField
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={1}
                                                            justify="center"
                                                            alignItems="flex-end"
                                                        >
                                                            <Button
                                                                id="hazMatDescriptionReset"
                                                                variant="outlined"
                                                                color="primary"
                                                                className={
                                                                    classes.resetButton
                                                                }
                                                                disabled={
                                                                    !hazmatEmergency?.name ||
                                                                    !hazmatEmergency
                                                                        ?.phone
                                                                        ?.phone_number ||
                                                                    item?.hazMatDescription ===
                                                                        buildDescription(
                                                                            item,
                                                                            hazmatEmergency
                                                                        )
                                                                }
                                                                onClick={() => {
                                                                    setItemsHazMatDescription(
                                                                        hazmatEmergency
                                                                    )
                                                                }}
                                                            >
                                                                <FormattedMessage
                                                                    id="orderDetails_revert"
                                                                    defaultMessage="Revert"
                                                                />
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                            return null
                                        })
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
)

export default HazMatDetails
