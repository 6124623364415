import React, { useState, useEffect } from "react"
import { withStyles } from "@material-ui/core/styles"
import { tableStyles } from "./styles"
import Grid from "@material-ui/core/Grid"
import Table from "@material-ui/core/Table"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import TablePagination from "@material-ui/core/TablePagination"
import Paper from "@material-ui/core/Paper"
import DiscountTableHead from "./DiscountsTableHead"
import Spinner from "../../util/spinner"
import DiscountsModalAndSnackbar from "./DiscountsModalAndSnackbar"
import DiscountSearchBar from "../DiscountsSearchBar"
import { connect } from "react-redux"
import { get } from "lodash"
import { fetchDiscounts } from "../../../actions/freightdirect-discount"
import DiscountsTableBody from "./DiscountsTableBody"

const DiscountsTable = ({ classes, discounts, requestDiscounts }) => {
    const [order, setOrder] = useState("asc")
    const [orderBy, setOrderBy] = useState("accountType")
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [isLoading, setLoading] = useState(false)
    const [discountState, setDiscountState] = useState([])
    const [sbOpen, setSbOpen] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [sbVariant] = useState("")
    const [sbMessage] = useState("")
    const [uploadModalOpen, setUploadModalOpen] = useState(false)

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            await requestDiscounts()
            setLoading(false)
        }
        fetchData()
    }, [requestDiscounts, setDiscountState])

    const handleRequestSort = property => {
        const isAsc = orderBy === property && order === "asc"
        const setOrderValue = isAsc ? "desc" : "asc"
        setOrder(setOrderValue)
        setOrderBy(property)
    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setSbOpen(false)
    }

    const handleModal = bool => {
        setUploadModalOpen(bool)
    }
    const onSearch = value => {
        if (value.length < 4) return
        const filteredDiscounts = discounts.filter(discount =>
            get(discount, "accountNumber").includes(value)
        )
        setIsSearch(true)
        setDiscountState(filteredDiscounts)
    }
    const handleResetDiscounts = search => {
        setDiscountState(discounts)
        if (search) {
            setSearchValue(search)
        } else {
            setSearchValue("")
        }
        setIsSearch(false)
    }
    const discountsToMap = isSearch ? discountState : discounts
    return isLoading ? (
        <Spinner />
    ) : (
        <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.root}
        >
            <Grid xs={12} item container>
                <DiscountsModalAndSnackbar
                    {...{
                        sbOpen,
                        classes,
                        handleSnackbarClose,
                        handleModal,
                        uploadModalOpen,
                        sbVariant,
                        sbMessage,
                        requestDiscounts,
                    }}
                />
                <Grid
                    container
                    justify="space-around"
                    alignItems="center"
                    className={classes.input__grid}
                >
                    <Grid container xs="6">
                        <DiscountSearchBar
                            {...{
                                setSearchValue,
                                searchValue,
                                onSearch,
                                handleResetDiscounts,
                            }}
                        />
                    </Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleModal(true)}
                    >
                        <FormattedMessage
                            id="discounts.upload_discounts"
                            defaultMessage="Upload"
                        />
                    </Button>
                </Grid>
            </Grid>
            <Grid xs={12} item container justify="center" alignContent="center">
                <Paper className={classes.paper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <DiscountTableHead
                            {...{
                                classes,
                                order,
                                orderBy,
                                handleRequestSort,
                            }}
                        />
                        <DiscountsTableBody
                            {...{
                                discountsToMap,
                                order,
                                orderBy,
                                page,
                                rowsPerPage,
                                classes,
                                isSearch,
                            }}
                        />
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={discountsToMap.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, props) => ({
    ...state.discounts,
})

const mapDispatchToProps = dispatch => ({
    requestDiscounts: () => dispatch(fetchDiscounts()),
})

export default withStyles(tableStyles)(
    connect(mapStateToProps, mapDispatchToProps)(DiscountsTable)
)
