import { get } from "lodash"
import { defineMessages } from "react-intl"
import { combineValidators } from "revalidate"
import composeValidators from "revalidate/lib/composeValidators"
import {
    countryPhoneValidator,
    emailValidator,
    hasHazMatIdPrefix,
    hasLength,
    hasLengthGreaterThan,
    hasLengthLessThan,
    isConsigneeImporter,
    isGreaterThan,
    isInternationalShipment,
    isNumeric,
    isOneOf,
    isOneOfSilent,
    isRequired,
    isRequiredIf,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    nmfcSubcodeValidator,
    nmfcValidator,
    requiredNaftaDeclaration,
    packageTypeValidator,
} from "../../../actions/validation"
import { isRequiredIfNonDomesticOrOffshoreWithItemCustoms } from "../../book"
import { isRequiredIfPickedUp, pickupWindowValidator } from "../../book/pickup"
import {
    freightClassValues,
    hazClassValues,
    heightValidator,
    pkgGrpValues,
    widthValidator,
    hazContainersValues,
} from "../../quotesPage/quotesDrawer"

const messages = defineMessages({
    nameField: {
        id: "generalTerms__companyName",
        defaultMessage: "Company Name",
    },
    address1OrAddress2Field: {
        id: "generalTerms__address1OrAddress2",
        defaultMessage: "Address 1 or Address 2",
    },
    cityField: { id: "generalTerms__city", defaultMessage: "City" },
    stateField: { id: "generalTerms__state", defaultMessage: "State" },
    countryField: { id: "generalTerms__country", defaultMessage: "Country" },
    postalCodeField: {
        id: "generalTerms__postalCode",
        defaultMessage: "Postal Code",
    },
    contactNameField: {
        id: "generalTerms__contactName",
        defaultMessage: "Contact Name",
    },
    phoneField: {
        id: "generalTerms__contactPhone",
        defaultMessage: "Contact Phone",
    },
    extensionField: {
        id: "generalTerms__contactExtension",
        defaultMessage: "Contact Extension",
    },
    emailField: {
        id: "generalTerms__contactEmail",
        defaultMessage: "Contact Email",
    },
    pickupWindowValidator: {
        id: "orderDetails.validation__pickupWindow",
        defaultMessage:
            "{field} should be at least 90 minutes after Ready Time",
    },
    pickupReadyField: {
        id: "generalTerms.pickUpInformation__readyTime",
        defaultMessage: "Ready Time",
    },
    pickupCloseField: {
        id: "generalTerms.pickUpInformation__closeTime",
        defaultMessage: "Close Time",
    },
    pickupNameField: {
        id: "generalTerms__name",
        defaultMessage: "Name",
    },
    pickupPhoneField: {
        id: "generalTerms__phone",
        defaultMessage: "Phone",
    },
    pickupExtensionField: {
        id: "generalTerms__extension",
        defaultMessage: "Extension",
    },
    pickupEmailField: {
        id: "generalTerms__email",
        defaultMessage: "Email",
    },
})

const isRequiredIfStreet1MissingV2 = (prefix = "") =>
    isRequiredIf(
        values =>
            !get(
                values,
                prefix ? `${prefix}.address.street1` : "address.street1"
            )
    )

const isRequiredIfStreet2MissingV2 = (prefix = "") =>
    isRequiredIf(
        values =>
            !get(
                values,
                prefix ? `${prefix}.address.street2` : "address.street2"
            )
    )

export const isRequiredIfAddressEmpty = (prefix = "") => {
    return isRequiredIf(values => {
        return !values?.[prefix]?.shippingAddress?.address?.postalCode
    })
}

export const isRequiredIfIntlAndNoBrokerName = (prefix = "") =>
    isRequiredIf(
        values =>
            isInternationalShipment(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country")
            ) && !values?.[prefix]?.brokerName
    )

export const isRequiredIfIsAdvancedItems = isRequiredIf(
    values => values?.isAdvancedItems
)

export const addBrokerValidator = combineValidators({
    brokerName: isRequired({
        field: {
            id: "generalTerms__brokerName",
            defaultMessage: "Broker Name",
        },
    }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator("address.country", null, "US")
        )({
            field: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
        }),
    },
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
        }),
    },
})

export const addressValidator = (prefix = "") => ({
    name: isRequired({ field: messages.contactNameField }),
    address: {
        street1: isRequiredIfStreet2MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfStreet1MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: isRequired({ field: messages.postalCodeField }),
        country: isRequired({ field: messages.countryField }),
    },
})

export const addressQuickRateValidator = () => ({
    address: {
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: isRequired({ field: messages.postalCodeField }),
    },
})

export const contactValidator = country => ({
    name: isRequired({ field: messages.nameField }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(country)
        )({
            field: messages.phoneField,
        }),
        extensionField: isNumeric({
            field: messages.extensionField,
        }),
    },
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: messages.emailField,
        }),
    },
})

const isRequiredIfIntl = isRequiredIf(values =>
    isInternationalShipment(
        get(values, "origin.shippingAddress.address.country"),
        get(values, "destination.shippingAddress.address.country")
    )
)

export const isRequiredIfIntlAndSelectGenerateInvoice = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice")
)

const isRequiredIfNotIntraMexico = isRequiredIf(
    values =>
        !(
            get(values, "origin.shippingAddress.address.country") === "MX" &&
            get(values, "destination.shippingAddress.address.country") === "MX"
        )
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        )
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        requiredNaftaDeclaration(
            get(values, "commercialInvoice.naftaDeclaration", false)
        )
)

const isRequiredIfStreet1MissingImporterCommercial = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street1")
)

const isRequiredIfStreet2MissingImporterCommercial = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street2")
)

export const customsCommercialInvoiceValidation = () => ({
    termsOfSale: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__termsOfSale",
            defaultMessage: "Terms Of Sale",
        },
    }),
    dutiesPaidBy: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id:
                "orderDetails.support__documentation__select__duties_payable_by",
            defaultMessage: "Duties Payable By",
        },
    }),
    naftaLowValueRole: isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration(
        {
            field: {
                id: "orderDetails.support__documentation__naftaLowValueRole",
                defaultMessage: "USMCA Status",
            },
        }
    ),
    importerOfRecord: {
        taxIdentification: {
            type: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.number"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_select__tax__type",
                    defaultMessage: "Select Importer Tax Identification Type",
                },
            }),
            number: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.type"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer__tax__identification__number",
                    defaultMessage: " Importer Tax Identification Number",
                },
            }),
        },
        name: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter({
            field: {
                id:
                    "orderDetails.support__documentation__importer__companyName",
                defaultMessage: " Importer Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2MissingImporterCommercial({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address1",
                    defaultMessage: "Importer Address 1",
                },
            }),
            street2: isRequiredIfStreet1MissingImporterCommercial({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address2",
                    defaultMessage: "Importer Address 2",
                },
            }),
            postalCode: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer_zipPostalCode",
                        defaultMessage: "Importer Zip/Postal Code",
                    },
                }
            ),
            city: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__city",
                        defaultMessage: "Importer City",
                    },
                }
            ),
            country: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__country",
                        defaultMessage: "Importer Country",
                    },
                }
            ),
            state: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__state",
                        defaultMessage: "Importer State/Province",
                    },
                }
            ),
        },
    },
})

export const customsBrokerValidationV2 = (countryField, prefix) => ({
    selectBroker: isRequiredIfIntlAndNoBrokerName(prefix)({
        field: {
            id: "addBroker__selectBroker",
            defaultMessage: "Select Existing Broker",
        },
    }),
    brokerName: isRequiredIfIntl({
        field: {
            id: "generalTerms__brokerName",
            defaultMessage: "Broker Name",
        },
    }),
    phone: {
        phone_number: composeValidators(
            isRequiredIfIntl,
            countryPhoneValidator(countryField)
        )({
            field: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
        }),
    },
    email: {
        email_address: composeValidators(
            isRequiredIfIntl,
            emailValidator
        )({
            field: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
        }),
    },
    address: {
        street1: isRequiredIfStreet2MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfStreet1MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: isRequired({ field: messages.postalCodeField }),
        country: isRequired({ field: messages.countryField }),
    },
})

export const customsBrokerValidation = countryField => ({
    name: isRequiredIfIntl({
        field: {
            id: "generalTerms__companyName",
            defaultMessage: "Company Name",
        },
    }),
    contact: {
        phone: {
            phone_number: composeValidators(
                isRequiredIfIntl,
                countryPhoneValidator(countryField)
            )({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
        email: {
            email_address: composeValidators(
                isRequiredIfIntl,
                emailValidator
            )({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
    },
    address: {
        street1: isRequiredIfStreet2MissingV2("")({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfStreet1MissingV2("")({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: isRequired({ field: messages.postalCodeField }),
        country: isRequired({ field: messages.countryField }),
    },
})

export const pickupValidator = countryField => ({
    readyTime: isRequiredIfPickedUp({
        field: messages.pickupReadyField,
    }),
    closeTime: composeValidators(
        isRequiredIfPickedUp,
        pickupWindowValidator
    )({
        field: messages.pickupCloseField,
    }),
    pickupContact: {
        name: isRequiredIfPickedUp({ field: messages.pickupNameField }),
        phone: {
            phone_number: composeValidators(
                isRequiredIfPickedUp,
                countryPhoneValidator(countryField)
            )({
                field: messages.pickupPhoneField,
            }),
            extension: isNumeric({
                field: messages.pickupExtensionField,
            }),
        },
        email: {
            email_address: composeValidators(
                isRequiredIfPickedUp,
                emailValidator
            )({
                field: messages.pickupEmailField,
            }),
        },
    },
})

export const handlingUnitsValidator = {
    "handlingUnits[].items[].nmfcCode": nmfcValidator,
    "handlingUnits[].items[].nmfcSubclass": nmfcSubcodeValidator,
    "handlingUnits[].items[].countryOfManufacture": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items__countryOfManufacture",
                defaultMessage: "Country of Manufacture",
            },
        }
    ),
    "handlingUnits[].items[].unitPrice": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items_unitPriceNoCurrency",
                defaultMessage: "Unit Price",
            },
        }
    ),
    "handlingUnits[].items[].unit": isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__No__of__units",
            defaultMessage: " {itemName} No. of units",
            values: {
                itemName: "",
            },
        },
    }),
    "handlingUnits[].items[].unitOfMeasure": isRequiredIfIntlAndSelectGenerateInvoice(
        {
            field: {
                id: "orderDetails.support__documentation__unit__of__measure",
                defaultMessage: " {itemName} Unit of measure",
                values: {
                    itemName: "",
                },
            },
        }
    ),
    "handlingUnits[].items": hasLengthGreaterThan(0)({
        field: { id: "items__item", defaultMessage: "Item" },
    }),
    "handlingUnits[].items[].weight": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].count": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__huCount", defaultMessage: "H/U Count" } }),
    "handlingUnits[].items[].pieces": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__pieces", defaultMessage: "Pieces" } }),
    "handlingUnits[].items[].freightDirectPieces[].count": composeValidators(
        isRequiredIf(values => {
            return values?.isFreightDirect || values.isFreightDirectReturns
        }),
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__count", defaultMessage: "Count" } }),
    "handlingUnits[].items[].freightDirectPieces[].weight": composeValidators(
        isGreaterThan(0),
        isRequiredIf(values => {
            return values?.isFreightDirect || values.isFreightDirectReturns
        }),
        isNumeric
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].packageType": composeValidators(
        isRequired,
        packageTypeValidator
    )({
        field: {
            id: "items__packageType",
            defaultMessage: "Package Type",
        },
    }),
    "handlingUnits[].items[].description": composeValidators(
        isRequired,
        hasLengthLessThan(201)
    )({
        field: {
            id: "items__description",
            defaultMessage: "Description",
        },
    }),
    "handlingUnits[].items[].freightClass": composeValidators(
        isRequiredIfNotIntraMexico,
        isOneOfSilent(freightClassValues)
    )({ field: { id: "items__class", defaultMessage: "Class" } }),
    "handlingUnits[].width": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        widthValidator()
    )({ field: { id: "items__width", defaultMessage: "Width" } }),
    "handlingUnits[].height": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        heightValidator()
    )({ field: { id: "items__height", defaultMessage: "Height" } }),
    "handlingUnits[].length": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__length", defaultMessage: "Length" } }),
    "handlingUnits[].items[].hazMatUnNumber": composeValidators(
        isRequired,
        hasHazMatIdPrefix(),
        hasLength(6)
    )({
        field: {
            id: "items__unNumber__error",
            defaultMessage:
                "Identification Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code.",
        },
    }),
    "handlingUnits[].items[].hazMatPkgGrp": composeValidators(
        isRequired,
        isOneOf(pkgGrpValues)
    )({ field: { id: "items__pkgGrp", defaultMessage: "Pkg Grp" } }),
    "handlingUnits[].items[].hazMatClass": composeValidators(
        isRequired,
        isOneOf(hazClassValues)
    )({ field: { id: "items__hazClass", defaultMessage: "Haz Class" } }),
    "handlingUnits[].items[].hazMatContainer": composeValidators(
        isRequired,
        isOneOf(hazContainersValues)
    )({
        field: {
            id: "items__containerType",
            defaultMessage: "Container Type",
        },
    }),
}
