/* global google */
import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps"
import { withStyles } from "@material-ui/core"
import { stringLocation } from "../../actions/index"
import GlobalSpinner from "./spinner"
import { connect } from "react-redux"
import { goFetch } from "../../http"
import { usePrevious } from "../../misc"

const styles = theme => ({
    map__container: {
        height: "100%",
        width: "100%",
    },
})

const fetchCoordinates = async address => {
    const params = {
        queryString: stringLocation(address, false),
        country: address?.country,
    }
    try {
        const { data } = await goFetch(
            "/lookup/location/coordinates",
            {
                validErrorCodes: [404],
                params,
            },
            true
        )
        return data
    } catch (error) {
        console.error(error)
    }
}

const AsyncMap = ({ addresses }) => {
    const [positions, setPositions] = useState([])
    const [mapTypeId, setMapTypeId] = useState("roadmap")
    const googleMapApi = useRef()
    const prevAddresses = usePrevious(addresses)

    useEffect(() => {
        const refreshAddresses = async () => {
            let rawPositions = await Promise.all(
                addresses?.map(fetchCoordinates)
            )
            rawPositions = rawPositions?.filter(x => x)
            const locations = rawPositions?.map(x => x.location)
            setPositions(locations ?? [])
            if (!googleMapApi.current || rawPositions?.length === 0) return
            setMapTypeId("hybrid")
            let bounds
            if (rawPositions?.length === 1) {
                bounds = new google.maps.LatLngBounds(
                    rawPositions[0]?.bounds?.southwest,
                    rawPositions[0]?.bounds?.northeast
                )
            } else {
                bounds = new google.maps.LatLngBounds()
                for (const marker of rawPositions) {
                    const innerBounds = new google.maps.LatLngBounds(
                        marker?.bounds?.southwest,
                        marker?.bounds?.northeast
                    )
                    bounds.union(innerBounds)
                }
            }
            googleMapApi.current.fitBounds(bounds)
        }
        if (
            addresses?.length !== prevAddresses?.length ||
            addresses?.some(
                (addr, i) =>
                    stringLocation(addr) !== stringLocation(prevAddresses?.[i])
            )
        ) {
            refreshAddresses()
        }
    }, [prevAddresses, addresses])

    return (
        <GoogleMap
            defaultZoom={12}
            tilt={0}
            heading={0}
            mapTypeId={mapTypeId}
            center={positions.length === 1 && positions[0]}
            onMapTypeIdChanged={() =>
                setMapTypeId(googleMapApi.current.getMapTypeId())
            }
            ref={googleMapApi}
        >
            {positions.map((position, index) => (
                <Marker position={position} key={index} />
            ))}
        </GoogleMap>
    )
}

AsyncMap.propTypes = {
    addresses: PropTypes.array.isRequired,
    visualConfirmation: PropTypes.object,
    positions: PropTypes.array,
}

AsyncMap.defaultProps = {
    positions: [],
    visualConfirmation: {},
}

export const InnerMap = withScriptjs(
    withGoogleMap(({ addresses }) => <AsyncMap addresses={addresses} />)
)

InnerMap.propTypes = {
    addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = state => ({
    language: state?.user?.profile?.preferences?.language ?? "en-us",
})

export const Map = withStyles(styles)(
    connect(
        mapStateToProps,
        undefined
    )(props => (
        <InnerMap
            {...props}
            loadingElement={<GlobalSpinner isGlobal={false} />}
            containerElement={<div className={props.classes.map__container} />}
            googleMapURL={
                `https://maps.googleapis.com/maps/api/js?v=3.exp&language=${props.language}&key=` +
                `${window._env_.REACT_APP_GOOGLE_API_KEY}&channel=ltlselect`
            }
            mapElement={<div className={props.classes.map__container} />}
        />
    ))
)
