import { Grid, Typography, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import BookSquare from "../atoms/BookSquare"
import BasicInfoSummary from "../summaryCards/BasicInfoSummary"
import DestinationSummary from "../summaryCards/DestinationSummary"
import FinalizeSummary from "../summaryCards/FinalizeSummary"
import ItemsSummary from "../summaryCards/ItemsSummary"
import OriginSummary from "../summaryCards/OriginSummary"
import Origin from "../atoms/review/Origin"
import Destination from "../atoms/review/Destination"
import { Element, scroller } from "react-scroll"
import { weightUnit } from "../../../util/units"

const useStyles = makeStyles({
    titleContainer: {
        padding: "15px 0",
    },
    cardsContainer: {
        paddingRight: "2%",
    },
    summaryCard: {
        paddingBottom: "12px",
    },
    bookSquareContainer: {
        alignContent: "flex-start",
        position: "fixed",
        left: "66.666%",
        top: "129px",
        right: "72px",
    },
})

export default function Review({
    formValues = {},
    handleEdit,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    permissions = {},
    currentStep,
    originCities,
    destinationCities,
    changeField,
    trackGA,
    invalid,
    onBookShipment,
    isLoading,
}) {
    const classes = useStyles()
    const [isEditingOrigin, setIsEditingOrigin] = useState(false)
    const [isEditingDestination, setIsEditingDestination] = useState(false)
    const [oldOrigin, setOldOrigin] = useState({})
    const [oldDestination, setOldDestination] = useState({})

    const {
        selectedRate = { rateQuoteDetail: {}, serviceLevel: {} },
        preferredSystemOfMeasurement,
        isFreightDirect,
        isFreightDirectReturns,
    } = formValues

    const canViewRateAmounts = permissions?.viewRateAmounts?.value

    useEffect(() => {
        if (currentStep === 6) {
            scroller.scrollTo("reviewTitle", {
                offset: -140,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid item container alignContent="flex-start">
            <Grid
                item
                container
                className={classes.titleContainer}
                alignItems="center"
            >
                <Grid item container xs={4}>
                    <Button
                        id="changeRateReview"
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            trackGA(
                                "Book Shipment Flow",
                                "Review",
                                "Back To Rates"
                            )
                            handleEdit(4)
                        }}
                    >
                        <FormattedMessage
                            id="bookShipment.review__backToRates"
                            defaultMessage="Back to Rates"
                        />
                    </Button>
                </Grid>
                <Grid item container xs={4} justify="center">
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.reviewAndBook"
                            defaultMessage="Review and book."
                        />
                    </Typography>
                    <Element name="reviewTitle" />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={8}
                direction="column"
                className={classes.cardsContainer}
            >
                <Grid item container className={classes.summaryCard}>
                    <BasicInfoSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                        isReview
                    />
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    {!isEditingOrigin ? (
                        <OriginSummary
                            pickupAccessorialsList={pickupAccessorialsList}
                            handleReviewEdit={() => {
                                setOldOrigin(formValues.origin)
                                setIsEditingOrigin(true)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Begin Edit Origin"
                                )
                            }}
                            formValues={formValues}
                            isReview
                        />
                    ) : (
                        <Origin
                            formValues={formValues}
                            cities={originCities}
                            handleCancelEdit={() => {
                                changeField("origin", oldOrigin)
                                setIsEditingOrigin(false)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Cancel Edit Origin"
                                )
                            }}
                            handleUpdate={() => {
                                setIsEditingOrigin(false)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Confirm Edit Origin"
                                )
                            }}
                            invalid={invalid}
                        />
                    )}
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    {!isEditingDestination ? (
                        <DestinationSummary
                            handleReviewEdit={() => {
                                setOldDestination(formValues.destination)
                                setIsEditingDestination(true)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Begin Edit Destination"
                                )
                            }}
                            formValues={formValues}
                            deliveryAccessorialsList={deliveryAccessorialsList}
                            isReview
                        />
                    ) : (
                        <Destination
                            formValues={formValues}
                            cities={destinationCities}
                            handleCancelEdit={() => {
                                changeField("destination", oldDestination)
                                setIsEditingDestination(false)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Cancel Edit Destination"
                                )
                            }}
                            handleUpdate={() => {
                                setIsEditingDestination(false)
                                trackGA(
                                    "Book Shipment Flow",
                                    "Review",
                                    "Confirm Edit Destination"
                                )
                            }}
                            invalid={invalid}
                        />
                    )}
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    <ItemsSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                        weightUnit={weightUnit(preferredSystemOfMeasurement)}
                        isReview
                    />
                </Grid>
                <Grid item container className={classes.summaryCard}>
                    <FinalizeSummary
                        handleEdit={handleEdit}
                        formValues={formValues}
                    />
                </Grid>
            </Grid>
            <Grid item xs={4} className={classes.bookSquareContainer}>
                <BookSquare
                    disabled={
                        isEditingOrigin || isEditingDestination || isLoading
                    }
                    selectedRate={selectedRate}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    canViewRateAmounts={canViewRateAmounts}
                    onBookShipment={onBookShipment}
                />
            </Grid>
        </Grid>
    )
}
