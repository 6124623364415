import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Label } from "react-bootstrap"
import { getAccessorialText } from "../../actions/accessorial"

const AccessorialListPresentation = ({
    list,
    accessorials = [],
    className,
    handleClick,
}) => (
    <div className={className}>
        {accessorials.map((item, i) => (
            <div key={i}>
                <Label
                    className="quote-accessorial-label"
                    onClick={() => handleClick && handleClick(item)}
                    bsStyle="info"
                >
                    {getAccessorialText(item, list)}
                    {handleClick && (
                        <span className="quote-accessorial-delete-button">
                            x
                        </span>
                    )}
                </Label>
            </div>
        ))}
    </div>
)

AccessorialListPresentation.propTypes = {
    list: PropTypes.array.isRequired,
    accessorials: PropTypes.array.isRequired,
    handleClick: PropTypes.func,
    className: PropTypes.string,
}

AccessorialListPresentation.defaultProps = {
    handleClick: undefined,
    className: "accessorial-list",
}

const mapStateToProps = state => ({
    list: Object.keys(state.accessorials.list).reduce(
        (prev, key) => [...prev, ...state.accessorials.list[key]],
        []
    ),
})

export const AccessorialList = connect(mapStateToProps)(
    AccessorialListPresentation
)
