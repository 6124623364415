import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactTable from "react-table"
import { Button, FormControl } from "react-bootstrap"
import { closeModal } from "../../actions/modal"
import { searchTextChange, updateLineItem } from "../../actions/quote-item"

const description = {
    header: "Description",
    accessor: "description",
    render: rowInfo => rowInfo.value,
    minWidth: 70,
}

const freightClass = {
    header: "Class",
    accessor: "freightClass",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const weight = {
    header: "Weight",
    accessor: "weight",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const packageType = {
    header: "Packaging Type",
    accessor: "packageType",
    render: rowInfo => rowInfo.value,
    minWidth: 50,
}

const dimensions = {
    header: "Dimensions",
    id: "dimensions",
    accessor: row => `${row.length}x${row.width}x${row.height}`,
    render: rowInfo => rowInfo.value,
    minWidth: 50,
}
const nmfc = {
    id: "nmfc",
    header: "NMFC",
    accessor: element =>
        element.nmfc ? `${element.nmfc1}-${element.nmfc2}` : "",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const unNumber = {
    header: "UN#",
    accessor: "unNumber",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const hazMatClass = {
    header: "Haz Class",
    accessor: "hazClass",
    render: rowInfo => rowInfo.value,
    minWidth: 40,
}

const pkgGrp = {
    header: "Pak Grp",
    accessor: "pkgGrp",
    render: rowInfo => rowInfo.value,
    minWidth: 40,
}

const columns = [
    description,
    freightClass,
    weight,
    packageType,
    dimensions,
    nmfc,
    unNumber,
    hazMatClass,
    pkgGrp,
]

export const BrowseItemsPresentation = ({
    items,
    onClick,
    onCloseClick,
    searchChange,
    searchText,
}) => (
    <div className="column">
        <h2 className="bol-field-title">{"Item List"}</h2>
        <div className="flex-item-align-right search-container">
            <FormControl
                onChange={searchChange}
                placeholder="Search"
                value={searchText}
            />
        </div>
        <div className="browse-items-list">
            <ReactTable
                data={items}
                columns={columns}
                defaultPageSize={50}
                showPagination={false}
                className="overflow-visible"
                pageSize={items.length}
                pageSizeOptions={[10]}
                getTheadProps={() => ({
                    className: "align-left",
                })}
                getTableProps={() => ({
                    className: "overflow-visible",
                })}
                getNoDataProps={() => ({
                    style: { display: "none" },
                })}
                getTfootProps={() => ({
                    className: "table-footer",
                })}
                getTrProps={(state, rowInfo) => ({
                    className: "browse-item-table-row",
                    onClick: () => {
                        onClick(rowInfo)
                    },
                })}
            />
        </div>
        <Button
            className="margin-top flex-item-align-right"
            onClick={onCloseClick}
        >
            {"Close"}
        </Button>
    </div>
)

BrowseItemsPresentation.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    searchChange: PropTypes.func.isRequired,
    searchText: PropTypes.string,
}

BrowseItemsPresentation.defaultProps = {
    items: [],
    searchText: "",
}

const mapStateToProps = state => ({
    ...state.searchItem,
})

const mapDispatchToProps = (dispatch, { index }) => ({
    searchChange: e => dispatch(searchTextChange(e.target.value)),
    onClick: item => {
        dispatch(updateLineItem(item.row, index))
        dispatch(closeModal())
    },
    onCloseClick: () => dispatch(closeModal()),
})

export const BrowseItems = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrowseItemsPresentation)
