import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Button, withStyles } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { updateUserOperatingLocation } from "../../actions/user"
import Spinner from "../util/spinner"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"

const styles = theme => ({
    formControl: {
        width: "100%",
    },
})

const MandatoryPrompt = ({
    authFinished,
    classes,
    operatingLocation,
    locations,
    updateUser,
    userIsLoaded,
    accountActive,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [province, setProvince] = useState()

    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        if (authFinished && accountActive && userIsLoaded) {
            let hasCanadianBillTo = false
            for (let i = 0; i < locations.length; i++) {
                if (
                    locations[i]?.address?.country === "CA" &&
                    locations[i].is3rdPartyEnabled
                ) {
                    hasCanadianBillTo = true
                    break
                }
            }

            if (!operatingLocation && hasCanadianBillTo) {
                openDialog()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authFinished, accountActive, locations.length])

    const onClose = () => {
        setIsOpen(false)
    }

    const openDialog = () => {
        setIsOpen(true)
    }

    const handleChange = event => {
        setProvince(event.target.value)
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            await updateUser(province)
            onClose()
            if (province === "QC") {
                openSnackbar(
                    "info",
                    <FormattedMessage
                        id="locations__quebecShipperAccountWarning"
                        defaultMessage="The account entered allows you to rate and ship with FedEx Freight only at this point."
                    />,
                    null
                )
            }
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="generalTerms__error"
                    defaultMessage="Error"
                />
            )
        }
        setIsLoading(false)
    }

    return (
        <Dialog open={isOpen} onClose={() => {}}>
            {isLoading && <Spinner />}
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage
                    id="mandatoryPrompt__title"
                    defaultMessage="Please select the province in which you are personally located."
                />
            </DialogTitle>
            <DialogContent>
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id="demo-simple-select-label">
                        <FormattedMessage
                            id="generalTerms__province"
                            defaultMessage="Province"
                        />
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={province}
                        onChange={handleChange}
                    >
                        <MenuItem value={"AB"}>
                            <FormattedMessage
                                id="canada.alberta"
                                defaultMessage="Alberta"
                            />
                        </MenuItem>
                        <MenuItem value={"BC"}>
                            <FormattedMessage
                                id="canada.britishColumbia"
                                defaultMessage="British Columbia"
                            />
                        </MenuItem>
                        <MenuItem value={"MB"}>
                            <FormattedMessage
                                id="canada.manitoba"
                                defaultMessage="Manitoba"
                            />
                        </MenuItem>
                        <MenuItem value={"NB"}>
                            <FormattedMessage
                                id="canada.newBrunswick"
                                defaultMessage="New Brunswick"
                            />
                        </MenuItem>
                        <MenuItem value={"NL"}>
                            <FormattedMessage
                                id="canada.newfoundlandAndLabrador"
                                defaultMessage="Newfoundland and Labrador"
                            />
                        </MenuItem>
                        <MenuItem value={"NT"}>
                            <FormattedMessage
                                id="canada.northwestTerritories"
                                defaultMessage="Northwest Territories"
                            />
                        </MenuItem>
                        <MenuItem value={"NS"}>
                            <FormattedMessage
                                id="canada.novaScotia"
                                defaultMessage="Nova Scotia"
                            />
                        </MenuItem>
                        <MenuItem value={"NV"}>
                            <FormattedMessage
                                id="canada.nunavut"
                                defaultMessage="Nunavut"
                            />
                        </MenuItem>
                        <MenuItem value={"ON"}>
                            <FormattedMessage
                                id="canada.ontario"
                                defaultMessage="Ontario"
                            />
                        </MenuItem>
                        <MenuItem value={"PE"}>
                            <FormattedMessage
                                id="canada.princeEdwardIsland"
                                defaultMessage="Prince Edward Island"
                            />
                        </MenuItem>
                        <MenuItem value={"QC"}>
                            <FormattedMessage
                                id="canada.quebec"
                                defaultMessage="Quebec"
                            />
                        </MenuItem>
                        <MenuItem value={"SK"}>
                            <FormattedMessage
                                id="canada.saskatchewan"
                                defaultMessage="Saskatchewan"
                            />
                        </MenuItem>
                        <MenuItem value={"YT"}>
                            <FormattedMessage
                                id="canada.yukon"
                                defaultMessage="Yukon"
                            />
                        </MenuItem>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleSubmit()}
                    color="primary"
                    variant="contained"
                    disabled={!province}
                >
                    <FormattedMessage
                        id="generalTerms__confirm"
                        defaultMessage="Confirm"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}
const mapStateToProps = state => {
    const { preferences = {}, locations = [] } = state?.user?.profile ?? {}

    return {
        authFinished: state?.authorization?.isFinished,
        userIsLoaded: state?.user?.preferences?.isLoaded,
        accountActive: state?.user?.preferences?.accountActive,
        operatingLocation: preferences.operatingLocation,
        locations,
    }
}

const mapDispatchToProps = dispatch => ({
    updateUser: async data => dispatch(updateUserOperatingLocation(data)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(MandatoryPrompt))
