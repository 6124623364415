import React, { Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"
import { get } from "lodash"
/* eslint-disable no-unused-vars */
import momentDurationFormatSetup from "moment-duration-format"
/* eslint-enable no-unused-vars */
import {
    withStyles,
    Grid,
    Typography,
    List,
    ListItem,
    Divider,
} from "@material-ui/core"
import { isGuaranteedQuote } from "../card"
import { calculateMarkupPrice } from "../card/util"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    carrier__logo: {
        height: "41px",
        width: "71px",
    },
    carrier__tag: {},
    original__price: {
        textDecoration: `line-through ${theme.palette.text.secondary}`,
    },
    list: {
        backgroundColor: theme.palette.background.paper,
    },
})

const QuotesPanel = ({
    selections,
    carriers,
    guaranteedList,
    classes,
    markup,
    currencyCode,
}) => (
    <Fragment>
        <Typography variant="headline" gutterBottom>
            <FormattedMessage
                id="rateResults.share__ratesHeading"
                defaultMessage="Share the following quotes:"
            />
        </Typography>
        <List className={classes.list} dense disablePadding>
            {selections.map((quote, i) => {
                const guaranteed = isGuaranteedQuote(
                    get(quote, "rate.serviceLevel.code"),
                    guaranteedList
                )
                const momentDeliveryDate = moment.utc(
                    get(quote, "rate.deliveryDateTime")
                )
                const originalPrice = get(
                    quote,
                    "rate.rateQuoteDetail.totalInPreferredCurrency"
                )
                    ? get(
                          quote,
                          "rate.rateQuoteDetail.totalInPreferredCurrency"
                      )
                    : get(quote, "rate.rateQuoteDetail.total")
                const markupPrice = calculateMarkupPrice(originalPrice, markup)
                const isFreightDirect = quote?.mode === "LTL_DIRECT"
                const isFreightDirectReturns =
                    quote?.mode === "LTL_DIRECT_RETURNS"

                return (
                    <Fragment>
                        {i !== 0 && <Divider />}
                        <ListItem>
                            <Grid
                                item
                                xs={12}
                                container
                                spacing={16}
                                alignItems="center"
                                key={i}
                            >
                                <Grid
                                    item
                                    justify="flex-start"
                                    alignItems="center"
                                    xs={2}
                                >
                                    {get(quote, "rate.carrierCode") ===
                                        "FXFE" ||
                                    get(quote, "rate.carrierCode") ===
                                        "FXNL" ? (
                                        <img
                                            className={classes.carrier__logo}
                                            src="https://cloud.p-44.com/img/carriers/FXFElogo.png"
                                            alt="FXNL"
                                        />
                                    ) : (
                                        <Typography
                                            variant="subheading"
                                            className={classes.carrier__tag}
                                        >
                                            {
                                                carriers[quote.rate.carrierCode]
                                                    .name
                                            }
                                        </Typography>
                                    )}
                                </Grid>
                                {get(quote, "rate.isDomesticOffshore") ? (
                                    <Grid item xs={3}>
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="orderDetails.header__unknownDeliveryDate"
                                                defaultMessage="Call carrier for delivery date"
                                            />
                                        </Typography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={3}>
                                        <Typography>
                                            {" "}
                                            {momentDeliveryDate.format(
                                                "dddd MMM-Do"
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                                {!get(quote, "rate.isDomesticOffshore") ||
                                    (!isFreightDirect && (
                                        <Grid item xs={2}>
                                            <Typography>
                                                {" "}
                                                {momentDeliveryDate.format(
                                                    "LT"
                                                )}
                                            </Typography>
                                        </Grid>
                                    ))}
                                {(isFreightDirect ||
                                    isFreightDirectReturns) && (
                                    <Grid item xs={2}>
                                        <Typography>
                                            {quote?.rate?.serviceLevel
                                                ?.description ||
                                                quote?.rate?.serviceLevel?.code}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={2}>
                                    {guaranteed && (
                                        <Typography>
                                            <FormattedMessage
                                                id="generalTerms__guaranteed"
                                                defaultMessage="Guaranteed"
                                            />
                                        </Typography>
                                    )}
                                </Grid>
                                {get(quote, "rate.isDomesticOffshore") && (
                                    <Grid item xs={3}>
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__domesticOffshore"
                                                defaultMessage="Domestic Offshore"
                                            />
                                        </Typography>
                                    </Grid>
                                )}
                                {originalPrice && (
                                    <Grid item>
                                        {originalPrice !== markupPrice ? (
                                            <Typography
                                                color="textSecondary"
                                                className={
                                                    classes.original__price
                                                }
                                            >
                                                {`${currencyCode}${originalPrice.toFixed(
                                                    2
                                                )}`}
                                            </Typography>
                                        ) : (
                                            <Typography>{`${currencyCode}${originalPrice.toFixed(
                                                2
                                            )}`}</Typography>
                                        )}
                                    </Grid>
                                )}
                                {originalPrice && (
                                    <Grid item>
                                        {originalPrice !== markupPrice && (
                                            <Typography>{`${currencyCode}${markupPrice.toFixed(
                                                2
                                            )}`}</Typography>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </ListItem>
                    </Fragment>
                )
            })}
        </List>
    </Fragment>
)

const mapStateToProps = state => ({
    carriers: state.carriers.carriers,
    guaranteedList: state.accessorials.list.guaranteed,
})

export default withStyles(styles)(connect(mapStateToProps)(QuotesPanel))
