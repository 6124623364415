import React, { Fragment, Component } from "react"
import { connect } from "react-redux"
import { get } from "lodash"
import {
    Grid,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    SnackbarContent,
    Typography,
    withStyles,
} from "@material-ui/core"
import amber from "@material-ui/core/colors/amber"
import ShareIcon from "@material-ui/icons/Share"
import CarouselIcon from "@material-ui/icons/ViewCarousel"
import ListIcon from "@material-ui/icons/ViewList"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import QuoteGridList from "../quote/result/QuoteList"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import QuoteResultCarousel from "../quote/result/carousel/quoteList"
import FreightDirectQuotes from "./freightDirect/freightDirectQuotes"
import FreightDirectReturnsQuotes from "./freightDirectReturns/freightDirectReturnsQuotes"
import { shareModeChange, createShare } from "../../actions/share"
import { QuoteShareModal } from "../quote/share/share"
import GlobalSpinner from "../util/spinner"
import { changePath } from "../../actions/index"
import { formSelector, locationPermissionSelector } from "./selectors"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    carousel__container: {
        width: "100%",
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    leftButton: {
        marginRight: theme.spacing.unit,
    },
    secondary__header: {
        padding: "1% 1%",
    },
    quick_quote_secondary__header: {
        backgroundColor: "#F2F2F2",
        padding: "1% 10%",
    },
    main__container: {
        marginBottom: "10px",
    },
    quick_quote_main__container: {
        marginTop: "10px",
        marginBottom: "10px",
    },
    button__root: {
        height: "40px",
    },
    errorSnackbar: {
        backgroundColor: amber[700],
        maxWidth: "100%",
    },
    share__button__container: {
        marginTop: "8px",
    },
    chat__container: {
        // marginRight: '12px'
    },
    chat_button: {
        marginLeft: "10px",
    },
    view_buttons: {
        justifyContent: "flex-start",
    },
    no_volume_rate__container: {
        height: "500px",
    },
    button__get_quotes: {
        margin: "15px 5px",
    },
    toggleContainer: {
        height: 56,
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        margin: `${theme.spacing.unit}px 0`,
        background: theme.palette.background.default,
    },
    view_toggle: {
        maxHeight: "40px",
    },
})

class QuotesResult extends Component {
    state = {
        modalOpen: false,
        rateView: "carousel",
        alignment: "left",
        listSelect: false,
        carouselSelected: true,
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleModalOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleModalClose = () => {
        this.setState({ modalOpen: false })
    }

    handleViewCarouselSwitch = () => {
        this.setState({
            rateView: "carousel",
            carouselSelected: true,
            listSelected: false,
        })
    }

    handleViewListSwitch = () => {
        this.setState({
            rateView: "listView",
            carouselSelected: false,
            listSelected: true,
        })
    }

    handleAlignment = (event, alignment) => this.setState({ alignment })

    handleRateModeToggle = () => {
        this.props.changePath(`/rate/${this.props.shipmentId}/edit`)
    }

    determineRatesOrder = rateQuotes => {
        let copiedRateQuotes = [...rateQuotes]
        let domesticOffshoreIndex = null
        let domesticOffshoreRate = null

        copiedRateQuotes.forEach((rate, index) => {
            if (get(rate, "rateQuotes[0].isDomesticOffshore")) {
                domesticOffshoreIndex = index
                domesticOffshoreRate = rate
            }
        })

        if (domesticOffshoreRate) {
            copiedRateQuotes.splice(domesticOffshoreIndex, 1)
            return [domesticOffshoreRate, ...copiedRateQuotes]
        } else return rateQuotes
    }

    render() {
        const {
            classes,
            rateQuotes,
            isFetching,
            toggleShare,
            ratesError,
            query,
            onClickGetRates,
            shipmentId,
            share: { isOpen, selections },
            isExpired,
            shipped,
            onClickRefreshRates,
            getBol,
            canCreateRates,
            canCreateShipments,
            isLoaded,
            canViewRateAmounts,
        } = this.props
        const isQuickQuote = this.props.quickQuote
        const { alignment } = this.state
        const orderedRates = this.determineRatesOrder(rateQuotes)

        const buttonClasses = {
            root: classes.button__root,
        }

        const {
            isFreightDirect,
            isFreightDirectReturns,
            isInBondShipment,
        } = query

        const freightDirect = (
            <Grid item container>
                <FreightDirectQuotes
                    canViewRateAmounts={canViewRateAmounts && !isInBondShipment}
                    isShipped={shipped}
                    isExpired={isExpired}
                    onCheckMark={this.onCheckMark}
                    canCreateShipments={!isQuickQuote && canCreateShipments}
                    getBol={getBol}
                    rates={rateQuotes}
                />
            </Grid>
        )

        const freightDirectReturns = (
            <Grid item container>
                <FreightDirectReturnsQuotes
                    canViewRateAmounts={canViewRateAmounts && !isInBondShipment}
                    isShipped={shipped}
                    isExpired={isExpired}
                    onCheckMark={this.onCheckMark}
                    canCreateShipments={!isQuickQuote && canCreateShipments}
                    getBol={getBol}
                    rates={rateQuotes}
                />
            </Grid>
        )

        const freight = (
            <Grid
                item
                container
                className={
                    isQuickQuote
                        ? classes.quick_quote_main__container
                        : classes.main__container
                }
            >
                {canCreateRates &&
                !isFetching &&
                !orderedRates.length &&
                get(query, "volumeLTL") ? (
                    <Grid
                        item
                        container
                        justify="center"
                        className={classes.no_volume_rate__container}
                        alignContent="flex-start"
                    >
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="rateResults__noVolumeRates"
                                defaultMessage="No Volume Rates were found for this shipment. Do you want to check LTL rates?"
                            />
                        </Typography>
                        <Grid item container justify="center">
                            <Button
                                onClick={() =>
                                    onClickGetRates(query, shipmentId)
                                }
                                variant="contained"
                                color="secondary"
                                className={classes.button__get_quotes}
                                disabled={isFetching}
                                id="getRates__submit"
                            >
                                <FormattedMessage
                                    id="getRates.form__getRates"
                                    defaultMessage="GET RATES"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                ) : this.state.rateView === "listView" ? (
                    <QuoteGridList
                        onCheckMark={this.onCheckMark}
                        getBol={getBol}
                        rates={orderedRates}
                        canCreateShipments={!isQuickQuote && canCreateShipments}
                        onClickRefreshRates={onClickRefreshRates}
                        isExpired={isExpired}
                        isShipped={shipped}
                        shipmentId={shipmentId}
                        canViewRateAmounts={
                            canViewRateAmounts && !isInBondShipment
                        }
                    />
                ) : (
                    <div className={classes.carousel__container}>
                        <QuoteResultCarousel
                            onCheckMark={this.onCheckMark}
                            rates={orderedRates}
                            getBol={getBol}
                            canCreateShipments={
                                !isQuickQuote && canCreateShipments
                            }
                            isExpired={isExpired}
                            isShipped={shipped}
                            canViewRateAmounts={
                                canViewRateAmounts && !isInBondShipment
                            }
                        />
                    </div>
                )}
            </Grid>
        )

        return (
            <Fragment>
                {(isFetching || !isLoaded) && <GlobalSpinner />}
                {!isFetching && (
                    <Grid
                        item
                        container
                        className={
                            isQuickQuote
                                ? classes.quick_quote_secondary__header
                                : classes.secondary__header
                        }
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            className={classes.share__button__container}
                        >
                            <Grid
                                item
                                container
                                xs={2}
                                justify="center"
                                alignItems="center"
                                className={classes.view_buttons}
                            >
                                {!isFreightDirect && !isFreightDirectReturns && (
                                    <ToggleButtonGroup
                                        value={alignment}
                                        exclusive
                                        onChange={this.handleAlignment}
                                        justify="flex-start"
                                        className={classes.view_toggle}
                                    >
                                        <ToggleButton
                                            classes={buttonClasses}
                                            variant="contained"
                                            selected={
                                                this.state.carouselSelected
                                            }
                                            onClick={
                                                this.handleViewCarouselSwitch
                                            }
                                        >
                                            <CarouselIcon
                                                className={classes.leftIcon}
                                            />
                                        </ToggleButton>
                                        <ToggleButton
                                            classes={buttonClasses}
                                            variant="contained"
                                            selected={this.state.listSelected}
                                            onClick={this.handleViewListSwitch}
                                        >
                                            <ListIcon
                                                className={classes.leftIcon}
                                            />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={2}
                                justify="center"
                                alignItems="center"
                            >
                                {isQuickQuote && (
                                    <Button
                                        size="small"
                                        color="secondary"
                                        variant="outlined"
                                        id={"finalize_btn"}
                                        onClick={this.handleRateModeToggle}
                                    >
                                        <FormattedMessage
                                            id="quickQuote__finalizeRate"
                                            defaultMessage="FINALIZE RATE"
                                        />
                                    </Button>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={4}
                                justify="center"
                                alignItems="center"
                            >
                                {isExpired && !isFetching && !shipped && (
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        justify="center"
                                        direction="column"
                                    >
                                        <Grid item container justify="center">
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    id="rateResults__outdated"
                                                    defaultMessage="This quote is outdated"
                                                />
                                            </Typography>
                                        </Grid>
                                        {canCreateRates && (
                                            <Grid
                                                item
                                                container
                                                justify="center"
                                            >
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    variant="outlined"
                                                    onClick={() =>
                                                        onClickRefreshRates(
                                                            shipmentId
                                                        )
                                                    }
                                                >
                                                    <FormattedMessage
                                                        id="rateResults__rerunQuote"
                                                        defaultMessage="Click here to re-run the quote"
                                                    />
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={4}
                                justify="flex-end"
                                alignItems="center"
                                className={classes.chat__container}
                            >
                                {isOpen && (
                                    <Button
                                        classes={buttonClasses}
                                        variant="contained"
                                        onClick={() => toggleShare(false)}
                                        className={classes.leftButton}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                )}

                                <Button
                                    classes={buttonClasses}
                                    variant="contained"
                                    disabled={isOpen && selections.length === 0}
                                    color={
                                        !isOpen
                                            ? "default"
                                            : selections.length > 0
                                            ? "secondary"
                                            : "primary"
                                    }
                                    onClick={() =>
                                        isOpen
                                            ? this.handleModalOpen()
                                            : toggleShare(true, shipmentId)
                                    }
                                >
                                    <ShareIcon className={classes.leftIcon} />
                                    <FormattedMessage
                                        id="rateResults.share__share"
                                        defaultMessage="Share"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {!isFetching &&
                    (isFreightDirectReturns
                        ? freightDirectReturns
                        : isFreightDirect
                        ? freightDirect
                        : freight)}
                <QuoteShareModal
                    open={this.state.modalOpen}
                    handleClose={this.handleModalClose}
                />
                {!isFetching && !!ratesError?.length && (
                    <Grid item container spacing={16}>
                        <Grid item xs={12}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                >
                                    <Typography>
                                        <FormattedMessage
                                            id="rateResults__carrierRatesError"
                                            defaultMessage="At least one carrier is having trouble retrieving rates.  View more information"
                                        />
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid item container spacing={16}>
                                        {ratesError.map((rateError, i) => {
                                            const errorCarrier =
                                                rateError?.carrierCode ?? ""
                                            return (
                                                rateError?.errorMessages ?? []
                                            ).map((error, j) => {
                                                const errorDiagnostic =
                                                    error?.diagnostic
                                                const errorMessage =
                                                    error?.message
                                                const source =
                                                    error?.source === "SYSTEM"
                                                        ? "Project 44"
                                                        : "Carrier Error"
                                                const errorText = errorDiagnostic
                                                    ? `${errorCarrier}: ${errorMessage} (${source}) - ${errorDiagnostic}`
                                                    : `${errorCarrier}: ${errorMessage} (${source})`
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        key={`${i}_${j}`}
                                                    >
                                                        <SnackbarContent
                                                            className={
                                                                classes.errorSnackbar
                                                            }
                                                            message={
                                                                <Typography>
                                                                    {errorText}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        })}
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, props) => ({
    ...state.quotes.active,
    ...locationPermissionSelector(state, props),
    query: formSelector(state, props),
    share: state.share,
    // locationPermissions: state.user.profile.locations ,
})

const mapDispatchToProps = dispatch => ({
    toggleShare: (value, shipmentId) =>
        dispatch(shareModeChange(value, shipmentId)),
    handleCreateShare: () => dispatch(createShare()),
    changePath: path => dispatch(changePath(path)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(QuotesResult))
