import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import NextButton from "../atoms/NextButton"
import SummaryButtons from "../atoms/SummaryButtons"
import { Element, scroller } from "react-scroll"
import HazMatForm from "../atoms/HazMatForm"
import HazMatDetails from "../atoms/HazMatDetails"

import AlertsPreferences from "../../../alerts/alertsPreferences"
import InBondCard from "../../../book/InBondCard"
import OptionalIdentifiers from "../atoms/OptionalIdentifiers"
import CustomsBrokerageForm from "../atoms/CustomsBrokerageForm"
import {
    isInternationalShipment,
    isMexicoToUsaInternationalShipment,
    isUsaMainlandToMexicoInternationalShipment,
    isUSDomesticOffshoreShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isValidEmail,
    isValidPhoneNumber,
} from "../../../../actions/validation"
import { bookShipmentFormValidation } from ".."
import { reduxForm } from "redux-form"
import { isFedExCarrier } from "../../../../misc"
import SupportDocumentationForm from "../atoms/SupportDocumentationForm"
import { createHandlingUnitInitialValues } from "../../../documents/certificateOfOrigin/selectors"
import PickUpForm from "../atoms/PickUpForm"
import {
    sensibleReadyTime,
    isPastPickupCutoffTime,
} from "../../../../components/book/selectors"
import { weightUnitSingularCapital } from "../../../util/units"

const useStyles = makeStyles({
    section: {
        paddingBottom: "20px",
    },
    sectionWide: {
        paddingBottom: "45px",
    },
    sectionSmall: {
        padding: "0px 15% 45px 15%",
    },
})

const Finalize = ({
    handleComplete,
    handleUpdate,
    editMode,
    handleCancelEdit,
    currentStep,
    formValues = {},
    changeField,
    invalid,
    activeQuote = {},
    touchField,
}) => {
    const classes = useStyles()
    const {
        handlingUnits = [],
        selectedRate = {},
        identifiers = {},
        destination = {},
        origin = {},
        hazmatEmergency = {},
        requiresCommercialInvoice = false,
        preferredCurrencyCode = "USD",
        preferredSystemOfMeasurement = "IMPERIAL",
        isInBondShipment,
        pickupLater,
        selectedLocation,
        isFreightDirectReturns,
    } = formValues

    const { proEnabled } = identifiers
    const { _id, terminal } = selectedRate
    const originCountry = origin?.shippingAddress?.address?.country
    const destinationCountry = destination?.shippingAddress?.address?.country
    const originState = origin?.shippingAddress?.address?.state
    const destinationState = destination?.shippingAddress?.address?.state
    const { search } = activeQuote
    const isMXToUSIntl = isMexicoToUsaInternationalShipment(
        originCountry,
        destinationCountry
    )
    const isIntl = isInternationalShipment(originCountry, destinationCountry)
    const isUSDomesticOffshore = isUSDomesticOffshoreShipment(
        originCountry,
        destinationCountry,
        originState,
        destinationState
    )
    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        originCountry,
        destinationCountry,
        originState,
        destinationState
    )

    const isUSMainlandToMXIntl = isUsaMainlandToMexicoInternationalShipment(
        originCountry,
        destinationCountry,
        originState
    )

    const certificateOfOriginLineItems = createHandlingUnitInitialValues(
        handlingUnits,
        weightUnitSingularCapital(preferredSystemOfMeasurement)
    )

    const originTimezone = search?.origin?.originTimezone ?? "Pacific/Honolulu"
    const pickupDateStr = search?.pickupDate
    const isPastPickup = isPastPickupCutoffTime(
        pickupDateStr,
        originTimezone,
        originCountry
    )
    const calculateTotalPrice = () => {
        return handlingUnits.reduce(
            (huSum, hu) =>
                huSum +
                hu.items.reduce(
                    (itemSum, item) =>
                        itemSum +
                        (item.unitPrice == null ? 0 : item.unitPrice) *
                            (item.unit == null
                                ? item.pieces == null
                                    ? 1
                                    : item.pieces
                                : item.unit) *
                            hu.count,
                    0
                ),
            0
        )
    }

    const shouldShowAES = () => {
        if (
            (isUSDomesticOffshore === true &&
                (originState === "PR" || destinationState === "PR")) ||
            isUSMainlandToMXIntl === true
        ) {
            const value = calculateTotalPrice()
            if (value > 2500) {
                return true
            }
            return false
        }
    }

    const [isHazMat, setIsHazMat] = useState(false)
    const [hazMatContact, setHazMatContact] = useState(null)

    useEffect(() => {
        if (currentStep === 5) {
            scroller.scrollTo("finalizeTitle", {
                duration: 1000,
                smooth: true,
                offset: -160,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        setIsHazMat(
            handlingUnits.some(hu => {
                return hu.items.some(item => item.isHazMat)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])

    useEffect(() => {
        setIsHazMat(
            handlingUnits.some(hu => {
                return hu.items.some(item => item.isHazMat)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])

    useEffect(() => {
        if (
            hazmatEmergency?.name &&
            hazmatEmergency?.phone?.phone_number &&
            isValidPhoneNumber(
                hazmatEmergency?.phone?.phone_number,
                originCountry
            ) &&
            hazmatEmergency?.email &&
            isValidEmail(hazmatEmergency?.email)
        ) {
            if (
                !hazMatContact?.name &&
                !hazMatContact?.phone?.phone_number &&
                !hazMatContact?.email
            ) {
                setHazMatContact(hazmatEmergency)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hazmatEmergency])

    useEffect(() => {
        changeField("certificateOfOrigin", {
            certificateOfOriginLineItems,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const pickupTime = sensibleReadyTime(
            origin?.readyTime,
            pickupDateStr,
            originTimezone
        )
        changeField("origin", {
            ...origin,
            readyTime: pickupTime,
        })
        touchField("origin.closeTime")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isFreightDirectReturns) {
            changeField("origin", {
                ...origin,
                readyTime: "8:00 AM",
                closeTime: "7:00 PM",
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid item container>
            <Grid item container justify="center" className={classes.section}>
                <Typography variant="title">
                    <FormattedMessage
                        id="bookShipment.finalize__title"
                        defaultMessage="Finalize"
                    />
                </Typography>
                <Element name="finalizeTitle" />
            </Grid>
            <Grid
                item
                container
                className={classes.section}
                justify="center"
                alignItems="center"
            >
                {isHazMat && (
                    <Grid item container className={classes.sectionSmall}>
                        <HazMatForm />
                    </Grid>
                )}

                {isHazMat &&
                    hazMatContact?.name &&
                    hazMatContact?.email &&
                    hazMatContact?.phone?.phone_number && (
                        <Grid item container className={classes.sectionSmall}>
                            <HazMatDetails
                                changeField={changeField}
                                hazMatContact={hazMatContact}
                            />
                        </Grid>
                    )}

                {isMXToUSIntl && (
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isMXToUSIntl}
                            isExport={true}
                            country={originCountry}
                            formObjectName={"exportCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            changeField={changeField}
                        />
                    </Grid>
                )}

                {(isIntl || isUSDomesticOffshore) && (
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isIntl}
                            country={destinationCountry}
                            formObjectName={"importCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            changeField={changeField}
                        />
                    </Grid>
                )}

                {(isIntl || isUSDomesticOffshoreWithItemCustoms) &&
                    isFedExCarrier(selectedRate?.carrierCode) && (
                        <Grid item container>
                            <SupportDocumentationForm
                                changeField={changeField}
                                locationId={selectedLocation?._id}
                                shipmentId={identifiers?.internalTrackingNumber}
                            />
                        </Grid>
                    )}

                {isInBondShipment && (
                    <Grid item container className={classes.sectionSmall}>
                        <InBondCard
                            bookShipmentWorkflow
                            formName="bookShipment"
                        />
                    </Grid>
                )}
                <Grid item container className={classes.sectionSmall}>
                    <OptionalIdentifiers
                        selectedRate={selectedRate}
                        proEnabled={proEnabled}
                        changeField={changeField}
                        shouldShowAES={shouldShowAES()}
                        totalPrice={{
                            display: requiresCommercialInvoice,
                            amount: calculateTotalPrice(),
                            preferredCurrencyCode: preferredCurrencyCode,
                        }}
                    />
                </Grid>

                <Grid item container className={classes.sectionSmall}>
                    <PickUpForm
                        country={originCountry}
                        pickupLater={pickupLater}
                        terminal={terminal}
                        isPastPickup={isPastPickup}
                        rateMode={selectedRate?.mode}
                        carrierCode={selectedRate?.carrierCode}
                        isFreightDirectReturns={isFreightDirectReturns}
                    />
                </Grid>

                <Grid item container className={classes.section}>
                    <AlertsPreferences
                        bookShipmentWorkflow
                        share
                        formName="bookShipment"
                        isFreightDirect={
                            selectedRate?.mode === "LTL_DIRECT" ||
                            selectedRate?.mode === "LTL_DIRECT_RETURNS"
                        }
                        location={selectedLocation}
                    />
                </Grid>
                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            handleUpdate()
                        }}
                        invalid={invalid}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                handleComplete(isInvalidNextButton)
                            } else {
                                handleComplete()
                            }
                        }}
                        invalid={invalid}
                    />
                )}
            </Grid>
        </Grid>
    )
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Finalize)
