import React from "react"
import Grid from "@material-ui/core/Grid"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import { arrayRemove, change } from "redux-form"
import PropTypes from "prop-types"
import { Button, Typography } from "@material-ui/core"
import AlertsUserFields from "./alertsUserFields"
import AlertsRecipientFields from "./alertsRecipientFields"
import AlertShareModal from "./alertShareModal"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
})

class AlertsPreferences extends React.Component {
    state = {
        modalOpen: false,
        disableAlertPanel: false,
        disabledFields: {
            disableAdd: false,
            disableDispatched: false,
            disablePickedUp: false,
            disableOutForDelivery: false,
            disableDelivered: false,
            disableSelectAll: false,
            disableAlerts: false,
            disableLanguage: false,
        },
    }

    handleModalOpen = () => {
        this.setState({ modalOpen: true })
    }

    handleModalClose = () => {
        this.setState({ modalOpen: false })
    }

    componentDidMount() {
        if (this.props.isTrackPage) {
            this.checkAlertsToDisable(this.props.statusSteps)
        }
    }

    confirmRecipients = recipients => {
        this.props.confirmRecipients(recipients)
        this.setState({ modalOpen: false })
    }

    checkAlertsToDisable(statusSteps = []) {
        const { isTrackPage, isPickupLater, isShipmentCreator } = this.props
        const { statusSteps: steps, activeIndex } = statusSteps
        const currentStatus = get(steps[activeIndex], "status")
        const currentStatusIdentifier = steps[activeIndex]?.statusIdentifier

        if (!isShipmentCreator) {
            this.setState({ disableAlertPanel: true })
        } else {
            let adjustedStatus

            if (!currentStatus && isPickupLater && isTrackPage) {
                adjustedStatus = "DISPATCHED"
            } else {
                adjustedStatus = currentStatusIdentifier
            }

            if (adjustedStatus) {
                switch (adjustedStatus) {
                    case "DELIVERED":
                        this.setState({
                            disableAlertPanel: true,
                        })
                        break
                    case "DISPATCHED":
                        this.setState({
                            disabledFields: {
                                disableAdd: false,
                                disableDispatched: true,
                                disablePickedUp: false,
                                disableOutForDelivery: false,
                                disableDelivered: false,
                                disableSelectAll: true,
                                disableAlerts: false,
                            },
                        })
                        break
                    case "PICKED_UP":
                    case "IN_TRANSIT":
                        this.setState({
                            disabledFields: {
                                disableAdd: false,
                                disableDispatched: true,
                                disablePickedUp: true,
                                disableOutForDelivery: false,
                                disableDelivered: false,
                                disableSelectAll: true,
                                disableAlerts: false,
                            },
                        })
                        break
                    case "OUT_FOR_DELIVERY":
                        this.setState({
                            disabledFields: {
                                disableAdd: false,
                                disableDispatched: true,
                                disablePickedUp: true,
                                disableOutForDelivery: true,
                                disableDelivered: false,
                                disableSelectAll: true,
                                disableAlerts: false,
                            },
                        })
                        break
                    default:
                        this.setState({
                            disableAlertPanel: false,
                        })
                        break
                }
            }
        }
    }

    render() {
        const {
            chooseWarnings,
            share,
            title,
            selectedRecipients,
            handleSelectAll,
            handleDelete,
            isFreightDirect,
            bookShipmentWorkflow,
            location,
        } = this.props

        return (
            <Grid item container xs={12}>
                <Grid item container>
                    {bookShipmentWorkflow ? (
                        <Grid item container justify="center">
                            <Typography variant="subheading">
                                {title || (
                                    <FormattedMessage
                                        id="alertsPreferences__title"
                                        defaultMessage="Notifications"
                                    />
                                )}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={share ? 3 : 12} justify="center">
                            <Typography variant="title">
                                {title || (
                                    <FormattedMessage
                                        id="alertsPreferences__title"
                                        defaultMessage="Notifications"
                                    />
                                )}
                            </Typography>
                        </Grid>
                    )}

                    {share && (
                        <Grid item xs={3} justify="center">
                            <Button
                                id="addRecipient"
                                variant="outlined"
                                color="primary"
                                onClick={() => this.handleModalOpen()}
                                disabled={
                                    this.state.disableAlertPanel
                                        ? this.state.disableAlertPanel
                                        : this.state.disabledFields.disableAdd
                                }
                            >
                                {
                                    <FormattedMessage
                                        id="alertsPreferences__addRecipient"
                                        defaultMessage="Add Recipient"
                                    />
                                }
                            </Button>
                        </Grid>
                    )}
                </Grid>
                <AlertShareModal
                    open={this.state.modalOpen}
                    initialValues={{ selectedRecipients }}
                    handleClose={this.handleModalClose}
                    confirmRecipients={this.confirmRecipients}
                    isFreightDirect={isFreightDirect}
                    location={location}
                />
                <Grid item container>
                    <Grid item container xs={share ? 4 : 12}>
                        <AlertsUserFields
                            share={share}
                            chooseWarnings={chooseWarnings}
                            subject="user"
                            disableAlertPanel={this.state.disableAlertPanel}
                            disabledFields={this.state.disabledFields}
                        />
                    </Grid>
                    {share &&
                        !this.state.modalOpen &&
                        (selectedRecipients || []).map((recipient, index) => (
                            <Grid item container xs={4} key={index}>
                                <AlertsRecipientFields
                                    index={index}
                                    share={share}
                                    handleSelectAll={handleSelectAll}
                                    chooseWarnings={chooseWarnings}
                                    subject={recipient.label}
                                    handleDelete={handleDelete}
                                    disableAlertPanel={
                                        this.state.disableAlertPanel
                                    }
                                    disabledFields={this.state.disabledFields}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        )
    }
}

AlertsPreferences.propTypes = {
    chooseWarnings: PropTypes.bool,
    share: PropTypes.bool,
}

AlertsPreferences.defaultProps = {
    chooseWarnings: false,
    share: false,
}

const mapRecipients = contacts => {
    const recipients = []
    contacts.forEach(contact => {
        const contactInfo = contact.contact
        recipients.push({
            values: [
                {
                    type: "phone",
                    value: contactInfo.phone.phoneNumber,
                },
                {
                    type: "email",
                    value: contactInfo.email.emailAddress,
                },
            ],
            label: contactInfo.name,
        })
    })
    return recipients
}

const mapStateToProps = (state, props) => {
    const formName = props.formName

    return {
        contacts: mapRecipients(state.addressBook.addresses),
        selectedRecipients: get(state, [
            "form",
            formName,
            "values",
            "selectedRecipients",
        ]),
        newEmailRecipient: get(state, [
            "form",
            formName,
            "values",
            "newEmailRecipient",
        ]),
        emailError: get(state, [
            "form",
            formName,
            "syncErrors",
            "newEmailRecipient",
        ]),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    confirmRecipients: recipients => {
        dispatch(change(props.formName, "selectedRecipients", recipients))
        recipients.forEach(recipient => {
            const alertsSection = props?.shipmentDetailsFormValues?.alerts || []
            const oldValues = alertsSection[recipient.label] || {}
            if (!oldValues?.language) {
                oldValues.language = "en-us"
            }

            dispatch(
                change(props.formName, `alerts["${recipient.label}"]`, {
                    ...oldValues,
                })
            )
        })
    },
    handleDelete: (index, subject) => {
        dispatch(arrayRemove(props.formName, "selectedRecipients", index))
        dispatch(arrayRemove(props.formName, "alerts", index))
        dispatch(change(props.formName, `alerts["${subject}"]`, null))
    },
    handleSelectAll: subject => {
        dispatch(
            change(props.formName, `alerts["${subject}"].dispatched`, true)
        )
        dispatch(change(props.formName, `alerts["${subject}"].pickedUp`, true))
        dispatch(
            change(props.formName, `alerts["${subject}"].outForDelivery`, true)
        )
        dispatch(change(props.formName, `alerts["${subject}"].delivered`, true))
        dispatch(
            change(
                props.formName,
                `alerts["${subject}"].podImageAvailable`,
                true
            )
        )
    },
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AlertsPreferences)
)
