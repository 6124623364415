const currencyLabelWithoutAmount = preferredCurrencyCode => {
    switch (preferredCurrencyCode) {
        case "USD":
            return "$"
        case "CAD":
            return "C$"
        case "MXP":
            return "Mex$"
        default:
            return "$"
    }
}

const currencyLabel = (preferredCurrencyCode, amount) =>
    currencyLabelWithoutAmount(preferredCurrencyCode) +
    Number(amount).toFixed(2)

export { currencyLabelWithoutAmount, currencyLabel }
