import React, { Component } from "react"
import { connect } from "react-redux"
import {
    formValues,
    resetSection,
    touch,
    untouch,
    ReduxFormContext,
} from "redux-form"
import { get } from "lodash"
import moment from "moment"
import {
    composeValidators,
    createValidator,
    combineValidators,
} from "revalidate"
import { hasError } from "revalidate/assertions"
import { defineMessages } from "react-intl"
import PickupForm from "./form"
import { reduxFormAction } from "../../../misc"
import {
    emailValidator,
    countryPhoneValidator,
    isRequiredIf,
    isNumeric,
} from "../../../actions/validation"

const messages = defineMessages({
    pickupWindowValidator: {
        id: "orderDetails.validation__pickupWindow",
        defaultMessage:
            "{field} should be at least 90 minutes after Ready Time",
    },
    pickupReadyField: {
        id: "generalTerms.pickUpInformation__readyTime",
        defaultMessage: "Ready Time",
    },
    pickupCloseField: {
        id: "generalTerms.pickUpInformation__closeTime",
        defaultMessage: "Close Time",
    },
    pickupNameField: {
        id: "generalTerms__name",
        defaultMessage: "Name",
    },
    pickupPhoneField: {
        id: "generalTerms__phone",
        defaultMessage: "Phone",
    },
    pickupExtensionField: {
        id: "generalTerms__extension",
        defaultMessage: "Extension",
    },
    pickupEmailField: {
        id: "generalTerms__email",
        defaultMessage: "Email",
    },
})

export const pickupWindowValidator = createValidator(
    message => (value, allValues) => {
        const time =
            get(allValues, "readyTime") || get(allValues, "origin.readyTime")
        const readyTime = moment(time, "HH:mm A")
        const closeTime = moment(value, "HH:mm A")
        const minutes = closeTime.diff(readyTime, "minutes")
        return minutes >= 90 ? undefined : message
    },
    field => ({ ...messages.pickupWindowValidator, values: { field } })
)

export const isRequiredIfPickedUp = isRequiredIf(
    values => !get(values, "pickupLater")
)

export const pickupValidator = countryField => ({
    readyTime: isRequiredIfPickedUp({ field: messages.pickupReadyField }),
    closeTime: composeValidators(
        isRequiredIfPickedUp,
        pickupWindowValidator
    )({
        field: messages.pickupCloseField,
    }),
    pickup: {
        name: isRequiredIfPickedUp({ field: messages.pickupNameField }),
        phone: {
            phone_number: composeValidators(
                isRequiredIfPickedUp,
                countryPhoneValidator(countryField)
            )({
                field: messages.pickupPhoneField,
            }),
            extension: isNumeric({
                field: messages.pickupExtensionField,
            }),
        },
        email: composeValidators(
            isRequiredIfPickedUp,
            emailValidator
        )({
            field: messages.pickupEmailField,
        }),
    },
})

const validator = combineValidators(pickupValidator("country"))

class Pickup extends Component {
    constructor(props) {
        super(props)
        this.fields = [
            "pickup.name",
            "pickup.phone.phone_number",
            "pickup.phone.extension",
            "pickup.email",
            "readyTime",
            "closeTime",
        ]
    }
    componentDidMount() {
        reduxFormAction(
            this.context,
            [
                "readyTime",
                "closeTime",
                "pickup.phone.phone_number",
                "pickup.phone.extension",
            ],
            this.props.touch
        )
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.readyTime !== this.props.readyTime ||
            prevProps.closeTime !== this.props.closeTime
        ) {
            reduxFormAction(
                this.context,
                ["readyTime", "closeTime"],
                this.props.touch
            )
        }
    }

    checkIfValid(newProps) {
        const { pickupLater, pickup, readyTime, closeTime, country } =
            newProps || this.props
        return !hasError(
            validator({ pickupLater, pickup, readyTime, closeTime, country })
        )
    }

    handleEditMode(editMode) {
        this.setState({ editMode })
    }

    handleOnCancel() {
        reduxFormAction(
            this.context,
            ["pickup", "readyTime", "closeTime"],
            this.props.cancel
        )
        reduxFormAction(this.context, this.fields, this.props.untouch)
        this.setState({ editMode: this.state.initEditMode })
    }

    handleOnDone() {
        const { pickupLater } = this.props
        reduxFormAction(this.context, this.fields, this.props.touch)
        const valid = this.checkIfValid()
        if (pickupLater || valid) this.handleEditMode(false)
    }

    render() {
        const { pickupLater, terminal } = this.props
        return (
            <PickupForm
                {...this.props}
                onCancel={() => this.handleOnCancel()}
                onDone={() => this.handleOnDone()}
                terminal={terminal}
                pickupLater={pickupLater}
            />
        )
    }
}

Pickup.contextType = ReduxFormContext

const mapDispatchToProps = dispatch => ({
    cancel: (form, section) => dispatch(resetSection(form, section)),
    untouch: (form, field) => dispatch(untouch(form, field)),
    touch: (form, field) => dispatch(touch(form, field)),
})

export default formValues({
    pickup: "pickup",
    readyTime: "readyTime",
    closeTime: "closeTime",
    pickupLater: "pickupLater",
})(connect(undefined, mapDispatchToProps)(Pickup))
