import { Grid, Typography } from "@material-ui/core"
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import SummaryButtons from "../SummaryButtons"
import { makeStyles } from "@material-ui/styles"
import { supportedCountryCodes } from "../../../../../misc"
import { Field } from "redux-form"
import FormZipCode from "../../../../form/form-zip-code"
import FormField from "../../../../form/form-field"
import FormSelectAutocompleteNew from "../../../../form/form-select-autocomplete-new"
import { Element, scroller } from "react-scroll"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
    },
    formContainer: {
        paddingRight: "8px",
    },
    mainInformation: {
        paddingBottom: "14px",
        borderBottom: "solid 1px #D4D4D4",
    },
    deliveryInformation: {
        paddingTop: "3px",
    },
})

export default function Destination({
    formValues = {},
    cities = [],
    handleCancelEdit,
    handleUpdate,
    invalid,
}) {
    const classes = useStyles()

    const { isFreightDirect, isFreightDirectReturns } = formValues

    useEffect(() => {
        scroller.scrollTo("destinationReviewTitle", {
            duration: 1000,
            smooth: true,
            offset: -90,
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const disabledNextButton = invalid || !formValues?.destination?.contact

    return (
        <Grid item container>
            <Grid item container justify="center">
                <Typography variant="title">
                    <FormattedMessage
                        id="bookShipment.review__destination"
                        defaultMessage="Edit Destination Information"
                    />
                </Typography>
                <Element name="destinationReviewTitle" />
            </Grid>
            <Grid item container>
                <Grid item container>
                    <Field
                        name="destination.shippingAddress.name"
                        required
                        label={
                            <FormattedMessage
                                id="generalTerms__companyName"
                                defaultMessage="Company Name"
                            />
                        }
                        disabled={true}
                        component={FormField}
                    />
                </Grid>

                <Grid item container xs={8}>
                    <Field
                        name="destination.shippingAddress.address.street1"
                        required
                        label={
                            <FormattedMessage
                                id="generalTerms__address1"
                                defaultMessage="Address 1"
                            />
                        }
                        disabled={true}
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={4}>
                    <Field
                        name="destination.shippingAddress.address.street2"
                        label={
                            <FormattedMessage
                                id="generalTerms__address2"
                                defaultMessage="Address 2"
                            />
                        }
                        disabled={true}
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={3}>
                    <Field
                        name="destination.shippingAddress.address.postalCode"
                        label={
                            <FormattedMessage
                                id="generalTerms__zipPostalCode"
                                defaultMessage="Zip/Postal Code"
                            />
                        }
                        disabled={true}
                        component={FormZipCode}
                        form="bookShipment"
                        country={
                            formValues?.destination?.shippingAddress?.address
                                ?.country
                        }
                    />
                </Grid>
                <Grid item container xs={5}>
                    <Field
                        name="destination.shippingAddress.address.city"
                        label={
                            <FormattedMessage
                                id="generalTerms__city"
                                defaultMessage="City"
                            />
                        }
                        component={FormSelectAutocompleteNew}
                        options={cities.map(c => ({
                            value: c,
                            label: c,
                        }))}
                        customValue
                    />
                </Grid>
                <Grid item container xs={2}>
                    <Field
                        name="destination.shippingAddress.address.state"
                        label={
                            <FormattedMessage
                                id="generalTerms__state"
                                defaultMessage="State"
                            />
                        }
                        disabled={true}
                        component={FormField}
                    />
                </Grid>

                <Grid item container xs={2}>
                    <Field
                        component={FormSelectAutocompleteNew}
                        name="destination.shippingAddress.address.country"
                        label={
                            <FormattedMessage
                                id="generalTerms__country"
                                defaultMessage="Country"
                            />
                        }
                        disabled={true}
                        options={supportedCountryCodes}
                    />
                </Grid>
                <Grid item container>
                    <Field
                        name="destination.contact.name"
                        label={
                            <FormattedMessage
                                id="generalTerms__contactName"
                                defaultMessage="Contact Name"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={4}>
                    <Field
                        name="destination.contact.phone.phone_number"
                        label={
                            <FormattedMessage
                                id="generalTerms__contactPhone"
                                defaultMessage="Contact Phone"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={2}>
                    <Field
                        name="destination.contact.phone.extension"
                        label={
                            <FormattedMessage
                                id="generalTerms__extension"
                                defaultMessage="Extension"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Field
                        name="destination.contact.email.email_address"
                        label={
                            <FormattedMessage
                                id="generalTerms__contactEmail"
                                defaultMessage="Contact Email"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
            </Grid>
            <Grid item container className={classes.deliveryInformation}>
                {!isFreightDirect && !isFreightDirectReturns && (
                    <Field
                        name="destination.note"
                        label={
                            <FormattedMessage
                                id="orderDetails.delivery__specialInstructions"
                                defaultMessage="Special Instructions"
                            />
                        }
                        component={FormField}
                        multiline
                    />
                )}
            </Grid>
            <SummaryButtons
                handleCancelEdit={handleCancelEdit}
                handleUpdate={() => {
                    handleUpdate()
                }}
                invalid={disabledNextButton}
            />
        </Grid>
    )
}
