import React from "react"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

export default {
    id: "getRates",
    steps: [
        {
            title: (
                <FormattedMessage
                    id="tutorial.rates__header"
                    defaultMessage="Tutorial: Rates"
                />
            ),
            target: "#getRatesButton",
            content: (
                <Grid container>
                    <Typography variant="subheading" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates__title"
                            defaultMessage="Welcome to the Rates tutorial"
                        />
                    </Typography>
                    <Typography align="left">
                        <FormattedMessage
                            id="tutorial.rates__subtitle"
                            defaultMessage="Here's where you'll get rates on LTL Select. We'll show you a brief 4-step tutorial."
                        />
                    </Typography>
                </Grid>
            ),
            disableBeacon: true,
        },
        {
            title: (
                <FormattedMessage
                    id="tutorial.rates__header"
                    defaultMessage="Tutorial: Rates"
                />
            ),
            target: "#billToLocation",
            content: (
                <Grid container>
                    <Typography variant="subheading" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates.billToLocation__title"
                            defaultMessage="Quickly find your locations"
                        />
                    </Typography>
                    <Typography align="left">
                        <FormattedMessage
                            id="tutorial.rates.billToLocation__content"
                            defaultMessage="Use the bill-to location dropdown to select which of your locations this rate should be associated with. You can manage your locations by finding the 'Locations' page in the navigation."
                        />
                    </Typography>
                </Grid>
            ),
        },
        {
            title: (
                <FormattedMessage
                    id="tutorial.rates__header"
                    defaultMessage="Tutorial: Rates"
                />
            ),
            target: "#favoriteItemsContainer",
            content: (
                <Grid container>
                    <Typography variant="subheading" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates.favoriteItems__title"
                            defaultMessage="Save time with saved items"
                        />
                    </Typography>
                    <Typography align="left" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates.favoriteItems__content1"
                            defaultMessage="You may use the list of saved items on the right side to automatically fill in the fields on the left. This allows you to quickly get a quote with items you've shipped before."
                        />
                    </Typography>
                    <Typography align="left" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates.favoriteItems__content2"
                            defaultMessage="Items that you use to get rates on the left side automatically get saved here. You can also manually add items that you use regularly by finding the 'Items' page in the navigation."
                        />
                    </Typography>
                </Grid>
            ),
        },
        {
            title: (
                <FormattedMessage
                    id="tutorial.rates__header"
                    defaultMessage="Tutorial: Rates"
                />
            ),
            target: "#quickQuoteButton",
            content: (
                <Grid container>
                    <Typography variant="subheading" gutterBottom>
                        <FormattedMessage
                            id="tutorial.rates.quickQuote__title"
                            defaultMessage="Quick Quote"
                        />
                    </Typography>
                    <Typography align="left">
                        <FormattedMessage
                            id="tutorial.rates.quickQuote__content"
                            defaultMessage="At the bottom left of the page you'll find the option to create a Quick Quote. These quotes require much less detail to get you a rough estimate of shipping costs."
                        />
                    </Typography>
                </Grid>
            ),
        },
    ],
}
