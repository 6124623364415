import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import { composeHandlingUnitSummary } from "../atoms/HandlingUnit"

const useStyles = makeStyles({
    basicInfoSummaryContainer: {
        padding: "0 15%",
    },
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
    header: {
        paddingBottom: "15px",
    },
    section: {
        paddingTop: "15px",
    },
    huRow: {
        borderTop: "solid 1px #d4d4d4",
        padding: "8px 0",
    },
    huRowTop: {
        padding: "8px 0",
    },
})

const ItemsSummary = ({
    formValues,
    handleEdit,
    weightUnit,
    intl,
    isReview,
}) => {
    const classes = useStyles()
    const { handlingUnits = [] } = formValues

    return (
        <Grid
            item
            container
            className={!isReview ? classes.basicInfoSummaryContainer : ""}
        >
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.itemsSummary__title"
                            defaultMessage="Line Items"
                        />
                    </Typography>
                    {!isReview && (
                        <Button
                            id="editItems"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleEdit(3)}
                        >
                            <FormattedMessage
                                id="generalTerms__edit"
                                defaultMessage="Edit"
                            />
                        </Button>
                    )}
                </Grid>
                <Grid item container direction="column">
                    {handlingUnits.map((hu, index) => (
                        <Grid
                            item
                            container
                            className={
                                index === 0 ? classes.huRowTop : classes.huRow
                            }
                        >
                            <Typography variant="caption">
                                {composeHandlingUnitSummary(
                                    hu,
                                    weightUnit,
                                    intl
                                )}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default injectIntl(ItemsSummary)
