import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Element, animateScroll } from "react-scroll"
import { Panel } from "react-bootstrap"
import { scrolledToValidation } from "../../actions/validation"

class ValidationPanelPresentation extends Component {
    componentWillReceiveProps(newProps) {
        if (this.props.needScroll && newProps.validation.length) {
            animateScroll.scrollTo("validation-error", {
                duration: 200,
                smooth: true,
            })
            this.props.onScroll()
        }
    }

    render() {
        const { validation } = this.props
        if (!validation.length) return <div />
        return (
            <Element name="validation-error">
                <Panel bsStyle="danger" className="error-message">
                    {validation.map((item, i) => (
                        <div key={i}>{item.message}</div>
                    ))}
                </Panel>
            </Element>
        )
    }
}

ValidationPanelPresentation.propTypes = {
    validation: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
        })
    ).isRequired,
    needScroll: PropTypes.bool.isRequired,
    onScroll: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    needScroll: state.validation.needScroll,
})

const mapDispatchToProps = dispatch => ({
    onScroll: () => dispatch(scrolledToValidation()),
})

export const ValidationPanel = connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidationPanelPresentation)

export const combineValidations = (...validations) =>
    validations.reduce(
        (prev, item) =>
            prev.concat(
                Object.keys(item || {}).map(key => ({
                    field: key,
                    message: item[key],
                }))
            ),
        []
    )
