import React from "react"
import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
} from "@material-ui/core"
import PropTypes from "prop-types"

const headCells = [
    {
        id: "accountNumber",
        numeric: false,
        disablePadding: true,
        label: "Account",
    },
    {
        id: "accountType",
        numeric: false,
        disablePadding: false,
        label: "Account Type",
    },
    {
        id: "basicDiscount",
        numeric: true,
        disablePadding: false,
        label: "Basic Fee/Discount",
    },
    {
        id: "basicApptDiscount",
        numeric: true,
        disablePadding: false,
        label: "Basic by Appt Fee/Discount",
    },
    {
        id: "standardDiscount",
        numeric: true,
        disablePadding: false,
        label: "Standard Fee/Discount",
    },
    {
        id: "premiumDiscount",
        numeric: true,
        disablePadding: false,
        label: "Premium Fee/Discount",
    },
    {
        id: "basicPickupDiscount",
        numeric: true,
        disablePadding: false,
        label: "Return Basic Pickup Fee/Discount",
    },
    {
        id: "basicApptPickupDiscount",
        numeric: true,
        disablePadding: false,
        label: "Return Basic by Appt Pickup Fee/Discount",
    },
    {
        id: "standardPickupDiscount",
        numeric: true,
        disablePadding: false,
        label: "Return Standard Pickup Fee/Discount",
    },
    {
        id: "premiumPickupDiscount",
        numeric: true,
        disablePadding: false,
        label: "Return Premium Pickup Fee/Discount",
    },
]

const DiscountTableHead = ({ order, orderBy, handleRequestSort, classes }) => {
    const createSortHandler = property => event => {
        handleRequestSort(property, event)
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        sortDirection={orderBy === headCell.id ? order : "asc"}
                        className={
                            headCell.id === "accountNumber"
                                ? classes.fixed__column
                                : ""
                        }
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

DiscountTableHead.propTypes = {
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    handleRequestSort: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
}

export default DiscountTableHead
