import React from "react"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import { FormattedMessage } from "react-intl"
import { currencyLabel } from "../../util/currencyLabel"

const styles = theme => ({
    rate__divider: {
        "border-bottom": "1px solid black",
        width: "40%",
        padding: "1px 0",
    },
})

function RateBreakdown(props) {
    const {
        charges,
        totalInPreferredCurrency,
        gridSize,
        classes,
        quoteID,
        preferredCurrencyCode,
        isBookShipment,
        rateIndex,
    } = props

    if (!totalInPreferredCurrency && !quoteID) return null

    return (
        <Grid
            item
            container
            justify="flex-end"
            alignItems="flex-end"
            xs={gridSize}
        >
            {charges?.map((entry, index) =>
                entry.amountInPreferredCurrency || entry.amount ? (
                    <Grid item container key={index} xs={12} justify="flex-end">
                        <Grid item xs={9}>
                            <Typography
                                id={`select-rate-${rateIndex}-charge-${index}-description`}
                                variant="caption"
                                align="right"
                            >
                                {entry.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography
                                id={`select-rate-${rateIndex}-charge-${index}-value`}
                                variant="caption"
                                align="right"
                            >
                                {currencyLabel(
                                    preferredCurrencyCode,
                                    entry?.amountInPreferredCurrency ??
                                        entry?.amount
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                ) : null
            )}
            <Grid
                item
                justify="flex-end"
                alignItems="center"
                className={classes.rate__divider}
            />
            <Grid item container alignItems="center">
                {isBookShipment && <Grid item container xs={2} />}
                <Grid item xs={isBookShipment ? 4 : 6}>
                    {quoteID && (
                        <Typography variant="caption">
                            {`ID#: ${quoteID}`}
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Typography
                        id={`select-rate-${rateIndex}-total`}
                        align="right"
                    >
                        <FormattedMessage
                            id="rateBreakdown__total"
                            defaultMessage="Total"
                        />
                        {":"}
                    </Typography>
                </Grid>
                {totalInPreferredCurrency && (
                    <Grid item xs={3}>
                        <Typography
                            id={`select-rate-${rateIndex}-total-value`}
                            variant="subheading"
                            align="right"
                        >
                            {currencyLabel(
                                preferredCurrencyCode,
                                totalInPreferredCurrency
                            )}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Grid>
    )
}

RateBreakdown.propTypes = {
    charges: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    gridSize: PropTypes.number.isRequired,
    listView: PropTypes.bool.isRequired,
}

export default withStyles(styles)(RateBreakdown)
