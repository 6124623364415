import React, { useState, useContext, createContext } from "react"

const Context = createContext({
    open: false,
    openModal: () => {},
    closeModal: () => {},
    shipmentId: undefined,
    setShipmentId: () => {},
    shouldOpen: false,
    setShouldOpen: () => {},
})

export const useBookedShipmentContext = () => {
    const bookedShipmentContext = useContext(Context)
    if (!bookedShipmentContext) {
        throw new Error(
            "Cannot use booked shipment context ouside of bookedShipmentProvider"
        )
    }
    return bookedShipmentContext
}

const BookedShipmentProviderContext = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [shipmentId, setShipmentId] = useState(undefined)
    const [shouldOpen, setShouldOpen] = useState(false)

    const openModal = shipmentId => {
        setShipmentId(shipmentId)
        setOpen(true)
        setShouldOpen(false)
    }

    const closeModal = () => {
        setShipmentId(undefined)
        setOpen(false)
    }

    return (
        <Context.Provider
            value={{
                open,
                openModal,
                closeModal,
                shipmentId,
                setShipmentId,
                shouldOpen,
                setShouldOpen,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const BookedShipmentProvider = BookedShipmentProviderContext
