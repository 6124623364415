import React from "react"
import { connect } from "react-redux"
import { Field, resetSection, arrayPush, formValues } from "redux-form"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import CancelIcon from "@material-ui/icons/Cancel"
import EmailIcon from "@material-ui/icons/Email"
import { withStyles, Typography, Grid, Chip, Avatar } from "@material-ui/core"
import RecipientsField from "../../form/form-select-recipients"
import FormField from "../../form/form-field"
import { FormattedMessage } from "react-intl"
import { preferenceLanguageOptions } from "../../constants/preferenceLanguageOptions"
import FormSelect from "../../form/form-select"

const styles = theme => ({
    form__container: {
        marginBottom: theme.spacing.unit * 5,
    },
    chip: {
        margin: "20px 10px",
        backgroundColor: theme.palette.primary.light,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    select__container: {
        padding: "10px",
    },
    recipients__field: {
        width: "250px",
    },
    add__recipient: {
        marginTop: "5px",
    },
})

const RecipientsForm = ({
    selected,
    contacts,
    classes,
    onDelete,
    addNewRecipient,
    newEmailRecipient,
    newPhoneRecipient,
    language,
    addRecipient,
    emailError,
    phoneError,
}) => (
    <Grid
        item
        container
        xs={12}
        className={classes.form__container}
        spacing={16}
    >
        <Grid item xs={12}>
            <Typography variant="title" gutterBottom>
                <FormattedMessage
                    id="rateResults.share__recipients"
                    defaultMessage="Recipients"
                />
            </Typography>
        </Grid>
        <Grid item container xs={5}>
            <Paper className={classes.select__container}>
                <Grid item>
                    <Field
                        component={RecipientsField}
                        name="recipients"
                        placeholder={
                            <FormattedMessage
                                id="rateResults.share__selectContact"
                                defaultMessage="Select Contact"
                            />
                        }
                        className={classes.recipients__field}
                        options={contacts}
                        onChange={(e, value) => addRecipient(value)}
                    />
                </Grid>
                {/*[LS-153] hide the text message now*/}
                {/*<Grid item>*/}
                {/*    <Field*/}
                {/*        component={FormField}*/}
                {/*        name="newPhoneRecipient"*/}
                {/*        label={*/}
                {/*            <FormattedMessage*/}
                {/*                id="rateResults.share__phoneNumber"*/}
                {/*                defaultMessage="Phone Number"*/}
                {/*            />*/}
                {/*        }*/}
                {/*        className={classes.recipients__field}*/}
                {/*        normalize={normalizePhone}*/}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item>
                    <Field
                        component={FormField}
                        name="newEmailRecipient"
                        label={
                            <FormattedMessage
                                id="rateResults.share__emailAddress"
                                defaultMessage="Email Address"
                            />
                        }
                        className={classes.recipients__field}
                    />
                </Grid>
                <Grid item>
                    <Field
                        component={FormSelect}
                        name="language"
                        placeholder={
                            <FormattedMessage
                                id="rateResults.share__selectLanguage"
                                defaultMessage="Select Language"
                            />
                        }
                        options={preferenceLanguageOptions}
                        className={classes.recipients__field}
                    />
                </Grid>
                <Grid
                    item
                    container
                    justify="flex-end"
                    className={classes.add__recipient}
                >
                    <Button
                        variant="contained"
                        onClick={() =>
                            addNewRecipient(newEmailRecipient, language)
                        }
                        disabled={
                            !newEmailRecipient || !!emailError || !!phoneError
                        }
                    >
                        <FormattedMessage
                            id="rateResults.share__addRecipient"
                            defaultMessage="Add Recipient"
                        />
                    </Button>
                </Grid>
            </Paper>
        </Grid>
        <Grid item container direction="column" xs={7}>
            <Grid item>
                {selected &&
                    selected.map((contact, i) => (
                        <Chip
                            tabIndex={-1}
                            avatar={
                                <Avatar className={classes.chip__avatar}>
                                    <EmailIcon />
                                    {/*{contact.emailAddress == null ? (*/}
                                    {/*    <ChatIcon />*/}
                                    {/*) : (*/}
                                    {/*    <EmailIcon />*/}
                                    {/*)}*/}
                                </Avatar>
                            }
                            label={`${contact.guestName}, ${
                                contact.language.split("-")[0]
                            }`}
                            className={classes.chip}
                            deleteIcon={<CancelIcon />}
                            onDelete={() => onDelete(contact, i)}
                        />
                    ))}
            </Grid>
        </Grid>
    </Grid>
)

const mapStateToProps = (state, props) => ({
    emailError: state?.form?.[props?.formName]?.syncErrors?.newEmailRecipient,
    phoneError: state?.form?.[props?.formName]?.syncErrors?.newPhoneRecipient,
})

const mapDispatchToProps = (dispatch, props) => ({
    addRecipient: value => {
        if (!value.length) return
        const recipient = { ...value[value.length - 1] }
        let addedValue = {
            language: props.language,
            guestName: `${recipient?.label} (${recipient?.value?.value})`,
            emailAddress: recipient?.value?.value,
        }
        // LS-153: disable texting. keep it with commenting, in case we need it back
        // if (recipient.value.type === 'email') {
        //     addedValue.emailAddress = recipient.value.value
        // } else {
        //     addedValue.phoneNumber = recipient.value.value
        // }
        dispatch(arrayPush("shareQuote", "mergedRecipients", addedValue))
    },
    addNewRecipient: (email, language) => {
        dispatch(resetSection("shareQuote", "newPhoneRecipient"))
        dispatch(resetSection("shareQuote", "newEmailRecipient"))
        dispatch(
            arrayPush("shareQuote", "mergedRecipients", {
                emailAddress: email,
                language,
                guestName: email,
            })
        )
    },
})

export default withStyles(styles)(
    formValues({
        newEmailRecipient: "newEmailRecipient",
        newPhoneRecipient: "newPhoneRecipient",
        mergedRecipients: "mergedRecipients",
        language: "language",
    })(connect(mapStateToProps, mapDispatchToProps)(RecipientsForm))
)
