import React, { Component } from "react"
import { connect } from "react-redux"
import { Prompt } from "react-router"
import { reduxForm } from "redux-form"
import { get } from "lodash"
import Joyride, { EVENTS, ACTIONS } from "react-joyride"
import { combineValidators, composeValidators } from "revalidate"
import {
    Grid,
    withStyles,
    Typography,
    Paper,
    Snackbar,
    Button,
} from "@material-ui/core"
import {
    basicUpdateUser,
    setActiveLocation,
    trackGAEventNew,
} from "../../actions/user"
import { changePath } from "../../actions"
import LocationContainer from "./components/item"
import UnsavedChanges from "./components/unsavedChanges"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import {
    emailValidator,
    countryPhoneValidator,
    isRequired,
    isRequiredIf,
    isNumeric,
} from "../../actions/validation"
import { finishTutorial } from "../../actions/user"
import tutorialStyles from "../tutorials/styles"
import locationsTutorial from "../tutorials/locations"
import { FormattedMessage } from "react-intl"
import { locationUpdateConfirm } from "../../messages/confirmation-constants"

const styles = theme => ({
    leftNav__container: {
        paddingTop: "1%",
        paddingBottom: "1%",
    },
    leftNav__paper: {
        width: "100%",
        height: "100%",
        paddingTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        boxSizing: "border-box",
        flexDirection: "column",
        paddingBottom: "12px",
        minHeight: "700px",
    },
    leftNav__title: {
        padding: "20px 10px",
    },
    leftNav__location: {
        padding: "10px 10px",
        "&:hover": {
            cursor: "pointer",
        },
        "&:active": {
            backgroundColor: "#D4D4D4",
            cursor: "pointer",
        },
    },
    leftNav__location__active: {
        padding: "10px 10px",
        backgroundColor: "#F2F2F2",
        width: "110%",
        zIndex: 2,
    },
    right__content: {
        padding: "3px 20px 20px 20px",
        zIndex: 4,
    },
    shadow__hider: {
        width: "400px",
    },
    location__name: {
        fontWeight: 1000,
    },
    default__tag: {
        paddingLeft: "5px",
    },
})

class LocationsPresentation extends Component {
    state = {
        index: 0,
        sbOpen: false,
        sbVariant: null,
        sbMessage: "",
        unsavedChangesOpen: false,
        bufferedIndex: null,
        run: true,
        stepIndex: 0,
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        this.setState({ sbOpen: false })
    }

    handleSnackbarOpen = (variant, message) => {
        this.setState({
            sbOpen: true,
            sbVariant: variant,
            sbMessage: message,
        })
    }

    setActiveLocation = index => {
        const { dirty, setActiveLocation } = this.props

        if (index === this.props.activeLocationIndex) {
        } else if (dirty) {
            this.setState({
                unsavedChangesOpen: true,
                bufferedIndex: index,
            })
        } else setActiveLocation(index)
    }

    handleUnsavedChangesClose = () => {
        this.setState({ unsavedChangesOpen: false })
    }

    handleUnsavedChangesContinue = () => {
        this.props.setActiveLocation(this.state.bufferedIndex)
        this.setState({
            unsavedChangesOpen: false,
            bufferedIndex: null,
        })
    }

    componentDidMount() {
        const { basicUpdateUser, setActiveLocation } = this.props

        basicUpdateUser()
        setActiveLocation(0)
    }

    handleJoyride = async data => {
        const { index, type, action } = data
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            this.setState({
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
            })
        } else if ([EVENTS.TOUR_END].includes(type)) {
            this.props.finishTutorial(data, locationsTutorial.id)
        }
    }

    render() {
        const {
            classes,
            locations,
            handleSubmit,
            dirty,
            activeLocationIndex,
            defaultLocationId,
            tutorialCompleted,
            onAddLocationSelect,
        } = this.props
        const {
            sbOpen,
            sbMessage,
            sbVariant,
            unsavedChangesOpen,
            run,
            stepIndex,
        } = this.state
        const isRequested =
            get(locations[activeLocationIndex], "users[0].role") === "requested"
        const isOwner =
            get(locations[activeLocationIndex], "users[0].role") === "owner"

        return (
            <Grid container alignItems="flex-start">
                {!tutorialCompleted && isOwner && (
                    <Joyride
                        callback={data => this.handleJoyride(data)}
                        steps={locationsTutorial.steps.owner}
                        showSkipButton
                        showProgress
                        continuous
                        floaterProps={{
                            placement: "right",
                        }}
                        stepIndex={stepIndex}
                        run={run}
                        styles={tutorialStyles}
                        locale={{
                            back: (
                                <FormattedMessage
                                    id="tutorial.back"
                                    defaultMessage="Back"
                                />
                            ),
                            close: (
                                <FormattedMessage
                                    id="tutorial.close"
                                    defaultMessage="Close"
                                />
                            ),
                            last: (
                                <FormattedMessage
                                    id="tutorial.last"
                                    defaultMessage="Last"
                                />
                            ),
                            next: (
                                <FormattedMessage
                                    id="tutorial.next"
                                    defaultMessage="Next"
                                />
                            ),
                            skip: (
                                <FormattedMessage
                                    id="tutorial.skip"
                                    defaultMessage="Skip"
                                />
                            ),
                        }}
                    />
                )}
                <Prompt
                    when={dirty && !isRequested}
                    message={locationUpdateConfirm}
                />
                <UnsavedChanges
                    message={locationUpdateConfirm}
                    handleClose={this.handleUnsavedChangesClose}
                    handleContinue={this.handleUnsavedChangesContinue}
                    open={unsavedChangesOpen}
                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={sbOpen}
                    autoHideDuration={5000}
                    onClose={this.handleSnackbarClose}
                    id="snackbar"
                >
                    <ErrorSnackbarContentWrapper
                        variant={sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={<Typography>{sbMessage}</Typography>}
                    />
                </Snackbar>
                <Grid container xs={2} className={classes.leftNav__container}>
                    <Paper className={classes.leftNav__paper} elevation={2}>
                        <Grid item container id="locationLeft">
                            <Grid
                                item
                                container
                                className={classes.leftNav__title}
                            >
                                <Typography variant="subheading">
                                    <FormattedMessage
                                        id="locations.menu__overview"
                                        defaultMessage="Location Overview"
                                    />
                                </Typography>
                            </Grid>
                            <Grid item container>
                                {locations.map((location, i) => {
                                    const isFreightDirect =
                                        location?.locationType ===
                                        "FEDEX_DIRECT"

                                    const isFreightDirectReturns =
                                        location?.locationType ===
                                        "FEDEX_DIRECT_RETURNS"

                                    const isFreightBox =
                                        location?.locationType ===
                                        "FEDEX_FREIGHT_BOX"

                                    return (
                                        <Grid
                                            item
                                            container
                                            alignContent="center"
                                            wrap={
                                                i === activeLocationIndex
                                                    ? "nowrap"
                                                    : "wrap"
                                            }
                                            id={`leftNavLocation__${i}`}
                                            className={
                                                i === activeLocationIndex
                                                    ? classes.leftNav__location__active
                                                    : classes.leftNav__location
                                            }
                                            onClick={() =>
                                                this.setActiveLocation(i)
                                            }
                                        >
                                            <Grid
                                                item
                                                className={
                                                    i === activeLocationIndex &&
                                                    classes.shadow__hider
                                                }
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        className={
                                                            classes.location__name
                                                        }
                                                        color="inherit"
                                                        id={`leftNavLocation__${i}__name`}
                                                    >
                                                        {`${
                                                            get(
                                                                location,
                                                                "cpgName"
                                                            )
                                                                ? location.cpgName
                                                                : get(
                                                                      location,
                                                                      "shippingAddress.name"
                                                                  )
                                                        }`}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        className={
                                                            classes.default__tag
                                                        }
                                                    >
                                                        {defaultLocationId ===
                                                            get(
                                                                location,
                                                                "_id"
                                                            ) && (
                                                            <FormattedMessage
                                                                id="locations__default"
                                                                defaultMessage="(Default)"
                                                            />
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                {location?.fedexFreightAccount && (
                                                    <Typography
                                                        id={`leftNavLocation__${i}__freightAccount`}
                                                        variant="caption"
                                                    >
                                                        {isFreightDirect ||
                                                        isFreightDirectReturns
                                                            ? [
                                                                  <FormattedMessage
                                                                      id="locations__FXFDShipperAccountNumber"
                                                                      defaultMessage="FXFD Shipper Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexFreightAccount,
                                                              ]
                                                            : [
                                                                  <FormattedMessage
                                                                      id="locations__shipperAccountNumber"
                                                                      defaultMessage="Shipper Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexFreightAccount,
                                                              ]}
                                                    </Typography>
                                                )}
                                                {location?.fedexBillToAccount && (
                                                    <Typography
                                                        id={`leftNavLocation__${i}__billToAccount`}
                                                        variant="caption"
                                                    >
                                                        {isFreightDirect ||
                                                        isFreightDirectReturns
                                                            ? [
                                                                  <FormattedMessage
                                                                      id="locations__FXFDThirdPartyAccountNumber"
                                                                      defaultMessage="FXFD Third Party Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexBillToAccount,
                                                              ]
                                                            : [
                                                                  <FormattedMessage
                                                                      id="locations__thirdPartyAccountNumber"
                                                                      defaultMessage="Third Party Account #"
                                                                  />,
                                                                  ": ",
                                                                  location?.fedexBillToAccount,
                                                              ]}
                                                    </Typography>
                                                )}
                                                {isFreightDirect && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightDirect"
                                                            defaultMessage="FedEx Freight Direct"
                                                        />
                                                    </Typography>
                                                )}
                                                {isFreightBox && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightBox"
                                                            defaultMessage="FedEx Freight Box"
                                                        />
                                                    </Typography>
                                                )}
                                                {isFreightDirectReturns && (
                                                    <Typography
                                                        variant="caption"
                                                        color="primary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__freightDirectReturns"
                                                            defaultMessage="FedEx Freight Direct Returns"
                                                        />
                                                    </Typography>
                                                )}
                                                {get(
                                                    location,
                                                    "users[0].role"
                                                ) === "requested" && (
                                                    <Typography
                                                        variant="caption"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__requestedAccess"
                                                            defaultMessage="Requested Access"
                                                        />
                                                    </Typography>
                                                )}
                                                {get(
                                                    location,
                                                    "users[0].permissions.suspended.value"
                                                ) && (
                                                    <Typography
                                                        variant="caption"
                                                        color="secondary"
                                                    >
                                                        <FormattedMessage
                                                            id="locations.users__inactive"
                                                            defaultMessage="Inactive"
                                                        />
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.addLocation__button}
                        >
                            <Button
                                color="primary"
                                onClick={onAddLocationSelect}
                                id="addLocationButton"
                            >
                                +{" "}
                                <FormattedMessage
                                    id="locations.menu__addLocation"
                                    defaultMessage="Add Location"
                                />
                            </Button>
                        </Grid>
                    </Paper>
                </Grid>
                {locations[activeLocationIndex] && (
                    <Grid
                        item
                        container
                        xs={10}
                        className={classes.right__content}
                    >
                        <LocationContainer
                            location={locations[activeLocationIndex]}
                            form={"location"}
                            index={activeLocationIndex}
                            handleSnackbarOpen={this.handleSnackbarOpen}
                            handleSubmit={handleSubmit}
                            dirty={dirty}
                            startedDefault={get(
                                this.props.initialValues,
                                "isDefaultLocation",
                                false
                            )}
                        />
                    </Grid>
                )}
            </Grid>
        )
    }
}

const mapStateToProps = (state, props) => {
    const locations = get(state, "user.profile.locations", [])
    if (!locations.length) return locations
    const activeLocationIndex = get(state, "user.activeLocation", 0)
    const isDefaultLocation =
        get(locations[activeLocationIndex], "_id", "") ===
        get(state, "user.profile.preferences.defaultLocationId", "")

    const activeLocation = locations[activeLocationIndex] ?? {
        pickupAccessorials: [],
        deliveryAccessorials: [],
    }

    let existingPickupAccessorials = activeLocation?.pickupAccessorials ?? [
        { value: "DOCKPU" },
    ]
    let existingDeliveryAccessorials = activeLocation?.deliveryAccessorials ?? [
        { value: "DOCKDEL" },
    ]

    const pickupAccessorials = existingPickupAccessorials.map(
        entry => entry.value
    )

    const deliveryAccessorials = existingDeliveryAccessorials.map(
        entry => entry.value
    )

    return {
        locations,
        activeLocationIndex,
        initialValues: {
            ...locations[activeLocationIndex],
            isDefaultLocation,
            pickupAccessorials,
            deliveryAccessorials,
        },
        defaultLocationId: get(
            state,
            "user.profile.preferences.defaultLocationId",
            ""
        ),
        tutorialCompleted: get(
            state,
            `user.profile.preferences.tutorials.${locationsTutorial.id}.completed`,
            false
        ),
    }
}

const mapDispatchToProps = dispatch => ({
    basicUpdateUser: () => dispatch(basicUpdateUser()),
    setActiveLocation: index => dispatch(setActiveLocation(index)),
    onAddLocationSelect: () => {
        dispatch(trackGAEventNew("Location", "Add Location Button Click"))
        dispatch(changePath("/locations/add"))
    },
    finishTutorial: (data, id) => dispatch(finishTutorial(data, id)),
})

const isRequiredIfStreet1Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street1
)
const isRequiredIfStreet2Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street2
)

const isRequiredIfPickUpAndDestroy = isRequiredIf(values =>
    get(values, "pickupAndDestroyLocation")
)

const isRequiredIfStreet1MissingPickAndDestoy = isRequiredIf(
    values =>
        !values?.pickupAndDestroyLocation?.billingAddress?.street1 &&
        values?.pickupAndDestroyLocation
)
const isRequiredIfStreet2MissingPickAndDestoy = isRequiredIf(
    values =>
        !values?.pickupAndDestroyLocation?.billingAddress?.street2 &&
        values?.pickupAndDestroyLocation
)

const locationItemValidation = combineValidators({
    readyTime: isRequired({
        field: {
            id: "generalTerms.pickUpInformation__readyTime",
            defaultMessage: "Ready Time",
        },
    }),
    closeTime: isRequired({
        field: {
            id: "generalTerms.pickUpInformation__closeTime",
            defaultMessage: "Close Time",
        },
    }),
    pickupContact: {
        name: isRequired({
            field: {
                id: "generalTerms.pickUpInformation__pickupName",
                defaultMessage: "Pickup Name",
            },
        }),
        email: {
            email_address: composeValidators(
                isRequired,
                emailValidator
            )({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
        phone: {
            phone_number: composeValidators(
                isRequired,
                countryPhoneValidator("shippingAddress.address.country")
            )({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
    },
    shippingAddress: {
        name: isRequired({
            field: {
                id: "generalTerms__companyName",
                defaultMessage: "Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequired({
                field: {
                    id: "generalTerms__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequired({
                field: {
                    id: "generalTerms__state",
                    defaultMessage: "State",
                },
            }),
            postalCode: isRequired({
                field: {
                    id: "generalTerms__postalCode",
                    defaultMessage: "Postal Code",
                },
            }),
        },
    },
    fedexBillToAccount: isNumeric({
        field: {
            id: "locations__thirdPartyAccountNumber",
            defaultMessage: "Third Party Account #",
        },
    }),
    fedexFreightAccount: isNumeric({
        field: {
            id: "locations__shipperAccountNumber",
            defaultMessage: "Shipper Account #",
        },
    }),
    pickupAndDestroyLocation: {
        fedexBillToAccount: isRequiredIfPickUpAndDestroy({
            field: {
                id:
                    "locations.pickUpAndDetroyInformation__thirdPartyAccountNumber",
                defaultMessage: "Third Party Account #",
            },
        }),
        billingCompanyName: isRequiredIfPickUpAndDestroy({
            field: {
                id: "locations.pickUpAndDetroyInformation__companyName",
                defaultMessage: "Company Name",
            },
        }),
        billingAddress: {
            street1: isRequiredIfStreet2MissingPickAndDestoy({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1MissingPickAndDestoy({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__stateProvince",
                    defaultMessage: "State",
                },
            }),
            postalCode: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "locations.pickUpAndDetroyInformation__zipPostalCode",
                    defaultMessage: "Postal Code",
                },
            }),
            country: isRequiredIfPickUpAndDestroy({
                field: {
                    id: "pickupAndDestroyLocation.billingAddress.country",
                    defaultMessage: "Country",
                },
            }),
        },
    },
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "location",
            enableReinitialize: true,
            validate: locationItemValidation,
        })(LocationsPresentation)
    )
)
