import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../form/form-field"
import normalizePhone from "../util/normalizePhone"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
})

const CustomsBrokerageForm = ({
    classes,
    isRequired,
    isExport = false,
    country,
}) => (
    <Grid xs={12} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="headline">
                        {isExport ? (
                            <FormattedMessage
                                id="orderDetails.customs__export__title"
                                defaultMessage="Export Customs Brokerage Information"
                            />
                        ) : (
                            <FormattedMessage
                                id="orderDetails.customs__import__title"
                                defaultMessage="Import Customs Brokerage Information"
                            />
                        )}
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                <Grid container>
                    <Grid item container xs={6}>
                        <Field
                            name="name"
                            label={
                                <FormattedMessage
                                    id="generalTerms__companyName"
                                    defaultMessage="Company Name"
                                />
                            }
                            component={FormField}
                            required={isRequired}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="contact.email.email_address"
                            label={
                                <FormattedMessage
                                    id="generalTerms__emailAddress"
                                    defaultMessage="Email Address"
                                />
                            }
                            component={FormField}
                            required={isRequired}
                        />
                    </Grid>
                    <Grid item container xs={4}>
                        <Field
                            name="contact.phone.phone_number"
                            label={
                                <FormattedMessage
                                    id="generalTerms__phoneNumber"
                                    defaultMessage="Phone Number"
                                />
                            }
                            component={FormField}
                            required={isRequired}
                            normalize={normalizePhone(country)}
                        />
                    </Grid>
                    <Grid item container xs={2}>
                        <Field
                            name="contact.phone.extension"
                            label={
                                <FormattedMessage
                                    id="generalTerms__extension"
                                    defaultMessage="Extension"
                                />
                            }
                            component={FormField}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name="contact.name"
                            label={
                                <FormattedMessage
                                    id="generalTerms__contactName"
                                    defaultMessage="Contact Name"
                                />
                            }
                            component={FormField}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
)

CustomsBrokerageForm.propTypes = {
    classes: PropTypes.object,
}

CustomsBrokerageForm.defaultProps = {
    classes: {},
}

export default withStyles(styles)(CustomsBrokerageForm)
