import React, { Component } from "react"
import { Grid, Typography, Button, Snackbar } from "@material-ui/core"
import { connect } from "react-redux"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { Field } from "redux-form"
import { reduxForm, SubmissionError } from "redux-form"
import Divider from "@material-ui/core/Divider"
import Permissions from "./permissions"
import RoleCopy from "./roleCopy"
import FormSelect from "../../form/form-select"
import { updateUserOnLocation, removeUserLocation } from "../../../actions/user"
import { ErrorSnackbarContentWrapper } from "../../errorSnackbar"
import CircularProgress from "@material-ui/core/CircularProgress"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { FormattedMessage } from "react-intl"
import { defineMessages } from "react-intl"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "24px 24px 0 24px",
        top: "50%",
        left: "50%",
        width: "900px",
    },
    location__information: {
        padding: "10px 0",
    },
    addUser__permissions: {
        marginTop: "20px",
        padding: "5px",
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    button__container: {
        padding: "24px 0",
    },
    info__container: {
        paddingBottom: "8px",
    },
})

const roleOptionsMessages = defineMessages({
    chooseARole: {
        id: "locations.users__chooseARole",
        defaultMessage: "Choose a role",
    },
    owner: { id: "locations.users__owner", defaultMessage: "Owner" },
    companyUser: {
        id: "locations.users__companyUser",
        defaultMessage: "Company User",
    },
    thirdPartyVendor: {
        id: "locations.users__thirdPartyVendor",
        defaultMessage: "Third Party Vendor",
    },
})

const roleOptions = [
    {
        value: undefined,
        label: roleOptionsMessages.chooseARole,
        disabled: true,
    },
    {
        value: "owner",
        label: roleOptionsMessages.owner,
    },
    {
        value: "member",
        label: roleOptionsMessages.companyUser,
    },
    {
        value: "thirdParty",
        label: roleOptionsMessages.thirdPartyVendor,
    },
]

class ManageUser extends Component {
    state = {
        isRemoving: false,
    }

    denyAccess = async () => {
        const { user, information, denyAccess } = this.props
        try {
            await this.setState({ isRemoving: true })
            await denyAccess(information._id, get(user, "user.id"))
            this.props.handleClose()
        } catch (error) {
            this.props.handleSnackbarOpen(
                "error",
                ["Unable to remove user.", get(error, "response.message")].join(
                    " "
                )
            )
            await this.setState({
                isRemoving: false,
            })
        }
    }

    render() {
        const {
            classes,
            user,
            handleClose,
            suspendUser,
            unsuspendUser,
            confirmManageUser,
            currentRole,
            submitting,
            handleSubmit,
            index,
            setPrimaryOwner,
        } = this.props
        const { isRemoving } = this.state
        const isRequested = user.role === "requested"
        const me = index === 0

        return (
            <Grid className={classes.paper}>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <Typography>{this.state.sbMessage}</Typography>
                        }
                    />
                </Snackbar>
                <Grid item container justify="space-between">
                    <Grid item container xs={12} justify="space-between">
                        {isRequested ? (
                            <Typography variant="headline">
                                <FormattedMessage
                                    id="locations.manageUserModal__grantOrDeny"
                                    defaultMessage="Grant or Deny Access"
                                />
                            </Typography>
                        ) : (
                            <Typography variant="headline">
                                <FormattedMessage
                                    id="locations.manageUserModal__manageUser"
                                    defaultMessage="Manage User"
                                />
                            </Typography>
                        )}
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={9}
                        direction="column"
                        className={classes.info__container}
                    >
                        <Typography variant="subheading">{`${get(
                            user,
                            "user.firstname",
                            ""
                        )} ${get(user, "user.lastname", "")}`}</Typography>
                        <Typography variant="subheading">
                            {get(user, "user.email.email_address", "")}
                        </Typography>
                        {get(user, "isPrimary") &&
                            get(user, "role" === "owner") && (
                                <Typography
                                    color="primary"
                                    variant="subheading"
                                >
                                    <FormattedMessage
                                        id="locations.users__primaryOwner"
                                        defaultMessage="Primary Owner"
                                    />
                                </Typography>
                            )}
                        {isRequested && (
                            <Typography variant="subheading" color="secondary">
                                <FormattedMessage
                                    id="locations.users__requestedAccess"
                                    defaultMessage="Requested Access"
                                />
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid
                    item
                    container
                    className={classes.addUser__permissions}
                    justify="space-between"
                >
                    <Grid
                        item
                        container
                        xs={4}
                        id="manageUser__selectRole__container"
                        alignContent="flex-start"
                        spacing={16}
                    >
                        <Field
                            component={FormSelect}
                            name="role"
                            id={"manageUser__selectRole"}
                            label={[
                                "* ",
                                <FormattedMessage
                                    id="locations.addUserModal__role"
                                    defaultMessage="Role"
                                />,
                            ]}
                            options={roleOptions}
                            required
                        />
                        <RoleCopy
                            currentRole={currentRole}
                            isRequested={isRequested}
                        />
                        {user.role === "owner" && !user.isPrimary && (
                            <Grid item container justify="center">
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={handleSubmit(setPrimaryOwner)}
                                >
                                    <FormattedMessage
                                        id="locations.manageUserModal__makePrimaryOwner"
                                        defaultMessage="Make Primary Owner"
                                    />
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Permissions
                        me={me}
                        classes={classes}
                        gridSize={7}
                        defaultMode={isRequested}
                        editMode
                        checkEditable={false}
                        information={user}
                    />
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justify="space-between"
                    className={classes.button__container}
                >
                    <Grid item container xs={6}>
                        {isRequested && (
                            <Button
                                id="manageUser__deny"
                                color="primary"
                                onClick={handleSubmit(this.denyAccess)}
                            >
                                <FormattedMessage
                                    id="locations.manageUserModal__denyAccess"
                                    defaultMessage="Deny Access"
                                />
                            </Button>
                        )}
                        {!isRequested && index !== 0 && (
                            <Button
                                color="primary"
                                onClick={handleSubmit(this.denyAccess)}
                            >
                                <FormattedMessage
                                    id="locations.manageUserModal__removeUser"
                                    defaultMessage="Remove User"
                                />
                            </Button>
                        )}
                        {!get(user, "permissions.suspended.value") &&
                            !isRequested && (
                                <Button
                                    color="primary"
                                    onClick={handleSubmit(suspendUser)}
                                >
                                    <FormattedMessage
                                        id="locations.manageUserModal__deactivateUser"
                                        defaultMessage="Deactivate User"
                                    />
                                </Button>
                            )}
                        {get(user, "permissions.suspended.value") &&
                            !isRequested && (
                                <Button
                                    color="primary"
                                    onClick={handleSubmit(unsuspendUser)}
                                >
                                    <FormattedMessage
                                        id="locations.manageUserModal__reactivateUser"
                                        defaultMessage="Reactivate User"
                                    />
                                </Button>
                            )}
                    </Grid>
                    <Grid item container xs={6} justify="flex-end">
                        <Button
                            id="manageUser__confirm"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit(confirmManageUser)}
                        >
                            {isRequested ? (
                                <FormattedMessage
                                    id="locations.manageUserModal__grantAccess"
                                    defaultMessage="Grant Access"
                                />
                            ) : (
                                <FormattedMessage
                                    id="generalTerms__confirm"
                                    defaultMessage="Confirm"
                                />
                            )}
                        </Button>
                        {(submitting || isRemoving) && (
                            <CircularProgress
                                className={classes.submitLoader}
                                size={48}
                                color="secondary"
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state, props) => ({
    initialValues: {
        permissions:
            get(props, "user.role", "") === "requested"
                ? {}
                : get(props, "user.permissions", {}),
        role:
            get(props, "user.role", "") === "requested"
                ? "placeholder"
                : get(props, "user.role", ""),
    },
    currentRole: get(state, "form.manageUser.values.role", ""),
    permissions: get(state, ["form", "manageUser", "values", "permissions"]),
})

const mapDispatchToProps = (dispatch, props) => ({
    confirmManageUser: async values => {
        const { user, information } = props
        const { permissions, role } = values
        if (role === "placeholder") {
            throw new SubmissionError({
                role: (
                    <FormattedMessage
                        id="locations.manageUserModal__pleaseChooseARole"
                        defaultMessage="Please choose a role"
                    />
                ),
            })
        }
        try {
            await dispatch(
                updateUserOnLocation({
                    user,
                    permissions,
                    role,
                    locationId: information._id,
                })
            )
            props.handleSnackbarOpen(
                "success",
                <FormattedMessage
                    id="locations.manageUserModal__updateSuccessful"
                    defaultMessage="Update Successful"
                />
            )
            props.trackGA("Location", "Manage User - Confirm Button Success")
        } catch (error) {
            props.handleSnackbarOpen(
                "error",
                get(
                    error,
                    "response.message",
                    <FormattedMessage
                        id="locations.manageUserModal__errorManagingUser"
                        defaultMessage="Error Managing User"
                    />
                )
            )
            props.trackGA("Location", "Manage User - Confirm Button Error")
        }
        props.handleClose()
    },
    suspendUser: async () => {
        const { user, information } = props
        await dispatch(
            updateUserOnLocation({
                user,
                permissions: { suspended: { value: true } },
                locationId: information._id,
            })
        )
        props.handleClose()
        props.trackGA("Location", "Manage User - Deactivate")
    },
    unsuspendUser: async () => {
        const { user, information } = props
        await dispatch(
            updateUserOnLocation({
                user,
                permissions: { suspended: { value: false } },
                locationId: information._id,
            })
        )
        props.handleClose()
        props.trackGA("Location", "Manage User - Reactivate")
    },
    denyAccess: async (locationId, userId) => {
        const isRequested = get(props, "user.role") === "requested"

        try {
            await dispatch(removeUserLocation(locationId, userId))
            props.handleSnackbarOpen(
                "success",
                <FormattedMessage
                    id="locations.manageUserModal__userSuccessfullyRemoved"
                    defaultMessage="User Successfully Removed"
                />
            )
            if (isRequested) {
                props.trackGA("Location", "Manage User - Deny Access")
            } else {
                props.trackGA("Location", "Manage User - Remove User")
            }
        } catch (error) {
            props.handleSnackbarOpen(
                "error",
                get(
                    error,
                    "response.message",
                    <FormattedMessage
                        id="locations.manageUserModal__errorRemovingUser"
                        defaultMessage="Error Removing User"
                    />
                )
            )
            props.trackGA("Location", "Manage User - Remove/Deny User Error")
        }
    },
    setPrimaryOwner: async (locationId, userId) => {
        const { user, information } = props

        try {
            await dispatch(
                updateUserOnLocation({
                    user,
                    isPrimary: true,
                    locationId: information._id,
                })
            )
            props.handleSnackbarOpen(
                "success",
                <FormattedMessage
                    id="locations.manageUserModal__primaryOwnerSet"
                    defaultMessage="Primary Owner Set"
                />
            )
            props.trackGA("Location", "Manage User - Set Primary Owner Success")
            props.handleClose()
        } catch (error) {
            props.handleSnackbarOpen(
                "error",
                get(
                    error,
                    "response.message",
                    <FormattedMessage
                        id="locations.manageUserModal__primaryOwnerError"
                        defaultMessage="Error setting primary owner"
                    />
                )
            )
            props.trackGA("Location", "Manage User - Set Primary Owner Error")
            props.handleClose()
        }
    },
})

export const ManageUserModal = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "manageUser", // a unique identifier for this form
            enableReinitialize: true,
        })(ManageUser)
    )
)
