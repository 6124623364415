import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { Modal, withStyles, Typography, Grid } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { closeModal } from "../../actions/modal"
import {
    accountCreationSuccess,
    accountCreationPartialSuccess,
} from "../../messages/confirmation-constants"
import { addLocationError } from "../../messages/error-constants"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    button__container: {
        display: "flex",
        "align-items": "center",
        "margin-bottom": "20px",
    },
    button__close: {
        "margin-left": "auto",
    },
    modal__container: {
        top: "20%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
        width: theme.spacing.unit * 65,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
    accountCreationSuccess__title: {
        paddingBottom: "10px",
    },
})

const UserSignedupNotification = ({
    onCloseClick,
    classes,
    open,
    history,
    isShipperActive,
    is3rdPartyActive,
    locationType,
    isQuebecShipperAccount,
}) => (
    <Modal open={open} onClose={onCloseClick}>
        <div className={classes.modal__container}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item container xs={9} alignItems="center">
                    {isShipperActive && is3rdPartyActive ? (
                        <Grid item container justify="center">
                            <Grid
                                item
                                container
                                className={
                                    classes.accountCreationSuccess__title
                                }
                            >
                                <Typography variant="subheading">
                                    {accountCreationSuccess}
                                </Typography>
                            </Grid>
                            {locationType === "FEDEX_DIRECT" && (
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="locations__freightDirectMessage"
                                        defaultMessage="The account entered allows you to rate and ship with FedEx Freight Direct only. To be able to get rates with other carriers, please sign up using your FedEx Freight shipper or third party account."
                                    />
                                </Typography>
                            )}
                            {isQuebecShipperAccount && (
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Typography color="secondary">
                                        <FormattedMessage
                                            id="locations__quebecShipperAccountWarning"
                                            defaultMessage="The account entered allows you to rate and ship with FedEx Freight only at this point."
                                        />
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <Grid item container>
                            <Typography>
                                {accountCreationPartialSuccess(
                                    isShipperActive ? "bill to" : "freight"
                                )}
                            </Typography>
                            <Typography>{addLocationError}</Typography>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item
                    container
                    xs={3}
                    alignItems="center"
                    justify="flex-end"
                >
                    <IconButton
                        className={classes.button__close}
                        onClick={e => {
                            e.stopPropagation()
                            onCloseClick(history)
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    </Modal>
)

UserSignedupNotification.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    is3rdPartyActive: PropTypes.bool.isRequired,
    isShipperActive: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
}

UserSignedupNotification.defaultProps = {
    classes: {},
}

const mapStateToProps = state => {
    const signupLocation = get(state, "user.profile.locations", []).find(
        location => {
            const userAccess = get(location, "users[0].access", {})
            return (
                userAccess.granted &&
                !userAccess.invited &&
                !userAccess.requested
            )
        }
    )

    const shippingState = signupLocation?.shippingAddress?.address?.state
    const shippingCountry = signupLocation?.shippingAddress?.address?.country

    return {
        open: state.modal.isOpen,
        is3rdPartyActive:
            !get(signupLocation, "fedexBillToAccount") ||
            get(signupLocation, "is3rdPartyEnabled"),
        isShipperActive:
            !get(signupLocation, "fedexFreightAccount") ||
            get(signupLocation, "isShipperEnabled"),
        locationType: signupLocation?.locationType,
        isQuebecShipperAccount:
            signupLocation?.isShipperEnabled &&
            shippingState === "QC" &&
            shippingCountry === "CA",
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    onCloseClick: () => {
        dispatch(closeModal())
    },
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(UserSignedupNotification)
)
