import React, { Fragment } from "react"
import Item from "./Item"

export const BookItems = ({ fields, ...props }) => (
    <Fragment>
        {fields.map((item, index) => (
            <Item {...props} key={index} itemIndex={index} prefix={item} />
        ))}
    </Fragment>
)
