import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import { get, isEmpty, isEqual } from "lodash"
import Joyride, { EVENTS, ACTIONS } from "react-joyride"
import QuotesForm from "./quotesForm"
import { changePath } from "../../actions"
import { QuoteLoaderContainer } from "../quote/loader"
import { copyShipment } from "../../actions/quote-request"
import QuotesResult from "../quotesPage/quotesResult"
import { newQuickQuoteHandlingUnit } from "../../reducers/search"
import { quickQuotesFormSelector } from "../quotesPage/selectors"
import { finishTutorial } from "../../actions/user"
import tutorialStyles from "../tutorials/styles"
import quickQuoteTutorial from "../tutorials/quickQuote"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    loader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        "z-index": "9999",
    },
    hidden__drawer: {
        display: "none",
    },
    sticky_stepper: {
        position: "fixed",
        zIndex: 10,
        marginTop: "-8px",
        left: 0,
        right: 0,
        minWidth: "312px",
        width: "100%",
        padding: "0 7%",
    },
    quote__content: {
        marginTop: "0px",
        minWidth: "922px",
    },
    carousel__container: {
        width: "100%",
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    snackbar_message: {
        display: "inline",
    },
    main__container: {
        // overflow: 'scroll',
        minWidth: "924px",
        padding: "25px 25px",
        marginTop: "-8px",
    },
    quote__details: {
        height: "86px",
        padding: "4px 8px 4px 8px",
        backgroundColor: "#F2F2F2",
    },
    quote__entry__right__container: {
        paddingLeft: "25px",
    },
    quote__entry__right__element: {
        height: "460px",
    },
    quote__details__subcontainer: {
        margin: "8px 0px",
    },
    hide: {
        display: "none",
    },
})

class QuotesPage extends React.Component {
    constructor(props) {
        super(props)
        this.redirectIfNotQuickQuote(props)
        this.state = {
            openAddItemDialog: false,
            errorItems: [],
            run: true,
            stepIndex: 0,
        }
    }

    calculateErrorItems() {
        const { syncErrors } = this.props
        const errorItems = []

        syncErrors.handlingUnits.forEach((hu, index) => {
            if (!isEmpty(get(syncErrors, `handlingUnits[${index}].items[0]`))) {
                errorItems.push(index)
            }
        })

        return errorItems
    }

    componentDidUpdate(prevProps) {
        if (
            get(prevProps, "syncErrors.handlingUnits") &&
            get(this.props, "syncErrors.handlingUnits")
        ) {
            if (
                !isEqual(
                    prevProps.syncErrors.handlingUnits,
                    this.props.syncErrors.handlingUnits
                )
            ) {
                const errorItems = this.calculateErrorItems()
                this.setState({ errorItems })
            }
        }
    }

    redirectIfNotQuickQuote = props => {
        if (props.bolNumber && !props.isQuickQuote)
            this.props.changePath(`/rate/${props.bolNumber}`)
    }

    componentWillReceiveProps(newProps) {
        this.redirectIfNotQuickQuote(newProps)
        if (!this.props.isLoaded && newProps.isLoaded && !this.state.run) {
            this.setState({
                run: true,
                stepIndex: this.state.stepIndex + 1,
            })
        }
    }

    addNewItem = fields => {
        const errorItems = this.calculateErrorItems()
        this.setState({ errorItems })

        if (errorItems.length) {
            this.setState({ openAddItemDialog: true })
        } else {
            fields.push(newQuickQuoteHandlingUnit())
        }
    }

    handleAddItemDialogClose = () => {
        this.setState({ openAddItemDialog: false })
    }

    handleJoyride = async data => {
        const { index, type, action } = data
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            if (index === 1 && !this.props.isLoaded) {
                this.setState({ run: false })
            } else {
                this.setState({
                    stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
                })
            }
        } else if ([EVENTS.TOUR_END].includes(type)) {
            this.props.finishTutorial(data, quickQuoteTutorial.id)
        }
    }

    render() {
        const {
            classes,
            isFetching,
            bolNumber,
            isExpired,
            onClickRefreshRates,
            match,
            isLoaded,
            tutorialCompleted,
        } = this.props
        const { openAddItemDialog, run, stepIndex } = this.state

        return (
            <Grid container className={classes.main__container}>
                {!tutorialCompleted && (
                    <Joyride
                        callback={data => this.handleJoyride(data)}
                        steps={quickQuoteTutorial.steps}
                        showSkipButton
                        showProgress
                        continuous
                        stepIndex={stepIndex}
                        run={run}
                        styles={tutorialStyles}
                        locale={{
                            back: (
                                <FormattedMessage
                                    id="tutorial.back"
                                    defaultMessage="Back"
                                />
                            ),
                            close: (
                                <FormattedMessage
                                    id="tutorial.close"
                                    defaultMessage="Close"
                                />
                            ),
                            last: (
                                <FormattedMessage
                                    id="tutorial.last"
                                    defaultMessage="Last"
                                />
                            ),
                            next: (
                                <FormattedMessage
                                    id="tutorial.next"
                                    defaultMessage="Next"
                                />
                            ),
                            skip: (
                                <FormattedMessage
                                    id="tutorial.skip"
                                    defaultMessage="Skip"
                                />
                            ),
                        }}
                    />
                )}
                <QuoteLoaderContainer match={match} />
                <Grid
                    container
                    direction="row"
                    className={classes.quote__content}
                >
                    <Grid item container xs={12} wrap="nowrap">
                        <Grid item container xs={12}>
                            <QuotesForm
                                handleAddItemDialogClose={
                                    this.handleAddItemDialogClose
                                }
                                openAddItemDialog={openAddItemDialog}
                                addNewItem={this.addNewItem}
                                shipmentId={get(match, "params.shipmentId")}
                                getQuotes={this.getQuotes}
                                isLoaded={isLoaded}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    className={classes.quote__content}
                >
                    <Grid item container direction="column" xs={12}>
                        {(isLoaded || isFetching) && (
                            <QuotesResult
                                bolNumber={bolNumber}
                                shipmentId={get(match, "params.shipmentId")}
                                onClickRefreshRates={onClickRefreshRates}
                                onClickGetRates={() => {}}
                                isExpired={isExpired}
                                isLoaded={isLoaded}
                                isFetching={isFetching}
                                quickQuote
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

QuotesPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = (state, props) => ({
    ...state.form.quickQuote,
    ...state.quotes.active,
    bolNumber: get(props, "computedMatch.params.bolNumber"),
    isQuickQuote: get(
        quickQuotesFormSelector(state, {
            bolNumber: get(props, "computedMatch.params.bolNumber"),
        }),
        "isQuickQuote",
        true
    ),
    match: props.computedMatch,
    tutorialCompleted: get(
        state,
        `user.profile.preferences.tutorials.${quickQuoteTutorial.id}.completed`,
        false
    ),
})

const mapDispatchToProps = dispatch => ({
    finishTutorial: (data, id) => dispatch(finishTutorial(data, id)),
    onClickRefreshRates: bolNumber => dispatch(copyShipment(bolNumber)),
    changePath: path => dispatch(changePath(path)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(QuotesPage))
