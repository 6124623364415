import React, { Fragment, Component } from "react"
import { get, values, orderBy } from "lodash"
import ReactGA from "react-ga"
import createCachedSelector from "re-reselect"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { change } from "redux-form"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import DashboardFilters from "./dashboardFilters"
import TablePagination from "../../util/table-pagination"
import { selectShipment } from "../../../actions/book-shipment"
import {
    requestShipments,
    exportShipmentsAsCsv,
    requestShipmentsWithFilters,
} from "../../../actions/track"
import {
    applyActiveDashboardTile,
    applyDashboardFilters,
} from "../../../actions/dashboard"
import { changePageSize } from "../../../actions/pagination"
import { trackGAEvent } from "../../../actions/user"
import DashboardShipmentItem from "./../dashboardItem"
import DashboardQuoteItem from "./../dashboardQuoteItem"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Grid, Typography } from "@material-ui/core"
import {
    changePath,
    setTableFilterToShipments,
    toggleTableFilter,
} from "../../../actions"
import { dismissAlert } from "../../../actions/alerts"
import {
    requestQuotes,
    copyShipment,
    requestQuotesWithFilters,
} from "../../../actions/quote-request"
import { cancelShipment } from "../../../actions/book-shipment"
import GlobalSpinner from "../../util/spinner"
import { FormattedMessage } from "react-intl"
import DashboardTiles from "../tiles"

const styles = theme => ({
    dashboard__itemContainer: {
        padding: "0% 3%",
    },
    export_btn: {
        marginTop: "-36px",
    },
    progress: {
        margin: theme.spacing.unit * 2,
        top: "50%",
        right: "50%",
        position: "absolute",
        zIndex: 9999,
    },
    btnrow: {
        marginLeft: theme.spacing.unit * 5,
    },
    export__error: {
        marginLeft: "10px",
    },
    submitLoader: {
        marginLeft: "10px",
    },
    export__csv__button: {
        marginLeft: "80px",
        marginBottom: "15px",
    },
    dashboard__filterChip: {
        marginRight: "10px",
    },
    dashboard__filtersContainer: {
        paddingBottom: "16px",
        paddingTop: "16px",
    },
})

class ShipmentsPresentation extends Component {
    state = {
        openItemId: null,
        openItem: false,
        openCancelShipmentDialog: false,
    }

    toggleInfo = id => {
        if (this.state.openItem === true && this.state.openItemId !== id) {
            this.setState({ openItemId: id })
        } else {
            this.setState({
                openItemId: id,
                openItem: !this.state.openItem,
            })
        }
    }

    noExpand = event => {
        event.stopPropagation()
    }

    render() {
        const {
            shipments,
            isLoaded,
            isFetching,
            onShipAgain,
            onClickDetails,
            carriers,
            onViewQuote,
            onCancelShipment,
            dismissAlert,
            fetchElements,
            fetchElementsOnFilterApply,
            page,
            pageSize,
            setIsStarring,
            isStarring,
        } = this.props

        return (
            <Fragment>
                {(isFetching || isStarring) && <GlobalSpinner />}
                {isLoaded && (
                    <div id="dashboard-table">
                        {!!carriers &&
                            shipments.map((shipment, index) =>
                                shipment.shipment ? (
                                    <DashboardShipmentItem
                                        index={index}
                                        shipmentId={
                                            shipment.identifiers
                                                .internalTrackingNumber
                                        }
                                        fetchElements={fetchElements}
                                        fetchElementsOnFilterApply={
                                            fetchElementsOnFilterApply
                                        }
                                        onViewQuote={onViewQuote}
                                        onClickDetails={() =>
                                            onClickDetails(shipment)
                                        }
                                        key={
                                            shipment.identifiers
                                                .internalTrackingNumber
                                        }
                                        {...shipment}
                                        toggleInfo={this.toggleInfo}
                                        noExpand={this.noExpand}
                                        isOpen={
                                            shipment.shipment._id ===
                                                this.state.openItemId &&
                                            this.state.openItem
                                        }
                                        onShipAgain={onShipAgain}
                                        onCancelShipment={onCancelShipment}
                                        carrierCapabilities={
                                            carriers[shipment.shipment.carrier]
                                        }
                                        dismissAlert={dismissAlert}
                                        shipmentCPG={shipment.query.cpg}
                                        page={page}
                                        pageSize={pageSize}
                                        setIsStarring={setIsStarring}
                                    />
                                ) : (
                                    <DashboardQuoteItem
                                        index={index}
                                        onViewQuote={onViewQuote}
                                        onClickDetails={() =>
                                            onClickDetails(shipment)
                                        }
                                        key={shipment._id}
                                        toggleInfo={this.toggleInfo}
                                        {...shipment}
                                        isOpen={
                                            shipment._id ===
                                                this.state.openItemId &&
                                            this.state.openItem
                                        }
                                    />
                                )
                            )}
                    </div>
                )}
            </Fragment>
        )
    }
}

class ShipmentsWithPagination extends Component {
    state = {
        exportingCSV: false,
        showSpinner: false,
        loadingTable: false,
        filterDrawerOpen: false,
        page: 1,
        pageSize: this.props.pageSize,
        isStarring: false,
    }

    componentDidMount() {
        ReactGA.pageview("/dashboard")
    }

    setIsLoading = value => {
        this.setState({ loadingTable: value })
    }

    setIsStarring = value => {
        this.setState({ isStarring: value })
    }

    async componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps.dashboardFilters) !==
            JSON.stringify(this.props.dashboardFilters)
        ) {
            this.setState({ loadingTable: true })
            try {
                await this.props.fetchElementsOnFilterApply()
                this.setState({ loadingTable: false })
            } catch (_) {
                this.setState({ loadingTable: false })
            }
        }
    }

    onShipAgain = async (bolNumber, queryVersion) => {
        this.setState({ showSpinner: true })
        await this.props.onShipAgain(bolNumber, queryVersion)
        this.setState({ showSpinner: false })
    }

    onExportClick = async () => {
        await this.setState({ exportingCSV: true })
        try {
            await this.props.onExportClick()
            this.setState({ exportingCSV: false })
        } catch (err) {
            this.setState({
                exportError: get(
                    err,
                    "response.message",
                    "Error exporting CSV"
                ),
                exportingCSV: false,
            })
        }
    }

    fetchElements = async targetSize => {
        this.setState({ loadingTable: true })
        try {
            await this.props.fetchElements(targetSize, true)
            this.setState({ loadingTable: false })
        } catch (_) {
            this.setState({ loadingTable: false })
        }
    }

    applyDashboardFilters = () => {
        this.props.applyDashboardFilters()
        this.setState({ page: 1, pageSize: 10 })
    }

    applyChangeFilter = filter => {
        const {
            handleChangeFilter,
            setActiveTileInStore,
            activeDashboardTile,
        } = this.props

        handleChangeFilter(filter)
        this.setState({ page: 1 })
        if (filter === "quotes" && !!activeDashboardTile) {
            setActiveTileInStore(null)
            this.props.fetchElementsOnFilterApply()
        }
    }

    openFilterDrawer = () => {
        this.setState({ filterDrawerOpen: true })
    }

    closeFilterDrawer = () => {
        this.setState({ filterDrawerOpen: false })
    }

    adjustPagination = (page, pageSize) => {
        this.setState({ page, pageSize })
    }

    adjustPage = page => {
        this.setState({ page })
    }

    render() {
        const {
            shipments,
            onClickDetails,
            isLoaded,
            carriers,
            totalElements,
            isQuoteCopying,
            isFetching,
            savePageSize,
            classes,
            dashboardFilterFormValues,
            onViewQuote,
            tableFilters,
            onCancelShipment,
            dismissAlert,
            locations,
            dashboardFilters = {},
            resetFormField,
            setToShipmentsOnly,
            activeDashboardTile,
            setActiveTileInStore,
        } = this.props

        const {
            showSpinner,
            loadingTable,
            filterDrawerOpen,
            page,
            pageSize,
        } = this.state

        return showSpinner ? (
            <GlobalSpinner />
        ) : (
            <div className={classes.dashboard__itemContainer}>
                <DashboardTiles
                    fetchElementsOnFilterApply={
                        this.props.fetchElementsOnFilterApply
                    }
                    setToShipmentsOnly={setToShipmentsOnly}
                    setIsLoading={this.setIsLoading}
                    adjustPage={this.adjustPage}
                    setActiveTileInStore={setActiveTileInStore}
                />
                <DashboardFilters
                    tableFilters={tableFilters}
                    locations={locations}
                    handleChangeFilter={this.applyChangeFilter}
                    classes={classes}
                    applyDashboardFilters={this.applyDashboardFilters}
                    dashboardFilters={dashboardFilters}
                    filterDrawerOpen={filterDrawerOpen}
                    openFilterDrawer={this.openFilterDrawer}
                    closeFilterDrawer={this.closeFilterDrawer}
                    resetFormField={resetFormField}
                    carriers={carriers}
                    formValues={dashboardFilterFormValues}
                />
                <TablePagination
                    elements={shipments}
                    fetchElements={this.fetchElements}
                    savePageSize={savePageSize}
                    pageSize={pageSize}
                    page={page}
                    loadingTable={loadingTable}
                    totalElements={totalElements}
                    adjustPagination={this.adjustPagination}
                    render={elementsToDisplay => (
                        <ShipmentsPresentation
                            carriers={carriers}
                            shipments={elementsToDisplay}
                            onClickDetails={onClickDetails}
                            onViewQuote={onViewQuote}
                            onShipAgain={this.onShipAgain}
                            isLoaded={isLoaded}
                            isFetching={isFetching}
                            setIsStarring={this.setIsStarring}
                            isQuoteCopying={isQuoteCopying}
                            classes={classes}
                            onCancelShipment={onCancelShipment}
                            dismissAlert={dismissAlert}
                            fetchElements={this.props.fetchElements}
                            fetchElementsOnFilterApply={
                                this.props.fetchElementsOnFilterApply
                            }
                            page={page}
                            pageSize={pageSize}
                        />
                    )}
                    noElement={() => (
                        <Grid item container justify="center">
                            {loadingTable && <GlobalSpinner />}
                            {activeDashboardTile ||
                            Object.keys(dashboardFilters?.carrierFilter ?? {})
                                .length ||
                            Object.keys(dashboardFilters?.locationFilter ?? {})
                                .length ? (
                                <Grid item container justify="center">
                                    <Typography variant="subheading">
                                        <FormattedMessage
                                            id="dashboard__noElementsFromFilter"
                                            defaultMessage="There are no shipments matching your current filter."
                                        />
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid item container>
                                    <Grid item container justify="center">
                                        <Typography variant="title">
                                            <FormattedMessage
                                                id="dashboard__noElements"
                                                defaultMessage="Welcome to LTL Select! You have no quotes/shipments to display"
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item container justify="center">
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="dashboard__noElementsDetail"
                                                defaultMessage="Quotes and shipments that you create will show up on this dashboard"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    )}
                />
                {isLoaded && !loadingTable && shipments.length > 0 && (
                    <Grid
                        item
                        container
                        alignItems="center"
                        className={classes.export_btn}
                    >
                        <Grid
                            item
                            container
                            className={classes.export__csv__button}
                        >
                            <Tooltip
                                title={
                                    <FormattedMessage
                                        id="dashboard__lastNDays"
                                        defaultMessage="Last {n} days"
                                        values={{ n: 90 }}
                                    />
                                }
                            >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={this.onExportClick}
                                >
                                    <FormattedMessage
                                        id="dashboard__exportAsCsv"
                                        defaultMessage="Export as CSV"
                                    />
                                </Button>
                            </Tooltip>
                            {this.state.exportingCSV && (
                                <CircularProgress
                                    className={classes.submitLoader}
                                    size={24}
                                    color="secondary"
                                />
                            )}
                        </Grid>
                        {this.state.exportError && (
                            <Grid item>
                                <Typography
                                    color="secondary"
                                    variant="caption"
                                    className={classes.export__error}
                                >
                                    {this.state.exportError}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
        )
    }
}

ShipmentsPresentation.propTypes = {
    shipments: PropTypes.array.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    isQuoteCopying: PropTypes.bool.isRequired,
    onClickDetails: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    shipmentList: PropTypes.array.isRequired,
    totalElements: PropTypes.number.isRequired,
}

ShipmentsWithPagination.propTypes = {
    ...ShipmentsPresentation.PropTypes,
    fetchElements: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    savePageSize: PropTypes.func.isRequired,
    onExportClick: PropTypes.func.isRequired,
}

const keySelector = state =>
    [
        get(state, "dashboard.filters.quotes") && "quotes",
        get(state, "dashboard.filters.shipments") && "shipments",
    ].join(",")

const tableSelector = createCachedSelector(
    state => state.dashboard.filters,
    state => state.quotes.list.items,
    state => state.shipment.list,
    (filters, quotesList, shipmentsList) => {
        const { quotes, shipments } = filters || {}
        const list = [
            ...(quotes ? values(quotesList || {}) : []),
            ...(shipments ? values(shipmentsList || {}) : []),
        ]
        return orderBy(list, ["created_at"], ["desc"])
    }
)(keySelector)

const mapStateToProps = state => {
    const { quotes, shipments } = get(state, "dashboard.filters", {})
    const totalElements =
        (shipments ? state.shipment.totalCount : 0) +
        (quotes ? state.quotes.list.totalCount : 0)
    const locations = get(state, "user.profile.locations", []).filter(
        item =>
            !get(item, "users[0].permissions.suspended.value") &&
            get(item, "users[0].access.granted")
    )

    return {
        isLoaded:
            (!shipments || state.shipment.isLoaded) &&
            (!quotes || state.quotes.list.isLoaded),
        isFetching:
            (shipments && state.shipment.isFetching) ||
            (quotes && state.quotes.list.isFetching),
        isQuoteCopying: state.quotes.active.isFetching,
        pageSize: state.shipment.pagination.pageSize,
        carriers: state.carriers.carriers,
        tableFilters: state.dashboard.filters,
        totalElements,
        shipments: tableSelector(state),
        locations,
        dashboardFilters: get(state, "dashboard.dashboardFilters", {}),
        dashboardFilterFormValues: get(
            state,
            "form.dashboardFilters.values",
            {}
        ),
        activeDashboardTile: state?.dashboard?.activeDashboardTile,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    setActiveTileInStore: value => dispatch(applyActiveDashboardTile(value)),
    onClickDetails: record => {
        dispatch(trackGAEvent("Shipment", "Tracking details"))
        return dispatch(selectShipment(record.identifiers))
    },
    fetchElements: async (targetSize, force) => {
        return Promise.all([
            dispatch(requestShipments(targetSize, force)),
            dispatch(requestQuotes(targetSize, force)),
        ])
    },
    fetchElementsOnFilterApply: async targetSize => {
        return await Promise.all([
            dispatch(requestQuotesWithFilters(targetSize)),
            dispatch(requestShipmentsWithFilters(targetSize)),
        ])
    },
    savePageSize: pageSize => dispatch(changePageSize(pageSize)),
    onExportClick: () => dispatch(exportShipmentsAsCsv()),
    onViewQuote: (bolNumber, isQuickQuote, isQuickRate, queryVersion) => {
        if (queryVersion === "V1" || !queryVersion) {
            dispatch(
                changePath(
                    isQuickQuote ? `/qrate/${bolNumber}` : `/rate/${bolNumber}`
                )
            )
        } else if (queryVersion === "V2") {
            dispatch(
                changePath(
                    isQuickRate
                        ? `/quickRate/${bolNumber}`
                        : `/book/${bolNumber}`
                )
            )
        }
    },
    applyDashboardFilters: () => dispatch(applyDashboardFilters()),
    onShipAgain: async (bolNumber, queryVersion) => {
        await dispatch(copyShipment(bolNumber, queryVersion))
    },
    onCancelShipment: (...args) => dispatch(cancelShipment(...args)),
    handleChangeFilter: filter => dispatch(toggleTableFilter(filter)),
    setToShipmentsOnly: () => dispatch(setTableFilterToShipments()),
    dismissAlert: (bolNumber, alertId) =>
        dispatch(dismissAlert(bolNumber, alertId)),
    resetFormField: (field, value) =>
        dispatch(change("dashboardFilters", field, value)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(ShipmentsWithPagination)
)
