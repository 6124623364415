import React, { Component } from "react"
import PropTypes from "prop-types"

export class TooltipOnOverflow extends Component {
    constructor(props) {
        super(props)
        this.elementRef = null

        this.onMouseEnter = () => {
            if (!this.elementRef) return
            if (this.elementRef.offsetWidth < this.elementRef.scrollWidth) {
                this.elementRef.setAttribute("title", this.props.value)
            } else {
                this.elementRef.removeAttribute("title")
            }
        }

        this.setElementRef = element => {
            this.elementRef = element
            if (this.elementRef) {
                this.elementRef.addEventListener(
                    "mouseenter",
                    this.onMouseEnter
                )
            }
        }
    }

    render() {
        return (
            <div className="truncated" ref={this.setElementRef}>
                {this.props.children}
            </div>
        )
    }
}

TooltipOnOverflow.propTypes = {
    value: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}
