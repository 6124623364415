import React, { useEffect, useState } from "react"
import { Element, scroller } from "react-scroll"
import { uniqWith, isEqual } from "lodash"
import Grid from "@material-ui/core/Grid"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import RatesError from "../atoms/RatesError"
import moment from "moment"
import ShareIcon from "@material-ui/icons/Share"
import { Button } from "@material-ui/core"
import { CarrierLogo } from "../../../util"
import RateRow from "../atoms/RateRow"
import { isGuaranteedQuote } from "../../../quote/card"
import RatesSummary from "../summaryCards/RatesSummary"
import { RateShareModal } from "../atoms/share/RateShareModal"
import FXFDRates from "../atoms/freightDirect/FXFDRates"
import FXFDReturnsRates from "../atoms/freightDirectReturns/FXFDReturnsRates"
import FormSwitch from "../../../form/form-switch"
import { Field } from "redux-form"

const extractTerminalAddress = terminal => {
    return {
        address: { ...terminal.address },
        name: terminal?.location,
    }
}

const extractTerminalContact = terminal => {
    const { contact = {} } = terminal

    return {
        name: contact.name,
        phone: {
            phone_number: contact.phone,
            extension: contact.extension,
        },
        email: {
            email_address: contact.email,
        },
    }
}

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    block: {
        padding: "5px",
    },
    dateGroup: {
        padding: "10px 0",
        borderTop: "solid 2px #969696",
    },
    carrierGroup: {
        padding: "10px 0",
        borderTop: "dotted 1px #969696",
    },
    carrierGroup__first: {
        padding: "10px 0",
    },
    rateRow: {
        padding: "10px 0",
    },
    expansionPanel: {
        width: "100%",
        boxShadow: "none",
        backgroundColor: "rgb(242, 242, 242)",
    },
    expansionPanelSummary: {
        cursor: "default",
    },
    shareButton: {
        marginLeft: "8px",
    },
    rerun: {
        paddingBottom: "16px",
    },
    ratesError: {
        paddingBottom: "16px",
    },
    shareContainer: {
        paddingBottom: "16px",
    },
})

const ltlFilter = rate => rate.mode !== "LTL"
const thirdPartyFilter = rate => rate.direction !== "THIRD_PARTY"
const volumeFilter = rate => rate.mode !== "VOLUME_LTL"

export default function Rates({
    editMode,
    currentStep,
    guaranteedList,
    permissions = {},
    formValues = {},
    shipmentId,
    activeQuote = {
        result: { rateQuotes: [], ratesError: [] },
        shippedRateQuote: {},
    },
    handleComplete,
    handleEdit,
    changeField,
    isQuickRate,
    trackGA,
    onClickReRunRates,
    isLoading,
    setEditMode,
}) {
    const classes = useStyles()
    const [rateBuckets, setRateBuckets] = useState({})
    const [hasLTLRate, setHasLTLRate] = useState(false)
    const [hasThirdPartyRate, setHasThirdPartyRate] = useState(false)
    const [hasVolumeRate, setHasVolumeRate] = useState(false)
    const [isShareModalOpen, setIsShareModalOpen] = useState(false)
    const [isSharing, setIsSharing] = useState(false)
    const [selections, setSelections] = useState(new Set())

    const { viewRateAmounts, createRates } = permissions

    const canViewRateAmounts = viewRateAmounts?.value

    const {
        isFreightDirect,
        isFreightDirectReturns,
        selectedLocation = {},
        destination = {},
        hideLTLRates,
        hideThirdPartyRates,
        hideVolumeRates,
        origin = {},
    } = formValues

    const { shipped, expired, shippedRateQuote } = activeQuote
    const disabledVolumenRatesCountries = ["MX"]
    useEffect(() => {
        if (currentStep === 4 || isQuickRate) {
            scroller.scrollTo("ratesTitle", {
                smooth: true,
                duration: 500,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, isQuickRate])

    useEffect(() => {
        if (isFreightDirect && !isFreightDirectReturns) {
            processFXFDRates(activeQuote)
        } else if (isFreightDirectReturns) {
            processFXFDReturnsRates(activeQuote)
        } else {
            processRates(activeQuote?.result?.rateQuotes)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeQuote, hideLTLRates, hideThirdPartyRates, hideVolumeRates])

    useEffect(() => {
        const ratesAndErrors = [
            ...activeQuote?.result?.rateQuotes,
            ...activeQuote?.result?.ratesError,
        ]
        setHasLTLRate(ratesAndErrors?.some(x => !ltlFilter(x)))
        setHasThirdPartyRate(ratesAndErrors?.some(x => !thirdPartyFilter(x)))

        if (
            disabledVolumenRatesCountries.includes(
                origin?.shippingAddress?.address?.country
            ) ||
            disabledVolumenRatesCountries.includes(
                destination?.shippingAddress?.address?.country
            )
        ) {
            setHasVolumeRate(false)
        } else {
            setHasVolumeRate(ratesAndErrors?.some(x => !volumeFilter(x)))
        }
    }, [activeQuote, origin, destination, disabledVolumenRatesCountries])

    const processRates = rateQuotes => {
        const tempRateBuckets = rateQuotes
            .filter(
                x =>
                    (!hideLTLRates || ltlFilter(x)) &&
                    (!hideThirdPartyRates || thirdPartyFilter(x)) &&
                    (!hideVolumeRates || volumeFilter(x))
            )
            .map(rate => ({
                ...rate,
                deliveryDateTime: moment(rate.deliveryDateTime),
                guaranteed: isGuaranteedQuote(
                    rate.serviceLevel && rate.serviceLevel.code,
                    guaranteedList
                ),
            }))
            .sort(defaultSortMethod)
            .reduce((prev, rate) => {
                const date = moment(rate.deliveryDateTime).format("YYYYMMDD")
                const bucketCategory = `${rate.carrierCode}.${rate.mode}`

                const adjustedRate = {
                    ...rate,
                    guaranteed: isGuaranteedQuote(
                        rate.serviceLevel && rate.serviceLevel.code,
                        guaranteedList
                    ),
                }

                prev[date] = prev[date] ?? {}
                prev[date][bucketCategory] = [
                    ...(prev[date][bucketCategory] ?? []),
                    adjustedRate,
                ]
                return prev
            }, {})

        setRateBuckets(tempRateBuckets)
    }

    const processFXFDRates = rateResult => {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic__title"
                        defaultMessage="Basic"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basic"
                        defaultMessage="Front door, back door, or to the garage with no signature"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus__title"
                        defaultMessage="Basic by Appointment"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__basicPlus"
                        defaultMessage="Front door, back door, or to the garage with signature"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard__title"
                        defaultMessage="Standard"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__standard"
                        defaultMessage="To the first ground-level room"
                    />
                ),
                rates: [],
            },
            premium: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium__title"
                        defaultMessage="Premium"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirect__premium"
                        defaultMessage="Room of choice and packaging removal by request"
                    />
                ),
                rates: [],
            },
        }

        rateResult.result.rateQuotes.forEach(individualRate => {
            switch (individualRate.serviceLevel?.code) {
                case "BASIC":
                    formattedRates.basic.rates.push(individualRate)
                    break
                case "BASIC_APPT":
                    formattedRates.basicPlus.rates.push(individualRate)
                    break
                case "STANDARD":
                    formattedRates.standard.rates.push(individualRate)
                    break
                case "PREMIUM":
                    formattedRates.premium.rates.push(individualRate)
                    break
                default:
                    break
            }
        })
        setRateBuckets(formattedRates)
    }

    const processFXFDReturnsRates = rateResult => {
        let formattedRates = {
            basic: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPickup__title"
                        defaultMessage="Basic Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basic"
                        defaultMessage="Front door, back door, or from the garage with no signature"
                    />
                ),
                rates: [],
            },
            basicPlus: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup__title"
                        defaultMessage="Basic by Appointment Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__basicPlusPickup"
                        defaultMessage="Front door, back door, or from the garage with signature"
                    />
                ),
                rates: [],
            },
            standard: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup__title"
                        defaultMessage="Standard Pickup"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__standardPickup"
                        defaultMessage="From the first ground-level room"
                    />
                ),
                rates: [],
            },
            premium: {
                title: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__premiumPickup__title"
                        defaultMessage="Premium"
                    />
                ),
                label: (
                    <FormattedMessage
                        id="rateResults.freightDirectReturns__premiumPickup"
                        defaultMessage="Room of choice and packaging by request"
                    />
                ),
                rates: [],
            },
        }

        rateResult.result.rateQuotes.forEach(individualRate => {
            switch (individualRate.serviceLevel?.code) {
                case "BASIC_PICKUP":
                    formattedRates.basic.rates.push(individualRate)
                    break
                case "BASIC_APPT_PICKUP":
                    formattedRates.basicPlus.rates.push(individualRate)
                    break
                case "STANDARD_PICKUP":
                    formattedRates.standard.rates.push(individualRate)
                    break
                case "PREMIUM_PICKUP":
                    formattedRates.premium.rates.push(individualRate)
                    break
                default:
                    break
            }
        })

        setRateBuckets(formattedRates)
    }

    const setIsSelected = (id, value) => {
        const newSet = new Set(selections)
        if (value) {
            newSet.add(id)
            setSelections(newSet)
        } else {
            if (newSet.has(id)) {
                newSet.delete(id)
            }
            setSelections(newSet)
        }
    }

    const defaultSortMethod = (a, b) => {
        if (
            (b.guaranteed && a.guaranteed) ||
            (!b.guaranteed && !a.guaranteed)
        ) {
            return moment(a.deliveryDateTime).isAfter(
                moment(b.deliveryDateTime)
            )
                ? 1
                : -1
        }
        return b.guaranteed ? 1 : -1
    }

    const selectRate = rate => {
        const canDoThirdParty =
            selectedLocation?.fedexBillToAccount ||
            selectedLocation?.pickupAndDestroyLocation?.fedexBillToAccount

        const isPickAndDestroyRate =
            rate?.serviceLevel?.code === "BASIC_PICKUP_AND_DESTROY"

        const changeToTerminal = isPickAndDestroyRate && canDoThirdParty

        if (changeToTerminal) {
            changeField("destination", {
                ...destination,
                shippingAddress: extractTerminalAddress(rate?.terminal),
                contact: extractTerminalContact(rate?.terminal),
            })
        }

        changeField("selectedRate", rate)
        changeField("identifiers", activeQuote?.identifiers ?? {})
        setEditMode(false)
        handleComplete()
    }

    const ratesError = uniqWith(
        activeQuote?.result?.ratesError?.filter(
            x =>
                (!hideLTLRates || ltlFilter(x)) &&
                (!hideThirdPartyRates || thirdPartyFilter(x)) &&
                (!hideVolumeRates || volumeFilter(x))
        ),
        (a, b) =>
            a.carrierCode === b.carrierCode &&
            isEqual(a.errorMessages, b.errorMessages)
    )

    const hasMultipleRateCategories =
        [hasLTLRate, hasThirdPartyRate, hasVolumeRate].filter(x => x).length > 1

    const hasMultipleAvailableRateFilters =
        [
            hasLTLRate && !hideLTLRates,
            hasThirdPartyRate && !hideThirdPartyRates,
            hasVolumeRate && !hideVolumeRates,
        ].filter(x => x).length > 1

    if (currentStep === 4 || isQuickRate) {
        return (
            <Grid item container>
                <RateShareModal
                    open={isShareModalOpen}
                    handleClose={() => {
                        setIsShareModalOpen(false)
                    }}
                    selectedRates={selections}
                    activeQuote={activeQuote}
                    shipmentId={shipmentId}
                />
                <Grid item container justify="center">
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.rates__title"
                            defaultMessage="Rates"
                        />
                    </Typography>
                    <Element name="ratesTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    {!shipped && expired && (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="center"
                            direction="column"
                            className={classes.rerun}
                        >
                            <Grid item container justify="center">
                                <Typography>
                                    <FormattedMessage
                                        id="bookShipment__outdated"
                                        defaultMessage="This rate is outdated."
                                    />
                                </Typography>
                            </Grid>
                            {createRates?.value && (
                                <Grid item container justify="center">
                                    <Button
                                        size="small"
                                        color="secondary"
                                        disabled={isLoading}
                                        variant="outlined"
                                        onClick={() => {
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate Flow"
                                                    : "Book Shipment Flow",
                                                "Re-Run Expired Rate Click"
                                            )
                                            onClickReRunRates()
                                        }}
                                    >
                                        <FormattedMessage
                                            id="bookShipment__rerunRate"
                                            defaultMessage="Click here to re-run the rate"
                                        />
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    )}
                    <Grid item container alignContent="flex-start">
                        <Grid
                            item
                            container
                            xs={9}
                            className={classes.ratesError}
                        >
                            {ratesError.length > 0 && (
                                <RatesError ratesError={ratesError} />
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={3}
                            justify="flex-end"
                            className={classes.shareContainer}
                        >
                            {isSharing && (
                                <Grid item>
                                    <Button
                                        id="cancelShareRates"
                                        variant="contained"
                                        onClick={() => {
                                            setIsSharing(false)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate Flow"
                                                    : "Book Shipment Flow",
                                                "Share",
                                                "Share mode cancel"
                                            )
                                        }}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                </Grid>
                            )}
                            <Grid item className={classes.shareButton}>
                                <Button
                                    id="shareRates"
                                    variant="contained"
                                    disabled={
                                        (isSharing && selections.size === 0) ||
                                        isLoading
                                    }
                                    color={
                                        !isSharing
                                            ? "default"
                                            : selections.size > 0
                                            ? "secondary"
                                            : "primary"
                                    }
                                    onClick={() => {
                                        if (isSharing) {
                                            setIsShareModalOpen(true)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate Flow"
                                                    : "Book Shipment Flow",
                                                "Share",
                                                "Modal Open"
                                            )
                                        } else {
                                            setIsSharing(true)
                                            trackGA(
                                                isQuickRate
                                                    ? "Quick Rate Flow"
                                                    : "Book Shipment Flow",
                                                "Share",
                                                "Share mode enabled"
                                            )
                                        }
                                    }}
                                >
                                    <ShareIcon className={classes.leftIcon} />
                                    <FormattedMessage
                                        id="rateResults.share__share"
                                        defaultMessage="Share"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                        {hasMultipleRateCategories && (
                            <Grid item container>
                                {hasThirdPartyRate && (
                                    <Grid item container xs={4}>
                                        <Field
                                            component={FormSwitch}
                                            name="hideThirdPartyRates"
                                            disabled={
                                                !hideThirdPartyRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideThirdPartyRates"
                                                    defaultMessage="Hide Third Party Rates"
                                                />
                                            }
                                            onClick={(e, value) => {
                                                trackGA(
                                                    isQuickRate
                                                        ? "Quick Rate Flow"
                                                        : "Book Shipment Flow",
                                                    "Rate Filters",
                                                    "Hide Third Party Rates",
                                                    value
                                                )
                                            }}
                                        />
                                    </Grid>
                                )}
                                {hasVolumeRate && (
                                    <Grid item container xs={4}>
                                        <Field
                                            component={FormSwitch}
                                            name="hideVolumeRates"
                                            disabled={
                                                !hideVolumeRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideVolumeRates"
                                                    defaultMessage="Hide Volume LTL Rates"
                                                />
                                            }
                                            onClick={(e, value) => {
                                                trackGA(
                                                    isQuickRate
                                                        ? "Quick Rate Flow"
                                                        : "Book Shipment Flow",
                                                    "Rate Filters",
                                                    "Hide Volume Rates",
                                                    value
                                                )
                                            }}
                                        />
                                    </Grid>
                                )}
                                {hasLTLRate && (
                                    <Grid item container xs={4}>
                                        <Field
                                            component={FormSwitch}
                                            name="hideLTLRates"
                                            disabled={
                                                !hideLTLRates &&
                                                !hasMultipleAvailableRateFilters
                                            }
                                            label={
                                                <FormattedMessage
                                                    id="bookShipment.hideLTLRates"
                                                    defaultMessage="Hide LTL Rates"
                                                />
                                            }
                                            onClick={(e, value) => {
                                                trackGA(
                                                    isQuickRate
                                                        ? "Quick Rate Flow"
                                                        : "Book Shipment Flow",
                                                    "Rate Filters",
                                                    "Hide Shipper Rates",
                                                    value
                                                )
                                            }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        <Grid item container>
                            {isFreightDirect &&
                                !isFreightDirectReturns &&
                                Object.keys(rateBuckets).length > 0 &&
                                activeQuote?.result?.rateQuotes?.length > 0 && (
                                    <Grid item container>
                                        <FXFDRates
                                            rateBuckets={rateBuckets}
                                            classes={classes}
                                            isSharing={isSharing}
                                            selections={selections}
                                            setIsSelected={setIsSelected}
                                            selectRate={selectRate}
                                            canViewRateAmounts={true}
                                            expired={expired}
                                            shippedRateQuote={shippedRateQuote}
                                            shipped={shipped}
                                        />
                                    </Grid>
                                )}
                            {isFreightDirectReturns &&
                                Object.keys(rateBuckets).length > 0 &&
                                activeQuote?.result?.rateQuotes?.length > 0 && (
                                    <Grid item container>
                                        <FXFDReturnsRates
                                            rateBuckets={rateBuckets}
                                            classes={classes}
                                            isSharing={isSharing}
                                            selections={selections}
                                            setIsSelected={setIsSelected}
                                            selectRate={selectRate}
                                            canViewRateAmounts={
                                                canViewRateAmounts
                                            }
                                            expired={expired}
                                            shippedRateQuote={shippedRateQuote}
                                            shipped={shipped}
                                        />
                                    </Grid>
                                )}
                            {!isFreightDirect &&
                                !isFreightDirectReturns &&
                                activeQuote?.result?.rateQuotes?.length > 0 &&
                                Object.keys(rateBuckets)
                                    .sort((a, b) => {
                                        return moment(a).isAfter(moment(b))
                                            ? 1
                                            : -1
                                    })
                                    .map((dateGroup, dateIndex) => {
                                        const firstBucketCategoryKey = Object.keys(
                                            rateBuckets[dateGroup]
                                        )[0]

                                        const firstRateEntryAtFirstCategory =
                                            rateBuckets[dateGroup][
                                                firstBucketCategoryKey
                                            ][0]

                                        const deliveryDate =
                                            firstRateEntryAtFirstCategory.transitDays &&
                                            moment(
                                                firstRateEntryAtFirstCategory.deliveryDateTime
                                            )

                                        return (
                                            <Grid
                                                item
                                                container
                                                alignContent="flex-end"
                                                className={classes.dateGroup}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    xs={3}
                                                    direction="column"
                                                >
                                                    {deliveryDate ? (
                                                        <Typography
                                                            id={`datetime-rate-${dateIndex}`}
                                                            variant="subheading"
                                                            className={
                                                                classes.date
                                                            }
                                                        >
                                                            {deliveryDate.format(
                                                                "ddd, MMM Do"
                                                            )}
                                                        </Typography>
                                                    ) : (
                                                        <Typography className="left-padding call-carrier">
                                                            {
                                                                "Call carrier for delivery date"
                                                            }
                                                        </Typography>
                                                    )}
                                                    {dateIndex === 0 && (
                                                        <Typography
                                                            id="rateResults__fastest"
                                                            color="secondary"
                                                            variant="subheading"
                                                        >
                                                            <FormattedMessage
                                                                id="rateResults__fastest"
                                                                defaultMessage="Fastest"
                                                            />
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item container xs={9}>
                                                    {Object.keys(
                                                        rateBuckets[dateGroup]
                                                    ).map(
                                                        (
                                                            categoryKey,
                                                            carrierIndex
                                                        ) => (
                                                            <Grid
                                                                item
                                                                container
                                                                className={
                                                                    carrierIndex ===
                                                                    0
                                                                        ? classes.carrierGroup__first
                                                                        : classes.carrierGroup
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    container
                                                                    xs={4}
                                                                    alignContent="flex-start"
                                                                >
                                                                    <CarrierLogo
                                                                        carrierCode={
                                                                            rateBuckets[
                                                                                dateGroup
                                                                            ][
                                                                                categoryKey
                                                                            ][0]
                                                                                ?.carrierCode
                                                                        }
                                                                        mode={
                                                                            rateBuckets[
                                                                                dateGroup
                                                                            ][
                                                                                categoryKey
                                                                            ][0]
                                                                                ?.mode
                                                                        }
                                                                        serviceLevel={
                                                                            rateBuckets[
                                                                                dateGroup
                                                                            ][
                                                                                categoryKey
                                                                            ][0]
                                                                                ?.serviceLevel
                                                                                ?.description
                                                                        }
                                                                        largeText
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    xs={8}
                                                                >
                                                                    {rateBuckets[
                                                                        dateGroup
                                                                    ][
                                                                        categoryKey
                                                                    ].map(
                                                                        (
                                                                            rate,
                                                                            index
                                                                        ) => {
                                                                            const isShippedRate =
                                                                                shippedRateQuote?._id ===
                                                                                rate._id
                                                                            return (
                                                                                <RateRow
                                                                                    rate={
                                                                                        rate
                                                                                    }
                                                                                    expired={
                                                                                        expired
                                                                                    }
                                                                                    guaranteedList={
                                                                                        guaranteedList
                                                                                    }
                                                                                    canViewRateAmounts={
                                                                                        canViewRateAmounts
                                                                                    }
                                                                                    isSharing={
                                                                                        isSharing
                                                                                    }
                                                                                    setIsSelected={
                                                                                        setIsSelected
                                                                                    }
                                                                                    selections={
                                                                                        selections
                                                                                    }
                                                                                    selectRate={
                                                                                        selectRate
                                                                                    }
                                                                                    index={`${dateIndex}-${index}`}
                                                                                    isShippedRate={
                                                                                        isShippedRate
                                                                                    }
                                                                                    shipped={
                                                                                        shipped
                                                                                    }
                                                                                />
                                                                            )
                                                                        }
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else if (currentStep > 4) {
        return (
            <RatesSummary
                handleEdit={handleEdit}
                formValues={formValues}
                canViewRateAmounts={canViewRateAmounts}
            />
        )
    }
}
