import React, { useState, useEffect } from "react"
import { Element, scroller } from "react-scroll"
import { getFormValues, reduxForm, reset, touch } from "redux-form"
import { makeStyles } from "@material-ui/styles"
import Grid from "@material-ui/core/Grid"
import BookStepper from "./BookStepper"
import BasicInfo from "./steps/BasicInfo"
import Origin from "./steps/Origin"
import Destination from "./steps/Destination"
import { connect } from "react-redux"
import { FormattedMessage, injectIntl } from "react-intl"
import { change } from "redux-form"
import { constructSubLabel, constructLabel } from "../../util"
import { formSelector } from "./selector"
import { searchContacts } from "../../../actions/contact"
import { combineValidators } from "revalidate"
import { merge } from "lodash"
import { setIsBookingQuickRate } from "../../../actions/redesign"
import {
    aesNumberValidator,
    invalidInbondShipment,
    isRequired,
    isRequiredIf,
    nmfcFormValidator,
    pickUpDateValidator,
} from "../../../actions/validation"
import {
    sortByPriority,
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
} from "../../quotesPage/accessorials"
import Items from "./steps/Items"
import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import VagueCommodityDialog from "../../quotesPage/dialog/vagueCommodityDialog"
import {
    createShipment,
    populateActiveRate,
    requestQuote,
} from "../../../actions/book-shipment-requests"
import Spinner from "../../util/spinner"
import { requestItems } from "../../../actions/item"
import Rates from "./steps/Rates"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import { changePath, requestAccessorials } from "../../../actions"
import Finalize from "./steps/Finalize"
import {
    addressValidator,
    handlingUnitsValidator,
    contactValidator,
    pickupValidator,
    customsBrokerValidationV2,
    isRequiredIfIntlAndSelectGenerateInvoice,
    customsCommercialInvoiceValidation,
} from "./validators"
import Review from "./steps/Review"
import { customsCOOValidation, hazmatValidator } from "../../book"
import composeValidators from "revalidate/lib/composeValidators"
import { trackGAEventRR } from "../../../actions/user"
import { useBookedShipmentContext } from "../../../context/providers/BookedShipmentProvider"
import { copyShipment } from "../../../actions/quote-request"
import FreightBoxRestrictionDialog from "./atoms/FreightBoxRestrictionDialog"

const useStyles = makeStyles({
    baseContainer: {
        paddingTop: "72px",
        paddingBottom: "142px",
    },
    reviewContainer: {
        padding: "0px 5% 72px 5%",
    },
    stepperContainer: {
        position: "fixed",
        top: 64,
        zIndex: "1000",
    },
    workflowContainer: {
        padding: "80px 10% 25px 10%",
        maxWidth: "1500px",
    },
    input__field: {
        //backgroundColor: "rgb(242, 242, 242)",
        cursor: "pointer",
    },
    textInput__field: {
        cursor: "text",
    },
    stepContainer: {
        paddingBottom: "45px",
    },
    containerForm: {
        display: "flex",
        width: "100%",
    },
})

const defaultSteps = [
    {
        label: "Basic Info",
    },
    {
        label: "Origin",
    },
    {
        label: "Destination",
    },
    {
        label: "Line Items",
    },
    {
        label: "Rates",
    },
    {
        label: "Finalize",
    },
    {
        label: "Review and Book",
    },
]

export const intraMexicoFreightClassCheck = formValues => {
    let newValues = { ...formValues }
    if (
        newValues?.origin?.shippingAddress?.address?.country === "MX" &&
        newValues?.destination?.shippingAddress?.address?.country === "MX"
    ) {
        newValues.handlingUnits.forEach(hu => {
            hu.items.forEach(item => {
                if (!item?.freightClass) {
                    //default to mexico, the freight class does not change the shipment value for intramexico.
                    item.freightClass = 50
                }
            })
        })
    }
    return newValues
}

const BookShipment = ({
    locations,
    formattedLocations,
    changeField,
    initialValues,
    formValues = {},
    performContactSearch,
    contactSearchInProgress,
    originCities,
    destinationCities,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    vagueCommodities = [],
    getRatesRequest,
    getBookRequest,
    loadItems,
    changePath,
    guaranteedList,
    shipmentId,
    loadAccessorials,
    activeQuote,
    resetForm,
    trackGA,
    invalid,
    touchField,
    isBookingQuickRate,
    quickRate,
    setIsBookingQR,
    user,
    loadRate,
    handleSubmit,
    reRunRates,
    validate,
}) => {
    const isReturnMode = initialValues?.isReturnMode
    const classes = useStyles()
    const [currentStep, setCurrentStep] = useState(
        shipmentId && !isReturnMode ? 4 : 0
    )
    const [editMode, setEditMode] = useState(false)
    const [previousStep, setPreviousStep] = useState(0)
    const [isHUOpen, setIsHUOpen] = useState(0)
    const [vagueItemDescriptions, setVagueItemDescriptions] = useState([])
    const [isVagueCommodity, setIsVagueCommodity] = useState(false)
    const [
        isNotAllowedInBondCountryModal,
        setIsNotAllowedInBondCountryModal,
    ] = useState(false)

    const [steps] = useState(defaultSteps)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingRate, setIsLoadingRate] = useState(false)

    const [isControlledFlow, setIsControlledFlow] = useState(true)
    const [isFreightBoxDialogOpen, setIsFreightBoxDialogOpen] = useState(false)

    const { shouldReset, origin, destination, isInBondShipment } = formValues

    const originCountry = origin?.shippingAddress?.address?.country
    const destinationCountry = destination?.shippingAddress?.address?.country

    const invalidInBondCountry =
        isInBondShipment &&
        invalidInbondShipment(originCountry, destinationCountry)

    const [oldFormValues, setOldFormValues] = useState({})

    const { setShouldOpen } = useBookedShipmentContext()

    const onClickReRunRates = async () => {
        setIsLoading(true)
        await reRunRates(shipmentId, "V2")
        setIsLoading(false)
        scroller.scrollTo("ratesTitle", {
            offset: -140,
        })
    }

    useEffect(() => {
        if (shipmentId && !isReturnMode) {
            const fetchData = async () => {
                setIsLoadingRate(true)
                trackGA("Book Shipment Flow", "Load Existing Rate")
                await loadRate(shipmentId)
                setIsLoadingRate(false)
            }
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId])

    useEffect(() => {
        if (isBookingQuickRate) {
            Object.keys(quickRate).forEach(k => {
                if (k !== "isQuickRate") {
                    changeField(k, quickRate[k])
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBookingQuickRate])

    useEffect(() => {
        trackGA("Book Shipment Flow", "Page Mount")

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shouldReset) {
            trackGA(
                "Book Shipment Flow",
                "Reset from Nav Bar",
                `Current Step: ${currentStep}`
            )
            setCurrentStep(0)
            setIsBookingQR(false)
            setOldFormValues({})
            setPreviousStep(0)
            setIsControlledFlow(true)
            setEditMode(false)
            setIsHUOpen(0)
            changeField("shouldReset", false)
            changeField("shouldReset", false)
            changeField("destination", {
                country: initialValues?.destination?.country,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldReset])

    useEffect(() => {
        loadAccessorials()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (invalidInBondCountry) {
            setIsNotAllowedInBondCountryModal(true)
            trackGA("Book Shipment Flow", "In-Bond Modal", "Appears")
        } else {
            setIsNotAllowedInBondCountryModal(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originCountry, destinationCountry])
    const { openSnackbar } = useSnackbarContext()

    const addNewHandlingUnit = fields => {
        trackGA(
            "Book Shipment Flow",
            "Add New Handling Unit",
            `Current Length: ${fields.length}`
        )
        fields.push(newFreightDirectHandlingUnit())
        const id = fields.length
        setIsHUOpen(id > -1 ? id : undefined)
    }

    const handleHUPanel = index => {
        setIsHUOpen(isHUOpen === index ? null : index)
    }

    const onStepClick = label => {
        trackGA("Book Shipment Flow", "Stepper - Step Click", label)
        scroller.scrollTo(label, {
            duration: 500,
            smooth: true,
            offset: -175,
        })
    }

    const handleComplete = isInvalidNextButton => {
        if (isInvalidNextButton) {
        } else {
            trackGA(
                "Book Shipment Flow",
                "Complete Step",
                defaultSteps[currentStep].label
            )
            setCurrentStep(currentStep + 1)
        }
    }

    const handleUpdate = () => {
        trackGA("Book Shipment Flow", "Update", defaultSteps[currentStep].label)

        setEditMode(false)
        if (currentStep <= 3) {
            trackGA(
                "Book Shipment Flow",
                "Update info after initial rating",
                defaultSteps[currentStep].label
            )
        }
        setCurrentStep(currentStep + 1)
    }

    const handleEdit = index => {
        trackGA(
            "Book Shipment Flow",
            "Edit",
            defaultSteps[index].label,
            currentStep
        )

        setIsControlledFlow(false)

        setPreviousStep(currentStep)
        setCurrentStep(index)
        setEditMode(true)
        setOldFormValues(formValues)
    }

    const handleCancelEdit = () => {
        trackGA(
            "Book Shipment Flow",
            "Cancel Edit",
            defaultSteps[currentStep].label,
            previousStep
        )

        setIsControlledFlow(true)

        Object.keys(oldFormValues).forEach(entry => {
            changeField(entry, oldFormValues[entry])
        })
        setEditMode(false)
        setCurrentStep(previousStep)
    }

    const checkVagueCommodities = async isUpdate => {
        const originCountry =
            formValues?.origin?.shippingAddress?.address?.country
        const destinationCountry =
            formValues?.destination?.shippingAddress?.address?.country
        const vagueItemDescriptions = []

        if (originCountry !== destinationCountry) {
            formValues.handlingUnits.forEach((hu, huIndex) => {
                hu.items.forEach((item, itemIndex) => {
                    vagueCommodities.forEach(vagueCommodity => {
                        if (
                            vagueCommodity.name.toLowerCase() ===
                            item.description.trim().toLowerCase()
                        ) {
                            const validDescription = {
                                huIndex,
                                itemIndex,
                                oldDescription: item.description,
                                newDescription: item.description,
                                vagueDescription: vagueCommodity.description,
                                class: item.freightClass,
                            }
                            vagueItemDescriptions.push(validDescription)
                        }
                    })
                })
            })
        }
        if (vagueItemDescriptions.length > 0) {
            trackGA("Book Shipment Flow", "Vague Commodities Modal", "Appears")
            setVagueItemDescriptions(vagueItemDescriptions)
            setIsVagueCommodity(true)
        } else {
            await makeRateRequest(isUpdate)
        }
    }

    const getRates = isUpdate => {
        checkVagueCommodities(isUpdate)
    }

    const onBookShipment = async () => {
        trackGA("Book Shipment Flow", "Review", "Book Shipment")
        trackGA("Book Shipment Flow", "Book Shipment")
        setIsLoading(true)
        try {
            const shipmentResult = await getBookRequest(formValues)

            changePath(`/track/${shipmentId}`)

            const carrier = shipmentResult?.shipment?.carrier
            trackGA("Book Shipment Flow", "Book Success", carrier)
            if (carrier === "FXNL" || carrier === "FXFE") {
                setShouldOpen(true)
            }
        } catch (err) {
            console.log(err)
            if (err) {
                trackGA("Book Shipment Flow", "Book Error", err)
                openSnackbar("error", err)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const makeRateRequest = async isUpdate => {
        trackGA("Book Shipment Flow", "Rate Request", `isUpdate: ${isUpdate}`)
        setIsLoading(true)
        let newFormValues = intraMexicoFreightClassCheck(formValues)
        try {
            await getRatesRequest(newFormValues)
            trackGA(
                "Book Shipment Flow",
                "Rating Success",
                `isUpdate: ${isUpdate}`
            )
            if (isUpdate) {
                handleUpdate()
            } else {
                handleComplete()
            }
        } catch (err) {
            if (err?.props?.id && err?.props?.defaultMessage) {
                trackGA(
                    "Book Shipment Flow",
                    "Rating Error",
                    err?.props?.defaultMessage
                )
                openSnackbar("error", <FormattedMessage {...err?.props} />)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const inputProps = {
        classes: {
            root: classes.input__field,
            input: classes.input__field,
        },
    }

    const textInputProps = {
        classes: {
            root: classes.textInput__field,
            input: classes.textInput__field,
        },
    }

    const resetSelection = field => {
        changeField(`${field}Name`, null)
        changeField(`${field}City`, null)
        changeField(`${field}State`, null)
        changeField(`${field}Street`, null)
        changeField(`${field}Contact`, null)
        changeField(`${field}Address`, null)
        changeField(`${field}ContactInfo`, null)
        changeField(`${field}AddressInfo`, null)
    }

    const props = {
        currentStep,
        editMode,
        handleComplete,
        handleEdit,
        handleCancelEdit,
        handleUpdate,
        inputProps,
        textInputProps,
        initialValues,
        formValues,
        setIsLoading,
        permissions: formValues?.selectedLocation?.users[0].permissions,
        shipmentId,
        activeQuote,
        changeField,
        trackGA,
        invalid,
        touchField,
        isBookingQuickRate,
        isControlledFlow,
        setIsControlledFlow,
        handleSubmit,
    }

    const proccedWithOutInbondModal = () => {
        trackGA("Book Shipment Flow", "In-Bond Modal", "Proceed")
        setIsNotAllowedInBondCountryModal(false)
        changeField("isInBondShipment", false)
    }

    const cancelInbondModal = () => {
        trackGA("Book Shipment Flow", "In-Bond Modal", "Cancel")
        setIsNotAllowedInBondCountryModal(false)
    }

    if (isLoadingRate) {
        return <Spinner />
    }

    return (
        <Grid container>
            <FreightBoxRestrictionDialog
                open={isFreightBoxDialogOpen}
                handleClose={() => setIsFreightBoxDialogOpen(false)}
            />
            <form
                onSubmit={handleSubmit(() => {})}
                className={classes.containerForm}
            >
                {isLoading && <Spinner />}
                {currentStep < 6 && (
                    <Grid
                        container
                        direction="column"
                        className={classes.baseContainer}
                        alignItems="center"
                    >
                        <Grid
                            item
                            container
                            className={classes.stepperContainer}
                        >
                            <BookStepper
                                currentStep={currentStep}
                                steps={steps}
                                onStepClick={onStepClick}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.workflowContainer}
                        >
                            {currentStep >= 0 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[0].label} />
                                    <BasicInfo
                                        {...{
                                            ...props,
                                            formattedLocations,
                                            locations,
                                            resetSelection,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 1 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[1].label} />
                                    <Origin
                                        {...{
                                            ...props,
                                            performContactSearch,
                                            contactSearchInProgress,
                                            cities: originCities,
                                            pickupAccessorialsList,
                                            isNotAllowedInBondCountryModal,
                                            proccedWithOutInbondModal,
                                            cancelInbondModal,
                                            invalidInBondCountry,
                                            setIsFreightBoxDialogOpen,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 2 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[2].label} />
                                    <Destination
                                        {...{
                                            ...props,
                                            performContactSearch,
                                            contactSearchInProgress,
                                            cities: destinationCities,
                                            deliveryAccessorialsList,
                                            isNotAllowedInBondCountryModal,
                                            proccedWithOutInbondModal,
                                            cancelInbondModal,
                                            invalidInBondCountry,
                                            setIsFreightBoxDialogOpen,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 3 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[3].label} />
                                    <Items
                                        {...{
                                            ...props,
                                            handleHUPanel,
                                            isHUOpen,
                                            addNewHandlingUnit,
                                            getRates,
                                            loadItems,
                                            isLoading,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 4 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[4].label} />
                                    <Rates
                                        {...{
                                            ...props,
                                            guaranteedList,
                                            onClickReRunRates,
                                            isLoading,
                                            setEditMode,
                                        }}
                                    />
                                </Grid>
                            )}
                            {currentStep >= 5 && (
                                <Grid
                                    item
                                    container
                                    className={classes.stepContainer}
                                >
                                    <Element name={steps[5].label} />
                                    <Finalize
                                        {...{
                                            ...props,
                                            originCities,
                                            destinationCities,
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <VagueCommodityDialog
                            isVagueCommodity={isVagueCommodity}
                            originalFormValues={formValues}
                            // checkAddressValidation={this.checkAddressValidation}
                            vagueItemDescriptions={vagueItemDescriptions}
                            vagueCommodities={vagueCommodities}
                            currentForm={"bookShipment"}
                            toggleVagueCommodityStatus={setIsVagueCommodity}
                        />
                    </Grid>
                )}
                {currentStep === 6 && (
                    <Grid item container className={classes.reviewContainer}>
                        <Review
                            {...{
                                ...props,
                                pickupAccessorialsList,
                                deliveryAccessorialsList,
                                onBookShipment,
                                isLoading,
                            }}
                        />
                    </Grid>
                )}
            </form>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => ({
    loadRate: shipmentId => dispatch(populateActiveRate(shipmentId)),
    changeField: (field, value) =>
        dispatch(change("bookShipment", field, value)),
    performContactSearch: (value, postal_code, country) =>
        dispatch(searchContacts(value, "quote", postal_code, country)),
    getRatesRequest: async payload => dispatch(requestQuote(payload)),
    getBookRequest: async payload => {
        const shipmentResult = await dispatch(createShipment(payload))
        return shipmentResult
    },
    loadItems: () => dispatch(requestItems()),
    changePath: path => dispatch(changePath(path)),
    loadAccessorials: () => dispatch(requestAccessorials()),
    resetForm: form => dispatch(reset(form)),
    trackGA: (category, action, label, value) =>
        dispatch(trackGAEventRR(category, action, label, value)),
    touchField: field => dispatch(touch("bookShipment", field)),
    setIsBookingQR: value => dispatch(setIsBookingQuickRate(value)),
    reRunRates: async (shipmentId, queryVersion) =>
        dispatch(copyShipment(shipmentId, queryVersion)),
})

const mapStateToProps = (state, props) => {
    const locations = state?.user?.profile?.locations ?? []

    const formValues = getFormValues("bookShipment")(state)

    const formattedLocations = locations
        .filter(
            item =>
                item?.users[0]?.permissions?.createRates?.value &&
                !item?.users[0]?.permissions?.suspended?.value &&
                !item.isPending
        )
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    props.intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item.locationType === "FEDEX_FREIGHT_BOX",
            }
        })

    const contactSearchInProgress = state?.contact?.searchInProgress
    const language = state?.user?.profile?.preferences?.language

    const shipmentId = props?.computedMatch?.params?.shipmentId

    const originCountry = formValues?.origin?.shippingAddress?.address?.country
    const destinationCountry =
        formValues?.destination?.shippingAddress?.address?.country

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            originCountry !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            destinationCountry !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        shipmentId,
        locations,
        formattedLocations,
        initialValues: formSelector(state, props),
        formValues,
        contactSearchInProgress,
        originCities:
            state?.postalCode[
                `${originCountry}-${formValues?.origin?.shippingAddress?.address?.postal_code}`
            ]?.cities,
        destinationCities:
            state?.postalCode[
                `${destinationCountry}-${formValues?.destination?.shippingAddress?.address?.postal_code}`
            ]?.cities,
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        vagueCommodities: state?.vagueCommodities?.list,
        guaranteedList: state.accessorials.list.guaranteed,
        activeQuote: state?.redesign?.activeRates[shipmentId],
        isBookingQuickRate: state?.redesign?.isBookingQuickRate,
        quickRate: state?.form?.quickRate?.values,
        user: state?.user?.profile,
    }
}

export const bookShipmentFormValidation = (values, props) => {
    const mainValidation = combineValidators({
        billToLocation: isRequired({
            field: {
                id: "getRates.form__billToLocation",
                defaultMessage: "Bill to Location",
            },
        }),
        pickupDate: composeValidators(
            isRequired,
            pickUpDateValidator("isFreightDirectReturns")
        )({
            field: {
                id: "getRates.form__shipDate",
                defaultMessage: "Ship Date",
            },
        }),
        origin: {
            shippingAddress: addressValidator("origin.shippingAddress"),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(
                        values => values?.origin?.taxIdentification?.number
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__exporter__select__tax__type",
                        defaultMessage:
                            "Select  Exporter Tax Identification Type",
                    },
                }),
                number: composeValidators(
                    isRequiredIf(
                        values => values?.origin?.taxIdentification?.type
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__exporter__tax__identification__number",
                        defaultMessage: " Exporter Tax Identification Number",
                    },
                }),
            },
            contact: contactValidator("origin.shippingAddress.address.country"),
            ...pickupValidator("origin.shippingAddress.address.country"),
        },
        destination: {
            shippingAddress: addressValidator("destination.shippingAddress"),
            contact: contactValidator(
                "destination.shippingAddress.address.country"
            ),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(
                        values => values?.destination?.taxIdentification?.number
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__consignee__select__tax__type",
                        defaultMessage:
                            "Select  Consignee  Tax Identification Type",
                    },
                }),
                number: composeValidators(
                    isRequiredIf(
                        values => values?.destination?.taxIdentification?.type
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__consignee__tax__identification__number",
                        defaultMessage: " Consignee Tax Identification Number",
                    },
                }),
            },
        },
        exportCustomsBrokerProfile: customsBrokerValidationV2(
            "origin.shippingAddress.address.country",
            "exportCustomsBrokerProfile"
        ),
        importCustomsBrokerProfile: customsBrokerValidationV2(
            "destination.shippingAddress.address.country",
            "importCustomsBrokerProfile"
        ),
        commercialInvoice: customsCommercialInvoiceValidation(),
        certificateOfOrigin: customsCOOValidation(),
        purposeOfShipment: isRequiredIfIntlAndSelectGenerateInvoice({
            field: {
                id: "orderDetails.support__documentation__select_purpose",
                defaultMessage: "Select Purpose Of Shipment",
            },
        }),
        identifiers: {
            aesCitationNumber: aesNumberValidator({
                field: {
                    id: "orderDetails.label__AES",
                    defaultMessage: "AES Citation Number",
                },
            }),
        },
        hazmatEmergency: hazmatValidator(
            "origin.shippingAddress.address.country"
        ),
        totalLinearFeet: isRequired({
            field: {
                id: "getRates.form__estLinearFeet",
                defaultMessage: "Est. Linear Feet",
            },
        }),
        ...handlingUnitsValidator,
    })(values, props)
    return merge({}, mainValidation, nmfcFormValidator(values))
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            // a unique name for the form
            form: "bookShipment",
            validate: bookShipmentFormValidation,
            destroyOnUnmount: true,
            forceUnregisterOnUnmount: true,
            enableReinitialize: true,
        })(BookShipment)
    )
)
