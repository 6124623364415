export default {
    beacon: {
        outline: "none",
        display: "none",
    },
    options: {
        primaryColor: "#4D148C",
        textColor: "black",
        zIndex: 5000,
    },
}
