import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import {
    isInternationalShipment,
    isMexicoToUsaInternationalShipment,
    isUSDomesticOffshoreShipment,
} from "../../../../actions/validation"
import { Chip, withStyles } from "@material-ui/core"

const styles = theme => ({
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
    header: {
        paddingBottom: "15px",
    },
    section: {
        paddingTop: "15px",
    },
    underline: {
        textDecoration: "underline",
    },
    chip: {
        margin: "5px 10px 5px 0px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
})

const FinalizeSummary = ({ handleEdit, formValues = {}, classes }) => {
    const {
        handlingUnits = [],
        selectedRate = {},
        identifiers = {},
        destination = {},
        origin = {},
        hazmatEmergency = {},
        isInBondShipment,
        cf7512 = [],
        isFreightDirect,
        isFreightDirectReturns,
        pickupLater,
        exportCustomsBrokerProfile = {},
        importCustomsBrokerProfile = {},
    } = formValues

    const {
        bolNumber,
        orderNumber,
        referenceNumber,
        proNumber,
        proEnabled,
    } = identifiers
    const { _id } = selectedRate
    const isVolumeRate = selectedRate?.mode === "VOLUME_LTL"
    const quoteId = selectedRate?.capacityProviderQuoteNumber
    const originCountry = origin?.shippingAddress?.address?.country
    const destinationCountry = destination?.shippingAddress?.address?.country
    const isMXToUSIntl = isMexicoToUsaInternationalShipment(
        originCountry,
        destinationCountry
    )
    const isIntl = isInternationalShipment(originCountry, destinationCountry)
    const isUSDomesticOffshore = isUSDomesticOffshoreShipment(
        originCountry,
        destinationCountry,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const [isHazMat, setIsHazMat] = useState(false)

    useEffect(() => {
        setIsHazMat(
            handlingUnits.some(hu => {
                return hu.items.some(item => item.isHazMat)
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])

    return (
        <Grid item container>
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.finalizeSummary__title"
                            defaultMessage="Additional Details"
                        />
                    </Typography>
                    <Button
                        id="editFinalize"
                        color="primary"
                        variant="outlined"
                        onClick={() => handleEdit(5)}
                    >
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    </Button>
                </Grid>
                <Grid item container>
                    <Grid item container xs={6} direction="column">
                        <Typography>
                            <FormattedMessage
                                id="book.optionalIdentifiers__title"
                                defaultMessage="Shipment Identifiers"
                            />
                        </Typography>
                        {proEnabled && proNumber && (
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="bookShipment.review__trackingId"
                                    defaultMessage="Tracking ID: {proNumber}"
                                    values={{ proNumber }}
                                />
                            </Typography>
                        )}
                        {bolNumber && (
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="bookShipment.review__BOL"
                                    defaultMessage="Bill of Lading ID: {bolNumber}"
                                    values={{ bolNumber }}
                                />
                            </Typography>
                        )}
                        {orderNumber && (
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="bookShipment.review__PO"
                                    defaultMessage="PO: {orderNumber}"
                                    values={{ orderNumber }}
                                />
                            </Typography>
                        )}
                        {referenceNumber && (
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="bookShipment.review__referenceId"
                                    defaultMessage="Reference ID: {referenceNumber}"
                                    values={{ referenceNumber }}
                                />
                            </Typography>
                        )}
                        {isVolumeRate && quoteId && (
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__quoteId"
                                    defaultMessage="Quote ID"
                                />
                                {`: ${quoteId}`}
                            </Typography>
                        )}
                    </Grid>

                    {!pickupLater && (
                        <Grid item container xs={6} direction="column">
                            <Typography>
                                <FormattedMessage
                                    id="orderResults.pickup__title"
                                    defaultMessage="Pickup Requirements"
                                />
                            </Typography>
                            <Typography variant="caption">
                                {origin?.readyTime} - {origin?.closeTime}
                            </Typography>
                            <Typography variant="caption">
                                {origin?.pickupContact?.name}
                            </Typography>
                            <Typography variant="caption">
                                {`${
                                    origin?.pickupContact?.phone?.phone_number
                                }${
                                    origin?.pickupContact?.phone?.extension
                                        ? ` - ${origin?.pickupContact?.phone?.extension}`
                                        : ""
                                }`}
                            </Typography>
                            <Typography variant="caption">
                                {origin?.pickupContact?.email.email_address}
                            </Typography>
                        </Grid>
                    )}

                    {isHazMat && (
                        <Grid item container xs={6} direction="column">
                            <Typography
                                className={`${
                                    !isFreightDirect && !isFreightDirectReturns
                                        ? classes.section
                                        : ""
                                }`}
                            >
                                <FormattedMessage
                                    id="orderResults.mainContact"
                                    defaultMessage="HazMat Contact"
                                />
                            </Typography>
                            <Typography variant="caption">
                                {hazmatEmergency?.name}
                            </Typography>
                            <Typography variant="caption">
                                {`${hazmatEmergency?.phone?.phone_number}${
                                    hazmatEmergency?.phone?.extension
                                        ? ` - ${hazmatEmergency?.phone?.extension}`
                                        : ""
                                }`}
                            </Typography>
                            <Typography variant="caption">
                                {hazmatEmergency?.email}
                            </Typography>
                        </Grid>
                    )}
                    {isMXToUSIntl && (
                        <Grid item container xs={6} direction="column">
                            <Typography
                                className={`${
                                    !isFreightDirect && !isFreightDirectReturns
                                        ? classes.section
                                        : ""
                                }`}
                            >
                                <FormattedMessage
                                    id="orderDetails.customs__export__title"
                                    defaultMessage="Export Customs Brokerage Information"
                                />
                            </Typography>
                            <Typography variant="caption">
                                {exportCustomsBrokerProfile?.brokerName}
                            </Typography>
                            <Typography variant="caption">
                                {`${
                                    exportCustomsBrokerProfile?.phone
                                        ?.phone_number
                                }${
                                    exportCustomsBrokerProfile?.phone?.extension
                                        ? ` - ${exportCustomsBrokerProfile?.phone?.extension}`
                                        : ""
                                }`}
                            </Typography>
                            <Typography variant="caption">
                                {
                                    exportCustomsBrokerProfile?.email
                                        ?.email_address
                                }
                            </Typography>
                            <Typography variant="caption">
                                {
                                    exportCustomsBrokerProfile?.address
                                        ?.postalCode
                                }
                            </Typography>
                        </Grid>
                    )}

                    {(isIntl ||
                        (isUSDomesticOffshore &&
                            !!importCustomsBrokerProfile)) && (
                        <Grid item container xs={6} direction="column">
                            <Typography
                                className={`${
                                    !isFreightDirect && !isFreightDirectReturns
                                        ? classes.section
                                        : ""
                                }`}
                            >
                                <FormattedMessage
                                    id="orderDetails.customs__import__title"
                                    defaultMessage="Import Customs Brokerage Information"
                                />
                            </Typography>
                            <Typography variant="caption">
                                {importCustomsBrokerProfile?.brokerName}
                            </Typography>
                            <Typography variant="caption">
                                {`${
                                    importCustomsBrokerProfile?.phone
                                        ?.phone_number
                                }${
                                    importCustomsBrokerProfile?.phone?.extension
                                        ? ` - ${importCustomsBrokerProfile?.phone?.extension}`
                                        : ""
                                }`}
                            </Typography>
                            <Typography variant="caption">
                                {
                                    importCustomsBrokerProfile?.email
                                        ?.email_address
                                }
                            </Typography>
                            <Typography variant="caption">
                                {
                                    importCustomsBrokerProfile?.address
                                        ?.postalCode
                                }
                            </Typography>
                        </Grid>
                    )}

                    {isInBondShipment && (
                        <Grid item container xs={6} direction="column">
                            <Typography
                                className={`${
                                    !isFreightDirect && !isFreightDirectReturns
                                        ? classes.section
                                        : ""
                                }`}
                            >
                                <FormattedMessage
                                    id="book.inBond__title"
                                    defaultMessage="CF7512 Numbers & Transportation Type"
                                />
                            </Typography>
                            <Grid item container>
                                {cf7512.map((number, i) => (
                                    <Chip
                                        label={number}
                                        key={i}
                                        className={classes.chip}
                                        backgroundColor="#F4F4F4"
                                        tabIndex={-1}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(FinalizeSummary)
