import { SubmissionError } from "redux-form"
import sha256 from "crypto-js/sha256"
import { goFetch } from "../http"
import { errorMessage, USER_INPUT_ENDED_DELAY } from "./index"
import { sortByObjectIdDate } from "../misc"

export const ADD_SAVED_ITEM = "ADD_SAVED_ITEM"
export const DELETE_SAVED_ITEM = "DELETE_SAVED_ITEM"
export const ITEM_TOGGLE_EDIT = "ITEM_TOGGLE_EDIT"
export const ITEM_TOGGLE_EXPAND = "ITEM_TOGGLE_EXPAND"
export const ITEM_LIST_RESULT = "ITEM_LIST_RESULT"
export const ITEM_SEARCH_RESULT = "ITEM_SEARCH_RESULT"
export const ITEM_FIELD_VALIDATION = "ITEM_FIELD_VALIDATION"
export const UPDATE_ITEM_FIELD = "UPDATE_ITEM_FIELD"
export const QUEUE_ITEM_SEARCH = "QUEUE_ITEM_SEARCH"
export const INVALIDATE_ITEM_LIST = "INVALIDATE_ITEM_LIST"
export const ADD_NEW_EMPTY_ITEM = "ADD_NEW_EMPTY_ITEM"
export const CANCEL_NEW_EMPTY_ITEM = "CANCEL_NEW_EMPTY_ITEM"

export const addNewEmptyItem = () => ({ type: ADD_NEW_EMPTY_ITEM })

export const cancelNewEmptyItem = () => ({ type: CANCEL_NEW_EMPTY_ITEM })

export const addSavedItem = (record, index) => ({
    type: ADD_SAVED_ITEM,
    record,
    index,
})

export function saveItem(item, index) {
    return async dispatch => {
        try {
            const url =
                item._id && index != null ? `/item/${item._id}` : "/item"
            const { data: db } = await goFetch(
                url,
                {
                    method: item._id && index != null ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: item,
                },
                true
            )
            item._id = db._id
            dispatch(addSavedItem(item, index))
        } catch (error) {
            dispatch(errorMessage(error))
            throw SubmissionError
        }
    }
}

export function saveItemIndex(item, index) {
    return (dispatch, getState) => {
        if (index === null || index === undefined) return null
        return dispatch(saveItem(item, index))
    }
}

export const toggleEditMode = index => ({ type: ITEM_TOGGLE_EDIT, index })

export const toggleExpand = index => ({ type: ITEM_TOGGLE_EXPAND, index })

const itemListResult = (data = []) => ({
    type: ITEM_LIST_RESULT,
    items: data.sort(sortByObjectIdDate),
})

export const invalidateItemList = () => ({ type: INVALIDATE_ITEM_LIST })

export function requestItems() {
    return async (dispatch, getState) => {
        const { isLoaded } = getState().item
        if (isLoaded) return null
        try {
            const { data, status } = await goFetch(
                "/item",
                { validErrorCodes: [404] },
                true
            )
            if (status === 404) return dispatch(itemListResult())
            return dispatch(itemListResult(data))
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

const itemSearchResult = (identifier, items = []) => ({
    type: ITEM_SEARCH_RESULT,
    identifier,
    items,
})

function innerSearchItems(value, origin, originAddress, identifier) {
    return async (dispatch, getState) => {
        try {
            const isNumber = /^\d+$/.test(value)
            const params = {
                q: value,
                ...(isNumber && { unNumber: value }),
                limit: 5,
                origin,
                originAddress,
            }
            const { data, status } = await goFetch(
                "/item",
                { validErrorCodes: [404], params },
                true
            )
            if (status === 404) return dispatch(itemSearchResult(identifier))
            return dispatch(itemSearchResult(identifier, data))
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

export const itemSearchHash = (value, origin, originAddressId) =>
    sha256([value, origin, originAddressId].join(",")).toString()

export function searchItems(value, origin, originAddressId) {
    return async (dispatch, getState) => {
        if (!value || value.length < 3) return
        const identifier = itemSearchHash(value, origin, originAddressId)
        const result = getState().item.search[identifier]
        if (result) return
        const { searchInProgress } = getState().item
        clearTimeout(searchInProgress)
        const timeoutId = setTimeout(
            () =>
                dispatch(
                    innerSearchItems(value, origin, originAddressId, identifier)
                ),
            USER_INPUT_ENDED_DELAY
        )
        dispatch({ type: QUEUE_ITEM_SEARCH, id: timeoutId })
    }
}

export function deleteItem(id) {
    return async dispatch => {
        try {
            const { status } = await goFetch(
                `/item/${id}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    validErrorCodes: [404],
                },
                true
            )
            if (status === 404) return
            dispatch({ type: DELETE_SAVED_ITEM, id })
        } catch (error) {
            dispatch(errorMessage(error))
            throw new Error()
        }
    }
}

export function updateItemField(field, value, index, size = null) {
    return { type: UPDATE_ITEM_FIELD, field, value, index, size }
}

export function itemFieldValidation(validation, index, strict) {
    return { type: ITEM_FIELD_VALIDATION, validation, index, strict }
}
