import React from "react"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Divider from "@material-ui/core/Divider"
import { withStyles } from "@material-ui/core/styles"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Typography from "@material-ui/core/Typography"
import { get } from "lodash"
import moment from "moment"
import { accessorialsSelector } from "../util/accessorialsDisplay"
import AccessorialsDisplay from "./accessorialsDisplay"
import QuoteHUDisplay from "./quoteHUDisplay"
import { FormattedMessage, injectIntl } from "react-intl"
import { weightUnit } from "../util/units"

const styles = theme => ({
    parent__container: {
        "min-width": "870px",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": "0px",
    },
    panel__summary: {
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
        minHeight: "84px",
    },
    item__container: {
        overflow: "auto",
    },
    button__info: {
        "margin-right": "15%",
    },
    button__icon: {
        "&button": {
            "&:focus": {
                outline: "none",
            },
        },
    },
    warning__icon: {
        "margin-left": "5px",
    },
    warning__box: {
        "background-color": "#D3D3D3",
        "align-items": "center",
        width: "120px",
        height: "90px",
        display: "flex",
        "justify-content": "center",
        padding: "5%",
    },
    identifiers__container: {
        "white-space": "pre-line",
        display: "flex",
        width: "90%",
    },
    content__container: {
        "white-space": "pre-line",
        display: "flex",
        width: "90%",
        "padding-left": "20px",
    },
    address__container__open: {
        "white-space": "pre-line",
        display: "flex",
        width: "50%",
    },
    address__box__left: {
        "min-width": "35%",
    },
    address__box__right: {
        "min-width": "65%",
    },
    actions: {
        padding: "4px 16px 8px 8px",
    },
    directionIcon: {
        width: "35px",
        height: "35px",
    },
    carrier__logo: {
        height: "51px",
        width: "57px",
    },
    carrier__tag: {
        "margin-left": "16px",
    },
    item__description: {
        "margin-left": "16px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "flex-start",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "10px",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    hide: {
        display: "none",
        "margin-left": "16px",
    },
    identifiers__box__left: {
        "min-width": "40%",
    },
    identifiers__box__right: {
        "min-width": "60%",
    },
    expansionPanelDetails__section: {
        padding: "10px",
    },
    section__title: {
        textDecoration: "underline",
    },
    whiteSpace: {
        whiteSpace: "pre-wrap",
    },
})

const AddressPart = ({ data, title }) =>
    data && (
        <Grid item container xs={6}>
            <Grid item container justify="flex-start" direction="row" xs={12}>
                <Grid item>
                    <Typography variant="caption">{title}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                justify="flex-start"
                direction="column"
                xs={12}
            >
                <Grid item>
                    {get(data, "name") && <Typography>{data.name}</Typography>}
                </Grid>
                <Grid item>
                    {get(data, "address.street1") && (
                        <Typography>{get(data, "address.street1")}</Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address") && (
                        <Typography>
                            {get(data, "address.city")},{" "}
                            {get(data, "address.state")}{" "}
                            {get(data, "address.postalCode")}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )

function DashboardItem(props) {
    const {
        identifiers = {},
        isOpen,
        search = {},
        classes,
        _id,
        accessorials,
        updated_at,
        shipped,
        onClickDetails,
        onViewQuote,
        shipmentId,
        index,
        result,
        handlingUnits = [],
        totalWeight,
        totalHandlingUnits,
        intl,
    } = props
    const destinationAddress =
        get(search, "destinationAddress.name") ||
        (get(search, "destinationCity")
            ? `${get(search, "destinationCity")}, ${get(
                  search,
                  "destinationState"
              )}`
            : get(search, "destination"))
    const originAddress =
        get(search, "originAddress.name") ||
        (get(search, "originCity")
            ? `${get(search, "originCity")}, ${get(search, "originState")}`
            : get(search, "origin"))
    const isQuickQuote = get(search, "isQuickQuote")
    const isQuickRate = get(search, "isQuickRate")

    const isFreightDirect = result?.rateQuotes.some(
        rate => rate.mode === "LTL_DIRECT"
    )

    const isFreightDirectReturns = result?.rateQuotes.some(
        rate => rate.mode === "LTL_DIRECT_RETURNS"
    )

    return (
        <div className="quote-item">
            <ExpansionPanel
                id={`dashboardQuoteItem__${index}`}
                className={classes.parent__container}
                expanded={isOpen}
            >
                <ExpansionPanelSummary
                    expandIcon={
                        <ExpandMore
                            id={`dashboardQuoteItemExpandMore__${index}`}
                        />
                    }
                    onClick={() => props.toggleInfo(_id)}
                    className={classes.panel__summary}
                >
                    <Grid container>
                        <Grid
                            item
                            container
                            justify="flex-start"
                            alignItems="center"
                            xs={2}
                        >
                            <img
                                className={classes.carrier__logo}
                                src="static/LTLselect_logo-icon-only.png"
                                alt="LTL Select"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.item__container}
                            direction="column"
                            xs={2}
                        >
                            {get(identifiers, "bolNumber", "") !== "" && (
                                <div className={classes.identifiers__container}>
                                    <span
                                        className={
                                            classes.identifiers__box__left
                                        }
                                    >
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__rateId"
                                                defaultMessage="Rate ID"
                                            />
                                            :{" "}
                                        </Typography>
                                    </span>
                                    <span
                                        className={
                                            classes.identifiers__box__right
                                        }
                                    >
                                        <Typography
                                            id={`dashboardQuoteItem__${index}__bolNumber`}
                                        >
                                            {identifiers.bolNumber}
                                        </Typography>
                                    </span>
                                </div>
                            )}
                            {get(identifiers, "referenceNumber", "") !== "" && (
                                <div className={classes.identifiers__container}>
                                    <span
                                        className={
                                            classes.identifiers__box__left
                                        }
                                    >
                                        <Typography variant="caption">
                                            <FormattedMessage
                                                id="generalTerms__referenceId"
                                                defaultMessage="Reference ID"
                                            />
                                            :{" "}
                                        </Typography>
                                    </span>
                                    <span
                                        className={
                                            classes.identifiers__box__right
                                        }
                                    >
                                        <Typography>
                                            {identifiers.referenceNumber}
                                        </Typography>
                                    </span>
                                </div>
                            )}
                        </Grid>
                        {
                            <Grid
                                item
                                container
                                justify="center"
                                direction="column"
                                xs={3}
                            >
                                <div className={classes.content__container}>
                                    <span
                                        className={classes.address__box__right}
                                    >
                                        <Typography
                                            variant={"subheading"}
                                            id={`dashboardQuoteItem__${index}__originAndDestination`}
                                        >
                                            {originAddress} &#8594;{" "}
                                            {destinationAddress}
                                        </Typography>
                                    </span>
                                </div>
                            </Grid>
                        }
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            xs={2}
                        >
                            {totalHandlingUnits && totalWeight && (
                                <Grid item container justify="flex-end">
                                    <Typography
                                        variant={"subheading"}
                                        align="right"
                                        className={classes.whiteSpace}
                                    >
                                        {`${totalHandlingUnits} H/U  |  `}
                                    </Typography>
                                    <Typography
                                        variant="subheading"
                                        align="right"
                                        className={classes.whiteSpace}
                                    >
                                        {totalWeight}
                                        {weightUnit(
                                            search?.preferredSystemOfMeasurement
                                        )}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            alignItems="center"
                            xs={2}
                        >
                            <Grid item container justify="flex-end" xs={12}>
                                {isQuickQuote ? (
                                    <Typography variant={"subheading"}>
                                        <FormattedMessage
                                            id="generalTerms__quickQuoteLegacy"
                                            defaultMessage="Quick Quote (Legacy)"
                                        />
                                    </Typography>
                                ) : isQuickRate ? (
                                    <Typography variant={"subheading"}>
                                        <FormattedMessage
                                            id="generalTerms__quickRate"
                                            defaultMessage="Quick Rate"
                                        />
                                    </Typography>
                                ) : (
                                    <Typography variant={"subheading"}>
                                        <FormattedMessage
                                            id="dashboard.quote__quoted"
                                            defaultMessage="Quoted"
                                        />
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item container justify="flex-end" xs={12}>
                                <Typography
                                    variant="caption"
                                    id={`dashboardQuoteItem__${index}__date`}
                                >
                                    {moment(updated_at).format("M/DD/YY")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <Divider />
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                            alignItems="flex-start"
                        >
                            <Grid item container>
                                <Typography
                                    variant="subheading"
                                    className={classes.section__title}
                                >
                                    {" "}
                                    <FormattedMessage
                                        id="dashboard__routingSummary"
                                        defaultMessage="Routing Summary"
                                    />{" "}
                                </Typography>
                            </Grid>
                            <AddressPart
                                data={search?.originAddress}
                                title={`${intl.formatMessage({
                                    id: "generalTerms__from",
                                    defaultMessage: "From",
                                })}:`}
                            />
                            <AddressPart
                                data={search?.destinationAddress}
                                title={`${intl.formatMessage({
                                    id: "generalTerms__to",
                                    defaultMessage: "To",
                                })}:`}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                            alignContent="flex-start"
                        >
                            {!isFreightDirectReturns && accessorials && (
                                <AccessorialsDisplay
                                    accessorials={accessorials}
                                    isFreightDirect={isFreightDirect}
                                    isInBondShipment={search.isInBondShipment}
                                    id={`dashboardQuoteItem__${index}__accesorials`}
                                />
                            )}
                        </Grid>
                        <Grid
                            item
                            container
                            xs={6}
                            className={classes.expansionPanelDetails__section}
                        >
                            <QuoteHUDisplay
                                search={search}
                                handlingUnits={handlingUnits}
                            />
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                <ExpansionPanelActions className={classes.actions}>
                    {shipped && (
                        <Button
                            id={`dashboardQuoteItem__${index}____orderDetails`}
                            onClick={onClickDetails}
                            variant="outlined"
                            color="primary"
                            size="small"
                        >
                            <FormattedMessage
                                id="dashboard.shipment__orderDetails"
                                defaultMessage="Order Details"
                            />
                        </Button>
                    )}
                    <Button
                        id={`dashboardQuoteItem__${index}____viewRates`}
                        onClick={() =>
                            onViewQuote(
                                shipmentId,
                                isQuickQuote,
                                isQuickRate,
                                search?.queryVersion
                            )
                        }
                        variant="outlined"
                        color="primary"
                        size="small"
                    >
                        <FormattedMessage
                            id="dashboard.quote__viewRates"
                            defaultMessage="View Rates"
                        />
                    </Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        </div>
    )
}

const mapStateToProps = (state, props) => {
    const shipmentId = get(props, "identifiers.internalTrackingNumber")
    return {
        shipmentId,
        accessorials: {
            pickup: [
                ...accessorialsSelector(state, {
                    shipmentId,
                    isPickup: true,
                    includeCalculated: true,
                }),
            ],
            delivery: [...accessorialsSelector(state, { shipmentId })],
        },
    }
}

export default withStyles(styles)(
    connect(mapStateToProps)(injectIntl(DashboardItem))
)
