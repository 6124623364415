import React, { Fragment } from "react"
import { connect } from "react-redux"
import { createSelector } from "reselect"
import createCachedSelector from "re-reselect"
import { get } from "lodash"
import Chip from "@material-ui/core/Chip"
import { withStyles } from "@material-ui/core/styles"
import { getAccessorialText } from "../../actions/accessorial"
import { isFedExCarrier } from "../../misc"
import { rateSelector } from "../book/selectors"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    chip: {
        margin: "20px 10px",
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
})

const AccessorialsDisplayFragment = ({
    classes,
    accessorials = [],
    isInBondShipment,
}) =>
    accessorials ? (
        <Fragment>
            {accessorials.map((label, i) => (
                <Chip
                    label={label}
                    key={i}
                    className={classes.chip}
                    tabIndex={-1}
                />
            ))}
            {isInBondShipment && (
                <Chip
                    tabIndex={-1}
                    label={
                        <FormattedMessage
                            id="accessorials.panel__freightMovingInBond"
                            defaultMessage="Freight Moving In-Bond"
                        />
                    }
                    className={classes.chip}
                    backgroundColor="#F4F4F4"
                />
            )}
        </Fragment>
    ) : null

const listSelector = createSelector(
    state => state.accessorials.list.pickup,
    state => state.accessorials.list.delivery,
    state => state.accessorials.list.commodity,
    state => state.user.profile.preferences.language,
    (pickup = [], delivery = [], commodity = [], language) =>
        pickup
            ? pickup
                  .map(entry => ({
                      value: entry.value,
                      label: entry.label[language],
                  }))
                  .concat(
                      delivery.map(entry => ({
                          value: entry.value,
                          label: entry.label[language],
                      })),
                      commodity.map(entry => ({
                          value: entry.value,
                          label: entry.label[language],
                      }))
                  )
            : []
)

const mutuallyExclusiveAccessorials = Object.freeze([
    "LGPU",
    "RESPU",
    "LTDPU",
    "LGDEL",
    "RESDEL",
    "LTDDEL",
    "DOCKPU",
    "DOCKDEL",
])

const baseAccessorialsSelector = createCachedSelector(
    (state, props) => state.shipment.list[props.shipmentId],
    (state, props) => state.quotes.list.items[props.shipmentId],
    (state, props, type) => type,
    (shipment, quote, type) => {
        if (shipment) {
            return get(shipment, ["shipment", type, "accessorials"].join("."))
        }
        const quoteType =
            (type === undefined && "commodity") ||
            (type === "origin" ? "pickup" : "delivery")
        return get(quote, `search.${quoteType}Accessorials`)
    }
)((state, props, type) => [props.shipmentId, type].join() || "")

const itemsSelector = createCachedSelector(
    (state, props) => state.shipment.list[props.shipmentId],
    (state, props) => state.quotes.list.items[props.shipmentId],
    (shipment, quote) => get(shipment, "items") || get(quote, "items")
)((state, props) => props.shipmentId || "")

const additionalAccessorialsSelector = createCachedSelector(
    itemsSelector,
    rateSelector,
    baseAccessorialsSelector,
    (state, props) => props.isPickup,
    (state, props) => props.includeCalculated,
    (
        items = [],
        rate,
        commodityAccessorials = [],
        isPickup,
        includeCalculated
    ) => {
        if (!isPickup || !includeCalculated) return commodityAccessorials
        const extremeLengthCharges = get(rate, "rateQuoteDetail.charges", [])
            .filter(({ code }) => code.startsWith("ELS"))
            .map(({ code }) => {
                if (
                    isFedExCarrier(rate.carrierCode) &&
                    code.startsWith("ELS")
                ) {
                    const maxLength = Math.max(
                        ...items.map(item => item.length)
                    )
                    return maxLength > 96 && maxLength < 144
                        ? {
                              label: "Over Length Shipment",
                          }
                        : {
                              label: "Extreme Length Shipment",
                          }
                }
                return { value: code }
            })

        const hazMat = items.some(i => i.isHazMat) ? [{ label: "HazMat" }] : []

        return commodityAccessorials.concat(extremeLengthCharges, hazMat)
    }
)((state, props) =>
    [props.shipmentId, !!props.isPickup, !!props.insideCalculated].join()
)

export const accessorialsSelector = createCachedSelector(
    (state, props) =>
        baseAccessorialsSelector(
            state,
            props,
            props.isPickup ? "origin" : "destination"
        ),
    additionalAccessorialsSelector,
    (state, props) => props.isPickup,
    listSelector,
    (baseAccessorials = [], additionalAccessorials = [], isPickup, list) => {
        const accessorials = baseAccessorials.concat(additionalAccessorials)
        const dockNeeded = !mutuallyExclusiveAccessorials.some(el =>
            accessorials.map(el => el.value).includes(el)
        )
        const accessorialLabels = accessorials.map(item =>
            getAccessorialText(item, list)
        )
        return dockNeeded
            ? [
                  isPickup ? (
                      <FormattedMessage
                          id="accessorials.dockPickup"
                          defaultMessage="Dock Pickup"
                      />
                  ) : (
                      <FormattedMessage
                          id="accessorials.dockDelivery"
                          defaultMessage="Dock Delivery"
                      />
                  ),
                  ...accessorialLabels,
              ]
            : accessorialLabels
    }
)((state, props) => [props.shipmentId, !!props.isPickup].join() || "")

const mapStateToProps = (state, props) => ({
    accessorials: accessorialsSelector(state, props),
})

export const AccessorialsDisplay = withStyles(styles)(
    connect(mapStateToProps)(AccessorialsDisplayFragment)
)
