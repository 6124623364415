import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { reduxForm, Field } from "redux-form"
import GlobalSpinner from "../util/spinner"
import { connect } from "react-redux"
import { Modal, Snackbar, Typography, Grid, Button } from "@material-ui/core"
import ContactForm from "./contact/contactForm"
import ContactItem from "./contact/contactItem"
import { ImportCSVModal } from "./import-contacts-modal"
import FormField from "../form/form-field"
import { requestContactAddresses, selectContact } from "../../actions/address"
import { filterContacts } from "../dashboard/search/util"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    contact__list: {
        padding: "5px",
        "overflow-y": "scroll",
        "max-height": "708px",
        "margin-top": "10px",
        width: "auto",
    },
    import__button: {
        display: "flex",
        "justify-content": "flex-end",
        top: "80px",
        position: "absolute",
    },
    contacts__container: {
        margin: "4% 3%",
    },
    contacts__left__container: {
        "margin-top": "10px",
        "min-width": "285px",
    },
    contacts__right__container: {
        "margin-top": "10px",
    },
    modal__container: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
    },
})

export class AddressBookPresentation extends Component {
    state = {
        importModalOpen: false,
        sbOpen: false,
        sbVariant: "",
        sbMessage: "",
        isLoaded: false,
    }

    async componentDidMount() {
        await this.props.requestContactAddresses()
        this.setState({ isLoaded: true })
    }

    componentWillReceiveProps(newProps) {
        if (
            (!this.props.isLoaded && newProps.isLoaded) ||
            newProps.contactId !== this.props.contactId
        ) {
            this.props.selectContact(newProps.contactId)
        }
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ sbOpen: false })
    }

    handleModalOpen = () => {
        this.setState({ importModalOpen: true })
    }

    handleModalClose = () => {
        this.setState({ importModalOpen: false })
    }

    render() {
        const { addresses, selected, classes } = this.props
        const { isLoaded } = this.state
        return !isLoaded ? (
            <GlobalSpinner />
        ) : (
            <div className={classes.contacts__container}>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={1000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <span>
                                <Typography>{this.state.sbMessage}</Typography>
                            </span>
                        }
                    />
                </Snackbar>
                <Modal
                    open={this.state.importModalOpen}
                    onClose={this.handleModalClose}
                >
                    <div className={classes.modal__container}>
                        <ImportCSVModal onCloseClick={this.handleModalClose} />
                    </div>
                </Modal>
                <div className={classes.import__button}>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={this.handleModalOpen}
                    >
                        <FormattedMessage
                            id="contacts__importContacts"
                            defaultMessage="Import Contacts"
                        />
                    </Button>
                </div>
                <Grid container wrap="nowrap">
                    {addresses && (
                        <Grid
                            item
                            container
                            xs={5}
                            direction="column"
                            className={classes.contacts__left__container}
                        >
                            <form>
                                <Field
                                    component={FormField}
                                    name="contactSearch"
                                    label={
                                        <FormattedMessage
                                            id="contacts__search"
                                            defaultMessage="Search"
                                        />
                                    }
                                    type="text"
                                />
                            </form>
                            <div className={classes.contact__list}>
                                {addresses.map((item, index) => (
                                    <ContactItem
                                        {...item}
                                        index={index}
                                        selected={
                                            item.contact.id === selected.id
                                        }
                                        key={item.contact.id}
                                        id={item.contact.id}
                                    />
                                ))}
                            </div>
                        </Grid>
                    )}
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.contacts__right__container}
                    >
                        <ContactForm />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

AddressBookPresentation.propTypes = {
    addresses: PropTypes.array.isRequired,
    selected: PropTypes.object,
    contactId: PropTypes.string,
    selectContact: PropTypes.func.isRequired,
    requestContactAddresses: PropTypes.func.isRequired,
    classes: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
}

AddressBookPresentation.defaultProps = {
    selected: { contact: {} },
    contactId: "",
    classes: {},
}

const mapStateToProps = (state, props) => ({
    addresses: filterContacts(
        ((state.form.searchContact || {}).values || {}).contactSearch,
        state.addressBook.addresses,
        { contactName: true, companyName: true, filter: true }
    ),
    contactId: props.computedMatch.params.contactId,
    selected: state.addressBook.selected,
})

const mapDispatchToProps = dispatch => ({
    selectContact: contactId => dispatch(selectContact(contactId)),
    requestContactAddresses: () => dispatch(requestContactAddresses()),
})

export const AddressBook = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "searchContact", // a unique identifier for this form
            enableReinitialize: true,
        })(AddressBookPresentation)
    )
)
