import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage, injectIntl } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import { isGuaranteedQuote } from "../../../quote/card"
import { calcDeliveryTime, CarrierLogo } from "../../../util"
import { currencyLabel } from "../../../util/currencyLabel"
import moment from "moment"
import { connect } from "react-redux"

const useStyles = makeStyles({
    ratesSummaryContainer: {
        padding: "0 15%",
    },
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
    header: {
        paddingBottom: "15px",
    },
    section: {
        paddingTop: "15px",
    },
    serviceLevel_text: {
        color: "green",
    },
})

export const determineFXFDLabel = code => {
    switch (code) {
        case "BASIC":
            return (
                <FormattedMessage
                    id="rateResults.freightDirect__basic__title"
                    defaultMessage="Basic"
                />
            )

        case "BASIC_APPT":
            return (
                <FormattedMessage
                    id="rateResults.freightDirect__basicPlus__title"
                    defaultMessage="Basic by Appointment"
                />
            )

        case "STANDARD":
            return (
                <FormattedMessage
                    id="rateResults.freightDirect__standard__title"
                    defaultMessage="Standard"
                />
            )

        case "PREMIUM":
            return (
                <FormattedMessage
                    id="rateResults.freightDirect__premium__title"
                    defaultMessage="Premium"
                />
            )
        case "BASIC_PICKUP":
            return (
                <FormattedMessage
                    id="rateResults.freightDirectReturns__basicPickup__title"
                    defaultMessage="Basic Pickup"
                />
            )
        case "BASIC_PICKUP_AND_DESTROY":
            return (
                <FormattedMessage
                    id="rateResults.freightDirectReturns__pickupDestroy__title"
                    defaultMessage="Pickup & Destroy"
                />
            )
        default:
            return ""
    }
}

export const RateFreightDirectServiceLevel = ({
    rate,
    isFreightDirect,
    isFreightDirectReturns,
    ...props
}) =>
    isFreightDirect || isFreightDirectReturns ? (
        <Typography {...props}>
            {rate?.serviceLevel?.description ??
                determineFXFDLabel(rate?.serviceLevel?.code)}
        </Typography>
    ) : null

export const RateDeliveryDate = ({ rate, ...props }) =>
    !!rate?.isNonRateable ? (
        <Typography variant="subheading" {...props}>
            <FormattedMessage
                id="orderDetails.header__unknownDeliveryDate"
                defaultMessage="Call carrier for delivery date"
            />
        </Typography>
    ) : (
        <Typography {...props}>
            {moment(rate?.deliveryDateTime).format("dddd, MMM Do")}
        </Typography>
    )

export const RateDeliveryTime = ({ rate, isFreightDirect, ...props }) =>
    rate?.isNonRateable || isFreightDirect ? null : (
        <Typography {...props}>
            {calcDeliveryTime(rate.deliveryDateTime)}
        </Typography>
    )

const RateGuaranteedPresentation = ({
    rate,
    canViewRateAmounts,
    guaranteedList,
    ...props
}) => {
    const classes = useStyles()
    const guaranteed = isGuaranteedQuote(
        rate?.serviceLevel?.code,
        guaranteedList
    )

    if (!guaranteed && canViewRateAmounts) {
        return null
    }

    return guaranteed ? (
        <Typography
            variant="caption"
            className={classes.serviceLevel_text}
            {...props}
        >
            <FormattedMessage
                id="generalTerms__guaranteed"
                defaultMessage="Guaranteed"
            />
        </Typography>
    ) : (
        <Typography variant="caption" {...props}>
            <FormattedMessage
                id="generalTerms__standardService"
                defaultMessage="Standard Service"
            />
        </Typography>
    )
}

const mapStateToProps = state => ({
    guaranteedList: state.accessorials.list.guaranteed,
})

export const RateGuaranteed = connect(mapStateToProps)(
    RateGuaranteedPresentation
)

export const rateTotalAmount = (rate, canViewRateAmounts) => {
    if (!canViewRateAmounts || !rate.showRateAmounts || rate.isNonRateable) {
        return null
    }

    return rate?.rateQuoteDetail?.totalInPreferredCurrency ??
        rate?.rateQuoteDetail?.total
        ? currencyLabel(
              rate?.preferredCurrencyCode,
              rate?.rateQuoteDetail?.totalInPreferredCurrency
                  ? rate?.rateQuoteDetail?.totalInPreferredCurrency
                  : rate?.rateQuoteDetail?.total
          )
        : null
}

const RatesSummary = ({ formValues, handleEdit, canViewRateAmounts }) => {
    const classes = useStyles()
    const {
        selectedRate = { rateQuoteDetail: {}, serviceLevel: {} },
        isFreightDirect,
        isFreightDirectReturns,
    } = formValues

    return (
        <Grid item container className={classes.ratesSummaryContainer}>
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignRates="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.ratesSummary__title"
                            defaultMessage="Selected Rate"
                        />
                    </Typography>
                    <Button
                        id="editRate"
                        color="primary"
                        variant="outlined"
                        onClick={() => handleEdit(4)}
                    >
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    </Button>
                </Grid>
                <Grid item container alignItems="center" alignContent="center">
                    <Grid
                        item
                        container
                        xs={4}
                        alignItems="center"
                        justify="center"
                    >
                        <CarrierLogo
                            carrierCode={selectedRate?.carrierCode}
                            mode={selectedRate?.mode}
                            serviceLevel={selectedRate?.serviceLevel}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        direction="column"
                        justify="center"
                    >
                        <RateFreightDirectServiceLevel
                            rate={selectedRate}
                            isFreightDirect={isFreightDirect}
                            isFreightDirectReturns={isFreightDirectReturns}
                            variant="subheading"
                        />
                        <RateDeliveryDate rate={selectedRate} />
                        <RateDeliveryTime
                            rate={selectedRate}
                            isFreightDirect={isFreightDirect}
                        />
                        <RateGuaranteed
                            rate={selectedRate}
                            canViewRateAmounts={canViewRateAmounts}
                        />
                        {selectedRate?.mode === "VOLUME_LTL" &&
                            selectedRate?.capacityProviderQuoteNumber && (
                                <Typography variant="caption">
                                    {`ID#: ${selectedRate?.capacityProviderQuoteNumber}`}
                                </Typography>
                            )}
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Typography variant="subheading" color="secondary">
                            {rateTotalAmount(selectedRate, canViewRateAmounts)}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default injectIntl(RatesSummary)
