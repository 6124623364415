export const tableStyles = theme => ({
    root: {},
    paper: {
        overflowX: "auto",
    },
    table: {},
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    discount__container: {
        padding: "1% 6%",
    },
    upload__button: {
        marginBottom: 10,
    },
    modal__container: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
    },
    fixed__column: {
        minWidth: 175,
        paddingRight: 15,
    },
    reset__button: {
        height: "35px",
        margin: "10px 0 0 5px",
    },
    input__grid: {
        height: "90px",
        paddingBottom: "10px",
    },
})
