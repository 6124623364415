import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Search from "@material-ui/icons/Search"
import InputAdornment from "@material-ui/core/InputAdornment"
import Grid from "@material-ui/core/Grid"
import { Field, reduxForm, formValues } from "redux-form"
import FormField from "../form/form-field"
import Popper from "@material-ui/core/Popper"
import { searchContacts } from "../../actions/contact"
import { searchQuotes } from "../../actions/quote-request"
import { searchShipments } from "../../actions/track"
import Result from "./result"

const styles = theme => ({
    popper: {
        zIndex: 1,
    },
    search_text: {
        color: "white",
        "background-color": theme.palette.primary.light,
        "border-radius": "20px",
        marginTop: "auto !important",
    },
    search_icon: {
        paddingLeft: "5px",
    },
})

class GlobalSearch extends React.Component {
    constructor(props) {
        super(props)
        this.clickedInside = false
        this.clickTimeout = null
        this.state = { showOverlay: true }
    }

    componentWillUnmount = () => {
        clearTimeout(this.clickTimeout)
    }

    handleContainerMouseDown() {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputFocus(e) {
        this.setState({ anchorEl: e.currentTarget, showOverlay: true })
    }

    handleInputBlur(e) {
        const showOverlay = this.clickedInside

        // Force input's focus if blur event was caused by clicking on dropdown
        if (!showOverlay) {
            this.setState({ showOverlay: false, anchorEl: null })
        }
    }

    handleOnSelect() {
        this.setState({ showOverlay: false, anchorEl: null })
    }

    render() {
        const {
            classes,
            fieldChange,
            searchInProgress,
            searchValue,
        } = this.props
        const { anchorEl, showOverlay } = this.state

        const inputLabelProps = {
            shrink: true,
            FormLabelClasses: {
                root: classes.input__label,
                focused: classes.input__label,
            },
        }

        const inputHelperProps = {
            classes: {
                root: classes.input__helper,
                error: classes.input__helper,
            },
        }

        const popperOpen =
            showOverlay && !!(searchValue && searchValue.length >= 3)

        return (
            <Grid item container justify="flex-end">
                <Field
                    component={FormField}
                    name={"search"}
                    InputLabelProps={inputLabelProps}
                    InputHelperProps={inputHelperProps}
                    onChange={e => fieldChange(e.target.value)}
                    onFocus={e => this.handleInputFocus(e)}
                    onBlur={e => this.handleInputBlur(e)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                className={classes.search_icon}
                            >
                                <Search />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                    className={classes.search_text}
                />
                <Popper
                    onMouseDown={() => this.handleContainerMouseDown()}
                    open={popperOpen}
                    anchorEl={anchorEl}
                    placement="bottom-end"
                    disablePortal
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    className={classes.popper}
                >
                    <Result
                        searchInProgress={searchInProgress}
                        addressesNeeded
                        term={this.props.searchValue}
                        onSelect={() => this.handleOnSelect()}
                    />
                </Popper>
            </Grid>
        )
    }
}

GlobalSearch.propTypes = {
    classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
    const contactSearchInProgress = state.contact.searchInProgress
    const quoteSearchInProgress = state.quotes.list.searchInProgress
    const shipmentSearchInProgress = state.shipment.searchInProgress
    return {
        searchInProgress:
            contactSearchInProgress ||
            quoteSearchInProgress ||
            shipmentSearchInProgress,
    }
}

const mapDispatchToProps = dispatch => ({
    fieldChange: value => {
        dispatch(searchContacts(value, "dashboard"))
        dispatch(searchShipments(value))
        dispatch(searchQuotes(value))
    },
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({ form: "search", enableReinitialize: true })(
            formValues({ searchValue: "search" })(GlobalSearch)
        )
    )
)
