import { defineMessages } from "react-intl"

export const packageTypesMessages = defineMessages({
    PLT4840: {
        id: "items.packageTypes__PLT4840",
        defaultMessage: "Pallet 48x40",
    },
    PLT4848: {
        id: "items.packageTypes__PLT4848",
        defaultMessage: "Pallet 48x48",
    },
    PLT: {
        id: "items.packageTypes__PLT",
        defaultMessage: "Pallet",
    },
    SKID: {
        id: "items.packageTypes__SKID",
        defaultMessage: "Skid",
    },
    BAG: {
        id: "items.packageTypes__BAG",
        defaultMessage: "Bag",
    },
    BOX: {
        id: "items.packageTypes__BOX",
        defaultMessage: "Box",
    },
    BUNDLE: {
        id: "items.packageTypes__BUNDLE",
        defaultMessage: "Bundle",
    },
    COIL: {
        id: "items.packageTypes__COIL",
        defaultMessage: "Coil",
    },
    CRATE: {
        id: "items.packageTypes__CRATE",
        defaultMessage: "Crate",
    },
    CYLINDER: {
        id: "items.packageTypes__CYLINDER",
        defaultMessage: "Cylinder",
    },
    DRUM: {
        id: "items.packageTypes__DRUM",
        defaultMessage: "Drum",
    },
    PAIL: {
        id: "items.packageTypes__PAIL",
        defaultMessage: "Pail",
    },
    REEL: {
        id: "items.packageTypes__REEL",
        defaultMessage: "Reel",
    },
    ROLL: {
        id: "items.packageTypes__ROLL",
        defaultMessage: "Roll",
    },
    TUBE: {
        id: "items.packageTypes__TUBE",
        defaultMessage: "Tube & Pipe",
    },
    FBXP: {
        id: "items.packageTypes__Standard",
        defaultMessage: "Standard 38",
    },
    FBXI: {
        id: "items.packageTypes__Small",
        defaultMessage: "Small 28",
    },
})

export const packageTypeOptions = [
    {
        value: "PLT4840",
        label: packageTypesMessages.PLT4840,
        dimensions: { length: 48, width: 40 },
    },
    {
        value: "PLT4848",
        label: packageTypesMessages.PLT4848,
        dimensions: { length: 48, width: 48 },
    },
    { value: "PLT", label: packageTypesMessages.PLT },
    { value: "SKID", label: packageTypesMessages.SKID },
    { value: "BAG", label: packageTypesMessages.BAG },
    { value: "BOX", label: packageTypesMessages.BOX },
    { value: "BUNDLE", label: packageTypesMessages.BUNDLE },
    { value: "COIL", label: packageTypesMessages.COIL },
    { value: "CRATE", label: packageTypesMessages.CRATE },
    { value: "CYLINDER", label: packageTypesMessages.CYLINDER },
    { value: "DRUM", label: packageTypesMessages.DRUM },
    { value: "PAIL", label: packageTypesMessages.PAIL },
    { value: "REEL", label: packageTypesMessages.REEL },
    { value: "ROLL", label: packageTypesMessages.ROLL },
    { value: "TUBE", label: packageTypesMessages.TUBE },
]

export const packageTypeValues = packageTypeOptions.map(item => item.value)

export const freightBoxPackageTypeOptions = [
    { value: "FBXP", label: packageTypesMessages.FBXP },
    { value: "FBXI", label: packageTypesMessages.FBXI },
]

export const allPackageTypeOptions = [
    {
        value: "PLT4840",
        label: packageTypesMessages.PLT4840,
        dimensions: { length: 48, width: 40 },
    },
    {
        value: "PLT4848",
        label: packageTypesMessages.PLT4848,
        dimensions: { length: 48, width: 48 },
    },
    { value: "PLT", label: packageTypesMessages.PLT },
    { value: "SKID", label: packageTypesMessages.SKID },
    { value: "BAG", label: packageTypesMessages.BAG },
    { value: "BOX", label: packageTypesMessages.BOX },
    { value: "BUNDLE", label: packageTypesMessages.BUNDLE },
    { value: "COIL", label: packageTypesMessages.COIL },
    { value: "CRATE", label: packageTypesMessages.CRATE },
    { value: "CYLINDER", label: packageTypesMessages.CYLINDER },
    { value: "DRUM", label: packageTypesMessages.DRUM },
    { value: "PAIL", label: packageTypesMessages.PAIL },
    { value: "REEL", label: packageTypesMessages.REEL },
    { value: "ROLL", label: packageTypesMessages.ROLL },
    { value: "TUBE", label: packageTypesMessages.TUBE },
    { value: "FBXP", label: packageTypesMessages.FBXP },
    { value: "FBXI", label: packageTypesMessages.FBXI },
]

export const provideAllPackageTypes = intl => {
    return allPackageTypeOptions.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

export const freightBoxPackageTypeValues = freightBoxPackageTypeOptions.map(
    item => item.value
)
