import React, { useEffect, useState } from "react"
import { Element, scroller } from "react-scroll"
import ItemsSummary from "../summaryCards/ItemsSummary"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { Field, FieldArray, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import Typography from "@material-ui/core/Typography"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { BookHandlingUnits } from "../atoms/BookHandlingUnits"
import { goFetch } from "../../../../http"
import { cloneDeep, set } from "lodash"
import {
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isUSDomesticShipment,
} from "../../../../actions/validation"
import { supportedCurrencies } from "../../../../misc"
import FormSelectAutocompleteNew from "../../../form/form-select-autocomplete-new"
import { connect } from "react-redux"
import { searchItems } from "../../../../actions/item"
import { bookShipmentFormValidation } from "../index"
import { lengthUnit, weightUnit } from "../../../util/units"
import { withStyles } from "@material-ui/core"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline",
    },
    bottomContainer: {
        paddingTop: "15px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
})

const Items = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = { handlingUnits: [] },
    handleHUPanel,
    isHUOpen,
    changeField,
    getRates,
    addNewHandlingUnit,
    loadItems,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    invalid,
    trackGA,
    isLoading,
    classes,
}) => {
    const [shipmentWeight, setShipmentWeight] = useState(0)

    const {
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        preferredSystemOfMeasurement,
        handlingUnits = [],
        origin,
        destination,
    } = formValues

    useEffect(() => {
        if (currentStep === 3) {
            loadItems()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const isIntraMexico =
        origin?.shippingAddress?.address?.country === "MX" &&
        destination?.shippingAddress?.address?.country === "MX"

    useEffect(() => {
        if (currentStep === 3) {
            scroller.scrollTo("itemsTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (currentStep === 3) {
            setShipmentWeight(getShipmentWeight())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits, editMode])

    useEffect(() => {
        adjustEstimatedLinearFeet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits.length])

    const adjustEstimatedLinearFeet = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)
        if (fieldName === "doNotStack") {
            set(adjustedFormValues, `${prefix}.stackable`, !value)
        }

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const adjustEstimatedLinearFeetOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const calculateEstimatedLinearFeet = async handlingUnits => {
        const { preferredSystemOfMeasurement, origin, destination } = formValues

        try {
            const {
                data: {
                    totalLinearFeet,
                    capLoadTotalLinearFeet,
                    volumeTotalLinearFeet,
                    isOverLTLLimit,
                },
            } = await goFetch(
                `/quotes/linearfeet`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        handlingUnits,
                        preferredSystemOfMeasurement,
                        originCountry:
                            origin?.shippingAddress?.address?.country,
                        destinationCountry:
                            destination?.shippingAddress?.address?.country,
                    },
                },
                true
            )
            changeField("totalLinearFeet", totalLinearFeet)
            changeField("capLoadTotalLinearFeet", capLoadTotalLinearFeet)
            changeField("volumeTotalLinearFeet", volumeTotalLinearFeet)
            changeField("userProvidedTotalLinearFeet", false)
            changeField("isOverLTLLimit", isOverLTLLimit)
        } catch (error) {
            console.error(error)
        }
    }

    const getShipmentWeight = () => {
        const weight = formValues?.handlingUnits.reduce((acc, hu) => {
            if (hu.isMultiClass) {
                const result = hu?.items.reduce((acc, item) => {
                    if (item.weight) {
                        return (acc += Number(item?.weight))
                    } else return acc
                }, 0)

                const finalResult = result * hu.count
                return acc + finalResult
            } else {
                const finalResult = parseInt(hu?.items[0].weight ?? 0, 10)

                if (hu.isIndividualHUWeight) {
                    return acc + (finalResult ? finalResult * hu?.count : 0)
                } else {
                    return acc + (finalResult ? finalResult : 0)
                }
            }
        }, 0)

        return weight
    }

    const isIntl = isInternationalShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country
    )

    const isUSDomestic = isUSDomesticShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country
    )

    if (currentStep === 3) {
        return (
            <Grid item container>
                <Grid item container>
                    <Grid item container xs={4} />
                    <Grid item container xs={4} justify="center">
                        <Typography variant="title">
                            <FormattedMessage
                                id="bookShipment.items__title"
                                defaultMessage="Line Items"
                            />
                        </Typography>
                        <Element name="itemsTitle" />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        justify="flex-end"
                        alignItems="flex-end"
                    >
                        <a
                            rel="noopener noreferrer"
                            href="https://www.fedex.com/en-us/shipping/freight-services/class-calculator.html"
                            target="_blank"
                        >
                            <Typography
                                variant="caption"
                                className={classes.freightClass__helper}
                            >
                                <FormattedMessage
                                    id="items__freightClassLink"
                                    defaultMessage="What's my freight class?"
                                />
                            </Typography>
                        </a>
                    </Grid>
                </Grid>
                {!isUSDomestic && !isFreightBox && (
                    <Grid item container className={classes.section}>
                        <Grid item container xs={6}>
                            <Field
                                component={FormSelectAutocompleteNew}
                                name="preferredCurrencyCode"
                                label={
                                    <FormattedMessage
                                        id="bookShipment.preferredCurrency"
                                        defaultMessage="Preferred Currency"
                                    />
                                }
                                options={supportedCurrencies}
                            />
                        </Grid>
                        <Grid item container xs={6}>
                            <Field
                                component={FormSelectAutocompleteNew}
                                name="preferredSystemOfMeasurement"
                                label={
                                    <FormattedMessage
                                        id="bookShipment.preferredSystemOfMeasurement"
                                        defaultMessage="Preferred System of Measurement"
                                    />
                                }
                                options={[
                                    {
                                        value: "IMPERIAL",
                                        label: "lbs/in",
                                    },
                                    {
                                        value: "METRIC",
                                        label: "kg/cm",
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item container className={classes.section}>
                    <FieldArray
                        name="handlingUnits"
                        component={BookHandlingUnits}
                        handlePanel={handleHUPanel}
                        isOpen={isHUOpen}
                        isFreightDirect={isFreightDirect}
                        isFreightDirectReturns={isFreightDirectReturns}
                        isFreightBox={isFreightBox}
                        formValues={formValues}
                        changeField={changeField}
                        weightUnit={weightUnit(preferredSystemOfMeasurement)}
                        lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                        adjustEstimatedLinearFeet={adjustEstimatedLinearFeet}
                        adjustEstimatedLinearFeetOnSearchChange={
                            adjustEstimatedLinearFeetOnSearchChange
                        }
                        isIntl={isIntl}
                        isUSDomesticOffshoreWithItemCustoms={
                            isUSDomesticOffshoreWithItemCustoms
                        }
                        isIntraMexico={isIntraMexico}
                        favoriteItems={favoriteItems}
                        itemsIsLoaded={itemsIsLoaded}
                        doSearch={doSearch}
                        trackGA={trackGA}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className={classes.bottomContainer}
                    alignItems="center"
                >
                    <Grid item container xs={4}>
                        <FieldArray
                            name="handlingUnits"
                            component={({ fields }) => (
                                <Button
                                    id={"addLineItem"}
                                    className={classes.button__add_item}
                                    color="primary"
                                    type="outlined"
                                    onClick={() => {
                                        addNewHandlingUnit(fields)
                                    }}
                                >
                                    +{" "}
                                    <FormattedMessage
                                        id="bookShipment.addLineItem"
                                        defaultMessage="Add Line Item"
                                    />
                                </Button>
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        justify="center"
                        alignItems="center"
                    >
                        <Typography
                            id="shipmentWeight"
                            align="center"
                            variant="subheading"
                        >
                            <FormattedMessage
                                id="getRates.totalShipmentWeight"
                                defaultMessage="TOTAL SHIPMENT WEIGHT: {shipmentWeight} {weightUnit}"
                                values={{
                                    shipmentWeight,
                                    weightUnit: weightUnit(
                                        preferredSystemOfMeasurement
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item container xs={2} justify="flex-end">
                        {!isFreightDirect && !isFreightDirectReturns && (
                            <Field
                                component={FormField}
                                name="totalLinearFeet"
                                label={
                                    <FormattedMessage
                                        id="getRates.estLinearFeet"
                                        defaultMessage="Est. Linear Feet"
                                    />
                                }
                                required
                                onChange={() => {
                                    trackGA(
                                        "Book Shipment Flow",
                                        "User Provided Total Linear Feet"
                                    )
                                    changeField(
                                        "userProvidedTotalLinearFeet",
                                        true
                                    )
                                }}
                            />
                        )}
                    </Grid>
                </Grid>

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            getRates(true)
                        }}
                        invalid={invalid || handlingUnits.length === 0}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidGetRatesButton => {
                            if (isInvalidGetRatesButton) {
                                handleComplete(isInvalidGetRatesButton)
                            } else {
                                getRates(false)
                            }
                        }}
                        isItemsStep
                        invalid={invalid || handlingUnits.length === 0}
                        isLoading={isLoading}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 3) {
        return (
            <ItemsSummary
                handleEdit={handleEdit}
                formValues={formValues}
                weightUnit={weightUnit(preferredSystemOfMeasurement)}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    doSearch: (value, origin, originAddress) =>
        dispatch(searchItems(value, origin, originAddress)),
})

const mapStateToProps = state => {
    return {
        itemsIsLoaded: state.item.isLoaded,
        favoriteItems: (state.item.elements ?? []).slice(1, 51),
    }
}

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            // a unique name for the form
            form: "bookShipment",
            validate: bookShipmentFormValidation,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
        })(Items)
    )
)
