import React from "react"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import * as sortFunctions from "../../util/sort-functions"
import { get } from "lodash"
import { Typography } from "@material-ui/core"

const DiscountsTableBody = ({
    discountsToMap,
    order,
    orderBy,
    page,
    rowsPerPage,
    classes,
    isSearch,
}) => {
    return (
        <TableBody>
            {discountsToMap.length !== 0 &&
                sortFunctions
                    .stableSort(
                        discountsToMap,
                        sortFunctions.getComparator(order, orderBy)
                    )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`
                        return (
                            <TableRow tabIndex={-1} key={row.accountNumber}>
                                <TableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                    className={classes.fixed__column}
                                >
                                    {row.accountNumber}
                                </TableCell>
                                <TableCell align="left">
                                    {get(row, "accountType", "Regular")}
                                </TableCell>
                                <TableCell align="right">
                                    {row.basicFee
                                        ? row.basicFee + "$"
                                        : row.basicDiscount + "%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.basicApptFee
                                        ? row.basicApptFee + "$"
                                        : row.basicApptDiscount + "%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.standardFee
                                        ? row.standardFee + "$"
                                        : row.standardDiscount + "%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.premiumFee
                                        ? row.premiumFee + "$"
                                        : row.premiumDiscount + "%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.basicPickupFee
                                        ? row.basicPickupFee + "$"
                                        : row.basicPickupDiscount
                                        ? row.basicPickupDiscount + "%"
                                        : "0%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.basicApptPickupFee
                                        ? row.basicApptPickupFee + "$"
                                        : row.basicApptPickupDiscount
                                        ? row.basicApptPickupDiscount + "%"
                                        : "0%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.standardPickupFee
                                        ? row.standardPickupFee + "$"
                                        : row.standardPickupDiscount
                                        ? row.standardPickupDiscount + "%"
                                        : "0%"}
                                </TableCell>
                                <TableCell align="right">
                                    {row.premiumPickupFee
                                        ? row.premiumPickupFee + "$"
                                        : row.premiumPickupDiscount
                                        ? row.premiumPickupDiscount + "%"
                                        : "0%"}
                                </TableCell>
                            </TableRow>
                        )
                    })}
            {!discountsToMap.length && isSearch && (
                <TableRow>
                    <TableCell align="left" variant="footer">
                        <Typography color="secondary">No results</Typography>
                    </TableCell>
                </TableRow>
            )}
        </TableBody>
    )
}

export default DiscountsTableBody
