import React, { Component } from "react"
import { connect } from "react-redux"
import { Field, FormSection, getFormValues } from "redux-form"
import { Grid, Button, withStyles, Typography } from "@material-ui/core"
import { get, cloneDeep } from "lodash"
import FormField from "../../form/form-field"
import FormZipCode from "../../form/form-zip-code"
import { generateTimeList } from "../../book/pickup/form"
import LocationBillingInformation from "./billing-information"
import {
    updateUserLocation,
    removeUserLocation,
    manageLocationCarrier,
    updateUserOnboardingDNA,
    updateUserDefaultLocation,
    updateCustomLocationPreferences,
    trackGAEventNew,
} from "../../../actions/user"
import { requestPostalCode } from "../../../actions/postal-code"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import Permissions from "./permissions"
import { UserManagement } from "./userManagement"
import FormSwitch from "../../form/form-switch"
import normalizePhone from "../../util/normalizePhone"
import Carriers from "./carriers"
import { FormattedMessage } from "react-intl"
import { supportedCountryCodes } from "../../../misc"
import formSelect from "../../form/form-select"
import { CustomsDocumentsCard } from "./customsProfile/CustomsDocumentsCard"
import Brokers from "./customsProfile/Brokers"
import Accessorials from "../../redesign/bookShipment/atoms/Accessorials"
import {
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
    sortByPriority,
} from "../../quotesPage/accessorials"
import Spinner from "../../util/spinner"

const styles = theme => ({
    location__container: {},
    checkbox__container: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    textField: {
        width: "50%",
    },
    location__row: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
            color: "white",
        },
    },
    location__row__last: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        borderBottom: "1px solid #F2F2F2",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
            color: "white",
        },
    },
    location__row__noHover: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
    },
    location__row__last__noHover: {
        padding: "8px 4px",
        borderTop: "1px solid #F2F2F2",
        borderBottom: "1px solid #F2F2F2",
    },
    location__card__header: {
        paddingBottom: "8px",
    },
    location__section: {
        padding: "1%",
    },
    location__card: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
        minHeight: "401px",
    },
    location__card__wrapper: {
        padding: "20px 20px",
        minHeight: "401px",
    },
    location__card__top: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "20px 20px",
    },
    location__card__title: {
        borderRadius: "4px",
        backgroundColor: "#fff",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
        padding: "10px 20px",
    },
    users__container: {
        maxHeight: "308px",
        overflowY: "scroll",
    },
    user__row: {
        padding: "5px 5px",
        margin: "2px 0",
        backgroundColor: "#D4D4D4",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: "white",
        },
        minHeight: "40px",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    action__button: {
        marginLeft: "15px",
    },
    textField_right: {
        width: "100%",
    },
    user__status__label: {
        paddingRight: "10px",
    },
    form__wrapper: {
        width: "100%",
    },
    controls__container: {
        height: "100%",
    },
    brokerCard: {
        height: "100%",
    },
    accessorialContainer: {
        padding: "3px",
    },
})

let LocationAddress = ({ cities, classes, index, city, country }) => (
    <Grid item container>
        <Grid item container xs={4}>
            <Field
                name="shippingAddress.address.street1"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__address1"
                        defaultMessage="Address 1"
                    />,
                ]}
                maxLength="30"
                className={classes.textField_right}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name="shippingAddress.address.postalCode"
                component={FormZipCode}
                cityProp={city}
                country={country}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__zipPostalCode"
                        defaultMessage="Zip/Postal Code"
                    />,
                ]}
                fieldPrefix="shippingAddress"
                inputProps={{ maxLength: "7" }}
                className={classes.textField_right}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.address.city"
                component={FormSelectAutocomplete}
                customValue
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__city"
                        defaultMessage="City"
                    />,
                ]}
                options={[
                    { value: city, label: city },
                    ...cities.map(value => ({
                        value,
                        label: value,
                    })),
                ]}
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.name"
                label={[
                    "* ",
                    <FormattedMessage
                        id="locations.shippingInformation__shipperName"
                        defaultMessage="Shipper Name"
                    />,
                ]}
                className={classes.textField__right}
                component={FormField}
            />
        </Grid>
        <Grid item container xs={4}>
            <Field
                name="shippingAddress.address.street2"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        id="generalTerms__address2"
                        defaultMessage="Address 2"
                    />
                }
                maxLength="30"
                className={classes.textField_right}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name="shippingAddress.address.state"
                component={FormField}
                type="text"
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__stateProvince"
                        defaultMessage="State/Province"
                    />,
                ]}
                disabled
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="shippingAddress.address.country"
                component={formSelect}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__country"
                        defaultMessage="Country"
                    />,
                ]}
                options={supportedCountryCodes}
                maxLength="30"
                required
            />
        </Grid>
        <Grid item container xs={3}>
            <Field
                name="cpgName"
                component={FormField}
                type="text"
                label={
                    <FormattedMessage
                        id="locations__nickname"
                        defaultMessage="Nickname"
                    />
                }
                className={classes.textField_right}
            />
        </Grid>
    </Grid>
)

const locationAddressMapStateToProps = (state, props) => {
    return {
        cities: get(
            state,
            `postalCode[${props.country}-${props.postalCode}].cities`,
            [props.city]
        ),
    }
}

LocationAddress = connect(locationAddressMapStateToProps)(LocationAddress)

const LocationDetails = ({ classes, index, owner, country }) => (
    <Grid item container>
        <Grid item container>
            <Typography className={classes.title} variant="title">
                <FormattedMessage
                    id="locations.pickUpInformation__title"
                    defaultMessage="Pick Up Information"
                />
            </Typography>
        </Grid>
        <Grid item container xs={6} id="readyTime">
            <Field
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__readyTime"
                        defaultMessage="Ready Time"
                    />,
                ]}
                name="readyTime"
                options={generateTimeList()}
                component={FormSelectAutocomplete}
                disabled={!owner}
            />
        </Grid>
        <Grid item container xs={6} id="closeTime">
            <Field
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__closeTime"
                        defaultMessage="Close Time"
                    />,
                ]}
                name="closeTime"
                options={generateTimeList()}
                component={FormSelectAutocomplete}
                disabled={!owner}
            />
        </Grid>
        <Grid item container>
            <Field
                id="pickupContact__name"
                name="pickupContact.name"
                component={FormField}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms.pickUpInformation__pickupName"
                        defaultMessage="Pickup Name"
                    />,
                ]}
                className={classes.textField__right}
                disabled={!owner}
            />
        </Grid>
        <Grid item container>
            <Field
                name="pickupContact.email.email_address"
                id="pickupContact__email"
                component={FormField}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__emailAddress"
                        defaultMessage="Email Address"
                    />,
                ]}
                className={classes.textField__right}
                disabled={!owner}
            />
        </Grid>
        <Grid item container xs={8}>
            <Field
                name="pickupContact.phone.phone_number"
                id="pickupContact__phone"
                component={FormField}
                label={[
                    "* ",
                    <FormattedMessage
                        id="generalTerms__phoneNumber"
                        defaultMessage="Phone Number"
                    />,
                ]}
                normalize={normalizePhone(country)}
                disabled={!owner}
            />
        </Grid>
        <Grid item container xs={4}>
            <Field
                name="pickupContact.phone.extension"
                id="pickupContact__extension"
                component={FormField}
                label={
                    <FormattedMessage
                        id="generalTerms__ext"
                        defaultMessage="Ext"
                    />
                }
                disabled={!owner}
            />
        </Grid>
    </Grid>
)

class LocationContainer extends Component {
    async componentDidMount() {
        await this.props?.requestPostalCode(
            this.props?.postalCode,
            this.props?.country
        )
    }

    async componentWillReceiveProps(newProps) {
        if (this.props?.location !== newProps?.location) {
            await this.props?.requestPostalCode(
                newProps?.postalCode,
                newProps?.props?.country
            )
        }
    }

    state = {
        isProcessing: false,
        mode: "",
        activeCarrier: null,
        onboardingUrl: "",
        onboardingWindow: null,
        dialogOpen: false,
    }

    enterCarrierOnboarding = async () => {
        const { onboardingWindow, onboardingUrl } = this.state
        const { updateUserOnboardingDNA, formDNA } = this.props
        if (formDNA) {
            await updateUserOnboardingDNA()
        }
        onboardingWindow.location.href = onboardingUrl
        this.handleClose()
    }

    handleClickOpen = async (mode, carrierCode, accountId) => {
        const { location, userOnboardingDNA, language } = this.props
        let onboardingWindow = null
        if (userOnboardingDNA) {
            onboardingWindow = window.open("", "_blank")
            onboardingWindow.document.write("Loading...")
        }

        await this.setState({ isProcessing: true, onboardingWindow })
        const onboardingUrl = await this.props.onCarrierManagementClick(
            location,
            carrierCode,
            accountId,
            language
        )
        await this.setState({
            isProcessing: false,
            dialogOpen: !userOnboardingDNA,
            activeCarrier: carrierCode,
            mode,
            onboardingUrl,
        })
        if (userOnboardingDNA) {
            this.enterCarrierOnboarding()
        }
    }

    onboardingModalContinue = async () => {
        const onboardingWindow = window.open("", "_blank")
        onboardingWindow.document.write("Loading...")
        await this.setState({ onboardingWindow })
        this.enterCarrierOnboarding()
    }

    handleClose = () => {
        this.setState({ dialogOpen: false })
    }

    removeLocation = async () => {
        try {
            await this.setState({ isProcessing: true })
            this.props.trackGA("Location", "Unsubscribe Button Click")
            await this.props.removeLocation(this.props.location)
            this.props.handleSnackbarOpen(
                "success",
                "Successfully Unsubscribed from Location"
            )
            this.setState({ isProcessing: false })
        } catch (error) {
            this.props.handleSnackbarOpen(
                "error",
                "Unable to Unsubscribe from Location"
            )
            this.props.trackGA("Location", "Unsubscribe Error")
            this.setState({
                isProcessing: false,
            })
        }
    }

    handleDoNotAsk = () => {}

    saveDefault = async isDefaultLocation => {
        const { startedDefault, updateUserDefaultLocation } = this.props

        if (isDefaultLocation && !startedDefault) {
            await updateUserDefaultLocation(get(this.props, "location._id"))
        } else if (!isDefaultLocation && startedDefault) {
            await updateUserDefaultLocation(null)
        }
    }

    handleSave = async values => {
        await this.setState({ isProcessing: true })
        this.props.trackGA("Location", "Update Button Click")
        try {
            await this.saveDefault(get(values, "isDefaultLocation"))
            await this.props.handleUpdate(values, this.props.index)
            await this.props.updateCustomLocationPreferences(values)
            this.props.handleSnackbarOpen("success", "Save Successful")
        } catch (err) {
            const errorMessage =
                err?.response?.message ?? err?.response?.validationErrors ?? []
            const message =
                (Array.isArray(errorMessage) && errorMessage.join("\n")) ||
                errorMessage ||
                "Unable To Save"
            this.props.handleSnackbarOpen("error", message)
            this.props.trackGA("Location", "Update Error")
            return err
        } finally {
            this.setState({ isProcessing: false })
        }
    }

    handleSuspend = async (location, value) => {
        this.setState({
            isProcessing: true,
        })
        await this.props.handleSuspend(location, value)
        this.setState({
            isProcessing: false,
        })
    }

    render() {
        const {
            location = {},
            classes,
            handleSnackbarOpen,
            carrierList,
            index,
            postalCode,
            dirty,
            handleSubmit,
            trackGA,
            country,
            pickupAccessorialsList = [],
            deliveryAccessorialsList = [],
            formValues = {},
        } = this.props
        const carriers = (location?.carriers ?? []).filter(
            it => it.carrier !== "FXFE"
        )
        const { dialogOpen } = this.state
        const locationUser = location?.users?.[0]
        const owner = locationUser?.role === "owner"
        const isRequested = !owner && locationUser?.role === "requested"
        const isSuspended = locationUser?.permissions?.suspended?.value
        const locationUserCanShip =
            locationUser?.permissions?.createShipments?.value
        const isFreightDirect = location?.locationType === "FEDEX_DIRECT"
        const isFreightBox = location?.locationType === "FEDEX_FREIGHT_BOX"

        const isQuebecShipperAccount = false

        return (
            <Grid container>
                {this.state.isProcessing && <Spinner />}
                <form className={classes.form__wrapper}>
                    <Grid container>
                        <Grid
                            item
                            container
                            className={classes.location__section}
                        >
                            <Grid
                                item
                                container
                                className={classes.location__card__title}
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Typography
                                        className={classes.location__name}
                                        color="inherit"
                                        variant="title"
                                    >
                                        {`${
                                            get(location, "cpgName")
                                                ? location.cpgName
                                                : get(
                                                      location,
                                                      "shippingAddress.name"
                                                  )
                                        }`}
                                    </Typography>
                                    {isFreightDirect && (
                                        <Typography
                                            variant="subheading"
                                            color="primary"
                                        >
                                            <FormattedMessage
                                                id="locations.users__freightDirect"
                                                defaultMessage="FedEx Freight Direct"
                                            />
                                        </Typography>
                                    )}
                                    {isFreightBox && (
                                        <Typography
                                            variant="subheading"
                                            color="primary"
                                        >
                                            <FormattedMessage
                                                id="locations.users__freightBox"
                                                defaultMessage="FedEx Freight Box"
                                            />
                                        </Typography>
                                    )}
                                    {isRequested && (
                                        <Typography
                                            variant="subheading"
                                            color="secondary"
                                        >
                                            <FormattedMessage
                                                id="locations.users__requestedAccess"
                                                defaultMessage="Requested Access"
                                            />
                                        </Typography>
                                    )}
                                    {isSuspended && (
                                        <Typography
                                            variant="subheading"
                                            color="secondary"
                                        >
                                            <FormattedMessage
                                                id="locations.users__inactive"
                                                defaultMessage="Inactive"
                                            />
                                        </Typography>
                                    )}
                                    {location?.fedexFreightAccount && (
                                        <Typography variant="caption">
                                            {isFreightDirect
                                                ? [
                                                      <FormattedMessage
                                                          id="locations__FXFDShipperAccountNumber"
                                                          defaultMessage="FXFD Shipper Account #"
                                                      />,
                                                      ": ",
                                                      location?.fedexFreightAccount,
                                                  ]
                                                : [
                                                      <FormattedMessage
                                                          id="locations__shipperAccountNumber"
                                                          defaultMessage="Shipper Account #"
                                                      />,
                                                      ": ",
                                                      location?.fedexFreightAccount,
                                                  ]}
                                        </Typography>
                                    )}
                                    {location?.fedexBillToAccount && (
                                        <Typography variant="caption">
                                            {isFreightDirect
                                                ? [
                                                      <FormattedMessage
                                                          id="locations__FXFDThirdPartyAccountNumber"
                                                          defaultMessage="FXFD Third Party Account #"
                                                      />,
                                                      ": ",
                                                      location?.fedexBillToAccount,
                                                  ]
                                                : [
                                                      <FormattedMessage
                                                          id="locations__thirdPartyAccountNumber"
                                                          defaultMessage="Third Party Account #"
                                                      />,
                                                      ": ",
                                                      location?.fedexBillToAccount,
                                                  ]}
                                        </Typography>
                                    )}
                                </Grid>
                                {!isRequested && (
                                    <Grid item>
                                        <Grid item container justify="flex-end">
                                            <Grid
                                                item
                                                container
                                                justify="flex-end"
                                                alignItems="flex-end"
                                            ></Grid>
                                            <Grid item>
                                                <Field
                                                    name={`isDefaultLocation`}
                                                    component={FormSwitch}
                                                    label={
                                                        <FormattedMessage
                                                            id="locations__setDefault"
                                                            defaultMessage="Set default"
                                                        />
                                                    }
                                                    id="setDefaultLocation"
                                                />
                                            </Grid>
                                            <Button
                                                id="locationUpdate"
                                                className={
                                                    classes.action__button
                                                }
                                                variant="contained"
                                                disabled={!dirty}
                                                color="primary"
                                                onClick={handleSubmit(
                                                    this.handleSave
                                                )}
                                            >
                                                <FormattedMessage
                                                    id="generalTerms__update"
                                                    defaultMessage="Update"
                                                />
                                            </Button>
                                            <Button
                                                id="locationUnsubscribe"
                                                className={
                                                    classes.action__button
                                                }
                                                color="secondary"
                                                variant="outlined"
                                                onClick={() =>
                                                    this.removeLocation()
                                                }
                                            >
                                                <FormattedMessage
                                                    id="locations__unsubscribe"
                                                    defaultMessage="Unsubscribe"
                                                />
                                            </Button>
                                            {!owner &&
                                                !isSuspended &&
                                                get(
                                                    location,
                                                    "users[0].permissions.suspended.editable"
                                                ) && (
                                                    <Button
                                                        className={
                                                            classes.action__button
                                                        }
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.handleSuspend(
                                                                location,
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="locations__deactivate"
                                                            defaultMessage="Deactivate"
                                                        />
                                                    </Button>
                                                )}
                                            {!owner &&
                                                isSuspended &&
                                                get(
                                                    location,
                                                    "users[0].permissions.suspended.editable"
                                                ) && (
                                                    <Button
                                                        className={
                                                            classes.action__button
                                                        }
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={() =>
                                                            this.handleSuspend(
                                                                location,
                                                                false
                                                            )
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="locations__reactivate"
                                                            defaultMessage="Reactivate"
                                                        />
                                                    </Button>
                                                )}
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {!isRequested && (
                            <Grid
                                item
                                container
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card__top}
                                >
                                    <Grid
                                        container
                                        alignContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        {owner && (
                                            <Grid item container xs={12}>
                                                <Typography
                                                    className={classes.title}
                                                    gutterBottom
                                                    variant="title"
                                                >
                                                    <FormattedMessage
                                                        id="locations.shippingInformation__title"
                                                        defaultMessage="Shipping Information"
                                                    />
                                                </Typography>
                                                <LocationAddress
                                                    classes={classes}
                                                    postalCode={postalCode}
                                                    country={country}
                                                    city={get(
                                                        location,
                                                        "shippingAddress.address.city"
                                                    )}
                                                />
                                            </Grid>
                                        )}
                                        {!isFreightDirect ? (
                                            <Grid item container>
                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    className={
                                                        classes.accessorialContainer
                                                    }
                                                >
                                                    <Accessorials
                                                        list={
                                                            pickupAccessorialsList
                                                        }
                                                        name={
                                                            "pickupAccessorials"
                                                        }
                                                        formName="location"
                                                        formValues={formValues}
                                                        pickup
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    xs={6}
                                                    className={
                                                        classes.accessorialContainer
                                                    }
                                                >
                                                    <Accessorials
                                                        list={
                                                            deliveryAccessorialsList
                                                        }
                                                        name={
                                                            "deliveryAccessorials"
                                                        }
                                                        formName="location"
                                                        formValues={formValues}
                                                        delivery
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {location.isShipperEnabled && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <LocationDetails
                                        owner={owner}
                                        classes={classes}
                                        index={this.props.index}
                                        country={country}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {owner && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.location__card}
                                >
                                    <LocationBillingInformation
                                        owner={owner}
                                        location={location}
                                        cpgCode={location.cpgCode}
                                    />
                                </Grid>
                            </Grid>
                        )}

                        <Grid item xs={6} className={classes.location__section}>
                            <Grid
                                item
                                container
                                className={classes.location__card}
                            >
                                {owner ? (
                                    <UserManagement
                                        handleSnackbarOpen={handleSnackbarOpen}
                                        classes={classes}
                                        index={index}
                                        information={location}
                                        gridSize={12}
                                        trackGA={trackGA}
                                    />
                                ) : (
                                    <FormSection name="users[0]">
                                        <Permissions
                                            classes={classes}
                                            gridSize={12}
                                            editMode
                                            checkEditable
                                            information={get(
                                                location,
                                                "users[0]",
                                                {}
                                            )}
                                            me
                                        />
                                    </FormSection>
                                )}
                            </Grid>
                        </Grid>
                        {(owner || carriers?.length > 0) &&
                            !isFreightDirect &&
                            !isQuebecShipperAccount && (
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.location__section}
                                >
                                    <Grid
                                        item
                                        container
                                        className={classes.location__card}
                                    >
                                        <Carriers
                                            owner={owner}
                                            handleClickOpen={
                                                this.handleClickOpen
                                            }
                                            handleClose={this.handleClose}
                                            onboardingModalContinue={
                                                this.onboardingModalContinue
                                            }
                                            classes={classes}
                                            location={location}
                                            carrierList={carrierList}
                                            dialogOpen={dialogOpen}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        {locationUserCanShip && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <CustomsDocumentsCard
                                    locationId={location?._id}
                                    className={classes.location__card__wrapper}
                                />
                            </Grid>
                        )}
                        {locationUserCanShip && (
                            <Grid
                                item
                                xs={6}
                                className={classes.location__section}
                            >
                                <Brokers
                                    locationId={location?._id}
                                    location={location}
                                    classes={classes}
                                />
                            </Grid>
                        )}
                    </Grid>
                </form>
            </Grid>
        )
    }
}

const mapStateToProps = (state, props) => {
    const formValues = getFormValues(props.form)(state)
    const language = state?.user?.profile?.preferences?.language

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            formValues?.shippingAddress?.address?.country !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            formValues?.shippingAddress?.address?.country !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        postalCode: formValues?.shippingAddress?.address?.postalCode,
        country: formValues?.shippingAddress?.address?.country,
        carrierList: state?.carriers,
        formDNA: formValues?.formDNA ?? false,
        userOnboardingDNA: state?.user?.preferences?.onboardingDNA ?? false,
        language: state?.user?.profile?.preferences?.language,
        formValues,
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    removeLocation: async location => {
        await dispatch(removeUserLocation(location._id))
    },
    handleUpdate: async (location, index) =>
        dispatch(updateUserLocation(location, index)),
    updateUserDefaultLocation: async value =>
        dispatch(updateUserDefaultLocation(value)),
    updateCustomLocationPreferences: async location =>
        dispatch(updateCustomLocationPreferences(location)),
    handleSuspend: async (location, value) => {
        if (value === true) {
            dispatch(trackGAEventNew("Location", "Non Owner - Deactivate Self"))
        } else if (value === false) {
            dispatch(trackGAEventNew("Location", "Non Owner - Reactivate Self"))
        }
        const newLocation = cloneDeep(location)
        newLocation.users[0].permissions.suspended.value = value
        return await dispatch(updateUserLocation(newLocation))
    },
    requestPostalCode: (postalCode, country) =>
        dispatch(requestPostalCode(postalCode, { country })),
    onCarrierManagementClick: (location, carrierCode, accountId, language) =>
        dispatch(
            manageLocationCarrier(location, carrierCode, accountId, language)
        ),
    updateUserOnboardingDNA: async () => dispatch(updateUserOnboardingDNA()),
    trackGA: (category, action, value) =>
        dispatch(trackGAEventNew(category, action, value)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(LocationContainer)
)
