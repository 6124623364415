import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { Field, FormSection, resetSection } from "redux-form"
import BrokerForm from "../../../locations/components/customsProfile/BrokerForm"
import { Button } from "@material-ui/core"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import { connect } from "react-redux"

const CustomsBrokerageForm = ({
    isExport = false,
    country,
    cities,
    selectedLocation,
    formObjectName,
    resetForm,
    changeField,
    postalCode,
}) => {
    const [shouldShowForm, setShouldShowForm] = useState(false)

    useEffect(() => {
        if (postalCode) {
            setShouldShowForm(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const formatBrokerOptions = brokers => {
        return brokers.map(broker => {
            return {
                value: broker,
                label: broker.brokerName,
            }
        })
    }

    const onBrokerSelect = option => {
        Object.keys(option).forEach(key => {
            changeField(`${formObjectName}.${key}`, option[key])
        })
        setShouldShowForm(true)

        setTimeout(() => {
            changeField(
                `${formObjectName}.address.state`,
                option?.address?.state
            )
        }, 1)
    }

    return (
        <Grid xs={12} item>
            <FormSection name={formObjectName}>
                <Grid>
                    <Grid item container justify="center">
                        <Typography variant="subheading">
                            {isExport ? (
                                <FormattedMessage
                                    id="orderDetails.customs__export__title"
                                    defaultMessage="Export Customs Brokerage Information"
                                />
                            ) : (
                                <FormattedMessage
                                    id="orderDetails.customs__import__title"
                                    defaultMessage="Import Customs Brokerage Information"
                                />
                            )}
                        </Typography>
                    </Grid>
                    <Grid item container alignItems="center">
                        <Grid item container xs={8}>
                            <Field
                                name="selectBroker"
                                component={FormSelectAutocomplete}
                                label={
                                    <FormattedMessage
                                        id="addBroker__selectBroker"
                                        defaultMessage="Select Existing Broker"
                                    />
                                }
                                options={formatBrokerOptions(
                                    selectedLocation?.brokers ?? []
                                )}
                                onChange={option => {
                                    onBrokerSelect(option)
                                }}
                            />
                        </Grid>
                        <Grid item container xs={3} justify="flex-end">
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    resetForm()
                                    setShouldShowForm(true)
                                }}
                                color="primary"
                            >
                                <FormattedMessage
                                    id="addBroker__addNew"
                                    defaultMessage="Add New Broker"
                                />
                            </Button>
                        </Grid>
                    </Grid>

                    {shouldShowForm ? (
                        <BrokerForm cities={cities} country={country} />
                    ) : null}
                </Grid>
            </FormSection>
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    const country =
        state?.form.bookShipment?.values[props.formObjectName]?.address?.country

    const postalCode =
        state?.form.bookShipment?.values[props.formObjectName]?.address
            ?.postalCode

    return {
        cities: state[`postalCode[${country}-${postalCode}].cities`],
        country,
        postalCode,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    resetForm: () =>
        dispatch(resetSection("bookShipment", props.formObjectName)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomsBrokerageForm)
