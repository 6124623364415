import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import { destroy, Field, formValues } from "redux-form"
import { makeStyles } from "@material-ui/styles"
import FormCheckbox from "../../../form/form-checkbox"
import CommercialInvoiceForm from "./CommercialInvoiceForm"
import CertificateOfOriginBOLForm from "./CertificateOfOriginBOLForm"
import { useCustomsProfileContext } from "../../../../context/providers/CustomsProfileProvider"
import { Chip, Dialog, Divider } from "@material-ui/core"
import { AddDocumentWizard } from "../../../documents/upload/AddDocumentWizard"
import { CustomsDocumentList } from "../../../locations/components/customsProfile/CustomsDocumentsCard"

const useStyles = makeStyles({
    underline: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    sectionSmall: {
        padding: "25px 15% 0 15%",
    },
    sectionMedium: {
        padding: "25px 8% 0 8%",
    },
    scrolling__list: {
        maxHeight: "333px",
        overflowY: "auto",
    },
    chip: {
        margin: "16px 8px",
    },
    chipTitle: {
        marginLeft: "16px",
    },
    divider: {
        margin: "16px 0",
    },
    newDocumentButton: {
        marginTop: "8px",
        marginBottom: "8px",
    },
})

const SupportDocumentationForm = formValues({
    commercialInvoice: "requiresCommercialInvoice",
    certificateOfOrigin: "requiresCertificateOfOrigin",
    selectedCustomsDocuments: "selectedCustomsDocuments",
})(
    ({
        commercialInvoice,
        certificateOfOrigin,
        changeField,
        locationId,
        shipmentId,
        selectedCustomsDocuments = [],
    }) => {
        const classes = useStyles()
        const [documentModalOpen, setDocumentModalOpen] = useState(false)
        const [newDocuments, setNewDocuments] = useState([])
        const [linkedDocuments, setLinkedDocuments] = useState([])
        const { documentList, setLocationId } = useCustomsProfileContext()
        const [cooListSelected, setCooListSelected] = useState(false)

        useEffect(() => {
            setLocationId(locationId)
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locationId])

        useEffect(() => {
            let isSelectCoo = selectedCustomsDocuments.some(
                doc => doc.documentCategory === "certificateOfOrigin"
            )
            setCooListSelected(isSelectCoo)
            if (cooListSelected) {
                changeField("requiresCertificateOfOrigin", false)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedCustomsDocuments])

        const onLinkToShipment = (document, isNew = false) => {
            if (
                !selectedCustomsDocuments ||
                selectedCustomsDocuments.length === 0
            ) {
                changeField("deferredTradeDocuments", true)
            }
            changeField("selectedCustomsDocuments", [
                ...(selectedCustomsDocuments ?? []),
                document,
            ])
            if (isNew) {
                setNewDocuments(prev => [...prev, document])
            } else {
                setLinkedDocuments(prev => [...prev, document])
            }
        }

        const onUnlinkFromShipment = (document, isNew = false) => {
            const newSelected = selectedCustomsDocuments?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (newSelected.length === 0) {
                changeField("deferredTradeDocuments", false)
            }
            changeField("selectedCustomsDocuments", newSelected)
            const localDocuments = (isNew
                ? newDocuments
                : linkedDocuments
            )?.filter(
                x =>
                    !(
                        x.documentCategory === document.documentCategory &&
                        x.customFilename === document.customFilename
                    )
            )
            if (isNew) {
                setNewDocuments(localDocuments)
            } else {
                setLinkedDocuments(localDocuments)
            }
        }

        const onCreateNewDocument = values => {
            const document = {
                documentCategory: values?.documentCategory,
                shipmentOnly: values?.shipmentOnly,
                customFilename: values?.customFilename,
                locationId,
                ...(values?.shipmentOnly && {
                    internalTrackingNumber: shipmentId,
                }),
                ...(values?.documentCategory === "USMCA" && values?.usmca),
            }
            onLinkToShipment(document, true)
            setDocumentModalOpen(false)
        }

        const onCloseDialog = () => {
            setDocumentModalOpen(false)
            destroy("newCustomsDocument")
        }

        return (
            <React.Fragment>
                <Dialog
                    open={documentModalOpen}
                    maxWidth="lg"
                    onSubmit={e => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onClose={onCloseDialog}
                >
                    <AddDocumentWizard
                        initialStep="generateDocument"
                        onGenerate={onCreateNewDocument}
                        onClose={onCloseDialog}
                        locationId={locationId}
                        shipmentId={shipmentId}
                        enableProfileOverwrite={false}
                        enableShipmentOverwrite={false}
                        customShipmentList={[
                            ...newDocuments,
                            ...linkedDocuments,
                        ]}
                        newflow={true}
                    />
                </Dialog>

                <Grid xs={12} item className={classes.sectionSmall}>
                    <Grid>
                        <Grid item container justify="center">
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="orderDetails.support__documentation__title"
                                    defaultMessage="Supporting Documentation"
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={3} justify="center">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setDocumentModalOpen(true)}
                                className={classes.newDocumentButton}
                            >
                                <FormattedMessage
                                    id="orderDetails.addNewDocument"
                                    defaultMessage="Add New Document"
                                />
                            </Button>
                        </Grid>
                        {(documentList?.length > 0 ||
                            newDocuments?.length > 0) && (
                            <React.Fragment>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subheading"
                                            gutterBottom
                                        >
                                            <FormattedMessage
                                                id="documents.linkDocuments"
                                                defaultMessage="Link document"
                                            />
                                        </Typography>
                                    </Grid>
                                    {documentList?.length > 0 && (
                                        <Grid
                                            item
                                            container
                                            xs={4}
                                            className={classes.scrolling__list}
                                        >
                                            <Grid item xs={12}>
                                                <CustomsDocumentList
                                                    attachable
                                                    customsDocuments={
                                                        documentList
                                                    }
                                                    attachedDocuments={
                                                        selectedCustomsDocuments ??
                                                        []
                                                    }
                                                    locationId={locationId}
                                                    onLinkToShipment={
                                                        onLinkToShipment
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        container
                                        xs={documentList?.length ? 8 : 12}
                                        alignContent="flex-start"
                                    >
                                        {newDocuments?.length > 0 && (
                                            <React.Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.chipTitle
                                                    }
                                                >
                                                    <Typography>
                                                        <FormattedMessage
                                                            id="documents.newDocuments"
                                                            defaultMessage="New documents"
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    {newDocuments?.map(
                                                        (
                                                            selectedDocument,
                                                            idx
                                                        ) => (
                                                            <Chip
                                                                key={idx}
                                                                color="primary"
                                                                className={
                                                                    classes.chip
                                                                }
                                                                label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                                                onDelete={() =>
                                                                    onUnlinkFromShipment(
                                                                        selectedDocument,
                                                                        true
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        )}

                                        {linkedDocuments?.length > 0 && (
                                            <React.Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    className={
                                                        classes.chipTitle
                                                    }
                                                >
                                                    <Typography>
                                                        <FormattedMessage
                                                            id="documents.linkedDocuments"
                                                            defaultMessage="Linked documents"
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={12}>
                                                    {linkedDocuments?.map(
                                                        (
                                                            selectedDocument,
                                                            idx
                                                        ) => (
                                                            <Chip
                                                                key={idx}
                                                                color="primary"
                                                                className={
                                                                    classes.chip
                                                                }
                                                                label={`${selectedDocument?.documentCategory} - ${selectedDocument?.customFilename}`}
                                                                onDelete={() =>
                                                                    onUnlinkFromShipment(
                                                                        selectedDocument,
                                                                        false
                                                                    )
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Grid>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                </Grid>
                                <Divider
                                    variant="middle"
                                    className={classes.divider}
                                />
                            </React.Fragment>
                        )}
                        <Grid container>
                            <Grid item container xs={12}>
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="documents.tradeDocumentationInfoPreBook"
                                        defaultMessage="Trade documentation will be sent to Customs on your behalf as a single document after a signature & notary stamp have been applied. FedEx requires trade documentation to be signed. You can add your signature by uploading it directly or by printing the trade documents first, signing them manually, and then uploading the signed trade documents to LTL Select. Your documents will automatically be shared with FedEx."
                                    />
                                </Typography>
                            </Grid>

                            <Grid item container xs={12}>
                                <Grid item container xs={3}>
                                    <Field
                                        component={FormCheckbox}
                                        name="requiresCommercialInvoice"
                                        label={
                                            <Typography variant="subheading">
                                                <FormattedMessage
                                                    id="orderDetails.commercial__invoice__title"
                                                    defaultMessage="Commercial Invoice"
                                                />
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={3}>
                                    <Field
                                        component={FormCheckbox}
                                        name="requiresCertificateOfOrigin"
                                        label={
                                            <Typography variant="subheading">
                                                <FormattedMessage
                                                    id="orderDetails.certificate__of__origin__title"
                                                    defaultMessage="Certificate Of Origin"
                                                />
                                            </Typography>
                                        }
                                        disabled={cooListSelected}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {commercialInvoice && (
                    <Grid item className={classes.sectionMedium}>
                        <CommercialInvoiceForm />
                    </Grid>
                )}

                {certificateOfOrigin && !cooListSelected && (
                    <Grid item className={classes.sectionMedium}>
                        <CertificateOfOriginBOLForm />
                    </Grid>
                )}
            </React.Fragment>
        )
    }
)

export default SupportDocumentationForm
