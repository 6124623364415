import React from "react"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import LocationFragment from "./locationFragment"
import { highlightTerm } from "../util"
import { FormattedMessage } from "react-intl"

const SearchShipmentItem = props => {
    const { term, shipment, status, identifiers, onClick } = props
    const {
        internalTrackingNumber: shipmentId,
        bolNumber,
        referenceNumber,
        proNumber,
    } = identifiers || {}
    const currentStatus = get(status, "currentStatus.code", "CREATED")

    return (
        <ListItem
            button
            onClick={() => onClick(shipmentId)}
            className="shipment-item"
        >
            <ListItemIcon>
                <LocalShippingIcon />
            </ListItemIcon>
            <ListItemText>
                <Grid container direction="row">
                    <LocationFragment
                        data={shipment}
                        location="origin"
                        term={term}
                    />
                    <Grid item xs={1}>
                        <ArrowForwardIcon />
                    </Grid>
                    <LocationFragment
                        data={shipment}
                        location="destination"
                        term={term}
                    />
                </Grid>
                <Typography variant="caption">{currentStatus}</Typography>
                <Typography variant="caption">
                    <FormattedMessage
                        id="generalTerms__BOL"
                        defaultMessage="BOL"
                    />
                    #:
                    {highlightTerm(term, bolNumber)}
                </Typography>
                {proNumber && (
                    <Typography variant="caption">
                        <FormattedMessage
                            id="generalTerms__trackingId"
                            defaultMessage="Tracking ID"
                        />
                        #:
                        {highlightTerm(term, proNumber)}
                    </Typography>
                )}
                {referenceNumber && (
                    <Typography variant="caption">
                        <FormattedMessage
                            id="generalTerms__referenceId"
                            defaultMessage="Reference ID"
                        />
                        #:
                        {highlightTerm(term, referenceNumber)}
                    </Typography>
                )}
            </ListItemText>
        </ListItem>
    )
}

export default SearchShipmentItem
