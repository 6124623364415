import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import {
    Button,
    withStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { Field } from "redux-form"
import FormField from "../../form/form-field"
import {
    isRequired,
    hasLengthLessThan,
    vagueCommodityCheck,
} from "../../../actions/validation"
import composeValidators from "revalidate/lib/composeValidators"
import IconButton from "@material-ui/core/IconButton"
import { includes } from "lodash"
import GlobalSpinner from "../../util/spinner"
import { trackGAEventNew } from "../../../actions/user"

const styles = theme => ({
    paper: {
        minWidth: "800px",
    },

    vague_item_list: {
        overflowY: "auto",
    },
    helpText: {
        padding: "5px 15px 5px 0px",
    },
    vagueItemBox: {
        borderTop: "solid 1px #D4D4D4",
        padding: "10px 0px 20px 0px",
    },
    youEntered: {
        paddingRight: "5px",
    },
})

const VagueCommodityDialog = ({
    classes,
    isVagueCommodity,
    originalFormValues,
    vagueItemDescriptions,
    vagueCommodities,
    checkAddressValidation,
    toggleVagueCommodityStatus,
    GAEvent,
}) => {
    const [vagueItems, setVagueItems] = useState(vagueItemDescriptions)
    const [isValid, setIsValid] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setVagueItems(vagueItemDescriptions)
    }, [vagueItemDescriptions])

    const vagueCommodityNameList = vagueCommodities.map(it =>
        it.name.toLowerCase()
    )

    const closeDialog = () => {
        toggleVagueCommodityStatus(false)
        GAEvent("Vague Commodity", "Close Dialog")
    }
    const updateRate = async () => {
        const originalFormValuesCopy = { ...originalFormValues }
        const oldHu = [...originalFormValuesCopy?.handlingUnits]
        const updatedHu = oldHu.map((hu, huIndex) => {
            const items = hu.items
            items.map((item, itemIndex) => {
                vagueItems.forEach(it => {
                    if (it.huIndex === huIndex && it.itemIndex === itemIndex)
                        item.description = it.newDescription
                })
                return item
            })
            hu.items = items
            return hu
        })
        originalFormValuesCopy.handlingUnits = updatedHu

        GAEvent("Vague Commodity", "Update Rate")

        setLoading(true)
        await checkAddressValidation({
            ...originalFormValuesCopy,
        })
        setLoading(false)
        toggleVagueCommodityStatus(false)
    }

    const updateVagueItems = vagueItem => e => {
        const tempVagueItems = vagueItems
        tempVagueItems.map(it => {
            if (
                it.huIndex === vagueItem.huIndex &&
                it.itemIndex === vagueItem.itemIndex
            ) {
                it.newDescription = e.target.value
            }
            return it
        })

        let tempIsValid = true
        let newDescription
        for (let i = 0; i < vagueItems.length; i++) {
            newDescription = vagueItems[i].newDescription
            if (
                newDescription == null ||
                newDescription.trim().isEmpty ||
                newDescription.length > 201
            ) {
                tempIsValid = false
                break
            }

            if (
                includes(
                    vagueCommodityNameList,
                    newDescription.toLowerCase().trim()
                )
            ) {
                tempIsValid = false
                break
            }
        }
        setIsValid(tempIsValid)
        setVagueItems(tempVagueItems)
    }

    return (
        <Dialog
            open={isVagueCommodity}
            classes={{
                paper: classes.paper,
            }}
        >
            {loading && <GlobalSpinner />}
            <DialogTitle id="vague-commodity-check-dialog-title">
                <Grid container item direction="row">
                    <Grid container item xs={8}>
                        <Grid item container direction="column">
                            <FormattedMessage
                                id="getRates__vague_commodity_dialog__title"
                                defaultMessage="Vague Commodity Check"
                            />
                            <Typography>
                                <FormattedMessage
                                    id="getRates__vague_commodity_dialog__subtitle"
                                    defaultMessage="Please improve your descriptions of the following items"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={4}
                        justify="flex-end"
                        alignItems="flex-start"
                    >
                        <IconButton
                            id="closeVagueCommodityDialog"
                            onClick={closeDialog}
                        >
                            <Grid alignContent="end">
                                <CloseIcon id="closeIconVagueCommodityDialog" />
                            </Grid>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid item container className={classes.vague_item_list}>
                    {vagueItems &&
                        vagueItems.map((vagueItem, itemIndex) => (
                            <Grid
                                container
                                item
                                direction="row"
                                className={classes.vagueItemBox}
                            >
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    className={classes.helpText}
                                >
                                    <Typography variant="caption">
                                        {vagueItem.vagueDescription}
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={6}
                                    direction="column"
                                    className={classes.item_description}
                                >
                                    <Grid container item alignItems="center">
                                        <Typography
                                            className={classes.youEntered}
                                        >
                                            <FormattedMessage
                                                id="getRates__vague_commodity_dialog__youEntered"
                                                defaultMessage="You entered:"
                                            />
                                        </Typography>
                                        <Typography>
                                            {vagueItem?.oldDescription}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        direction="row"
                                        className={classes.hu_up_padding}
                                    >
                                        <Grid item container xs={4}>
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    id="getRates__vague_commodity_dialog__class"
                                                    defaultMessage="Class: {class}"
                                                    values={{
                                                        class: vagueItem.class,
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container>
                                        <Field
                                            label={
                                                <FormattedMessage
                                                    id="getRates__vague_commodity_dialog__label"
                                                    defaultMessage="Enter New Description"
                                                />
                                            }
                                            component={FormField}
                                            name={`newDescription.${itemIndex}`}
                                            onChange={updateVagueItems(
                                                vagueItem
                                            )}
                                            validate={composeValidators(
                                                isRequired,
                                                hasLengthLessThan(201),
                                                vagueCommodityCheck(
                                                    vagueCommodityNameList
                                                )
                                            )({
                                                field: {
                                                    id: `items__new__description`,
                                                    defaultMessage:
                                                        "New Description",
                                                },
                                            })}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    item
                    justify="flex-end"
                    className={classes.update_button}
                >
                    <Button
                        id="updateRateVagueCommodityDialog"
                        type="submit"
                        onClick={updateRate}
                        color="primary"
                        variant="contained"
                        disabled={!isValid || loading}
                    >
                        <FormattedMessage
                            id="getRates__submit"
                            defaultMessage="GET RATES"
                        />
                    </Button>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

const mapDispatchToProps = dispatch => ({
    GAEvent: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export default connect(
    undefined,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(VagueCommodityDialog))
