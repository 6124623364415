import React, { useState, Fragment } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    Grid,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import AddBroker from "./AddBroker"

const Brokers = ({ classes, location }) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [activeBroker, setActiveBroker] = useState(null)

    const { brokers = [] } = location

    return (
        <Fragment>
            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false)
                }}
                maxWidth="lg"
            >
                <AddBroker
                    setDialogOpen={setDialogOpen}
                    location={location}
                    editMode={editMode}
                    activeBroker={activeBroker}
                />
            </Dialog>
            <Card className={classes.brokerCard}>
                <CardHeader
                    classes={{ action: classes.card__action }}
                    action={
                        <Button
                            color="primary"
                            id="brokersCard__addBroker"
                            onClick={() => {
                                setEditMode(false)
                                setDialogOpen(true)
                            }}
                        >
                            <FormattedMessage
                                id="locations.brokersCard__addNew"
                                defaultMessage="Add Broker"
                            />
                        </Button>
                    }
                    title={
                        <Typography variant="title">
                            <FormattedMessage
                                id="locations.brokersCard__title"
                                defaultMessage="Brokers"
                            />
                        </Typography>
                    }
                />
                <CardContent classes={{ root: classes.card__content }}>
                    {brokers.map((broker = {}, index) => (
                        <Grid
                            item
                            container
                            alignItems="center"
                            justify="space-between"
                            id={`userManagement__locationRow__${index}`}
                            key={index}
                            className={
                                index === location?.brokers?.length - 1
                                    ? classes.location__row__last
                                    : classes.location__row
                            }
                            onClick={() => {
                                setEditMode(true)
                                setActiveBroker(broker)
                                setDialogOpen(true)
                            }}
                        >
                            <Grid item>
                                <Typography>{broker.brokerName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption">
                                    {broker.email?.email_address}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </CardContent>
            </Card>
        </Fragment>
    )
}

export default Brokers
