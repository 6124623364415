import React, { Fragment, Component } from "react"
import { connect } from "react-redux"
import createCachedSelector from "re-reselect"
import { createSelector } from "reselect"
import Grid from "@material-ui/core/Grid"
import GlobalSpinner from "../util/spinner"
import Divider from "@material-ui/core/Divider"
import { withStyles } from "@material-ui/core/styles"
import ExpandMore from "@material-ui/icons/ExpandMore"
import Button from "@material-ui/core/Button"
import WarningIcon from "@material-ui/icons/Warning"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import Snackbar from "@material-ui/core/Snackbar"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"
import moment from "moment"
import { get } from "lodash"
import RateBreakdown from "./components/rateBreakdown"
import MasterTimeline, {
    statusStepSelector,
} from "../shipmentTimeline/masterTimeline"
import SnackbarContentWrapper from "../quote/share/snackbar"
import Badge from "@material-ui/core/Badge"
import { accessorialsSelector } from "../util/accessorialsDisplay"
import TerminalInfo from "../util/terminal-info"
import AccessorialsDisplay from "./accessorialsDisplay"
import ItemsDisplay from "./itemsDisplay"
import { FormattedMessage, injectIntl } from "react-intl"
import { currencyLabel } from "../util/currencyLabel"
import MiniAttachments from "../track/result/attachments/miniAttachments"
import { changePath } from "../../actions"
import { trackGAEventNew } from "../../actions/user"
import { IconButton } from "@material-ui/core"
import { goFetch } from "../../http"

const styles = theme => ({
    parent__container: {
        "min-width": "870px",
        "flex-grow": 1,
        "flex-shrink": 1,
        "flex-basis": "0px",
    },
    parent__container__open: {
        marginBottom: "10px !important",
    },
    panel__summary: {
        height: "84px",
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
        paddingLeft: "8px",
    },
    item__container: {
        overflow: "auto",
    },
    button__info: {
        "margin-right": "15%",
    },
    button__icon: {
        "&button": {
            "&:focus": {
                outline: "none",
            },
        },
    },
    warning__icon: {
        "margin-left": "5px",
    },
    warning__box: {
        "background-color": "#D3D3D3",
        "align-items": "center",
        width: "120px",
        height: "90px",
        display: "flex",
        "justify-content": "center",
        padding: "5%",
    },
    content__container: {
        "white-space": "pre-line",
        display: "flex",
        width: "90%",
        "padding-left": "20px",
    },
    address__container__open: {
        "white-space": "pre-line",
        display: "flex",
        width: "50%",
    },
    address__box__left: {
        "min-width": "25%",
    },
    address__box__right: {
        "min-width": "75%",
    },
    actions: {
        padding: "4px 16px 8px 8px",
    },
    directionIcon: {
        width: "35px",
        height: "35px",
    },
    carrier__logo: {
        height: "41px",
        width: "71px",
    },
    carrier__tag: {
        "margin-left": "8px",
    },
    item__description: {
        "margin-left": "16px",
        display: "flex",
        "flex-direction": "column",
        "align-items": "flex-start",
    },
    item__total: {
        "margin-left": "16px",
        "margin-top": "10px",
    },
    caption_inline: {
        display: "inline",
        "margin-left": "16px",
    },
    hide: {
        display: "none",
        "margin-left": "16px",
    },
    identifiers__container: {
        "white-space": "pre-line",
        display: "flex",
        width: "90%",
    },
    identifiers__box__left: {
        "min-width": "40%",
    },
    identifiers__box__right: {
        "min-width": "60%",
    },
    badge: {
        badge: {
            top: 1,
            right: -15,
        },
    },
    terminalInfo__container: {
        padding: "20px 0px",
    },
    expansionPanelDetails__topContainer: {
        margin: "0 30px",
    },
    expansionPanelDetails__middleContainer: {
        margin: "0 30px",
        padding: "10px 0px",
    },
    expansionPanelDetails__bottomContainer: {
        margin: "0 30px",
        padding: "10px 0px",
    },
    expansionPanelDetails__section: {
        padding: "10px",
    },
    section__title: {
        textDecoration: "underline",
    },
    rateBreakdown__container: {
        paddingTop: "10px",
    },
})

const AddressPart = ({ data, title }) =>
    data && (
        <Grid item container xs={6}>
            <Grid item container justify="flex-start" direction="row" xs={12}>
                <Grid item>
                    <Typography variant="caption">{title}</Typography>
                </Grid>
            </Grid>
            <Grid
                item
                container
                justify="flex-start"
                direction="column"
                xs={12}
            >
                <Grid item>
                    {get(data, "name", "") !== "" && (
                        <Typography>{data.name}</Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address.street1", "") !== "" && (
                        <Typography>{data.address.street1}</Typography>
                    )}
                </Grid>
                <Grid item>
                    {get(data, "address", "") !== "" && (
                        <Typography>
                            {data.address.city}, {data.address.state}{" "}
                            {data.address.postalCode}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )

class DashboardItem extends Component {
    state = {
        openCancelShipmentDialog: false,
        snackbarOpen: false,
        snackbarVariant: "",
        cancelSuccess: "",
        cancelError: "",
        loading: false,
    }

    handleCancelShipmentDialogClose = () => {
        this.setState({
            openCancelShipmentDialog: false,
        })
    }

    handleCancelShipmentDialogOpen = () => {
        this.setState({
            openCancelShipmentDialog: true,
        })
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ snackbarOpen: false })
    }

    setLoading = bool => {
        this.setState({
            loading: bool,
        })
    }

    handleCancelShipmentDialogOpen = () => {
        this.setState({
            openCancelShipmentDialog: true,
        })
    }

    handleSnackbarOpen = (variant, info) => {
        variant === "error"
            ? this.setState({
                  snackbarOpen: true,
                  snackbarVariant: variant,
                  cancelError: `${info.response.message.errorMessage}: \n ${info.response.message.errors[0].message}`,
              })
            : this.setState({
                  snackbarOpen: true,
                  snackbarVariant: variant,
                  cancelSuccess: `Shipment ${info.bolNumber} successfully cancelled.`,
              })
    }

    handleReturnShipment = () => {
        const { shipmentId, changePath, trackGA } = this.props

        trackGA("FXFD-Returns", "Dashboard Return Button Click")
        changePath(`/book/${shipmentId}/return`)
    }

    setStarred = async value => {
        const {
            page,
            pageSize,
            fetchElementsOnFilterApply,
            shipmentId,
            setIsStarring,
        } = this.props

        setIsStarring(true)
        try {
            await goFetch(
                `/shipments/${shipmentId}/favorite`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        isFavorite: value,
                    },
                },
                true
            )
            await fetchElementsOnFilterApply(page * pageSize, true)
        } catch (error) {
            this.handleSnackbarOpen(
                "error",
                <FormattedMessage
                    id="dashboard.starredError"
                    defaultMessage="Unable to star shipment"
                />
            )
        } finally {
            setIsStarring(false)
        }
    }

    render() {
        const {
            identifiers,
            isOpen,
            shipment,
            classes,
            carrier,
            onClickDetails,
            onShipAgain,
            accessorials,
            onViewQuote,
            intl,
            onCancelShipment,
            shipmentId,
            toggleInfo,
            currentStatus,
            canShip,
            activeAlerts,
            canViewRateAmounts,
            index,
            status,
            handlingUnits,
            totalWeight,
            totalHandlingUnits,
            fetchElements,
            page,
            pageSize,
            hasFXFDReturnsLocation,
            query,
        } = this.props
        const {
            snackbarOpen,
            snackbarVariant,
            cancelSuccess,
            cancelError,
            loading,
        } = this.state
        const shipmentStatus = get(currentStatus, "status", "")
        const shipmentStatusIdentifier = currentStatus?.statusIdentifier
        const canCancelShipment = get(carrier, "features.cancel_dispatch")
        const carrierName = get(carrier, "name")
        const carrierCode = get(carrier, "carrier_id")
        const usePreferredCurrency =
            get(shipment, "rate.rateQuoteDetail.totalInPreferredCurrency") !==
            null

        const openClasses = {
            expanded: classes.parent__container__open,
        }

        const isFreightDirect = shipment?.rate?.mode === "LTL_DIRECT"
        const isFreightDirectReturns =
            shipment?.rate?.mode === "LTL_DIRECT_RETURNS"
        const serviceLevel = shipment?.rate?.serviceLevel
        const isVendorAccount = shipment?.rate?.accountType === "VENDOR"

        const eligibleForReturn =
            currentStatus?.code === "DELIVERED" &&
            hasFXFDReturnsLocation &&
            isFreightDirect

        const { isQuickRate, isQuickQuote, queryVersion, pickupDate } = query

        return (
            <div className="shipment-item">
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackbarClose}
                >
                    <SnackbarContentWrapper
                        variant={snackbarVariant}
                        className={classes.margin}
                        message={
                            snackbarVariant === "success"
                                ? cancelSuccess
                                : cancelError
                        }
                        onClose={this.handleSnackbarClose}
                    />
                </Snackbar>
                <ExpansionPanel
                    id={`dashboardShipmentItem__${index}`}
                    className={classes.parent__container}
                    classes={openClasses}
                    expanded={isOpen}
                >
                    <ExpansionPanelSummary
                        className={classes.panel__summary}
                        expandIcon={
                            <ExpandMore
                                id={`dashboardShipmentItemExpandMore__${index}`}
                            />
                        }
                        onClick={e => {
                            toggleInfo(shipment._id)
                        }}
                    >
                        <Grid container>
                            <Grid item container xs={2} alignItems="center">
                                <Grid item container xs={4}>
                                    <IconButton
                                        onClick={e => {
                                            console.log("clicked")
                                            this.setStarred(
                                                !shipment.isFavorite
                                            )
                                            e.stopPropagation()
                                        }}
                                    >
                                        {shipment.isFavorite ? (
                                            <StarIcon color="primary" />
                                        ) : (
                                            <StarBorderIcon color="primary" />
                                        )}
                                    </IconButton>
                                </Grid>
                                <Grid item container xs={8} alignItems="center">
                                    {["FXFE", "FXNL"].includes(carrierCode) ? (
                                        <img
                                            className={classes.carrier__logo}
                                            src="https://cloud.p-44.com/img/carriers/FXFElogo.png"
                                            alt="FXNL"
                                        />
                                    ) : (
                                        <Typography
                                            variant="subheading"
                                            className={classes.carrier__tag}
                                        >{`${carrierName}`}</Typography>
                                    )}
                                    {carrierCode && (
                                        <Grid item container>
                                            <Typography
                                                variant="caption"
                                                className={classes.carrier__tag}
                                            >{`(${carrierCode})`}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.item__container}
                                justify="center"
                                direction="column"
                                xs={2}
                            >
                                {get(identifiers, "proNumber", "") !== "" && (
                                    <div
                                        className={
                                            classes.identifiers__container
                                        }
                                    >
                                        <span
                                            className={
                                                classes.identifiers__box__left
                                            }
                                        >
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    id="generalTerms__trackingId"
                                                    defaultMessage="Tracking ID"
                                                />
                                                :{" "}
                                            </Typography>
                                        </span>
                                        <span
                                            className={
                                                classes.identifiers__box__right
                                            }
                                        >
                                            <Typography>
                                                {identifiers.proNumber}
                                            </Typography>
                                        </span>
                                    </div>
                                )}
                                {get(identifiers, "bolNumber", "") !== "" && (
                                    <div
                                        className={
                                            classes.identifiers__container
                                        }
                                    >
                                        <span
                                            className={
                                                classes.identifiers__box__left
                                            }
                                        >
                                            <Typography variant="caption">
                                                <FormattedMessage
                                                    id="generalTerms__BOL"
                                                    defaultMessage="BOL"
                                                />
                                                :{" "}
                                            </Typography>
                                        </span>
                                        <span
                                            className={
                                                classes.identifiers__box__right
                                            }
                                        >
                                            <Typography
                                                id={`dashboardShipmentItem__${index}__bolNumber`}
                                            >
                                                {identifiers.bolNumber}
                                            </Typography>
                                        </span>
                                    </div>
                                )}
                            </Grid>
                            <Fragment>
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    direction="column"
                                    xs={4}
                                >
                                    {get(shipment, "destination.name", "") !==
                                        "" && (
                                        <div
                                            className={
                                                classes.content__container
                                            }
                                        >
                                            <span
                                                className={
                                                    classes.address__box__right
                                                }
                                            >
                                                <Typography
                                                    variant={"subheading"}
                                                >
                                                    {`${shipment.origin.name}`}{" "}
                                                    &#8594;{" "}
                                                    {`${shipment.destination.name}`}
                                                </Typography>
                                            </span>
                                        </div>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justify="flex-end"
                                    alignItems="center"
                                    xs={1}
                                >
                                    {get(shipment, "rate.rateQuoteDetail") &&
                                        canViewRateAmounts &&
                                        !get(
                                            shipment,
                                            "rate.isDomesticOffshore"
                                        ) && (
                                            <Typography variant={"subheading"}>
                                                {currencyLabel(
                                                    shipment?.rate
                                                        ?.preferredCurrencyCode,
                                                    usePreferredCurrency
                                                        ? get(
                                                              shipment,
                                                              "rate.rateQuoteDetail.totalInPreferredCurrency"
                                                          )
                                                        : get(
                                                              shipment,
                                                              "rate.rateQuoteDetail.total"
                                                          )
                                                )}
                                            </Typography>
                                        )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    alignItems="flex-end"
                                    xs={2}
                                >
                                    <Grid
                                        item
                                        container
                                        justify="flex-end"
                                        xs={12}
                                    >
                                        <Typography
                                            align="right"
                                            id={`dashboardShipmentItem__${index}__status`}
                                            variant={"subheading"}
                                        >
                                            {get(
                                                status,
                                                "currentStatus.code"
                                            ) === "CANCELED"
                                                ? "CANCELED"
                                                : shipmentStatus}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="flex-end"
                                        xs={12}
                                    >
                                        <Typography
                                            align="right"
                                            variant="caption"
                                        >
                                            {get(
                                                status,
                                                "currentStatus.code"
                                            ) === "CANCELED"
                                                ? moment(
                                                      get(
                                                          status,
                                                          "currentStatus.timestamp"
                                                      )
                                                  ).format("M/DD/YY")
                                                : moment(
                                                      get(
                                                          currentStatus,
                                                          "latestEventTime"
                                                      )
                                                  ).format("M/DD/YY")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Fragment>
                            {/* } */}
                            {activeAlerts.length > 0 && (
                                <Grid
                                    item
                                    container
                                    xs={1}
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Badge
                                        badgeContent={activeAlerts.length}
                                        color="secondary"
                                        classes={{
                                            badge: classes.badge,
                                        }}
                                    >
                                        <WarningIcon
                                            id={`dashboardShipmentItemWarningIcon__${index}`}
                                        />
                                    </Badge>
                                </Grid>
                            )}
                        </Grid>
                    </ExpansionPanelSummary>
                    <Divider />
                    <ExpansionPanelDetails>
                        <Grid container>
                            <Grid
                                item
                                container
                                xs={12}
                                className={
                                    classes.expansionPanelDetails__topContainer
                                }
                                alignContent="flex-start"
                            >
                                <Grid
                                    item
                                    container
                                    xs={4}
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                >
                                    <Grid item container>
                                        <Typography
                                            variant="subheading"
                                            className={classes.section__title}
                                        >
                                            {" "}
                                            <FormattedMessage
                                                id="dashboard__routingSummary"
                                                defaultMessage="Routing Summary"
                                            />{" "}
                                        </Typography>
                                    </Grid>
                                    <AddressPart
                                        data={shipment.origin}
                                        title={`${intl.formatMessage({
                                            id: "generalTerms__from",
                                            defaultMessage: "From",
                                        })}:`}
                                    />
                                    <AddressPart
                                        data={shipment.destination}
                                        title={`${intl.formatMessage({
                                            id: "generalTerms__to",
                                            defaultMessage: "To",
                                        })}:`}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={2}
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                >
                                    {(get(identifiers, "orderNumber") ||
                                        get(
                                            identifiers,
                                            "referenceNumber"
                                        )) && (
                                        <Grid item container>
                                            <Typography
                                                variant="subheading"
                                                className={
                                                    classes.section__title
                                                }
                                            >
                                                {" "}
                                                <FormattedMessage
                                                    id="dashboard__identifiers"
                                                    defaultMessage="Identifiers"
                                                />{" "}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        direction="column"
                                        alignItems="center"
                                        justify="center"
                                        className={classes.item__container}
                                    >
                                        {get(identifiers, "orderNumber") && (
                                            <div
                                                className={
                                                    classes.identifiers__container
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.identifiers__box__left
                                                    }
                                                >
                                                    <Typography variant="caption">
                                                        <FormattedMessage
                                                            id="generalTerms__PO"
                                                            defaultMessage="PO"
                                                        />
                                                        :{" "}
                                                    </Typography>
                                                </span>
                                                <span
                                                    className={
                                                        classes.identifiers__box__right
                                                    }
                                                >
                                                    <Typography>
                                                        {
                                                            identifiers.orderNumber
                                                        }
                                                    </Typography>
                                                </span>
                                            </div>
                                        )}
                                        {get(
                                            identifiers,
                                            "referenceNumber"
                                        ) && (
                                            <div
                                                className={
                                                    classes.identifiers__container
                                                }
                                            >
                                                <span
                                                    className={
                                                        classes.identifiers__box__left
                                                    }
                                                >
                                                    <Typography variant="caption">
                                                        {" "}
                                                        <FormattedMessage
                                                            id="generalTerms__referenceId"
                                                            defaultMessage="Reference ID"
                                                        />
                                                        :{" "}
                                                    </Typography>
                                                </span>
                                                <span
                                                    className={
                                                        classes.identifiers__box__right
                                                    }
                                                >
                                                    <Typography>
                                                        {
                                                            identifiers.referenceNumber
                                                        }
                                                    </Typography>
                                                </span>
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={4}
                                    justify="flex-end"
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                >
                                    <MiniAttachments
                                        internalTrackingNumber={shipmentId}
                                        isDashBoard={true}
                                        id={`dashboardShipmentItemMiniAttachments__${index}`}
                                    />
                                </Grid>
                                {get(shipment, "rate.terminal") && (
                                    <Grid
                                        item
                                        container
                                        xs={2}
                                        className={
                                            classes.expansionPanelDetails__section
                                        }
                                    >
                                        <Grid item container justify="flex-end">
                                            <Typography
                                                variant="subheading"
                                                className={
                                                    classes.section__title
                                                }
                                            >
                                                {" "}
                                                <FormattedMessage
                                                    id="dashboard__serviceCenter"
                                                    defaultMessage="Service Center"
                                                />{" "}
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            justify="flex-end"
                                            className={
                                                classes.terminalInfo__container
                                            }
                                        >
                                            <TerminalInfo
                                                reverse
                                                terminal={get(
                                                    shipment,
                                                    "rate.terminal"
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                xs={12}
                                className={
                                    classes.expansionPanelDetails__bottomContainer
                                }
                                justify="center"
                            >
                                <MasterTimeline
                                    totalWeight={totalWeight}
                                    handlingUnits={handlingUnits}
                                    shipment={shipment}
                                    shipmentId={shipmentId}
                                    identifiers={identifiers}
                                    preferredSystemOfMeasurement={
                                        query?.preferredSystemOfMeasurement
                                    }
                                    pickupDate={pickupDate}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                className={
                                    classes.expansionPanelDetails__middleContainer
                                }
                                alignContent="flex-start"
                            >
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                >
                                    <ItemsDisplay
                                        preferredSystemOfMeasurement={
                                            query?.preferredSystemOfMeasurement
                                        }
                                        handlingUnits={handlingUnits}
                                        totalWeight={totalWeight}
                                        totalHandlingUnits={totalHandlingUnits}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={3}
                                    className={
                                        classes.expansionPanelDetails__section
                                    }
                                    alignContent="flex-start"
                                >
                                    {accessorials && (
                                        <AccessorialsDisplay
                                            accessorials={accessorials}
                                            isFreightDirect={isFreightDirect}
                                            isFreightDirectReturns={
                                                isFreightDirectReturns
                                            }
                                            serviceLevel={serviceLevel}
                                            isInBondShipment={
                                                shipment?.isInBondShipment
                                            }
                                            id={`dashboardShipmentItem__${index}__accesorials`}
                                        />
                                    )}
                                </Grid>
                                {canViewRateAmounts &&
                                    !shipment?.isInBondShipment &&
                                    !isVendorAccount &&
                                    serviceLevel?.code !==
                                        "BASIC_PICKUP_AND_DESTROY" && (
                                        <Grid
                                            item
                                            container
                                            xs={3}
                                            justify="flex-end"
                                            alignContent="flex-start"
                                        >
                                            <Grid
                                                item
                                                container
                                                justify="flex-end"
                                            >
                                                <Typography
                                                    variant="subheading"
                                                    className={
                                                        classes.section__title
                                                    }
                                                >
                                                    {" "}
                                                    <FormattedMessage
                                                        id="dashboard__rateBreakdown"
                                                        defaultMessage="Rate Breakdown"
                                                    />{" "}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                alignItems="flex-start"
                                                className={
                                                    classes.rateBreakdown__container
                                                }
                                            >
                                                <RateBreakdown
                                                    listView={false}
                                                    charges={get(
                                                        shipment,
                                                        "rate.rateQuoteDetail.charges"
                                                    )}
                                                    usePreferredCurrency
                                                    totalInPreferredCurrency={
                                                        usePreferredCurrency
                                                            ? get(
                                                                  shipment,
                                                                  "rate.rateQuoteDetail.totalInPreferredCurrency"
                                                              )
                                                            : get(
                                                                  shipment,
                                                                  "rate.rateQuoteDetail.total"
                                                              )
                                                    }
                                                    preferredCurrencyCode={get(
                                                        shipment,
                                                        "rate.preferredCurrencyCode"
                                                    )}
                                                    gridSize={12}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                    <ExpansionPanelActions className={classes.actions}>
                        {["DISPATCHED"].includes(shipmentStatusIdentifier) &&
                            canCancelShipment &&
                            get(status, "currentStatus.code") !==
                                "CANCELED" && (
                                <Fragment>
                                    <Dialog
                                        open={
                                            this.state.openCancelShipmentDialog
                                        }
                                        onClose={() =>
                                            this.handleCancelShipmentDialogClose(
                                                false
                                            )
                                        }
                                    >
                                        {loading && <GlobalSpinner />}
                                        <DialogTitle id="alert-dialog-title">
                                            <FormattedMessage
                                                id="dashboard.shipment__cancelShipmentTitle"
                                                defaultMessage="Are you sure you would like to cancel the shipment?"
                                            />
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                <FormattedMessage
                                                    id="dashboard.shipment__cancelShipmentMessage"
                                                    defaultMessage="Once you cancel the shipment this action can not be undone."
                                                />
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() =>
                                                    this.handleCancelShipmentDialogClose()
                                                }
                                                color="primary"
                                                autoFocus
                                            >
                                                <FormattedMessage
                                                    id="generalTerms__no"
                                                    defaultMessage="No"
                                                />
                                            </Button>
                                            <Button
                                                onClick={async () => {
                                                    this.setLoading(true)
                                                    const result = await onCancelShipment(
                                                        shipment,
                                                        identifiers
                                                    )
                                                    result.name ===
                                                    "ResponseError"
                                                        ? this.handleSnackbarOpen(
                                                              "error",
                                                              result
                                                          )
                                                        : this.handleSnackbarOpen(
                                                              "success",
                                                              identifiers
                                                          )

                                                    if (
                                                        result.name !==
                                                        "ResponseError"
                                                    ) {
                                                        await fetchElements(
                                                            page * pageSize,
                                                            true
                                                        )
                                                    }
                                                    this.setLoading(false)
                                                    this.handleCancelShipmentDialogClose()
                                                }}
                                                color="secondary"
                                                autoFocus
                                            >
                                                <FormattedMessage
                                                    id="generalTerms__yes"
                                                    defaultMessage="Yes"
                                                />
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                    <Button
                                        id={`dashboardShipmentItem__${index}__cancel`}
                                        variant="contained"
                                        color="secondary"
                                        size="small"
                                        onClick={() =>
                                            this.handleCancelShipmentDialogOpen()
                                        }
                                    >
                                        <FormattedMessage
                                            id="dashboard.shipment__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                </Fragment>
                            )}
                        {eligibleForReturn && (
                            <Button
                                id={`dashboardShipmentItem__${index}__return`}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => this.handleReturnShipment()}
                            >
                                <FormattedMessage
                                    id="dashboard.shipment__return"
                                    defaultMessage="Return"
                                />
                            </Button>
                        )}
                        {canShip && !isFreightDirectReturns && (
                            <Button
                                id={`dashboardShipmentItem__${index}__shipAgain`}
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() =>
                                    onShipAgain(shipmentId, queryVersion)
                                }
                            >
                                <FormattedMessage
                                    id="dashboard.shipment__shipAgain"
                                    defaultMessage="Ship Again"
                                />
                            </Button>
                        )}
                        <Button
                            id={`dashboardShipmentItem__${index}__rates`}
                            variant="outlined"
                            color="primary"
                            size="small"
                            className={classes.button__left}
                            onClick={() =>
                                onViewQuote(
                                    shipmentId,
                                    isQuickQuote,
                                    isQuickRate,
                                    queryVersion
                                )
                            }
                        >
                            <FormattedMessage
                                id="documents__rates"
                                defaultMessage="Rates"
                            />
                        </Button>
                        <Button
                            id={`dashboardShipmentItem__${index}__orderDetails`}
                            variant="outlined"
                            color="primary"
                            size="small"
                            onClick={onClickDetails}
                        >
                            <FormattedMessage
                                id="dashboard.shipment__orderDetails"
                                defaultMessage="Order Details"
                            />
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </div>
        )
    }
}

const activeAlertsSelector = createCachedSelector(
    (state, { shipmentId }) => state.shipment.list[shipmentId].alerts,
    alerts =>
        get(alerts, "events", []).filter(
            event => !event.dismissed && event.type === "warning"
        )
)((state, { shipmentId }) => `${shipmentId}`)

const canShipSelector = createCachedSelector(
    (state, { shipmentId }) =>
        get(state, `shipment.list[${shipmentId}].query.cpg`),
    state => state.user.profile.locations,
    (cpgCode, locations) => {
        if (!locations || !cpgCode) return null
        const location = locations.find(loc => loc.cpgCode === cpgCode)
        return get(
            location,
            "users[0].permissions.createShipments.value",
            false
        )
    }
)((state, { shipmentId }) =>
    get(state, `shipment.list[${shipmentId}].query.cpg`, "")
)

const carrierSelector = createSelector(
    (state, { shipmentId }) =>
        get(state, `shipment.list[${shipmentId}].shipment.carrier`),
    state => state.carriers.carriers,
    (carrierCode, carrierList = {}) => carrierList[carrierCode]
)

const mapStateToProps = createCachedSelector(
    (state, props) =>
        accessorialsSelector(state, {
            ...props,
            isPickup: true,
            includeCalculated: true,
        }),
    accessorialsSelector,
    canShipSelector,
    carrierSelector,
    statusStepSelector,
    activeAlertsSelector,
    (state, props) =>
        get(state, "user.profile.locations").find(
            loc => loc.cpgCode === get(props, "shipmentCPG")
        ),
    state => state?.user?.preferences?.hasFXFDReturnsLocation,
    (
        pickupAccessorials,
        accessorials,
        canShip,
        carrier,
        steps,
        activeAlerts,
        shipmentLocation,
        hasFXFDReturnsLocation
    ) => {
        const { activeIndex, statusSteps = [] } = steps || {}
        const currentStatus =
            activeIndex >= 0 ? statusSteps[activeIndex] : undefined

        return {
            accessorials: {
                pickup: pickupAccessorials,
                delivery: accessorials,
            },
            canShip,
            carrier,
            currentStatus,
            activeAlerts,
            canViewRateAmounts: get(
                shipmentLocation,
                "users[0].permissions.viewRateAmounts.value"
            ),
            hasFXFDReturnsLocation,
        }
    }
)((state, { shipmentId }) => `${shipmentId}`)

const mapDispatchToProps = dispatch => ({
    changePath: path => dispatch(changePath(path)),
    trackGA: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardItem))
)
