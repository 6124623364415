import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { get } from "lodash"
import QuoteRow from "../QuoteRow"
import NoRateOnlyDispatchRow from "../noRateOnlyDispatchRow"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    root: {
        justifyContent: "space-around",
        width: "100%",
    },
    gridList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
    },
    title: {
        color: theme.palette.primary.light,
    },

    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    paper_root: {
        ...theme.mixins.gutters(),
        paddingTop: "5px", // theme.spacing.unit * 2,
        paddingBottom: "5px", // theme.spacing.unit * 2,
    },
    date_container: {
        marginBottom: theme.spacing.unit,
        marginTop: theme.spacing.unit,
    },
})

class QuoteGridList extends Component {
    state = {
        openItemId: null,
        openItem: false,
        domesticOffshoreRate: null,
    }

    toggleInfo = id => {
        if (this.state.openItem === true && this.state.openItemId !== id) {
            this.setState({ openItemId: id })
        } else {
            this.setState({
                openItemId: id,
                openItem: !this.state.openItem,
            })
        }
    }

    render() {
        const {
            classes,
            rates,
            isExpired,
            isShipped,
            getBol,
            canCreateShipments,
            canViewRateAmounts,
        } = this.props

        return (
            <Grid item container className={classes.root} xs={12}>
                {rates.length > 0 &&
                    rates.map((carrier, index) => (
                        <Grid container direction="column" key={index}>
                            {(index === 0 ||
                                carrier.groupDate !==
                                    rates[index - 1].groupDate) && (
                                <Grid item container direction="column">
                                    {!!carrier?.rateQuotes[0]?.isNonRateable ? (
                                        <Grid item container>
                                            <Typography
                                                variant="subheading"
                                                className={
                                                    classes.date_container
                                                }
                                            >
                                                <FormattedMessage
                                                    id="orderDetails.header__unknownDeliveryDate"
                                                    defaultMessage="Call carrier for delivery date"
                                                />
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid item container>
                                            <Typography
                                                variant="subheading"
                                                className={
                                                    classes.date_container
                                                }
                                                color={"secondary"}
                                            >
                                                {index === 0 ? (
                                                    <FormattedMessage
                                                        id="rateResults__fastest"
                                                        defaultMessage="Fastest "
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Typography>
                                            <Typography
                                                variant="subheading"
                                                className={
                                                    classes.date_container
                                                }
                                            >
                                                {index === 0 ? " - " : ""}{" "}
                                                {moment(
                                                    carrier.groupDate
                                                ).format("dddd MMM-Do")}
                                            </Typography>
                                        </Grid>
                                    )}
                                    <Divider />
                                </Grid>
                            )}
                            {!!carrier?.rateQuotes[0]?.isNonRateable ? (
                                <NoRateOnlyDispatchRow
                                    getBol={getBol}
                                    isThirdParty={
                                        get(
                                            carrier,
                                            "rateQuotes[0].direction"
                                        ) === "THIRD_PARTY"
                                    }
                                    carrier={carrier}
                                    index={index}
                                    disabled={
                                        isExpired ||
                                        isShipped ||
                                        !canCreateShipments
                                    }
                                />
                            ) : (
                                <QuoteRow
                                    getBol={getBol}
                                    isOpen={
                                        index === this.state.openItemId &&
                                        this.state.openItem
                                    }
                                    toggleInfo={this.toggleInfo}
                                    index={index}
                                    disabled={
                                        isExpired ||
                                        isShipped ||
                                        !canCreateShipments
                                    }
                                    canViewRateAmounts={canViewRateAmounts}
                                    carrier={carrier}
                                    isThirdParty={
                                        get(
                                            carrier,
                                            "rateQuotes[0].direction"
                                        ) === "THIRD_PARTY"
                                    }
                                />
                            )}
                        </Grid>
                    ))}
            </Grid>
        )
    }
}

QuoteGridList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(QuoteGridList)
