import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Navbar, Nav, NavItem } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { UserHeader, LogOut } from "../user"
import { createGAEvent, changePath } from "../../actions"

const NavigationPresentation = ({ onClick, onRateAndShipClick }) => (
    <Navbar inverse fluid collapseOnSelect>
        <Navbar.Header>
            <Navbar.Brand>
                <a href="#/home" onClick={() => onClick("Logo")}>
                    <img
                        className="logo"
                        alt="LTL Select"
                        src="static/LTLselect_logo-sm.png"
                    />
                </a>
            </Navbar.Brand>
            <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
            <Nav>
                <LinkContainer to="/home">
                    <NavItem eventKey={1} onClick={() => onClick("Home")}>
                        HOME
                    </NavItem>
                </LinkContainer>
                <LinkContainer
                    onClick={() => onRateAndShipClick("Rate & Ship")}
                >
                    <NavItem eventKey={2}>RATE & SHIP</NavItem>
                </LinkContainer>
                <LinkContainer to="/track" onClick={() => onClick("Track")}>
                    <NavItem eventKey={3}>TRACK</NavItem>
                </LinkContainer>
                <LinkContainer to="/items" onClick={() => onClick("Items")}>
                    <NavItem eventKey={4}>ITEMS</NavItem>
                </LinkContainer>
                <LinkContainer
                    to="/address"
                    onClick={() => onClick("Contacts")}
                >
                    <NavItem eventKey={5}>CONTACTS</NavItem>
                </LinkContainer>
            </Nav>
            <Nav pullRight>
                <NavItem onClick={() => onClick("User")}>
                    {" "}
                    <UserHeader />{" "}
                </NavItem>
                <NavItem onClick={() => onClick("Logout")}>
                    {" "}
                    <LogOut />{" "}
                </NavItem>
            </Nav>
        </Navbar.Collapse>
    </Navbar>
)

NavigationPresentation.propTypes = {
    onClick: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    onClick: action => dispatch(createGAEvent("Navigation", action)),
    onRateAndShipClick: action => {
        dispatch(createGAEvent("Navigation", action))
        dispatch(changePath("/rate/"))
    },
})

export const Navigation = connect(
    undefined,
    mapDispatchToProps
)(NavigationPresentation)
