import React from "react"
import { withStyles } from "@material-ui/core/styles"
import {
    Button,
    Divider,
    Grid,
    IconButton,
    Modal,
    Typography,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import CloseIcon from "@material-ui/icons/Close"
import Spinner from "../../util/spinner"
import ProNumberResultSuccess from "./proNumberResultSuccess"
import { trackGAEventNew } from "../../../actions/user"
import { connect } from "react-redux"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: "24px 24px 0 24px",
        top: "40%",
        left: "50%",
        width: "900px",
        outline: "none",
        overflowX: "scroll",
    },
    proNumberResult: {
        marginTop: "25px",
    },
    button__container: {
        padding: "24px 0",
        height: "80px",
    },
    proNumberSearchResultAddress: {
        padding: "10px",
    },
    proNumberSearchResultAddressTitle: {
        paddingBottom: "10px",
    },
    proNumberSearchResultContact: {
        paddingTop: "15px",
    },
    section__title: {
        fontWeight: "500",
    },
    consignee: {
        padding: "10px",
        borderLeft: "solid 1px #D4D4D4",
    },
})

const ProNumberResult = props => {
    const {
        isProNumberSearch,
        handleCloseProSearchModal,
        shipmentsProNumberSearch,
        classes,
        proNumber,
        changeField,
        touchForm,
        trackGA,
        newFlow = false,
        setIsReturning,
        setReturnsTrackingId,
    } = props

    const touchQuoteForm = handlingUnits => {
        const rateFormValues = [
            "billToLocation",
            "includeThirdParty",
            "originCountry",
            "origin",
            "destinationCountry",
            "destination",
            "pickupDate",
            "volumeLTL",
            "totalLinearFeet",
        ]

        const rateHandlingUnitFields = [
            "isMultiClass,",
            "count",
            "packageType",
            "length",
            "width",
            "height",
            "stackable",
        ]

        const handlingUnitItemFields = [
            "isHazMat,",
            "description",
            "freightClass",
            "nmfcCode",
            "nmfcSubclass",
            "pieces",
            "weight",
        ]

        const freightDirectPiecesFields = ["weight", "count"]

        let handlingUnitsMapped = handlingUnits.map((handlingUnit, index) => {
            let handlingUnitMapped = rateHandlingUnitFields.map(
                handlingUnitField =>
                    `handlingUnits[${index}].${handlingUnitField}`
            )
            let itemsMapped = []
            if (handlingUnit?.items) {
                itemsMapped = handlingUnit.items.map((item, indexItem) => {
                    let itemMapped = handlingUnitItemFields.map(
                        itemField =>
                            `handlingUnits[${index}].items[${indexItem}].${itemField}`
                    )
                    let freightPiecesMapped = []
                    if (item?.freightDirectPieces) {
                        freightPiecesMapped = item.freightDirectPieces.map(
                            (_, indexfreightDirectPiece) =>
                                freightDirectPiecesFields.map(
                                    freightDirectPiecesField =>
                                        `handlingUnits[${index}].items[${indexItem}].freightDirectPieces[${indexfreightDirectPiece}].${freightDirectPiecesField}`
                                )
                        )
                    }
                    return [...itemMapped, ...freightPiecesMapped]
                })
            }
            return [...handlingUnitMapped, ...itemsMapped, ...rateFormValues]
        })

        touchForm(...handlingUnitsMapped.flat(3))
    }

    const confirmShipment = shipmentResult => {
        const { shipment = {}, handlingUnits = [] } = shipmentResult
        const { destination = {} } = shipment
        changeField("quote", "originContactInfo", {
            name: destination?.name,
            contact: {
                name: destination?.contact?.name,
                phone: {
                    phoneNumber: destination?.contact?.phone?.phone_number,
                    extension: destination?.contact?.phone?.extension,
                },
                email: {
                    emailAddress: destination?.contact?.email?.email_address,
                },
            },
            address: { ...destination?.address },
        })
        changeField("quote", "originContact", destination?.contact?._id)
        changeField("quote", "originAddress", destination?.address?._id)
        changeField("quote", "originName", destination?.name)
        changeField("quote", "origin", destination?.address?.postalCode)
        changeField("quote", "originCity", destination?.address?.city)
        changeField("quote", "originState", destination?.address?.state)
        changeField("quote", "originCountry", destination?.address?.country)
        changeField("quote", "originStreet", destination?.address?.street1)

        changeField("quote", "handlingUnits", handlingUnits)
        touchQuoteForm(handlingUnits)
        handleCloseProSearchModal()
        trackGA("FXFD-Returns", "PRO Modal Confirm Click")
    }

    const confirmShipmentNewFlow = shipmentResult => {
        const { shipment = {}, handlingUnits = [] } = shipmentResult
        const { destination = {} } = shipment

        changeField("origin", {
            shippingAddress: {
                address: {
                    city: destination?.address?.city,
                    country: destination?.address?.country,
                    postalCode: destination?.address?.postal_code,
                    state: destination?.address?.state,
                    street1: destination?.address?.street_1,
                    street2: destination?.address?.street_2,
                },
                name: destination?.name,
            },
            contact: {
                _id: destination?.contact?._id,
                name: destination?.contact?.name,
                phone: {
                    phone_number: destination?.contact?.phone?.phone_number,
                    extension: destination?.contact?.phone?.extension,
                },
                email: {
                    email_address: destination?.contact?.email?.email_address,
                },
            },
            readyTime: "7:00 AM",
            closeTime: "8:00 PM",
            pickupContact: {
                name: destination?.contact?.name,
                phone: {
                    phone_number: destination?.contact?.phone?.phone_number,
                    extension: destination?.contact?.phone?.extension,
                },
                email: {
                    email_address: destination?.contact?.email?.email_address,
                },
            },
        })
        changeField("handlingUnits", handlingUnits)
        setIsReturning(true)
        setReturnsTrackingId(proNumber)
        handleCloseProSearchModal()
        trackGA("FXFD-Returns", "PRO Modal Confirm Click")
    }

    return (
        <Modal open={isProNumberSearch} onClose={handleCloseProSearchModal}>
            <Grid className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    alignContent="center"
                >
                    <Typography variant="headline">
                        <FormattedMessage
                            id="getRates.searchProNumberModal__searchProNumber"
                            defaultMessage="Tracking Number Result: {proNumber}"
                            values={{ proNumber }}
                        />
                    </Typography>
                    <IconButton
                        id="closeProNumberModal"
                        onClick={handleCloseProSearchModal}
                    >
                        <CloseIcon id="closeIconProNumberModal" />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Divider />
                </Grid>
                {shipmentsProNumberSearch?.error && (
                    <Grid
                        className={classes.button__container}
                        container
                        direction="column"
                        alignItems="center"
                        alignContent="center"
                        justify="center"
                    >
                        <Typography variant="headline">
                            {shipmentsProNumberSearch.error}
                        </Typography>
                    </Grid>
                )}
                {!shipmentsProNumberSearch?.result &&
                    !shipmentsProNumberSearch?.loading && (
                        <Grid
                            className={classes.proNumberResult}
                            container
                            direction="column"
                            alignItems="center"
                            alignContent="center"
                            justify="center"
                        >
                            <Typography variant="headline">
                                <FormattedMessage
                                    id="getRates.searchProNumberModal__searchProNumberHeadingEmptyResults"
                                    defaultMessage="No Tracking Number Found"
                                />
                            </Typography>
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="getRates.searchProNumberModal__searchProNumberSubHeadingEmptyResults"
                                    defaultMessage="Please re-enter a valid Tracking Number"
                                />
                            </Typography>
                        </Grid>
                    )}
                {shipmentsProNumberSearch?.result && (
                    <ProNumberResultSuccess
                        shipment={shipmentsProNumberSearch?.result}
                        classes={classes}
                    />
                )}
                {shipmentsProNumberSearch?.loading && (
                    <Grid className={classes.button__container}>
                        <Spinner />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    container
                    justify="flex-end"
                    alignItems="center"
                    className={classes.button__container}
                >
                    {shipmentsProNumberSearch?.result && (
                        <Button
                            id="confirmResultProNumber"
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                newFlow
                                    ? confirmShipmentNewFlow(
                                          shipmentsProNumberSearch?.result
                                      )
                                    : confirmShipment(
                                          shipmentsProNumberSearch?.result
                                      )
                            }
                        >
                            <FormattedMessage
                                id="generalTerms__confirm"
                                defaultMessage="Confirm"
                            />
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Modal>
    )
}

const mapDispatchToProps = (dispatch, props) => ({
    trackGA: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export default connect(
    undefined,
    mapDispatchToProps
)(withStyles(styles)(ProNumberResult))
