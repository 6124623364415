import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import SwapHorizIcon from "@material-ui/icons/SwapHoriz"
import Button from "@material-ui/core/Button"
import {
    Field,
    reduxForm,
    getFormValues,
    FieldArray,
    clearSubmitErrors,
    untouch,
} from "redux-form"
import { composeValidators, combineValidators } from "revalidate"
import Popper from "@material-ui/core/Popper"
import Typography from "@material-ui/core/Typography"
import { requestQuote } from "../../actions/quote-request"
import FormField from "../form/form-field"
import FormSelect from "../form/form-select"
import { requestPostalCode } from "../../actions/postal-code"
import { searchContacts } from "../../actions/contact"
import { SuggestionBox } from "../quote/post-code/suggestion-box"
import {
    pastDateValidator,
    isOneOfSilent,
    isRequired,
    isNumeric,
    hasLengthGreaterThan,
    isGreaterThan,
    isValidCountryPostalCode,
} from "../../actions/validation"
import { get } from "lodash"
import { freightClasses } from "../item/line-item"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import { quickQuotesFormSelector } from "../quotesPage/selectors"
import BillToLocationPicker from "../quotesPage/billToLocationPicker"
import { populateQuoteForm } from "../../actions/quote"
import ShipmentSnackbar from "../book/snackbar"
import { QuoteItems } from "./quoteItem"
import { newQuickQuoteHandlingUnit } from "../../reducers/search"
import { FormattedMessage } from "react-intl"
import FormSelectAutocompleteDark from "../form/form-select-autocomplete-dark"
import { supportedCountryCodes } from "../../misc"

const styles = theme => ({
    main__container: {
        width: "100%",
        height: "100%",
        flexGrow: 1,
        minWidth: "331px",
    },
    form_paper: {
        "background-color": theme.palette.primary.main,
        width: "100%",
    },
    quote__form__container: {
        "padding-top": "15px",
        "padding-bottom": "5px",
        "background-color": theme.palette.primary.main,
    },
    quote__form__container__item: {
        // 'border-top': 'solid 1px white',
    },
    quote__form__container__item__bottom: {
        "padding-top": "15px",
        "background-color": theme.palette.primary.main,
    },
    input__field: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        fontFamily: theme.typography.fontFamily,
    },
    input__field__select__autocomplete: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px",
        height: "20px",
        fontFamily: theme.typography.fontFamily,
    },
    input__field__focused: {
        "background-color": theme.palette.primary.light,
    },
    field__root: {
        "background-color": theme.palette.primary.light,
    },
    multiline__field: {
        color: "white",
        fontFamily: theme.typography.fontFamily,
        width: "inherit",
        "padding-left": "6px",
        "padding-bottom": "6px",
    },
    input__label: {
        color: "white !important",
    },
    input__helper: {
        "margin-top": "3px",
    },
    quote__label: {
        color: "white",
        margin: "12px 8px",
    },
    quote__label__hazmat: {
        margin: "12px 8px",
        color: theme.palette.secondary.light,
    },
    cssFocused: {},
    hide: {
        display: "none",
    },
    menuButton: {
        position: "absolute",
        top: 100,
        right: 100,
    },
    button__add_item: {
        margin: "15px 5px",
        color: "white",
    },
    button__clear: {
        margin: "15px 5px",
        color: "white",
    },
    button__get_quotes: {
        margin: "15px 5px",
    },
    popper: {
        zIndex: 100,
    },
    volumeLTL__container: {
        marginTop: "-3px",
    },
    item__icon: {
        marginTop: "14px",
        cursor: "pointer",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    swap__icon: {
        cursor: "pointer",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
        marginTop: "22px",
        marginLeft: "5px",
    },
    switch_column_width: {
        maxWidth: "30px",
    },
    location__icon__container: {
        marginTop: "109px",
    },
    location__icon__top: {
        color: "white",
    },
    location__icon__middle: {
        color: "white",
        marginTop: "24px",
    },
    location__icon__bottom: {
        color: "white",
        marginTop: "25px",
    },
    item__panel__summary: {
        cursor: "pointer",
    },
    item__panel: {
        "background-color": "transparent", // theme.palette.primary.main,
        border: 0,
        margin: 0,
        width: "100%",
        boxShadow: "0 0px 0px 0px rgba(255, 105, 135, .3)",
    },
    item__panel__details: {
        justifyContent: "center",
        padding: "0 0 10px 0",
    },
    select__icon: {
        color: "white",
    },
    third__party__label: {
        marginLeft: "12px",
        marginTop: "4px",
    },
    third__party__checkbox: {
        marginLeft: "2px",
        height: "32px",
        width: "32px",
    },
    third__party__hidden: {
        marginLeft: "12px",
        visibility: "hidden",
        cursor: "default",
    },
    third__party__container: {
        height: "34px",
        marginLeft: "10px",
        marginTop: "18px",
    },
    location__container: {
        minHeight: "98px",
        paddingBottom: "3px",
    },
    location__label: {
        color: "white",
        marginLeft: "7px",
    },
    rate_commands: {
        marginTop: "-70px",
    },
})

function getItemWeight(items) {
    let count = 0
    items.forEach(item => {
        if (item.weight) count += parseInt(item.weight, 10)
    })
    return count
}

class QuotesDrawer extends React.Component {
    state = {
        showOriginOverlay: false,
        showDestinationOverlay: false,
        showBillToOverlay: false,
        anchorEl: null,
        origin: "",
        destination: "",
        itemSearch: "",
        noItemsHelper: false,
    }

    componentDidMount() {
        this.clickedInside = false
        this.clickTimeout = null
    }

    componentWillUnmount = () => {
        clearTimeout(this.clickTimeout)
    }

    handleInputFocus = (field, e) => {
        let country
        if (field === "origin") {
            this.setState({
                showOriginOverlay: true,
                anchorEl: e.currentTarget,
            })
            country = this.props?.formValues?.originCountry
        } else if (field === "destination") {
            this.setState({
                showDestinationOverlay: true,
                anchorEl: e.currentTarget,
            })
            country = this.props?.formValues?.destinationCountry
        } else if (field === "billToLocation") {
            this.setState({
                showBillToOverlay: true,
                anchorEl: e.currentTarget,
            })
            country = this.props?.formValues?.originCountry
        }
        this.props.onPostalCodeChange(e.target.value, field, country)
    }

    handleContainerMouseDown = () => {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputBlur = (field, e) => {
        const showOverlay = this.clickedInside

        // Force input's focus if blur event was caused by clicking on dropdown
        if (!showOverlay) {
            if (field === "origin") {
                this.setState({
                    showOriginOverlay: false,
                    anchorEl: null,
                })
            } else if (field === "destination") {
                this.setState({
                    showDestinationOverlay: false,
                    anchorEl: null,
                })
            } else if (field === "billToLocation") {
                this.setState({
                    showBillToOverlay: false,
                    anchorEl: null,
                })
            }
        }
    }

    onBillToLocationChange = option => {
        const { change } = this.props
        const selectedLocation = this.props.locations.find(
            location => location.cpgCode === option.value
        )
        const thirdPartyOnly = !selectedLocation?.isShipperEnabled

        change("cpg", selectedLocation?.cpgCode)
        change("billToLocation", selectedLocation?.cpgCode)
        change("billToLocationAddress", selectedLocation?.shippingAddress?._id)
        change("thirdPartyOnly", thirdPartyOnly)
        if (thirdPartyOnly) {
            change("includeThirdParty", true)
            this.resetSelection("origin")
            this.resetSelection("destination")
            change("origin", "")
            change(
                "originCountry",
                selectedLocation?.shippingAddress?.address?.country
            )
            change("destination", "")
        } else {
            change(
                "origin",
                selectedLocation?.shippingAddress?.address?.postalCode
            )
            change(
                "originCity",
                selectedLocation?.shippingAddress?.address?.city
            )
            change(
                "originState",
                selectedLocation?.shippingAddress?.address?.state
            )
            change(
                "originStreet",
                selectedLocation?.shippingAddress?.address?.street1
            )
            change(
                "originCountry",
                selectedLocation?.shippingAddress?.address?.country
            )
            change("originName", selectedLocation?.shippingAddress?.name)
            change("originCpgCode", selectedLocation?.cpgCode)
            change("originAddress", selectedLocation?.shippingAddress?._id)
            change("originContactInfo", selectedLocation?.shippingAddress)
        }
    }

    handleCitySelect = (field, city, state, country) => {
        const { formValues, change, clearSubmitErrors } = this.props
        const billToLocation = this.props.locations.find(
            location => location.cpgCode === formValues.cpg
        )
        clearSubmitErrors()
        change(`${field}City`, city)
        change(`${field}State`, state)
        change(`${field}Country`, country)
        change(`${field}Name`, "")
        change(`${field}CpgCode`, "")
        change(`${field}Contact`, "")
        change(`${field}Address`, "")
        change(`${field}ContactInfo`, "")
        change(`${field}AddressInfo`, "")

        if (field === "origin") {
            this.setState(
                () => ({ showOriginOverlay: false }),
                () => {
                    if (
                        !formValues?.destinationState &&
                        billToLocation?.isShipperEnabled
                    ) {
                        change(
                            "destination",
                            billToLocation?.shippingAddress?.address
                                ?.postalCode ?? ""
                        )
                        change(
                            "destinationCity",
                            billToLocation?.shippingAddress?.address?.city ?? ""
                        )
                        change(
                            "destinationState",
                            billToLocation?.shippingAddress?.address?.state ??
                                ""
                        )
                        change(
                            "destinationStreet",
                            billToLocation?.shippingAddress?.address?.street1 ??
                                ""
                        )
                        change(
                            "destinationCountry",
                            billToLocation?.shippingAddress?.address?.country ??
                                ""
                        )
                        change(
                            "destinationName",
                            billToLocation?.shippingAddress?.name
                        )
                        change("destinationCpgCode", billToLocation?.cpgCode)
                        change(
                            "destinationAddress",
                            billToLocation?.shippingAddress?._id
                        )
                        change(
                            "destinationContactInfo",
                            billToLocation?.shippingAddress
                        )
                    }
                }
            )
        } else if (field === "destination") {
            this.setState({ showDestinationOverlay: false })
        }
        clearSubmitErrors()
    }

    handleAddressSelect = (field, item) => {
        const { formValues, change, clearSubmitErrors } = this.props
        const billToLocation = this.props.locations.find(
            location => location.cpgCode === formValues.cpg
        )
        clearSubmitErrors()
        change(field, get(item, "address.postalCode"))
        change(`${field}Name`, get(item, "name"))
        change(`${field}CpgCode`, "")
        change(`${field}Contact`, get(item, "contact.id"))
        change(`${field}Address`, get(item, "id"))
        change(`${field}City`, get(item, "address.city"))
        change(`${field}Country`, get(item, "address.country"))
        change(`${field}State`, get(item, "address.state"))
        change(`${field}Street`, get(item, "address.street1"))
        change(`${field}ContactInfo`, item.contact ? item : "")
        change(`${field}AddressInfo`, item.contact ? "" : item)

        if (field === "origin") {
            this.setState(
                () => ({ showOriginOverlay: false }),
                () => {
                    if (
                        !formValues?.destinationState &&
                        billToLocation?.isShipperEnabled
                    ) {
                        change(
                            "destination",
                            billToLocation?.shippingAddress?.address?.postalCode ?? ""
                        )
                        change(
                            "destinationCity",
                            billToLocation?.shippingAddress?.address?.city ?? ""
                        )
                        change(
                            "destinationState",
                            billToLocation?.shippingAddress?.address?.state ?? ""
                        )
                        change(
                            "destinationStreet",
                            billToLocation?.shippingAddress?.address?.street1 ?? ""
                        )
                        change(
                            "destinationCountry",
                            billToLocation?.shippingAddress?.address?.country ?? ""
                        )
                        change(
                            "destinationName",
                            billToLocation?.shippingAddress?.name
                        )
                        change(
                            "destinationCpgCode",
                            billToLocation?.cpgCode
                        )
                        change(
                            "destinationAddress",
                            billToLocation?.shippingAddress?._id
                        )
                        change(
                            "destinationContactInfo",
                            billToLocation?.shippingAddress
                        )
                    }
                }
            )
        } else if (field === "destination") {
            this.setState({ showDestinationOverlay: false })
        }
        clearSubmitErrors()
    }

    resetSelection = field => {
        const { change } = this.props

        change(`${field}Name`, null)
        change(`${field}City`, null)
        change(`${field}State`, null)
        change(`${field}Street`, null)
        change(`${field}Contact`, null)
        change(`${field}Address`, null)
        change(`${field}ContactInfo`, null)
        change(`${field}AddressInfo`, null)
    }

    clearAll = obj => {
        const { change, initialValues, untouched } = this.props

        for (let i in obj) {
            switch (i) {
                case "handlingUnits":
                    change(i, [newQuickQuoteHandlingUnit])
                    break
                case "billToLocation":
                case "billToLocationAddress":
                case "cpg":
                case "deliveryAccessorials":
                case "pickupAccessorials":
                case "pickupDate":
                case "totalLinearFeet":
                case "volumeLTL":
                case "includeThirdParty":
                case "isQuickQuote":
                    change(i, initialValues[i])
                    untouched(i)
                    break
                default:
                    change(i, null)
                    untouched(i)
            }
        }
        untouched("items[0].freightClass")
        untouched("items[0].weight")
    }

    swapFields = () => {
        const { formValues, change } = this.props

        change("origin", get(formValues, "destination", ""))
        change("originCity", get(formValues, "destinationCity", ""))
        change("originState", get(formValues, "destinationState", ""))
        change("originCountry", get(formValues, "destinationCountry", ""))
        change("originStreet", get(formValues, "destinationStreet", ""))
        change("originName", get(formValues, "destinationName", ""))
        change("originCpgCode", get(formValues, "destinationCpgCode", ""))
        change("originContact", get(formValues, "destinationContact", ""))
        change(
            "originContactInfo",
            get(formValues, "destinationContactInfo", "")
        )
        change("originAddress", get(formValues, "destinationAddress", ""))
        change(
            "originAddressInfo",
            get(formValues, "destinationAddressInfo", "")
        )
        change("destination", get(formValues, "origin", ""))
        change("destinationCity", get(formValues, "originCity", ""))
        change("destinationState", get(formValues, "originState", ""))
        change("destinationCountry", get(formValues, "originCountry", ""))
        change("destinationStreet", get(formValues, "originStreet", ""))
        change("destinationName", get(formValues, "originName", ""))
        change("destinationCpgCode", get(formValues, "originCpgCode", ""))
        change("destinationContact", get(formValues, "originContact", ""))
        change(
            "destinationContactInfo",
            get(formValues, "originContactInfo", "")
        )
        change("destinationAddress", get(formValues, "originAddress", ""))
        change(
            "destinationAddressInfo",
            get(formValues, "originAddressInfo", "")
        )
    }

    submitForm = async values => {
        if (get(values, "handlingUnits.length")) {
            await this.props.onSearchClick(values)
            this.props.loadQuote(get(this.props, "shipmentId"))
        } else {
            this.setState({ noItemsHelper: true })
        }
    }

    setTopResult = (result, resultType, field, state, country) => {
        if (resultType === "city") {
            this.handleCitySelect(field, result, state, country)
        } else if (resultType === "address") {
            this.handleAddressSelect(field, result)
        }
    }

    render() {
        const {
            classes,
            handleSubmit,
            performContactSearch,
            onPostalCodeChange,
            searchInProgress,
            formValues,
            submitting,
            addNewItem,
            openAddItemDialog,
            handleAddItemDialogClose,
            error,
            initialValues,
            formattedLocations,
        } = this.props
        const {
            anchorEl,
            showOriginOverlay,
            showDestinationOverlay,
            showBillToOverlay,
            noItemsHelper,
        } = this.state

        const inputProps = {
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                input: classes.input__field,
                focused: classes.input__field__focused,
            },
        }
        const inputSelectAutocompleteProps = {
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                input: classes.input__field__select__autocomplete,
                focused: classes.input__field__focused,
            },
        }
        const multilineProps = {
            multiline: true,
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                multiline: classes.multiline__field,
            },
        }
        const inputLabelProps = {
            shrink: true,
            FormLabelClasses: {
                root: classes.input__label,
                focused: classes.input__label,
            },
        }
        const formLabelProps = {
            classes: {
                root: classes.input__label,
                label: classes.input__label,
            },
        }
        const inputHelperProps = {
            classes: {
                root: classes.input__helper,
                error: classes.input__helper,
            },
        }

        const selectClasses = {
            icon: classes.select__icon,
        }

        return (
            <Fragment>
                <Paper className={classes.form_paper} xs={12}>
                    <form
                        autoComplete="off"
                        onSubmit={handleSubmit(this.submitForm)}
                    >
                        <ShipmentSnackbar
                            variant="error"
                            content={!submitting && error}
                        />
                        <Grid
                            container
                            justify="center"
                            direction="row"
                            className={classes.quote__form__container}
                            id={"quick_quote_form"}
                        >
                            <Grid xs={11} item container direction="row">
                                <Grid direction="column" xs={3}>
                                    <Field
                                        id="billToLocation__quickQuote"
                                        component={FormSelect}
                                        name="billToLocation"
                                        label={
                                            <FormattedMessage
                                                id="getRates.form__billToLocation"
                                                defaultMessage="Bill to Location"
                                            />
                                        }
                                        options={formattedLocations}
                                        billTo
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                        InputHelperProps={inputHelperProps}
                                        selectClasses={selectClasses}
                                        disabled
                                        onClick={e =>
                                            this.handleInputFocus(
                                                "billToLocation",
                                                e
                                            )
                                        }
                                        onBlur={e =>
                                            this.handleInputBlur(
                                                "billToLocation",
                                                e
                                            )
                                        }
                                    />
                                    <BillToLocationPicker
                                        onBillToLocationChange={
                                            this.onBillToLocationChange
                                        }
                                        options={formattedLocations}
                                        handleClose={e =>
                                            this.handleInputBlur(
                                                "billToLocation",
                                                e
                                            )
                                        }
                                        anchorEl={anchorEl}
                                        showBillToOverlay={showBillToOverlay}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                xs={11}
                                item
                                container
                                direction="row"
                                id={"quick_quote_fields"}
                            >
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    className={classes.location__container}
                                    xs={1}
                                >
                                    <Field
                                        component={FormSelectAutocompleteDark}
                                        name="originCountry"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__country"
                                                defaultMessage="Country"
                                            />
                                        }
                                        options={supportedCountryCodes}
                                        InputProps={
                                            inputSelectAutocompleteProps
                                        }
                                        InputLabelProps={inputLabelProps}
                                        selectClasses={selectClasses}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    className={classes.location__container}
                                    xs={2}
                                >
                                    <Field
                                        component={FormField}
                                        name="origin"
                                        label={
                                            <FormattedMessage
                                                id="getRates.form__whereFrom"
                                                defaultMessage="Where From?"
                                            />
                                        }
                                        inputRef={el => (this.originInput = el)}
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                        InputHelperProps={inputHelperProps}
                                        onChange={e => {
                                            performContactSearch(
                                                e.target.value,
                                                formValues?.originCountry
                                            )
                                            this.setState({
                                                origin: e.target.value,
                                            })
                                            onPostalCodeChange(
                                                e.target.value,
                                                "origin",
                                                formValues?.originCountry,
                                                true
                                            )
                                            this.resetSelection("origin")
                                        }}
                                        onFocus={e =>
                                            this.handleInputFocus("origin", e)
                                        }
                                        onBlur={e =>
                                            this.handleInputBlur("origin", e)
                                        }
                                        required
                                    />
                                    <Popper
                                        onMouseDown={() =>
                                            this.handleContainerMouseDown()
                                        }
                                        open={showOriginOverlay}
                                        anchorEl={anchorEl}
                                        placement="right-start"
                                        disablePortal
                                        modifiers={{
                                            flip: {
                                                enabled: true,
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement:
                                                    "scrollParent",
                                            },
                                        }}
                                        className={classes.popper}
                                    >
                                        <SuggestionBox
                                            searchInProgress={searchInProgress}
                                            addressesNeeded
                                            setTopResult={this.setTopResult}
                                            field={"origin"}
                                            term={this.state.origin}
                                            country={formValues?.originCountry}
                                            handleAddressSelect={item =>
                                                this.handleAddressSelect(
                                                    "origin",
                                                    item
                                                )
                                            }
                                            handleCitySelect={(
                                                city,
                                                state,
                                                country
                                            ) =>
                                                this.handleCitySelect(
                                                    "origin",
                                                    city,
                                                    state,
                                                    country
                                                )
                                            }
                                        />
                                    </Popper>
                                    {get(
                                        formValues,
                                        "originContactInfo.contact.name"
                                    ) && (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(
                                                formValues,
                                                "originContactInfo.contact.name"
                                            )}
                                        </Typography>
                                    )}
                                    {get(formValues, "originName") &&
                                    get(formValues, "originCity") &&
                                    get(formValues, "originState") ? (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(formValues, "originName")}
                                            {`${get(formValues, "originName") &&
                                                " - "}`}{" "}
                                            {get(formValues, "originCity")}
                                            {`${get(formValues, "originCity") &&
                                                ", "}`}{" "}
                                            {get(formValues, "originState")}
                                        </Typography>
                                    ) : (
                                        get(formValues, "originCity") &&
                                        get(formValues, "originState") && (
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.location__label
                                                }
                                            >
                                                {get(formValues, "originCity")}
                                                {`${get(
                                                    formValues,
                                                    "originCity"
                                                ) && ", "}`}{" "}
                                                {get(formValues, "originState")}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                                <Grid
                                    xs={1}
                                    item
                                    container
                                    direction="column"
                                    className={classes.switch_column_width}
                                >
                                    <SwapHorizIcon
                                        onClick={() => this.swapFields()}
                                        className={classes.swap__icon}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    className={classes.location__container}
                                    xs={1}
                                >
                                    <Field
                                        component={FormSelectAutocompleteDark}
                                        name="destinationCountry"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__country"
                                                defaultMessage="Country"
                                            />
                                        }
                                        options={supportedCountryCodes}
                                        InputProps={
                                            inputSelectAutocompleteProps
                                        }
                                        InputLabelProps={inputLabelProps}
                                        selectClasses={selectClasses}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    container
                                    direction="column"
                                    className={classes.location__container}
                                    xs={2}
                                >
                                    <Field
                                        component={FormField}
                                        name="destination"
                                        label={
                                            <FormattedMessage
                                                id="getRates.form__whereTo"
                                                defaultMessage="Where To?"
                                            />
                                        }
                                        inputRef={el =>
                                            (this.destinationInput = el)
                                        }
                                        InputProps={inputProps}
                                        InputLabelProps={inputLabelProps}
                                        InputHelperProps={inputHelperProps}
                                        onChange={e => {
                                            performContactSearch(
                                                e.target.value,
                                                formValues?.destinationCountry
                                            )
                                            this.setState({
                                                destination: e.target.value,
                                            })
                                            onPostalCodeChange(
                                                e.target.value,
                                                "destination",
                                                formValues?.destinationCountry
                                            )
                                            this.resetSelection("destination")
                                        }}
                                        onFocus={e =>
                                            this.handleInputFocus(
                                                "destination",
                                                e
                                            )
                                        }
                                        onBlur={e =>
                                            this.handleInputBlur(
                                                "destination",
                                                e
                                            )
                                        }
                                    />
                                    <Popper
                                        onMouseDown={() =>
                                            this.handleContainerMouseDown()
                                        }
                                        open={showDestinationOverlay}
                                        anchorEl={anchorEl}
                                        placement="right-start"
                                        disablePortal
                                        modifiers={{
                                            flip: {
                                                enabled: true,
                                            },
                                            preventOverflow: {
                                                enabled: true,
                                                boundariesElement:
                                                    "scrollParent",
                                            },
                                        }}
                                        className={classes.popper}
                                    >
                                        <SuggestionBox
                                            searchInProgress={searchInProgress}
                                            addressesNeeded
                                            setTopResult={this.setTopResult}
                                            field={"destination"}
                                            term={this.state.destination}
                                            country={
                                                formValues?.destinationCountry
                                            }
                                            handleAddressSelect={item =>
                                                this.handleAddressSelect(
                                                    "destination",
                                                    item
                                                )
                                            }
                                            handleCitySelect={(
                                                city,
                                                state,
                                                country
                                            ) =>
                                                this.handleCitySelect(
                                                    "destination",
                                                    city,
                                                    state,
                                                    country
                                                )
                                            }
                                        />
                                    </Popper>
                                    {get(
                                        formValues,
                                        "destinationContactInfo.contact.name"
                                    ) && (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(
                                                formValues,
                                                "destinationContactInfo.contact.name"
                                            )}
                                        </Typography>
                                    )}
                                    {get(formValues, "destinationName") &&
                                    get(formValues, "destinationCity") &&
                                    get(formValues, "destinationState") ? (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(formValues, "destinationName")}
                                            {`${get(
                                                formValues,
                                                "destinationName"
                                            ) && " - "}`}{" "}
                                            {get(formValues, "destinationCity")}
                                            {`${get(
                                                formValues,
                                                "destinationCity"
                                            ) && ", "}`}{" "}
                                            {get(
                                                formValues,
                                                "destinationState"
                                            )}
                                        </Typography>
                                    ) : (
                                        get(formValues, "destinationCity") &&
                                        get(formValues, "destinationState") && (
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.location__label
                                                }
                                            >
                                                {get(
                                                    formValues,
                                                    "destinationCity"
                                                )}
                                                {`${get(
                                                    formValues,
                                                    "destinationCity"
                                                ) && ", "}`}{" "}
                                                {get(
                                                    formValues,
                                                    "destinationState"
                                                )}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                                <Grid item container xs={4} justify="flex-end">
                                    <FieldArray
                                        name="handlingUnits"
                                        component={QuoteItems}
                                        classes={classes}
                                        selectClasses={selectClasses}
                                        multilineProps={multilineProps}
                                        formLabelProps={formLabelProps}
                                        inputProps={inputProps}
                                        inputHelperProps={inputHelperProps}
                                        inputLabelProps={inputLabelProps}
                                        inputSelectAutocompleteProps={
                                            inputSelectAutocompleteProps
                                        }
                                    />
                                    <Grid item container>
                                        <FieldArray
                                            name="handlingUnits"
                                            component={({ fields }) => (
                                                <Button
                                                    color="primary"
                                                    className={
                                                        classes.button__add_item
                                                    }
                                                    onClick={() => {
                                                        this.setState({
                                                            noItemsHelper: false,
                                                        })
                                                        addNewItem(fields)
                                                    }}
                                                >
                                                    +{" "}
                                                    <FormattedMessage
                                                        id="getRates.form__addItem"
                                                        defaultMessage="Add Item"
                                                    />
                                                </Button>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={9} />
                                <Grid item container xs={3}>
                                    <Grid item container xs={6}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classes.button__clear}
                                            onClick={() => {
                                                this.clearAll(initialValues)
                                                this.props.clearSubmitErrors()
                                            }}
                                        >
                                            <FormattedMessage
                                                id="getRates.form__clear"
                                                defaultMessage="CLEAR"
                                            />
                                        </Button>
                                    </Grid>

                                    <Grid
                                        item
                                        container
                                        xs={6}
                                        className={classes.submit}
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            className={
                                                classes.button__get_quotes
                                            }
                                            disabled={submitting}
                                        >
                                            <FormattedMessage
                                                id="getRates.form__getRates"
                                                defaultMessage="GET RATES"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={openAddItemDialog}
                            onClose={() => handleAddItemDialogClose()}
                        >
                            <DialogTitle id="alert-dialog-title">
                                <FormattedMessage
                                    id="getRates__completeItems"
                                    defaultMessage="Please complete all current items before moving on to a new one."
                                />
                            </DialogTitle>
                            <DialogActions>
                                <Button
                                    onClick={() => handleAddItemDialogClose()}
                                    color="primary"
                                    autoFocus
                                >
                                    <FormattedMessage
                                        id="generalTerms__ok"
                                        defaultMessage="OK"
                                    />
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Grid
                            item
                            container
                            xs={12}
                            justify="center"
                            alignItems="center"
                        >
                            {noItemsHelper && (
                                <Typography color="secondary">
                                    <FormattedMessage
                                        id="getRates.form__itemError2"
                                        defaultMessage="Please add at least one item"
                                    />
                                </Typography>
                            )}
                            <Grid item container xs={12} justify="center">
                                {get(formValues, "items") && (
                                    <Typography
                                        variant="caption"
                                        color="primary"
                                    >
                                        <FormattedMessage
                                            id="getRates.form__totalWeight"
                                            defaultMessage="Total Weight: {weight} lbs"
                                            values={{
                                                weight: getItemWeight(
                                                    formValues.items
                                                ),
                                            }}
                                        />
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Fragment>
        )
    }
}

QuotesDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    defaultLocation: PropTypes.object.isRequired,
}

const constructLabel = (cpgName, defaultNickname) => cpgName || defaultNickname

const constructSubLabel = (fedexFreightAccount, fedexBillToAccount) => {
    if (fedexFreightAccount && !fedexBillToAccount)
        return `Shipper Acct: ${fedexFreightAccount}`
    else if (fedexFreightAccount && fedexBillToAccount)
        return `Shipper Acct: ${fedexFreightAccount} | Third Party Acct: ${fedexBillToAccount}`
    else if (!fedexFreightAccount && fedexBillToAccount)
        return `Third Party Acct: ${fedexBillToAccount}`
}

const mapStateToProps = (state, props) => {
    const requestsInProgress = Object.keys(state.requests)
    const postalCodeLookupInProgress = requestsInProgress.some(key =>
        /lookupPostalCode/.test(key)
    )
    const contactSearchInProgress = state.contact.searchInProgress
    const locations = get(state, "user.profile.locations", [])

    const formattedLocations = locations
        .filter(
            item =>
                get(item, "users[0].permissions.createRates.value") &&
                !get(item, "users[0].permissions.suspended.value")
        )
        .map(item => {
            const { city, state: addressState } = get(
                item,
                "shippingAddress.address",
                {}
            )
            const defaultNickname = `${get(
                item,
                "shippingAddress.name"
            )} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount
                ),
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
            }
        })

    return {
        initialValues: quickQuotesFormSelector(state, props),
        searchInProgress: postalCodeLookupInProgress || contactSearchInProgress,
        locations,
        formattedLocations,
        defaultLocation: locations.find(location => location.isDefault),
        formValues: getFormValues("quickQuote")(state),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    onSearchClick: async values => {
        const finalizedHandlingUnits = []
        values.handlingUnits.forEach((hu, index) => {
            finalizedHandlingUnits[index] = {
                ...newQuickQuoteHandlingUnit(),
                items: hu.items,
            }
        })

        const finalizedValues = {
            ...values,
            handlingUnits: finalizedHandlingUnits,
        }

        return dispatch(requestQuote(finalizedValues))
    },
    performContactSearch: (value, country) =>
        dispatch(searchContacts(value, "quote", undefined, country)),
    onPostalCodeChange: (value, field, country, fetchTimezone) =>
        dispatch(requestPostalCode(value, { country, field, fetchTimezone })),
    clearSubmitErrors: () => dispatch(clearSubmitErrors("quickQuote")),
    loadQuote: shipmentId => dispatch(populateQuoteForm(shipmentId)),
    untouched: field => dispatch(untouch("quickQuote", field)),
})

const freightClassValues = freightClasses.map(item => item.value)

const handlingUnitValidation = {
    "handlingUnits[].items": hasLengthGreaterThan(0)({
        field: { id: "items__item", defaultMessage: "Item" },
    }),
    "handlingUnits[].items[].weight": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].items[].freightClass": composeValidators(
        isRequired,
        isOneOfSilent(freightClassValues)
    )({ field: { id: "items__class", defaultMessage: "Class" } }),
}

const isValidOrigin = (field, fieldValue, formValues) => {
    const originCountry = get(formValues, "originCountry")
    if (isValidCountryPostalCode(fieldValue, originCountry)) {
        return false
    } else {
        return (
            <FormattedMessage
                id="getRates.form__invalidPostalCode"
                defaultMessage="Invalid {field} postal code"
                values={{ field: originCountry }}
            />
        )
    }
}

const isValidDestination = (field, fieldValue, formValues) => {
    const destinationCountry = get(formValues, "destinationCountry")
    if (isValidCountryPostalCode(fieldValue, destinationCountry)) {
        return false
    } else {
        return (
            <FormattedMessage
                id="getRates.form__invalidPostalCode"
                defaultMessage="Invalid {field} postal code"
                values={{ field: destinationCountry }}
            />
        )
    }
}

const formValidation = combineValidators({
    pickupDate: isRequired({
        field: {
            id: "generalTerms__shipDate",
            defaultMessage: "Ship Date",
        },
    }),
    destination: composeValidators(
        isRequired,
        isValidDestination
    )({
        field: {
            id: "generalTerms__destination",
            defaultMessage: "Destination",
        },
    }),
    origin: composeValidators(
        isRequired,
        isValidOrigin
    )({
        field: { id: "generalTerms__origin", defaultMessage: "Origin" },
    }),
    ...handlingUnitValidation,
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "quickQuote", // a unique identifier for this form
            validate: formValidation,
            asyncValidate: pastDateValidator,
            asyncChangeFields: ["origin", "pickupDate"],
            enableReinitialize: true,
        })(QuotesDrawer)
    )
)
