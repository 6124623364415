import React, { useState } from "react"
import TileGroup from "./TileGroup"
import { Button, Grid, Modal } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import ManageTiles from "./ManageTiles"
import { connect } from "react-redux"

const DashboardTiles = ({
    tileSettings,
    setActiveTileInStore,
    fetchElementsOnFilterApply,
    setToShipmentsOnly,
    setIsLoading,
    adjustPage,
    activeTileInStore,
}) => {
    const [manageTilesOpen, setManageTilesOpen] = useState(false)

    const activateTile = async value => {
        setIsLoading(true)
        setActiveTileInStore(value)
        await fetchElementsOnFilterApply()
        adjustPage(1)
        setToShipmentsOnly()
        setIsLoading(false)
    }

    const onClose = () => {
        setManageTilesOpen(false)
    }

    return (
        <Grid item container>
            <Grid item container xs={10}>
                <TileGroup
                    tileSettings={tileSettings}
                    activeTile={activeTileInStore}
                    activateTile={activateTile}
                />
            </Grid>
            <Grid item container xs={2} justify="flex-end" alignItems="center">
                <Button
                    color="primary"
                    onClick={() => {
                        setManageTilesOpen(true)
                    }}
                >
                    <FormattedMessage
                        id="dashboard.tiles__manage"
                        defaultMessage="Manage Dashboard"
                    />
                </Button>
            </Grid>
            <Modal open={manageTilesOpen} onClose={onClose}>
                <ManageTiles onClose={onClose} activateTile={activateTile} />
            </Modal>
        </Grid>
    )
}

const mapStateToProps = state => {
    const tileSettings = state?.user?.profile?.preferences?.tileSettings
    const activeTileInStore = state?.dashboard?.activeDashboardTile

    return {
        tileSettings,
        activeTileInStore,
    }
}

export default connect(mapStateToProps)(DashboardTiles)
