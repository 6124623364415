import React from "react"
import { withStyles, Grid, Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    actions__container: {
        marginBottom: theme.spacing.unit * 2,
    },
    button: {
        marginRight: theme.spacing.unit * 2,
    },
})

export const PrintableModalActions = withStyles(styles)(
    ({ handleClose, handlePrint, handleEmail, classes }) => (
        <Grid container className={classes.actions__container}>
            <Grid item xs={12}>
                <Button
                    variant="outlined"
                    onClick={handlePrint}
                    className={classes.button}
                >
                    <FormattedMessage
                        id="generalTerms__print"
                        defaultMessage="Print"
                    />
                </Button>
                <Button
                    variant="outlined"
                    onClick={handleEmail}
                    className={classes.button}
                >
                    <FormattedMessage
                        id="generalTerms__email"
                        defaultMessage="Email"
                    />
                </Button>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                    className={classes.button}
                >
                    <FormattedMessage
                        id="generalTerms__close"
                        defaultMessage="Close"
                    />
                </Button>
            </Grid>
        </Grid>
    )
)
