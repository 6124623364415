import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { get } from "lodash"
import { Typography, withStyles, Paper, Grid } from "@material-ui/core"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { quoteSelect } from "../../../actions/quote"
import RateBreakdown from "../../dashboard/components/rateBreakdown"
import { Checkbox } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { currencyLabel } from "../../util/currencyLabel"

const styles = theme => ({
    gridListTile: {
        borderBottom: "1px solid lightgrey",
        padding: "10px",
        width: "100%",
        minHeight: "77px",
    },
    expansion__panel: {
        width: "100%",
        padding: 0,
    },
    summary__content: {
        margin: "0px !important",
    },
    grid__paper: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    paperContainer: {
        padding: "12px",
    },
    individualRate: {
        borderTop: "solid 1px #F5F5F5",
    },
    card__enabled: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#D3D3D3",
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    card: {
        padding: "12px !important",
        minHeight: "92px",
        width: "82%",
    },
    card__disabled: {
        cursor: "default",
    },
    card__vendor: {
        minHeight: "92px",
        padding: "12px !important",
    },
    typography__rightAlign: {
        width: "100%",
    },
    card__selected: {
        backgroundColor: theme.palette.secondary.light,
    },
    exception__container: {
        padding: "15px",
        minHeight: "92px",
    },
    expansionPanel__root: {
        width: "100%",
    },
    header__label: {
        padding: "0 25px",
    },
})

const FreightDirectReturnsQuoteCardPresentation = props => {
    const dispatch = useDispatch()

    const onClick = (shipmentId, carrier, quoteId, mode, metadata) => {
        dispatch(quoteSelect(shipmentId, carrier, quoteId, mode, metadata))
    }

    const shipmentId = useSelector(
        state => state.identifiers.internalTrackingNumber
    )

    const shippedRateQuote = useSelector(
        state => state.quotes.active.shippedRateQuote
    )

    const share = useSelector(state => state.share)

    const {
        classes,
        disabled,
        canViewRateAmounts,
        serviceLevel,
        cardIndex,
        getBol,
    } = props

    const { rates, title, label } = serviceLevel

    const checkboxClasses = {
        root: classes.checkbox__root,
    }

    const summaryClasses = {
        content: classes.summary__content,
    }

    const { isOpen, selected } = share

    return (
        <Grid item container className={classes.paperContainer}>
            <Paper className={classes.grid__paper}>
                <Grid item container className={classes.gridListTile}>
                    <Grid item container justify="center">
                        <Grid item container justify="center">
                            <Typography variant="subheading">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.header__label}
                        >
                            <Typography variant="caption" align="center">
                                {label}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {rates
                    .sort((x, y) => x.direction.localeCompare(y.direction))
                    .map((rate, rateIndex) => {
                        const {
                            rateQuoteDetail,
                            errorMessages,
                            mode,
                            direction,
                            carrierCode,
                            serviceLevel,
                            transitDays,
                            deliveryDateTime,
                        } = rate

                        const isThirdParty = rate.direction === "THIRD_PARTY"

                        const deliveryDate =
                            Number.isInteger(transitDays) && deliveryDateTime

                        const isSelectedRate =
                            shippedRateQuote?.mode === mode &&
                            carrierCode === shippedRateQuote?.carrierCode &&
                            direction === shippedRateQuote?.direction &&
                            serviceLevel?.code ===
                                shippedRateQuote?.serviceLevel?.code

                        const isException =
                            errorMessages?.length &&
                            !rateQuoteDetail?.totalInPreferredCurrency &&
                            !rateQuoteDetail?.total

                        const isPickUpAndDestroyWithRate =
                            rate.serviceLevel?.code ===
                                "BASIC_PICKUP_AND_DESTROY" &&
                            rateQuoteDetail?.totalInPreferredCurrency &&
                            rateQuoteDetail?.total

                        const isPickUpAndDestroy =
                            rate.serviceLevel?.code ===
                            "BASIC_PICKUP_AND_DESTROY"

                        return (
                            <Grid
                                item
                                container
                                className={
                                    rateIndex !== 0
                                        ? classes.individualRate
                                        : ""
                                }
                            >
                                {isException ? (
                                    <Grid
                                        item
                                        container
                                        justify="center"
                                        className={classes.exception__container}
                                    >
                                        {errorMessages.map(error => (
                                            <Grid
                                                item
                                                container
                                                className={classes.l}
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    variant="caption"
                                                    color="secondary"
                                                >
                                                    {error?.message}
                                                </Typography>
                                            </Grid>
                                        ))}
                                    </Grid>
                                ) : rate?.accountType === "VENDOR" ||
                                  !canViewRateAmounts ||
                                  (isPickUpAndDestroy &&
                                      !isPickUpAndDestroyWithRate) ? (
                                    <Grid item container>
                                        <Grid
                                            item
                                            container
                                            className={`${
                                                classes.card__vendor
                                            } ${isSelectedRate &&
                                                classes.card__selected} ${
                                                isOpen || disabled
                                                    ? classes.card__disabled
                                                    : classes.card__enabled
                                            }`}
                                            onClick={e => {
                                                e.stopPropagation()
                                                if (!disabled && !isOpen) {
                                                    onClick(
                                                        shipmentId,
                                                        rate.carrierCode,
                                                        rate.index,
                                                        rate.mode,
                                                        rate.direction,
                                                        {
                                                            rateIndex,
                                                            cardIndex,
                                                        }
                                                    )

                                                    getBol()
                                                }
                                            }}
                                            alignContent="flex-start"
                                        >
                                            <Grid item container xs={4}>
                                                <Typography color="primary">
                                                    <FormattedMessage
                                                        id="rateResults__bookNow"
                                                        defaultMessage="Book Now"
                                                    />
                                                </Typography>
                                            </Grid>

                                            <Grid item container xs={8}>
                                                <Typography
                                                    align="right"
                                                    className={
                                                        classes.typography__rightAlign
                                                    }
                                                >
                                                    {rate.carrierCode ===
                                                    "FXFE" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__FedExPriority"
                                                            defaultMessage="FedEx Priority"
                                                        />
                                                    ) : rate.carrierCode ===
                                                      "FXNL" ? (
                                                        <FormattedMessage
                                                            id="generalTerms__FedExEconomy"
                                                            defaultMessage="FedEx Economy"
                                                        />
                                                    ) : (
                                                        rate.carrierCode
                                                    )}
                                                </Typography>
                                            </Grid>
                                            <Grid item container xs={4}>
                                                {isOpen && (
                                                    <Checkbox
                                                        classes={
                                                            checkboxClasses
                                                        }
                                                        checked={selected}
                                                        onClick={e =>
                                                            e.stopPropagation()
                                                        }
                                                        onChange={() =>
                                                            onClick(
                                                                shipmentId,
                                                                rate.carrierCode,
                                                                rate.index,
                                                                rate.mode,
                                                                rate.direction,
                                                                {
                                                                    rateIndex,
                                                                    cardIndex,
                                                                }
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item container xs={8}>
                                                <Typography
                                                    variant="caption"
                                                    align="right"
                                                    className={
                                                        classes.typography__rightAlign
                                                    }
                                                >
                                                    {(isPickUpAndDestroyWithRate ||
                                                        (!isPickUpAndDestroy &&
                                                            !!deliveryDate)) &&
                                                        deliveryDate.format(
                                                            "dddd, MMM Do"
                                                        )}
                                                </Typography>
                                                {isThirdParty && (
                                                    <Typography
                                                        variant="caption"
                                                        align="right"
                                                        className={
                                                            classes.typography__rightAlign
                                                        }
                                                    >
                                                        <FormattedMessage
                                                            id="getRates.form__thirdPartyPricing"
                                                            defaultMessage="Third Party Pricing"
                                                        />
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <ExpansionPanel
                                        className={classes.expansionPanel__root}
                                    >
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            className={classes.expansion__panel}
                                            classes={summaryClasses}
                                        >
                                            <Grid
                                                item
                                                container
                                                className={`${
                                                    classes.card
                                                } ${isSelectedRate &&
                                                    classes.card__selected} ${
                                                    isOpen || disabled
                                                        ? classes.card__disabled
                                                        : classes.card__enabled
                                                }`}
                                                onClick={e => {
                                                    e.stopPropagation()
                                                    if (!disabled && !isOpen) {
                                                        onClick(
                                                            shipmentId,
                                                            rate.carrierCode,
                                                            rate.index,
                                                            rate.mode,
                                                            rate.direction,
                                                            {
                                                                rateIndex,
                                                                cardIndex,
                                                            }
                                                        )

                                                        getBol()
                                                    }
                                                }}
                                                alignContent="flex-start"
                                            >
                                                <Grid item container xs={4}>
                                                    {canViewRateAmounts && (
                                                        <Typography>
                                                            {currencyLabel(
                                                                rate?.preferredCurrencyCode,
                                                                rate
                                                                    ?.rateQuoteDetail
                                                                    ?.totalInPreferredCurrency
                                                                    ? rate
                                                                          ?.rateQuoteDetail
                                                                          ?.totalInPreferredCurrency
                                                                    : rate
                                                                          ?.rateQuoteDetail
                                                                          ?.total
                                                            )}
                                                        </Typography>
                                                    )}
                                                </Grid>

                                                <Grid item container xs={8}>
                                                    <Typography
                                                        align="right"
                                                        className={
                                                            classes.typography__rightAlign
                                                        }
                                                    >
                                                        {rate.carrierCode ===
                                                        "FXFE" ? (
                                                            <FormattedMessage
                                                                id="generalTerms__FedExPriority"
                                                                defaultMessage="FedEx Priority"
                                                            />
                                                        ) : rate.carrierCode ===
                                                          "FXNL" ? (
                                                            <FormattedMessage
                                                                id="generalTerms__FedExEconomy"
                                                                defaultMessage="FedEx Economy"
                                                            />
                                                        ) : (
                                                            rate.carrierCode
                                                        )}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={4}>
                                                    {isOpen && (
                                                        <Checkbox
                                                            classes={
                                                                checkboxClasses
                                                            }
                                                            checked={selected}
                                                            onClick={e =>
                                                                e.stopPropagation()
                                                            }
                                                            onChange={() =>
                                                                onClick(
                                                                    shipmentId,
                                                                    rate.carrierCode,
                                                                    rate.index,
                                                                    rate.mode,
                                                                    rate.direction,
                                                                    {
                                                                        rateIndex,
                                                                        cardIndex,
                                                                    }
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </Grid>
                                                <Grid item container xs={8}>
                                                    <Typography
                                                        variant="caption"
                                                        align="right"
                                                        className={
                                                            classes.typography__rightAlign
                                                        }
                                                    >
                                                        {(isPickUpAndDestroyWithRate ||
                                                            (!isPickUpAndDestroy &&
                                                                !!deliveryDate)) &&
                                                            deliveryDate.format(
                                                                "dddd, MMM Do"
                                                            )}
                                                    </Typography>
                                                    {isThirdParty && (
                                                        <Typography
                                                            variant="caption"
                                                            align="right"
                                                            className={
                                                                classes.typography__rightAlign
                                                            }
                                                        >
                                                            <FormattedMessage
                                                                id="getRates.form__thirdPartyPricing"
                                                                defaultMessage="Third Party Pricing"
                                                            />
                                                        </Typography>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <RateBreakdown
                                                listView={false}
                                                charges={get(
                                                    rate,
                                                    "rateQuoteDetail.charges"
                                                )}
                                                totalInPreferredCurrency={
                                                    get(
                                                        rate,
                                                        "rateQuoteDetail.totalInPreferredCurrency"
                                                    )
                                                        ? get(
                                                              rate,
                                                              "rateQuoteDetail.totalInPreferredCurrency"
                                                          )
                                                        : get(
                                                              rate,
                                                              "rateQuoteDetail.total"
                                                          )
                                                }
                                                preferredCurrencyCode={get(
                                                    rate,
                                                    "preferredCurrencyCode"
                                                )}
                                                gridSize={12}
                                                paddingRight={"3%"}
                                                quoteID={
                                                    rate.capacityProviderQuoteNumber
                                                }
                                            />
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )}
                            </Grid>
                        )
                    })}
            </Paper>
        </Grid>
    )
}

export const FreightDirectReturnsQuoteCard = withStyles(styles)(
    FreightDirectReturnsQuoteCardPresentation
)
