import { Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import { supportedCountryCodes } from "../../../../misc"
import FormField from "../../../form/form-field"
import FormSelect from "../../../form/form-select"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import FormZipCode from "../../../form/form-zip-code"
import normalizePhone from "../../../util/normalizePhone"

const useStyles = makeStyles({
    section: {
        paddingTop: "28px",
    },
})

export default function BrokerForm({ country, cities = [] }) {
    const classes = useStyles()

    return (
        <Grid item container>
            <Grid container>
                <Grid item container xs={6}>
                    <Field
                        name={"brokerName"}
                        label={
                            <FormattedMessage
                                id="generalTerms__brokerName"
                                defaultMessage="Broker Name"
                            />
                        }
                        component={FormField}
                        required
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Field
                        name={"email.email_address"}
                        label={
                            <FormattedMessage
                                id="generalTerms__email"
                                defaultMessage="Email Address"
                            />
                        }
                        component={FormField}
                        required
                    />
                </Grid>
                <Grid item container xs={4}>
                    <Field
                        name={"phone.phone_number"}
                        label={
                            <FormattedMessage
                                id="generalTerms__phoneNumber"
                                defaultMessage="Phone Number"
                            />
                        }
                        component={FormField}
                        required
                        normalize={normalizePhone(country || "US")}
                    />
                </Grid>
                <Grid item container xs={2}>
                    <Field
                        name={"phone.extension"}
                        label={
                            <FormattedMessage
                                id="generalTerms__extension"
                                defaultMessage="Extension"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Grid item container xs={6}>
                    <Field
                        name={"contactName"}
                        label={
                            <FormattedMessage
                                id="generalTerms__contactName"
                                defaultMessage="Contact Name"
                            />
                        }
                        component={FormField}
                    />
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid item xs={6}>
                        <Field
                            name="address.street1"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__address"
                                    defaultMessage="Address"
                                />
                            }
                            maxLength="30"
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="address.street2"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__address2"
                                    defaultMessage="Address 2"
                                />
                            }
                            maxLength="30"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="address.country"
                            component={FormSelect}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__country"
                                    defaultMessage="Country"
                                />
                            }
                            options={supportedCountryCodes}
                            maxLength="30"
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="address.postalCode"
                            component={FormZipCode}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__zipPostalCode"
                                    defaultMessage="Zip/Postal Code"
                                />
                            }
                            country={country}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="address.city"
                            component={FormSelectAutocomplete}
                            label={
                                <FormattedMessage
                                    id="generalTerms__city"
                                    defaultMessage="City"
                                />
                            }
                            customValue
                            options={cities.map(c => ({
                                value: c,
                                label: c,
                            }))}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="address.state"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="generalTerms__stateProvince"
                                    defaultMessage="State/Province"
                                />
                            }
                            inputProps={{ maxLength: 2 }}
                            required
                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid item container xs={6}>
                        <Field
                            name={"portName"}
                            label={
                                <FormattedMessage
                                    id="generalTerms__portName"
                                    defaultMessage="Port Name"
                                />
                            }
                            component={FormField}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            name={"portCode"}
                            label={
                                <FormattedMessage
                                    id="generalTerms__portNumber"
                                    defaultMessage="Port Number"
                                />
                            }
                            component={FormField}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
