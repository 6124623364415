import React, { Component, Fragment } from "react"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import Snackbar from "@material-ui/core/Snackbar"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"

class FlashMessage extends Component {
    state = {
        open: true,
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") return
        this.setState({ open: false })
    }

    render() {
        const {
            proNumber,
            pickupLater,
            shipmentConfirmationNumber,
            isIntl,
        } = this.props
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={this.state.open}
                autoHideDuration={6000}
                onClose={this.handleSnackbarClose}
            >
                <ErrorSnackbarContentWrapper
                    variant="success"
                    onClose={this.handleSnackbarClose}
                    message={
                        <span>
                            <Typography>
                                {pickupLater ? (
                                    <FormattedMessage
                                        id="orderResults.flash__pickupCreatedMessage"
                                        defaultMessage="Your shipment has been created. Thank you for using LTL Select!"
                                    />
                                ) : (
                                    <FormattedMessage
                                        id="orderResults.flash__pickupProcessedMessage"
                                        defaultMessage="Your shipment has been processed. Thank you for using LTL Select!"
                                    />
                                )}
                            </Typography>
                            {!pickupLater && (
                                <Fragment>
                                    <Typography>
                                        <FormattedMessage
                                            id="orderResults.flash__pickupNumberMessage"
                                            defaultMessage="Your pickup confirmation number is: {shipmentConfirmationNumber}"
                                            values={{
                                                shipmentConfirmationNumber,
                                            }}
                                        />
                                    </Typography>
                                    {proNumber && (
                                        <Typography>{`Tracking ID: ${proNumber}`}</Typography>
                                    )}
                                </Fragment>
                            )}
                            {isIntl && (
                                <Fragment>
                                    <Typography>
                                        <FormattedMessage
                                            id="orderResults.flash__internationalMessage"
                                            defaultMessage="Please remember to prepare the certificate of origin and commercial invoice for this shipment."
                                        />
                                    </Typography>
                                </Fragment>
                            )}
                        </span>
                    }
                />
            </Snackbar>
        )
    }
}

export default FlashMessage
