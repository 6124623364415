import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CropSquare from "@material-ui/icons/CropSquare"
import Avatar from "@material-ui/core/Avatar"
import { FormattedMessage } from "react-intl"
import HandlingUnitsTable from "./handlingUnitsTable"

const styles = {
    description: {
        width: "40%",
    },
    section__title: {
        textDecoration: "underline",
    },
    card: {
        width: "100%",
    },
    topTableRow: {
        borderTop: "solid 1px #D4D4D4",
    },
}

class HandlingUnitsDisplay extends React.Component {
    render() {
        const {
            classes,
            handlingUnits,
            preferredSystemOfMeasurement,
        } = this.props

        return (
            <Grid item container xs={12}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Typography variant="title">
                                <FormattedMessage
                                    id="orderDetails__handlingUnits"
                                    defaultMessage="Handling Units"
                                />
                            </Typography>
                        }
                        avatar={
                            <Avatar>
                                <CropSquare />
                            </Avatar>
                        }
                    />
                    <CardContent className={classes.content}>
                        <HandlingUnitsTable
                            preferredSystemOfMeasurement={
                                preferredSystemOfMeasurement
                            }
                            handlingUnits={handlingUnits}
                        />
                    </CardContent>
                </Card>
            </Grid>
        )
    }
}

export default withStyles(styles)(HandlingUnitsDisplay)
