import React, { Component, Fragment } from "react"
import { Field, formValueSelector, reduxForm, change } from "redux-form"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    Grid,
    Button,
    withStyles,
    ListItemIcon,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
    Divider,
} from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Create"
import FormField from "../form/form-field"
import FormCheckbox from "../form/form-checkbox"
import {
    toggleEditMode,
    toggleExpand,
    cancelNewEmptyItem,
} from "../../actions/item"
import {
    isRequired,
    isRequiredIf,
    isNumeric,
    hasHazMatIdPrefix,
    hasLength,
} from "../../actions/validation"
import { createGAEvent } from "../../actions"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import { FormattedMessage, defineMessages, injectIntl } from "react-intl"
import { combineValidators, composeValidators } from "revalidate"
import { allPackageTypeOptions, provideAllPackageTypes } from "./constants"

export const packageTypesMessages = defineMessages({
    PLT4840: {
        id: "items.packageTypes__PLT4840",
        defaultMessage: "Pallet 48x40",
    },
    PLT4848: {
        id: "items.packageTypes__PLT4848",
        defaultMessage: "Pallet 48x48",
    },
    PLT: {
        id: "items.packageTypes__PLT",
        defaultMessage: "Pallet",
    },
    SKID: {
        id: "items.packageTypes__SKID",
        defaultMessage: "Skid",
    },
    BAG: {
        id: "items.packageTypes__BAG",
        defaultMessage: "Bag",
    },
    BOX: {
        id: "items.packageTypes__BOX",
        defaultMessage: "Box",
    },
    BUNDLE: {
        id: "items.packageTypes__BUNDLE",
        defaultMessage: "Bundle",
    },
    COIL: {
        id: "items.packageTypes__COIL",
        defaultMessage: "Coil",
    },
    CRATE: {
        id: "items.packageTypes__CRATE",
        defaultMessage: "Crate",
    },
    CYLINDER: {
        id: "items.packageTypes__CYLINDER",
        defaultMessage: "Cylinder",
    },
    DRUM: {
        id: "items.packageTypes__DRUM",
        defaultMessage: "Drum",
    },
    PAIL: {
        id: "items.packageTypes__PAIL",
        defaultMessage: "Pail",
    },
    REEL: {
        id: "items.packageTypes__REEL",
        defaultMessage: "Reel",
    },
    ROLL: {
        id: "items.packageTypes__ROLL",
        defaultMessage: "Roll",
    },
    TUBE: {
        id: "items.packageTypes__TUBE",
        defaultMessage: "Tube & Pipe",
    },
})

export const packageTypes = [
    {
        value: "PLT4840",
        label: packageTypesMessages.PLT4840,
        dimensions: { length: 48, width: 40 },
    },
    {
        value: "PLT4848",
        label: packageTypesMessages.PLT4848,
        dimensions: { length: 48, width: 48 },
    },
    { value: "PLT", label: packageTypesMessages.PLT },
    { value: "SKID", label: packageTypesMessages.SKID },
    { value: "BAG", label: packageTypesMessages.BAG },
    { value: "BOX", label: packageTypesMessages.BOX },
    { value: "BUNDLE", label: packageTypesMessages.BUNDLE },
    { value: "COIL", label: packageTypesMessages.COIL },
    { value: "CRATE", label: packageTypesMessages.CRATE },
    { value: "CYLINDER", label: packageTypesMessages.CYLINDER },
    { value: "DRUM", label: packageTypesMessages.DRUM },
    { value: "PAIL", label: packageTypesMessages.PAIL },
    { value: "REEL", label: packageTypesMessages.REEL },
    { value: "ROLL", label: packageTypesMessages.ROLL },
    { value: "TUBE", label: packageTypesMessages.TUBE },
]

export const providePackageTypes = intl => {
    return packageTypes.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

export const freightClasses = [
    { value: 50, label: "50" },
    { value: 55, label: "55" },
    { value: 60, label: "60" },
    { value: 65, label: "65" },
    { value: 70, label: "70" },
    { value: 77.5, label: "77.5" },
    { value: 85, label: "85" },
    { value: 92.5, label: "92.5" },
    { value: 100, label: "100" },
    { value: 110, label: "110" },
    { value: 125, label: "125" },
    { value: 150, label: "150" },
    { value: 175, label: "175" },
    { value: 200, label: "200" },
    { value: 250, label: "250" },
    { value: 300, label: "300" },
    { value: 400, label: "400" },
    { value: 500, label: "500" },
]

const hazMatPkgGrpMessages = defineMessages({
    none: {
        id: "items.hazMatPkgGrp__none",
        defaultMessage: "None",
    },
})

export const hazMatPkgGrp = [
    { value: "I", label: "I" },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "NONE", label: hazMatPkgGrpMessages.none },
]

export const hazMatClasses = [
    {
        value: "1",
        label: {
            id: "items__hazmat__explosives",
            defaultMessage: "1 Explosives",
        },
    },
    {
        value: "1.1",
        label: {
            id: "items__hazmat__explosives_1.1",
            defaultMessage: "1.1 Mass Explosive Hazard",
        },
    },
    {
        value: "1.2",
        label: {
            id: "items__hazmat__explosives_1.2",
            defaultMessage: "1.2 Projection Hazard",
        },
    },
    {
        value: "1.3",
        label: {
            id: "items__hazmat__explosives_1.3",
            defaultMessage:
                "1.3 Fire and/or Minor Blast/Minor Projection Hazard",
        },
    },
    {
        value: "1.4",
        label: {
            id: "items__hazmat__explosives_1.4",
            defaultMessage: "1.4 Minor Explosion Hazard",
        },
    },
    {
        value: "1.5",
        label: {
            id: "items__hazmat__explosives_1.5",
            defaultMessage: "1.5 Very Insensitive With Mass Explosion Hazard",
        },
    },
    {
        value: "1.6",
        label: {
            id: "items__hazmat__explosives_1.6",
            defaultMessage:
                "1.6 Extremely Insensitive; No Mass Explosion Hazard",
        },
    },
    {
        value: "2",
        label: { id: "items__hazmat__gases", defaultMessage: "2 Gases" },
    },
    {
        value: "2.1",
        label: {
            id: "items__hazmat__gases_2.1",
            defaultMessage: "2.1 Flammable Gases",
        },
    },
    {
        value: "2.2",
        label: {
            id: "items__hazmat__gases_2.2",
            defaultMessage: "2.2 Nonflammable Gases",
        },
    },
    {
        value: "2.3",
        label: {
            id: "items__hazmat__gases_2.3",
            defaultMessage: "2.3 Toxic Gases",
        },
    },
    {
        value: "3",
        label: {
            id: "items__hazmat__liquids",
            defaultMessage: "3 Flammable and Combustible Liquids",
        },
    },
    {
        value: "4",
        label: {
            id: "items__hazmat__solids",
            defaultMessage: "4 Flammable Solids",
        },
    },
    {
        value: "4.1",
        label: {
            id: "items__hazmat__solids__4.1",
            defaultMessage: "4.1 Flammable Solids",
        },
    },
    {
        value: "4.2",
        label: {
            id: "items__hazmat__solids_4.2",
            defaultMessage: "4.2 Spontaneously Combustible",
        },
    },
    {
        value: "4.3",
        label: {
            id: "items__hazmat__solids__4.3",
            defaultMessage: "4.3 Dangerous When Wet",
        },
    },
    {
        value: "5",
        label: {
            id: "items__hazmat__subtstances__peroxides",
            defaultMessage: "5 Oxidizing Substances, Organic Peroxides",
        },
    },
    {
        value: "5.1",
        label: {
            id: "items__hazmat__subtstances__peroxides__5.1",
            defaultMessage: "5.1 Oxidizing Substances",
        },
    },
    {
        value: "5.2",
        label: {
            id: "items__hazmat__subtstances__peroxides__5.2",
            defaultMessage: "5.2 Organic Peroxides",
        },
    },
    {
        value: "6",
        label: {
            id: "items__hazmat__subtstances",
            defaultMessage: "6 Toxic Substances and Infectious Substances",
        },
    },
    {
        value: "6.1",
        label: {
            id: "items__hazmat__subtstances__6.1",
            defaultMessage: "6.1 Toxic Substances",
        },
    },
    {
        value: "6.2",
        label: {
            id: "items__hazmat__subtstances__6.2",
            defaultMessage: "6.2 Infectious Substances",
        },
    },
    {
        value: "7",
        label: {
            id: "items__hazmat__readioactive",
            defaultMessage: "7 Radioactive Material",
        },
    },
    {
        value: "8",
        label: {
            id: "items__hazmat__corrossives",
            defaultMessage: "8 Corrosives (Liquids And Solids)",
        },
    },
    {
        value: "9",
        label: {
            id: "items__hazmat__miscellaneous",
            defaultMessage: "9 Miscellaneous Hazardous Materials",
        },
    },
]

export const provideHazMatClasses = intl => {
    return hazMatClasses.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

export const hazMatContainers = [
    {
        value: "CTN",
        label: { id: "items__hazmat__containers__CTN", defaultMessage: "CTN" },
    },
    {
        value: "Cylinder",
        label: {
            id: "items__hazmat__containers__Cylinder",
            defaultMessage: "Cylinder",
        },
    },
    {
        value: "Drum",
        label: {
            id: "items__hazmat__containers__Drum",
            defaultMessage: "Drum",
        },
    },
    {
        value: "Tote",
        label: {
            id: "items__hazmat__containers__Tote",
            defaultMessage: "Tote",
        },
    },
    {
        value: "Bottle",
        label: {
            id: "items__hazmat__containers__Bottle",
            defaultMessage: "Bottle",
        },
    },
    {
        value: "Bucket",
        label: {
            id: "items__hazmat__containers__Bucket",
            defaultMessage: "Bucket",
        },
    },
    {
        value: "Can",
        label: { id: "items__hazmat__containers__Can", defaultMessage: "Can" },
    },
    {
        value: "Carboy",
        label: {
            id: "items__hazmat__containers__Carboy",
            defaultMessage: "Carboy",
        },
    },
    {
        value: "Crate",
        label: {
            id: "items__hazmat__containers__Crate",
            defaultMessage: "Crate",
        },
    },
    {
        value: "Dewar",
        label: {
            id: "items__hazmat__containers__Dewar",
            defaultMessage: "Dewar",
        },
    },
    {
        value: "IBC",
        label: { id: "items__hazmat__containers__IBC", defaultMessage: "IBC" },
    },
    {
        value: "Keg",
        label: { id: "items__hazmat__containers__Keg", defaultMessage: "Keg" },
    },
    {
        value: "Pail",
        label: {
            id: "items__hazmat__containers__Pail",
            defaultMessage: "Pail",
        },
    },
    {
        value: "Tank",
        label: {
            id: "items__hazmat__containers__Tank",
            defaultMessage: "Tank",
        },
    },
    {
        value: "CTN/Crate",
        label: {
            id: "items__hazmat__containers__CTN/Crate",
            defaultMessage: "CTN/Crate",
        },
    },
    {
        value: "CTN/Drum",
        label: {
            id: "items__hazmat__containers__CTN/Drum",
            defaultMessage: "CTN/Drum",
        },
    },
    {
        value: "Drum/Crate",
        label: {
            id: "items__hazmat__containers__Drum/Crate",
            defaultMessage: "Drum/Crate",
        },
    },
    {
        value: "Bag",
        label: { id: "items__hazmat__containers__Bag", defaultMessage: "Bag" },
    },
    {
        value: "Jerrican",
        label: {
            id: "items__hazmat__containers__Jerrican",
            defaultMessage: "Jerrican",
        },
    },
    {
        value: "Overpack",
        label: {
            id: "items__hazmat__containers__Overpack",
            defaultMessage: "Overpack",
        },
    },
    {
        value: "No PKG",
        label: {
            id: "items__hazmat__containers__No__PKG",
            defaultMessage: "No PKG",
        },
    },
    {
        value: "Case",
        label: {
            id: "items__hazmat__containers__Case",
            defaultMessage: "Case",
        },
    },
    {
        value: "Large Packaging",
        label: {
            id: "items__hazmat__containers__large__packaging",
            defaultMessage: "Large Packaging",
        },
    },
    {
        value: "Jar/Drum",
        label: {
            id: "items__hazmat__containers__Jar/Drum",
            defaultMessage: "Jar/Drum",
        },
    },
    {
        value: "PKG",
        label: { id: "items__hazmat__containers__PKG", defaultMessage: "PKG" },
    },
    {
        value: "Barrel",
        label: {
            id: "items__hazmat__containers__Barrel",
            defaultMessage: "Barrel",
        },
    },
    {
        value: "Cage",
        label: {
            id: "items__hazmat__containers__Cage",
            defaultMessage: "Cage",
        },
    },
    {
        value: "No Pkg/Skid",
        label: {
            id: "items__hazmat__containers__No__Pkg/Skid",
            defaultMessage: "No Pkg/Skid",
        },
    },
    {
        value: "Box",
        label: { id: "items__hazmat__containers__Box", defaultMessage: "Box" },
    },
    {
        value: "Mini - Portafeed",
        label: {
            id: "items__hazmat__containers__Mini__Portafeed",
            defaultMessage: "Mini - Portafeed",
        },
    },
]

export const provideHazMatContainers = intl => {
    return hazMatContainers.map(entry => ({
        value: entry.value,
        label: intl.formatMessage(entry.label),
    }))
}

const styles = theme => ({
    location__container: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    checkbox__container: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
    },
    divider__container: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },

    button__rounded: {
        borderRadius: "25px",
        marginTop: "10px",
        marginBottom: "10px",
        marginRight: "10px",
    },
    input__number: {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    panel__summary: {
        height: "60px",
        "&:hover": {
            backgroundColor: "#F4F4F4",
        },
    },
    textFieldDropdown: {
        marginTop: "-2px",
    },
    actions__container: {
        marginTop: "145px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        paddingRight: "20px",
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
})

let LineItemForm = ({
    isHazMat,
    classes,
    handleEdit,
    handleSave,
    handleDelete,
    handleCancel,
    handleClear,
    _id,
    editMode,
    onPackageTypeChange,
    onDimensionChange,
    intl,
}) => (
    <Grid container spacing={16}>
        <Grid item container xs={1} justify="center" alignItems="flex-end">
            <Field
                name={"isHazMat"}
                label={
                    <FormattedMessage
                        id="items__hazMat"
                        defaultMessage="HazMat"
                    />
                }
                component={FormCheckbox}
                disabled={!editMode}
                enableSwitch
            />
        </Grid>
        <Grid item container xs={4}>
            <Field
                name={"description"}
                label={[
                    "* ",
                    <FormattedMessage
                        id="items__description"
                        defaultMessage="Description"
                    />,
                ]}
                component={FormField}
                className={classes.textField__description}
                disabled={!editMode}
            />
        </Grid>
        <Grid item container xs={2} className={classes.textFieldDropdown}>
            <Field
                name={"freightClass"}
                label={
                    <FormattedMessage
                        id="items__class"
                        defaultMessage="Class"
                    />
                }
                component={FormSelectAutocomplete}
                options={freightClasses}
                disabled={!editMode}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name={"weight"}
                label={
                    <FormattedMessage
                        id="items__weight"
                        defaultMessage="Weight"
                    />
                }
                type="number"
                inputProps={{
                    min: 0,
                    className: classes.input__number,
                }}
                component={FormField}
                className={classes.textField}
                disabled={!editMode}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name={"packageType"}
                label={
                    <FormattedMessage
                        id="items__packageType"
                        defaultMessage="Package Type"
                    />
                }
                component={FormSelectAutocomplete}
                options={provideAllPackageTypes(intl)}
                className={classes.textField__packageType}
                disabled={!editMode}
                onChange={(e, value) => onPackageTypeChange(value, _id)}
            />
        </Grid>

        <Grid item container xs={4}>
            <Grid item container xs={4}>
                <Field
                    name={"length"}
                    label={
                        <FormattedMessage
                            id="items__length"
                            defaultMessage="Length"
                        />
                    }
                    component={FormField}
                    type="number"
                    inputProps={{
                        min: 0,
                        className: classes.input__number,
                    }}
                    className={classes.textField__dimensions}
                    disabled={!editMode}
                    onChange={(e, value) =>
                        onDimensionChange(value, _id, "length")
                    }
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name={"width"}
                    label={
                        <FormattedMessage
                            id="items__width"
                            defaultMessage="Width"
                        />
                    }
                    component={FormField}
                    type="number"
                    inputProps={{
                        min: 0,
                        className: classes.input__number,
                    }}
                    className={classes.textField__dimensions}
                    disabled={!editMode}
                    onChange={(e, value) =>
                        onDimensionChange(value, _id, "width")
                    }
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name={"height"}
                    label={
                        <FormattedMessage
                            id="items__height"
                            defaultMessage="Height"
                        />
                    }
                    component={FormField}
                    type="number"
                    inputProps={{
                        min: 0,
                        className: classes.input__number,
                    }}
                    className={classes.textField__dimensions}
                    disabled={!editMode}
                />
            </Grid>
        </Grid>
        <Grid item container xs={2}>
            <Field
                name={"nmfc1"}
                label={
                    <FormattedMessage
                        id="generalTerms__nmfcCode"
                        defaultMessage="NMFC Code"
                    />
                }
                component={FormField}
                className={classes.textField__nmfc}
                inputProps={{ maxLength: 6 }}
                disabled={!editMode}
            />
        </Grid>
        <Grid item container xs={2}>
            <Field
                name={"nmfc2"}
                label={
                    <FormattedMessage
                        id="generalTerms__nmfcSubcode"
                        defaultMessage="NMFC Subcode"
                    />
                }
                component={FormField}
                className={classes.textField__nmfc}
                inputProps={{ maxLength: 2 }}
                disabled={!editMode}
            />
        </Grid>
        <Grid item container xs={1}>
            <Field
                name={"pieces"}
                label={
                    <FormattedMessage
                        id="items__pieces"
                        defaultMessage="Pieces"
                    />
                }
                component={FormField}
                type="number"
                inputProps={{
                    min: 0,
                    className: classes.input__number,
                }}
                className={classes.textField__pieces}
                disabled={!editMode}
            />
        </Grid>
        <Grid
            item
            container
            xs={1}
            className={classes.textFieldDropdown}
            direction="row"
            justify="flex-start"
            alignItems="flex-end"
        >
            <Field
                name={"stackable"}
                label={
                    <FormattedMessage
                        id="items__stackable"
                        defaultMessage="Stackable"
                    />
                }
                component={FormCheckbox}
                disabled={!editMode}
            />
        </Grid>
        {/* <Grid item container xs={2}>
            <Field
                name={'hsCode'}
                label="Harmonized Code"
                component={FormField}
                disabled={!editMode}
            />
        </Grid>
        <Grid item xs={2} />
        <Grid item container xs={4}>
        <Field
                name={'countryOfManufacture'}
                label="Country of Manufacture"
                component={FormSelectAutocomplete}
                options={countriesOfOrigin}
                disabled={!editMode}
            />
        </Grid> */}
        {isHazMat && (
            <React.Fragment>
                <Grid item className={classes.divider__container} xs={12}>
                    <Divider />
                </Grid>
                <Grid
                    item
                    container
                    xs={2}
                    className={classes.textFieldDropdown}
                >
                    <Field
                        name={"unNumber"}
                        label={
                            <FormattedMessage
                                id="items__unNumber"
                                defaultMessage="ID Numbers"
                            />
                        }
                        component={FormField}
                        className={classes.textField__hazMatHazClass}
                        disabled={!editMode}
                        inputProps={{ maxLength: 6 }}
                    />
                </Grid>
                <Grid item xs={4} className={classes.textFieldDropdown}>
                    <Field
                        name={"hazClass"}
                        label={
                            <FormattedMessage
                                id="items__hazClass"
                                defaultMessage="Haz Class"
                            />
                        }
                        component={FormSelectAutocomplete}
                        className={classes.textField__hazMatHazClass}
                        disabled={!editMode}
                        options={provideHazMatClasses(intl)}
                    />
                </Grid>
                <Grid item xs={2} className={classes.textFieldDropdown}>
                    <Field
                        name={"pkgGrp"}
                        label={
                            <FormattedMessage
                                id="items__pkgGrp"
                                defaultMessage="Pkg Grp"
                            />
                        }
                        component={FormSelectAutocomplete}
                        className={classes.textfield__hazMatPkgGrp}
                        disabled={!editMode}
                        options={hazMatPkgGrp}
                    />
                </Grid>
                <Grid item xs={2} className={classes.textFieldDropdown}>
                    <Field
                        name={"hazContainer"}
                        label={
                            <FormattedMessage
                                id="items__containerType"
                                defaultMessage="Container Type"
                            />
                        }
                        component={FormSelectAutocomplete}
                        className={classes.textfield__hazMatPkgGrp}
                        disabled={!editMode}
                        options={provideHazMatContainers(intl)}
                    />
                </Grid>
            </React.Fragment>
        )}
        <Grid
            item
            container
            xs={12}
            justify="flex-end"
            className={classes.actions__container}
        >
            <LineItemActions
                handleEdit={handleEdit}
                handleSave={handleSave}
                handleDelete={handleDelete}
                handleCancel={handleCancel}
                handleClear={handleClear}
                _id={_id}
                editMode={editMode || !_id}
            />
        </Grid>
    </Grid>
)

LineItemForm = injectIntl(withStyles(styles)(LineItemForm))

LineItemForm.propTypes = {
    isHazMat: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
}

let LineItemActions = ({
    _id,
    handleEdit,
    handleSave,
    handleDelete,
    handleClear,
    handleCancel,
    editMode,
    classes,
}) => (
    <Grid container>
        {_id ? (
            <Grid item container justify="flex-end">
                <Button
                    variant="contained"
                    className={classes.button__rounded}
                    onClick={handleDelete}
                >
                    <FormattedMessage
                        id="generalTerms__delete"
                        defaultMessage="Delete"
                    />
                </Button>
                {editMode && (
                    <Button
                        variant="contained"
                        className={classes.button__rounded}
                        onClick={handleCancel}
                    >
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="secondary"
                    className={classes.button__rounded}
                    onClick={editMode ? handleSave() : handleEdit}
                >
                    {editMode ? (
                        <FormattedMessage
                            id="generalTerms__save"
                            defaultMessage="Save"
                        />
                    ) : (
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    )}
                </Button>
            </Grid>
        ) : (
            <Grid item container justify="flex-end">
                <Grid item container justify="flex-end">
                    <a
                        rel="noopener noreferrer"
                        href="https://www.fedex.com/en-us/shipping/freight-services/class-calculator.html"
                        target="_blank"
                    >
                        <Typography
                            variant="caption"
                            className={classes.freightClass__helper}
                        >
                            <FormattedMessage
                                id="items__freightClassLink"
                                defaultMessage="What's my freight class?"
                            />
                        </Typography>
                    </a>
                </Grid>
                <Grid item container justify="flex-end">
                    <Button
                        variant="contained"
                        className={classes.button__rounded}
                        onClick={handleClear}
                    >
                        <FormattedMessage
                            id="generalTerms__clear"
                            defaultMessage="Clear"
                        />
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button__rounded}
                        onClick={handleSave()}
                    >
                        <FormattedMessage
                            id="generalTerms__add"
                            defaultMessage="Add"
                        />
                    </Button>
                </Grid>
            </Grid>
        )}
    </Grid>
)

LineItemActions = withStyles(styles)(LineItemActions)

LineItemActions.propTypes = {
    _id: PropTypes.string.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    editMode: PropTypes.bool.isRequired,
}

let LineItemData = ({ item, classes, hovering, handleEdit, handleDelete }) => {
    const packageType = (
        allPackageTypeOptions.find(i => i.value === item.packageType) || {}
    ).label
    const packageTypeTranslation = {
        id: packageType?.id,
        defaultMessage: packageType?.defaultMessage,
    }

    return (
        <Grid container>
            <Grid item container direction="column" justify="center" xs={2}>
                <Typography>
                    {item.pieces > 1 ? (
                        [
                            `${item.pieces} `,
                            <FormattedMessage
                                id="items__pieces"
                                defaultMessage="Pieces"
                            />,
                        ]
                    ) : (
                        <FormattedMessage
                            id="items__onePiece"
                            defaultMessage="1 Piece"
                        />
                    )}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={2}>
                <Typography>
                    {(item.description && item.description.length > 20
                        ? `${item.description.substring(0, 20)}...`
                        : item.description) || (
                        <FormattedMessage
                            id="items__description"
                            defaultMessage="Description"
                        />
                    )}
                    {item.nmfc1 && item.nmfc2 && `/${item.nmfc1}-${item.nmfc2}`}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={1}>
                <Typography>
                    {item.freightClass || (
                        <FormattedMessage
                            id="items__class"
                            defaultMessage="Class"
                        />
                    )}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={1}>
                <Typography>
                    {item.weight ? (
                        `${item.weight}`
                    ) : (
                        <FormattedMessage
                            id="items__weight"
                            defaultMessage="Weight"
                        />
                    )}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={2}>
                <Typography>
                    {item.packageType ? (
                        <FormattedMessage {...packageTypeTranslation} />
                    ) : (
                        <FormattedMessage
                            id="items__packageType"
                            defaultMessage="Package Type"
                        />
                    )}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={2}>
                <Typography>
                    {!item.length || !item.width || !item.height
                        ? ""
                        : `${item.length}x${item.width}x${item.height}`}
                </Typography>
            </Grid>
            <Grid item container direction="column" justify="center" xs={1}>
                {item.isHazMat && (
                    <Fragment>
                        <Typography>
                            <FormattedMessage
                                id="items__hazmat"
                                defaultMessage="Hazmat"
                            />
                        </Typography>
                        <Typography variant="caption">
                            {[
                                `${item.unNumber} `,
                                `${item.hazClass} `,
                                item.pkgGrp === "NONE" ? (
                                    <FormattedMessage
                                        id="items.hazMatPkgGrp__none"
                                        defaultMessage="None"
                                    />
                                ) : (
                                    item.pkgGrp
                                ),
                            ]}
                        </Typography>
                    </Fragment>
                )}
            </Grid>
            <Grid item container direction="column" justify="center" xs={1}>
                {hovering && (
                    <div onClick={e => e.stopPropagation()}>
                        <ListItemIcon>
                            <EditIcon onClick={handleEdit} />
                        </ListItemIcon>
                        <ListItemIcon>
                            <DeleteIcon onClick={handleDelete} />
                        </ListItemIcon>
                    </div>
                )}
            </Grid>
        </Grid>
    )
}

LineItemData = withStyles(styles)(LineItemData)

class LineItem extends Component {
    state = {
        hovering: false,
    }

    handleMouseEnter = () => {
        this.setState({ hovering: true })
    }

    handleMouseLeave = () => {
        this.setState({ hovering: false })
    }

    handleCancel = () => {
        if (this.props.item._id) {
            this.props.toggleEditMode(this.props.index)
            this.props.toggleExpand(this.props.index)
        } else this.props.cancelNewItem()
    }

    handleSave = () =>
        this.props.handleSubmit(values =>
            this.props
                .handleSave(values, this.props.index)
                .then(res => {
                    this.props.reset()
                })
                .catch(err => err)
        )

    onPackageTypeChange = (value, id) => {
        if (value === "PLT4840") {
            this.props.changeField(id, "length", 48)
            this.props.changeField(id, "width", 40)
            this.props.changeField(id, "height", null)
        } else if (value === "PLT4848") {
            this.props.changeField(id, "length", 48)
            this.props.changeField(id, "width", 48)
            this.props.changeField(id, "height", null)
        }
    }

    onDimensionChange = (value, id, name) => {
        if (this.props.itemData.packageType === "PLT4840") {
            if (
                (name === "length" && value !== 48) ||
                (name === "width" && value !== 40)
            ) {
                this.props.changeField(id, "packageType", "PLT")
            }
        } else if (this.props.itemData.packageType === "PLT4848") {
            if (
                (name === "length" && value !== 48) ||
                (name === "width" && value !== 48)
            ) {
                this.props.changeField(id, "packageType", "PLT")
            }
        }
    }

    render() {
        const {
            itemData,
            item,
            handleDelete,
            toggleEditMode,
            toggleExpand,
            index,
            reset,
            initialValues,
            classes,
        } = this.props
        const { hovering } = this.state
        return (
            <Fragment>
                <ExpansionPanel
                    expanded={item.expanded || !item._id}
                    onChange={() => toggleExpand(index)}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                >
                    {!item.editMode && (
                        <ExpansionPanelSummary
                            className={classes.panel__summary}
                        >
                            {(!item.expanded || !item._id) && (
                                <LineItemData
                                    item={initialValues}
                                    hovering={hovering}
                                    handleEdit={() => {
                                        toggleEditMode(index)
                                        toggleExpand(index)
                                    }}
                                    handleDelete={() => handleDelete(item._id)}
                                />
                            )}
                        </ExpansionPanelSummary>
                    )}
                    <ExpansionPanelDetails>
                        {(item.expanded || !item._id) && (
                            <LineItemForm
                                isHazMat={itemData.isHazMat}
                                editMode={itemData.editMode || !item._id}
                                handleEdit={() => toggleEditMode(index)}
                                handleSave={() => this.handleSave()}
                                handleDelete={() => handleDelete(item._id)}
                                handleCancel={this.handleCancel}
                                handleClear={reset}
                                _id={item._id}
                                onPackageTypeChange={this.onPackageTypeChange}
                                onDimensionChange={this.onDimensionChange}
                            />
                        )}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form)
    return {
        itemData: {
            isHazMat: selector(state, "isHazMat"),
            editMode: selector(state, "editMode"),
            packageType: selector(state, "packageType"),
        },
    }
}

const mapDispatchToProps = dispatch => ({
    toggleEditMode: index => {
        dispatch(createGAEvent("Item", "Items Edit"))
        return dispatch(toggleEditMode(index))
    },
    toggleExpand: index => dispatch(toggleExpand(index)),
    cancelNewItem: () => dispatch(cancelNewEmptyItem()),
    changeField: (id, field, value) =>
        dispatch(change(`lineItemForm_${id}`, field, value)),
})

LineItemForm.propTypes = {
    itemData: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    changeEditMode: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

const validation = combineValidators({
    description: isRequired({
        field: {
            id: "items__description",
            defaultMessage: "Description",
        },
    }),
    nmfc1: composeValidators(
        isNumeric,
        isRequiredIf(values => values.nmfc2)
    )({
        field: {
            id: "generalTerms__nmfcCode",
            defaultMessage: "NMFC Code",
        },
    }),
    nmfc2: isNumeric({
        field: {
            id: "generalTerms__nmfcSubcode",
            defaultMessage: "NMFC Subcode",
        },
    }),
    unNumber: composeValidators(
        isRequiredIf(values => values.isHazMat),
        hasHazMatIdPrefix(),
        hasLength(6)
    )({
        field: {
            id: "items__unNumber__error",
            defaultMessage:
                "Identification Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code.",
        },
    }),
    hazClass: isRequiredIf(values => values.isHazMat)({
        field: {
            id: "items__hazClass",
            defaultMessage: "Haz Class",
        },
    }),
    pkgGrp: isRequiredIf(values => values.isHazMat)({
        field: {
            id: "items__pkgGrp",
            defaultMessage: "Pkg Grp",
        },
    }),
    hazContainer: isRequiredIf(values => values.isHazMat)({
        field: {
            id: "items__containerType",
            defaultMessage: "Container Type",
        },
    }),
})

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            enableReinitialize: true,
            validate: validation,
        })(LineItem)
    )
)
