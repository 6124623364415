import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import csv from "csvtojson"
import { composeValidators, createValidator } from "revalidate"
import { get } from "lodash"
import {
    Grid,
    Button,
    withStyles,
    Typography,
    Input,
    Tooltip,
} from "@material-ui/core"
import { Table, Column, AutoSizer } from "react-virtualized"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"
import CloseIcon from "@material-ui/icons/Close"
import CircularProgress from "@material-ui/core/CircularProgress"
import FileUpload from "../util/file-upload"
import {
    countryPhoneValidator,
    emailValidator,
    hasLength,
    isRequired,
    isNumeric,
    isOneOf,
    isValidCountryPostalCode,
} from "../../actions/validation"
import {
    contactsImportCSVLoadError,
    contactsImportCSVTooLargeError,
    contactsImportError,
} from "../../messages/error-constants"
import { importContacts } from "../../actions/contact"
import { FormattedMessage, injectIntl } from "react-intl"
import { supportedCountryCodes } from "../../misc"
import { generateTimeList } from "../book/pickup/form"
import moment from "moment"

const styles = theme => ({
    button__container: {
        marginBottom: "20px",
    },
    button__close: {
        marginLeft: "auto",
    },
    content: {
        width: "90vw",
        maxHeight: "90vh",
        padding: "30px",
        borderRadius: "6px",
        backgroundColor: "#fff",
        overflowY: "scroll",
    },
    error__container: {
        margin: "35px 0",
    },
    error__item: {
        backgroundColor: "#D4D4D4",
        padding: "8px",
        marginTop: "5px",
    },
    error__line: {
        marginRight: "10px",
    },
    table__container: {
        width: "100%",
        height: "400px",
        position: "relative",
    },
    table__row: {
        display: "flex",
        flexDirection: "row",
    },
    table__header: {
        padding: "2px 2px 3px",
    },
    data__input: {
        padding: "2px 2px 3px",
    },
    error__field: {
        color: "#D40000",
    },
    delete__button: {
        height: "0",
    },
    summary: {
        margin: "16px",
    },
    headingTableTitle: {
        fontWeight: 600,
    },
})

const headers = [
    "name",
    "phone",
    "ext",
    "mobile",
    "email",
    "address_name",
    "address_1",
    "address_2",
    "city",
    "state",
    "postal_code",
    "country",
    "pickupContact_readyTime",
    "pickupContact_closeTime",
    "pickupContact_name",
    "pickupContact_email",
    "pickupContact_phone",
    "pickupContact_extension",
]

class DataTableField extends Component {
    state = { isOpen: false, canBeOpen: false }

    componentWillReceiveProps(newProps) {
        if (this.props.error && !newProps.error && this.state.isOpen) {
            this.setState({ isOpen: false })
        } else if (
            !this.props.error &&
            newProps.error &&
            !this.state.isOpen &&
            this.state.canBeOpen
        ) {
            this.setState({ isOpen: true })
        }
    }

    handleTooltipOpen = () => {
        if (this.props.error) {
            this.setState({ isOpen: true, canBeOpen: true })
        }
    }

    handleTooltipClose = () => {
        if (this.props.error) {
            this.setState({ isOpen: false, canBeOpen: false })
        }
    }

    render() {
        const { value, column, row, error, classes, intl, ...rest } = this.props
        const { isOpen } = this.state

        return (
            <Tooltip
                onClose={this.handleTooltipClose}
                onOpen={this.handleTooltipOpen}
                title={
                    error?.id && error?.defaultMessage ? (
                        <FormattedMessage {...error} />
                    ) : (
                        ""
                    )
                }
                placement="bottom"
                open={isOpen}
            >
                <Input
                    placeholder={
                        error
                            ? intl.formatMessage({
                                  id: "generalTerms__error",
                                  defaultMessage: "Error",
                              })
                            : ""
                    }
                    classes={{ input: classes.data__input }}
                    className={
                        error ? classes.error__field : classes.data__field
                    }
                    margin="none"
                    fullWidth
                    disableUnderline
                    value={value ? value : ""}
                    {...rest}
                />
            </Tooltip>
        )
    }
}

const DataTableCell = ({ value, column, classes, row, intl, ...rest }) => {
    let error = get(column, "validator") && column.validator(value, row)

    return (
        <DataTableField
            {...rest}
            intl={intl}
            column={column}
            value={value}
            error={error}
            classes={classes}
            inputProps={{ style: { fontSize: "smaller" } }}
        />
    )
}

class DataTable extends Component {
    render() {
        const {
            data,
            columns,
            onValueChange,
            onDeleteRow,
            classes,
            intl,
        } = this.props
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        height={height}
                        width={width}
                        headerHeight={32}
                        rowCount={data.length}
                        rowGetter={({ index }) => data[index]}
                        rowHeight={24}
                        rowClassName={classes.table__row}
                    >
                        {columns.map(column => (
                            <Column
                                columnData={column}
                                flexGrow={1}
                                width={column.width || 100}
                                key={column.field}
                                dataKey={column.field}
                                headerRenderer={({ columnData }) => (
                                    <span className={classes.table__header}>
                                        {columnData.title?.id ? (
                                            <Typography
                                                variant="body1"
                                                className={
                                                    classes.headingTableTitle
                                                }
                                            >
                                                <FormattedMessage
                                                    {...columnData.title}
                                                />
                                            </Typography>
                                        ) : (
                                            <Typography
                                                variant="body1"
                                                className={
                                                    classes.headingTableTitle
                                                }
                                            >
                                                {columnData.title}
                                            </Typography>
                                        )}
                                    </span>
                                )}
                                cellRenderer={({
                                    columnData,
                                    rowData,
                                    dataKey,
                                    cellData,
                                    rowIndex,
                                }) =>
                                    columnData.title === "" ? (
                                        <IconButton
                                            aria-label="Delete"
                                            className={classes.delete__button}
                                            onClick={() =>
                                                onDeleteRow(rowIndex)
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    ) : (
                                        <DataTableCell
                                            column={columnData}
                                            row={rowData}
                                            value={cellData || ""}
                                            classes={classes}
                                            onChange={e =>
                                                onValueChange(
                                                    rowIndex,
                                                    dataKey,
                                                    e.target.value
                                                )
                                            }
                                            intl={intl}
                                        />
                                    )
                                }
                            />
                        ))}
                    </Table>
                )}
            </AutoSizer>
        )
    }
}

const postalCodeValidator = (value, row) => {
    if (!isValidCountryPostalCode(value, row.country)) {
        return {
            id: "contacts.import__invalidZipPostalCode",
            defaultMessage: "Invalid zip/postal code",
        }
    }
}

const pickupWindowValidator = createValidator(
    message => (value, allValues) => {
        const time = get(allValues, "pickupContact_readyTime")
        if (!time) {
            return undefined
        }
        const readyTime = moment(time, "HH:mm A")
        const closeTime = moment(value, "HH:mm A")
        const minutes = closeTime.diff(readyTime, "minutes")
        return minutes >= 90 ? undefined : message
    },
    field => ({
        ...{
            id: "orderDetails.validation__pickupWindow",
            defaultMessage:
                "{field} should be at least 90 minutes after Ready Time",
        },
        values: { field },
    })
)

class ImportCSVModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            importing: false,
            imported: false,
            submitting: false,
            result: null,
            invalidRows: [],
            fixedCount: 0,
            validRows: [],
            sbError: false,
            sbMessage: "",
        }
    }

    columnDef = [
        {
            field: "name",
            title: {
                id: "contacts.import__name",
                defaultMessage: "Name",
            },
            width: 140,
            validator: isRequired({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__name",
                    defaultMessage: "Name",
                }),
            }),
        },
        {
            field: "phone",
            title: {
                id: "contacts.import__phoneNumber",
                defaultMessage: "Phone Number",
            },
            validator: composeValidators(
                isRequired,
                countryPhoneValidator("country")
            )({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__phoneNumber",
                    defaultMessage: "Phone Number",
                }),
            }),
            width: 150,
        },
        {
            field: "ext",
            title: {
                id: "contacts.import__ext",
                defaultMessage: "Ext.",
            },
            width: 40,
            validator: isNumeric({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__ext",
                    defaultMessage: "Ext.",
                }),
            }),
        },
        {
            field: "mobile",
            title: {
                id: "contacts.import__mobile",
                defaultMessage: "Mobile",
            },
            validator: countryPhoneValidator("country")({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__mobile",
                    defaultMessage: "Mobile",
                }),
            }),
        },
        {
            field: "email",
            title: {
                id: "contacts.import__emailAddress",
                defaultMessage: "Email Address",
            },
            width: 150,
            validator: composeValidators(
                isRequired,
                emailValidator
            )({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__emailAddress",
                    defaultMessage: "Email Address",
                }),
            }),
        },
        {
            field: "address_name",
            title: {
                id: "contacts.import__companyName",
                defaultMessage: "Company Name",
            },
            width: 140,
            validator: isRequired({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__companyName",
                    defaultMessage: "Company Name",
                }),
            }),
        },
        {
            field: "address_1",
            title: {
                id: "contacts.import__address",
                defaultMessage: "Address",
            },
            width: 140,
            validator: isRequired({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__address",
                    defaultMessage: "Address",
                }),
            }),
        },
        {
            field: "address_2",
            title: {
                id: "contacts.import__address2",
                defaultMessage: "Address 2",
            },
        },
        {
            field: "city",
            title: {
                id: "contacts.import__city",
                defaultMessage: "City",
            },
            validator: isRequired({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__city",
                    defaultMessage: "City",
                }),
            }),
        },
        {
            field: "state",
            title: {
                id: "contacts.import__state",
                defaultMessage: "State",
            },
            width: 40,
            validator: composeValidators(
                isRequired,
                hasLength(2)
            )({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__state",
                    defaultMessage: "State",
                }),
            }),
        },
        {
            field: "postal_code",
            title: {
                id: "contacts.import__postalCode",
                defaultMessage: "Postal Code",
            },
            width: 110,
            validator: postalCodeValidator,
        },
        {
            field: "country",
            title: {
                id: "contacts.import__country",
                defaultMessage: "Country",
            },
            width: 40,
            validator: composeValidators(
                isRequired,
                isOneOf(supportedCountryCodes.map(x => x.value))
            )({
                field: this.props.intl.formatMessage({
                    id: "contacts.import__country",
                    defaultMessage: "Country",
                }),
            }),
        },
        {
            field: "pickupContact_readyTime",
            title: {
                id: "generalTerms.pickUpInformation__readyTime",
                defaultMessage: "Ready Time",
            },
            width: 110,
            validator: composeValidators(
                isOneOf(generateTimeList().map(x => x.value))
            )({
                field: this.props.intl.formatMessage({
                    id: "generalTerms.pickUpInformation__readyTime",
                    defaultMessage: "Ready Time",
                }),
            }),
        },
        {
            field: "pickupContact_closeTime",
            title: {
                id: "generalTerms.pickUpInformation__closeTime",
                defaultMessage: "Close Time",
            },
            width: 110,
            validator: composeValidators(
                isOneOf(generateTimeList().map(x => x.value)),
                pickupWindowValidator
            )({
                field: this.props.intl.formatMessage({
                    id: "generalTerms.pickUpInformation__closeTime",
                    defaultMessage: "Close Time",
                }),
            }),
        },
        {
            field: "pickupContact_name",
            title: {
                id: "generalTerms.pickUpInformation__pickupName",
                defaultMessage: "Pickup Name",
            },
            width: 140,
        },
        {
            field: "pickupContact_email",
            title: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
            width: 150,
            validator: composeValidators(emailValidator)({
                field: this.props.intl.formatMessage({
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                }),
            }),
        },
        {
            field: "pickupContact_phone",
            title: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
            width: 150,
            validator: composeValidators(countryPhoneValidator("country"))({
                field: this.props.intl.formatMessage({
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                }),
            }),
        },
        {
            field: "pickupContact_extension",
            title: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
            width: 40,
            validator: composeValidators(isNumeric)({
                field: this.props.intl.formatMessage({
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                }),
            }),
        },
        {
            title: "",
            width: 60,
        },
    ]

    validateRow = row => {
        const rowErrors = this.columnDef
            .map(column => {
                const revalidateError =
                    column.validator &&
                    column.validator(get(row, column.field), row)
                if (revalidateError) {
                    return revalidateError
                } else return undefined
            })
            .filter(x => x)

        return rowErrors.length ? { id: row.id, errors: rowErrors } : undefined
    }

    submitData = async data => {
        this.setState({ submitting: true })
        try {
            const { data: result } = await this.props.onContactImport(data)
            this.setState({ result })
        } catch (err) {
            this.setState({
                sbMessage: contactsImportError,
                sbError: false,
            })
        } finally {
            this.setState({ submitting: false })
        }
    }

    handleCSVLoad = async file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = event => resolve(event.target.result)
            reader.onerror = reject
            reader.readAsText(file)
        })
    }

    handleImport = async file => {
        this.setState({
            importing: true,
            imported: false,
            fixedCount: 0,
            invalidRows: [],
            validRows: [],
            result: null,
            sbMessage: "",
            sbError: false,
        })
        try {
            const csvString = await this.handleCSVLoad(file)
            const rows = await csv({
                headers,
                checkColumn: false,
                noheader: false,
                ignoreEmpty: true,
            }).fromString(csvString)
            if (rows.length > 1000) {
                this.setState({
                    sbMessage: contactsImportCSVTooLargeError,
                    sbError: true,
                })
                return
            }
            const { validRows, invalidRows } = rows
                .filter(row => headers.some(key => !!row[key]))
                .map(row => {
                    const rowLength = get(row, "postal_code.length", 0)

                    if (rowLength === 0 || rowLength === 5 || rowLength === 7)
                        return row
                    return {
                        ...row,
                        postal_code: row.postal_code.padStart(5, "0"),
                    }
                })
                .reduce(
                    (prev, row, id) => {
                        const isInvalid = this.validateRow(row)
                        if (isInvalid) {
                            prev.invalidRows.push({
                                ...row,
                                id,
                            })
                        } else {
                            prev.validRows.push({
                                ...row,
                                id,
                            })
                        }
                        return prev
                    },
                    { validRows: [], invalidRows: [] }
                )
            this.setState({
                invalidRows,
                validRows,
                imported: true,
                importing: false,
            })
            if (!invalidRows.length && validRows.length) {
                await this.submitData(validRows)
            }
        } catch (err) {
            this.setState({
                sbMessage: contactsImportCSVLoadError(err.message),
                sbError: true,
            })
        } finally {
            this.setState({ importing: false })
        }
    }

    handleSubmit = async () => {
        const { validRows, invalidRows } = this.state
        const allData = [...validRows, ...invalidRows]
        this.setState({ validRows: allData, imported: false })
        return this.submitData(allData)
    }

    handleDeleteRow = id => {
        const { invalidRows } = this.state
        let { fixedCount } = this.state
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            ...invalidRows.slice(id + 1),
        ]
        const targetRow = invalidRows[id]
        const rowInvalid = this.validateRow(targetRow)
        if (!rowInvalid) fixedCount = fixedCount - 1
        this.setState({ invalidRows: newInvalidRows, fixedCount })
    }

    handleValueChange = (id, field, value) => {
        const { invalidRows } = this.state
        let { fixedCount } = this.state
        const newRow = { ...invalidRows[id], [field]: value }
        const newInvalidRows = [
            ...invalidRows.slice(0, id),
            newRow,
            ...invalidRows.slice(id + 1),
        ]
        const oldValidation = this.validateRow(invalidRows[id])
        const newValidation = this.validateRow(newRow)
        if (oldValidation && !newValidation) {
            fixedCount = fixedCount + 1
        } else if (!oldValidation && newValidation) {
            fixedCount = fixedCount - 1
        }

        this.setState({ fixedCount, invalidRows: newInvalidRows })
    }

    render() {
        const { onCloseClick, classes, language = "en-us", intl } = this.props
        const {
            invalidRows,
            fixedCount,
            validRows,
            submitting,
            importing,
            imported,
            result,
            sbMessage,
            sbError,
        } = this.state

        return (
            <Grid container className={classes.content}>
                <Grid item container xs={10}>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="contacts.import__instructions"
                            defaultMessage="To import your address book, please look at the sample file below. Your upload file needs to be in the .csv format and use the same column headers."
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    className={classes.button__container}
                    xs={2}
                    justify="flex-end"
                >
                    <IconButton
                        className={classes.button__close}
                        onClick={onCloseClick}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                            window.open(
                                `/static/import_contacts_sample__${language}.csv`,
                                "_blank"
                            )
                        }
                    >
                        <FormattedMessage
                            id="contacts.import__downloadSample"
                            defaultMessage="Download sample"
                        />
                    </Button>
                    {importing && (
                        <CircularProgress size={30} color="secondary" />
                    )}
                    <FileUpload
                        labelText={
                            <FormattedMessage
                                id="contacts.import__uploadFile"
                                defaultMessage="Upload file"
                            />
                        }
                        onSubmit={file => this.handleImport(file)}
                        accept=".csv"
                        allowSameFile
                    />
                </Grid>
                {sbMessage && (
                    <Grid item container>
                        <Typography color={sbError ? "error" : undefined}>
                            {sbMessage}
                        </Typography>
                    </Grid>
                )}
                {imported && (
                    <Grid item container>
                        <Grid item className={classes.summary}>
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="contacts.import__summary"
                                    defaultMessage="Summary:"
                                />
                            </Typography>
                            <Typography>
                                <FormattedMessage
                                    id="contacts.import__validRows"
                                    defaultMessage="Valid records: {validRows}, invalid records: {invalidRows}"
                                    values={{
                                        validRows:
                                            validRows.length + fixedCount,
                                        invalidRows:
                                            invalidRows.length - fixedCount,
                                    }}
                                />
                            </Typography>
                            {invalidRows.length > 0 && (
                                <Typography>
                                    <FormattedMessage
                                        id="contacts.import__invalidRows"
                                        defaultMessage="Please correct the validation errors below, or remove the invalid items."
                                    />
                                </Typography>
                            )}
                        </Grid>
                        {invalidRows.length > 0 && (
                            <div className={classes.table__container}>
                                <DataTable
                                    data={invalidRows}
                                    onValueChange={(...args) =>
                                        this.handleValueChange(...args)
                                    }
                                    onDeleteRow={index =>
                                        this.handleDeleteRow(index)
                                    }
                                    columns={this.columnDef}
                                    classes={classes}
                                    intl={intl}
                                />
                            </div>
                        )}
                        <Grid item container direction="row-reverse">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => this.handleSubmit()}
                                    disabled={invalidRows.length !== fixedCount}
                                >
                                    <FormattedMessage
                                        id="contacts.import__submit"
                                        defaultMessage="Submit"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {submitting && (
                    <Grid item container direction="column" alignItems="center">
                        <Grid item>
                            <CircularProgress size={30} color="secondary" />
                        </Grid>
                        <Grid item>
                            <Typography variant="subheading">
                                <FormattedMessage
                                    id="contacts.import__pleaseWait"
                                    defaultMessage="Importing contacts. Please wait and do not close this window..."
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {result && (
                    <Grid item container direction="column" alignItems="center">
                        {get(result, "error.length") ? (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subheading">
                                        <FormattedMessage
                                            id="contacts.import__finished"
                                            defaultMessage="Finished importing contacts."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage
                                            id="contacts.import__result"
                                            defaultMessage="Created {successful} new contacts, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography gutterBottom>
                                        <FormattedMessage
                                            id="contacts.import__unableToImport"
                                            defaultMessage="Unfortunately we were not able to import the following contacts:"
                                        />
                                    </Typography>
                                </Grid>
                                {get(result, "error", []).map(
                                    ({ index, error }, key) => (
                                        <Grid item>
                                            <Typography>
                                                <FormattedMessage
                                                    id="contacts.import__rowError"
                                                    defaultMessage="Name: {name}, Company name: {companyName} - {error}"
                                                    values={{
                                                        name: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        companyName: get(
                                                            validRows,
                                                            `[${index}].name`,
                                                            ""
                                                        ),
                                                        error,
                                                    }}
                                                />
                                            </Typography>
                                        </Grid>
                                    )
                                )}
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage
                                            id="contacts.import__finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Grid item>
                                    <Typography variant="subheading">
                                        <FormattedMessage
                                            id="contacts.import__success"
                                            defaultMessage="Successfully finished importing contacts."
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage
                                            id="contacts.import__result"
                                            defaultMessage="Created {successful} new contacts, {duplicate} already existed."
                                            values={{
                                                successful: get(
                                                    result,
                                                    "successful.length",
                                                    0
                                                ),
                                                duplicate: get(
                                                    result,
                                                    "duplicate.length",
                                                    0
                                                ),
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage
                                            id="contacts.import__finalMessage"
                                            defaultMessage="You may now close this window or upload a different file."
                                        />
                                    </Typography>
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                )}
            </Grid>
        )
    }
}

ImportCSVModal.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    handleImport: PropTypes.func.isRequired,
}

ImportCSVModal.defaultProps = {
    classes: {},
}

const mapStateToProps = state => ({
    language: state?.user?.profile?.preferences?.language,
})

const mapDispatchToProps = dispatch => ({
    onContactImport: async data => dispatch(importContacts(data)),
})

ImportCSVModal = withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(injectIntl(ImportCSVModal))
)

export { ImportCSVModal }
