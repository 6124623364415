import FileSaver from "file-saver"
import { get } from "lodash"
import { goFetch } from "../http"
import { createGAEvent, errorMessage, USER_INPUT_ENDED_DELAY } from "./index"
import { attachPusher } from "./chat"

export const ADD_SHIPMENT = "ADD_SHIPMENT"
export const SHIPMENT_LIST_LOAD = "SHIPMENT_LIST_LOAD"
export const SHIPMENT_LIST_LOAD_ERROR = "SHIPMENT_LIST_LOAD_ERROR"
export const SHIPMENT_LIST_RESULT = "SHIPMENT_LIST_RESULT"
export const TRACK_FIELD_CHANGE = "TRACK_FIELD_CHANGE"
export const SHIPMENT_SEARCH_RESULT = "SHIPMENT_SEARCH_RESULT"
export const QUEUE_SHIPMENT_SEARCH = "QUEUE_SHIPMENT_SEARCH"
export const SHIPMENT_LIST_RESULT_FILTER_APPLIED =
    "SHIPMENT_LIST_RESULT_FILTER_APPLIED"

export function addShipment(result) {
    return { type: ADD_SHIPMENT, result }
}

function shipmentLoad() {
    return { type: SHIPMENT_LIST_LOAD }
}

function shipmentLoadError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: SHIPMENT_LIST_LOAD_ERROR,
            error,
        })
    }
}

const shipmentListResult = result => ({ type: SHIPMENT_LIST_RESULT, result })
const shipmentListResultFilterApplied = result => ({
    type: SHIPMENT_LIST_RESULT_FILTER_APPLIED,
    result,
})

function shouldRequestShipments(state, prefix = "shipment") {
    return (
        !state[prefix].isFetching &&
        (!state[prefix].isLoaded ||
            state[prefix].fetchedCount < state[prefix].totalCount)
    )
}

async function requestShipment(
    prefix,
    state,
    urlPrefix,
    targetSize,
    dashboardFilters,
    activeDashboardTile
) {
    const { pagination } = state[prefix]
    targetSize = targetSize || pagination.pageSize
    let pageNumber = Math.round(targetSize / pagination.pageSize)
    let url = `/shipments?pageNumber=${pageNumber}`

    if (dashboardFilters) {
        const { locationFilter, carrierFilter } = dashboardFilters
        let filterString = ""

        if (locationFilter) {
            Object.keys(locationFilter).forEach(key => {
                if (locationFilter[key])
                    filterString = filterString.concat(`&cpg=${key}`)
            })
        }

        if (carrierFilter) {
            Object.keys(carrierFilter).forEach(key => {
                if (carrierFilter[key])
                    filterString = filterString.concat(`&scac=${key}`)
            })
        }

        url = url.concat(filterString)
    }

    if (activeDashboardTile) {
        const userTimezoneOffset = new Date().getTimezoneOffset()

        let tileString = ""
        switch (activeDashboardTile) {
            case "delivered":
                tileString = `&status=DELIVERED&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "deliveredToday":
                tileString = `&status=DELIVERED_TODAY&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "pickedUp":
                tileString = `&status=PICKED_UP&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "pickingUpToday":
                tileString = `&status=PICKING_UP_TODAY&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "pickingUpTomorrow":
                tileString = `&status=PICKING_UP_TOMORROW&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "inTransit":
                tileString = `&status=IN_TRANSIT&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "outForDelivery":
                tileString = `&status=OUT_FOR_DELIVERY&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "canceled":
                tileString = `&status=CANCELED&userTimezoneOffset=${userTimezoneOffset}`
                break
            case "warning":
                tileString = "&warning=true"
                break
            case "starred":
                tileString = "&favorite=true"
                break
            case "inbound":
                tileString = "&direction=INBOUND"
                break
            case "outbound":
                tileString = "&direction=OUTBOUND"
                break
            case "thirdParty":
                tileString = "&direction=THIRD_PARTY"
                break
            default:
                return ""
        }
        url = url.concat(tileString)
    }

    const { data, status } = await goFetch(
        url,
        {
            method: "GET",
            credentials: "same-origin",
            params: { ...pagination },
            validErrorCodes: [404],
        },
        true
    )
    ;(data.list || []).forEach(shipment => {
        if (!shipment.shipment.origin.address) {
            shipment.shipment.origin.address = {}
        }
        if (!shipment.shipment.destination.address) {
            shipment.shipment.destination.address = {}
        }
    })
    return { data, status }
}

export function requestShipments(targetSize, force) {
    return async (dispatch, getState) => {
        if (!force) {
            if (!shouldRequestShipments(getState())) return null
            if (
                targetSize &&
                targetSize <= get(getState(), "shipment.fetchedCount", 0)
            ) {
                return null
            }
        }

        const dashboardFilters = get(
            getState(),
            "form.dashboardFilters.values",
            {}
        )

        dispatch(shipmentLoad())

        const activeDashboardTile = getState().dashboard?.activeDashboardTile

        try {
            const { data, status } = await requestShipment(
                "shipment",
                getState(),
                "shipments",
                targetSize,
                dashboardFilters,
                activeDashboardTile
            )
            if (status === 404)
                return dispatch(shipmentListResult({ list: [] }))
            const retVal = dispatch(shipmentListResult(data))
            const activeShipments = get(retVal, "list", []).filter(
                item =>
                    get(item, "status.currentStatus.code", "CREATED") !==
                    "DELIVERED"
            )
            activeShipments.forEach(item => {
                const channel = get(item, "identifiers.internalTrackingNumber")
                dispatch(attachPusher(channel))
            })
            return retVal
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

export function requestShipmentsWithFilters(targetSize) {
    return async (dispatch, getState) => {
        const dashboardFilters = get(
            getState(),
            "form.dashboardFilters.values",
            {}
        )
        dispatch(shipmentLoad())

        const activeDashboardTile = getState().dashboard?.activeDashboardTile

        try {
            const { data, status } = await requestShipment(
                "shipment",
                getState(),
                "shipments",
                targetSize,
                dashboardFilters,
                activeDashboardTile
            )
            if (status === 404)
                return dispatch(shipmentListResult({ list: [] }))
            const retVal = dispatch(shipmentListResultFilterApplied(data))
            const activeShipments = get(retVal, "list", []).filter(
                item =>
                    get(item, "status.currentStatus.code", "CREATED") !==
                    "DELIVERED"
            )
            activeShipments.forEach(item => {
                const channel = get(item, "identifiers.internalTrackingNumber")
                dispatch(attachPusher(channel))
            })
            return retVal
        } catch (error) {
            return dispatch(shipmentLoadError(error))
        }
    }
}

async function fetchShipment(shipmentId, shareKey) {
    const { data } = await goFetch(
        `/shipments/${shipmentId}`,
        {
            method: "GET",
            credentials: "same-origin",
        },
        true,
        shareKey
    )
    return data
}

export function populateTrackForm(shipmentId) {
    return async (dispatch, getState) => {
        try {
            if (!shipmentId) return
            let shipment = getState().shipment.list[shipmentId]
            const shareKey = getState().shareStatus.shareKey
            shipment = await fetchShipment(shipmentId, shareKey)
            dispatch(addShipment(shipment))
        } catch (err) {
            dispatch(errorMessage(err))
        }
    }
}

export const fieldChange = (field, value) => ({
    type: TRACK_FIELD_CHANGE,
    field,
    value,
})

export function proLinkClick(shipmentId) {
    return dispatch => {
        dispatch(
            createGAEvent("Shipment", "Tracking ID (FedEx) hyper link", "", {
                shipment: shipmentId,
            })
        )
    }
}

export function exportShipmentsAsCsv() {
    return async dispatch => {
        try {
            const { data } = await goFetch(
                "/shipments/csv",
                {
                    method: "GET",
                    credentials: "same-origin",
                    responseType: "text",
                },
                true,
                null,
                "text/csv"
            )
            const blob = new Blob([data], {
                type: "text/csv;charset=utf-8",
            })
            FileSaver.saveAs(blob, "shipments.csv")
            return blob
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}

export function getShipmentIndex(shipmentId, index, list) {
    return (dispatch, getState) => {
        const shipments = getState().shipment.list
        const shipmentArray = Object.keys(shipments).sort((a, b) =>
            shipments[b].created_at > shipments[a].created_at ? 1 : -1
        )
        const wantedIndex =
            shipmentArray.findIndex(s => s === shipmentId) + index
        if (wantedIndex < 0 || wantedIndex >= shipments.length) return null
        return shipmentArray[wantedIndex]
    }
}

const shipmentSearchResult = (value, data = []) => ({
    type: SHIPMENT_SEARCH_RESULT,
    value,
    data,
})

function innerSearchShipments(value) {
    return async dispatch => {
        try {
            const params = { q: value, pageSize: 5 }
            const { data, status } = await goFetch(
                "/shipments",
                { validErrorCodes: [404], params },
                true
            )
            if (status === 404) return dispatch(shipmentSearchResult(value))
            return dispatch(shipmentSearchResult(value, data.list))
        } catch (error) {
            return dispatch(errorMessage(error))
        }
    }
}

export function searchShipments(value) {
    return async (dispatch, getState) => {
        if (!value || value.length < 3) return
        const result = getState().shipment.search[value]
        if (result) return
        const { searchInProgress } = getState().shipment
        clearTimeout(searchInProgress)
        const timeoutId = setTimeout(
            () => dispatch(innerSearchShipments(value)),
            USER_INPUT_ENDED_DELAY
        )
        dispatch({ type: QUEUE_SHIPMENT_SEARCH, id: timeoutId })
    }
}
