import React, { Component } from "react"
import { get } from "lodash"
import { Field, FormSection } from "redux-form"
import FormCheckbox from "../../form/form-checkbox"
import { withStyles, Typography, Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    filterRow: {
        borderBottom: "Solid 1px #D4D4D4",
    },
})

const FilterRow = ({ location, classes }) => {
    const isFreightDirect = location?.locationType === "FEDEX_DIRECT"
    const isFreightDirectReturns =
        location?.locationType === "FEDEX_DIRECT_RETURNS"
    const isFreightBox = location?.locationType === "FEDEX_FREIGHT_BOX"
    const isDirect = isFreightDirect || isFreightDirectReturns

    return (
        <Grid item container alignItems="center" className={classes.filterRow}>
            <Grid item container>
                <Field
                    name={get(location, "cpgCode")}
                    label={`${
                        get(location, "cpgName")
                            ? location.cpgName
                            : get(location, "shippingAddress.name")
                    }`}
                    component={FormCheckbox}
                />
            </Grid>
            <Grid item container direction="column">
                {location?.fedexFreightAccount && (
                    <Typography variant="caption">
                        {isDirect
                            ? [
                                  <FormattedMessage
                                      id="locations__FXFDShipperAccountNumber"
                                      defaultMessage="FXFD Shipper Account #"
                                  />,
                                  ": ",
                                  location?.fedexFreightAccount,
                              ]
                            : [
                                  <FormattedMessage
                                      id="locations__shipperAccountNumber"
                                      defaultMessage="Shipper Account #"
                                  />,
                                  ": ",
                                  location?.fedexFreightAccount,
                              ]}
                    </Typography>
                )}
                {location?.fedexBillToAccount && (
                    <Typography variant="caption">
                        {isDirect
                            ? [
                                  <FormattedMessage
                                      id="locations__FXFDThirdPartyAccountNumber"
                                      defaultMessage="FXFD Third Party Account #"
                                  />,
                                  ": ",
                                  location?.fedexBillToAccount,
                              ]
                            : [
                                  <FormattedMessage
                                      id="locations__thirdPartyAccountNumber"
                                      defaultMessage="Third Party Account #"
                                  />,
                                  ": ",
                                  location?.fedexBillToAccount,
                              ]}
                    </Typography>
                )}
            </Grid>
            {isFreightDirect && (
                <Grid>
                    <Typography variant="caption" color="primary">
                        <FormattedMessage
                            id="dashboard.filters__freightDirect"
                            defaultMessage="FedEx Freight Direct"
                        />
                    </Typography>
                </Grid>
            )}
            {isFreightDirectReturns && (
                <Grid>
                    <Typography variant="caption" color="primary">
                        <FormattedMessage
                            id="dashboard.filters__freightDirectReturns"
                            defaultMessage="FedEx Freight Direct Returns"
                        />
                    </Typography>
                </Grid>
            )}
            {isFreightBox && (
                <Grid>
                    <Typography variant="caption" color="primary">
                        <FormattedMessage
                            id="dashboard.filters__freightBox"
                            defaultMessage="FedEx Freight Box"
                        />
                    </Typography>
                </Grid>
            )}

        </Grid>
    )
}

class DashboardLocationFilter extends Component {
    render() {
        const { classes, locations } = this.props

        return (
            <Grid item container>
                <FormSection name="locationFilter">
                    {locations.map(loc => (
                        <FilterRow
                            classes={classes}
                            location={loc}
                            key={get(loc, "cpgCode")}
                        />
                    ))}
                </FormSection>
            </Grid>
        )
    }
}

export default withStyles(styles)(DashboardLocationFilter)
