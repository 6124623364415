import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import { Grid } from "@material-ui/core"
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft"
import { withStyles } from "@material-ui/core/styles"
import { DatePicker } from "material-ui-pickers"
import FormField from "./form-field"

const styles = theme => ({
    form__container: {
        width: "100%",
        marginTop: "-3px",
    },
    input__label: {
        margin: "0",
    },
})

const FormDatePicker = ({
    input,
    label,
    type,
    dateFormat = "MM/DD/YYYY",
    autoComplete,
    placeholder,
    maxLength,
    classes,
    noMinDate,
    minDate,
    maxDate,
    InputProps,
    required,
    InputLabelProps,
    InputHelperProps,
    dataTestId,
    ...rest
}) => (
    <Grid item container className={classes.form__container}>
        <Grid item container direction="column">
            <DatePicker
                autoOk
                format={dateFormat}
                invalidLabel=""
                placeholder={placeholder}
                id={input.name}
                margin="none"
                rightArrowIcon={<RightArrowIcon />}
                leftArrowIcon={<LeftArrowIcon />}
                TextFieldComponent={FormField}
                InputProps={InputProps}
                InputLabelProps={InputLabelProps}
                label={label}
                input={input}
                required={required}
                minDate={
                    !!noMinDate
                        ? undefined
                        : minDate ||
                          moment.utc(
                              moment()
                                  .tz("Pacific/Honolulu")
                                  .format("YYYY-MM-DD")
                          )
                }
                dataTestId={dataTestId}
                maxDate={maxDate}
                {...input}
                {...rest}
                onChange={value => {
                    const utcValue = moment.utc(
                        moment(value).format("YYYY-MM-DD")
                    )
                    input.onChange(utcValue)
                    input.onBlur(utcValue)
                }}
            />
        </Grid>
    </Grid>
)

FormDatePicker.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    type: PropTypes.oneOf([
        "text",
        "email",
        "file",
        "number",
        "tel",
        "checkbox",
        "password",
        "date",
    ]),
    label: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    autoComplete: PropTypes.string,
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
    classes: PropTypes.object,
    InputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    InputHelperProps: PropTypes.object,
}

FormDatePicker.defaultProps = {
    meta: {},
    autoComplete: undefined,
    maxLength: undefined,
    placeholder: undefined,
    label: undefined,
    type: undefined,
    input: undefined,
    classes: {},
}

export default withStyles(styles)(FormDatePicker)
