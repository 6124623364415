import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import { FormControl } from "react-bootstrap"
import { connect } from "react-redux"
import { searchItems } from "../../actions/item"

const description = {
    header: "Description",
    accessor: "description",
    render: rowInfo => rowInfo.value,
    minWidth: 70,
}

const freightClass = {
    header: "Class",
    accessor: "freightClass",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const weight = {
    header: "Weight",
    accessor: "weight",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const packageType = {
    header: "Package Type",
    accessor: "packageType",
    render: rowInfo => rowInfo.value,
    minWidth: 50,
}

const dimensions = {
    header: "Dims",
    id: "dimensions",
    accessor: row => `${row.length}x${row.width}x${row.height}`,
    render: rowInfo => rowInfo.value,
    minWidth: 50,
}
const nmfc = {
    header: "NMFC",
    id: "nmfc",
    accessor: element =>
        element.nmfc ? `${element.nmfc1}-${element.nmfc2}` : "",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const unNumber = {
    header: "UN#",
    accessor: "unNumber",
    render: rowInfo => rowInfo.value,
    minWidth: 30,
}

const hazMatClass = {
    header: "Haz Class",
    accessor: "hazClass",
    render: rowInfo => rowInfo.value,
    minWidth: 40,
}

const pkgGrp = {
    header: "Pkg Grp",
    accessor: "pkgGrp",
    render: rowInfo => rowInfo.value,
    minWidth: 40,
}

const columns = [
    description,
    freightClass,
    weight,
    packageType,
    dimensions,
    nmfc,
    unNumber,
    hazMatClass,
    pkgGrp,
]

class InputFieldPresentation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showOverlay: false,
        }
        this.input = null
        this.clickedInside = false
        this.clickTimeout = null
        this.changed = false
    }

    componentWillUnmount() {
        clearTimeout(this.clickTimeout)
    }

    handleContainerMouseDown() {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputFocus() {
        this.setState({
            showOverlay: true,
        })
    }

    handleInputBlur() {
        const showOverlay = this.clickedInside

        this.setState({
            showOverlay,
        })

        // Force input's focus if blur event was caused by clicking on dropdown
        if (showOverlay) {
            this.input.focus()
        }
    }

    handleSelect(item) {
        this.input.blur()
        this.setState({ showOverlay: false })
        this.props.onSelect(item)
    }

    render() {
        const {
            name,
            className,
            placeholder,
            value,
            tabIndex,
            autoFocus,
            disabled,
            onChange,
            items,
            maxLength,
            doSearch,
        } = this.props
        return (
            <div
                className={className}
                tabIndex="-1"
                role="button"
                onMouseDown={() => this.handleContainerMouseDown()}
            >
                <FormControl
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    inputRef={el => {
                        this.input = el
                    }}
                    onChange={(...args) => {
                        doSearch(...args)
                        onChange(...args)
                    }}
                    tabIndex={tabIndex}
                    autoFocus={autoFocus}
                    onFocus={() => this.handleInputFocus()}
                    onBlur={() => this.handleInputBlur()}
                    readOnly={disabled}
                    name={name}
                />
                {this.state.showOverlay && items.length > 0 && (
                    <div className="browse-items-list">
                        <ReactTable
                            data={items}
                            columns={columns}
                            defaultPageSize={50}
                            showPagination={false}
                            className="suggested-items-table"
                            pageSize={items.length}
                            pageSizeOptions={[10]}
                            getTheadProps={() => ({
                                className: "align-left",
                            })}
                            getTableProps={() => ({
                                className: "overflow-visible",
                            })}
                            getNoDataProps={() => ({
                                style: {
                                    display: "none",
                                },
                            })}
                            getTfootProps={() => ({
                                className: "table-footer",
                            })}
                            getTrProps={(state, rowInfo) => ({
                                className: "browse-item-table-row",
                                onClick: () => {
                                    this.handleSelect(rowInfo.row)
                                },
                            })}
                        />
                    </div>
                )}
            </div>
        )
    }
}

InputFieldPresentation.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    tabIndex: PropTypes.number,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    doSearch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    items: PropTypes.array,
    className: PropTypes.string,
    name: PropTypes.string,
    maxLength: PropTypes.number.isRequired,
}

InputFieldPresentation.defaultProps = {
    tabIndex: 0,
    autoFocus: false,
    disabled: false,
    items: [],
    className: "",
    name: "",
    placeholder: "",
}

const mapStateToProps = (state, props) => ({
    items: state.item.search[props.value],
    maxLength: props.field === "unNumber" ? 4 : 200,
})

const mapDispatchToProps = dispatch => ({
    doSearch: e => dispatch(searchItems(e.target.value)),
})

export const ItemSmart = connect(
    mapStateToProps,
    mapDispatchToProps
)(InputFieldPresentation)
