import React from "react"
import { get, capitalize } from "lodash"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { highlightTerm } from "../util"

const addressName = (data, prefix) => {
    const {
        [`${prefix}Address`]: address,
        [`${prefix}City`]: city,
        [prefix]: postalCode,
        [`${prefix}State`]: state,
    } = data || {}
    const addr = address || data?.[prefix]
    return `${addr?.address?.city ?? city}, ${addr?.address?.state ?? state} ${addr?.address?.postalCode ?? postalCode}`
}

const LocationFragment = ({ data, location, term }) => {
    const name =
        get(data, `${location}Address.name`) || get(data, `${location}.name`)
    const address = addressName(
        /* get(data, `${location}.address`) || */ data,
        location
    )
    return (
        <Grid item xs>
            <Typography>
                {name ? highlightTerm(term, name) : capitalize(location)}
            </Typography>
            <Typography variant="caption">
                {highlightTerm(term, address)}
            </Typography>
        </Grid>
    )
}

export default LocationFragment
