import React, { Fragment, useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Field, formValues, reset } from "redux-form"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import { attachmentOptions, attachmentOptionsMessages } from "../../../../misc"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { useAttachmentsContext } from "../../../../context/providers/attachmentsProvider"
import { populateTrackForm } from "../../../../actions/track"
import { UploadDocument } from "../../../documents/upload/UploadDocument"
import { CustomsDocumentList } from "../../../locations/components/customsProfile/CustomsDocumentsCard"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import { useCustomsProfileContext } from "../../../../context/providers/CustomsProfileProvider"
import GlobalSpinner from "../../../util/spinner"
import { FormlessFilenameCollisionCheckStep } from "../../../documents/checks/FilenameCollisionCheckStep"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { UploadProcessingDialog } from "../../../documents/upload/UploadProcessingDialog"
import FormSelect from "../../../form/form-select"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"

const styles = theme => ({
    card: {
        height: "100%",
        width: "100%",
    },
    content: {},
    dropZone: {
        padding: "30px",
        border: "1px dashed #D4D4D4",
        borderRadius: "5px",
        "&:hover": {
            cursor: "pointer",
        },
    },
    dropZone__root: {
        outline: "none",
    },
    dialog__content: {
        padding: "10px",
    },
    dialog__section: {
        padding: "10px",
    },
    dialog__fileIcon: {
        marginLeft: "5px",
    },
    dialog__caption: {
        paddingLeft: "3px",
    },
    existingFile: {
        padding: "10px",
    },
    existingFile__icon: {
        fontSize: "60px",
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.primary.main,
        },
    },
    dialog__overflowVisible: {
        overflowY: "visible",
    },
    previewImage: {
        width: "100%",
    },
    previewImage__container: {
        position: "relative",
        overflowY: "auto",
        borderLeft: "solid 1px #D4D4D4",
        padding: "5px",
    },
    dialog__main__container: {
        height: "100%",
    },
    dialog__section__parent: {
        position: "relative",
        overflowY: "auto",
    },
    dialog__section__child: {
        position: "absolute",
        width: "100%",
    },
    link__document__dialog: {
        height: "100vh",
    },
})

export const _arrayBufferToBase64 = buffer => {
    let binary = ""
    let bytes = new Uint8Array(buffer)
    let len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return btoa(binary)
}

const attachmentOptionList = [
    {
        value: "USMCA",
        label: attachmentOptionsMessages["USMCA"],
    },
    {
        value: "certificateOfOrigin",
        label: attachmentOptionsMessages["certificateOfOrigin"],
    },
]

let LinkDocumentStep = formValues("documentCategoryFilter")(
    ({
        shipmentDocumentList,
        classes,
        locationId,
        onSelect,
        onClose,
        documentCategoryFilter = "USMCA",
    }) => {
        const [isLoading, setIsLoading] = useState(false)
        const [isError, setIsError] = useState(false)
        const [documents, setDocuments] = useState([])
        const [preViewdocument, setPreViewdocumentt] = useState([])

        const [imgSrc, setImgSrc] = useState(null)
        const { documentList, getDocument } = useCustomsProfileContext()
        const filteredDocumentList = documentList.filter(
            doc => doc?.documentCategory === documentCategoryFilter
        )
        useEffect(() => {
            if (filteredDocumentList?.length) {
                setPreViewdocumentt(filteredDocumentList[0])
                setDocuments([filteredDocumentList[0]])
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [documentList, documentCategoryFilter])

        useEffect(() => {
            const loadDocument = async () => {
                setImgSrc(null)
                setIsLoading(true)
                setIsError(false)
                const document = preViewdocument
                try {
                    const documentContent = await getDocument(
                        document?.documentCategory,
                        document?.customFilename,
                        "png"
                    )
                    if (documentContent?.content !== null) {
                        setImgSrc(
                            `data:image/png;base64,${documentContent?.content}`
                        )
                    }
                } catch (error) {
                    setIsError(true)
                } finally {
                    setIsLoading(false)
                }
            }
            loadDocument()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [locationId, preViewdocument])

        const onSubmit = async () => {
            onSelect(documents)
        }

        const setDocumentsList = document => {
            let newListDoc = []
            if (documentCategoryFilter === "USMCA") {
                newListDoc = [document]
            } else {
                newListDoc = documents
                if (
                    !documents.some(x => x?.documentId === document?.documentId)
                ) {
                    newListDoc.push(document)
                } else {
                    newListDoc = documents.filter(doc => {
                        return doc?.documentId !== document?.documentId
                    })
                }
            }
            setPreViewdocumentt(document)
            setDocuments(newListDoc)
        }

        return (
            <Fragment>
                <DialogTitle>
                    <Grid
                        item
                        container
                        justify="space-between"
                        alignItems="center"
                    >
                        <FormattedMessage
                            id="documents.link__document__title"
                            defaultMessage="Link document"
                        />
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.link__document__dialog}>
                    <Grid
                        item
                        container
                        className={classes.dialog__main__container}
                    >
                        <Grid
                            item
                            container
                            xs={4}
                            className={classes.dialog__section__parent}
                        >
                            <Grid
                                item
                                xs={12}
                                className={classes.dialog__section__child}
                            >
                                <Field
                                    name="documentCategoryFilter"
                                    label={
                                        <FormattedMessage
                                            id="documents.upload__selectFileCategory"
                                            defaultMessage="File Category"
                                        />
                                    }
                                    component={FormSelect}
                                    options={attachmentOptionList}
                                    required
                                />
                                <CustomsDocumentList
                                    selectable={true}
                                    attachable={false}
                                    previewable={false}
                                    selectedDocuments={documents}
                                    onSelect={document =>
                                        setDocumentsList(document)
                                    }
                                    customsDocuments={filteredDocumentList}
                                    attachedDocuments={shipmentDocumentList}
                                    locationId={locationId}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={8}
                            justify="center"
                            className={classes.previewImage__container}
                        >
                            {!isLoading && imgSrc && !isError && (
                                <Grid
                                    item
                                    className={classes.dialog__section__child}
                                >
                                    <img
                                        className={classes.previewImage}
                                        src={imgSrc}
                                        alt={
                                            <FormattedMessage
                                                id="documents__unavailable"
                                                defaultMessage="Unavailable"
                                            />
                                        }
                                    />
                                </Grid>
                            )}
                            {!isLoading && (!imgSrc || isError) && (
                                <FormattedMessage
                                    id="documents__unavailable"
                                    defaultMessage="Unavailable"
                                />
                            )}
                            {isLoading && <GlobalSpinner isGlobal={false} />}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onClose}>
                        <FormattedMessage
                            id="generalTerms__cancel"
                            defaultMessage="Cancel"
                        />
                    </Button>
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="secondary"
                        disabled={documents?.length <= 0}
                    >
                        <FormattedMessage
                            id="generalTerms.link__document"
                            defaultMessage="Link document"
                        />
                    </Button>
                </DialogActions>
            </Fragment>
        )
    }
)
const linkMapStateToProps = (state, props) => ({
    shipmentDocumentList:
        state.shipment.list[props?.shipmentId]?.shipment?.attachments ?? [],
})

LinkDocumentStep = connect(linkMapStateToProps)(
    withStyles(styles)(LinkDocumentStep)
)

let LinkDocument = ({ open, locationId, shipmentId, onClose }) => {
    const [stagedDocuments, setStagedDocuments] = useState(null)
    const [step, setStep] = useState("linkDocument")

    useEffect(() => {
        if (stagedDocuments) {
            setStep("filenameCollisionCheck")
        }
    }, [stagedDocuments])

    useEffect(() => {
        if (open) {
            resetState()
        }
    }, [open])

    const { associateDocumentsToShipment } = useCustomsProfileContext()
    const { openSnackbar } = useSnackbarContext()

    const onSubmit = async () => {
        setStep("processing")
        try {
            await associateDocumentsToShipment(stagedDocuments, shipmentId)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.manage__addSuccess"
                    defaultMessage="File successfully added"
                />
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.manage__addError"
                    defaultMessage="Unable to add file"
                />
            )
        }
        onClose(true)
    }

    const resetState = () => {
        setStep("linkDocument")
        setStagedDocuments(null)
    }

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            maxWidth="lg"
            fullWidth
            onSubmit={e => {
                e.preventDefault()
                e.stopPropagation()
            }}
        >
            {step === "linkDocument" && (
                <LinkDocumentStep
                    locationId={locationId}
                    shipmentId={shipmentId}
                    onSelect={setStagedDocuments}
                    onClose={() => onClose(false)}
                />
            )}
            {step === "filenameCollisionCheck" &&
                stagedDocuments?.map(document => (
                    <FormlessFilenameCollisionCheckStep
                        key={document?.documentId}
                        documentCategory={document?.documentCategory}
                        customFilename={document?.customFilename}
                        userFileName={document?.userFileName}
                        shipmentId={shipmentId}
                        locationId={locationId}
                        onBack={resetState}
                        onNoCollision={onSubmit}
                        onSubmit={onSubmit}
                        shipmentOnly={true}
                    />
                ))}
            {step === "processing" && <UploadProcessingDialog />}
        </Dialog>
    )
}

const AttachmentsCard = ({
    classes,
    locationId,
    internalTrackingNumber,
    isInternational,
}) => {
    const [linkDocumentDialogOpen, setLinkDocumentDialogOpen] = useState(false)
    const dispatch = useDispatch()
    const {
        openDialog,
        attachments,
        setInternalTrackingNumber,
    } = useAttachmentsContext()
    const { documentList, setLocationId } = useCustomsProfileContext()

    useEffect(() => {
        setInternalTrackingNumber(internalTrackingNumber)
        setLocationId(locationId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalTrackingNumber, locationId])

    const onFinish = async successful => {
        dispatch(reset("attachment"))
        if (successful) {
            await dispatch(populateTrackForm(internalTrackingNumber))
        }
    }

    const onUploadFinish = async () => {
        await onFinish(true)
    }

    const onDocumentDialogClose = async successful => {
        setLinkDocumentDialogOpen(false)
        await onFinish(successful)
    }

    return (
        <Grid item container>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={
                        <Typography variant="title">
                            <FormattedMessage
                                id="documents__sectionTitle"
                                defaultMessage="Documents"
                            />
                        </Typography>
                    }
                    action={
                        isInternational && (
                            <Button
                                color="primary"
                                disabled={!documentList?.length}
                                onClick={() => setLinkDocumentDialogOpen(true)}
                            >
                                <FormattedMessage
                                    id="generalTerms.link__documents"
                                    defaultMessage="Link documents"
                                />
                            </Button>
                        )
                    }
                />
                <CardContent className={classes.content}>
                    <UploadDocument
                        shipmentId={internalTrackingNumber}
                        locationId={locationId}
                        onFinish={onUploadFinish}
                    />
                    <LinkDocument
                        open={linkDocumentDialogOpen}
                        locationId={locationId}
                        shipmentId={internalTrackingNumber}
                        onClose={onDocumentDialogClose}
                    />
                    <Grid
                        item
                        container
                        className={classes.existingFiles__container}
                    >
                        {attachments.map(file => {
                            const categoryLabel = attachmentOptions.find(
                                entry => file.attachmentFileType === entry.value
                            )
                            const label = categoryLabel ? (
                                <FormattedMessage {...categoryLabel.label} />
                            ) : (
                                file.label
                            )

                            return (
                                <Grid
                                    item
                                    container
                                    xs={3}
                                    className={classes.existingFile}
                                    direction="column"
                                    alignItems="center"
                                    justify="center"
                                >
                                    <Typography
                                        variant="subheading"
                                        onClick={() =>
                                            openDialog(
                                                internalTrackingNumber,
                                                "manage",
                                                file,
                                                label
                                            )
                                        }
                                    >
                                        {label}
                                    </Typography>
                                    {file?.userFileName && (
                                        <Typography
                                            variant="caption"
                                            onClick={() =>
                                                openDialog(
                                                    internalTrackingNumber,
                                                    "manage",
                                                    file,
                                                    label
                                                )
                                            }
                                        >
                                            {file.userFileName}
                                        </Typography>
                                    )}
                                    <InsertDriveFileIcon
                                        onClick={() =>
                                            openDialog(
                                                internalTrackingNumber,
                                                "manage",
                                                file,
                                                label
                                            )
                                        }
                                        className={classes.existingFile__icon}
                                        color={
                                            file.isSystem
                                                ? "inherit"
                                                : "inherit"
                                        }
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                    <Grid item container justify="flex-end">
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                                openDialog(
                                    internalTrackingNumber,
                                    "share",
                                    undefined,
                                    undefined,
                                    true
                                )
                            }
                        >
                            <FormattedMessage
                                id="documents.manage__share"
                                defaultMessage="Share"
                            />
                        </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
export default withStyles(styles)(AttachmentsCard)
