import { clone } from "lodash"
import { SubmissionError } from "redux-form"
import { goFetch, goFetchV2 } from "../http"
import { changePath } from "./index"
import { noRateResults, rateRequestError } from "../messages/error-constants"
import { invalidateItemList } from "./item"
import { filterRatesError, quoteLoad } from "./quote-request"
import moment from "moment"
import {
    updateUserCustomsBrokeragePreferences,
    updateUserHazMatPreferences,
} from "./user"
import { requestContactAddresses } from "./address"
import { addShipment } from "./track"
import { cleanupObject, cleanupObjectDeep } from "../misc"
import {
    createCertificateOfOrigin,
    createAndAssociateDocuments,
} from "./book-shipment"

export const QUOTE_LOAD = "QUOTE_LOAD"
export const QUOTE_RESULT = "QUOTE_RESULT"
export const QUOTE_ADD_LIST_ITEM = "QUOTE_ADD_LIST_ITEM"

export const RATE_LOAD = "RATE_LOAD"

export function addQuoteListItem(quote) {
    return { type: QUOTE_ADD_LIST_ITEM, quote }
}

export function addActiveRate(quote) {
    return { type: RATE_LOAD, payload: quote }
}

export function populateActiveRate(shipmentId) {
    return async (dispatch, getState) => {
        try {
            const response = await goFetchV2(
                `/quotes/${shipmentId}`,
                {
                    method: "GET",
                    credentials: "same-origin",
                },
                true
            )

            let adjustedRate = { ...response.data }

            adjustedRate.result.ratesError = filterRatesError(
                adjustedRate?.result?.rateQuotes,
                adjustedRate?.result?.ratesError
            )
            return dispatch(addActiveRate(adjustedRate))
        } catch (error) {
            throw error
        }
    }
}

const preparePayload = values => {
    const {
        selectedLocation,
        preferredCurrencyCode,
        preferredSystemOfMeasurement,
        pickupDate,
        userProvidedTotalLinearFeet,
        totalLinearFeet,
        capLoadTotalLinearFeet,
        volumeTotalLinearFeet,
        isOverLTLLimit,
        isInBondShipment,
        protectFromFreezing,
        origin,
        destination,
        handlingUnits,
        isQuickRate,
        mode,
        includeThirdParty,
        includeVolume,
        isFreightDirectReturns,
        isFreightDirect,
    } = values

    let { pickupAccessorials, deliveryAccessorials } = values

    if (typeof pickupAccessorials === "string") {
        pickupAccessorials = pickupAccessorials.split(",")
    }
    if (typeof deliveryAccessorials === "string") {
        deliveryAccessorials = deliveryAccessorials.split(",")
    }

    return {
        includeThirdParty,
        includeVolume,
        locationId: selectedLocation?._id,
        cpg: selectedLocation?.cpgCode,
        preferredCurrencyCode,
        preferredSystemOfMeasurement,
        pickupDate: moment(pickupDate).format("YYYY-MM-DD"),
        userProvidedTotalLinearFeet,
        totalLinearFeet,
        capLoadTotalLinearFeet,
        volumeTotalLinearFeet,
        isOverLTLLimit,
        isInBondShipment,
        protectFromFreezing,
        origin: {
            contact: origin?.contact,
            pickupContact: origin?.pickupContact,
            shippingAddress: origin?.shippingAddress,
            readyTime: origin?.readyTime,
            closeTime: origin?.closeTime,
        },
        destination: {
            contact: destination?.contact,
            note: destination?.note,
            shippingAddress: destination?.shippingAddress,
        },
        mode,
        handlingUnits,
        pickupAccessorials:
            isFreightDirectReturns || isFreightDirect
                ? ["DOCKPU"]
                : pickupAccessorials,
        deliveryAccessorials:
            isFreightDirectReturns || isFreightDirect
                ? ["DOCKDEL"]
                : deliveryAccessorials,
        isQuickRate,
        isFreightDirectReturn: isFreightDirectReturns,
    }
}

export function requestQuote(values) {
    return async (dispatch, getState) => {
        // try {
        //     await pastDateValidator(quoteValues, dispatch)
        // } catch (error) {
        //     throw new SubmissionError(error)
        // }
        const state = getState()

        dispatch(quoteLoad())
        const isUpdate = state?.quotes?.active?.isLoaded && !values.isReturnMode
        const shipmentId = state?.identifiers?.internalTrackingNumber
        const url = isUpdate ? `/quotes/${shipmentId}` : "/quotes"
        let adjustedRate
        try {
            const response = await goFetchV2(
                url,
                {
                    method: isUpdate ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: preparePayload(values),
                },
                true
            )
            adjustedRate = { ...response.data }

            adjustedRate.result.ratesError = filterRatesError(
                adjustedRate?.result?.rateQuotes,
                adjustedRate?.result?.ratesError
            )
            if (values.isQuickRate) {
                dispatch(loadQuickQuoteResult(adjustedRate))
            } else {
                dispatch(loadBSQuoteResult(adjustedRate))
            }
        } catch (err) {
            throw rateRequestError
        }
        if (!adjustedRate?.result?.rateQuotes)
            throw new SubmissionError({ _error: noRateResults })
        dispatch(invalidateItemList())
    }
}

const contactPayload = ({ name, phone, email } = {}) =>
    cleanupObject({ name, phone, email })

const addressPayload = ({
    postalCode,
    street1,
    street2,
    city,
    state,
    country,
} = {}) => cleanupObject({ postalCode, street1, street2, city, state, country })

const timePayload = (time, date) => {
    if (!time) return moment(date).format("HH:mm")
    return moment(time, "HH:mm A").format("HH:mm")
}

const transformAlerts = (alerts, selectedRecipients) => ({
    preferences: alerts?.user,
    share:
        selectedRecipients?.map(recipient => {
            const preferences = alerts[recipient.label]
            const preferencesWithoutLanguage = clone(preferences)
            delete preferencesWithoutLanguage?.language
            return {
                name: recipient.label,
                email: recipient.value.value,
                language: preferences?.language,
                preferences: preferencesWithoutLanguage,
            }
        }) ?? [],
})

function endpointPayload(endpoint, date, appointmentNeeded = false) {
    return cleanupObject({
        _id: endpoint._id,
        contactId: endpoint?.contact?._id,
        name: endpoint?.shippingAddress?.name,
        address: addressPayload(
            endpoint?.shippingAddress?.address ?? endpoint?.address
        ),
        contact: contactPayload(endpoint.contact),
        appointment: appointmentNeeded
            ? contactPayload(endpoint.pickupContact)
            : undefined,
        note: endpoint.note,
        accessorials: endpoint.accessorials,
        date: moment.utc(date).format("YYYY-MM-DD"),
        startTime: timePayload(endpoint.readyTime, date),
        endTime: timePayload(endpoint.closeTime, date),
        isSaveAddress: !!(endpoint.isSaveAddress || endpoint._id),
        taxIdentification: endpoint.taxIdentification,
    })
}

const cleanupBroker = (broker = {}) => {
    const { selectBroker, updatedByUserId, updatedAt, ...rest } = broker

    let cleanedBroker = cleanupObjectDeep(rest)
    if (
        !cleanedBroker?.address?.postalCode &&
        !cleanedBroker?.address?.postal_code
    ) {
        cleanedBroker.address = undefined
    }
    return cleanedBroker
}

function createShipmentPayload(values) {
    return cleanupObject({
        origin: endpointPayload(
            values?.origin,
            values?.pickupDate,
            !values?.pickupLater
        ),
        destination: endpointPayload(
            values?.destination,
            values?.selectedRate?.deliveryDateTime
        ),
        carrier: values?.selectedRate?.carrierCode,
        cf7512: values?.cf7512,
        isPickupLater: values?.pickupLater ?? false,
        ...values?.identifiers,
        handlingUnits: values?.handlingUnits,
        alternateQuoteIndex: values?.selectedRate?.alternateQuote,
        selectedRate: values?.selectedRate?._id,
        shipMode: values?.selectedRate?.mode,
        direction: values?.selectedRate?.direction,
        appointment: values?.appointment && contactPayload(values?.appointment),
        emergency: values?.hazmatEmergency,
        exportCustomsBrokerProfile: values?.exportCustomsBrokerProfile
            ? cleanupBroker(values?.exportCustomsBrokerProfile)
            : null,
        importCustomsBrokerProfile: values?.importCustomsBrokerProfile
            ? cleanupBroker(values?.importCustomsBrokerProfile)
            : null,
        alerts: transformAlerts(values?.alerts, values?.selectedRecipients),
        commercialInvoice: values?.requiresCommercialInvoice
            ? values?.commercialInvoice
            : null,
        signatureImage: values?.signatureImage ?? null,
        itemComments: values?.itemComments,
        purposeOfShipment: values?.purposeOfShipment,
    })
}

function internalCreateShipment(values) {
    return async dispatch => {
        if (!values?.selectedRate) throw new Error("No rate selected")
        const { data: shipment } = await goFetch(
            "/shipments",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: createShipmentPayload(values),
            },
            true
        )
        dispatch(requestContactAddresses())
        dispatch(addShipment(shipment))
        return shipment
    }
}

export function createShipment(values) {
    return async dispatch => {
        try {
            await Promise.all([
                dispatch(updateUserHazMatPreferences(values?.hazmatEmergency)),
                dispatch(
                    updateUserCustomsBrokeragePreferences(
                        values?.exportCustomsBrokerageInfo,
                        values?.importCustomsBrokerageInfo
                    )
                ),
            ])
            const shipmentResult = await dispatch(
                internalCreateShipment(values)
            )
            dispatch(invalidateItemList())
            if (shipmentResult?.response?.message?.errorMessage) {
                throw new Error(shipmentResult?.response?.message?.errors?.[0])
            }
            dispatch(postShipmentActions(shipmentResult, values))
            return shipmentResult
        } catch (error) {
            let errorMessage =
                error?.response?.message?.errors?.[0]?.diagnostic ??
                error?.response?.message?.errors?.[0]?.message ??
                error?.response?.diagnostic ??
                error?.response?.message ??
                "Error"
            throw errorMessage
        }
    }
}

function postShipmentActions(shipment, values) {
    return dispatch => {
        const shipmentId = shipment?.identifiers?.internalTrackingNumber
        if (shipmentId && values?.requiresCertificateOfOrigin) {
            dispatch(
                createCertificateOfOrigin(
                    shipmentId,
                    values?.certificateOfOrigin
                )
            )
        }

        if (values?.selectedCustomsDocuments?.length) {
            dispatch(
                createAndAssociateDocuments(
                    values?.selectedLocation?._id,
                    values.selectedCustomsDocuments,
                    shipmentId
                )
            )
        }
    }
}

export function loadBSQuoteResult(quote) {
    return dispatch => {
        dispatch(addActiveRate(quote))
        const basePath = "/book"
        const shipmentId = quote?.identifiers?.internalTrackingNumber
        const path = shipmentId ? `${basePath}/${shipmentId}` : `${basePath}`
        dispatch(changePath(path))
    }
}

export function loadQuickQuoteResult(quote) {
    return dispatch => {
        dispatch(addActiveRate(quote))
        const basePath = "/quickRate"
        const shipmentId = quote?.identifiers?.internalTrackingNumber
        const path = shipmentId ? `${basePath}/${shipmentId}` : `${basePath}`
        dispatch(changePath(path))
    }
}
