import React, { Component } from "react"
import { get } from "lodash"
import { Modal, withStyles, Grid, Button } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { reduxForm, SubmissionError } from "redux-form"
import AddRecipients from "../locations/components/addRecipients"
import { combineValidators } from "revalidate"
import { emailValidator } from "../../actions/validation"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    modal__container: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: "lightgrey",
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
    },
    button__container: {
        marginTop: "10px",
    },
    margin: {
        margin: theme.spacing.unit,
    },
    chip: {
        margin: "20px 10px",
        backgroundColor: theme.palette.primary.light,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    select__container: {
        padding: "10px",
    },
    recipients__field: {
        width: "250px",
    },
    add__recipient: {
        marginTop: "5px",
    },
    title: {
        marginTop: "10px",
    },
})

class AlertShareModal extends Component {
    onClose() {
        this.props.handleClose()
    }

    confirmRecipients(values) {
        const { newEmailRecipient, selectedRecipients } = values
        if (get(newEmailRecipient, "length")) {
            throw new SubmissionError({
                newEmailRecipient: (
                    <FormattedMessage
                        id="alerts.shareModal__lastEmailError"
                        defaultMessage="It looks like you forgot to add the last email address"
                    />
                ),
            })
        }
        this.props.confirmRecipients(selectedRecipients)
    }

    render() {
        const {
            classes,
            open,
            handleSubmit,
            form,
            isFreightDirect,
            location,
        } = this.props
        return (
            <Modal open={open} onClose={() => this.onClose()}>
                <form>
                    <Grid className={classes.modal__container}>
                        <Grid item container xs={12} spacing={16}>
                            <Grid item xs={12}>
                                <Typography variant="title" gutterBottom>
                                    <FormattedMessage
                                        id="alerts.shareModal__title"
                                        defaultMessage="Recipients"
                                    />
                                </Typography>
                            </Grid>
                            <AddRecipients
                                formName={form}
                                isFreightDirect={isFreightDirect}
                                location={location}
                            />
                            <Grid
                                item
                                xs={12}
                                container
                                justify="space-between"
                                className={classes.button__container}
                            >
                                <Button
                                    variant="contained"
                                    onClick={() => this.onClose()}
                                    className={classes.leftButton}
                                >
                                    <FormattedMessage
                                        id="generalTerms__cancel"
                                        defaultMessage="Cancel"
                                    />
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit(values =>
                                        this.confirmRecipients(values)
                                    )}
                                >
                                    <FormattedMessage
                                        id="generalTerms__confirm"
                                        defaultMessage="Confirm"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </Modal>
        )
    }
}

const validation = combineValidators({
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

export default withStyles(styles)(
    reduxForm({
        form: "alertRecipients", // a unique identifier for this form
        enableReinitialize: true,
        validate: validation,
    })(AlertShareModal)
)
