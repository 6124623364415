import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"

const ChooseItemDialog = ({ open, isInAddHandlingUnitMode, handleClose }) => (
    <Dialog open={open} onClose={() => handleClose(false)}>
        <DialogTitle id="alert-dialog-title">
            {isInAddHandlingUnitMode ? (
                <FormattedMessage
                    id="getRates__replaceHandlingUnit"
                    defaultMessage="Would you like to replace the current handling unit with the one you selected?"
                />
            ) : (
                <FormattedMessage
                    id="getRates__replaceItem"
                    defaultMessage="Would you like to replace the current item with the one you selected?"
                />
            )}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {isInAddHandlingUnitMode ? (
                    <FormattedMessage
                        id="getRates__overwriteHandlingUnit"
                        defaultMessage="This will overwrite the fields of the current incomplete handling unit in the form (including all the items in it)"
                    />
                ) : (
                    <FormattedMessage
                        id="getRates__overwriteItem"
                        defaultMessage="This will overwrite the fields of the current incomplete item in the form"
                    />
                )}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => handleClose(false)}
                color="primary"
                autoFocus
            >
                <FormattedMessage
                    id="generalTerms__cancel"
                    defaultMessage="Cancel"
                />
            </Button>
            <Button
                onClick={() => handleClose(true)}
                color="secondary"
                autoFocus
            >
                <FormattedMessage id="generalTerms__yes" defaultMessage="Yes" />
            </Button>
        </DialogActions>
    </Dialog>
)

export default ChooseItemDialog
