import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { FormattedMessage, useIntl } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { modeDictionary } from "../atoms/modeOptions"
import { constructSubLabel, constructLabel } from "../../../util"

const useStyles = makeStyles({
    basicInfoSummaryContainer: {
        padding: "0 15%",
    },
    paper: {
        width: "100%",
        display: "flex",
        padding: "15px",
        flexWrap: "wrap",
    },
    header: {
        paddingBottom: "15px",
    },
    section: {
        paddingTop: "15px",
    },
})

export default function BasicInfoSummary({
    handleEdit,
    formValues = {},
    isReview,
}) {
    const classes = useStyles()
    const intl = useIntl()

    const {
        selectedLocation,
        mode,
        pickupDate,
        protectFromFreezing,
        isInBondShipment,
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
    } = formValues

    const { city, state: addressState } =
        selectedLocation?.shippingAddress?.address ?? {}
    const defaultNickname = `${selectedLocation?.shippingAddress?.name} - ${city}, ${addressState}`

    const label = constructLabel(selectedLocation?.cpgName, defaultNickname)

    const subLabel = constructSubLabel(
        selectedLocation?.fedexFreightAccount,
        selectedLocation?.fedexBillToAccount,
        intl,
        selectedLocation?.locationType === "FEDEX_DIRECT" ||
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"
    )
    return (
        <Grid
            item
            container
            className={!isReview ? classes.basicInfoSummaryContainer : ""}
        >
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.basicInfoSummary__title"
                            defaultMessage="Basic Info"
                        />
                    </Typography>
                    {!isReview && (
                        <Button
                            id="editBasicInfo"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleEdit(0)}
                        >
                            <FormattedMessage
                                id="generalTerms__edit"
                                defaultMessage="Edit"
                            />
                        </Button>
                    )}
                </Grid>
                <Grid item container direction="column">
                    <Typography variant="subheading">{label}</Typography>
                    <Typography variant="caption">{subLabel}</Typography>
                    {isFreightDirect && !isFreightDirectReturns && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirect"
                                    defaultMessage="FedEx Freight Direct"
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightBox && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightBox"
                                    defaultMessage="FedEx Freight Box"
                                />
                            </Typography>
                        </Grid>
                    )}
                    {isFreightDirectReturns && (
                        <Grid>
                            <Typography variant="caption" color="primary">
                                <FormattedMessage
                                    id="getRates.billToLocation__freightDirectReturns"
                                    defaultMessage="FedEx Freight Direct Returns"
                                />
                            </Typography>
                        </Grid>
                    )}
                    <Typography className={classes.section}>
                        {moment.utc(pickupDate).format("L")}
                    </Typography>
                    <Typography className={classes.section}>
                        {modeDictionary[mode]}
                    </Typography>
                    {protectFromFreezing && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="accessorials.protectFromFreezing"
                                defaultMessage="Protect From Freezing"
                            />
                        </Typography>
                    )}
                    {isInBondShipment && (
                        <Typography variant="caption">
                            <FormattedMessage
                                id="accessorials.panel__freightMovingInBond"
                                defaultMessage="Freight Moving In-Bond"
                            />
                        </Typography>
                    )}
                </Grid>
            </Paper>
        </Grid>
    )
}
