import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Field, FormSection } from "redux-form"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import Popper from "@material-ui/core/Popper"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import ContactForm from "../contactForm"
import FormField from "../../form/form-field"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import { SuggestionBox } from "../suggestionBox"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
    done: {
        "margin-left": "auto",
    },
    city__state: {
        height: "100%",
        "margin-top": "auto",
    },
})

class Form extends React.Component {
    state = {
        showOverlay: false,
        anchorEl: null,
    }

    handleContainerMouseDown = () => {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputFocus = e => {
        this.setState({ showOverlay: true, anchorEl: e.currentTarget })
    }

    handleInputBlur = e => {
        const showOverlay = this.clickedInside

        // Force input's focus if blur event was caused by clicking on dropdown
        if (!showOverlay) {
            this.setState({ showOverlay: false, anchorEl: null })
        }
    }

    componentDidMount() {
        this.clickedInside = false
        this.clickTimeout = null
    }

    componentWillUnmount = () => {
        clearTimeout(this.clickTimeout)
    }

    render() {
        const {
            state,
            postalCode,
            country,
            classes,
            title,
            readOnly,
            searchInProgress,
            mode,
            searchTerm,
            performContactSearch,
            handleAddressSelect,
            cities,
            isNameDirty,
            handleAddressFocus,
            handleAddressChange,
            serviceLevel,
            changeToTerminal,
        } = this.props
        const { showOverlay, anchorEl } = this.state

        return (
            <Fragment>
                <Grid xs={6} item>
                    <Card className={classes.card}>
                        <CardHeader
                            className={classes.header}
                            title={
                                <Typography variant="headline">
                                    {title}
                                </Typography>
                            }
                            avatar={
                                <Avatar>
                                    <LocationOnIcon
                                        variant="contained"
                                        color="secondary"
                                    />
                                </Avatar>
                            }
                        />
                        <CardContent className={classes.content}>
                            <FormSection name="address" component={Fragment}>
                                <Field
                                    name="name"
                                    required
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__companyName"
                                            defaultMessage="Company Name"
                                        />
                                    }
                                    component={FormField}
                                    onFocus={e => this.handleInputFocus(e)}
                                    onBlur={e => this.handleInputBlur(e)}
                                    onChange={e => {
                                        performContactSearch(e.target.value)
                                    }}
                                />
                                <Popper
                                    onMouseDown={() =>
                                        this.handleContainerMouseDown()
                                    }
                                    open={showOverlay && isNameDirty}
                                    anchorEl={anchorEl}
                                    placement="top"
                                    disablePortal
                                    modifiers={{
                                        flip: {
                                            enabled: true,
                                        },
                                        preventOverflow: {
                                            enabled: true,
                                            boundariesElement: "scrollParent",
                                        },
                                    }}
                                    className={classes.popper}
                                >
                                    <SuggestionBox
                                        searchInProgress={searchInProgress}
                                        term={searchTerm}
                                        handleAddressSelect={item => {
                                            this.setState({
                                                showOverlay: false,
                                                anchorEl: null,
                                            })
                                            handleAddressSelect(mode, item)
                                        }}
                                        postalCode={postalCode}
                                        country={country}
                                    />
                                </Popper>
                                <Field
                                    name="street1"
                                    required
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__address1"
                                            defaultMessage="Address 1"
                                        />
                                    }
                                    component={FormField}
                                    disabled={readOnly}
                                    onFocus={() => handleAddressFocus()}
                                    onChange={() => handleAddressChange()}
                                />
                                <Field
                                    name="street2"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__address2"
                                            defaultMessage="Address 2"
                                        />
                                    }
                                    component={FormField}
                                    disabled={readOnly}
                                    onFocus={() => handleAddressFocus()}
                                    onChange={() => handleAddressChange()}
                                />
                                <Field
                                    name="city"
                                    label={
                                        <FormattedMessage
                                            id="generalTerms__city"
                                            defaultMessage="City"
                                        />
                                    }
                                    component={FormSelectAutocomplete}
                                    customValue
                                    options={cities.map(value => ({
                                        value,
                                        label: value,
                                    }))}
                                    onFocus={() => handleAddressFocus()}
                                    onChange={() => handleAddressChange()}
                                />
                                <Typography
                                    variant="subheading"
                                    color="primary"
                                >
                                    {state &&
                                        postalCode &&
                                        country &&
                                        `${state} ${postalCode}, ${country}`}
                                </Typography>
                            </FormSection>
                            <FormSection name="contact" component={Fragment}>
                                <ContactForm
                                    country={country}
                                    serviceLevel={serviceLevel}
                                    changeToTerminal={changeToTerminal}
                                    mode={mode}
                                />
                            </FormSection>
                        </CardContent>
                    </Card>
                </Grid>
            </Fragment>
        )
    }
}

Form.propTypes = {
    classes: PropTypes.object,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
}

Form.defaultProps = {
    classes: {},
    state: "",
    postalCode: "",
}

export default withStyles(styles)(Form)
