import React from "react"
import PropTypes from "prop-types"
import { Field } from "redux-form"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage, injectIntl } from "react-intl"
import FormField from "../form/form-field"
import { AccessorialsDisplay } from "../util/accessorialsDisplay"
import Chip from "@material-ui/core/Chip"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
})

const DeliveryForm = ({
    intl,
    classes,
    shipmentId,
    mode,
    serviceLevel,
    isInBondShipment,
}) => (
    <Grid xs={6} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={
                    <Typography variant="headline">
                        <FormattedMessage
                            id="orderDetails.delivery__title"
                            defaultMessage="Delivery Requirements"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.content}>
                {mode !== "LTL_DIRECT" && mode !== "LTL_DIRECT_RETURNS" ? (
                    <AccessorialsDisplay
                        shipmentId={shipmentId}
                        isInBondShipment={isInBondShipment}
                    />
                ) : (
                    <Grid item container>
                        <Typography variant={"subheading"} color="primary">
                            <Chip
                                label={
                                    serviceLevel?.description
                                        ? serviceLevel?.description
                                        : serviceLevel?.code
                                }
                                color="primary"
                                tabIndex={-1}
                            />
                        </Typography>
                    </Grid>
                )}
                {mode !== "LTL_DIRECT" && mode !== "LTL_DIRECT_RETURNS" && (
                    <Field
                        name="note"
                        label={
                            <FormattedMessage
                                id="orderDetails.delivery__specialInstructions"
                                defaultMessage="Special Instructions"
                            />
                        }
                        component={FormField}
                        multiline
                    />
                )}
            </CardContent>
        </Card>
    </Grid>
)

DeliveryForm.propTypes = {
    classes: PropTypes.object,
}

DeliveryForm.defaultProps = {
    classes: {},
}

export default withStyles(styles)(injectIntl(DeliveryForm))
