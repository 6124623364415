import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import moment from "moment-business-days"
import { CarrierLogo } from "../../util"
import { QuoteDeliveryTime, QuotePrice, QuoteInfoBreakdown } from "./util"
import { trackGAEvent } from "../../../actions/user"

export const QuoteCardHeader = ({ deliveryDate, fastest, classes }) => (
    <Grid className={classes.quote_date}>
        {deliveryDate ? (
            <Typography>
                {deliveryDate.format("dddd MMM-Do")}
                {fastest && (
                    <span className="left-padding fastest">Fastest</span>
                )}
            </Typography>
        ) : (
            <Typography className="left-padding call-carrier">
                {"Call carrier for delivery date"}
            </Typography>
        )}
    </Grid>
)

QuoteCardHeader.propTypes = {
    deliveryDate: PropTypes.object,
    fastest: PropTypes.bool.isRequired,
}

QuoteCardHeader.defaultProps = {
    deliveryDate: undefined,
}

export const QuoteCardCarrier = ({
    carrierCode,
    mode,
    direction,
    serviceLevel,
}) => (
    <div className="quote-card-header">
        <div className="row-stretch">
            <CarrierLogo
                carrierCode={carrierCode}
                mode={mode}
                serviceLevel={serviceLevel}
            />
            <div className="rate-direction">
                {direction === "THIRD_PARTY" ? "3rd Party Pricing" : ""}
            </div>
        </div>
        <div className="row-spacing-rule" />
    </div>
)

QuoteCardCarrier.propTypes = {
    carrierCode: PropTypes.string,
    mode: PropTypes.string,
    direction: PropTypes.string,
    serviceLevel: PropTypes.string,
}

QuoteCardCarrier.defaultProps = {
    carrierCode: null,
    serviceLevel: null,
    mode: "LTL",
    direction: "SHIPPER",
}

export class QuoteCardContent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            breakdownOpen: false,
        }

        this.handleInfoClick = this.handleInfoClick.bind(this)
    }

    handleInfoClick(e) {
        e.stopPropagation()
        if (!this.state.breakdownOpen)
            trackGAEvent("Quote", "Rate details popup", this.props.cpg)
        this.setState({ breakdownOpen: !this.state.breakdownOpen })
    }

    render() {
        const {
            price,
            serviceCode,
            deliveryDate,
            onClick,
            displayMarkup,
            selected,
            viewMode,
            isShippedRateQuote,
            quoteInfo,
            carrierCode,
            classes,
            preferredCurrencyCode,
        } = this.props
        return (
            <Grid
                className={`quote-card-content row-stretch ${
                    selected ? "quote-card-share-selected" : ""
                } ${viewMode ? "view-mode" : ""}
              ${isShippedRateQuote ? "quote-card-rate-selected" : ""} ${
                    this.state.breakdownOpen ? "quote-card-breakdown-open" : ""
                }`}
                role="button"
                onClick={onClick}
                tabIndex="-1"
                direction="row"
                style={{ width: "100%" }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.quote_delivery_price}
                >
                    <Typography>
                        <QuotePrice
                            preferredCurrencyCode={preferredCurrencyCode}
                            price={price}
                            displayMarkup={displayMarkup}
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    justify="flex-end"
                    direction="column"
                    className={classes.quote_delivery_time}
                >
                    <Typography>
                        <QuoteDeliveryTime
                            date={deliveryDate}
                            serviceCode={serviceCode}
                        />
                    </Typography>
                </Grid>

                <Grid item className="quote-breakdown-tooltip">
                    {quoteInfo && quoteInfo.rateQuoteDetail && (
                        <QuoteInfoBreakdown
                            onClickBookShipment={onClick}
                            onClickInfo={this.handleInfoClick}
                            isOpen={this.state.breakdownOpen}
                            quoteInfo={quoteInfo}
                            carrierCode={carrierCode}
                            viewMode={viewMode}
                        />
                    )}
                </Grid>
            </Grid>
        )
    }
}

QuoteCardContent.propTypes = {
    serviceCode: PropTypes.string,
    price: PropTypes.number,
    deliveryDate: PropTypes.object,
    onClick: PropTypes.func,
    displayMarkup: PropTypes.bool,
    viewMode: PropTypes.bool,
    selected: PropTypes.bool,
    isShippedRateQuote: PropTypes.bool,
    quoteInfo: PropTypes.object,
    carrierCode: PropTypes.string,
    cpg: PropTypes.string,
}

QuoteCardContent.defaultProps = {
    serviceCode: null,
    price: null,
    deliveryDate: null,
    onClick: () => {},
    displayMarkup: true,
    selected: false,
    viewMode: false,
    isShippedRateQuote: false,
    quoteInfo: {},
    carrierCode: null,
    cpg: "",
}

export const QuoteCardPresentation = props => {
    const {
        quotes,
        carrierCode,
        onClick,
        fastest,
        displayMarkup,
        share,
        viewMode,
        shippedRateQuote,
        onMoreInfoClick,
        cpg,
        classes,
        preferredCurrencyCode,
    } = props

    // see if this quote card is selected
    let quotesMatchingCarrier = []
    if (share && share.selections) {
        quotesMatchingCarrier = share.selections.filter(
            q => q.name === carrierCode
        )
    }
    return (
        <Grid item container>
            <Grid item container direction="row">
                <QuoteCardHeader
                    deliveryDate={
                        quotes[0].transitDays &&
                        moment(quotes[0].deliveryDateTime)
                    }
                    fastest={fastest}
                    classes={classes}
                />
            </Grid>
            <Grid item container direction="row">
                <Grid
                    item
                    container
                    direction="row"
                    className={classes.quote_card_carrier}
                >
                    <QuoteCardCarrier
                        carrierCode={carrierCode}
                        mode={quotes[0].mode}
                        serviceLevel={quotes[0].serviceLevel.description}
                        direction={quotes[0].direction}
                    />
                </Grid>
                <Grid item container direction="row">
                    <Grid item container direction="column">
                        <Grid
                            tem
                            container
                            direction="row"
                            className={classes.quote_card_deliver_text}
                        >
                            <div className="flex-two" />
                            <Typography className="quote-card-delivery-by">
                                Delivery by
                            </Typography>
                            <div className="flex-half" />
                        </Grid>
                        <Grid
                            item
                            container
                            direction="row"
                            className={classes.quote_card_price}
                        >
                            {quotes.map((quote, key) => {
                                const matchingRate = quotesMatchingCarrier.findIndex(
                                    q =>
                                        q.alternateRateQuote === quote.index &&
                                        q.mode === quote.mode &&
                                        q.direction === quote.direction
                                )
                                const selected =
                                    !props.share || !props.share.isOpen
                                        ? false
                                        : matchingRate !== -1
                                const isSelectedRate =
                                    shippedRateQuote.mode === quote.mode &&
                                    carrierCode ===
                                        shippedRateQuote.carrierCode &&
                                    quote.direction ===
                                        shippedRateQuote.direction &&
                                    shippedRateQuote.rateQuoteDetail.total ===
                                        (quote.price ||
                                            (quote.rateQuoteDetail &&
                                                quote.rateQuoteDetail.total))
                                return (
                                    <QuoteCardContent
                                        preferredCurrencyCode={
                                            preferredCurrencyCode
                                        }
                                        key={key}
                                        displayMarkup={displayMarkup}
                                        selected={selected}
                                        viewMode={viewMode}
                                        isShippedRateQuote={isSelectedRate}
                                        price={
                                            quote.price ||
                                            (quote.rateQuoteDetail &&
                                                quote.rateQuoteDetail.total)
                                        }
                                        serviceCode={
                                            quote.serviceLevel &&
                                            quote.serviceLevel.code
                                        }
                                        deliveryDate={moment(
                                            quote.deliveryDateTime
                                        )}
                                        onClick={() =>
                                            onClick(quote.index, key + 1)
                                        }
                                        quoteInfo={quote}
                                        carrierCode={carrierCode}
                                        cpg={cpg}
                                        classes={classes}
                                    />
                                )
                            })}
                            {quotes[0].mode === "VOLUME_LTL" &&
                                (carrierCode === "FXNL" ||
                                    carrierCode === "FXFE") && (
                                    <div className="row quote-volume-align-right">
                                        <a
                                            className="quote-volume-more-info"
                                            href="http://www.fedex.com/us/freight/services/custom-solutions/truckload.html"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            onClick={() => onMoreInfoClick(cpg)}
                                        >
                                            More Info
                                        </a>
                                    </div>
                                )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

QuoteCardPresentation.propTypes = {
    quotes: PropTypes.array.isRequired,
    cpg: PropTypes.string.isRequired,
    shippedRateQuote: PropTypes.object,
    carrierCode: PropTypes.string,
    onClick: PropTypes.func,
    onMoreInfoClick: PropTypes.func.isRequired,
    fastest: PropTypes.bool,
    displayMarkup: PropTypes.bool,
    share: PropTypes.object,
    viewMode: PropTypes.bool,
}

QuoteCardPresentation.defaultProps = {
    carrierCode: null,
    shippedRateQuote: {},
    share: {},
    onClick: () => {},
    displayMarkup: true,
    fastest: false,
    viewMode: false,
}

const mapDispatchToProps = dispatch => ({
    onMoreInfoClick: cpg =>
        dispatch(trackGAEvent("Quote", "Click here for more info", cpg)),
})

export const QuoteCard = connect(
    undefined,
    mapDispatchToProps
)(QuoteCardPresentation)
