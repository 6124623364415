import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button, Radio } from "react-bootstrap"
import { closeModal } from "../../actions/modal"

export const AddressSelectionItem = ({
    raw,
    formatted,
    checked,
    selectable,
    onSelect,
}) => {
    const className = checked ? "suggestion-item-selected" : "suggestion-item"
    const addressString =
        formatted ||
        `${raw.street1}, ${raw.city}, ${raw.state} ${raw.postalCode}`
    if (selectable) {
        return (
            <Radio className={className} checked={checked} onClick={onSelect}>
                {addressString}
            </Radio>
        )
    }
    return <div className={className}>{addressString}</div>
}

AddressSelectionItem.propTypes = {
    raw: PropTypes.shape({
        street1: PropTypes.string.isRequired,
        street2: PropTypes.string,
        city: PropTypes.string.isRequired,
        state: PropTypes.string.isRequired,
        postalCode: PropTypes.string.isRequired,
    }).isRequired,
    formatted: PropTypes.string,
    checked: PropTypes.bool,
    selectable: PropTypes.bool,
    onSelect: PropTypes.func,
}

AddressSelectionItem.defaultProps = {
    formatted: "",
    checked: false,
    selectable: true,
    onSelect: () => {},
}

const AddressSelectionGroup = ({
    address,
    suggestions,
    kind,
    index,
    onSelect,
}) => (
    <div className="suggestion-group">
        <div className="suggestion-prompt">
            Please select the {kind} address you would like to use:
        </div>
        {suggestions.length ? (
            <div className="suggestion-list">
                {suggestions.map((item, i) => (
                    <AddressSelectionItem
                        {...item}
                        key={i}
                        checked={i === index}
                        onSelect={() => onSelect(i)}
                    />
                ))}
                <div>You entered:</div>
                <AddressSelectionItem
                    raw={address}
                    key={suggestions.length}
                    checked={index === suggestions.length}
                    onSelect={() => onSelect(suggestions.length)}
                />
            </div>
        ) : (
            <div className="suggestion-list">
                <div className="no-suggestion">
                    We found no relevant suggestions. Are you sure you entered
                    the correct address?
                </div>
                <div>You entered:</div>
                <AddressSelectionItem
                    checked
                    raw={address}
                    selectable={false}
                />
            </div>
        )}
    </div>
)

AddressSelectionGroup.propTypes = {
    address: PropTypes.object.isRequired,
    suggestions: PropTypes.array.isRequired,
    kind: PropTypes.string.isRequired,
    index: PropTypes.number,
    onSelect: PropTypes.func.isRequired,
}

AddressSelectionGroup.defaultProps = {
    index: 0,
}

const initializeIndexes = groups => {
    if (!groups) return {}
    return groups.reduce((prev, current) => {
        prev[`${current.kind}Index`] = 0
        return prev
    }, {})
}

export class AddressSelectionPresentation extends Component {
    constructor(props, context) {
        super(props, context)
        this.state = initializeIndexes(props.groups)
    }

    componentWillReceiveProps(newProps) {
        this.setState(initializeIndexes(newProps.groups))
    }

    handleAddressAccept() {
        const addresses = this.props.groups.map(group => {
            const index = this.state[`${group.kind}Index`]
            if (index < group.suggestions.length)
                return group.suggestions[index].raw
            return null
        })
        this.props.handleClose()
        this.props.onAddressAccept(addresses)
    }

    render() {
        const { groups, handleClose } = this.props
        return (
            <div className="column">
                {groups.map(group => {
                    if (group.found) return null
                    return (
                        <AddressSelectionGroup
                            key={group.kind}
                            address={group.address}
                            suggestions={group.suggestions}
                            index={this.state[`${group.kind}Index`]}
                            kind={group.kind}
                            onSelect={index =>
                                this.setState({
                                    [`${group.kind}Index`]: index,
                                })
                            }
                        />
                    )
                })}
                <div className="right-align">
                    <Button bsStyle="warning" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        bsStyle="success"
                        onClick={() => this.handleAddressAccept()}
                    >
                        Ok
                    </Button>
                </div>
            </div>
        )
    }
}

AddressSelectionPresentation.propTypes = {
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddressAccept: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch => ({
    handleClose: () => dispatch(closeModal()),
})

export const AddressSelection = connect(
    undefined,
    mapDispatchToProps
)(AddressSelectionPresentation)
