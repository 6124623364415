import React from "react"
import Destination from "./Destination"
import FormRadio from "../../form/form-radio"
import { modeOptions } from "../bookShipment/atoms/modeOptions"
import Accessorials from "../bookShipment/atoms/Accessorials"
import FormSwitch from "../../form/form-switch"
import Items from "./Items"
import FormDatePicker from "../../form/form-datepicker"
import FormSelectBillTo from "../../form/form-select-bill-to"
import Origin from "./Origin"
import { Button, Grid, Typography } from "@material-ui/core"
import { Field } from "redux-form"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import LocationLabel from "../../util/LocationLabel"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    title: {
        paddingBottom: "5px",
    },
    bigSection: {
        paddingTop: "30px",
    },
    baseContainer: {
        paddingTop: "32px",
        paddingBottom: "272px",
    },
    input__field: {
        //backgroundColor: "rgb(242, 242, 242)",
        cursor: "pointer",
        width: "100%",
    },
    textInput__field: {
        cursor: "text",
    },
    workflowContainer: {
        padding: "10px 10% 25px 10%",
    },
    basicInfo: {
        padding: "0 12%",
    },
    getRates: {
        paddingTop: "30px",
    },
    cancelEdit: {
        marginRight: "10px",
    },
    locationLabel: {
        paddingLeft: "3px",
    },
})

export default function QuickRateForm({
    subProps = { formValues: {} },
    onBillToLocationChange,
    onDirectionChange,
    changeField,
    formattedLocations = [],
    initialValues,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    invalid,
    inputProps,
    intl,
    handleHUPanel,
    isHUOpen,
    getRates,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    addNewHandlingUnit,
    handleCancel,
    editMode,
    trackGA,
    submitting,
    originCities,
    destinationCities,
}) {
    const classes = useStyles()

    const {
        origin = {},
        destination = {},
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        mode,
        selectedLocation = {},
    } = subProps?.formValues

    const locationPickupAccessorials = (
        selectedLocation?.pickupAccessorials ?? []
    ).map(entry => entry.value) ?? ["DOCKPU"]

    const locationDeliveryAccessorials = (
        selectedLocation?.deliveryAccessorials ?? []
    ).map(entry => entry.value) ?? ["DOCKDEL"]

    const onShowAccessorialsChange = value => {
        if (!value) {
            if (mode === "outbound") {
                changeField("pickupAccessorials", ["DOCKPU"])
            } else if (mode === "inbound") {
                changeField("deliveryAccessorials", ["DOCKDEL"])
            }
        } else {
            if (mode === "outbound") {
                changeField("pickupAccessorials", locationPickupAccessorials)
            } else if (mode === "inbound") {
                changeField(
                    "deliveryAccessorials",
                    locationDeliveryAccessorials
                )
            }
        }
    }

    return (
        <Grid item container className={classes.basicInfo}>
            <Grid item container className={classes.title}>
                {mode !== "thirdParty" && (
                    <Typography variant="subheading">
                        {mode === "outbound" ? (
                            <FormattedMessage
                                id="bookShipment.basicInfo__origin"
                                defaultMessage="Origin Information"
                            />
                        ) : (
                            <FormattedMessage
                                id="bookShipment.basicInfo__destination"
                                defaultMessage="Destination Information"
                            />
                        )}
                    </Typography>
                )}
            </Grid>
            <Grid item container xs={6} alignItems="flex-end">
                <Field
                    id="billToLocation"
                    component={FormSelectBillTo}
                    name="billToLocation"
                    label={
                        <FormattedMessage
                            id="getRates.form__billToLocation"
                            defaultMessage="Bill to Location"
                        />
                    }
                    onChange={onBillToLocationChange}
                    options={formattedLocations}
                    billTo
                    InputProps={inputProps}
                />
            </Grid>

            <Grid item container xs={6}>
                {!isFreightDirectReturns && (
                    <Grid item container className={classes.section}>
                        {selectedLocation?.isShipperEnabled && (
                            <Grid item container justify="flex-end">
                                <Field
                                    name="mode"
                                    component={FormRadio}
                                    options={
                                        selectedLocation?.is3rdPartyEnabled
                                            ? modeOptions.shipperAndThirdParty
                                            : modeOptions.shipperOnly
                                    }
                                    onChange={(e, newMode) =>
                                        onDirectionChange(
                                            selectedLocation,
                                            newMode
                                        )
                                    }
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
            <Grid item container className={classes.locationLabel}>
                <LocationLabel
                    isFreightBox={isFreightBox}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                />
            </Grid>
            <Grid item container className={classes.section}>
                <Grid item container xs={6} alignItems="flex-end">
                    <Field
                        component={FormDatePicker}
                        name="pickupDate"
                        label={[
                            "* ",
                            <FormattedMessage
                                id="getRates.form__shipDate"
                                defaultMessage="Ship Date"
                            />,
                        ]}
                        id="getRates__pickupDate"
                        placeholder={intl.formatMessage({
                            id: "getRates.form__date",
                            defaultMessage: "Date",
                        })}
                        InputProps={inputProps}
                    />
                </Grid>

                {selectedLocation?.isShipperEnabled &&
                    !isFreightDirect &&
                    !isFreightDirectReturns &&
                    mode !== "thirdParty" && (
                        <Grid
                            item
                            container
                            alignItems="flex-end"
                            justify="flex-end"
                            xs={6}
                        >
                            <Field
                                name={
                                    mode === "outbound"
                                        ? `origin.showAccessorials`
                                        : `destination.showAccessorials`
                                }
                                component={FormSwitch}
                                label={
                                    mode === "outbound" ? (
                                        <FormattedMessage
                                            id="bookShipment.basicInfo__addPickupAccessorials"
                                            defaultMessage="Add Pickup Accessorials"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="bookShipment.basicInfo__addDeliveryAccessorials"
                                            defaultMessage="Add Delivery Accessorials"
                                        />
                                    )
                                }
                                onChange={(e, value) => {
                                    onShowAccessorialsChange(value)
                                }}
                            />
                        </Grid>
                    )}
            </Grid>

            <Grid item container>
                {selectedLocation?.isShipperEnabled &&
                    !isFreightDirect &&
                    !isFreightDirectReturns &&
                    mode !== "thirdParty" &&
                    ((mode === "outbound" && origin.showAccessorials) ||
                        (mode === "inbound" &&
                            destination.showAccessorials)) && (
                        <Grid
                            item
                            container
                            alignContent="flex-start"
                            className={classes.section}
                        >
                            <Accessorials
                                list={
                                    mode === "outbound"
                                        ? pickupAccessorialsList
                                        : deliveryAccessorialsList ?? []
                                }
                                name={
                                    mode === "outbound"
                                        ? "pickupAccessorials"
                                        : "deliveryAccessorials"
                                }
                                quickRate
                                formName="quickRate"
                                formValues={subProps?.formValues}
                            />
                        </Grid>
                    )}
            </Grid>

            <Grid item container className={classes.bigSection}>
                {(mode === "inbound" || mode === "thirdParty") && (
                    <Grid item container>
                        <Origin
                            {...{
                                ...subProps,
                                pickupAccessorialsList,
                                cities: originCities,
                            }}
                        />
                    </Grid>
                )}

                {(mode === "outbound" || mode === "thirdParty") && (
                    <Grid
                        item
                        container
                        className={
                            mode === "thirdParty" ? classes.bigSection : ""
                        }
                    >
                        <Destination
                            {...{
                                ...subProps,
                                deliveryAccessorialsList,
                                cities: destinationCities,
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid item container className={classes.bigSection}>
                <Items
                    {...{
                        ...subProps,
                        handleHUPanel,
                        isHUOpen,
                        itemsIsLoaded,
                        favoriteItems,
                        doSearch,
                        addNewHandlingUnit,
                        trackGA,
                    }}
                />
            </Grid>

            <Grid item container justify="center" className={classes.getRates}>
                {editMode && (
                    <Button
                        id="quickCancelEdit"
                        color="secondary"
                        variant="outlined"
                        onClick={() => handleCancel()}
                        type="submit"
                        className={classes.cancelEdit}
                    >
                        <FormattedMessage
                            id="generalTerms__back"
                            defaultMessage="Back"
                        />
                    </Button>
                )}
                <Button
                    id="quickRateGetRates"
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                        if (!invalid) {
                            getRates()
                        }
                    }}
                    type="submit"
                    disabled={submitting}
                >
                    <FormattedMessage
                        id="bookShipment__getRates"
                        defaultMessage="Get Rates"
                    />
                </Button>
            </Grid>
        </Grid>
    )
}
