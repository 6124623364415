import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import GlobalSpinner from "../../util/spinner"
import { FormattedMessage } from "react-intl"
import { withStyles } from "@material-ui/core/styles"

const styles = theme => ({
    loading__container: {
        width: "200px",
        height: "100px",
    },
})

export const UploadProcessingDialog = withStyles(styles)(({ classes }) => (
    <Fragment>
        <DialogTitle id="alert-dialog-title">
            <Grid item container>
                <FormattedMessage
                    id="documents.processing__title"
                    defaultMessage="Processing"
                />
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid
                item
                container
                alignItems="center"
                alignContent="center"
                className={classes.loading__container}
            >
                <Grid item xs={12}>
                    <GlobalSpinner />
                </Grid>
            </Grid>
        </DialogContent>
    </Fragment>
))
