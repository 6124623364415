import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    SnackbarContent,
    Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import React from "react"
import { FormattedMessage } from "react-intl"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { amber } from "@material-ui/core/colors"
import WarningIcon from "@material-ui/icons/Warning"

const useStyles = makeStyles({
    errorSnackbar: {
        backgroundColor: amber[700],
        maxWidth: "100%",
    },
    expansionPanel: {
        width: "100%",
    },
    warningIcon: {},
})

export default function RatesError({ ratesError }) {
    const classes = useStyles()
    return (
        <Grid item container>
            <Grid item xs={12}>
                <ExpansionPanel className={classes.expansionPanel}>
                    <ExpansionPanelSummary
                        expandIcon={
                            <ExpandMoreIcon id="expandMoreIconErrorRates" />
                        }
                    >
                        <Grid item container xs={1} alignItems="center">
                            <WarningIcon className={classes.warningIcon} />
                        </Grid>
                        <Grid item container xs={11} alignItems="center">
                            <Typography id="carrierRatesErrorMessage">
                                <FormattedMessage
                                    id="rateResults__carrierRatesError"
                                    defaultMessage="At least one carrier is having trouble retrieving rates.  View more information"
                                />
                            </Typography>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item container spacing={16}>
                            {ratesError.map((rateError, i) => {
                                const errorCarrier =
                                    rateError?.carrierCode ?? ""
                                return (rateError?.errorMessages ?? []).map(
                                    (error, j) => {
                                        const errorDiagnostic =
                                            error?.diagnostic
                                        const errorMessage = error?.message
                                        const source =
                                            error?.source === "SYSTEM"
                                                ? "Project 44"
                                                : "Carrier Error"
                                        const errorText = errorDiagnostic
                                            ? `${errorCarrier}: ${errorMessage} (${source}) - ${errorDiagnostic}`
                                            : `${errorCarrier}: ${errorMessage} (${source})`
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                key={`${i}_${j}`}
                                            >
                                                <SnackbarContent
                                                    className={
                                                        classes.errorSnackbar
                                                    }
                                                    message={
                                                        <Typography
                                                            id={`rateError${i}_errorMessages${j}`}
                                                        >
                                                            {errorText}
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                        )
                                    }
                                )
                            })}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </Grid>
        </Grid>
    )
}
