import { escapeString } from "../../../misc"

export const prefixMatch = (term, text) =>
    text &&
    text
        .toString()
        .toLowerCase()
        .startsWith(term)

/* eslint-disable camelcase */
export const filterShipments = (term, shipments) => {
    const lowTerm = term.toString().toLowerCase()
    if (!lowTerm || lowTerm.length < 3) return []
    return Object.keys(shipments)
        .map(key => shipments[key])
        .filter(item => {
            const { referenceNumber, ...generated } = item.identifiers
            const referenceInteger = /^[\d-_.]*$/gi.test(
                (referenceNumber || "").toString()
            )
            const match =
                Object.keys(generated).some(key =>
                    prefixMatch(lowTerm, generated[key])
                ) ||
                (referenceInteger && prefixMatch(lowTerm, referenceNumber))

            if (match) return match

            const searchTerm = new RegExp(
                escapeString(lowTerm.toString()),
                "gi"
            )
            const addressFilter = [referenceInteger ? "" : referenceNumber]
            if (item.shipment) {
                ;[item.shipment.origin, item.shipment.destination].forEach(
                    record => {
                        const {
                            name = "",
                            address: { postalCode = "" } = {},
                        } = record || {}
                        addressFilter.push(name, postalCode)
                    }
                )
            } else {
                addressFilter.push(
                    item.search.originAddress && item.search.originAddress.name,
                    item.search.origin,
                    item.search.destinationAddress &&
                        item.search.destinationAddress.name,
                    item.search.destination
                )
            }

            return addressFilter.some(text => searchTerm.test(text))
        })
}
/* eslint-enable camelcase */

export const filterAddresses = (term, addresses) => {
    if (!term || term.length < 3) return []
    const regex = new RegExp(escapeString(term.toString()), "i")
    return addresses
        .filter(
            item =>
                regex.test(item.name) ||
                regex.test(item.contact.name) ||
                item.address.postalCode.startsWith(term)
        )
        .sort((a, b) => {
            const { count: aCount = 0 } = a.contact.metadata || {}
            const { count: bCount = 0 } = b.contact.metadata || {}
            return bCount - aCount || a.contact.name > b.contact.name
        })
}

export const filterContacts = (
    term,
    addresses,
    {
        sort = true,
        contactName = true,
        companyName = false,
        filter = false,
        postalCode,
        withMobile = false,
    } = {}
) => {
    let retVal = filter ? addresses : []
    if (term && term.length >= 3) {
        const regex = new RegExp(escapeString(term.toString()), "i")
        const preFiltered = postalCode
            ? addresses.filter(
                  item => item.address.postalCode === postalCode.toString()
              )
            : addresses
        retVal = preFiltered.filter(
            item =>
                (contactName && regex.test(item.contact.name)) ||
                (companyName && regex.test(item.name))
        )
        if (withMobile) {
            retVal = retVal.filter(item => item?.contact?.phone?.mobile)
        }
    }
    return sort
        ? retVal.sort((a, b) =>
              a.name === b.name
                  ? a.contact.name.localeCompare(b.contact.name)
                  : a.name.localeCompare(b.name)
          )
        : retVal
}

export const filterSearchItems = (term, items, sort) => {
    if (!term || term.length === 0 || !items) return items
    const regex = new RegExp(escapeString(term.toString()), "i")
    const { origin, originAddress } = sort || {}
    const result = items.filter(
        ({
            description,
            freightClass,
            weight,
            nmfc1,
            nmfc2,
            unNumber,
            hazClass,
            pkgGrp,
        }) =>
            [
                description,
                freightClass,
                weight,
                `${nmfc1}-${nmfc2}`,
                unNumber,
                hazClass,
                pkgGrp,
            ].some(value => regex.test(value))
    )
    if ((!origin || origin.length !== 5) && !originAddress) return result
    return result.sort(
        ({ metadata: a = {} } = {}, { metadata: b = {} } = {}) => {
            const {
                [originAddress]: aAddress = 0,
                [origin]: aZipCode = 0,
                total: aTotal = 0,
            } = a
            const {
                [originAddress]: bAddress = 0,
                [origin]: bZipCode = 0,
                total: bTotal = 0,
            } = b
            return (
                (originAddress && bAddress - aAddress) ||
                bZipCode - aZipCode ||
                bTotal - aTotal
            )
        }
    )
}

export const filterEmailContacts = (term, field, items) => {
    let retVal = []
    if (!term || term.length < 3) return retVal
    const regex = new RegExp(escapeString(term.toString()), "i")
    if (field === "name") {
        retVal = items.filter(address => regex.test(address.contact.name))
    }
    if (field === "email") {
        retVal = items.filter(address =>
            regex.test(address.contact.email.emailAddress)
        )
    }
    return retVal
}
