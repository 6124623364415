import { Grid, Paper, Typography, withStyles } from "@material-ui/core"
import React from "react"

const styles = theme => ({
    tileContents: {
        padding: "5px",
    },
    paper: {
        width: "100%",
        display: "flex",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
            color: "white",
        },
        "&:active": {
            backgroundColor: theme.palette.secondary.dark,
            color: "white",
        },
        minHeight: "50px",
    },
    active: {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
    },
})

const Tile = ({ value, count, label, icon, active, classes, activateTile }) => {
    return (
        <Grid
            item
            container
            onClick={() => {
                if (active) {
                    activateTile(null)
                } else {
                    activateTile(value)
                }
            }}
        >
            <Paper className={`${classes.paper} ${active && classes.active}`}>
                <Grid item container className={classes.tileContents}>
                    <Grid
                        item
                        container
                        xs={8}
                        direction="column"
                        alignItems="center"
                        justify="center"
                    >
                        <Grid item container>
                            <Typography variant="subheading" color="inherit">
                                {count}
                            </Typography>
                        </Grid>
                        <Grid item container alignItems="center">
                            <Typography color="inherit">{label}</Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        justify="flex-end"
                        alignItems="center"
                    >
                        {icon}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(Tile)
