import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

const styles = theme => ({
    mainContainer: {},
    header: {
        backgroundColor: theme.palette.primary.main,
        padding: "15px",
    },
    textContainer: {
        padding: "35px",
    },
})

class NoAccessPageDisplay extends Component {
    render() {
        const { classes } = this.props

        return (
            <Grid container className={classes.mainContainer}>
                <Grid item container className={classes.header}>
                    <img
                        alt="LTLselect"
                        src="static/LTLselect-logo-type-new.png"
                    />
                </Grid>
                <Grid
                    item
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.textContainer}
                >
                    <Typography variant="subheading">
                        LTL Select will soon be available in Quebec / LTL Select
                        sera bientôt disponible au Québec.
                    </Typography>
                </Grid>
            </Grid>
        )
    }
}

export const NoAccessPage = withStyles(styles)(NoAccessPageDisplay)
