import React, { Fragment } from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import CropSquare from "@material-ui/icons/CropSquare"
import Avatar from "@material-ui/core/Avatar"
import InfoIcon from "@material-ui/icons/Info"
import Tooltip from "@material-ui/core/Tooltip"
import FormField from "../form/form-field"
import { Field } from "redux-form"
import { countriesOfOrigin, provideLocaleSortedOptionsList } from "../../misc"
import FormSelectAutocomplete from "../form/form-select-autocomplete"
import {
    hazMatPkgGrp,
    packageTypesMessages,
    provideHazMatClasses,
    provideHazMatContainers,
} from "../item/line-item"
import FormSelect from "../form/form-select"
import { FormattedMessage, injectIntl } from "react-intl"
import { lengthUnit, weightUnit } from "../util/units"

const styles = theme => ({
    description: {},
    section__title: {
        textDecoration: "underline",
    },
    card: {
        width: "100%",
        overflow: "visible",
    },
    topTableRow: {
        borderTop: "solid 1px #D4D4D4",
    },
    index: {
        width: "4%",
    },
    infoIcon: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main,
        },
    },
    huContainer: {
        borderBottom: "solid 1px #D4D4D4",
        padding: "15px 0px",
    },
    spacedGrid: {
        padding: "5px 0",
    },
    formText: {
        paddingTop: "15px",
    },
    itemContainer: {
        borderBottom: "dashed 1px #D4D4D4",
        padding: "10px 0px 20px 0px",
        "&:last-child": {
            border: 0,
        },
    },
    aesContent: {
        "margin-left": "60px",
        "padding-top": "0px",
    },
})

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "black",
        maxWidth: 244,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}))(Tooltip)

class HandlingUnitsForm extends React.Component {
    showInfo = hu => {}

    render() {
        const {
            preferredCurrencyCode,
            preferredSystemOfMeasurement,
            classes,
            handlingUnits = [],
            isStrict,
            intl,
            updateShouldAES,
            shouldShowAES,
            shouldShowTotalForCommercial,
            totalPrice,
        } = this.props

        return (
            <Grid item container xs={12}>
                <Card className={classes.card}>
                    <CardHeader
                        className={classes.header}
                        title={
                            <Typography variant="title">
                                <FormattedMessage
                                    id="book.handlingUnits__handlingUnits"
                                    defaultMessage="Handling Units"
                                />
                            </Typography>
                        }
                        avatar={
                            <Avatar>
                                <CropSquare />
                            </Avatar>
                        }
                    />
                    <CardContent className={classes.content}>
                        {handlingUnits.map((hu, index) => (
                            <Grid
                                item
                                container
                                className={classes.huContainer}
                            >
                                <Grid
                                    item
                                    container
                                    xs={1}
                                    alignItems="center"
                                    justify="center"
                                >
                                    <HtmlTooltip
                                        placement="top-start"
                                        title={
                                            <Fragment>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems="center"
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        justify="center"
                                                    >
                                                        <Typography
                                                            color="inherit"
                                                            variant="subheading"
                                                        >
                                                            <FormattedMessage
                                                                id="book.handlingUnits__huInformation"
                                                                defaultMessage="H/U Information"
                                                            />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                        justify="space-between"
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            color="inherit"
                                                        >
                                                            <FormattedMessage
                                                                id="items__packageType"
                                                                defaultMessage="Package Type"
                                                            />
                                                            :
                                                        </Typography>
                                                        <Typography
                                                            align="right"
                                                            color="inherit"
                                                        >
                                                            {packageTypesMessages[
                                                                hu.packageType
                                                            ] ? (
                                                                <FormattedMessage
                                                                    {...packageTypesMessages[
                                                                        hu
                                                                            .packageType
                                                                    ]}
                                                                />
                                                            ) : (
                                                                hu.packageType
                                                            )}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                        justify="space-between"
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            color="inherit"
                                                        >
                                                            <FormattedMessage
                                                                id="items__dims"
                                                                defaultMessage="Dims"
                                                            />
                                                            {`(${lengthUnit(
                                                                preferredSystemOfMeasurement
                                                            )}): `}
                                                        </Typography>
                                                        <Typography
                                                            align="right"
                                                            color="inherit"
                                                        >
                                                            {[
                                                                hu.length,
                                                                hu.width,
                                                                hu.height,
                                                            ].join("x")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                        justify="space-between"
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            color="inherit"
                                                        >
                                                            <FormattedMessage
                                                                id="items__individualHUWeight"
                                                                defaultMessage="Individual H/U Weight"
                                                            />
                                                            {`(${weightUnit(
                                                                preferredSystemOfMeasurement
                                                            )}): `}
                                                        </Typography>
                                                        <Typography
                                                            align="right"
                                                            color="inherit"
                                                        >
                                                            {
                                                                hu.handlingUnitWeight
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems="center"
                                                        justify="space-between"
                                                    >
                                                        <Typography
                                                            variant="caption"
                                                            color="inherit"
                                                        >
                                                            <FormattedMessage
                                                                id="items__count"
                                                                defaultMessage="Count"
                                                            />
                                                            :{" "}
                                                        </Typography>
                                                        <Typography
                                                            align="right"
                                                            color="inherit"
                                                        >
                                                            {hu.count}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Fragment>
                                        }
                                    >
                                        <InfoIcon
                                            color="primary"
                                            className={classes.infoIcon}
                                            onClick={this.showInfo(hu)}
                                        />
                                    </HtmlTooltip>
                                </Grid>
                                <Grid item container xs={11}>
                                    {hu.items.map((item, itemIndex) => {
                                        const prefix = `handlingUnits[${index}].items[${itemIndex}]`

                                        return (
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                className={
                                                    classes.itemContainer
                                                }
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    className={classes.formText}
                                                    xs={4}
                                                    alignItems="center"
                                                >
                                                    <Typography>
                                                        <FormattedMessage
                                                            id="items__descriptionClassWeight"
                                                            defaultMessage="{description}, Class {freightClass}, {weight}{measure}"
                                                            values={{
                                                                description:
                                                                    item.description,
                                                                freightClass:
                                                                    item.freightClass,
                                                                weight:
                                                                    item.weight,
                                                                measure: weightUnit(
                                                                    preferredSystemOfMeasurement
                                                                ),
                                                            }}
                                                        />
                                                    </Typography>
                                                </Grid>
                                                <Grid item container xs={4}>
                                                    <Field
                                                        component={
                                                            FormSelectAutocomplete
                                                        }
                                                        name={`${prefix}.countryOfManufacture`}
                                                        label={[
                                                            isStrict
                                                                ? "* "
                                                                : "",
                                                            <FormattedMessage
                                                                id="items__countryOfManufacture"
                                                                defaultMessage="Country Of Manufacture"
                                                            />,
                                                        ]}
                                                        options={provideLocaleSortedOptionsList(
                                                            intl,
                                                            countriesOfOrigin,
                                                            ["US", "CA", "MX"]
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item container xs={2}>
                                                    <Field
                                                        type="number"
                                                        component={FormField}
                                                        name={`${prefix}.unitPrice`}
                                                        label={[
                                                            isStrict
                                                                ? "* "
                                                                : "",
                                                            <FormattedMessage
                                                                id="items__unitPrice"
                                                                defaultMessage="Unit Price ({preferredCurrencyCode})"
                                                                values={{
                                                                    preferredCurrencyCode,
                                                                }}
                                                            />,
                                                        ]}
                                                        onBlur={updateShouldAES}
                                                    />
                                                </Grid>
                                                <Grid item container xs={2}>
                                                    <Field
                                                        component={FormField}
                                                        name={`${prefix}.harmonizedCode`}
                                                        label={
                                                            <FormattedMessage
                                                                id="items__hsCode"
                                                                defaultMessage="HS Code"
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item container xs={4}>
                                                    <Grid
                                                        item
                                                        container
                                                        xs={6}
                                                        alignItems="center"
                                                    >
                                                        <Field
                                                            name={`${prefix}.nmfcCode`}
                                                            label={
                                                                <FormattedMessage
                                                                    id="items__nmfc"
                                                                    defaultMessage="NMFC"
                                                                />
                                                            }
                                                            placeholder="xxxxxx"
                                                            component={
                                                                FormField
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item container xs={6}>
                                                        <Field
                                                            name={`${prefix}.nmfcSubclass`}
                                                            label={
                                                                <FormattedMessage
                                                                    id="items__subclass"
                                                                    defaultMessage="Subclass"
                                                                />
                                                            }
                                                            placeholder="xx"
                                                            component={
                                                                FormField
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {item.isHazMat && (
                                                    <Fragment>
                                                        <Grid
                                                            item
                                                            container
                                                            xs={1}
                                                            alignItems="center"
                                                            justify="center"
                                                            className={
                                                                classes.formText
                                                            }
                                                        >
                                                            <Typography
                                                                variant="caption"
                                                                align="right"
                                                                color="secondary"
                                                            >
                                                                HazMat
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                name={`${prefix}.hazMatUnNumber`}
                                                                label={[
                                                                    "* ",
                                                                    <FormattedMessage
                                                                        id="items__unNumber"
                                                                        defaultMessage="ID Numbers"
                                                                    />,
                                                                ]}
                                                                placeholder="xxxx"
                                                                component={
                                                                    FormField
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <Field
                                                                component={
                                                                    FormSelect
                                                                }
                                                                name={`${prefix}.hazMatPkgGrp`}
                                                                label={[
                                                                    "* ",
                                                                    <FormattedMessage
                                                                        id="items__packageGroup"
                                                                        defaultMessage="Package Group"
                                                                    />,
                                                                ]}
                                                                options={
                                                                    hazMatPkgGrp
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <Field
                                                                component={
                                                                    FormSelect
                                                                }
                                                                name={`${prefix}.hazMatClass`}
                                                                label={[
                                                                    "* ",
                                                                    <FormattedMessage
                                                                        id="items__hazClass"
                                                                        defaultMessage="Haz Class"
                                                                    />,
                                                                ]}
                                                                options={provideHazMatClasses(
                                                                    intl
                                                                )}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={2}>
                                                            <Field
                                                                component={
                                                                    FormSelect
                                                                }
                                                                name={`${prefix}.hazMatContainer`}
                                                                label={[
                                                                    "* ",
                                                                    <FormattedMessage
                                                                        id="items__containerType"
                                                                        defaultMessage="Container Type"
                                                                    />,
                                                                ]}
                                                                options={provideHazMatContainers(
                                                                    intl
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Fragment>
                                                )}
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        ))}
                        {shouldShowTotalForCommercial && (
                            <Grid
                                item
                                container
                                xs={12}
                                alignContent="center"
                                justify="flex-end"
                                className={classes.huContainer}
                            >
                                <Grid item xs={2}>
                                    <Typography variant="subheading">
                                        <FormattedMessage
                                            id="items__total"
                                            defaultMessage="Total Price ({preferredCurrencyCode}) :  {totalPrice}"
                                            values={{
                                                preferredCurrencyCode,
                                                totalPrice,
                                            }}
                                        />
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </CardContent>
                    {shouldShowAES && (
                        <Grid>
                            <CardHeader
                                className={classes.header}
                                title={
                                    <Typography variant="title">
                                        <FormattedMessage
                                            id="orderDetails.header__AES"
                                            defaultMessage="AES Citation"
                                        />
                                    </Typography>
                                }
                            ></CardHeader>
                            <CardContent className={classes.aesContent}>
                                <Grid item container xs={4}>
                                    <Field
                                        name="identifiers.aesCitationNumber"
                                        label={
                                            <FormattedMessage
                                                id="orderDetails.label__AES"
                                                defaultMessage="AES Citation Number"
                                            />
                                        }
                                        placeholder={intl.formatMessage({
                                            id: "orderDetails.placeholder__AES",
                                            defaultMessage:
                                                "Enter AES Citation Number",
                                        })}
                                        component={FormField}
                                    />
                                </Grid>
                            </CardContent>
                        </Grid>
                    )}
                </Card>
            </Grid>
        )
    }
}

export default withStyles(styles)(injectIntl(HandlingUnitsForm))
