import { quoteInvalidate } from "./quote"
import { trackGAEvent } from "./user"

export const ADD_ACCESSORIAL = "ADD_ACCESSORIAL"
export const REMOVE_ACCESSORIAL = "REMOVE_ACCESSORIAL"

function trackAccessorialGAEvent(dispatch, field, value, cpg) {
    if (field === "commodityAccessorials" && value === "PFZ") {
        dispatch(trackGAEvent("Create Quote", "Protect from Freeze", cpg))
    } else if (
        field === "pickupAccessorials" ||
        field === "deliveryAccessorials"
    ) {
        dispatch(
            trackGAEvent(
                "Create Quote",
                "Optional and additional services",
                cpg
            )
        )
    }
}

const changeAccessorial = (type, field, value) => ({
    type,
    field,
    value: { value },
})

export function addAccessorial(field, value) {
    return (dispatch, getState) => {
        const realValue = value.value
        const { cpg } = getState().search
        trackAccessorialGAEvent(dispatch, field, realValue, cpg)

        dispatch(quoteInvalidate())
        if (realValue === "DOCKPU") {
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "LGPU"))
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "RESPU"))
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "LTDPU"))
        } else if (realValue === "DOCKDEL") {
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "LGDEL"))
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "RESDEL"))
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "LTDDEL"))
        } else if (["LGPU", "RESPU", "LTDPU"].includes(realValue)) {
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "DOCKPU"))
        } else if (["LGDEL", "RESDEL", "LTDDEL"].includes(realValue)) {
            dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, "DOCKDEL"))
        }
        dispatch(changeAccessorial(ADD_ACCESSORIAL, field, realValue))
    }
}

export function removeAccessorial(field, { value }) {
    return (dispatch, getState) => {
        const { cpg } = getState().search
        trackAccessorialGAEvent(dispatch, field, value, cpg)
        dispatch(quoteInvalidate())
        dispatch(changeAccessorial(REMOVE_ACCESSORIAL, field, value))
    }
}

export function getAccessorialText(item, list) {
    return item.label || (list.find(it => it.value === item.value) || {}).label
}
