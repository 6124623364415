import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Grid, Button, withStyles, Typography } from "@material-ui/core"
import Spinner from "../../util/spinner"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import FileUpload from "../../util/file-upload"
import { FormattedMessage } from "react-intl"
import { importDiscounts } from "../../../actions/freightdirect-discount"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

const styles = theme => ({
    button__container: {
        marginBottom: "20px",
    },
    button__close: {
        marginLeft: 0,
        padding: "1em",
    },
    content: {
        width: "50vw",
        maxHeight: "80vh",
        padding: "30px",
        borderRadius: "6px",
        backgroundColor: "#fff",
    },
    errorMessage: {
        marginTop: "16px",
    },
    root: {
        width: "100%",
        maxHeight: "200px",
        marginTop: theme.spacing.unit * 3,
        overflowX: "scroll",
    },
})

class UploadDiscountModal extends Component {
    state = {
        importing: false,
        result: null,
        sbMessages: [],
    }

    submitData = async file => {
        try {
            const { data: result } = await this.props.onDiscountImport(file)
            if (result.errorMessages && result.errorMessages.length) {
                this.setState({
                    sbMessages: result.errorMessages,
                })
            } else {
                this.props.onCloseClick()
                this.props.requestDiscounts()
            }
            this.setState({ result })
        } catch (err) {
            this.setState({
                sbMessages: [err],
            })
        }
    }

    handleImport = async file => {
        this.setState({
            importing: true,
            result: null,
            sbMessages: [],
        })
        try {
            await this.submitData(file)
        } catch (err) {
            this.setState({
                sbMessages: [err.message],
            })
        } finally {
            this.setState({ importing: false })
        }
    }

    render() {
        const { onCloseClick, classes } = this.props
        const { importing, sbMessages } = this.state

        return (
            <Grid container className={classes.content}>
                <Grid item container xs={10}>
                    <Typography variant="body1" className={classes.summary}>
                        <FormattedMessage
                            id="discounts.upload__instructions"
                            defaultMessage="For an example of how to correctly submit a new freight direct discount file, please look at the sample file below by clicking download sample. Your upload file needs to be in the .xlsx format and use the same column headers. Also, please be noted, this upload will wipe all the old discounts and use this new uploaded file."
                        />
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    className={classes.button__container}
                    xs={2}
                    justify="flex-end"
                >
                    <IconButton
                        className={classes.button__close}
                        onClick={() => onCloseClick(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                            window.open(
                                "/static/import_freight_direct_discounts_sample.xlsx",
                                "_blank"
                            )
                        }
                    >
                        <FormattedMessage
                            id="discounts.import__downloadSample"
                            defaultMessage="Download sample"
                        />
                    </Button>
                    {importing && <Spinner />}
                    <FileUpload
                        labelText="Upload file"
                        onSubmit={file => this.handleImport(file)}
                        accept=".xlsx"
                        allowSameFile
                    />
                </Grid>
                {sbMessages.length > 0 && (
                    <Grid item container>
                        <Typography
                            variant="subtitle1"
                            id="ErrorTitle"
                            color="error"
                            className={classes.errorMessage}
                        >
                            Failure Rows:
                        </Typography>
                        <Paper className={classes.root}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Row
                                        </TableCell>
                                        <TableCell align="center">
                                            Cell
                                        </TableCell>
                                        <TableCell align="center">
                                            Error
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sbMessages.map(row => (
                                        <TableRow>
                                            <TableCell align="center">
                                                {row.row}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.cell}
                                            </TableCell>
                                            <TableCell align="center">
                                                {row.message}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        )
    }
}

UploadDiscountModal.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    classes: PropTypes.object,
    requestDiscounts: PropTypes.func.isRequired,
}

UploadDiscountModal.defaultProps = {
    classes: {},
}

const mapDispatchToProps = dispatch => ({
    onDiscountImport: async file => dispatch(importDiscounts(file)),
})

export default withStyles(styles)(
    connect(undefined, mapDispatchToProps)(UploadDiscountModal)
)
