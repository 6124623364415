import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { CarrierLogo } from "../../util"
import RateBreakdown from "../../dashboard/components/rateBreakdown"
import MiniAttachments from "./attachments/miniAttachments"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
    },
    carrier_logo: {
        height: "90px",
        "& $img": {
            height: "60px",
        },
    },

    payment_detail: {
        width: "100%",
    },
    push_right: {
        float: "right",
    },
    section__title: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    section: {
        padding: "10px",
    },
    documents__container: {
        paddingTop: "10px",
        borderTop: "dotted 1px #D4D4D4",
    },
    identifiers__container: {
        paddingBottom: "10px",
    },
    header__serviceLevelText: {
        paddingTop: "20px",
    },
})

const HeaderCard = ({
    identifiers,
    pickupDate,
    deliveryDate,
    isShowDeliveryDate,
    carrierCode,
    status,
    shipment,
    classes,
    canViewRateAmounts,
    onViewQuote,
    shipmentId,
}) => {
    const serviceLevel = get(shipment, "rate.serviceLevel.description")
    const mode = get(shipment, "rate.mode")
    const isVendorAccount = shipment?.rate?.accountType === "VENDOR"
    const isInBondShipment = shipment?.isInBondShipment

    return (
        <Grid xs={12} item>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Grid container>
                        <Grid
                            xs={2}
                            item
                            container
                            alignItems="flex-start"
                            className={classes.section}
                            alignContent="flex-start"
                        >
                            <Grid item container>
                                <CarrierLogo
                                    carrierCode={
                                        get(shipment, "rate.isDomesticOffshore")
                                            ? "domestic_offshore"
                                            : carrierCode
                                    }
                                    className={classes.carrier_logo}
                                    mode={mode}
                                    serviceLevel={serviceLevel}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            xs={6}
                            item
                            container
                            alignContent="flex-start"
                            className={classes.section}
                        >
                            <Grid
                                item
                                container
                                className={classes.identifiers__container}
                            >
                                <Grid item container>
                                    <Typography
                                        variant="subheading"
                                        className={classes.section__title}
                                    >
                                        <FormattedMessage
                                            id="track__identifiers"
                                            defaultMessage="Identifiers"
                                        />
                                    </Typography>
                                </Grid>
                                {identifiers.proNumber && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__trackingId"
                                                defaultMessage="Tracking ID"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(identifiers, "proNumber")}
                                        </Typography>
                                    </Grid>
                                )}
                                {identifiers.bolNumber && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__BOL"
                                                defaultMessage="BOL"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(identifiers, "bolNumber")}
                                        </Typography>
                                    </Grid>
                                )}
                                {identifiers.pickupNumber && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__PU"
                                                defaultMessage="PU"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(identifiers, "pickupNumber")}
                                        </Typography>
                                    </Grid>
                                )}
                                {get(
                                    shipment,
                                    "rate.capacityProviderQuoteNumber"
                                ) && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__quoteId"
                                                defaultMessage="Quote ID"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(
                                                shipment,
                                                "rate.capacityProviderQuoteNumber"
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                                {identifiers.orderNumber && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__PO"
                                                defaultMessage="PO"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(identifiers, "orderNumber")}
                                        </Typography>
                                    </Grid>
                                )}
                                {identifiers.referenceNumber && (
                                    <Grid
                                        item
                                        container
                                        justify="space-between"
                                    >
                                        <Typography variant="subheading">
                                            <FormattedMessage
                                                id="generalTerms__referenceId"
                                                defaultMessage="Reference ID"
                                            />{" "}
                                            #:
                                        </Typography>
                                        <Typography>
                                            {get(
                                                identifiers,
                                                "referenceNumber"
                                            )}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.documents__container}
                            >
                                <MiniAttachments
                                    internalTrackingNumber={shipmentId}
                                />
                            </Grid>
                        </Grid>
                        {canViewRateAmounts &&
                            !isVendorAccount &&
                            !isInBondShipment && (
                                <Grid
                                    xs={4}
                                    item
                                    container
                                    className={classes.section}
                                    alignItems="flex-start"
                                    alignContent="flex-start"
                                >
                                    <Grid item container justify="flex-end">
                                        <Typography
                                            variant="subheading"
                                            className={classes.section__title}
                                        >
                                            <FormattedMessage
                                                id="orderDetails__rateBreakdown"
                                                defaultMessage="Rate Breakdown"
                                            />
                                        </Typography>
                                    </Grid>
                                    <RateBreakdown
                                        className={classes.payment_detail}
                                        charges={get(
                                            shipment,
                                            "rate.rateQuoteDetail.charges"
                                        )}
                                        totalInPreferredCurrency={
                                            get(
                                                shipment,
                                                "rate.rateQuoteDetail.totalInPreferredCurrency"
                                            )
                                                ? get(
                                                      shipment,
                                                      "rate.rateQuoteDetail.totalInPreferredCurrency"
                                                  )
                                                : get(
                                                      shipment,
                                                      "rate.rateQuoteDetail.total"
                                                  )
                                        }
                                        preferredCurrencyCode={get(
                                            shipment,
                                            "rate.preferredCurrencyCode"
                                        )}
                                    />
                                </Grid>
                            )}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}

HeaderCard.propTypes = {
    classes: PropTypes.object,
}

HeaderCard.defaultProps = {
    classes: {},
}

export default withStyles(styles)(HeaderCard)
