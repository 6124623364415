import React from "react"
import { FormattedMessage } from "react-intl"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import WarningIcon from "@material-ui/icons/Warning"
import StarIcon from "@material-ui/icons/Star"
import CancelIcon from "@material-ui/icons/Cancel"
import RightArrowIcon from "@material-ui/icons/KeyboardArrowRight"
import LeftArrowIcon from "@material-ui/icons/KeyboardArrowLeft"
import PersonIcon from "@material-ui/icons/Person"
import LocalShippingIcon from "@material-ui/icons/LocalShipping"

export const supportedTiles = [
    {
        value: "warning",
        label: (
            <FormattedMessage
                id="dashboard.tiles__warning"
                defaultMessage="Warning"
            />
        ),
        icon: <WarningIcon />,
    },
    {
        value: "starred",
        label: (
            <FormattedMessage
                id="dashboard.tiles__starred"
                defaultMessage="Starred"
            />
        ),
        icon: <StarIcon />,
    },
    {
        value: "pickedUp",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickedUp"
                defaultMessage="Picked Up"
            />
        ),
        icon: <ArrowUpwardIcon />,
    },
    {
        value: "pickingUpToday",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickingUpToday"
                defaultMessage="Picking Up Today"
            />
        ),
        icon: <ArrowUpwardIcon />,
    },
    {
        value: "pickingUpTomorrow",
        label: (
            <FormattedMessage
                id="dashboard.tiles__pickingUpTomorrow"
                defaultMessage="Picking Up Tomorrow"
            />
        ),
        icon: <ArrowUpwardIcon />,
    },
    {
        value: "inTransit",
        label: (
            <FormattedMessage
                id="dashboard.tiles__inTransit"
                defaultMessage="In Transit"
            />
        ),
        icon: <LocalShippingIcon />,
    },
    {
        value: "outForDelivery",
        label: (
            <FormattedMessage
                id="dashboard.tiles__outForDelivery"
                defaultMessage="Out For Delivery"
            />
        ),
        icon: <LocalShippingIcon />,
    },
    {
        value: "delivered",
        label: (
            <FormattedMessage
                id="dashboard.tiles__delivered"
                defaultMessage="Delivered"
            />
        ),
        icon: <CheckCircleIcon />,
    },
    {
        value: "deliveredToday",
        label: (
            <FormattedMessage
                id="dashboard.tiles__deliveredToday"
                defaultMessage="Delivered Today"
            />
        ),
        icon: <CheckCircleIcon />,
    },

    {
        value: "canceled",
        label: (
            <FormattedMessage
                id="dashboard.tiles__canceled"
                defaultMessage="Canceled"
            />
        ),
        icon: <CancelIcon />,
    },
    {
        value: "outbound",
        label: (
            <FormattedMessage
                id="dashboard.tiles__outbound"
                defaultMessage="Outbound"
            />
        ),
        icon: <RightArrowIcon />,
    },
    {
        value: "inbound",
        label: (
            <FormattedMessage
                id="dashboard.tiles__inbound"
                defaultMessage="Inbound"
            />
        ),
        icon: <LeftArrowIcon />,
    },
    {
        value: "thirdParty",
        label: (
            <FormattedMessage
                id="dashboard.tiles__thirdParty"
                defaultMessage="Third Party"
            />
        ),
        icon: <PersonIcon />,
    },
]
