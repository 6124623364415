import React from "react"
import { SubmissionError, change } from "redux-form"
import ReactGA from "react-ga"
import qs from "qs"
import moment from "moment"
import { STATUS, EVENTS } from "react-joyride"
import { get, capitalize, pick } from "lodash"
import { goFetch } from "../http"
import { requestShipments } from "./track"
import {
    errorMessage,
    requestAccessorials,
    changePath,
    endTimer,
} from "./index"
import { requestVagueCommodities } from "./vague-commodity"
import { authorizationFinished, getUserId } from "./authorization"
import { saveData } from "../misc"
import { getDefaultLocation } from "./quote"
import UserSignedupNotification from "../components/user/user-signedup-notification"
import { requestCarriers } from "./carrier"
import { openModal, closeModal } from "./modal"
import { attachPusher } from "./chat"
import { addLocationError } from "../messages/error-constants"

export const USER_RECEIVED = "USER_RECEIVED"
export const USER_DISAUTHENTICATED = "USER_DISAUTHENTICATED"
export const INIT_SIGNUP = "INIT_SIGNUP"
export const USER_UPDATE_START = "USER_UPDATE_START"
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR"
export const CONTACT_SELECT = "CONTACT_SELECT"
export const USER_PREFERENCE_ACCEPT_ADDRESS = "USER_PREFERENCE_ACCEPT_ADDRESS"
export const USER_CARRIER_RESULT = "USER_CARRIER_RESULT"
export const USER_TOGGLE_GROUP_BY_CARRIER = "TOGGLE_GROUP_BY_CARRIER"
export const SET_CPG = "SET_CPG"
export const USER_NEW_LOCATION_ACCEPT_ADDRESS =
    "USER_NEW_LOCATION_ACCEPT_ADDRESS"
export const SET_ACTIVE_LOCATION = "SET_ACTIVE_LOCATION"

export const userUpdateOptions = data => ({
    method: "PUT",
    credentials: "same-origin",
    headers: { "cache-control": "no-cache" },
    data,
})

const notifySuccess = (cpg = {}) => (dispatch, getState) => {
    dispatch(
        trackGAEvent("Profile", "Notify Change", cpg.cpgCode || cpg.cpgName)
    )
}

export function userReceived(user) {
    return dispatch => {
        saveData("user", user, true)
        dispatch({ type: USER_RECEIVED, user })
        return user
    }
}

export function setActiveLocation(index) {
    return { type: SET_ACTIVE_LOCATION, index }
}

function locationReceived(location) {
    return (dispatch, getState) => {
        const user = getState().user.profile
        const oldLocations = get(user, "locations", [])
        const locationIndex = oldLocations.findIndex(
            item => item._id === location._id
        )
        const locations =
            locationIndex >= 0
                ? [
                      ...oldLocations.slice(0, locationIndex),
                      location,
                      ...oldLocations.slice(locationIndex + 1),
                  ]
                : [...oldLocations, location]
        return dispatch(userReceived({ ...user, locations }))
    }
}

export function addUsersToLocation({
    permissions = {},
    role,
    selectedRecipients,
    locationId,
    language,
}) {
    return async dispatch => {
        try {
            const formattedPermissions = {}
            Object.keys(permissions).forEach(key => {
                formattedPermissions[key] = permissions[key].value
            })
            const formattedPayload = {
                users: selectedRecipients.map(
                    recipient => recipient.value.value
                ),
                permissions: formattedPermissions,
                role,
                language,
            }
            const { data: resp } = await goFetch(
                `/locations/${locationId}/users`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: formattedPayload,
                },
                true
            )
            const user = await retrieveUser()
            dispatch(userReceived(user))
            return resp
        } catch (error) {
            dispatch(errorMessage(error))
        }
    }
}

export function updateUserOnLocation({
    permissions = {},
    role,
    isPrimary,
    selectedRecipients,
    locationId,
    user,
}) {
    return async dispatch => {
        try {
            const formattedPermissions = {}
            Object.keys(permissions).forEach(key => {
                formattedPermissions[key] = permissions[key].value
            })
            const formattedPayload = {
                permissions: formattedPermissions,
                role,
                isPrimary,
            }
            const { data: resp } = await goFetch(
                `/locations/${locationId}/users/${user.user.id}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: formattedPayload,
                },
                true
            )
            const retrievedUser = await retrieveUser()
            dispatch(userReceived(retrievedUser))
            return resp
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}

export function basicUpdateUser() {
    return async dispatch => {
        try {
            const retrievedUser = await retrieveUser()
            dispatch(userReceived(retrievedUser))
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}

export function updateCustomLocationPreferences({
    cpgName,
    readyTime,
    closeTime,
    _id: locationId,
    users,
}) {
    return async dispatch => {
        const userId = get(users, "[0].user.id", "")
        try {
            const { data: resp } = await goFetch(
                `/locations/${locationId}/users/${userId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        cpgName,
                        readyTime,
                        closeTime,
                    },
                },
                true
            )
            const retrievedUser = await retrieveUser()
            dispatch(userReceived(retrievedUser))
            return resp
        } catch (error) {
            dispatch(errorMessage(error))
            throw error
        }
    }
}

export const contactSelected = contact => ({ type: CONTACT_SELECT, ...contact })

const setCpg = (cpgCode, quoteCpgCode) => ({
    type: SET_CPG,
    cpgCode,
    quoteCpgCode,
})

function setDefaultCpg() {
    return (dispatch, getState) => {
        const user = getState().user.preferences
        if (!user || !user.locations || !user.locations.length) return
        const record =
            user.locations.find(item => item.isDefault) || user.locations[0]
        dispatch(setCpg(record.cpgCode, record.quoteCpgCode))
    }
}

function redirectToRateScreenIfNeeded() {
    return async (dispatch, getState) => {
        const shareKey = getState().shareStatus.shareKey
        if (shareKey) return
        dispatch(changePath("/home"))
        const userLocations = get(getState(), "user.profile.locations", [])
        const canRate = userLocations.some(loc =>
            get("permissions.createRates.value")
        )
        if (!canRate) return
        const shipments = await dispatch(requestShipments(1))
        if (!shipments.list.length) {
            dispatch(changePath("/rate"))
        }
    }
}

const isAccountActive = (locations = []) =>
    locations.some(item => get(item, "users[0].access.granted"))

export function authenticated(redirectToHome) {
    return async (dispatch, getState) => {
        const user = getState().user.profile
        if (!user) return
        dispatch(authorizationFinished())
        if (!isAccountActive(get(user, "locations", []))) return
        dispatch(attachPusher(`user-${get(user, "id")}`))
        dispatch(setDefaultCpg())
        await Promise.all([
            dispatch(requestAccessorials()),
            dispatch(requestCarriers()),
            dispatch(requestVagueCommodities()),
            redirectToHome && dispatch(redirectToRateScreenIfNeeded()),
        ])
        const idSections = (getUserId() || "").split(/\||-/) || [""]
        const provider = capitalize(idSections[0])
        dispatch(endTimer("login", "System", "Login", provider))
    }
}

export const disauthenticated = () => dispatch => {
    dispatch({ type: USER_DISAUTHENTICATED })
    dispatch(changePath("/"))
}

export function initSignup(fields) {
    return dispatch => {
        dispatch({ type: INIT_SIGNUP, fields })
        dispatch(authorizationFinished())
    }
}

const createUserLocationPayload = (
    {
        sameAsShippingAddress,
        shippingAddress,
        address,
        fedexBillToAccount,
        fedexFreightAccount,
        billingContact,
        ...values
    },
    isThirdPartyOnly,
    isShipperOnly,
    isShipperAndThirdParty
) => ({
    ...values,
    shippingAddress: isThirdPartyOnly ? address : shippingAddress,
    billingContact: sameAsShippingAddress
        ? { companyName: shippingAddress.name }
        : billingContact,
    billingAddress: sameAsShippingAddress ? shippingAddress.address : address,
    fedexFreightAccount:
        isShipperOnly || isShipperAndThirdParty ? fedexFreightAccount : null,
    fedexBillToAccount:
        isThirdPartyOnly || isShipperAndThirdParty ? fedexBillToAccount : null,
})

const createUserPayload = ({ termsVersion, locations, ...values }) => ({
    ...values,
    locations: (locations || []).map(item => createUserLocationPayload(item)),
    ...(termsVersion && {
        termsAndConditions: {
            acceptedVersion: termsVersion,
        },
    }),
})

const userUpdateStart = () => ({ type: USER_UPDATE_START })

function userUpdateError(error) {
    return dispatch => {
        dispatch({ type: USER_UPDATE_ERROR, error })
        dispatch(errorMessage(error))
    }
}

async function upsertUser(values) {
    let innerValues = values
    const userId = get(values, "id")
    if (get(values, "alerts.user")) {
        innerValues = {
            ...values,
            preferences: {
                ...values.preferences,
                alerts: values.alerts.user,
            },
            alerts: undefined,
        }
        values.preferences.alerts = values.alerts.user
        delete values.alerts
    }

    const { data: user } = await goFetch(
        userId ? `/users/${userId}` : "/users",
        {
            method: userId ? "PUT" : "POST",
            credentials: "same-origin",
            headers: { "cache-control": "no-cache" },
            data: innerValues,
        },
        true
    )
    return user
}

export function setDefaultLocation(index, length) {
    // deprecated
    return dispatch => {
        for (let i = 0; i < length; i += 1) {
            if (i !== index)
                dispatch(
                    change(
                        "userPreferences",
                        `locations[${i}].isDefault`,
                        false
                    )
                )
        }
    }
}

function innerUpdateUserPreferences(data) {
    return async dispatch => {
        if (!data) return null
        try {
            const { data: resp } = await goFetch(
                `/users`,
                userUpdateOptions(data),
                true
            )
            return resp
        } catch (error) {
            dispatch(userUpdateError(error))
        }
        return null
    }
}

export function updateUser(values) {
    return async dispatch => {
        try {
            const user = await upsertUser(values)
            if (user) dispatch(notifySuccess())
            return user && dispatch(userReceived(user))
        } catch (err) {
            throw new SubmissionError({
                _error: "User update unsuccessful.",
            })
        }
    }
}

export function manageLocationCarrier(
    location,
    carrierCode,
    accountId,
    language
) {
    return async dispatch => {
        const locationId = get(location, "_id")
        const cpgCode = get(location, "cpgCode")
        const { data: token } = await goFetch(
            `/locations/token/${locationId}/${accountId}`,
            {
                method: "GET",
                credentials: "same-origin",
                responseType: "text",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        const storageToken = localStorage.getItem("token")
        const ltlSelectApiToken = `bearer ${storageToken.substr(
            1,
            storageToken.length - 2
        )}`
        const tokens = qs.stringify({ token, ltlSelectApiToken, language })

        const postfix = carrierCode
            ? `${cpgCode}/edit/${carrierCode}`
            : `${cpgCode}/add`
        return `${window._env_.REACT_APP_CARRIER_ONBOARDING_URL}/${postfix}?${tokens}`
    }
}

export function updateUserLocation(location) {
    return async dispatch => {
        const {
            pickupContact,
            billingContact,
            address: billingAddress, // Billing address
            shippingAddress,
            fedexBillToAccount,
            fedexFreightAccount,
            pickupAndDestroyLocation,
            pickupAccessorials = [],
            deliveryAccessorials = [],
        } = location

        const isOwner = location?.users?.[0]?.role === "owner"
        const locationId = location?._id ?? ""

        let adjustedPickupAccessorials = []
        let adjustedDeliveryAccessorials = []

        if (typeof pickupAccessorials === "string") {
            adjustedPickupAccessorials = pickupAccessorials.split(",")
        } else {
            adjustedPickupAccessorials = pickupAccessorials
        }

        if (typeof deliveryAccessorials === "string") {
            adjustedDeliveryAccessorials = deliveryAccessorials.split(",")
        } else {
            adjustedDeliveryAccessorials = deliveryAccessorials
        }

        if (pickupAccessorials?.length > 0) {
            adjustedPickupAccessorials = adjustedPickupAccessorials.map(
                entry => {
                    return { value: entry }
                }
            )
        }
        if (deliveryAccessorials?.length > 0) {
            adjustedDeliveryAccessorials = adjustedDeliveryAccessorials.map(
                entry => {
                    return { value: entry }
                }
            )
        }

        try {
            if (isOwner) {
                const cleanedShippingAddress = {
                    name: shippingAddress.name,
                    address: pick(shippingAddress.address, [
                        "street1",
                        "street2",
                        "city",
                        "state",
                        "postalCode",
                        "country",
                    ]),
                }
                await goFetch(
                    `/locations/${locationId}`,
                    {
                        method: "PUT",
                        credentials: "same-origin",
                        headers: {
                            "cache-control": "no-cache",
                        },
                        data: {
                            pickupContact,
                            billingContact,
                            billingAddress,
                            shippingAddress: cleanedShippingAddress,
                            fedexBillToAccount,
                            fedexFreightAccount,
                            pickupAndDestroyLocation,
                            pickupAccessorials: adjustedPickupAccessorials,
                            deliveryAccessorials: adjustedDeliveryAccessorials,
                        },
                    },
                    true
                )
            } else {
                const permissions = location?.users?.[0]?.permissions
                const userId = location?.users?.[0]?.user?.id ?? ""
                const formattedPermissions = {}
                Object.keys(permissions).forEach(key => {
                    formattedPermissions[key] = permissions[key].value
                })
                await goFetch(
                    `/locations/${locationId}/users/${userId}`,
                    {
                        method: "PUT",
                        credentials: "same-origin",
                        headers: {
                            "cache-control": "no-cache",
                        },
                        data: {
                            permissions: formattedPermissions,
                        },
                    },
                    true
                )
            }
        } catch (e) {
            const user = await retrieveUser()
            dispatch(userReceived(user))
            throw e
        }
        const user = await retrieveUser()
        dispatch(userReceived(user))
        return user
    }
}

export function removeUserLocation(locationId, userId) {
    return async (dispatch, getState) => {
        const state = getState()
        const calculatedUserId = userId || get(state, "user.profile.id")
        const rsvp = await goFetch(
            `/locations/${locationId}/users/${calculatedUserId}`,
            {
                method: "DELETE",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        const user = await retrieveUser()
        dispatch(userReceived(user))
        return rsvp
    }
}

export function updateUserCustomsBrokeragePreferences(exportData, importData) {
    return async dispatch => {
        if (!importData) return undefined
        dispatch(userUpdateStart())

        const body = {
            "preferences.importCustomsBrokerageInfo": importData,
            ...(exportData && {
                "preferences.importCustomsBrokerageInfo": exportData,
            }),
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function updateUserHazMatPreferences(data) {
    return async dispatch => {
        if (!data) return undefined
        dispatch(userUpdateStart())

        const body = {
            "preferences.hazmatEmergencyContact": data,
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function updateUserOnboardingDNA() {
    return async dispatch => {
        dispatch(userUpdateStart())

        const body = {
            "preferences.onboardingDNA": true,
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function updateUserTileSettings(tileSettings) {
    return async dispatch => {
        dispatch(userUpdateStart())

        const body = {
            "preferences.tileSettings": tileSettings,
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function updateUserDefaultLocation(locationId) {
    return async dispatch => {
        dispatch(userUpdateStart())

        const body = {
            "preferences.defaultLocationId": locationId,
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function updateUserOperatingLocation(data) {
    return async dispatch => {
        dispatch(userUpdateStart())

        const body = {
            "preferences.operatingLocation": data,
        }

        const user = await dispatch(innerUpdateUserPreferences(body))
        return user && dispatch(userReceived(user))
    }
}

export function finishTutorial(data, id) {
    return async dispatch => {
        const { status, type } = data

        if (
            [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
            type === EVENTS.TOUR_END
        ) {
            dispatch(userUpdateStart())

            const key = `preferences.tutorials.${id}`
            const body = {
                [key]: {
                    completed: true,
                    timestamp: moment(),
                    status,
                },
            }

            const user = await dispatch(innerUpdateUserPreferences(body))
            return user && dispatch(userReceived(user))
        }
    }
}

// export function toggleGroupByCarrier() {
//     return async (dispatch, getState) => {
//         const userId = getUserId()
//         const { preferences } = getState().user
//         dispatch(userUpdateStart())
//         dispatch({ type: USER_TOGGLE_GROUP_BY_CARRIER })
//         const body = {
//             preferences: {
//                 groupRatesByCarrier: !preferences.groupRatesByCarrier,
//             },
//         }
//         const user = await dispatch(innerUpdateUserPreferences(body))
//         return user && dispatch(userReceived(user))
//     }
// }

export function acceptPreferenceSuggestions(origin, index) {
    if (!origin)
        return {
            type: USER_PREFERENCE_ACCEPT_ADDRESS,
            origin: {},
            index,
        }
    return {
        type:
            index < 0
                ? USER_NEW_LOCATION_ACCEPT_ADDRESS
                : USER_PREFERENCE_ACCEPT_ADDRESS,
        index,
        origin: {
            shippingStreet1: origin.street1,
            shippingStreet2: origin.street2,
            shippingCity: origin.city,
            shippingState: origin.state,
            shippingPostalCode: origin.postalCode,
            shippingCountry: origin.country,
        },
    }
}

export const userCarrierResult = result => ({
    type: USER_CARRIER_RESULT,
    result,
})

export const getUserCarriers = () => async (dispatch, getState) => {
    try {
        if (getState().user.carriers.length) return
        const { data: carriers } = await goFetch(`/users/carriers`, {}, true)
        dispatch(userCarrierResult(carriers))
    } catch (error) {
        dispatch(errorMessage(error))
    }
}

export const forceGetUserCarriers = () => async (dispatch, getState) => {
    dispatch(userCarrierResult([]))
    try {
        const { data: carriers } = await goFetch(`/users/carriers`, {}, true)
        dispatch(userCarrierResult(carriers))
    } catch (error) {
        dispatch(errorMessage(error))
    }
}

async function innerAddLocation(
    values,
    isThirdPartyOnly,
    isShipperOnly,
    isShipperAndThirdParty
) {
    const data = createUserLocationPayload(
        values,
        isThirdPartyOnly,
        isShipperOnly,
        isShipperAndThirdParty
    )
    return goFetch(
        "/locations",
        {
            method: "POST",
            credentials: "same-origin",
            headers: { "cache-control": "no-cache" },
            data,
            errorHandler: resp => {
                if (get(resp, "status") !== 400) return false
                if (get(resp, "data.locationId")) return true
            },
        },
        true
    )
}

export function requestAccessToLocation(data) {
    return async (dispatch, getState) => {
        const state = getState()
        const userId = get(state, "user.profile.id")
        const locationId = get(data, "locationId")
        const fedexFreightAccount = get(data, "fedexFreightAccount")
        const fedexBillToAccount = get(data, "fedexBillToAccount")
        await goFetch(
            `/locations/${locationId}/users/${userId}/request`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: {
                    fedexFreightAccount,
                    fedexBillToAccount,
                },
            },
            true
        )
        const user = await retrieveUser()
        dispatch(userReceived(user))
    }
}

export function addLocation(values) {
    return async dispatch => {
        const userId = getUserId()
        if (!userId) return undefined
        const { location: locationData } = values
        let error
        let location
        const isThirdPartyOnly = values.isThirdParty && !values.isShipper
        const isShipperOnly = !values.isThirdParty && values.isShipper
        const isShipperAndThirdParty = values.isThirdParty && values.isShipper
        let duplicate
        try {
            const { data } = await innerAddLocation(
                locationData,
                isThirdPartyOnly,
                isShipperOnly,
                isShipperAndThirdParty
            )
            if (get(data, "duplicate")) {
                duplicate = data.duplicate
            } else {
                location = data
            }
        } catch (err) {
            error = err
        }
        if (!location || error) {
            const user = await retrieveUser()
            dispatch(userReceived(user))
            if (error) {
                throw new SubmissionError({
                    error,
                    _error: addLocationError(get(error, "response.message")),
                })
            }
            if (!location && !duplicate) {
                throw new SubmissionError({
                    _error: addLocationError(
                        "New location could not be created."
                    ),
                })
            }
        }
        if (location) {
            dispatch(locationReceived(location))
        }
        return { duplicate, location }
    }
}

export function signupSubmit(values) {
    return async (dispatch, getState) => {
        const { location: locationData, ...innerValues } = values
        let user
        let location
        try {
            const state = getState()
            const existingUser = state?.user?.profile
            const termsVersion = get(state, "terms.version")
            const payload = createUserPayload({
                ...existingUser,
                ...innerValues,
                ...(existingUser?.preferences && {
                    preferences: {
                        ...existingUser?.preferences,
                        ...innerValues?.preferences,
                    },
                }),
                termsVersion,
            })
            const isThirdPartyOnly = values.isThirdParty && !values.isShipper
            const isShipperOnly = !values.isThirdParty && values.isShipper
            const isShipperAndThirdParty =
                values.isThirdParty && values.isShipper
            user = await upsertUser(payload)
            let duplicate
            if (locationData) {
                const { data } = await innerAddLocation(
                    locationData,
                    isThirdPartyOnly,
                    isShipperOnly,
                    isShipperAndThirdParty
                )
                if (get(data, "duplicate")) {
                    duplicate = data.duplicate
                } else {
                    location = data
                }
            }
            if (!duplicate) {
                dispatch(openModal(<UserSignedupNotification />))
            }
            return duplicate
        } catch (error) {
            const reason = get(error, "response.message")
            dispatch(trackGAEvent("Signup", `Error ${reason}`))
            throw new SubmissionError({
                error,
                _error: addLocationError(reason),
            })
        } finally {
            if (user) {
                dispatch(userReceived(user))
                if (location) {
                    user = dispatch(locationReceived(location))
                    dispatch(trackGAEvent("Signup", "Success"))
                }
                dispatch(authenticated(user, true))
            } else {
                user = await retrieveUser()
                user && dispatch(userReceived(user))
            }
        }
    }
}

export function trackGAEvent(category, action, cpg, value) {
    return async (dispatch, getState) => {
        const state = getState()
        if (
            window._env_.REACT_APP_ENABLE_LOGGING === "disabled" &&
            get(state, "user.profile.isTestAccount")
        ) {
            return
        }
        const cpgCode = cpg || getDefaultLocation(state).cpgCode
        ReactGA.event({
            category,
            action,
            label: `${new Date()}, ${cpgCode}`,
            ...(value !== undefined && { value }),
        })
    }
}

// updated version of event tracking
export function trackGAEventNew(category, action, value) {
    return (dispatch, getState) => {
        const state = getState()
        if (
            window._env_.REACT_APP_ENABLE_LOGGING === "disabled" &&
            get(state, "user.profile.isTestAccount")
        ) {
            return
        }
        ReactGA.event({
            category,
            action,
            label: `${new Date()}`,
            ...(value !== undefined && { value }),
        })
    }
}

export function trackGAEventRR(category, action, label, value) {
    return (dispatch, getState) => {
        const state = getState()
        if (
            window._env_.REACT_APP_ENABLE_LOGGING === "disabled" &&
            get(state, "user.profile.isTestAccount")
        ) {
            return
        }
        ReactGA.event({
            category,
            action,
            label,
            ...(value !== undefined && { value }),
        })
    }
}

export function addFedexAccount({
    fedexBillToAccount,
    fedexFreightAccount,
    _id: locationId,
}) {
    return async dispatch => {
        try {
            await goFetch(
                `/locations/${locationId}`,
                {
                    method: "PUT",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        fedexBillToAccount,
                        fedexFreightAccount,
                    },
                },
                true
            )
            dispatch(closeModal())
            const user = await retrieveUser()
            dispatch(userReceived(user))
            return user && dispatch(userReceived(user))
        } catch (err) {
            const errors = get(err, "response.message.errors", [])
            throw new SubmissionError({
                _error:
                    errors.length > 0
                        ? errors[0].message
                        : "The request encountered an error, please try again later.",
            })
        }
    }
}

export const retrieveUser = async (email, sync) => {
    try {
        // const userId = getUserId()
        // TODO put '/users/' back
        // const url = sync ? '/users/locations' : '/users/'
        const url = "/users/locations"
        const token = localStorage.getItem("token")
        const authToken = `bearer ${token.substr(1, token.length - 2)}`
        const { data: retVal, status } = await goFetch(
            url,
            {
                // method: sync ? 'PUT' : 'GET',
                method: "GET",
                // params: { email },
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                    Authorization: authToken,
                },
                validErrorCodes: [404],
            },
            true
        )
        return status === 404 ? null : retVal
    } catch (error) {
        return null
    }
}
