import React, { useState } from "react"
import { Field, change } from "redux-form"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import FormField from "../form/form-field"
import {
    FormControlLabel,
    Checkbox,
    Button,
    Chip,
    IconButton,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import CloseIcon from "@material-ui/icons/Close"

const styles = theme => ({
    addNumbers: {
        paddingTop: "15px",
    },
    chip: {
        margin: "20px 10px",
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
    },
})

function InBondDialog({
    onClose,
    open,
    classes,
    newCF7512Number,
    changeField,
    cf7512ModalNumbers = [],
}) {
    const [isIT, setIsIT] = useState(false)
    const [isTE, setIsTE] = useState(false)

    const buttonDisabled = (!isIT && !isTE) || !newCF7512Number

    const handleAddNumber = () => {
        const suffix = isIT ? "IT" : isTE ? "T&E" : ""
        const stringToAdd = `${newCF7512Number}${suffix}`

        changeField("cf7512ModalNumbers", [...cf7512ModalNumbers, stringToAdd])
        changeField("newCF7512Number", "")
    }

    const handleDelete = number => {
        const cf7512ModalNumbersCopy = [...cf7512ModalNumbers]
        changeField(
            "cf7512ModalNumbers",
            cf7512ModalNumbersCopy.filter(x => x !== number)
        )
    }

    const confirmNumbers = () => {
        changeField("cf7512", [...cf7512ModalNumbers])
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="alert-dialog-title">
                <Grid item container justify="space-between">
                    <FormattedMessage
                        id="book.inBond__dialogTitle"
                        defaultMessage="CF7512 Numbers"
                    />
                    <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid item container>
                    <Grid item container xs={5}>
                        <Grid item container>
                            <Field
                                name="newCF7512Number"
                                component={FormField}
                                type="number"
                                label={[
                                    "* ",
                                    <FormattedMessage
                                        id="book.inBond__newCF7512Number"
                                        defaultMessage="Add New CF7512 Number"
                                    />,
                                ]}
                            />
                        </Grid>
                        <Grid item container>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isIT}
                                        onChange={event => {
                                            setIsIT(event.target.checked)
                                            setIsTE(false)
                                        }}
                                        value="isIT"
                                    />
                                }
                                label="Immediate Transportation (IT)"
                            />
                        </Grid>
                        <Grid item container>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isTE}
                                        onChange={event => {
                                            setIsTE(event.target.checked)
                                            setIsIT(false)
                                        }}
                                        value="isTE"
                                    />
                                }
                                label="Transportation Exportation (T&E)"
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            justify="flex-end"
                            className={classes.addNumbers}
                        >
                            <Button
                                color="primary"
                                disabled={buttonDisabled}
                                variant="outlined"
                                onClick={() => handleAddNumber()}
                            >
                                <FormattedMessage
                                    id="book.inBond__addNumber"
                                    defaultMessage="Add Number"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container xs={7}>
                        {cf7512ModalNumbers.map((number, i) => (
                            <Chip
                                label={number}
                                key={i}
                                className={classes.chip}
                                backgroundColor="#F4F4F4"
                                tabIndex={-1}
                                onDelete={() => handleDelete(number)}
                            />
                        ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => confirmNumbers()}
                    color="primary"
                    variant="contained"
                >
                    <FormattedMessage
                        id="generalTerms__confirm"
                        defaultMessage="confirm"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    return {
        newCF7512Number: state?.form[props.formName].values?.newCF7512Number,
        cf7512ModalNumbers:
            state?.form[props.formName].values?.cf7512ModalNumbers,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    changeField: (field, value) =>
        dispatch(change(props.formName, field, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(InBondDialog))
