import { Button, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { cloneDeep, set } from "lodash"
import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Field, FieldArray } from "redux-form"
import {
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isUSDomesticShipment,
} from "../../../actions/validation"
import { goFetch } from "../../../http"
import FormField from "../../form/form-field"
import FormSwitch from "../../form/form-switch"
import { BookHandlingUnits } from "../bookShipment/atoms/BookHandlingUnits"
import { QuickHandlingUnits } from "./quickItems/QuickHandlingUnits"
import { lengthUnit, weightUnit } from "../../util/units"
import { supportedCurrencies } from "../../../misc"
import FormSelectAutocompleteNew from "../../form/form-select-autocomplete-new"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    underline: {
        textDecoration: "underline  ",
    },
    bottomContainer: {
        paddingTop: "15px",
    },
    currencyAndMeasurement: {
        paddingBottom: "20px",
    },
})

export default function Items({
    formValues = { handlingUnits: [] },
    changeField,
    handleHUPanel,
    isHUOpen,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    addNewHandlingUnit,
    trackGA,
}) {
    const classes = useStyles()
    const {
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        preferredSystemOfMeasurement,
        handlingUnits = [],
        origin,
        destination,
        isAdvancedItems,
    } = formValues

    const isIntl = isInternationalShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country
    )

    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const isIntraMexico =
        origin?.shippingAddress?.address?.country === "MX" &&
        destination?.shippingAddress?.address?.country === "MX"

    useEffect(() => {
        adjustEstimatedLinearFeet()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits.length])

    const adjustEstimatedLinearFeet = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)
        if (fieldName === "doNotStack") {
            set(adjustedFormValues, `${prefix}.stackable`, !value)
        }

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const adjustEstimatedLinearFeetOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const calculateEstimatedLinearFeet = async handlingUnits => {
        const { preferredSystemOfMeasurement, origin, destination } = formValues

        try {
            const {
                data: {
                    totalLinearFeet,
                    capLoadTotalLinearFeet,
                    volumeTotalLinearFeet,
                    isOverLTLLimit,
                },
            } = await goFetch(
                `/quotes/linearfeet`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        handlingUnits,
                        preferredSystemOfMeasurement,
                        originCountry:
                            origin?.shippingAddress?.address?.country,
                        destinationCountry:
                            destination?.shippingAddress?.address?.country,
                    },
                },
                true
            )
            changeField("totalLinearFeet", totalLinearFeet)
            changeField("capLoadTotalLinearFeet", capLoadTotalLinearFeet)
            changeField("volumeTotalLinearFeet", volumeTotalLinearFeet)
            changeField("userProvidedTotalLinearFeet", false)
            changeField("isOverLTLLimit", isOverLTLLimit)
        } catch (error) {
            console.error(error)
        }
    }

    const isUSDomestic = isUSDomesticShipment(
        origin?.shippingAddress?.address?.country,
        destination?.shippingAddress?.address?.country
    )

    return (
        <Grid item container>
            {!isUSDomestic && !isFreightBox && (
                <Grid item container className={classes.currencyAndMeasurement}>
                    <Grid item container xs={6}>
                        <Field
                            component={FormSelectAutocompleteNew}
                            name="preferredCurrencyCode"
                            label={
                                <FormattedMessage
                                    id="bookShipment.preferredCurrency"
                                    defaultMessage="Preferred Currency"
                                />
                            }
                            options={supportedCurrencies}
                        />
                    </Grid>
                    <Grid item container xs={6}>
                        <Field
                            component={FormSelectAutocompleteNew}
                            name="preferredSystemOfMeasurement"
                            label={
                                <FormattedMessage
                                    id="bookShipment.preferredSystemOfMeasurement"
                                    defaultMessage="Preferred System of Measurement"
                                />
                            }
                            options={[
                                {
                                    value: "IMPERIAL",
                                    label: "lbs/in",
                                },
                                {
                                    value: "METRIC",
                                    label: "kg/cm",
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item container className={classes.title}>
                <Grid item container xs={4} alignItems="center">
                    <Typography variant="subheading">
                        <FormattedMessage
                            id="bookShipment.lineItems__title"
                            defaultMessage="Line Item Information"
                        />
                    </Typography>
                </Grid>
            </Grid>
            {!isFreightDirect && (
                <Grid item container>
                    <Field
                        name={"isAdvancedItems"}
                        component={FormSwitch}
                        label={
                            <FormattedMessage
                                id="bookShipment.basicInfo__showAdvancedItemEntry"
                                defaultMessage="Enter full line item details"
                            />
                        }
                        onChange={(e, value) => {
                            trackGA(
                                "Quick Rate Flow",
                                "Advanced Items Switch",
                                value
                            )
                            if (!value) {
                                changeField(
                                    "handlingUnits",
                                    (handlingUnits ?? []).map(hu => {
                                        return {
                                            items: [
                                                {
                                                    freightClass:
                                                        hu?.items[0]
                                                            .freightClass,
                                                    weight: hu?.items[0].weight,
                                                },
                                            ],
                                        }
                                    })
                                )
                            }
                        }}
                    />
                </Grid>
            )}

            {!isAdvancedItems && !isFreightDirect ? (
                <FieldArray
                    name="handlingUnits"
                    component={QuickHandlingUnits}
                    handlePanel={handleHUPanel}
                    isOpen={isHUOpen}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    isFreightBox={isFreightBox}
                    formValues={formValues}
                    changeField={changeField}
                    weightUnit={weightUnit(preferredSystemOfMeasurement)}
                    lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                    isIntl={isIntl}
                    isUSDomesticOffshoreWithItemCustoms={
                        isUSDomesticOffshoreWithItemCustoms
                    }
                    favoriteItems={favoriteItems}
                    itemsIsLoaded={itemsIsLoaded}
                    doSearch={doSearch}
                    trackGA={trackGA}
                    isIntraMexico={isIntraMexico}
                />
            ) : (
                <FieldArray
                    name="handlingUnits"
                    component={BookHandlingUnits}
                    handlePanel={handleHUPanel}
                    isOpen={isHUOpen}
                    isFreightDirect={isFreightDirect}
                    isFreightDirectReturns={isFreightDirectReturns}
                    isFreightBox={isFreightBox}
                    formValues={formValues}
                    changeField={changeField}
                    weightUnit={weightUnit(preferredSystemOfMeasurement)}
                    lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                    isIntl={isIntl}
                    isUSDomesticOffshoreWithItemCustoms={
                        isUSDomesticOffshoreWithItemCustoms
                    }
                    favoriteItems={favoriteItems}
                    itemsIsLoaded={itemsIsLoaded}
                    doSearch={doSearch}
                    adjustEstimatedLinearFeet={adjustEstimatedLinearFeet}
                    adjustEstimatedLinearFeetOnSearchChange={
                        adjustEstimatedLinearFeetOnSearchChange
                    }
                    trackGA={trackGA}
                    isIntraMexico={isIntraMexico}
                />
            )}
            <Grid
                item
                container
                className={classes.section}
                justify="space-between"
            >
                <FieldArray
                    name="handlingUnits"
                    component={({ fields }) => (
                        <Button
                            id="quickRateAddLineItem"
                            className={classes.button__add_item}
                            color="primary"
                            type="outlined"
                            onClick={() => {
                                addNewHandlingUnit(fields)
                            }}
                        >
                            +{" "}
                            <FormattedMessage
                                id="bookShipment.addLineItem"
                                defaultMessage="Add Line Item"
                            />
                        </Button>
                    )}
                />
                <Grid item justify="flex-end">
                    {!isFreightDirect &&
                        !isFreightDirectReturns &&
                        isAdvancedItems && (
                            <Field
                                component={FormField}
                                name="totalLinearFeet"
                                id="getRates__totalLinearFeet"
                                label={
                                    <FormattedMessage
                                        id="getRates.estLinearFeet"
                                        defaultMessage="Est. Linear Feet"
                                    />
                                }
                                required
                                onChange={() => {
                                    trackGA(
                                        "Quick Rate Flow",
                                        "User Provided Total Linear Feet"
                                    )
                                    changeField(
                                        "userProvidedTotalLinearFeet",
                                        true
                                    )
                                }}
                            />
                        )}
                </Grid>
            </Grid>
        </Grid>
    )
}
