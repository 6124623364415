import { Component } from "react"
import ReactGA from "react-ga"
import moment from "moment"
import { get } from "lodash"

export class ErrorBoundary extends Component {
    componentDidCatch(error, info) {
        const description = [
            moment.utc().format(),
            error,
            get(info, "componentStack"),
        ]
            .filter(x => x)
            .join(" ")
        ReactGA.exception({ description, fatal: true })
    }

    render() {
        return this.props.children
    }
}
