import { toast } from "react-toastify"
import { goFetch } from "../http"
import { CLOSE_MODAL } from "./modal"
import { createGAEvent } from "./index"
import { emailSentSuccess } from "../messages/confirmation-constants"
import { emailSendError } from "../messages/error-constants"

export const EMAIL_FIELD_CHANGE = "EMAIL_FIELD_CHANGE"
export const INIT_EMAIL_FORM = "INIT_EMAIL_FORM"
export const EMAIL_SENT = "EMAIL_SENT"
export const EMAIL_SENDING = "EMAIL_SENDING"

export const emailFieldChange = (field, value) => ({
    type: EMAIL_FIELD_CHANGE,
    field,
    value,
})

export function initEmailModalForm(shipmentId, isSendBol, isSendLabels) {
    return async (dispatch, getState) => {
        const state = getState()
        const shipment = state.shipment.list[shipmentId].shipment
        const destinationEmail = shipment.origin.appointment
            ? (shipment.origin.appointment.email || {}).email_address
            : ""

        const originEmail = shipment.origin.contact.email.email_address
        const cc =
            destinationEmail === originEmail
                ? {}
                : {
                      ccEmail: destinationEmail,
                      ccName: (shipment.origin.appointment || {}).name || "",
                  }
        dispatch({
            type: INIT_EMAIL_FORM,
            shipmentId,
            isSendLabels,
            isSendBol,
            toEmail: originEmail,
            toName: shipment.origin.contact.name,
            ccEmail: cc.ccEmail,
            ccName: cc.ccName,
        })
    }
}

function notifySuccess(shipmentId) {
    return dispatch => {
        toast.success(emailSentSuccess, {
            position: toast.POSITION.TOP_CENTER,
        })
        dispatch(
            createGAEvent("Shipment", "Image email sent", "", {
                shipment: shipmentId,
            })
        )
    }
}

function notifyError(shipmentId, error) {
    return dispatch => {
        toast.error(emailSendError, {
            position: toast.POSITION.TOP_CENTER,
        })
        dispatch(
            createGAEvent(
                "Shipment",
                "Image email send error",
                `Error: ${error}`,
                { shipment: shipmentId }
            )
        )
    }
}

export function sendDocumentsEmail(values) {
    return async (dispatch, getState) => {
        const state = getState().email
        const { shipmentId } = state
        const {
            ccEmails,
            toEmail,
            toName,
            toLanguage,
            ccEmail,
            ccName,
            ccLanguage,
            isSendLabels,
            isSendBol,
        } = values || {}
        try {
            if (!ccEmails) {
                // TODO: reject
            }
            const emails = [
                {
                    emailAddress: toEmail,
                    guestName: toName,
                    language: toLanguage,
                },
            ]
            if (ccName && ccEmail) {
                emails.push({
                    emailAddress: ccEmail,
                    guestName: ccName,
                    language: ccLanguage,
                })
            }
            await goFetch(
                `/documents/bol/${shipmentId}/email`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        emails,
                        isSendLabels,
                        isSendBol,
                    },
                },
                true
            )
            dispatch(notifySuccess(shipmentId))
            return dispatch({ type: EMAIL_SENT })
        } catch (error) {
            dispatch(notifyError(shipmentId, error))
            throw new Error()
        }
    }
}

export const closeModal = () => ({ type: CLOSE_MODAL })
