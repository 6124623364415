import { Grid, Typography } from "@material-ui/core"
import React from "react"
import { FormattedMessage } from "react-intl"

export default function LocationLabel({
    isFreightDirect,
    isFreightDirectReturns,
    isFreightBox,
}) {
    return (
        <Grid item container xs={12} direction="column">
            {isFreightDirect && (
                <Typography variant="caption" color="primary">
                    <FormattedMessage
                        id="getRates.billToLocation__freightDirect"
                        defaultMessage="FedEx Freight Direct"
                    />
                </Typography>
            )}
            {isFreightDirectReturns && (
                <Typography variant="caption" color="primary">
                    <FormattedMessage
                        id="getRates.billToLocation__freightDirectReturns"
                        defaultMessage="FedEx Freight Direct Returns"
                    />
                </Typography>
            )}
            {isFreightBox && (
                <Typography variant="caption" color="primary">
                    <FormattedMessage
                        id="getRates.billToLocation__freightBox"
                        defaultMessage="FedEx Freight Box"
                    />
                </Typography>
            )}
        </Grid>
    )
}
