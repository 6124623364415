import React from "react"
import Grid from "@material-ui/core/Grid"
import { connect } from "react-redux"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import { FormattedMessage } from "react-intl"
import { quoteSelectRow, quoteSelect } from "../../../actions/quote"
import { isRateSelectedForShare } from "../../../misc"
import { CarrierLogo } from "../../util"

const styles = theme => ({
    main__container: {},
    paper: {
        padding: "10px",
        minHeight: "111px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    book__button: {
        width: "100%",
    },
})

const NoRateOnlyDispatchRow = ({
    classes,
    isThirdParty,
    disabled,
    carrier,
    shareOpen,
    rowSelected,
    handleRowSelect,
    onClick,
    index,
    getBol,
    bolNumber,
}) => {
    const isDomesticOffshore = carrier?.rateQuotes?.[0]?.isDomesticOffshore
    return (
        <Grid
            item
            container
            direction="column"
            className={classes.main__container}
        >
            {isDomesticOffshore && (
                <Typography variant="subheading">
                    <FormattedMessage
                        id="rateResults__fedExDomesticOffshore"
                        defaultMessage="FedEx Domestic Offshore"
                    />
                </Typography>
            )}
            <Paper className={classes.paper} justify="center">
                <Grid item container xs={2}>
                    <Grid item xs={1}>
                        {shareOpen && (
                            <Grid
                                item
                                container
                                alignItems="flex-start"
                                justify="center"
                            >
                                {" "}
                                <Checkbox
                                    checked={rowSelected}
                                    onClick={e => e.stopPropagation()}
                                    onChange={() =>
                                        handleRowSelect(
                                            carrier.rateQuotes,
                                            carrier.carrierCode
                                        )
                                    }
                                    className={classes.share__checkbox}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container xs={11}>
                        <CarrierLogo
                            carrierCode={carrier.carrierCode}
                            isDomesticOffshore={isDomesticOffshore}
                        />
                    </Grid>
                </Grid>
                <Grid item container justify="center" xs={9}>
                    <Button
                        disabled={disabled}
                        color="primary"
                        className={classes.book__button}
                        onClick={e => {
                            e.stopPropagation()
                            if (!disabled && !shareOpen) {
                                onClick(
                                    bolNumber,
                                    carrier.carrierCode,
                                    carrier.rateQuotes[0].index,
                                    carrier.rateQuotes[0].mode,
                                    carrier.rateQuotes[0].direction,
                                    {
                                        rateIndex: 1,
                                        cardIndex: index + 1,
                                    }
                                )
                                getBol()
                            }
                        }}
                    >
                        <FormattedMessage
                            id="rateResults__bookNow"
                            defaultMessage="Book Now"
                        />
                    </Button>
                </Grid>
                <Grid
                    item
                    container
                    xs={1}
                    alignItems="flex-start"
                    justify="center"
                >
                    {isThirdParty && (
                        <Typography
                            variant="caption"
                            className={classes.third__party__label}
                        >
                            <FormattedMessage
                                id="rateResults__thirdPartyPricing"
                                defaultMessage="3rd Party Pricing"
                            />
                        </Typography>
                    )}
                </Grid>
            </Paper>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => ({
    bolNumber: state.identifiers.bolNumber,
    shareOpen: state.share.isOpen,
    rowSelected: ownProps.carrier.rateQuotes.every(rate =>
        isRateSelectedForShare(
            state.share.selections,
            rate,
            ownProps.carrier.carrierCode
        )
    ),
    shippedRateQuote: state.quotes.active.shippedRateQuote,
})

const mapDispatchToProps = dispatch => ({
    handleRowSelect: (rates, carrierCode) =>
        dispatch(quoteSelectRow(rates, carrierCode)),
    onClick: (bolNumber, carrier, quoteId, mode, direction, metadata) => {
        dispatch(
            quoteSelect(bolNumber, carrier, quoteId, mode, direction, metadata)
        )
    },
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(NoRateOnlyDispatchRow))
