import React from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem"
import { Grid } from "@material-ui/core"
import { FormattedMessage, injectIntl } from "react-intl"

const styles = theme => ({
    itemSearch__label: {},
    itemSearch__menuItem: {
        padding: "10px",
        display: "flex",
        height: "unset",
        borderBottom: "solid 1px #D2D2D2",
    },
})

class FormItemSearchOption extends React.Component {
    render() {
        const { classes, option = {}, index, onSelect, intl } = this.props
        const {
            isHazMat,
            count,
            weight,
            description,
            freightClass,
            nmfc1,
            nmfc2,
            packageType,
            disabled,
            value,
        } = option

        const getNmfcLabel = (nmfc1, nmfc2) => {
            if (!nmfc1 && !nmfc2) {
                return ""
            } else if (nmfc1) {
                if (nmfc2) {
                    return intl.formatMessage(
                        {
                            id: "bookshipment.itemSearch__nmfc1nmfc2",
                            defaultMessage: ", NMFC: {nmfc1}-{nmfc2}",
                        },
                        {
                            nmfc1,
                            nmfc2,
                        }
                    )
                } else {
                    return intl.formatMessage(
                        {
                            id: "bookshipment.itemSearch__nmfc1",
                            defaultMessage: ", NMFC: {nmfc1}",
                        },
                        {
                            nmfc1,
                        }
                    )
                }
            } else if (nmfc2) {
                return intl.formatMessage(
                    {
                        id: "bookshipment.itemSearch__nmfc2",
                        defaultMessage: ", NMFC: -{nmfc2}",
                    },
                    {
                        nmfc2,
                    }
                )
            }
        }

        const topLabel = (
            <FormattedMessage
                id="bookShipment.itemSearch__topLabel"
                defaultMessage={
                    "{description}, Class: {freightClass}{nmfcLabel}{isHazMatLabel}"
                }
                values={{
                    description,
                    freightClass,
                    nmfcLabel: getNmfcLabel(nmfc1, nmfc2),
                    isHazMatLabel: isHazMat ? ", HazMat" : "",
                }}
            />
        )
        const bottomLabel = (
            <FormattedMessage
                id="bookShipment.itemSearch__bottomLabel"
                defaultMessage={
                    "Package Type: {packageType}, Count: {count}, Weight: {weight}"
                }
                values={{ packageType, count, weight }}
            />
        )

        return (
            <MenuItem
                key={index}
                value={value}
                disabled={disabled}
                style={{
                    whiteSpace: "normal",
                }}
                onClick={event => onSelect(option, event)}
                className={classes.itemSearch__menuItem}
            >
                <Grid
                    item
                    container
                    alignItems="center"
                    alignContent="center"
                    className={classes.itemSearch__label}
                >
                    <Grid item container xs={12}>
                        <Typography color="inherit">{topLabel}</Typography>
                    </Grid>
                    <Grid item container>
                        <Typography variant="caption">{bottomLabel}</Typography>
                    </Grid>
                </Grid>
            </MenuItem>
        )
    }
}

export default injectIntl(withStyles(styles)(FormItemSearchOption))
