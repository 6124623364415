import React, { Fragment, Component } from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { withStyles } from "@material-ui/core/styles"
import { Map } from "./map"
import { displayFullPhone } from "../../actions/contact"

const styles = theme => ({
    card: {
        height: "100%",
    },
    content: {
        "margin-left": "60px",
        "padding-top": "0px !important",
        minWidth: "200px",
    },
    map__container: {
        width: "100%",
        height: "200px",
    },
    map_content: {
        paddingLeft: "60px",
        paddingRight: "10px",
    },
})

export const DisplayAddress = ({ address, showName = true }) => {
    const street1 = address?.street1 ?? ""
    const street2 = address?.street2 ?? ""
    const postalCode = address?.postalCode ?? ""
    const city = address?.city ?? ""
    const state = address?.state ?? ""
    return (
        <Grid xs={12} item>
            {showName && (
                <Typography variant="subheading">
                    {address?.name}
                </Typography>
            )}
            <Typography>{street1}</Typography>
            {street2 && <Typography>{street2}</Typography>}
            <Typography gutterBottom>
                {city && state && `${city}, ${state} ${postalCode}`}
            </Typography>
        </Grid>
    )
}

export const DisplayContact = ({ contact, disabled, hideEmail }) => {
    const textColor = disabled ? "textSecondary" : undefined
    return (
        <Grid xs={12} item>
            <Typography color={textColor} variant="subheading">
                {contact?.name ?? ""}
            </Typography>
            <Typography color={textColor}>
                {displayFullPhone(contact?.phone) ?? ""}
            </Typography>
            {!hideEmail && (
                <Typography color={textColor}>
                    {contact?.email ?? ""}
                </Typography>
            )}
        </Grid>
    )
}

DisplayContact.propTypes = {
    disabled: PropTypes.bool,
    contact: PropTypes.object.isRequired,
}

DisplayContact.defaultProps = {
    disabled: false,
}

class VisualVerification extends Component {
    state = {
        expanded: false,
    }

    toggleExpand = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    render() {
        const { address, classes } = this.props
        return (
            <Fragment>
                <div className={classes.map__container}>
                    <Map addresses={[address]} />
                </div>
            </Fragment>
        )
    }
}

VisualVerification = withStyles(styles)(VisualVerification)

const AddressCard = ({
    onChangeClick,
    address,
    contact,
    classes,
    title,
    visualVerification,
    gridSize,
    fixed,
    hideEmail,
}) => (
    <Grid xs={gridSize || 6} item>
        <Card className={classes.card}>
            <CardHeader
                className={classes.header}
                title={<Typography variant="headline">{title}</Typography>}
                avatar={
                    <Avatar>
                        <LocationOnIcon variant="contained" color="secondary" />
                    </Avatar>
                }
                action={
                    fixed ? null : (
                        <Button color="primary" onClick={onChangeClick}>
                            {"Change"}
                        </Button>
                    )
                }
            />
            <CardContent className={classes.content}>
                <Grid container>
                    <Grid xs={visualVerification ? 5 : 12} item>
                        <DisplayAddress address={address?.address ?? address} />
                        <DisplayContact
                            contact={address?.contact ?? contact}
                            hideEmail={hideEmail}
                        />
                    </Grid>
                    {visualVerification && (
                        <Grid xs={7} item className={classes.map_content}>
                            <VisualVerification
                                address={address?.address ?? address}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    </Grid>
)

AddressCard.propTypes = {
    classes: PropTypes.object,
    visualVerification: PropTypes.bool,
}

AddressCard.defaultProps = {
    classes: {},
    visualVerification: false,
}

export default withStyles(styles)(AddressCard)
