import {
    Button,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { formValues, reduxForm } from "redux-form"
import CloseIcon from "@material-ui/icons/Close"
import { connect } from "react-redux"
import Spinner from "../../../util/spinner"
import { goFetch } from "../../../../http"
import { useSnackbarContext } from "../../../../context/providers/snackbarProvider"
import { retrieveUser, userReceived } from "../../../../actions/user"
import BrokerForm from "./BrokerForm"
import { addBrokerValidator } from "../../../redesign/bookShipment/validators"

const styles = theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
        overflowY: "scroll",
    },
    section: {
        paddingTop: "28px",
    },
})

const AddBroker = ({
    classes,
    location,
    setDialogOpen,
    cities = [],
    country,
    handleSubmit,
    updateUserStore,
    editMode,
    activeBroker,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const { openSnackbar } = useSnackbarContext()

    const deleteBroker = async () => {
        setIsLoading(true)
        try {
            await goFetch(
                `/locations/${location?._id}/broker/${activeBroker?.id}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            const user = await retrieveUser()
            updateUserStore(user)
            openSnackbar(
                "success",
                <FormattedMessage
                    id="locations.addBroker__deleteSuccess"
                    defaultMessage="Broker deleted"
                />
            )
            setDialogOpen(false)
        } catch (err) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="locations.addBroker__error"
                    defaultMessage="Error"
                />
            )
        } finally {
            setIsLoading(false)
        }
    }

    const addBroker = async values => {
        setIsLoading(true)
        try {
            await goFetch(
                editMode
                    ? `/locations/${location?._id}/broker/${activeBroker?.id}`
                    : `/locations/${location?._id}/broker`,
                {
                    method: editMode ? "PUT" : "POST",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                    data: values,
                },
                true
            )
            const user = await retrieveUser()
            updateUserStore(user)
            openSnackbar(
                "success",
                editMode ? (
                    <FormattedMessage
                        id="locations.addBroker__editSuccess"
                        defaultMessage="Broker successfully edited"
                    />
                ) : (
                    <FormattedMessage
                        id="locations.addBroker__addSuccess"
                        defaultMessage="Broker successfully added"
                    />
                )
            )
            setDialogOpen(false)
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="locations.addBroker__error"
                    defaultMessage="Error"
                />
            )
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Grid className={classes.paper}>
            {isLoading ? <Spinner /> : null}
            <form onSubmit={handleSubmit(addBroker)}>
                <Grid item container justify="space-between">
                    <Grid item>
                        <Typography variant="headline">
                            {editMode ? (
                                <FormattedMessage
                                    id="locations.addBrokerModal__titleEdit"
                                    defaultMessage="Edit Broker"
                                />
                            ) : (
                                <FormattedMessage
                                    id="locations.addBrokerModal__titleAdd"
                                    defaultMessage="Add Broker"
                                />
                            )}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setDialogOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <BrokerForm cities={cities} country={country} />
                <Grid
                    item
                    container
                    className={classes.section}
                    justify={editMode ? "space-between" : "flex-end"}
                >
                    {editMode ? (
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={() => {
                                deleteBroker()
                            }}
                        >
                            <FormattedMessage
                                id="generalTerms__delete"
                                defaultMessage="Delete"
                            />
                        </Button>
                    ) : null}
                    {editMode ? (
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            <FormattedMessage
                                id="generalTerms__update"
                                defaultMessage="Update"
                            />
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                        >
                            <FormattedMessage
                                id="generalTerms__confirm"
                                defaultMessage="Confirm"
                            />
                        </Button>
                    )}
                </Grid>
            </form>
        </Grid>
    )
}

const mapStateToProps = (
    state,
    { postalCode, country, editMode, activeBroker }
) => {
    return {
        cities: state[`postalCode[${country}-${postalCode}].cities`],
        initialValues: editMode ? activeBroker : {},
    }
}

const mapDispatchToProps = dispatch => ({
    updateUserStore: user => dispatch(userReceived(user)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withStyles(styles)(
        reduxForm({
            form: "addBroker", // a unique identifier for this form
            enableReinitialize: true,
            validate: addBrokerValidator,
        })(
            formValues({
                postalCode: "address.postalCode",
                country: "address.country",
            })(AddBroker)
        )
    )
)
