import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { get } from "lodash"
import { withStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import PlaceIcon from "@material-ui/icons/Place"
import PersonIcon from "@material-ui/icons/Person"
import CircularProgress from "@material-ui/core/CircularProgress"
import { isValidCountryPostalCode } from "../../../actions/validation"
import { highlightTerm } from "../../util"
import { getCurrentShippingAddress } from "../../../actions/quote"
import { FormattedMessage } from "react-intl"
import { getContactKey } from "../../../actions/contact"

const styles = theme => ({
    paper: {
        maxHeight: 500,
        minWidth: 450,
        overflow: "scroll",
        padding: "12px",
    },
    suggestion__item__city: {
        cursor: "pointer",
        padding: "3px 0px",
        "&:hover": {
            "background-color": theme.palette.secondary.light,
        },
    },
    suggestion__item__address: {
        cursor: "pointer",
        padding: "3px 0px",
        "&:hover": {
            "background-color": theme.palette.secondary.light,
        },
    },
    suggestion__line: {
        margin: "0 7px",
    },
    suggestion__line__last: {
        "margin-left": "auto",
        "margin-right": "3px",
    },
})

const SuggestionItem = ({ item, onClick, prefix, classes, setChosen, key }) => {
    const { postalCode, city, state } = item.address || {}
    const { name } = item
    const { name: contactName } = item.contact || {}

    return (
        <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justify="flex-start"
            className={classes.suggestion__item__address}
            onClick={() => {
                setChosen()
                onClick(item)
            }}
            role="button"
            tabIndex="-1"
            dataTestId={`suggestionBoxMenuOption-${key}`}
        >
            <Grid item container alignItems="center" xs={1}>
                <PersonIcon />
            </Grid>
            <Grid item container alignItems="center" xs={11}>
                {name && (
                    <Typography className={classes.suggestion__line}>
                        {highlightTerm(prefix, name)}
                    </Typography>
                )}
                {contactName && (
                    <Typography className={classes.suggestion__line}>
                        {highlightTerm(prefix, contactName)}
                    </Typography>
                )}
                <Typography
                    className={classes.suggestion__line__last}
                    variant="caption"
                >
                    {city}, {state} {highlightTerm(prefix, postalCode)}
                </Typography>
            </Grid>
        </Grid>
    )
}

SuggestionItem.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    prefix: PropTypes.string.isRequired,
}

export class SuggestionBoxPresentation extends Component {
    chosen = false

    componentWillUnmount() {
        const {
            cities,
            addresses,
            field,
            state,
            country,
            setTopResult,
            term,
        } = this.props

        if (!this.chosen) {
            if (cities.length > 0) {
                setTopResult(cities[0], "city", field, state, country, term)
            } else if (addresses.length > 0) {
                setTopResult(addresses[0], "address", field)
            } else return
        } else return
    }

    setChosen = () => {
        this.chosen = true
    }

    render() {
        const {
            addresses,
            cities,
            state,
            country,
            handleAddressSelect,
            handleCitySelect,
            term,
            classes,
            searchInProgress,
        } = this.props
        const isZip = isValidCountryPostalCode(term, country)

        if (term.length < 3) return null
        return (
            <Paper className={classes.paper}>
                <Grid container direction="column" wrap="nowrap">
                    <Grid item container direction="row">
                        <Grid item xs={10}>
                            {" "}
                            {
                                <Typography variant="subheading">
                                    <FormattedMessage
                                        id="orderDetails.suggestion__title"
                                        defaultMessage="Showing Results for {term}"
                                        values={{
                                            term,
                                        }}
                                    />
                                </Typography>
                            }{" "}
                        </Grid>
                        <Grid item xs={2}>
                            {" "}
                            {searchInProgress && (
                                <CircularProgress size={20} color="secondary" />
                            )}{" "}
                        </Grid>
                    </Grid>
                    {cities.length > 0 && (
                        <div>
                            {cities.map((item, i) => (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    className={classes.suggestion__item__city}
                                    onClick={() => {
                                        this.setChosen()
                                        handleCitySelect(
                                            item,
                                            state,
                                            country,
                                            term
                                        )
                                    }}
                                    key={i}
                                    role="button"
                                    tabIndex="-1"
                                >
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        xs={1}
                                    >
                                        <PlaceIcon />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        alignItems="center"
                                        xs={11}
                                    >
                                        <Typography
                                            className={classes.suggestion__line}
                                        >
                                            {item}, {state}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    )}
                    {addresses.length > 0 && (
                        <div>
                            {addresses.map((item, i) => {
                                return (
                                    <SuggestionItem
                                        setChosen={this.setChosen}
                                        key={i}
                                        item={item}
                                        onClick={handleAddressSelect}
                                        prefix={term}
                                        classes={classes}
                                    />
                                )
                            })}
                        </div>
                    )}
                    {addresses.length === 0 && !isZip && !searchInProgress && (
                        <Typography color="secondary">
                            <FormattedMessage
                                id="generalTerms__noResults"
                                defaultMessage="No results found"
                            />
                        </Typography>
                    )}
                </Grid>
            </Paper>
        )
    }
}

SuggestionBoxPresentation.propTypes = {
    addresses: PropTypes.array,
    cities: PropTypes.array,
    state: PropTypes.string,
    country: PropTypes.string,
    handleAddressSelect: PropTypes.func.isRequired,
    term: PropTypes.string.isRequired,
}

SuggestionBoxPresentation.defaultProps = {
    addresses: [],
    cities: [],
    state: "",
    country: "",
}

const checkShippingAddressMatch = (state, term, country) => {
    if (!term || term.length < 3) return []
    const { cpg } = state.search
    const { companyName: name, id, cpgCode, ...address } =
        getCurrentShippingAddress(state, cpg) || {}
    const shippingAddress = { id, name, address }
    if (address?.country !== country) {
        return []
    }
    if (get(shippingAddress, "address.postalCode", "").startsWith(term))
        return [shippingAddress]
    const regex = new RegExp(`\\b${term}`, "i")
    if (regex.test(get(shippingAddress, "name", ""))) return [shippingAddress]
    return []
}

const mapStateToProps = (reduxState, { addressesNeeded, term, country }) => {
    const key = getContactKey(term, country)
    const retVal = {}
    if (addressesNeeded) {
        retVal.addresses = checkShippingAddressMatch(reduxState, term, country)
        retVal.addresses = retVal.addresses.concat(
            get(reduxState, `contact.search.quote.${key}`, [])
        )
    }

    const { cities, state } = reduxState.postalCode[`${country}-${term}`] || {}
    return { ...retVal, cities, state }
}

export const SuggestionBox = withStyles(styles)(
    connect(mapStateToProps)(SuggestionBoxPresentation)
)

SuggestionBox.propTypes = {
    term: PropTypes.string.isRequired,
    addressesNeeded: PropTypes.bool,
}

SuggestionBox.defaultProps = {
    addressesNeeded: true,
}
