import React from "react"
import PropTypes from "prop-types"

export const ValidatedField = props => {
    const errorMessage = props.name.split(" ").reduce((prev, item) => {
        prev = prev.concat((props.validation && props.validation[item]) || "")
        return prev
    }, "")
    return (
        <div
            className={`${props.className} validated-field ${
                errorMessage ? "validation-error" : ""
            }`}
        >
            {errorMessage && props.displayMessage && (
                <div className="errorMessage">{errorMessage}</div>
            )}
            {props.children}
        </div>
    )
}

ValidatedField.propTypes = {
    validation: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    displayMessage: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

ValidatedField.defaultProps = {
    displayMessage: false,
    className: "",
}
