import React, { Component } from "react"
import { Field } from "redux-form"
import { Grid, Typography } from "@material-ui/core"
import FormSwitch from "../../form/form-switch"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"

class Permissions extends Component {
    calculateDisabled = editable => {
        if (this.props.defaultMode) {
            return false
        }
        if (this.props.editMode) {
            if (this.props.checkEditable && !editable) {
                return true
            }
            return false
        }
        return true
    }

    createFormName = fieldName => {
        if (this.props.prefix) {
            return `${this.props.prefix}.${fieldName}`
        }
        return fieldName
    }

    render() {
        const { classes, gridSize, information, me } = this.props

        return (
            <Grid
                item
                container
                xs={gridSize}
                direction="column"
                className={classes.location__section}
            >
                <Typography className={classes.title} variant="title">
                    <FormattedMessage
                        id="locations.permissions__title"
                        defaultMessage="Views/Permissions"
                    />
                </Typography>
                {me ? (
                    <Typography variant="subheading">
                        <FormattedMessage
                            id="locations.permissions__selfTitle"
                            defaultMessage="I am able to..."
                        />
                    </Typography>
                ) : (
                    <Typography variant="subheading">
                        <FormattedMessage
                            id="locations.permissions__userTitle"
                            defaultMessage="Allow this user to..."
                        />
                    </Typography>
                )}
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__viewRateAmountsAndInvoices"
                                defaultMessage="View rate amounts and invoices"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__no"
                                    defaultMessage="No"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.viewRateAmounts.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.viewRateAmounts.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__yes"
                                    defaultMessage="Yes"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__createARate"
                                defaultMessage="Create a rate"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__no"
                                    defaultMessage="No"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.createRates.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.createRates.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__yes"
                                    defaultMessage="Yes"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__createAShipment"
                                defaultMessage="Create a shipment"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__no"
                                    defaultMessage="No"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.createShipments.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.createShipments.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="generalTerms__yes"
                                    defaultMessage="Yes"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__viewAllRates"
                                defaultMessage="View all carrier rates by other users"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="locations.permissions__self"
                                        defaultMessage="Self"
                                    />
                                </Typography>
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.viewAllRates.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.viewAllRates.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__all"
                                    defaultMessage="All"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__viewAllShipments"
                                defaultMessage="View all shipments booked with this location"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__self"
                                    defaultMessage="Self"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.viewAllShipments.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.viewAllShipments.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__all"
                                    defaultMessage="All"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__viewAllContacts"
                                defaultMessage="View all contacts associated with this location"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__self"
                                    defaultMessage="Self"
                                />
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.viewAllContacts.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.viewAllContacts.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="locations.permissions__all"
                                    defaultMessage="All"
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    alignItems="center"
                    justify="space-between"
                >
                    <Grid item container xs={8} alignItems="center">
                        <Typography>
                            <FormattedMessage
                                id="locations.permissions__viewAllItems"
                                defaultMessage="View all items associated with this location"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="locations.permissions__self"
                                        defaultMessage="Self"
                                    />
                                </Typography>
                            </Typography>
                        </Grid>
                        <Field
                            name={this.createFormName(
                                "permissions.viewAllItems.value"
                            )}
                            component={FormSwitch}
                            color="primary"
                            centered
                            disabled={this.calculateDisabled(
                                get(
                                    information,
                                    "permissions.viewAllItems.editable",
                                    true
                                )
                            )}
                        />
                        <Grid item container xs={2} justify="center">
                            <Typography variant="caption">
                                <Typography variant="caption">
                                    <FormattedMessage
                                        id="locations.permissions__all"
                                        defaultMessage="All"
                                    />
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default Permissions
