import moment from "moment"
import { get, clone, mergeWith } from "lodash"
import { SubmissionError } from "redux-form"
import { goFetch } from "../http"
import { addShipment, populateTrackForm } from "./track"
import { invalidateItemList } from "./item"
import { requestContactAddresses } from "./address"
import { changePath } from "./index"
import { cleanupObject } from "../misc"
import {
    updateUserHazMatPreferences,
    updateUserCustomsBrokeragePreferences,
    trackGAEvent,
    trackGAEventRR,
} from "./user"
import { generateInitialValues } from "../components/documents/certificateOfOrigin/selectors"
import {
    associateDocumentsToShipment,
    createFile,
} from "../context/providers/CustomsProfileProvider"

export const SAVE_SHIPMENT_ERROR = "SAVE_SHIPMENT_ERROR"
export const CANCEL_SHIPMENT_ERROR = "CANCEL_SHIPMENT_ERROR"
export const CANCEL_SHIPMENT = "CANCEL_SHIPMENT"

export const saveShipmentError = error => ({ type: SAVE_SHIPMENT_ERROR, error })
export const cancelShipmentError = error => ({
    type: CANCEL_SHIPMENT_ERROR,
    error,
})

const GAcreateShipmentActions = {
    CONSIGNEE: "Consignee",
    THIRD_PARTY: "Third Party",
    SHIPPER: "Shipper",
}

const contactPayload = ({ name, phone, email } = {}) =>
    cleanupObject({ name, phone, email })

const addressPayload = ({
    postalCode,
    street1,
    street2,
    city,
    state,
    country,
} = {}) => cleanupObject({ postalCode, street1, street2, city, state, country })

const timePayload = (time, date) => {
    if (!time) return moment(date).format("HH:mm")
    return moment(time, "HH:mm A").format("HH:mm")
}

function endpointPayload(endpoint) {
    return cleanupObject({
        _id: endpoint._id,
        contactId: get(endpoint, "contact._id"),
        name: get(endpoint, "address.name"),
        address: addressPayload(endpoint.address),
        contact: contactPayload(endpoint.contact),
        appointment: get(endpoint, "pickupLater")
            ? undefined
            : contactPayload(endpoint.pickup),
        note: endpoint.note,
        accessorials: endpoint.accessorials,
        date: moment.utc(endpoint.startDate).format("YYYY-MM-DD"),
        startTime: timePayload(endpoint.readyTime, endpoint.startDate),
        endTime: timePayload(
            endpoint.closeTime,
            endpoint.endDate || endpoint.startDate
        ),
        isSaveAddress: !!(endpoint.isSaveAddress || endpoint._id),
        taxIdentification: endpoint.taxIdentification,
    })
}

function transformAlerts(alerts, selectedRecipients) {
    const result = {}
    result.share = []

    result.preferences = alerts.user
    selectedRecipients.forEach(recipient => {
        const preferences = alerts[recipient.label]
        const preferencesWithoutLanguage = clone(preferences)
        delete preferencesWithoutLanguage?.language
        result.share.push({
            name: recipient.label,
            email: recipient.value.value,
            language: preferences?.language,
            preferences: preferencesWithoutLanguage,
        })
    })

    return result
}

function createShipmentPayload(book, selectedRate) {
    return cleanupObject({
        origin: endpointPayload(book.origin),
        destination: endpointPayload(book.destination),
        carrier: book?.rate?.carrierCode,
        cf7512: book.cf7512,
        isPickupLater: book?.origin?.pickupLater ?? false,
        ...book?.identifiers,
        handlingUnits: book?.handlingUnits,
        alternateQuoteIndex: selectedRate.alternateQuote,
        shipMode: book?.rate?.mode,
        direction: book?.rate?.direction,
        appointment: book?.appointment && contactPayload(book?.appointment),
        emergency: book.hazmatEmergency,
        exportCustomsBrokerageInfo: book.exportCustomsBrokerageInfo,
        importCustomsBrokerageInfo: book.importCustomsBrokerageInfo,
        alerts: transformAlerts(book.alerts, book.selectedRecipients),
        commercialInvoice: book?.requiresCommercialInvoice
            ? book.commercialInvoice
            : null,
        signatureImage: book?.signatureImage ?? null,
        itemComments: book.itemComments,
        purposeOfShipment: book.purposeOfShipment,
    })
}

export function updateAlertsPreferences(shipmentId, formName) {
    return async (dispatch, getState) => {
        const { alerts, selectedRecipients } = getState().form[formName].values
        const payload = transformAlerts(alerts, selectedRecipients)
        return goFetch(
            `/alert/${shipmentId}`,
            {
                method: "PUT",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
                data: payload,
            },
            true
        )
    }
}

function internalCreateShipment(book, selectedRate) {
    return async dispatch => {
        if (!selectedRate) throw new Error("No rate selected")
        const { data: shipment } = await goFetch(
            "/shipments",
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: createShipmentPayload(book, selectedRate),
            },
            true
        )
        dispatch(requestContactAddresses())
        dispatch(addShipment(shipment))
        return shipment
    }
}

function internalCancelShipment(shipmentId) {
    return async dispatch => {
        const { data: shipment } = await goFetch(
            `/shipments/${shipmentId}/cancel`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            },
            true
        )
        return shipment
    }
}

export function createCertificateOfOrigin(shipmentId, partialValues) {
    return async (dispatch, getState) => {
        try {
            const state = getState()
            const initialValues = generateInitialValues(state, { shipmentId })
            const certificateOfOriginLineItems = mergeWith(
                [],
                initialValues?.certificateOfOriginLineItems ?? [],
                partialValues?.certificateOfOriginLineItems,
                (objValue, srcValue) =>
                    srcValue === null || srcValue === undefined
                        ? objValue
                        : srcValue
            )
            const finalValues = {
                ...initialValues,
                ...partialValues,
                certificateOfOriginLineItems,
            }
            await goFetch(
                `/documents/generated/certificateOfOrigin`,
                {
                    data: finalValues,
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                },
                true
            )
            await dispatch(populateTrackForm(shipmentId))
        } catch (error) {
            console.error("Could not generate certificate of origin: ", error)
        }
    }
}

export const createAndAssociateDocuments = (
    locationId,
    selectedDocuments,
    shipmentId
) => {
    return async dispatch => {
        await Promise.all(
            selectedDocuments
                ?.filter(x => !x.documentId)
                ?.map(async document => {
                    const values = {
                        internalTrackingNumber: shipmentId,
                        ...document,
                    }
                    return await createFile(document?.documentCategory, values)
                })
        )
        const allDocuments = selectedDocuments?.filter(x => x.documentId)
        await associateDocumentsToShipment(locationId, allDocuments, shipmentId)
        await dispatch(populateTrackForm(shipmentId))
    }
}

export function createShipment(book, props) {
    return async dispatch => {
        try {
            const { direction, carrierCode } = book?.rate ?? {}
            const trackAction = GAcreateShipmentActions[direction]
            const gaValue = ["FXFE", "FXNL"].includes(carrierCode) ? 1 : 0
            dispatch(
                trackGAEvent(
                    "Create Shipment",
                    trackAction,
                    `${carrierCode}, ${book.cpg}`,
                    gaValue
                )
            )
            dispatch(
                trackGAEventRR(
                    "Legacy Rates",
                    "Book Shipment Pre-Fire",
                    `Carrier: ${carrierCode}, Direction: ${direction}`
                )
            )
            await Promise.all([
                dispatch(updateUserHazMatPreferences(book.hazmatEmergency)),
                dispatch(
                    updateUserCustomsBrokeragePreferences(
                        book.exportCustomsBrokerageInfo,
                        book.importCustomsBrokerageInfo
                    )
                ),
            ])
            dispatch(
                trackGAEventRR(
                    "Legacy Rates",
                    "Book Shipment Success",
                    `Carrier: ${carrierCode}, Direction: ${direction}`
                )
            )
            const selectedRate = props?.computedMatch?.params
            const shipmentResult = await dispatch(
                internalCreateShipment(book, selectedRate)
            )
            if (book?.selectedCustomsDocuments?.length) {
                await dispatch(
                    createAndAssociateDocuments(
                        props?.location?._id,
                        book?.selectedCustomsDocuments,
                        book?.identifiers?.internalTrackingNumber
                    )
                )
            }
            dispatch(invalidateItemList())
            if (shipmentResult?.response?.message?.errorMessage) {
                throw new Error(shipmentResult?.response?.message?.errors?.[0])
            } else return shipmentResult
        } catch (error) {
            let errorMessage = ""
            if (error?.response?.message?.errors?.[0]) {
                const errorObj = error?.response?.message?.errors?.[0] ?? {}
                if (errorObj.diagnostic) {
                    errorMessage = errorObj.diagnostic
                } else if (errorObj.message) {
                    errorMessage = errorObj.message
                }
            } else if (error?.response?.diagnostic) {
                errorMessage = error?.response?.diagnostic ?? "Error"
            } else if (error?.response?.message) {
                errorMessage = error?.response?.diagnostic ?? "Error"
            }
            dispatch(
                trackGAEventRR(
                    "Legacy Rates",
                    "Book Shipment Error",
                    errorMessage
                )
            )
            throw new SubmissionError({
                _error: errorMessage ?? "",
            })
        }
    }
}

export function cancelShipment(shipment, identifiers) {
    return async dispatch => {
        try {
            const { direction, carrierCode } = get(shipment, "rate", {})
            const trackAction = GAcreateShipmentActions[direction]
            const gaValue = ["FXFE", "FXNL"].includes(carrierCode) ? 1 : 0
            dispatch(
                trackGAEvent(
                    "Cancel Shipment",
                    trackAction,
                    `${carrierCode}, ${shipment.cpg}`,
                    gaValue
                )
            )
            return await dispatch(
                internalCancelShipment(identifiers.internalTrackingNumber)
            )
        } catch (error) {
            dispatch(cancelShipmentError(error))
            return error
        }
    }
}

export function selectShipment(identifiers) {
    return dispatch => {
        const { internalTrackingNumber: shipmentId } = identifiers
        dispatch(changePath(`/track/${shipmentId}`, true, false))
    }
}
