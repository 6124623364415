import React from "react"
import Grid from "@material-ui/core/Grid"
import WarningIcon from "@material-ui/icons/Warning"
import { withStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import { get } from "lodash"
import moment from "moment"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    alert__box__container: {
        backgroundColor: "#D4D4D4",
        padding: "5px",
        borderBottom: "1px solid #A9A9A9",
    },
    dismiss__button: {
        "&:hover": {
            cursor: "pointer",
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    whiteSpace: {
        whiteSpace: "pre-wrap",
    },
})

const AlertBox = ({ classes, dismissAlert, event, shipmentId, proNumber }) => (
    <Grid
        container
        className={classes.alert__box__container}
        alignItems="center"
    >
        <Grid item container xs={1} justify="flex-start">
            <WarningIcon />
        </Grid>
        <Grid item container xs={2} justify="flex-start">
            {proNumber && <Typography>{`#${proNumber}`}</Typography>}
        </Grid>
        <Grid
            item
            container
            xs={8}
            alignItems="center"
            className={classes.whiteSpace}
        >
            {get(event, "created_at") && (
                <Typography variant="caption">{`${moment(
                    get(event, "created_at")
                ).format("dddd MMM-Do h:mm A")}: `}</Typography>
            )}
            <Typography>{get(event, "description")}</Typography>
        </Grid>
        {!get(event, "dismissed") && (
            <Grid item container xs={1} alignItems="center" justify="flex-end">
                <Typography
                    color="primary"
                    className={classes.dismiss__button}
                    onClick={() => dismissAlert(shipmentId, get(event, "_id"))}
                >
                    <FormattedMessage
                        id="alertBox__dismiss"
                        defaultMessage="Dismiss"
                    />
                </Typography>
            </Grid>
        )}
    </Grid>
)

export default withStyles(styles)(AlertBox)
