import React, { Component } from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import { get } from "lodash"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Field } from "redux-form"
import FormCheckbox from "../../form/form-checkbox"
import { FormattedMessage } from "react-intl"

class Carriers extends Component {
    render() {
        const {
            dialogOpen,
            location,
            owner,
            handleClickOpen,
            classes,
            onboardingModalContinue,
            handleClose,
            carrierList,
        } = this.props
        const relevantCarriers =
            location.carriers == null
                ? []
                : location.carriers.filter(it => it.carrier !== "FXFE")
        return (
            <Grid item container direction="column">
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    alignContent="center"
                    className={classes.location__card__header}
                >
                    <Grid item container alignItems="center" xs={4}>
                        <Typography gutterBottom variant="title">
                            <FormattedMessage
                                id="locations.carriers__title"
                                defaultMessage="Carriers"
                            />
                        </Typography>
                    </Grid>
                    {owner && (
                        <Grid
                            item
                            container
                            xs={8}
                            alignItems="center"
                            justify="flex-end"
                        >
                            <Button
                                onClick={() => handleClickOpen("add")}
                                color="primary"
                                id="addCarrierButton"
                            >
                                <FormattedMessage
                                    id="locations.carriers__addCarrier"
                                    defaultMessage="Add Carrier"
                                />
                            </Button>
                        </Grid>
                    )}
                </Grid>
                {/*{carriers.length < 1 && <Grid item container justify="center"><CircularProgress size={48} color="secondary" /></Grid>}*/}
                {relevantCarriers.map((carrier, index) =>
                    owner ? (
                        <Grid
                            item
                            container
                            className={
                                index === relevantCarriers.length - 1
                                    ? classes.location__row__last
                                    : classes.location__row
                            }
                            alignItems="center"
                            key={index}
                            onClick={() =>
                                handleClickOpen(
                                    "edit",
                                    carrier.carrier,
                                    carrier.accountId
                                )
                            }
                        >
                            <Typography color="inherit">
                                {" "}
                                {get(
                                    carrierList,
                                    ["carriers", carrier.carrier, "name"],
                                    ""
                                )}{" "}
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid
                            item
                            container
                            className={
                                index === relevantCarriers.length - 1
                                    ? classes.location__row__last__noHover
                                    : classes.location__row__noHover
                            }
                            alignItems="center"
                            key={index}
                        >
                            <Typography color="inherit">
                                {" "}
                                {get(
                                    carrierList,
                                    ["carriers", carrier.carrier, "name"],
                                    ""
                                )}{" "}
                            </Typography>
                        </Grid>
                    )
                )}
                <Dialog open={dialogOpen} onClose={handleClose}>
                    <DialogTitle id="alert-dialog-title">
                        <FormattedMessage
                            id="locations.carriers__redirectNotice"
                            defaultMessage="Redirect Notice"
                        />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormattedMessage
                                id="locations.carriers__redirectDetails"
                                defaultMessage="LTL Select connects shippers to LTL carriers. This platform is provided by FedEx but run by a third party.  To enter your other carriers, you will be taken to project44 (p44).  FedEx does not see your accounts or transactions with any other carriers."
                            />
                        </DialogContentText>
                        <Field
                            name="formDNA"
                            component={FormCheckbox}
                            label={
                                <FormattedMessage
                                    id="locations.carriers__doNotAsk"
                                    defaultMessage="Do not ask me again"
                                />
                            }
                            containerStyle={classes.checkbox__container}
                            enableSwitch
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" autoFocus>
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button
                            onClick={() => onboardingModalContinue()}
                            variant="contained"
                            color="primary"
                            autoFocus
                        >
                            <FormattedMessage
                                id="generalTerms__continue"
                                defaultMessage="Continue"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

export default Carriers
