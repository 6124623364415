import React from "react"
import PropTypes from "prop-types"
import ReactTable from "react-table"
import Select from "react-select"
import { Button, Checkbox, OverlayTrigger, Tooltip } from "react-bootstrap"
import FontAwesome from "react-fontawesome"
import { connect } from "react-redux"
import { LineItemControl, SubInputControl } from "../item"
import {
    packageTypes,
    freightClasses,
    hazMatPkgGrp,
    provideHazMatContainers,
    provideHazMatClasses,
} from "../item/line-item"
import { ItemSmart } from "../item/progressive-item-search"
import { updateLineItem, showSearchItems } from "../../actions/quote-item"
import { injectIntl } from "react-intl"

export const validateStrictOnly = item => (item.strict ? item.list : {})

export const LineItemTablePresentation = props => {
    const {
        deleteRow,
        editable,
        data,
        onChange,
        onItemSelect,
        quoteMode,
        bolMode,
        isVolume,
        totalLinearFeet,
        isHazMat,
        onBrowseClick,
        onColumnBlur,
        intl,
    } = props
    const packageType = {
        Header: `${bolMode ? "Pkg" : "* Package"} Type`,
        accessor: "packageType",
        className: editable && "overflow-visible",
        Cell: rowInfo =>
            editable ? (
                <LineItemControl
                    field="packageType"
                    name="Package Type"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        options: packageTypes,
                        clearable: false,
                        className: "search-select package-type",
                    }}
                />
            ) : (
                rowInfo.value
            ),
        minWidth: 80,
    }

    const freightClass = {
        Header: `${isVolume || bolMode ? "" : "* "}Class`,
        accessor: "freightClass",
        className: editable && "overflow-visible",
        Cell: rowInfo =>
            editable ? (
                <LineItemControl
                    field="freightClass"
                    name="Class"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: isVolume ? -1 : 0,
                        options: freightClasses,
                        clearable: false,
                        className: "search-select freight-class",
                        disabled: isVolume,
                    }}
                />
            ) : (
                !isVolume && rowInfo.value
            ),
        minWidth: 55,
    }

    const pieces = {
        Header: "Pieces",
        accessor: "pieces",
        Cell: rowInfo =>
            bolMode ? (
                <LineItemControl
                    field="pieces"
                    name="Pieces"
                    placeholder="Pieces"
                    onChange={onChange}
                    rowInfo={rowInfo}
                    controlProps={{
                        min: 0,
                        tabIndex: 0,
                        type: "number",
                        onBlur: () => onColumnBlur("Pieces"),
                    }}
                />
            ) : (
                rowInfo.value
            ),
        minWidth: 55,
    }

    const handlingUnits = {
        Header: "* H/U",
        accessor: "count",
        Cell: rowInfo =>
            editable ? (
                <LineItemControl
                    field="count"
                    name="H/U"
                    placeholder="H/U"
                    controlProps={{
                        tabIndex: 0,
                        type: "number",
                    }}
                    rowInfo={rowInfo}
                    onChange={onChange}
                />
            ) : (
                rowInfo.value
            ),
        minWidth: 40,
    }

    const description = {
        Header: "* Description",
        accessor: "description",
        Cell: rowInfo =>
            editable ? (
                <div className="row-stretch">
                    <LineItemControl
                        field="description"
                        name="Description"
                        Control={ItemSmart}
                        onChange={(field, e) =>
                            onChange(field, e, rowInfo.index)
                        }
                        rowInfo={rowInfo}
                        textLength={200}
                        controlProps={{
                            tabIndex: 0,
                            onSelect: item => onItemSelect(item, rowInfo.index),
                        }}
                    />
                    <Button
                        bsStyle="link"
                        onClick={() => onBrowseClick(rowInfo.index)}
                    >
                        <FontAwesome
                            name="external-link"
                            alt="Browse items"
                            title="Browse items"
                        />
                    </Button>
                </div>
            ) : (
                rowInfo.value
            ),
        minWidth: 150,
    }

    const dimensions = {
        Header: `${isVolume ? "" : "* "}Dimensions (in)`,
        id: "dimensions",
        accessor: row => `${row.length}x${row.width}x${row.height}`,
        Cell: rowInfo =>
            editable ? (
                <div className="row">
                    {[
                        {
                            field: "length",
                            canBePrefilled: true,
                            max: 179,
                        },
                        {
                            field: "width",
                            canBePrefilled: true,
                            max: 93,
                        },
                        {
                            field: "height",
                            canBePrefilled: false,
                            max: 106,
                        },
                    ].map(({ field, canBePrefilled, max }) => (
                        <LineItemControl
                            field={field}
                            name={`${field[0].toUpperCase()}${field.slice(1)}`}
                            placeholder={`${field[0].toUpperCase()}${field.slice(
                                1
                            )}`}
                            onChange={onChange}
                            value={rowInfo.original[field]}
                            key={field}
                            rowInfo={rowInfo}
                            controlProps={{
                                min: 0,
                                max,
                                tabIndex:
                                    (canBePrefilled &&
                                        rowInfo.original.sizeLocked) ||
                                    isVolume
                                        ? -1
                                        : 0,
                                type: "number",
                                readOnly:
                                    (canBePrefilled &&
                                        rowInfo.original.sizeLocked) ||
                                    isVolume,
                            }}
                        />
                    ))}
                </div>
            ) : (
                !isVolume && rowInfo.value
            ),
        ...(!editable &&
            totalLinearFeet > 0 && {
                footer: () => <div>Total Linear Feet: {totalLinearFeet}</div>,
            }),
        minWidth: bolMode ? 120 : 125,
    }

    const nmfc = {
        id: "nmfc",
        Header: "NMFC",
        accessor: element =>
            element.nmfc ? `${element.nmfc1}-${element.nmfc2}` : "",
        Cell: rowInfo =>
            bolMode ? (
                <div tabIndex="-1" onBlur={() => onColumnBlur("NMFC")}>
                    <LineItemControl
                        field="nmfc1"
                        name="NMFC-1"
                        placeholder="xxxxxx"
                        onChange={onChange}
                        controlProps={{
                            className: "nmfc",
                            maxLength: 6,
                        }}
                        rowInfo={rowInfo}
                        value={rowInfo.original.nmfc1}
                        outerStyle="nmfc-container nmfc-outer"
                        elementStyle="nmfc-container"
                        controlStyle="nmfc-container"
                    />
                    -
                    <LineItemControl
                        field="nmfc2"
                        name="NMFC-2"
                        placeholder="xx"
                        onChange={onChange}
                        controlProps={{
                            className: "nmfc",
                            maxLength: 2,
                        }}
                        rowInfo={rowInfo}
                        value={rowInfo.original.nmfc2}
                        outerStyle="nmfc-container nmfc-outer"
                        elementStyle="nmfc-container"
                        controlStyle="nmfc-container"
                    />
                </div>
            ) : (
                rowInfo.value
            ),
        minWidth: 100,
    }

    const weight = {
        Header: "* Weight",
        accessor: "weight",
        Cell: rowInfo =>
            editable ? (
                <LineItemControl
                    field="weight"
                    name="Weight"
                    placeholder="Weight"
                    onChange={(fld, e, index) => {
                        if (e.target.value.toString().length <= 5) {
                            onChange(
                                fld,
                                {
                                    target: {
                                        value: e.target.value,
                                    },
                                },
                                index
                            )
                        }
                    }}
                    rowInfo={rowInfo}
                    controlProps={{
                        min: 0,
                        tabIndex: 0,
                        type: "number",
                    }}
                />
            ) : (
                rowInfo.value
            ),
        minWidth: 60,
    }

    const hazMat = {
        Header: () => (
            <div className="text-center">
                {"HazMat"}
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="important">
                            <div>
                                <p>{"Hazardous Materials."}</p>
                                <p>{"You will need:"}</p>
                                <ul>
                                    <li>{"UN *"}</li>
                                    <li>{"Hazmat class"}</li>
                                    <li>{"Packaging group"}</li>
                                </ul>
                            </div>
                        </Tooltip>
                    }
                >
                    <FontAwesome
                        className="left-margin"
                        name="question-circle"
                    />
                </OverlayTrigger>
            </div>
        ),
        accessor: "isHazMat",
        Cell: rowInfo => (
            <Checkbox
                checked={rowInfo.value}
                field="isHazMat"
                name="Haz"
                className="text-center"
                disabled={!editable}
                onChange={e =>
                    onChange(
                        "isHazMat",
                        {
                            target: {
                                value: e.target.checked,
                            },
                        },
                        rowInfo.index
                    )
                }
            />
        ),
        width: 70,
    }

    const editableHazMat = {
        Header: () => (
            <div className="text-center">
                {"HazMat"}
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="important">
                            <div>
                                <p>{"Hazardous Materials."}</p>
                                <p>{"You will need:"}</p>
                                <ul>
                                    <li>{"UN *"}</li>
                                    <li>{"Hazmat class"}</li>
                                    <li>{"Packaging group"}</li>
                                </ul>
                            </div>
                        </Tooltip>
                    }
                >
                    <FontAwesome
                        className="left-margin"
                        name="question-circle"
                    />
                </OverlayTrigger>
            </div>
        ),
        accessor: "isHazMat",
        expander: true,
        Cell: rowInfo => (
            <div className="text-center">
                <Checkbox
                    checked={rowInfo.original.isHazMat}
                    field="isHazMat"
                    name="Haz"
                    className="text-center"
                    onChange={e =>
                        onChange(
                            "isHazMat",
                            {
                                target: {
                                    value: e.target.checked,
                                },
                            },
                            rowInfo.index
                        )
                    }
                />
            </div>
        ),
        width: 70,
    }

    const unNumber = {
        Header: "* UN #",
        accessor: "unNumber",
        Cell: rowInfo =>
            rowInfo.original.isHazMat ? (
                <LineItemControl
                    field="unNumber"
                    name="UN #"
                    placeholder={0}
                    onChange={onChange}
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        maxLength: 4,
                    }}
                />
            ) : (
                ""
            ),
        minWidth: 100,
    }

    const pkgGrp = {
        Header: "* Pkg. Grp.",
        accessor: "pkgGrp",
        className: bolMode && "overflow-visible",
        Cell: rowInfo =>
            rowInfo.original.isHazMat ? (
                <LineItemControl
                    field="pkgGrp"
                    name="Pkg Grp"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        options: hazMatPkgGrp,
                        clearable: false,
                        className: "search-select pkg-grp",
                    }}
                />
            ) : (
                ""
            ),
        minWidth: 100,
    }

    const hazMatClass = {
        Header: "* Haz Class",
        accessor: "hazClass",
        className: bolMode && "overflow-visible",
        Cell: rowInfo =>
            rowInfo.original.isHazMat ? (
                <LineItemControl
                    field="hazClass"
                    name="Haz Class"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        options: provideHazMatContainers(intl),
                        clearable: false,
                        className: "search-select haz-class",
                    }}
                />
            ) : (
                ""
            ),
        minWidth: 100,
    }

    let columns
    if (editable) {
        columns = [
            editableHazMat,
            description,
            freightClass,
            weight,
            handlingUnits,
            packageType,
            dimensions,
        ]
    } else if (quoteMode) {
        columns = [
            hazMat,
            description,
            freightClass,
            weight,
            handlingUnits,
            packageType,
            dimensions,
        ]
    } else {
        columns = [
            pieces,
            handlingUnits,
            packageType,
            description,
            hazMat,
            dimensions,
            nmfc,
            weight,
            freightClass,
        ]
    }
    if (bolMode && isHazMat) {
        columns = [
            pieces,
            handlingUnits,
            packageType,
            description,
            hazMat,
            dimensions,
            nmfc,
            weight,
            freightClass,
            unNumber,
            pkgGrp,
            hazMatClass,
        ]
    }

    if (deleteRow || editable) {
        columns.push({
            Header: "Actions",
            headerStyle: { textAlign: "-webkit-center" },
            minWidth: 50,
            Cell: rowInfo => {
                const row = rowInfo.original
                const validation = (row.validation && row.validation.list) || {}
                const valid = row.isDefault
                    ? null
                    : Object.keys(validation).length === 0
                return (
                    <div className="text-center">
                        {editable && valid !== null ? (
                            <FontAwesome
                                className={valid ? "color-green" : "color-red"}
                                name={valid ? "check-circle" : "minus-circle"}
                            />
                        ) : (
                            <FontAwesome
                                className="color-white"
                                name="check-circle"
                            />
                        )}
                        {deleteRow && data.length > 1 && (
                            <Button
                                className="no-border"
                                tabIndex="-1"
                                onClick={() => deleteRow(rowInfo.index)}
                            >
                                <FontAwesome
                                    name="window-close"
                                    alt="Remove row"
                                    title="Remove row"
                                />
                            </Button>
                        )}
                    </div>
                )
            },
        })
    }

    const SubComponent = rowInfo => (
        <div className="row align-center left-padding">
            <div>{"(Optional)"}</div>
            <div className="row left-padding">
                <SubInputControl
                    elementStyle="sub-un-number"
                    field="unNumber"
                    name="* UN #"
                    Control={ItemSmart}
                    onChange={(field, e) => onChange(field, e, rowInfo.index)}
                    value={rowInfo.original.unNumber}
                    key="unNumber"
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        onSelect: item => onItemSelect(item, rowInfo.index),
                    }}
                />
                <SubInputControl
                    elementStyle="sub-hazmat-dropdown left-padding"
                    field="pkgGrp"
                    name="* Pkg Grp"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    value={rowInfo.original.pkgGrp}
                    key="pkgGrp"
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        options: hazMatPkgGrp,
                        clearable: false,
                        className: "search-select pkg-grp",
                    }}
                />
                <SubInputControl
                    elementStyle="sub-hazmat-dropdown left-padding"
                    field="hazClass"
                    name="* Haz Class"
                    Control={Select}
                    onChange={(fld, e, index) =>
                        onChange(fld, { target: e }, index)
                    }
                    value={rowInfo.original.hazClass}
                    key="hazClass"
                    rowInfo={rowInfo}
                    controlProps={{
                        tabIndex: 0,
                        options: provideHazMatClasses(intl),
                        clearable: false,
                        className: "search-select haz-class",
                    }}
                />
            </div>
            <div className="flex-two" />
        </div>
    )

    const expandedRows = data.reduce(
        (prev, item, index) =>
            item.isHazMat ? { ...prev, [index]: true } : prev,
        {}
    )

    return (
        <div className={isHazMat ? "haz-mat-table" : ""}>
            {editable ? (
                <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={4}
                    showPagination={false}
                    className="overflow-visible"
                    SubComponent={SubComponent}
                    expandedRows={expandedRows}
                    pageSize={data.length}
                    pageSizeOptions={[10]}
                    getTheadProps={() => ({
                        className: "align-left",
                    })}
                    getTableProps={() => ({
                        className: "overflow-visible",
                    })}
                    getNoDataProps={() => ({
                        style: { display: "none" },
                    })}
                    getTfootProps={() => ({
                        className: "table-footer",
                    })}
                />
            ) : (
                <ReactTable
                    data={data}
                    columns={columns}
                    defaultPageSize={4}
                    showPagination={false}
                    pageSize={Math.min(10, data.length)}
                    getTheadProps={() => ({
                        className: "align-left",
                    })}
                    getTableProps={() =>
                        isHazMat
                            ? {
                                  className: "overflow-visible",
                              }
                            : {}
                    }
                    getTfootProps={() => ({
                        className: "table-footer",
                    })}
                />
            )}
        </div>
    )
}

LineItemTablePresentation.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    deleteRow: PropTypes.func,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    onItemSelect: PropTypes.func.isRequired,
    onBrowseClick: PropTypes.func.isRequired,
    quoteMode: PropTypes.bool,
    bolMode: PropTypes.bool,
    isVolume: PropTypes.bool,
    totalLinearFeet: PropTypes.number,
    isHazMat: PropTypes.bool,
    onColumnBlur: PropTypes.func,
}

LineItemTablePresentation.defaultProps = {
    deleteRow: null,
    editable: false,
    onChange: null,
    quoteMode: false,
    bolMode: false,
    isVolume: false,
    totalLinearFeet: 0,
    isHazMat: false,
    onColumnBlur: () => {},
}

const mapDispatchToProps = dispatch => ({
    onItemSelect: (item, index) => dispatch(updateLineItem(item, index)),
    onBrowseClick: index => dispatch(showSearchItems(index)),
})

export const LineItemTable = injectIntl(
    connect(undefined, mapDispatchToProps)(LineItemTablePresentation)
)
