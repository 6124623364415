import React, { Fragment } from "react"
import {
    Typography,
    withStyles,
    Grid,
    CardHeader,
    CardContent,
    Card,
} from "@material-ui/core"
import { connect } from "react-redux"
import {
    change,
    Field,
    FieldArray,
    FormSection,
    formValues,
    reduxForm,
    reset,
} from "redux-form"
import { defineMessages, FormattedMessage, injectIntl } from "react-intl"
import FormField from "../../form/form-field"
import {
    countriesOfOrigin,
    provideLocaleSortedOptionsList,
} from "../../../misc"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import FormSwitch from "../../form/form-switch"
import { formattedPurposeOfShipment } from "../../book/SupportDocumentationForm"
import {
    formattedDutiesPayableType,
    formattedNaftaStatusType,
    formattedTermsOfSale,
    formattedUnitOfMeasuresType,
} from "../../book/CommercialInvoiceForm"
import {
    countryPhoneValidator,
    emailValidator,
    isNumeric,
    isRequired,
    isRequiredIf,
    matchesPattern,
} from "../../../actions/validation"
import { combineValidators, composeValidators } from "revalidate"
import DocumentActions from "../DocumentActions"
import ManageSignature from "../ManageSignature"
import {
    weightUnitPluralCapital,
    weightUnitSingularCapital,
} from "../../util/units"
import ContactDetails from "../ContactDetails"

const styles = theme => ({
    switchUscma: {
        paddingTop: "2%",
    },
    cardWithDropdown: {
        overflow: "visible",
    },
})

const CommercialInvoiceForm = props => {
    const { onSubmit, onBackClick, handleSubmit, submitting } = props

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container spacing={16}>
                <Grid item xs={6}>
                    <ShipmentDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <ExporterDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <ConsigneeDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <ImporterDetailsCard />
                </Grid>

                <Grid item xs={12}>
                    <BrokerDetailsCard />
                </Grid>
                <Grid item xs={12}>
                    <HandlingUnitsDetailsCard />
                </Grid>
                <Grid item xs={12}>
                    <CostsDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <USMCADetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <SignatureDetailsCard />
                </Grid>
                <DocumentActions
                    onBackClick={onBackClick}
                    submitting={submitting}
                    documentType={props.formTitle}
                />
            </Grid>
        </form>
    )
}

const mapHandlingUnit = (handlingUnits = [], unitOfMass) => {
    let commercialInvoiceLineItems = []
    handlingUnits.forEach(hu =>
        hu.items.forEach(item => {
            commercialInvoiceLineItems.push({
                description: item?.description,
                numberOfPackages: hu?.count,
                numberOfUnits: item?.unit ?? item?.pieces,
                unitOfMeasure: item?.unitOfMeasure ?? "PCS",
                netWeight: {
                    amount:
                        (item?.weight ?? 0) * (item?.unit ?? item?.pieces ?? 1),
                    unitOfMass,
                },
                countryOfManufacture: item?.countryOfManufacture,
                unitValue: item?.unitPrice,
                harmonizedTariffNumber: item?.harmonizedCode,
            })
        })
    )
    return commercialInvoiceLineItems
}

const generateInitialValues = (
    shipment,
    preferredSystemOfMeasurement,
    preferredCurrencyCode,
    previousDocument
) => {
    if (previousDocument?.documentFields) {
        return previousDocument.documentFields
    }

    const unitOfMass = weightUnitSingularCapital(preferredSystemOfMeasurement)

    const commercialInvoiceLineItems = mapHandlingUnit(
        shipment?.handlingUnits,
        unitOfMass
    )

    return {
        currencyCode: preferredCurrencyCode,
        preferredSystemOfMeasurement,
        unitOfMass,
        partiesRelated: false,
        importerIsConsignee: false,
        usmcaDeclaration: false,
        internalTrackingNumber: shipment?.identifiers?.internalTrackingNumber,
        trackingNumber: shipment?.identifiers?.proNumber,
        orderNumber: shipment?.identifiers?.orderNumber,
        shipDate: shipment?.shipment?.origin?.date,
        billOfLading: shipment?.identifiers?.bolNumber,
        exporterInfo: {
            name: shipment?.shipment?.origin?.name,
            address: { ...shipment?.shipment?.origin?.address },
            contact: {
                ...shipment?.shipment?.origin?.contact,
            },
        },
        consigneeInfo: {
            name: shipment?.shipment?.destination?.name,
            address: { ...shipment?.shipment?.destination?.address },
            contact: {
                ...shipment?.shipment?.destination?.contact,
            },
        },
        designatedBrokerInfo: {
            ...shipment?.shipment?.importCustomsBrokerageInfo,
        },
        commercialInvoiceLineItems,
        totalGrossWeight: {
            amount: commercialInvoiceLineItems.reduce(
                (previousValue, currentValue) =>
                    previousValue + (currentValue?.netWeight?.amount ?? 0),
                0
            ),
            unitOfMass,
        },
    }
}

const mapStateToProps = (state, props) => {
    let activeShipment = state.shipment.list[props.shipmentId]
    let locations = state?.user?.profile?.locations
    let location = locations.find(
        loc => loc.cpgCode === activeShipment?.query?.cpg
    )

    let preferredSystemOfMeasurement =
        activeShipment?.query?.preferredSystemOfMeasurement ?? "IMPERIAL"

    let preferredCurrencyCode =
        activeShipment?.shipment?.rate?.preferredCurrencyCode

    let initialValues = generateInitialValues(
        activeShipment,
        preferredSystemOfMeasurement,
        preferredCurrencyCode,
        props.previousDocument
    )

    return {
        bol: activeShipment?.identifiers?.bolNumber,
        trackingNumber: activeShipment?.identifiers?.proNumber,
        shipmentLocation: location,
        initialValues,
        preferredSystemOfMeasurement,
        preferredCurrencyCode,
        commercialInvoiceLineItems: initialValues?.commercialInvoiceLineItems,
    }
}

const messages = defineMessages({
    nameField: {
        id: "generalTerms__contactName",
        defaultMessage: "Contact Name",
    },
    phoneField: {
        id: "generalTerms__contactPhone",
        defaultMessage: "Contact Phone",
    },
    emailField: {
        id: "generalTerms__contactEmail",
        defaultMessage: "Contact Email",
    },
})

const isRequiredIfImporterIsNotConsignee = isRequiredIf(
    values => values?.importerIsConsignee === false
)

const itemValidation = {
    "commercialInvoiceLineItems[].numberOfPackages": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "commercialInvoice.no__of__packages",
            defaultMessage: "No. of Packages:",
        },
    }),
    "commercialInvoiceLineItems[].numberOfUnits": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "commercialInvoice.no__of__units",
            defaultMessage: "No. Of Units:",
        },
    }),
    "commercialInvoiceLineItems[].unitOfMeasure": isRequired({
        field: {
            id: "commercialInvoice.unit__of__measure",
            defaultMessage: "Unit of Measure:",
        },
    }),
    "commercialInvoiceLineItems[].netWeight.amount": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "commercialInvoice.net__weight",
            defaultMessage: "Net Weight ({preferredSystemOfMeasurement}):",
            values: {
                preferredSystemOfMeasurement: "",
            },
        },
    }),
    "commercialInvoiceLineItems[].countryOfManufacture": isRequired({
        field: {
            id: "commercialInvoice.country__of__manufacture",
            defaultMessage: "Country of Manufacture:",
        },
    }),
    "commercialInvoiceLineItems[].unitValue": composeValidators(isRequired)({
        field: {
            id: "commercialInvoice.items__unitPrice",
            defaultMessage: "Unit Price ({preferredCurrencyCode}):",
            values: {
                preferredCurrencyCode: "",
            },
        },
    }),
    "commercialInvoiceLineItems[].harmonizedTariffNumber": composeValidators(
        matchesPattern(/^\d{4}\.\d{2}(\.\d{2}(\d{2})?)?$/)
    )({
        field: {
            id: "commercialInvoice.harmonized__tariff__number",
            defaultMessage: "Harmonized Tariff Number :",
        },
    }),
}

const formValidation = combineValidators({
    partiesRelated: isRequired({
        field: {
            id: "commercialInvoice.parties",
            defaultMessage: "Parties To Transaction Related",
        },
    }),
    importerIsConsignee: isRequired({
        field: {
            id: "commercialInvoice.importer__is__consignee",
            defaultMessage: "Same as CONSIGNEE:",
        },
    }),
    usmcaDeclaration: isRequired({
        field: {
            id: "commercialInvoice.usmca__declaration",
            defaultMessage: "USMCA Low Value Declaration Statement",
        },
    }),
    usmcaDeclarationRole: isRequiredIf(values => values?.usmcaDeclaration)({
        field: {
            id: "commercialInvoice.usmca__value__role",
            defaultMessage: "USMCA Status",
        },
    }),
    trackingNumber: isRequired({
        field: {
            id: "commercialInvoice.tracking",
            defaultMessage: "Air Waybill No. / Tracking No.:",
        },
    }),
    designatedBrokerInfo: {
        name: isRequired({
            field: {
                id: "commercialInvoice.broker_name",
                defaultMessage: "Name of Broker:",
            },
        }),
        contact: {
            phone: {
                phone_number: composeValidators(
                    isRequired,
                    countryPhoneValidator("consigneeInfo.address.country")
                )({
                    field: messages.phoneField,
                }),
            },
            name: isRequired({
                field: {
                    id: "commercialInvoice.broker_contact__name",
                    defaultMessage: "Contact Name:",
                },
            }),
        },
    },
    shipmentPurpose: {
        shipmentPurposeType: isRequired({
            field: {
                id: "commercialInvoice.select_purpose",
                defaultMessage: "Select Purpose Of Shipment:",
            },
        }),
    },
    dutiesAndTaxesPayor: {
        dutiesAndTaxesPayorType: isRequired({
            field: {
                id: "commercialInvoice.duties_payable_by",
                defaultMessage: "Duties Payable By:",
            },
        }),
        otherDescription: composeValidators(
            isRequiredIf(values =>
                dutiesPayableByOther(
                    values?.dutiesAndTaxesPayor?.dutiesAndTaxesPayorType
                )
            )
        )({
            field: {
                id: "commercialInvoice.duties_payable_by_others",
                defaultMessage: "If Duties Payable By Other, please specify:",
            },
        }),
    },
    termsOfSale: {
        termsOfSaleType: isRequired({
            field: {
                id: "commercialInvoice.terms_of__sale",
                defaultMessage: "Terms Of Sale:",
            },
        }),
    },
    exporterInfo: {
        taxIdentification: {
            taxIdentificationType: isRequiredIf(
                values => values?.exporterInfo?.taxIdentification?.number
            )({
                field: {
                    id: "commercialInvoice.select__tax__type",
                    defaultMessage: "Select Tax Identification Type",
                },
            }),
            number: isRequiredIf(
                values =>
                    values?.exporterInfo?.taxIdentification
                        ?.taxIdentificationType
            )({
                field: {
                    id: "commercialInvoice.tax__id",
                    defaultMessage: "Tax ID#:",
                },
            }),
        },
        name: isRequired({
            field: {
                id: "commercialInvoice.company__name",
                defaultMessage: "Company Name:",
            },
        }),
        address: {
            street1: isRequiredIf(
                values => !values?.exporterInfo?.address?.street2
            )({
                field: {
                    id: "commercialInvoice.address1",
                    defaultMessage: "Address 1:",
                },
            }),
            street2: isRequiredIf(
                values => !values?.exporterInfo?.address?.street1
            )({
                field: {
                    id: "commercialInvoice.address2",
                    defaultMessage: "Address 2:",
                },
            }),
            postalCode: isRequired({
                field: {
                    id: "commercialInvoice.zipPostalCode",
                    defaultMessage: "Zip/Postal Code:",
                },
            }),
            city: isRequired({
                field: {
                    id: "commercialInvoice.city",
                    defaultMessage: "City:",
                },
            }),
            country: isRequired({
                field: {
                    id: "commercialInvoice.country",
                    defaultMessage: "Country:",
                },
            }),
            state: isRequired({
                field: {
                    id: "commercialInvoice.state",
                    defaultMessag: "State/Province:",
                },
            }),
        },
        contact: {
            name: isRequired({
                field: {
                    id: "commercialInvoice.broker_contact__name",
                    defaultMessage: "Contact Name:",
                },
            }),
            phone: {
                phone_number: composeValidators(
                    isRequired,
                    countryPhoneValidator("exporterInfo.address.country")
                )({
                    field: messages.phoneField,
                }),
            },
            email: {
                email_address: composeValidators(
                    isRequired,
                    emailValidator
                )({
                    field: messages.emailField,
                }),
            },
        },
    },
    consigneeInfo: {
        taxIdentification: {
            taxIdentificationType: isRequiredIf(
                values => values?.consigneeInfo?.taxIdentification?.number
            )({
                field: {
                    id: "commercialInvoice.select__tax__type",
                    defaultMessage: "Select Tax Identification Type",
                },
            }),
            number: isRequiredIf(
                values =>
                    values?.consigneeInfo?.taxIdentification
                        ?.taxIdentificationType
            )({
                field: {
                    id: "commercialInvoice.tax__id",
                    defaultMessage: "Tax ID#:",
                },
            }),
        },
        name: isRequired({
            field: {
                id: "commercialInvoice.company__name",
                defaultMessage: "Company Name:",
            },
        }),
        address: {
            street1: isRequiredIf(
                values => !values?.consigneeInfo?.address?.street2
            )({
                field: {
                    id: "commercialInvoice.address1",
                    defaultMessage: "Address 1:",
                },
            }),
            street2: isRequiredIf(
                values => !values?.consigneeInfo?.address?.street1
            )({
                field: {
                    id: "commercialInvoice.address2",
                    defaultMessage: "Address 2:",
                },
            }),
            postalCode: isRequired({
                field: {
                    id: "commercialInvoice.zipPostalCode",
                    defaultMessage: "Zip/Postal Code:",
                },
            }),
            city: isRequired({
                field: {
                    id: "commercialInvoice.city",
                    defaultMessage: "City:",
                },
            }),
            country: isRequired({
                field: {
                    id: "commercialInvoice.country",
                    defaultMessage: "Country:",
                },
            }),
            state: isRequired({
                field: {
                    id: "commercialInvoice.state",
                    defaultMessag: "State/Province:",
                },
            }),
        },
        contact: {
            name: isRequired({
                field: {
                    id: "commercialInvoice.broker_contact__name",
                    defaultMessage: "Contact Name:",
                },
            }),
            phone: {
                phone_number: composeValidators(
                    isRequired,
                    countryPhoneValidator("consigneeInfo.address.country")
                )({
                    field: messages.phoneField,
                }),
            },
            email: {
                email_address: composeValidators(
                    isRequired,
                    emailValidator
                )({
                    field: messages.emailField,
                }),
            },
        },
    },
    importerInfo: {
        taxIdentification: {
            taxIdentificationType: isRequiredIf(
                values => values?.importerInfo?.taxIdentification?.number
            )({
                field: {
                    id: "commercialInvoice.select__tax__type",
                    defaultMessage: "Select Tax Identification Type",
                },
            }),
            number: isRequiredIf(
                values =>
                    values?.importerInfo?.taxIdentification
                        ?.taxIdentificationType
            )({
                field: {
                    id: "commercialInvoice.tax__id",
                    defaultMessage: "Tax ID#:",
                },
            }),
        },
        name: isRequired({
            field: {
                id: "commercialInvoice.company__name",
                defaultMessage: "Company Name:",
            },
        }),
        address: {
            street1: isRequiredIf(
                values =>
                    !values?.importerInfo?.address?.street2 &&
                    values?.importerIsConsignee === false
            )({
                field: {
                    id: "commercialInvoice.address1",
                    defaultMessage: "Address 1:",
                },
            }),
            street2: isRequiredIf(
                values =>
                    !values?.importerInfo?.address?.street1 &&
                    values?.importerIsConsignee === false
            )({
                field: {
                    id: "commercialInvoice.address2",
                    defaultMessage: "Address 2:",
                },
            }),
            postalCode: isRequiredIfImporterIsNotConsignee({
                field: {
                    id: "commercialInvoice.zipPostalCode",
                    defaultMessage: "Zip/Postal Code:",
                },
            }),
            city: isRequiredIfImporterIsNotConsignee({
                field: {
                    id: "commercialInvoice.city",
                    defaultMessage: "City:",
                },
            }),
            country: isRequiredIfImporterIsNotConsignee({
                field: {
                    id: "commercialInvoice.country",
                    defaultMessage: "Country:",
                },
            }),
            state: isRequiredIfImporterIsNotConsignee({
                field: {
                    id: "commercialInvoice.state",
                    defaultMessag: "State/Province:",
                },
            }),
        },
    },
    totalGrossWeight: {
        amount: composeValidators(
            isRequired,
            isNumeric
        )({
            field: {
                id: "commercialInvoice.gross__weight",
                defaultMessage:
                    "Total Gross Weight ({preferredSystemOfMeasurement}):",
                values: {
                    preferredSystemOfMeasurement: "",
                },
            },
        }),
    },
    ...itemValidation,
})

const dutiesPayableByOther = duties_payable_by => duties_payable_by === "OTHER"

const mapDispatchToProps = dispatch => ({
    resetForm: () => dispatch(reset("commercialInvoiceForm")),
    changeField: (field, value) =>
        dispatch(change("commercialInvoiceForm", field, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "commercialInvoiceForm",
        enableReinitialize: true,
        validate: formValidation,
    })(CommercialInvoiceForm)
)

const USMCADetailsCard = withStyles(styles)(
    formValues("usmcaDeclaration")(({ classes, usmcaDeclaration }) => {
        return (
            <Card>
                <CardHeader
                    title={
                        <FormattedMessage
                            id="commercialInvoice.USMCA__tittle"
                            defaultMessage="USMCA Declaration Statement"
                        />
                    }
                />
                <CardContent>
                    <Grid item container>
                        <Grid item container xs={6}>
                            <Grid
                                item
                                container
                                className={classes.switchUscma}
                            >
                                <Field
                                    component={FormSwitch}
                                    name="usmcaDeclaration"
                                    label={
                                        <FormattedMessage
                                            id="commercialInvoice.usmca__declaration"
                                            defaultMessage="USMCA Low Value Declaration Statement"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                        {usmcaDeclaration && (
                            <Grid item container xs={6}>
                                <Field
                                    component={FormSelectAutocomplete}
                                    name={"usmcaDeclarationRole"}
                                    options={formattedNaftaStatusType}
                                    label={
                                        <FormattedMessage
                                            id="commercialInvoice.usmca__value__role"
                                            defaultMessage="USMCA Status"
                                        />
                                    }
                                    required={usmcaDeclaration}
                                />
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        )
    })
)

const CostsDetailsCard = formValues("currencyCode")(({ currencyCode }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="commercialInvoice.cost__tittle"
                    defaultMessage="Commercial Invoice Costs"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <Grid item container sm={12} md={2}>
                    <Field
                        name="insuranceCharge"
                        type="number"
                        component={FormField}
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.insurance_charge"
                                defaultMessage="Insurance Charge ({currencyCode}):"
                                values={{
                                    currencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
                <Grid item container sm={12} md={2}>
                    <Field
                        name="freightCharge"
                        type="number"
                        component={FormField}
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.freight_charge"
                                defaultMessage="Freight Charge ({currencyCode}):"
                                values={{
                                    currencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
                <Grid item container sm={12} md={2}>
                    <Field
                        name="packingCharge"
                        type="number"
                        component={FormField}
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.packing__charge"
                                defaultMessage="Packing Charge ({currencyCode}):"
                                values={{
                                    currencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
                <Grid item container sm={12} md={2}>
                    <Field
                        name="handlingCharge"
                        type="number"
                        component={FormField}
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.handling__charge"
                                defaultMessage="Handling Charge ({currencyCode}):"
                                values={{
                                    currencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
                <Grid item container xs={2}>
                    <Field
                        name="otherCharge"
                        type="number"
                        component={FormField}
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.other__charge"
                                defaultMessage="Other Charge ({currencyCode}):"
                                values={{
                                    currencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
))

const BrokerDetailsCard = () => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="commercialInvoice.broker__tittle"
                    defaultMessage="Broker"
                />
            }
            subheader={
                <FormattedMessage
                    id="commercialInvoice.broker__details__tittle"
                    defaultMessage="If there is a designated broker for this shipment, please provide contact information."
                />
            }
        />
        <CardContent>
            <Grid item container>
                <Grid item container sm={12} md={4}>
                    <Field
                        name={`designatedBrokerInfo.name`}
                        component={FormField}
                        type="text"
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.broker_name"
                                defaultMessage="Name of Broker:"
                            />,
                        ]}
                        required
                    />
                </Grid>
                <Grid item container sm={12} md={4}>
                    <Field
                        name={`designatedBrokerInfo.contact.phone.phone_number`}
                        component={FormField}
                        type="text"
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.broker_phone"
                                defaultMessage="Tel. No.:"
                            />,
                        ]}
                        required
                    />
                </Grid>
                <Grid item container sm={12} md={4}>
                    <Field
                        name={`designatedBrokerInfo.contact.name`}
                        component={FormField}
                        type="text"
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.broker_contact__name"
                                defaultMessage="Contact Name:"
                            />,
                        ]}
                        required
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

const HandlingUnitForm = formValues("description")(
    injectIntl(
        ({
            intl,
            description,
            weightUnit,
            preferredSystemOfMeasurement,
            preferredCurrencyCode,
        }) => (
            <Fragment>
                <Grid item container>
                    <Typography variant="subheading" gutterBottom>
                        <FormattedMessage
                            id="commercialInvoice.item__description"
                            defaultMessage="{itemName} item information:"
                            values={{
                                itemName: description,
                            }}
                        />
                    </Typography>
                </Grid>

                <Grid item container md={2}>
                    <Field
                        name="numberOfPackages"
                        type="number"
                        required={true}
                        component={FormField}
                        label={
                            <FormattedMessage
                                id="commercialInvoice.no__of__packages"
                                defaultMessage="No. of Packages:"
                            />
                        }
                    />
                </Grid>
                <Grid item container md={2}>
                    <Field
                        name="numberOfUnits"
                        type="number"
                        required
                        component={FormField}
                        label={
                            <FormattedMessage
                                id="commercialInvoice.no__of__units"
                                defaultMessage="No. Of Units:"
                            />
                        }
                    />
                </Grid>

                <Grid item container md={2}>
                    <Field
                        component={FormSelectAutocomplete}
                        name="unitOfMeasure"
                        required
                        options={formattedUnitOfMeasuresType}
                        label={
                            <FormattedMessage
                                id="commercialInvoice.unit__of__measure"
                                defaultMessage="Unit of Measure:"
                            />
                        }
                    />
                </Grid>
                <Grid item container md={2}>
                    <Field
                        name="netWeight.amount"
                        type="number"
                        required
                        component={FormField}
                        label={
                            <FormattedMessage
                                id="commercialInvoice.net__weight"
                                defaultMessage="Net Weight ({preferredSystemOfMeasurement}):"
                                values={{
                                    preferredSystemOfMeasurement: weightUnit,
                                }}
                            />
                        }
                    />
                </Grid>
                <Grid item container md={3}>
                    <Field
                        component={FormSelectAutocomplete}
                        name="countryOfManufacture"
                        required
                        label={
                            <FormattedMessage
                                id="commercialInvoice.country__of__manufacture"
                                defaultMessage="Country of Manufacture:"
                            />
                        }
                        options={provideLocaleSortedOptionsList(
                            intl,
                            countriesOfOrigin,
                            ["US", "CA", "MX"]
                        )}
                    />
                </Grid>
                <Grid item container md={2}>
                    <Field
                        type="number"
                        component={FormField}
                        name="unitValue"
                        required
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.items__unitPrice"
                                defaultMessage="Unit Price ({preferredCurrencyCode}):"
                                values={{
                                    preferredCurrencyCode,
                                }}
                            />,
                        ]}
                    />
                </Grid>
                <Grid item container md={3}>
                    <Field
                        type="string"
                        component={FormField}
                        name="harmonizedTariffNumber"
                        label={[
                            <FormattedMessage
                                id="commercialInvoice.harmonized__tariff__number"
                                defaultMessage="Harmonized Tariff Number :"
                            />,
                        ]}
                    />
                </Grid>
            </Fragment>
        )
    )
)

const HandlingUnitListPresentation = ({ fields, ...props }) => (
    <Fragment>
        {fields.map(prefix => (
            <FormSection name={prefix} key={prefix} component={Fragment}>
                <HandlingUnitForm {...props} />
            </FormSection>
        ))}
    </Fragment>
)

const HandlingUnitsDetailsCard = withStyles(styles)(
    injectIntl(
        formValues({
            preferredSystemOfMeasurement: "preferredSystemOfMeasurement",
            preferredCurrencyCode: "preferredCurrencyCode",
        })(
            ({
                classes,
                preferredSystemOfMeasurement,
                preferredCurrencyCode,
            }) => (
                <Card className={classes.cardWithDropdown}>
                    <CardHeader
                        title={
                            <Typography variant="title">
                                <FormattedMessage
                                    id="commercialInvoice.items__title"
                                    defaultMessage="Items"
                                />
                            </Typography>
                        }
                    />
                    <CardContent>
                        <Grid item container>
                            <FieldArray
                                name="commercialInvoiceLineItems"
                                component={HandlingUnitListPresentation}
                                props={{
                                    preferredSystemOfMeasurement,
                                    preferredCurrencyCode,
                                    weightUnit: weightUnitPluralCapital(
                                        preferredSystemOfMeasurement
                                    ),
                                }}
                            />
                            <Grid item container xs={12}>
                                <Grid item container md={2}>
                                    <Field
                                        name={`totalGrossWeight.amount`}
                                        type="number"
                                        required
                                        component={FormField}
                                        label={
                                            <FormattedMessage
                                                id="commercialInvoice.gross__weight"
                                                defaultMessage="Total Gross Weight ({preferredSystemOfMeasurement}):"
                                                values={{
                                                    preferredSystemOfMeasurement,
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={5}>
                                    <Field
                                        name={`specialInstructions[1]`}
                                        label={
                                            <FormattedMessage
                                                id="commercialInvoice.item__special__instruction"
                                                defaultMessage="Items Special Instructions Line 1:"
                                            />
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={5}>
                                    <Field
                                        name={`specialInstructions[2]`}
                                        label={
                                            <FormattedMessage
                                                id="commercialInvoice.item__special__instruction2"
                                                defaultMessage="Items Special Instructions Line 2:"
                                            />
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            )
        )
    )
)

const SignatureDetailsCard = ({ classes }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="certificateOfOrigin.signature__title"
                    defaultMessage="Signature"
                />
            }
        />
        <CardContent>
            <Grid
                item
                container
                alignItems="flex-start"
                justify="space-between"
                spacing={16}
            >
                <Grid item xs={6}>
                    <ManageSignature
                        fieldName="signatureImage"
                        previewDimensions={{
                            width: 326,
                            height: 26,
                        }}
                        addTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__addSignature"
                                defaultMessage="Add Signature"
                            />
                        }
                        replaceTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__replaceSignature"
                                defaultMessage="Replace Signature"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

const ShipmentDetailsCard = withStyles(styles)(
    formValues({
        dutiesAndTaxesPayorType: "dutiesAndTaxesPayor.dutiesAndTaxesPayorType",
    })(({ classes, dutiesAndTaxesPayorType }) => (
        <Card className={classes.cardWithDropdown}>
            <CardHeader
                title={
                    <FormattedMessage
                        id="commercialInvoice.shipment__tittle"
                        defaultMessage="Shipment Details"
                    />
                }
            />
            <CardContent>
                <Grid container>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name={`shipDate`}
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.ship__date"
                                    defaultMessage="Ship Date:"
                                />,
                            ]}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name={`trackingNumber`}
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.tracking"
                                    defaultMessage="Air Waybill No. / Tracking No.:"
                                />,
                            ]}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name={`billOfLading`}
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.bill__of__lading"
                                    defaultMessage="Bill of Lading:"
                                />,
                            ]}
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name={`purchaseOrderNumber`}
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.purchase__order"
                                    defaultMessage="Purchase Order No.:"
                                />,
                            ]}
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name={`invoiceNumber`}
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.invoice__number"
                                    defaultMessage="Invoice No.:"
                                />,
                            ]}
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            component={FormSelectAutocomplete}
                            name="shipmentPurpose.shipmentPurposeType"
                            options={formattedPurposeOfShipment}
                            required
                            label={
                                <FormattedMessage
                                    id="commercialInvoice.select_purpose"
                                    defaultMessage="Select Purpose Of Shipment:"
                                />
                            }
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            component={FormSelectAutocomplete}
                            name={"dutiesAndTaxesPayor.dutiesAndTaxesPayorType"}
                            options={formattedDutiesPayableType}
                            label={
                                <FormattedMessage
                                    id="commercialInvoice.duties_payable_by"
                                    defaultMessage="Duties Payable By:"
                                />
                            }
                            required
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name="dutiesAndTaxesPayor.otherDescription"
                            label={
                                <FormattedMessage
                                    id="commercialInvoice.duties_payable_by_others"
                                    defaultMessage=" If Duties Payable By Other, please specify:"
                                />
                            }
                            component={FormField}
                            required={dutiesAndTaxesPayorType === "OTHER"}
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            component={FormSelectAutocomplete}
                            name={"termsOfSale.termsOfSaleType"}
                            options={formattedTermsOfSale}
                            required
                            label={
                                <FormattedMessage
                                    id="commercialInvoice.terms_of__sale"
                                    defaultMessage="Terms Of Sale:"
                                />
                            }
                        />
                    </Grid>
                    <Grid item container sm={12} md={6}>
                        <Field
                            name="paymentTerms"
                            label={
                                <FormattedMessage
                                    id="commercialInvoice.payment__terms"
                                    defaultMessage="Payment Terms:"
                                />
                            }
                            component={FormField}
                        />
                    </Grid>
                    <Grid item container>
                        <Field
                            name="specialInstructions[0]"
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.special__instructions"
                                    defaultMessage="Special Instructions:"
                                />,
                            ]}
                        />
                    </Grid>
                    <Grid item container>
                        <Field
                            name="declarationStatements[0]"
                            component={FormField}
                            type="text"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.declaration__statements"
                                    defaultMessage="Declaration Statement(s):"
                                />,
                            ]}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    ))
)

const ImporterDetailsCard = formValues("importerIsConsignee")(
    ({ importerIsConsignee }) => (
        <Card>
            <CardHeader
                title={
                    <FormattedMessage
                        id="commercialInvoice.importer__tittle"
                        defaultMessage="Sold To / Importer (If Different From Consignee)"
                    />
                }
            />
            <CardContent>
                <Grid item container>
                    <Grid item container>
                        <Field
                            component={FormSwitch}
                            name="importerIsConsignee"
                            label={[
                                <FormattedMessage
                                    id="commercialInvoice.importer__is__consignee"
                                    defaultMessage="Same as CONSIGNEE:"
                                />,
                            ]}
                        />
                    </Grid>
                    {!importerIsConsignee && (
                        <FormSection name="importerInfo">
                            <ContactDetails />
                        </FormSection>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
)

const ExporterDetailsCard = () => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="commercialInvoice.exporter__tittle"
                    defaultMessage="Exporter"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <FormSection name="exporterInfo">
                    <ContactDetails includeContact />
                </FormSection>

                <Grid item container sm={12} md={6}>
                    <Field
                        component={FormSwitch}
                        name="partiesRelated"
                        label={[
                            "*",
                            <FormattedMessage
                                id="commercialInvoice.parties"
                                defaultMessage="Parties To Transaction Related"
                            />,
                        ]}
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
)

const ConsigneeDetailsCard = () => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="commercialInvoice.consignee__tittle"
                    defaultMessage="Consignee"
                />
            }
        />
        <CardContent>
            <Grid item container>
                <FormSection name="consigneeInfo">
                    <ContactDetails includeContact />
                </FormSection>
            </Grid>
        </CardContent>
    </Card>
)
