import { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { requestAccessorials } from "../../actions/index"
import { setEditMode, populateQuoteForm, quoteReset } from "../../actions/quote"
import { populateTrackForm } from "../../actions/track"

export class QuoteLoader extends Component {
    componentDidMount() {
        this.props.loadQuote(this.props.shipmentId)
        this.props.handleEditModeChange(this.props.editMode)
        this.props.loadAccessorials()
        try {
            this.props.loadShipment(this.props.shipmentId)
        } catch (error) {
            console.log(error)
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.shipmentId && newProps.editMode !== this.props.editMode) {
            this.props.handleEditModeChange(newProps.editMode)
            if (newProps.shipmentId === this.props.shipmentId) {
                this.props.loadQuote(newProps.shipmentId, newProps.viewMode)
            }
        }
        if (newProps.shipmentId !== this.props.shipmentId) {
            this.props.loadQuote(newProps.shipmentId, newProps.viewMode)
        }
    }

    componentWillUnmount() {
        this.props.resetQuote()
    }

    render() {
        return null
    }
}

QuoteLoader.propTypes = {
    editMode: PropTypes.bool,
    shipmentId: PropTypes.string,
    loadQuote: PropTypes.func.isRequired,
    resetQuote: PropTypes.func.isRequired,
    loadAccessorials: PropTypes.func.isRequired,
    handleEditModeChange: PropTypes.func.isRequired,
}

QuoteLoader.defaultProps = {
    shipmentId: "",
    editMode: false,
}

const mapStateToProps = (state, props) => ({
    shipmentId: props.match.params.shipmentId,
    editMode: props.match.params.mode === "edit",
})

const mapDispatchToProps = dispatch => ({
    loadQuote: shipmentId => dispatch(populateQuoteForm(shipmentId)),
    loadShipment: shipmentId => dispatch(populateTrackForm(shipmentId)),
    resetQuote: () => dispatch(quoteReset()),
    loadAccessorials: () => dispatch(requestAccessorials()),
    handleEditModeChange: mode => dispatch(setEditMode(mode)),
})

export const QuoteLoaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(QuoteLoader)
