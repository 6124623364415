import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Button, withStyles, Typography } from "@material-ui/core"
import { Field, change } from "redux-form"
import Dialog from "@material-ui/core/Dialog"
import Chip from "@material-ui/core/Chip"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { FormattedMessage } from "react-intl"
import AccessorialsField from "../../form/form-select-accessorial"
import Grid from "@material-ui/core/Grid"
import Spinner from "../../util/spinner"
import CancelIcon from "@material-ui/icons/Cancel"
import {
    formattedPriorityDeliveryAccessorials,
    sortByPriority,
    defaultAccessorialLogic,
} from "../accessorials"
import { trackGAEventNew } from "../../../actions/user"

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    paper: {
        minWidth: "650px",
    },
    formControl: {
        width: "100%",
        margin: theme.spacing.unit,
    },
    select__container: {
        width: "100%",
    },
    dialog__container: {},
    select__field: {
        width: "100%",
    },
    chip: {
        margin: "5px 5px",
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "white",
    },
    resiChips: {
        paddingTop: "10px",
    },
    resiCheckDescription2: {
        paddingTop: "15px",
    },
})

const ResidentialAddressDialog = ({
    classes,
    isResiDialogOpen,
    formValues,
    getRateSubmit,
    resiAccessorials,
    changeField,
    resiChips = "",
    currentDeliveryAccessorials = [],
    onIgnore,
    resiChipsPrefix,
    GAEvent,
    currentForm,
}) => {
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (isResiDialogOpen) {
            changeField(
                resiChipsPrefix,
                currentDeliveryAccessorials.concat("RESDEL").join(",")
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isResiDialogOpen])

    useEffect(() => {
        defaultAccessorialLogic(resiChipsPrefix, resiChips, changeField)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resiChips])

    const ignore = async () => {
        GAEvent("Residential Check", `ignore-${currentForm}`)
        setIsLoading(true)
        try {
            await onIgnore(formValues)
        } finally {
            setIsLoading(false)
        }
    }

    const updateRate = async () => {
        GAEvent("Residential Check", `update-${currentForm}`)
        setIsLoading(true)
        try {
            await getRateSubmit({
                ...formValues,
                resiAccessorials: (resiChips ?? "").split(","),
            })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Dialog
            open={isResiDialogOpen}
            onClose={() => {}}
            classes={{
                paper: classes.paper,
            }}
        >
            {isLoading && <Spinner />}
            <DialogTitle id="residential-address-check-dialog-title">
                <FormattedMessage
                    id="getRates__residential_Dialog__title"
                    defaultMessage="Residential Check"
                />
            </DialogTitle>
            <DialogContent>
                <Grid container justify="center" direction="row">
                    <Grid itme container xs={6}>
                        <Grid item container>
                            <Typography>
                                <FormattedMessage
                                    id="accessorials.resiCheckDescription1"
                                    defaultMessage="The destination has been identified as a residential address. Please consider adjusting your accessorial selection to receive accurate rates."
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            className={classes.resiCheckDescription2}
                        >
                            <Typography>
                                <FormattedMessage
                                    id="accessorials.resiCheckDescription2"
                                    defaultMessage="If you choose to add an accessorial, please select 'Update Rate'. To proceed with your previous selection, please select 'Ignore'."
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={6}
                        justify="center"
                        className={classes.select__container}
                    >
                        <Grid item container>
                            <Typography
                                variant="subheading"
                                className={classes.accessorials__select__text}
                            >
                                <FormattedMessage
                                    id="accessorials.adjustDeliveryAccessorials"
                                    defaultMessage="Adjust Delivery Accessorials"
                                />
                            </Typography>
                        </Grid>
                        <Grid item container>
                            <Field
                                component={AccessorialsField}
                                name={"selectedResiAccessorials"}
                                options={resiAccessorials}
                                label={
                                    <FormattedMessage
                                        id="generalTerms__select"
                                        defaultMessage="Select"
                                    />
                                }
                                className={classes.select__field}
                            />
                        </Grid>
                        <Grid item container className={classes.resiChips}>
                            {resiChips.split(",").map((accessorial, index) => (
                                <Grid item container>
                                    {accessorial && (
                                        <Chip
                                            id={`chip-${accessorial}-${index}`}
                                            tabIndex={-1}
                                            label={
                                                (
                                                    resiAccessorials.find(
                                                        it =>
                                                            it?.value ===
                                                            accessorial
                                                    ) || {}
                                                ).label
                                            }
                                            className={classes.chip}
                                            deleteIcon={
                                                <CancelIcon
                                                    id={`delete-chip-${accessorial}-${index}`}
                                                />
                                            }
                                            backgroundColor="#F4F4F4"
                                            onDelete={
                                                ["DOCKDEL", "DOCKPU"].includes(
                                                    accessorial
                                                )
                                                    ? null
                                                    : () =>
                                                          changeField(
                                                              resiChipsPrefix,
                                                              resiChips
                                                                  .split(",")
                                                                  .filter(
                                                                      x =>
                                                                          x !==
                                                                          accessorial
                                                                  )
                                                                  .join(",")
                                                          )
                                            }
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    id="ignoreResidentialCheck"
                    onClick={ignore}
                    color="secondary"
                    variant="outlined"
                    disabled={isLoading}
                >
                    <FormattedMessage
                        id="getRates__ignore"
                        defaultMessage="Ignore"
                    />
                </Button>
                <Button
                    id="updateRateResidentialCheck"
                    onClick={updateRate}
                    color="primary"
                    variant="contained"
                    disabled={resiChips.length === 0 || isLoading}
                >
                    <FormattedMessage
                        id="getRates__updateRate"
                        defaultMessage="Update Rate"
                    />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state, props) => {
    const { currentForm, deliveryAccessorials } = props
    const language = state?.user?.profile?.preferences?.language

    let currentDeliveryAccessorials

    if (typeof deliveryAccessorials === "string") {
        currentDeliveryAccessorials = deliveryAccessorials.split(",")
    } else {
        currentDeliveryAccessorials = deliveryAccessorials
    }

    return {
        resiAccessorials: sortByPriority(
            state?.accessorials?.list?.delivery.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        currentDeliveryAccessorials,
        formValues: state?.form[currentForm]?.values,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    changeField: (field, value) =>
        dispatch(change(props?.currentForm, field, value)),
    GAEvent: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ResidentialAddressDialog))
