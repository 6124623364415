import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import BasicInfoSummary from "../summaryCards/BasicInfoSummary"
import { useIntl, FormattedMessage } from "react-intl"
import FormDatePicker from "../../../form/form-datepicker"
import { Field, reduxForm } from "redux-form"
import FormSelectBillTo from "../../../form/form-select-bill-to"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { nextBusinessDay } from "../selector"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import FormRadio from "../../../form/form-radio"
import { modeOptions } from "../atoms/modeOptions"
import Typography from "@material-ui/core/Typography"
import FormCheckbox from "../../../form/form-checkbox"
import { searchShipmentByProNumber } from "../../../../actions/quote-request"
import SearchIcon from "@material-ui/icons/Search"
import FormField from "../../../form/form-field"
import ProNumberResult from "../../../quotesPage/proNumberResult/proNumberResult"
import { bookShipmentFormValidation } from "../index"
import LocationLabel from "../../../util/LocationLabel"

const useStyles = makeStyles({
    basicInfoContainer: {
        padding: "0 15%",
    },
    cancelButton: {
        marginRight: "10px",
    },
    section: {
        paddingTop: "10px",
    },
    search__icon: {
        fontSize: "40px",
        color: "#4d148c",
        "&:hover": {
            color: "#ff6200",
            cursor: "pointer",
        },
        "&:active": {
            color: "#4d148c",
            cursor: "pointer",
        },
        padding: "3%",
        marginTop: "18px",
    },
    search__icon__disabled: {
        fontSize: "40px",
        padding: "3%",
        marginTop: "18px",
        color: "#BDBDBD",
    },
    text: {
        paddingLeft: "3px",
    },
    locationLabel: {
        paddingLeft: "3px",
    },
})

let BasicInfo = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formattedLocations,
    changeField,
    locations,
    inputProps,
    formValues,
    initialValues,
    invalid,
    trackGA,
    isBookingQuickRate,
    setIsControlledFlow,
}) => {
    const intl = useIntl()
    const classes = useStyles()
    const [isProNumberSearch, setIsProNumberSearch] = useState(false)
    const [shipmentsProNumberSearch, setShipmentsProNumberSearch] = useState({
        error: null,
        result: null,
        loading: false,
    })
    const [isReturning, setIsReturning] = useState(false)
    const [returnsTrackingId, setReturnsTrackingId] = useState(false)

    const {
        proNumber,
        isFreightDirectReturns,
        isFreightDirect,
        isFreightBox,
        mode,
        selectedLocation = {
            pickupAccessorials: [{ value: "DOCKPU" }],
            deliveryAccessorials: [{ value: "DOCKDEL" }],
        },
        shippingContact,
    } = formValues

    const isShipperOnlyFreightDirect =
        selectedLocation.isShipperEnabled &&
        !selectedLocation.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isDoubleAccountFreightDirect =
        selectedLocation.isShipperEnabled &&
        selectedLocation.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT"

    const isDoubleAccountFreightDirectReturns =
        selectedLocation.isShipperEnabled &&
        selectedLocation.is3rdPartyEnabled &&
        selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

    const canSearchPro = proNumber && proNumber?.length >= 3

    //functions
    const searchProNumber = async () => {
        const proNumber = formValues?.proNumber
        if (!proNumber || proNumber.length < 3) return
        setIsProNumberSearch(true)
        trackGA(
            "Book Shipment Flow",
            "Basic Info",
            "Pro Number Search Initiated"
        )
        try {
            setShipmentsProNumberSearch({ loading: true })
            const shipments = await searchShipmentByProNumber(proNumber)
            if (shipments.length > 0) {
                setShipmentsProNumberSearch({
                    result: shipments[0],
                    loading: false,
                })
                changeField("proNumberSearchResult", true)
            } else {
                setShipmentsProNumberSearch({ result: null, loading: false })
                changeField("proNumberSearchResult", false)
            }
        } catch (err) {
            setShipmentsProNumberSearch({ error: err?.message })
            changeField("proNumberSearchResult", false)
        }
    }

    const beforeNextStep = () => {
        if (isBookingQuickRate) {
            if (mode === "inbound") {
                changeField("destination", {
                    ...selectedLocation,
                    contact: shippingContact,
                })
            } else if (mode === "outbound") {
                changeField("origin", {
                    ...selectedLocation,
                    contact: shippingContact,
                })
            }

            return
        }

        if (isFreightDirectReturns || isFreightDirect) {
            changeField("pickupAccessorials", [])
            changeField("deliveryAccessorials", [])
        }

        if (mode === "outbound") {
            changeField(
                "pickupAccessorials",
                selectedLocation?.pickupAccessorials?.map(entry => entry.value)
            )
        } else if (mode === "inbound") {
            changeField(
                "deliveryAccessorials",
                selectedLocation?.deliveryAccessorials?.map(
                    entry => entry.value
                )
            )
        }
    }

    const adjustBasedOnMode = (m, selectedLocation, isFreightDirectReturns) => {
        if (m === "inbound") {
            if (!isFreightDirectReturns) {
                changeField("origin", {
                    shippingAddress: null,
                })
                changeField(
                    "pickupAccessorials",
                    initialValues?.pickupAccessorials
                )
            }

            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
            changeField("destination", {
                ...selectedLocation,
                contact: shippingContact,
            })
        } else if (m === "outbound") {
            if (!initialValues?.shouldDisPlayContact) {
                changeField("destination", {
                    shippingAddress: null,
                })
            } else {
                changeField("destination", {
                    ...initialValues?.destination,
                })
            }

            changeField("pickupAccessorials", initialValues?.pickupAccessorials)
            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
            changeField("origin", {
                ...selectedLocation,
                contact: shippingContact,
            })
        } else if (m === "thirdParty") {
            changeField("origin", { country: initialValues?.origin?.country })
            changeField("destination", {
                country: initialValues?.destination?.country,
            })
            changeField("pickupAccessorials", initialValues?.pickupAccessorials)
            changeField(
                "deliveryAccessorials",
                initialValues?.deliveryAccessorials
            )
        }
    }

    const onModeChange = (m, selectedLocation, isFreightDirectReturns) => {
        setIsControlledFlow(true)
        adjustBasedOnMode(m, selectedLocation, isFreightDirectReturns)
    }

    const onBillToLocationChange = option => {
        if (editMode) {
            setIsControlledFlow(true)
        }

        const selectedLocation = locations.find(
            location => location.cpgCode === option.value
        )

        const isThirdPartyOnly =
            !selectedLocation.isShipperEnabled &&
            selectedLocation.is3rdPartyEnabled

        trackGA(
            "Book Shipment Flow",
            "Basic Info",
            `BillToLocation change - ${selectedLocation?.locationType}`
        )
        const isFreightDirectReturns =
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        const isFreightDirect =
            selectedLocation?.locationType === "FEDEX_DIRECT"

        const isFreightBox =
            selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

        const newMode = isFreightDirectReturns
            ? "inbound"
            : isThirdPartyOnly
            ? "thirdParty"
            : "outbound"

        changeField("selectedLocation", selectedLocation)
        changeField("mode", newMode)
        onModeChange(newMode, selectedLocation, isFreightDirectReturns)
        changeField("isFreightDirect", isFreightDirect)
        changeField("isFreightDirectReturns", isFreightDirectReturns)
        changeField("isFreightBox", isFreightBox)
        changeField("pickupDate", moment.utc(moment().format("YYYY-MM-DD")))

        if (isFreightDirectReturns) {
            changeField("pickupDate", nextBusinessDay())
        } else {
            changeField("proNumberSearchResult", false)
        }
    }

    if (currentStep === 0) {
        return (
            <Grid
                container
                justify="center"
                className={classes.basicInfoContainer}
            >
                <Grid item container justify="center">
                    <Typography id="basicInfoTitle" variant="title">
                        <FormattedMessage
                            id="bookShipment.basicInfo__title"
                            defaultMessage="Tell us about your shipment."
                        />
                    </Typography>
                </Grid>
                <Grid item container className={classes.section}>
                    <Field
                        component={FormSelectBillTo}
                        name="cpg"
                        label={
                            <FormattedMessage
                                id="getRates.form__billToLocation"
                                defaultMessage="Bill to Location"
                            />
                        }
                        onChange={option => {
                            onBillToLocationChange(option)
                        }}
                        options={formattedLocations}
                        billTo
                        InputProps={inputProps}
                    />
                </Grid>
                <Grid item container className={classes.locationLabel}>
                    <LocationLabel
                        isFreightBox={isFreightBox}
                        isFreightDirect={isFreightDirect}
                        isFreightDirectReturns={isFreightDirectReturns}
                    />
                </Grid>
                {isFreightDirect && (
                    <Grid item container className={classes.section}>
                        <Field
                            component={FormCheckbox}
                            name="isFreightDirectReturns"
                            label={
                                <FormattedMessage
                                    id="getRates.form__freightDirectReturns"
                                    defaultMessage="Freight Direct Returns"
                                />
                            }
                            onChange={(e, option) => {
                                if (option) {
                                    changeField("mode", "inbound")
                                    changeField("pickupDate", nextBusinessDay())
                                    onModeChange(
                                        "inbound",
                                        selectedLocation,
                                        option
                                    )
                                } else {
                                    changeField("mode", "outbound")
                                    onModeChange(
                                        "outbound",
                                        selectedLocation,
                                        option
                                    )
                                }
                            }}
                        />
                    </Grid>
                )}
                {isFreightDirectReturns && (
                    <Grid item container direction="row">
                        <Grid xs={11} item container direction="row">
                            <Field
                                component={FormField}
                                name="proNumber"
                                label={
                                    <FormattedMessage
                                        id="getRates.form_searchForReturn"
                                        defaultMessage="Search for a Return (Optional)"
                                    />
                                }
                                placeholder={intl.formatMessage({
                                    id: "getRates.form__proNumber",
                                    defaultMessage: "Tracking Number",
                                })}
                            />
                        </Grid>
                        <Grid
                            xs={1}
                            item
                            container
                            justify="center"
                            alignItems="flex-end"
                        >
                            <SearchIcon
                                id="searchProNumberIcon"
                                className={
                                    canSearchPro
                                        ? classes.search__icon
                                        : classes.search__icon__disabled
                                }
                                onClick={searchProNumber}
                            />
                        </Grid>
                        {isReturning && (
                            <Grid item container className={classes.text}>
                                <Typography
                                    id="returnTrackingID"
                                    color="primary"
                                >
                                    <FormattedMessage
                                        id="bookShipment.basicInfo__returning"
                                        defaultMessage="Return Tracking ID #{returnsTrackingId}"
                                        values={{ returnsTrackingId }}
                                    />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item container className={classes.section}>
                    <Field
                        component={FormDatePicker}
                        name="pickupDate"
                        label={[
                            "* ",
                            <FormattedMessage
                                id="getRates.form__shipDate"
                                defaultMessage="Ship Date"
                            />,
                        ]}
                        placeholder={intl.formatMessage({
                            id: "getRates.form__date",
                            defaultMessage: "Date",
                        })}
                        minDate={
                            isFreightDirectReturns
                                ? nextBusinessDay()
                                : moment.utc(
                                      moment()
                                          .tz("Pacific/Honolulu")
                                          .format("YYYY-MM-DD")
                                  )
                        }
                        maxDate={
                            isFreightDirectReturns
                                ? moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(7, "days")
                                : moment
                                      .utc(
                                          moment()
                                              .tz("Pacific/Honolulu")
                                              .format("YYYY-MM-DD")
                                      )
                                      .add(1, "year")
                        }
                        InputProps={inputProps}
                    />
                </Grid>
                {!isFreightDirectReturns &&
                    !isShipperOnlyFreightDirect &&
                    formValues?.selectedLocation?.isShipperEnabled && (
                        <Grid item container className={classes.section}>
                            <Grid item container>
                                <Field
                                    name="mode"
                                    component={FormRadio}
                                    options={
                                        isDoubleAccountFreightDirect
                                            ? modeOptions.doubleAccountFreightDirect
                                            : isDoubleAccountFreightDirectReturns
                                            ? modeOptions.doubleAccountFreightDirectReturns
                                            : formValues?.selectedLocation
                                                  ?.is3rdPartyEnabled
                                            ? modeOptions.shipperAndThirdParty
                                            : modeOptions.shipperOnly
                                    }
                                    required
                                    onChange={(e, value) => {
                                        onModeChange(
                                            value,
                                            selectedLocation,
                                            isFreightDirectReturns
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}

                {!isFreightDirectReturns && (
                    <Grid item container className={classes.section}>
                        <Grid item container>
                            <Field
                                name="protectFromFreezing"
                                label="Protect From Freezing"
                                component={FormCheckbox}
                            />
                            <Field
                                component={FormCheckbox}
                                name="isInBondShipment"
                                label={
                                    <FormattedMessage
                                        id="getRates.form__inBondShipment"
                                        defaultMessage="In-Bond Shipment"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                )}

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            beforeNextStep()
                            handleUpdate()
                        }}
                        invalid={invalid}
                    />
                ) : (
                    <NextButton
                        handleComplete={() => {
                            beforeNextStep()
                            handleComplete()
                        }}
                        invalid={invalid}
                    />
                )}

                <ProNumberResult
                    isProNumberSearch={isProNumberSearch}
                    handleCloseProSearchModal={() =>
                        setIsProNumberSearch(false)
                    }
                    shipmentsProNumberSearch={shipmentsProNumberSearch}
                    proNumber={formValues?.proNumber}
                    changeField={changeField}
                    newFlow={true}
                    setIsReturning={setIsReturning}
                    setReturnsTrackingId={setReturnsTrackingId}
                />
            </Grid>
        )
    } else if (currentStep > 0) {
        return (
            <Grid item container>
                <BasicInfoSummary
                    handleEdit={handleEdit}
                    formValues={formValues}
                />
            </Grid>
        )
    }
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(BasicInfo)
