import React, { useEffect, Fragment } from "react"
import {
    Typography,
    withStyles,
    Grid,
    Card,
    CardContent,
    CardHeader,
} from "@material-ui/core"
import { connect } from "react-redux"
import {
    change,
    Field,
    reduxForm,
    reset,
    FormSection,
    formValues,
    FieldArray,
} from "redux-form"
import { FormattedMessage, injectIntl } from "react-intl"
import FormField from "../../form/form-field"
import {
    countriesOfOrigin,
    provideLocaleSortedOptionsList,
    supportedCountryCodes,
} from "../../../misc"
import FormSelect from "../../form/form-select"
import FormZipCode from "../../form/form-zip-code"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import FormSelectCity from "../../form/form-select-city"
import { formattedPurposeOfShipment } from "../../book/SupportDocumentationForm"
import {
    isNumeric,
    isRequired,
    isRequiredIf,
} from "../../../actions/validation"
import { combineValidators, composeValidators } from "revalidate"
import FormDatePicker from "../../form/form-datepicker"
import ManageSignature from "../ManageSignature"
import DocumentActions from "../DocumentActions"
import { generateInitialValues } from "./selectors"
import { weightUnitPluralCapital } from "../../util/units"

const styles = theme => ({
    location__fixed: {
        maxHeight: "450px",
    },
    cardWithDropdown: {
        overflow: "visible",
    },
})

const CertificateOfOriginForm = props => {
    const { onSubmit, onBackClick, handleSubmit, submitting, formTitle } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item container spacing={16}>
                <Grid item xs={12}>
                    <ShipmentDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <FormSection name="exporter" component={Fragment}>
                        <LocationCard
                            title={
                                <FormattedMessage
                                    id="certificateOfOrigin.exporter__title"
                                    defaultMessage="Exporter"
                                />
                            }
                            includeContact
                        />
                    </FormSection>
                </Grid>
                <Grid item xs={6}>
                    <FormSection name="consignee" component={Fragment}>
                        <LocationCard
                            title={
                                <FormattedMessage
                                    id="certificateOfOrigin.consignee__title"
                                    defaultMessage="Consignee"
                                />
                            }
                        />
                    </FormSection>
                </Grid>
                <Grid item xs={12}>
                    <HandlingUnitCard />
                </Grid>
                <Grid item xs={6}>
                    <NotaryDetailsCard />
                </Grid>
                <Grid item xs={6}>
                    <SignatureDetailsCard />
                </Grid>
                <DocumentActions
                    onBackClick={onBackClick}
                    submitting={submitting}
                    documentType={formTitle}
                />
            </Grid>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    const activeShipment = state.shipment.list[props.shipmentId]

    const initialValues = generateInitialValues(state, props)

    return {
        bol: activeShipment?.identifiers?.bolNumber,
        trackingNumber: activeShipment?.identifiers?.proNumber,
        initialValues,
    }
}

const itemValidation = {
    "certificateOfOriginLineItems[].numberOfPackages": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "certificateOfOrigin.no__of__packages",
            defaultMessage: "No. of Packages:",
        },
    }),
    "certificateOfOriginLineItems[].netWeight.amount": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "certificateOfOrigin.net__weight",
            defaultMessage: "Net Weight ({preferredSystemOfMeasurement}):",
            values: {
                preferredSystemOfMeasurement: "",
            },
        },
    }),
    "certificateOfOriginLineItems[].grossWeight.amount": composeValidators(
        isRequired,
        isNumeric
    )({
        field: {
            id: "certificateOfOrigin.gross__weight",
            defaultMessage: "Gross Weight ({preferredSystemOfMeasurement}):",
            values: {
                preferredSystemOfMeasurement: "",
            },
        },
    }),
    "certificateOfOriginLineItems[].countryOfManufacture": isRequired({
        field: {
            id: "certificateOfOrigin.country__of__manufacture",
            defaultMessage: "Country of Manufacture:",
        },
    }),
}

const formValidation = combineValidators({
    trackingNumber: isRequired({
        field: {
            id: "certificateOfOrigin.tracking",
            defaultMessage: "Air Waybill No. / Tracking No.:",
        },
    }),
    carrier: isRequired({
        field: {
            id: "certificateOfOrigin.carrier",
            defaultMessage: "Carrier",
        },
    }),
    origin: {
        companyName: isRequired({
            field: {
                id: "certificateOfOrigin.company__name",
                defaultMessage: "Company Name:",
            },
        }),
        address: {
            street_1: isRequiredIf(
                values => !values?.exporterInfo?.address?.street_2
            )({
                field: {
                    id: "certificateOfOrigin.address1",
                    defaultMessage: "Address 1:",
                },
            }),
            street_2: isRequiredIf(
                values => !values?.exporterInfo?.address?.street_1
            )({
                field: {
                    id: "certificateOfOrigin.address2",
                    defaultMessage: "Address 2:",
                },
            }),
            postal_code: isRequired({
                field: {
                    id: "certificateOfOrigin.zipPostalCode",
                    defaultMessage: "Zip/Postal Code:",
                },
            }),
            city: isRequired({
                field: {
                    id: "certificateOfOrigin.city",
                    defaultMessage: "City:",
                },
            }),
            country: isRequired({
                field: {
                    id: "certificateOfOrigin.country",
                    defaultMessage: "Country:",
                },
            }),
            state: isRequired({
                field: {
                    id: "certificateOfOrigin.state",
                    defaultMessag: "State/Province:",
                },
            }),
        },
        contactName: isRequired({
            field: {
                id: "certificateOfOrigin.broker_contact__name",
                defaultMessage: "Contact Name:",
            },
        }),
    },
    destination: {
        companyName: isRequired({
            field: {
                id: "certificateOfOrigin.company__name",
                defaultMessage: "Company Name:",
            },
        }),
        address: {
            street_1: isRequiredIf(
                values => !values?.consigneeInfo?.address?.street_2
            )({
                field: {
                    id: "certificateOfOrigin.address1",
                    defaultMessage: "Address 1:",
                },
            }),
            street_2: isRequiredIf(
                values => !values?.consigneeInfo?.address?.street_1
            )({
                field: {
                    id: "certificateOfOrigin.address2",
                    defaultMessage: "Address 2:",
                },
            }),
            postal_code: isRequired({
                field: {
                    id: "certificateOfOrigin.zipPostalCode",
                    defaultMessage: "Zip/Postal Code:",
                },
            }),
            city: isRequired({
                field: {
                    id: "certificateOfOrigin.city",
                    defaultMessage: "City:",
                },
            }),
            country: isRequired({
                field: {
                    id: "certificateOfOrigin.country",
                    defaultMessage: "Country:",
                },
            }),
            state: isRequired({
                field: {
                    id: "certificateOfOrigin.state",
                    defaultMessag: "State/Province:",
                },
            }),
        },
    },
    ...itemValidation,
})

const mapDispatchToProps = dispatch => ({
    resetForm: () => dispatch(reset("certificateOfOriginForm")),
    changeField: (field, value) =>
        dispatch(change("certificateOfOriginForm", field, value)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    reduxForm({
        form: "certificateOfOriginForm",
        enableReinitialize: true,
        validate: formValidation,
    })(CertificateOfOriginForm)
)

const SignatureDetailsCard = withStyles(styles)(({ classes }) => (
    <Card>
        <CardHeader
            title={
                <FormattedMessage
                    id="certificateOfOrigin.signature__title"
                    defaultMessage="Signature"
                />
            }
        />
        <CardContent className={classes.location__fixed}>
            <Grid
                item
                container
                alignItems="flex-start"
                justify="space-between"
                spacing={16}
            >
                <Grid item xs={4}>
                    <ManageSignature
                        fieldName="exporterSignatureImage"
                        previewDimensions={{
                            width: 223,
                            height: 44,
                        }}
                        addTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__addExporterSignature"
                                defaultMessage="Add Exporter Signature"
                            />
                        }
                        replaceTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__replaceExporterSignature"
                                defaultMessage="Replace Exporter Signature"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <ManageSignature
                        fieldName="notaryStamp"
                        previewDimensions={{
                            width: 188,
                            height: 38,
                        }}
                        addTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__addNotaryStamp"
                                defaultMessage="Add Notary Stamp"
                            />
                        }
                        replaceTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__replaceNotaryStamp"
                                defaultMessage="Replace Notary Stamp"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <ManageSignature
                        fieldName="secretarySignatureImage"
                        previewDimensions={{
                            width: 186,
                            height: 42,
                        }}
                        addTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__addSecretarySignature"
                                defaultMessage="Add Secretary Signature"
                            />
                        }
                        replaceTitle={
                            <FormattedMessage
                                id="locations.signatureManagment__replaceSecretarySignature"
                                defaultMessage="Replace Secretary Signature"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
))

const HandlingUnitForm = formValues("description")(
    injectIntl(({ intl, description, weightUnit }) => (
        <Fragment>
            <Grid item container>
                <Typography variant="subheading" gutterBottom>
                    <FormattedMessage
                        id="certificateOfOrigin.item__description"
                        defaultMessage="{itemName} item information:"
                        values={{
                            itemName: description,
                        }}
                    />
                </Typography>
            </Grid>
            <Grid item container md={2}>
                <Field
                    name="numberOfPackages"
                    type="number"
                    required={true}
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.no__of__packages"
                            defaultMessage="No. of Packages:"
                        />
                    }
                />
            </Grid>
            <Grid item container md={2}>
                <Field
                    name="grossWeight.amount"
                    type="number"
                    required
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.gross__weight"
                            defaultMessage="Gross Weight ({preferredSystemOfMeasurement}):"
                            values={{
                                preferredSystemOfMeasurement: weightUnit,
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item container md={2}>
                <Field
                    name="netWeight.amount"
                    type="number"
                    required
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.net__weight"
                            defaultMessage="Net Weight ({preferredSystemOfMeasurement}):"
                            values={{
                                preferredSystemOfMeasurement: weightUnit,
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item container md={3}>
                <Field
                    component={FormSelectAutocomplete}
                    name="countryOfManufacture"
                    required
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.country__of__manufacture"
                            defaultMessage="Country of Manufacture:"
                        />
                    }
                    options={provideLocaleSortedOptionsList(
                        intl,
                        countriesOfOrigin,
                        ["US", "CA", "MX"]
                    )}
                />
            </Grid>
        </Fragment>
    ))
)

const HandlingUnitListPresentation = ({ fields, weightUnit }) => (
    <Fragment>
        {fields.map(prefix => (
            <FormSection name={prefix} key={prefix} component={Fragment}>
                <HandlingUnitForm weightUnit={weightUnit} />
            </FormSection>
        ))}
    </Fragment>
)

const HandlingUnitCard = withStyles(styles)(
    formValues("preferredSystemOfMeasurement")(
        ({ classes, intl, preferredSystemOfMeasurement }) => (
            <Card className={classes.cardWithDropdown}>
                <CardHeader
                    title={
                        <Typography variant="title">
                            <FormattedMessage
                                id="certificateOfOrigin.items__title"
                                defaultMessage="Items"
                            />
                        </Typography>
                    }
                />
                <CardContent className={classes.location__fixed}>
                    <Grid item container>
                        <FieldArray
                            name="certificateOfOriginLineItems"
                            component={HandlingUnitListPresentation}
                            props={{
                                weightUnit: weightUnitPluralCapital(
                                    preferredSystemOfMeasurement
                                ),
                            }}
                        />
                    </Grid>
                </CardContent>
            </Card>
        )
    )
)

const NotaryDetailsCard = injectIntl(
    withStyles(styles)(({ classes, intl }) => (
        <Card className={classes.cardWithDropdown}>
            <CardHeader
                title={
                    <Typography variant="title">
                        <FormattedMessage
                            id="certificateOfOrigin.notaryDetails__title"
                            defaultMessage="Notary details"
                        />
                    </Typography>
                }
            />
            <CardContent className={classes.location__fixed}>
                <Grid item container>
                    <Grid item container xs={12} md={6}>
                        <Field
                            name="datedAt"
                            component={FormField}
                            type="text"
                            label={
                                <FormattedMessage
                                    id="certificateOfOrigin.datedAt"
                                    defaultMessage="Dated at:"
                                />
                            }
                            required
                        />
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Field
                            name="datedOn"
                            component={FormDatePicker}
                            type="text"
                            dateFormat="ll"
                            noMinDate
                            label={
                                <FormattedMessage
                                    id="certificateOfOrigin.datedOn"
                                    defaultMessage="Dated on:"
                                />
                            }
                            disabled
                            required
                        />
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Field
                            name="swornDate"
                            component={FormDatePicker}
                            dateFormat="ll"
                            noMinDate
                            disableFuture
                            label={
                                <FormattedMessage
                                    id="certificateOfOrigin.swornDate"
                                    defaultMessage="Sworn on:"
                                />
                            }
                            required
                        />
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Field
                            name="chamberOfCommerce.name"
                            type="text"
                            required={true}
                            component={FormField}
                            label={
                                <FormattedMessage
                                    id="certificateOfOrigin.chamberOfCommerce__name"
                                    defaultMessage="Chamber of Commerce Name:"
                                />
                            }
                        />
                    </Grid>
                    <Grid item container xs={12} md={6}>
                        <Field
                            name="chamberOfCommerce.state"
                            type="text"
                            required={true}
                            component={FormField}
                            label={
                                <FormattedMessage
                                    id="certificateOfOrigin.chamberOfCommerce__state"
                                    defaultMessage="Chamber of Commerce State:"
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    ))
)

const ShipmentDetailsCard = withStyles(styles)(({ classes }) => (
    <Card className={classes.cardWithDropdown}>
        <CardHeader
            title={
                <FormattedMessage
                    id="certificateOfOrigin.shipment__title"
                    defaultMessage="Shipment Details"
                />
            }
        />
        <CardContent className={classes.location__fixed}>
            <Grid container>
                <Grid item container xs={12} md={6}>
                    <Field
                        name="shipDate"
                        component={FormDatePicker}
                        noMinDate
                        dateFormat="ll"
                        type="text"
                        label={[
                            <FormattedMessage
                                id="certificateOfOrigin.ship__date"
                                defaultMessage="Ship Date:"
                            />,
                        ]}
                        disabled
                        required
                    />
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Field
                        name="carrier"
                        component={FormField}
                        type="text"
                        label={[
                            <FormattedMessage
                                id="certificateOfOrigin.carrier"
                                defaultMessage="Carrier:"
                            />,
                        ]}
                        disabled
                        required
                    />
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Field
                        name="trackingNumber"
                        component={FormField}
                        type="text"
                        label={[
                            <FormattedMessage
                                id="certificateOfOrigin.tracking"
                                defaultMessage="Air Waybill No. / Tracking No.:"
                            />,
                        ]}
                        disabled
                        required
                    />
                </Grid>
                <Grid item container xs={12} md={6}>
                    <Field
                        component={FormSelectAutocomplete}
                        name="shipmentPurpose"
                        options={formattedPurposeOfShipment}
                        label={
                            <FormattedMessage
                                id="certificateOfOrigin.select_purpose"
                                defaultMessage="Select Purpose Of Shipment:"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
))

const LocationCard = formValues({
    postalCode: "address.postal_code",
    country: "address.country",
    address1: "address.street_1",
    address2: "address.street_2",
    city: "address.city",
})(
    withStyles(styles)(
        ({
            classes,
            country,
            city,
            postalCode,
            address1,
            address2,
            title,
            includeContact = false,
        }) => (
            <Card className={classes.cardWithDropdown}>
                <CardHeader title={title} />
                <CardContent className={classes.location__fixed}>
                    <Grid item container>
                        {includeContact && (
                            <Grid item container sm={12} md={6}>
                                <Field
                                    name="contactName"
                                    component={FormField}
                                    type="text"
                                    label={[
                                        <FormattedMessage
                                            id="certificateOfOrigin.contact__name"
                                            defaultMessage="Contact Name:"
                                        />,
                                    ]}
                                    required
                                />
                            </Grid>
                        )}
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="companyName"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.company__name"
                                        defaultMessage="Company Name:"
                                    />,
                                ]}
                                required
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.street_1"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.address1"
                                        defaultMessage="Address 1:"
                                    />,
                                ]}
                                required={!address2}
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.street_2"
                                component={FormField}
                                type="text"
                                label={
                                    <FormattedMessage
                                        id="certificateOfOrigin.address2"
                                        defaultMessage="Address 2:"
                                    />
                                }
                                required={!address1}
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.country"
                                component={FormSelect}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.country"
                                        defaultMessage="Country:"
                                    />,
                                ]}
                                options={supportedCountryCodes}
                                maxLength="2"
                                required
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.postal_code"
                                component={FormZipCode}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.zipPostalCode"
                                        defaultMessage="Zip/Postal Code:"
                                    />,
                                ]}
                                country={country}
                                required
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.city"
                                component={FormSelectCity}
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.city"
                                        defaultMessage="City:"
                                    />,
                                ]}
                                country={country}
                                postalCode={postalCode}
                                defaultCity={city}
                                required
                            />
                        </Grid>
                        <Grid item container sm={12} md={6}>
                            <Field
                                name="address.state"
                                component={FormField}
                                type="text"
                                label={[
                                    <FormattedMessage
                                        id="certificateOfOrigin.state"
                                        defaultMessage="State/Province:"
                                    />,
                                ]}
                                required
                                disabled
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    )
)
