import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import PaginationControls from "../util/pagination-controls"
import Spinner from "../util/spinner"

export default class TablePaginationItems extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            pageSize: props.pageSize,
        }
    }

    componentDidMount() {
        this.onPageChange(this.state.page, this.state.pageSize)
    }

    async onPageChange(page, pageSize = this.state.pageSize) {
        const { pageSize: oldSize, page: oldPage } = this.state
        this.setState({ isLoading: true, page, pageSize })
        try {
            const loadedPages = Math.floor(
                this.props.elements.length / pageSize
            )
            if (page >= loadedPages) {
                await this.props.fetchElements(page * pageSize)
            }
            this.setState({ isLoading: false })
        } catch (_) {
            this.setState({
                pageSize: oldSize,
                page: oldPage,
                isLoading: false,
            })
        }
    }

    onPageSizeChange(newPageSize) {
        const { pageSize, page } = this.state
        const totalPages = Math.ceil(this.props.totalElements / newPageSize)
        const currentRow = pageSize * page
        const newPage = Math.min(
            Math.ceil(currentRow / newPageSize),
            totalPages
        )
        this.props.savePageSize(newPageSize)
        this.onPageChange(newPage, newPageSize)
    }

    render() {
        const { isLoading } = this.state
        const { elements } = this.props
        if (isLoading)
            return (
                <Grid xs={12} container>
                    <Spinner />
                </Grid>
            )
        if (!elements || !elements.length) {
            return this.props.noElement() || null
        }
        const indexOfLast = this.state.page * this.state.pageSize
        const indexOfFirst = indexOfLast - this.state.pageSize
        const elementsToDisplay = elements.slice(indexOfFirst, indexOfLast)

        return (
            <div>
                {this.props.render(elementsToDisplay, indexOfFirst)}
                <PaginationControls
                    onPageSizeChange={pageSize =>
                        this.onPageSizeChange(pageSize)
                    }
                    onPageChange={page => this.onPageChange(page)}
                    page={this.state.page}
                    pageSize={this.state.pageSize}
                    totalElements={this.props.totalElements}
                    showPageSizeOptions={this.props.showPageSizeOptions}
                />
            </div>
        )
    }
}

TablePaginationItems.propTypes = {
    elements: PropTypes.array.isRequired,
    render: PropTypes.func.isRequired,
    fetchElements: PropTypes.func.isRequired,
    savePageSize: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    showPageSizeOptions: PropTypes.bool,
    totalElements: PropTypes.number.isRequired,
}

TablePaginationItems.defaultProps = {
    showPageSizeOptions: true,
}
