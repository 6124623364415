import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { populateTrackForm } from "../../actions/track"

export class TrackLoader extends React.Component {
    componentDidMount() {
        const { shipmentId, loadShipment } = this.props
        loadShipment(shipmentId)
    }

    componentWillReceiveProps({ shipmentId, shipment }) {
        if (shipmentId !== this.props.shipmentId) {
            this.props.loadShipment(shipmentId)
        }
    }

    render() {
        return null
    }
}

TrackLoader.propTypes = {
    shipmentId: PropTypes.string,
    shipment: PropTypes.object,
    loadShipment: PropTypes.func.isRequired,
}

TrackLoader.defaultProps = {
    shipmentId: null,
    shipment: null,
}

const mapStateToProps = (state, props) => ({
    shipmentId: props.match.params.shipmentId,
})

const mapDispatchToProps = dispatch => ({
    loadShipment: shipmentId => dispatch(populateTrackForm(shipmentId)),
})

export const TrackLoaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TrackLoader)
