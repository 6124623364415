import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
    reduxForm,
    formValueSelector,
    arrayRemove,
    SubmissionError,
} from "redux-form"
import { createShare } from "../../../actions/share"
import { Markup } from "./markup"
import ShareRecipients from "./recipients"
import SharedQuotesPanel from "./quotesPanel"
import SnackbarContentWrapper from "./snackbar"
import {
    Dialog,
    withStyles,
    Grid,
    Button,
    Snackbar,
    Typography,
} from "@material-ui/core"
import { requestContactAddresses } from "../../../actions/address"
import { shareQuoteSuccess } from "../../../messages/confirmation-constants"
import { shareQuoteError } from "../../../messages/error-constants"
import { emailValidator } from "../../../actions/validation"
import { combineValidators } from "revalidate"
import { FormattedMessage } from "react-intl"
import { defaultLanguage } from "../../constants/preferenceLanguageOptions"
import { currencyLabelWithoutAmount } from "../../util/currencyLabel"

const styles = theme => ({
    modal__container: {
        minWidth: "720px",
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        backgroundColor: "lightgrey",
    },
    button__container: {
        marginTop: "10px",
    },
    margin: {
        margin: theme.spacing.unit,
    },
})

const getNonDuplicateContacts = (contactList, selectedContacts) => {
    const newContacts = []
    contactList.forEach(c => {
        const emailSelected = selectedContacts.find(
            s => s.emailAddress === c.emailAddress && s.label === c.label
        )
        const newOption = {
            values: [
                ...(!emailSelected
                    ? [
                          {
                              value: c.emailAddress,
                              type: "email",
                          },
                      ]
                    : []),
            ],
            label: c.label,
        }
        if (newOption.values.length) newContacts.push(newOption)
    })
    return newContacts
}

class QuoteShareModalPresentation extends Component {
    state = {
        snackbarOpen: false,
        snackbarVariant: "",
    }

    componentDidMount() {
        this.props.requestContactAddresses()
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ snackbarOpen: false })
    }

    handleSnackbarOpen = variant => {
        this.setState({ snackbarOpen: true, snackbarVariant: variant })
    }

    handleDelete = (contact, i) => {
        const recipientIndex = this.props.recipients.indexOf(
            c =>
                contact.value.type === c.value.type && c.label === contact.label
        )
        this.props.handleDelete(recipientIndex, i)
    }

    sendShare = async values => {
        try {
            await this.props.createShare(values)
            this.props.reset()
            this.props.handleClose()
            this.handleSnackbarOpen("success")
        } catch (err) {
            this.props.handleClose()
            this.handleSnackbarOpen("error")
        }
    }

    render() {
        const {
            selections,
            selectedContacts,
            recipients,
            contacts,
            classes,
            open,
            handleClose,
            markup,
            handleSubmit,
            handleDelete,
            form,
            error,
        } = this.props
        const { snackbarOpen, snackbarVariant } = this.state
        const newContacts =
            recipients && getNonDuplicateContacts(contacts, recipients)
        const currencyCode = currencyLabelWithoutAmount(
            selections?.[0]?.rate?.preferredCurrencyCode
        )
        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={snackbarOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackbarClose}
                >
                    <SnackbarContentWrapper
                        variant={snackbarVariant}
                        className={classes.margin}
                        message={
                            snackbarVariant === "success"
                                ? shareQuoteSuccess
                                : shareQuoteError
                        }
                        onClose={this.handleSnackbarClose}
                    />
                </Snackbar>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    className={classes.modal}
                    fullWidth
                    maxWidth={"md"}
                >
                    <div className={`${classes.modal__container} share-modal`}>
                        <form onSubmit={handleSubmit(this.sendShare)}>
                            <Grid
                                container
                                className={classes.button__container}
                            >
                                <Grid item xs={12}>
                                    <ShareRecipients
                                        selected={selectedContacts}
                                        contacts={newContacts}
                                        onDelete={handleDelete}
                                        formName={form}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Markup currencyCode={currencyCode} />
                                </Grid>
                                <Grid item xs={12}>
                                    <SharedQuotesPanel
                                        selections={selections}
                                        markup={markup}
                                        currencyCode={currencyCode}
                                    />
                                </Grid>
                                {error && (
                                    <Typography color="error">
                                        {error}
                                    </Typography>
                                )}
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    justify="space-between"
                                    className={classes.button__container}
                                >
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            this.props.reset()
                                            this.props.handleClose()
                                        }}
                                        className={classes.leftButton}
                                    >
                                        <FormattedMessage
                                            id="generalTerms__cancel"
                                            defaultMessage="Cancel"
                                        />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >
                                        <FormattedMessage
                                            id="rateResults.share__share"
                                            defaultMessage="Share"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Dialog>
            </Fragment>
        )
    }
}

QuoteShareModalPresentation.propTypes = {
    selections: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    referenceNumber: PropTypes.string.isRequired,
    onReferenceNumberChange: PropTypes.func.isRequired,
    showUpcharge: PropTypes.bool.isRequired,
}

const selector = formValueSelector("shareQuote")

export const mapSelections = (list, shipmentId, selections) => {
    const quote = (list[shipmentId] || {}).result
    if (!quote || !quote.rateQuotes || !selections) return []
    return selections
        .map((selection, i) => {
            const carrier = quote.rateQuotes.find(
                item =>
                    item.carrierCode === selection.name &&
                    item.mode === selection.mode &&
                    item.direction === selection.direction
            )
            if (!carrier) return null
            return selection.alternateRateQuote !== undefined
                ? {
                      rate:
                          carrier.alternateRateQuotes[
                              selection.alternateRateQuote
                          ],
                      index: i,
                      mode: selection.mode,
                  }
                : {
                      rate: carrier,
                      index: i,
                      mode: selection.mode,
                  }
        })
        .filter(x => x && x.rate)
}

const mapRecipients = contacts => {
    const recipients = []
    contacts.forEach(contact => {
        const contactInfo = contact.contact
        recipients.push({
            emailAddress: contactInfo.email.emailAddress,
            label: contactInfo.name,
        })
    })
    return recipients
}

const validation = combineValidators({
    newEmailRecipient: emailValidator({
        field: {
            id: "generalTerms__emailAddress",
            defaultMessage: "Email Address",
        },
    }),
})

const mapStateToProps = state => ({
    selections: mapSelections(
        state.quotes.list.items,
        state.identifiers.internalTrackingNumber,
        state.share.selections
    ),
    contacts: mapRecipients(state.addressBook.addresses),
    recipients: selector(state, "recipients"),
    selectedContacts: selector(state, "mergedRecipients"),
    markup: selector(state, "markupType", "markupAmount"),
    initialValues: {
        markupAmount: 0,
        markupType: false,
        recipients: [],
        language: defaultLanguage,
    },
    referenceNumber: state.share.referenceNumber,
    contactQuery: state.share.contactQuery,
    showUpcharge: !!state.markup.markupAmount,
})

const mapDispatchToProps = dispatch => ({
    requestContactAddresses: () => dispatch(requestContactAddresses()),
    handleDelete: (recipientIndex, i) => {
        if (recipientIndex >= 0)
            dispatch(arrayRemove("shareQuote", "recipients", recipientIndex))
        dispatch(arrayRemove("shareQuote", "mergedRecipients", i))
    },
    createShare: async values => {
        const { mergedRecipients, newEmailRecipient } = values
        if (!mergedRecipients?.length) {
            throw new SubmissionError({
                _error: (
                    <FormattedMessage
                        id="rateResults.share__error1"
                        defaultMessage="Please add at least one user"
                    />
                ),
            })
        }
        if (newEmailRecipient?.length) {
            throw new SubmissionError({
                newEmailRecipient: (
                    <FormattedMessage
                        id="locations.addUserModal__lastEmailError"
                        defaultMessage="It looks like you forgot to add the last email address"
                    />
                ),
            })
        }
        return dispatch(createShare(values))
    },
})

export const QuoteShareModal = withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            form: "shareQuote", // a unique identifier for this form
            enableReinitialize: true,
            validate: validation,
        })(QuoteShareModalPresentation)
    )
)
