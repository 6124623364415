import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import OriginSummary from "../summaryCards/OriginSummary"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { Field, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage } from "react-intl"
import { makeStyles } from "@material-ui/styles"
import Typography from "@material-ui/core/Typography"
import { supportedCountryCodes } from "../../../../misc"
import { Map } from "../../../util"
import Accessorials from "../atoms/Accessorials"
import { Popper } from "@material-ui/core"
import { SuggestionBox } from "../atoms/SuggestionBox"
import { Element, scroller } from "react-scroll"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import FormSelectAutocompleteNew from "../../../form/form-select-autocomplete-new"
import FormZipCode from "../../../form/form-zip-code"
import { bookShipmentFormValidation } from "../index"
import InBondDialog from "../atoms/InBondDialog"
import normalizePhone from "../../../util/normalizePhone"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    accessorialsContainer: {
        paddingLeft: "8px",
    },
    mapContainer: {
        minHeight: "284px",
        maxHeight: "388px",
    },
    formContainer: {
        paddingRight: "8px",
    },
    mainInformation: {
        paddingBottom: "14px",
    },
    pickupInformation: {
        minHeight: "213px",
    },
    popper: {
        zIndex: 100,
    },
    searchContact: {
        paddingRight: "15px",
    },
    hide: {
        display: "none",
    },
})

const Origin = ({
    handleComplete,
    handleEdit,
    handleUpdate,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = {},
    performContactSearch,
    contactSearchInProgress,
    changeField,
    initialValues,
    cities = [],
    pickupAccessorialsList,
    invalid,
    textInputProps,
    isNotAllowedInBondCountryModal,
    proccedWithOutInbondModal,
    cancelInbondModal,
    invalidInBondCountry,
    isBookingQuickRate,
    isControlledFlow,
    setIsFreightBoxDialogOpen,
}) => {
    const {
        origin,
        isFreightDirect,
        isFreightBox,
        isFreightDirectReturns,
        proNumberSearchResult,
        mode,
    } = formValues

    const beforeNextStep = () => {}
    const classes = useStyles()
    const [showOverlay, setShowOverlay] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [shouldShowForm, setShouldShowForm] = useState(!!isBookingQuickRate)

    useEffect(() => {
        if (currentStep === 1) {
            scroller.scrollTo("originTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        if (
            mode === "outbound" ||
            isBookingQuickRate ||
            !isControlledFlow ||
            initialValues?.isReturnMode
        ) {
            setShouldShowForm(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, isBookingQuickRate, isControlledFlow])

    const handleInputFocus = (field, e) => {
        if (field === "searchContact") {
            setShowOverlay(true)
            setAnchorEl(e.currentTarget)
        }
    }

    const handleOptionSelect = (option = {}) => {
        if (isFreightBox && option?.address.country !== "US") {
            setIsFreightBoxDialogOpen(true)
            changeField("origin", {
                searchContact: "",
                shippingAddress: {
                    address: {
                        country:
                            initialValues?.origin?.shippingAddress?.address
                                ?.country,
                    },
                },
            })
            setShouldShowForm(true)
            return
        }
        setShowOverlay(false)
        setAnchorEl(null)
        changeField("origin", {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.origin?.shippingAddress?.address
                            ?.country,
                },
            },
        })
        setShouldShowForm(true)
        changeField("origin", {
            shippingAddress: {
                address: {
                    city: option?.address.city,
                    state: option?.address.state,
                    street1: option?.address.street1,
                    street2: option?.address.street2,
                    country: option?.address.country,
                    postalCode: option?.address.postalCode,
                },
                name: option?.name,
            },
            readyTime: option?.contact?.pickupContact?.readyTime,
            closeTime: option?.contact?.pickupContact?.closeTime,
            pickupContact: {
                name: option?.contact?.pickupContact?.name,
                phone: {
                    phone_number:
                        option?.contact?.pickupContact?.phone?.phoneNumber,
                    extension: option?.contact?.pickupContact?.phone?.extension,
                },
                email: {
                    email_address:
                        option?.contact?.pickupContact?.email?.emailAddress,
                },
            },
            contact: {
                name: option?.contact?.name,
                phone: {
                    phone_number: option?.contact?.phone?.phoneNumber,
                    extension: option?.contact?.phone?.extension,
                },
                email: {
                    email_address: option?.contact?.email?.emailAddress,
                },
            },
        })
        if (option?.pickupAccessorials?.length) {
            changeField(
                "pickupAccessorials",
                option?.pickupAccessorials.map(entry => entry.value) ?? []
            )
        } else {
            changeField("pickupAccessorials", [])
        }
    }

    const handleClickAway = () => {
        if (showOverlay) {
            setShowOverlay(false)
            setAnchorEl(null)
        }
    }

    const disabledNextButton = invalidInBondCountry || invalid

    if (currentStep === 1) {
        return (
            <Grid item container>
                <InBondDialog
                    isDialogOpen={isNotAllowedInBondCountryModal}
                    proccedWithOutInbondModal={proccedWithOutInbondModal}
                    cancelInbondModal={cancelInbondModal}
                    invalidCountry={origin?.shippingAddress?.address?.country}
                />
                <Popper
                    open={showOverlay}
                    anchorEl={anchorEl}
                    placement="bottom-start"
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: "scrollParent",
                        },
                    }}
                    className={classes.popper}
                >
                    <ClickAwayListener
                        mouseEvent="onMouseDown"
                        touchEvent="onTouchStart"
                        onClickAway={() => handleClickAway()}
                    >
                        <div>
                            <SuggestionBox
                                searchInProgress={contactSearchInProgress}
                                addressesNeeded
                                field={"origin"}
                                term={origin?.searchContact ?? ""}
                                handleAddressSelect={handleOptionSelect}
                                noCities
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>

                <Grid item container justify="center">
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.basicInfo__origin"
                            defaultMessage="Origin Information"
                        />
                    </Typography>
                    <Element name="originTitle" />
                </Grid>
                <Grid item container className={classes.section}>
                    <Grid
                        item
                        container
                        xs={7}
                        className={classes.formContainer}
                    >
                        <Grid
                            item
                            container
                            className={classes.mainInformation}
                            alignContent="flex-start"
                        >
                            {formValues?.mode !== "outbound" && (
                                <Grid item container alignItems="flex-end">
                                    <Grid
                                        item
                                        container
                                        xs={9}
                                        className={classes.searchContact}
                                    >
                                        <Field
                                            name="origin.searchContact"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__mainSearch"
                                                    defaultMessage="Search for a Contact"
                                                />
                                            }
                                            component={FormField}
                                            onChange={e => {
                                                setShowOverlay(true)
                                                setAnchorEl(e.currentTarget)
                                                performContactSearch(
                                                    e.target.value,
                                                    origin?.shippingAddress
                                                        ?.address?.postalCode
                                                )
                                            }}
                                            autoFocus
                                            onFocus={e =>
                                                handleInputFocus(
                                                    "searchContact",
                                                    e
                                                )
                                            }
                                            InputProps={textInputProps}
                                            disableBrowserAutocomplete
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={3}
                                        justify="center"
                                        alignItems="flex-start"
                                    >
                                        <Button
                                            id={"originAddNewContact"}
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                changeField("origin", {
                                                    shippingAddress: {
                                                        address: {
                                                            country:
                                                                initialValues
                                                                    ?.origin
                                                                    ?.shippingAddress
                                                                    ?.address
                                                                    ?.country,
                                                        },
                                                    },
                                                })
                                                changeField(
                                                    "pickupAccessorials",
                                                    []
                                                )
                                                setShouldShowForm(true)
                                            }}
                                        >
                                            <FormattedMessage
                                                id="generalTerms__addNew"
                                                defaultMessage="Add New"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                className={
                                    shouldShowForm || proNumberSearchResult
                                        ? ""
                                        : classes.hide
                                }
                            >
                                <Grid item container>
                                    <Field
                                        name="origin.shippingAddress.name"
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__companyName"
                                                defaultMessage="Company Name"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound"
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={8}>
                                    <Field
                                        name="origin.shippingAddress.address.street1"
                                        required
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__address1"
                                                defaultMessage="Address 1"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound"
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="origin.shippingAddress.address.street2"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__address2"
                                                defaultMessage="Address 2"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound"
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={3}>
                                    <Field
                                        name="origin.shippingAddress.address.postalCode"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__zipPostalCode"
                                                defaultMessage="Zip/Postal Code"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound"
                                        }
                                        component={FormZipCode}
                                        form="bookShipment"
                                        country={
                                            formValues?.origin?.shippingAddress
                                                ?.address?.country
                                        }
                                    />
                                </Grid>
                                <Grid item container xs={5}>
                                    <Field
                                        name="origin.shippingAddress.address.city"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__city"
                                                defaultMessage="City"
                                            />
                                        }
                                        component={FormSelectAutocompleteNew}
                                        options={cities.map(c => ({
                                            value: c,
                                            label: c,
                                        }))}
                                        customValue
                                    />
                                </Grid>
                                <Grid item container xs={2}>
                                    <Field
                                        name="origin.shippingAddress.address.state"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__state"
                                                defaultMessage="State"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound"
                                        }
                                        component={FormField}
                                        inputProps={{ maxLength: 2 }}
                                    />
                                </Grid>

                                <Grid item container xs={2}>
                                    <Field
                                        component={FormSelectAutocompleteNew}
                                        name="origin.shippingAddress.address.country"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__country"
                                                defaultMessage="Country"
                                            />
                                        }
                                        disabled={
                                            formValues?.mode === "outbound" ||
                                            isFreightBox
                                        }
                                        options={supportedCountryCodes}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Field
                                        name="origin.contact.name"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__contactName"
                                                defaultMessage="Contact Name"
                                            />
                                        }
                                        component={FormField}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="origin.contact.phone.phone_number"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__contactPhone"
                                                defaultMessage="Contact Phone"
                                            />
                                        }
                                        component={FormField}
                                        normalize={normalizePhone(
                                            formValues?.origin?.shippingAddress
                                                ?.address?.country
                                        )}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item container xs={2}>
                                    <Field
                                        name="origin.contact.phone.extension"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__extension"
                                                defaultMessage="Extension"
                                            />
                                        }
                                        component={FormField}
                                    />
                                </Grid>
                                <Grid item container xs={6}>
                                    <Field
                                        name="origin.contact.email.email_address"
                                        label={
                                            <FormattedMessage
                                                id="generalTerms__contactEmail"
                                                defaultMessage="Contact Email"
                                            />
                                        }
                                        component={FormField}
                                        required={true}
                                    />
                                </Grid>
                                {!isFreightDirect && !isFreightDirectReturns && (
                                    <Grid
                                        item
                                        container
                                        alignContent="flex-start"
                                    >
                                        <Accessorials
                                            list={pickupAccessorialsList}
                                            name={"pickupAccessorials"}
                                            formName="bookShipment"
                                            formValues={formValues}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={5}
                        className={classes.accessorialsContainer}
                    >
                        <Grid item container className={classes.mapContainer}>
                            <Map
                                addresses={[origin?.shippingAddress?.address]}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            beforeNextStep()
                            handleUpdate()
                        }}
                        invalid={disabledNextButton}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidNextButton => {
                            if (isInvalidNextButton) {
                                setShouldShowForm(true)
                                handleComplete(isInvalidNextButton)
                            } else {
                                beforeNextStep()
                                handleComplete()
                            }
                        }}
                        invalid={disabledNextButton}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 1) {
        return (
            <OriginSummary
                pickupAccessorialsList={pickupAccessorialsList}
                handleEdit={handleEdit}
                formValues={formValues}
            />
        )
    }
}

export default reduxForm({
    // a unique name for the form
    form: "bookShipment",
    validate: bookShipmentFormValidation,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
})(Origin)
