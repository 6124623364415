import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { shipToContact } from "../../../actions/contact"
import { changePath } from "../../../actions"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    parent__container: {
        padding: "10px 4px 10px 4px",
        "&:hover": {
            "background-color": "#d8d8d8",
            cursor: "pointer",
        },
        "&:active": {
            "background-color": theme.palette.primary.dark,
            color: "white",
        },
        borderBottom: "1px solid black",
    },
    selected: {
        "background-color": theme.palette.primary.light,
        color: "white",
        "&:hover": {
            "background-color": theme.palette.primary.light,
        },
    },
    button__icon: {
        "&button": {
            "&:focus": {
                outline: "none",
            },
        },
    },
    button__shipTo: {
        backgroundColor: "white",
        "&:hover": {
            backgroundColor: "#d8d8d8",
        },
    },
    contact__item__text: {
        backgroundColor: "white",
    },
})

const ContactItem = ({
    contact,
    address,
    selected,
    onClickChat,
    onClickCall,
    onClickMessage,
    onClickShipTo,
    shipToEnabled,
    id,
    name,
    onClickSelect,
    classes,
}) => (
    <Fragment>
        <Grid
            container
            onClick={e => {
                e.stopPropagation()
                onClickSelect(contact.id)
            }}
            className={`${classes.parent__container} ${
                selected ? classes.selected : ""
            }`}
        >
            <Grid item container justify="flex-start" direction="column" xs={5}>
                <Typography color="inherit">{name}</Typography>
                <Typography color="inherit">{`${address.city},`}</Typography>
                <Typography color="inherit">{`${address.state} ${address.postalCode}`}</Typography>
            </Grid>
            <Grid item container direction="column" xs={4} justify="center">
                <Typography variant="subheading" color="inherit">
                    {contact.name}
                </Typography>
            </Grid>
            <Grid item container direction="column" xs={3} justify="center">
                <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                        root: classes.button__shipTo,
                    }}
                    size="small"
                    onClick={e => {
                        e.stopPropagation()
                        onClickShipTo(id)
                    }}
                >
                    <FormattedMessage
                        id="contacts__shipTo"
                        defaultMessage="Ship To"
                    />
                </Button>
            </Grid>
        </Grid>
    </Fragment>
)

ContactItem.propTypes = {
    id: PropTypes.string,
    contact: PropTypes.object.isRequired,
    address: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool,
    shipToEnabled: PropTypes.bool,
    onClickChat: PropTypes.func,
    onClickCall: PropTypes.func,
    onClickMessage: PropTypes.func,
    onClickShipTo: PropTypes.func.isRequired,
    onClickSelect: PropTypes.func.isRequired,
    classes: PropTypes.object,
}

ContactItem.defaultProps = {
    shipToEnabled: true,
    selected: false,
    onClickChat: () => {},
    onClickCall: () => {},
    onClickMessage: () => {},
    classes: {},
    id: "",
}

const mapDispatchToProps = dispatch => ({
    onClickShipTo: id => dispatch(shipToContact(id)),
    onClickSelect: id => dispatch(changePath(`/address/${id}`)),
})

export default withStyles(styles)(
    connect(undefined, mapDispatchToProps)(ContactItem)
)
