import React from "react"
import Highlighter from "react-highlight-words"
import moment from "moment"
import { FormattedMessage } from "react-intl"
export { AccessorialList } from "./accessorial-list"
export { LineItemTable } from "./line-item-table"
export { Modal, PrintableContent } from "./modal"
export { ChatSelector } from "./chat-selector"
export { ValidatedField } from "./validated-field"
export { AddressSelection } from "./address-selection"
export { CarrierLogo } from "./carrier-logo"
export { ValidatedControl } from "./validated-control"
export { ValidationPanel, combineValidations } from "./validation-panel"
export { NotificationSettings } from "./notification-settings"
export { ContactSelection } from "./contact-selection"
export { Map } from "./map"
export { TooltipOnOverflow } from "./tooltip-on-overflow"
export { DownloadCSVButton } from "./download-csv-button"
export { Navigation } from "./navigation"
export { DeploymentReloadContainer } from "./deployment-reload"
export { InactivityTimer } from "./inactivity-timer"
export { EmailContact } from "./email-contact"
export { FooterRow } from "./footer-row"
export { PrintableModalActions } from "./printable-modal-actions"
export { ErrorBoundary } from "./errorBoundary"
export { withToken } from "./withToken"

export const truncateText = (text, length = 30) =>
    text && text.length > length ? `${text.substr(0, length - 3)}...` : text

export const calcDeliveryTime = date => {
    if (!date) return undefined
    const innerDate = date instanceof moment ? date : moment.utc(date)
    return (
        innerDate &&
        (innerDate.format("H") === "23" ? (
            <FormattedMessage
                id="generalTerms__closeOfBusiness"
                defaultMessage="Close of Business"
            />
        ) : (
            innerDate.format("LT")
        ))
    )
}

export const highlightTerm = (term, value) => (
    <Highlighter
        highlightClassName="highlight-term"
        searchWords={[term]}
        autoEscape
        textToHighlight={value}
    />
)

export const constructLabel = (cpgName, defaultNickname) =>
    cpgName || defaultNickname

export const constructSubLabel = (
    fedexFreightAccount,
    fedexBillToAccount,
    intl,
    isFreightDirect
) => {
    if (fedexFreightAccount && !fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount}`
        }
    } else if (fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    } else if (!fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    }
}
