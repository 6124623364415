import { Grid, Typography, withStyles } from "@material-ui/core"
import React, { useEffect } from "react"
import FormSwitch from "../../../form/form-switch"
import { FormattedMessage } from "react-intl"
import {
    hazMatPkgGrp,
    provideHazMatContainers,
    freightClasses,
    provideHazMatClasses,
} from "../../../item/line-item"
import { Field, FieldArray, formValues } from "redux-form"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import CloseIcon from "@material-ui/icons/Close"
import FreightDirectPiece from "./FreightDirectPiece"
import {
    countriesOfOrigin,
    join,
    provideLocaleSortedOptionsList,
} from "../../../../misc"

const styles = theme => ({
    itemInfo: {
        paddingLeft: "5%",
    },
    switchesContainer: {
        paddingLeft: "30px",
    },
    itemContainer: {
        borderTop: "solid 1px #D4D4D4",
        padding: "15px 0",
    },
    underline: {
        textDecoration: "underline",
    },
    item__icon: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    piecesContainerFXFD: { paddingTop: "10px" },
    button__add_item: {
        marginLeft: "20px",
    },
})

const ItemContainer = ({
    classes,
    prefix,
    item = {},
    itemIndex,
    onRemoveItemClick,
    isMultiClass,
    weightUnit,
    formValues,
    intl,
    isIntl,
    isUSDomesticOffshoreWithItemCustoms,
    getHUWeight,
    weight,
    isHazMat,
    isIntraMexico,
}) => {
    const {
        isFreightDirect,
        isFreightDirectReturns,
        preferredCurrencyCode,
    } = formValues
    useEffect(() => {
        getHUWeight()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weight])

    const piecesLabel = isMultiClass ? (
        <FormattedMessage
            id="getRates.items__piecesPerHU"
            defaultMessage="Pieces per H/U"
        />
    ) : (
        <FormattedMessage
            id="getRates.items__totalPieces"
            defaultMessage="Total Pieces"
        />
    )
    return (
        <Grid item container className={classes.itemContainer}>
            <Grid item container xs={9} className={classes.itemInfo}>
                <Typography variant="subheading">
                    <FormattedMessage
                        id="bookShipment.items__itemLevelInfo"
                        defaultMessage="Item Information"
                    />
                </Typography>
            </Grid>
            <Grid item container xs={3} justify="flex-end" alignItems="center">
                {isMultiClass && (
                    <CloseIcon
                        className={classes.item__icon}
                        onClick={() => onRemoveItemClick(itemIndex)}
                    />
                )}
            </Grid>

            <Grid item container xs={9} className={classes.itemInfo}>
                {isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormField}
                            name={`${prefix}.hazMatUnNumber`}
                            label={
                                <FormattedMessage
                                    id="items__unNumber"
                                    defaultMessage="UN #"
                                />
                            }
                            required
                            // onChange={e => doSearch(e)}
                        />
                        {/* <Popper
                            onMouseDown={() => this.handleContainerMouseDown()}
                            onMouseUp={() => this.handleContainerMouseUp()}
                            open={showItemOverlayUN}
                            anchorEl={anchorEl}
                            placement="bottom-end"
                            disablePortal
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                },
                            }}
                            className={classes.popper}
                        >
                            {get(this.props, "item.hazMatUnNumber.length") > 2 && (
                                <SuggestionBox
                                    term={item.hazMatUnNumber}
                                    origin={origin}
                                    originAddress={originAddress}
                                    onItemClick={this.onItemClick}
                                />
                            )}
                        </Popper> */}
                    </Grid>
                )}
                {isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormSelectAutocomplete}
                            name={`${prefix}.hazMatPkgGrp`}
                            label={
                                <FormattedMessage
                                    id="items__pkgGroup"
                                    defaultMessage="Pkg Group"
                                />
                            }
                            options={hazMatPkgGrp}
                            required
                        />
                    </Grid>
                )}
                {isHazMat && (
                    <Grid item container xs={4}>
                        <Field
                            component={FormSelectAutocomplete}
                            name={`${prefix}.hazMatContainer`}
                            label={
                                <FormattedMessage
                                    id="items__containerType"
                                    defaultMessage="Container Type"
                                />
                            }
                            options={provideHazMatContainers(intl)}
                            required
                        />
                    </Grid>
                )}
                {isHazMat && (
                    <Grid item container xs={12}>
                        <Field
                            component={FormSelectAutocomplete}
                            name={`${prefix}.hazMatClass`}
                            label={
                                <FormattedMessage
                                    id="items__hazClass"
                                    defaultMessage="Haz Class"
                                />
                            }
                            options={provideHazMatClasses(intl)}
                            required
                        />
                    </Grid>
                )}
                <Grid item container xs={8}>
                    <Field
                        component={FormField}
                        name={`${prefix}.description`}
                        label={
                            <FormattedMessage
                                id="items__description"
                                defaultMessage="Description"
                            />
                        }
                        // onChange={e => doSearch(e)}
                        required
                    />
                </Grid>
                <Grid item container xs={4}>
                    <Field
                        component={FormSelectAutocomplete}
                        name={`${prefix}.freightClass`}
                        label={
                            <FormattedMessage
                                id="items__freightClass"
                                defaultMessage="Freight Class"
                            />
                        }
                        options={freightClasses}
                        required={!isIntraMexico}
                    />
                </Grid>
                {(isIntl || isUSDomesticOffshoreWithItemCustoms) && (
                    <Grid item container>
                        <Grid item container xs={4}>
                            <Field
                                component={FormSelectAutocomplete}
                                name={`${prefix}.countryOfManufacture`}
                                label={
                                    <FormattedMessage
                                        id="items__countryOfManufacture"
                                        defaultMessage="Country Of Manufacture"
                                    />
                                }
                                options={provideLocaleSortedOptionsList(
                                    intl,
                                    countriesOfOrigin,
                                    ["US", "CA", "MX"]
                                )}
                                required
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                type="number"
                                component={FormField}
                                name={`${prefix}.unitPrice`}
                                label={
                                    <FormattedMessage
                                        id="items__unitPrice"
                                        defaultMessage="Unit Price ({preferredCurrencyCode})"
                                        values={{
                                            preferredCurrencyCode,
                                        }}
                                    />
                                }
                                required
                                // onBlur={updateShouldAES}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                component={FormField}
                                name={`${prefix}.harmonizedCode`}
                                label={
                                    <FormattedMessage
                                        id="items__hsCode"
                                        defaultMessage="HS Code"
                                    />
                                }
                            />
                        </Grid>
                    </Grid>
                )}
                {isMultiClass && (
                    <Grid item container xs={12}>
                        <Field
                            component={FormField}
                            name={`${prefix}.weight`}
                            label={
                                <FormattedMessage
                                    id="items__itemWeightPerHU"
                                    defaultMessage="Item Weight per H/U ({weightUnit})"
                                    values={{
                                        weightUnit,
                                    }}
                                />
                            }
                            required
                        />
                    </Grid>
                )}

                <Grid item xs={4}>
                    <Field
                        component={FormField}
                        name={`${prefix}.nmfcCode`}
                        placeholder={"xxxxxx"}
                        label={
                            <FormattedMessage
                                id="items__nmfc"
                                defaultMessage="NMFC"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <Field
                        component={FormField}
                        label={
                            <FormattedMessage
                                id="items__subclass"
                                defaultMessage="Subclass"
                            />
                        }
                        name={`${prefix}.nmfcSubclass`}
                        placeholder={"xx"}
                    />
                </Grid>
                {!isFreightDirect && !isFreightDirectReturns ? (
                    <Grid item container xs={4}>
                        <Field
                            component={FormField}
                            name={`${prefix}.pieces`}
                            label={piecesLabel}
                            value="1"
                            required
                        />
                    </Grid>
                ) : (
                    <Grid item container>
                        <FieldArray
                            name={`${prefix}.freightDirectPieces`}
                            component={FreightDirectPiece}
                            prefix={prefix}
                            classes={classes}
                            weightUnit={weightUnit}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid
                item
                container
                xs={3}
                className={classes.switchesContainer}
                alignContent="flex-start"
            >
                <Grid item container xs={6} alignItems="center">
                    <Field
                        component={FormSwitch}
                        name={`${prefix}.isHazMat`}
                        label={
                            <FormattedMessage
                                id="items__hazMat"
                                defaultMessage="HazMat"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const Item = formValues(({ prefix }) => ({
    weight: join(prefix, "weight"),
    isHazMat: join(prefix, "isHazMat"),
}))(ItemContainer)

export default withStyles(styles)(Item)
