import React, { Component } from "react"
import PropTypes from "prop-types"
import { FormControl } from "react-bootstrap"
import { ValidatedField } from "./index"
import { AccessorialList } from "./accessorial-list"

const validatedControlOnChange = (field, textLength, e, onChange) => {
    const value = e && e.target && e.target.value
    const length = value && value.length
    if (!textLength || !length) onChange(field, e)
    else if (textLength - length >= 0) {
        onChange(field, e)
    } else if (length && textLength) {
        e.target.value = value.slice(0, textLength)
        onChange(field, e)
    }
}

export const ValidatedControl = props => {
    const {
        name,
        field,
        placeholder,
        validation,
        value,
        onChange,
        required,
        Control,
        controlProps,
        outerStyle,
        elementStyle,
        titleStyle,
        accessorials,
        controlStyle,
        titleNeeded,
        editable,
        textLength,
        componentClass,
    } = props
    return (
        <ValidatedField
            className={outerStyle}
            name={field}
            validation={validation}
        >
            <div className={elementStyle}>
                {titleNeeded && (
                    <div className={titleStyle}>
                        <div className="left-margin">
                            {required && (
                                <span className="required-field">*</span>
                            )}
                            {name}:
                        </div>
                    </div>
                )}
                <div className={controlStyle}>
                    {accessorials.length > 0 && (
                        <AccessorialList
                            accessorials={accessorials}
                            className="row"
                        />
                    )}
                    {Control === FormControl ? (
                        <Control
                            type="text"
                            placeholder={placeholder}
                            value={value}
                            onChange={e =>
                                validatedControlOnChange(
                                    field,
                                    textLength,
                                    e,
                                    onChange
                                )
                            }
                            name={name}
                            disabled={!editable}
                            readOnly={!editable}
                            {...controlProps}
                            className={`${controlProps.className || ""}`}
                            componentClass={componentClass}
                        />
                    ) : (
                        <Control
                            type="text"
                            placeholder={name}
                            value={value}
                            onChange={e =>
                                validatedControlOnChange(
                                    field,
                                    textLength,
                                    e,
                                    onChange
                                )
                            }
                            disabled={!editable}
                            readOnly={!editable}
                            {...controlProps}
                            className={`${controlProps.className || ""}`}
                            name={name}
                            field={field}
                        />
                    )}
                    {textLength && value && value.length > 0 && editable && (
                        <div className="text-length {controlStyle}">
                            {`${textLength -
                                value.length} Characters remaining.`}
                        </div>
                    )}
                </div>
            </div>
        </ValidatedField>
    )
}

ValidatedControl.propTypes = {
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.any,
    validation: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    Control: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.element,
        PropTypes.instanceOf(Component),
    ]),
    controlProps: PropTypes.object,
    outerStyle: PropTypes.string,
    elementStyle: PropTypes.string,
    titleStyle: PropTypes.string,
    controlStyle: PropTypes.string,
    titleNeeded: PropTypes.bool,
    editable: PropTypes.bool,
    textLength: PropTypes.number,
    componentClass: PropTypes.string,
    accessorials: PropTypes.array,
}

ValidatedControl.defaultProps = {
    options: {},
    required: false,
    Control: FormControl,
    controlProps: {},
    outerStyle: "flex-one",
    elementStyle: "",
    titleStyle: "",
    controlStyle: "",
    titleNeeded: true,
    editable: true,
    validation: {},
    placeholder: "",
    textLength: undefined,
    componentClass: undefined,
    accessorials: [],
    value: "",
}
