import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { injectIntl, FormattedMessage } from "react-intl"
import { reduxForm, change, reset, getFormValues } from "redux-form"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/styles"
import { connect } from "react-redux"
import { changePath, requestAccessorials } from "../../../actions"
import { requestItems, searchItems } from "../../../actions/item"
import { constructSubLabel, constructLabel } from "../../util"
import { formSelector } from "./selector"
import {
    sortByPriority,
    formattedPriorityDeliveryAccessorials,
    formattedPriorityPickupAccessorials,
} from "../../quotesPage/accessorials"

import Spinner from "../../util/spinner"
import { searchContacts } from "../../../actions/contact"

import { requestPostalCodeQuickRate } from "../../../actions/postal-code"

import { newFreightDirectHandlingUnit } from "../../../reducers/search"
import {
    populateActiveRate,
    requestQuote,
} from "../../../actions/book-shipment-requests"
import {
    isRequired,
    isRequiredIf,
    nmfcFormValidator,
} from "../../../actions/validation"
import { merge } from "lodash"
import { combineValidators } from "revalidate"
import {
    addressValidator,
    addressQuickRateValidator,
    isRequiredIfAddressEmpty,
    handlingUnitsValidator,
} from "../bookShipment/validators"
import { useSnackbarContext } from "../../../context/providers/snackbarProvider"
import QuickRateForm from "./QuickRateForm"
import Rates from "../bookShipment/steps/Rates"
import { trackGAEventRR } from "../../../actions/user"
import QuickRateSummary from "./QuickRateSummary"
import moment from "moment"
import { setIsBookingQuickRate } from "../../../actions/redesign"
import { Button, DialogTitle } from "@material-ui/core"
import { intraMexicoFreightClassCheck } from "../bookShipment"
import FreightBoxRestrictionDialog from "../bookShipment/atoms/FreightBoxRestrictionDialog"

const useStyles = makeStyles({
    section: {
        paddingTop: "10px",
    },
    bigSection: {
        paddingTop: "30px",
    },
    baseContainer: {
        paddingTop: "32px",
        paddingBottom: "272px",
    },
    input__field: {
        //backgroundColor: "rgb(242, 242, 242)",
        cursor: "pointer",
        width: "100%",
    },
    textInput__field: {
        cursor: "text",
    },
    workflowContainer: {
        padding: "10px 10% 25px 10%",
    },
    basicInfo: {
        padding: "0 12%",
    },
    getRates: {
        paddingTop: "30px",
    },
    stepContainer: {
        paddingBottom: "45px",
    },
    containerForm: {
        display: "flex",
        width: "100%",
    },
})

const QuickRate = ({
    changeField,
    intl,
    locations,
    formattedLocations,
    resetForm,
    loadAccessorials,
    formValues = {},
    initialValues,
    shipmentId,
    activeQuote,
    performContactSearch,
    contactSearchInProgress,
    onPostalCodeChange,
    pickupAccessorialsList,
    deliveryAccessorialsList,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    loadItems,
    getRatesRequest,
    invalid,
    guaranteedList,
    changeRoute,
    trackGA,
    setIsBookingQR,
    loadRate,
    handleSubmit,
    submitting,
    originCities,
    destinationCities,
}) => {
    const classes = useStyles()
    const [currentStep, setCurrentStep] = useState(shipmentId ? 1 : 0)
    const [isLoading, setIsLoading] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [isHUOpen, setIsHUOpen] = useState(0)
    const { openSnackbar } = useSnackbarContext()
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isLoadingRate, setIsLoadingRate] = useState(false)
    const [isFreightBoxDialogOpen, setIsFreightBoxDialogOpen] = useState(false)

    const { shouldReset } = formValues

    useEffect(() => {
        trackGA("Quick Rate Flow", "Page Mount")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (shipmentId) {
            const fetchData = async () => {
                setIsLoadingRate(true)
                trackGA("Quick Rate Flow", "Load Existing Rate")
                await loadRate(shipmentId)
                setIsLoadingRate(false)
            }
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId])

    useEffect(() => {
        if (shouldReset) {
            trackGA(
                "Quick Rate Flow",
                "Reset from Nav Bar",
                `Current Step: ${currentStep}`
            )
            setCurrentStep(0)
            setIsBookingQR(false)
            setEditMode(false)
            setIsHUOpen(0)
            setIsDialogOpen(false)
        }

        changeField("shouldReset", false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldReset])

    useEffect(() => {
        loadAccessorials()
        loadItems()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addNewHandlingUnit = fields => {
        trackGA(
            "Quick Rate Flow",
            "Add New Handling Unit",
            `Current Length: ${fields.length}`
        )
        fields.push(newFreightDirectHandlingUnit())
        const id = fields.length
        setIsHUOpen(id > -1 ? id : undefined)
    }

    const handleHUPanel = index => {
        setIsHUOpen(isHUOpen === index ? null : index)
    }

    const inputProps = {
        classes: {
            root: classes.input__field,
            input: classes.input__field,
        },
    }

    const textInputProps = {
        classes: {
            root: classes.textInput__field,
            input: classes.textInput__field,
        },
    }

    const onBillToLocationChange = option => {
        const selectedLocation = locations.find(
            location => location.cpgCode === option.value
        )

        trackGA(
            "Quick Rate Flow",
            "BillToLocation Change",
            selectedLocation?.locationType
        )

        const isFreightDirectReturns =
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        const isFreightDirect =
            selectedLocation?.locationType === "FEDEX_DIRECT"

        const isFreightBox =
            selectedLocation?.locationType === "FEDEX_FREIGHT_BOX"

        let mode = "thirdParty"
        if (selectedLocation.isShipperEnabled) {
            mode = isFreightDirectReturns ? "inbound" : "outbound"
        }

        changeField("selectedLocation", selectedLocation)
        changeField("mode", mode)
        changeField("isFreightDirect", isFreightDirect)
        changeField("isFreightDirectReturns", isFreightDirectReturns)
        changeField("isFreightBox", isFreightBox)
        changeField("pickupDate", moment.utc(moment().format("YYYY-MM-DD")))

        if (isFreightBox) {
            const originCountry =
                formValues?.origin?.shippingAddress?.address?.country
            const destinationCountry =
                formValues?.destination?.shippingAddress?.address?.country

            if (originCountry !== "US" || destinationCountry !== "US") {
                setIsFreightBoxDialogOpen(true)
                const initialDestinationLocation = {
                    shippingAddress: {
                        address: {
                            country:
                                initialValues?.destination?.shippingAddress
                                    ?.address?.country,
                        },
                    },
                }
                changeField("destination", initialDestinationLocation)
            }
        }

        onDirectionChange(selectedLocation, mode, true)
    }

    const onDirectionChange = (location, mode, fromBilltoChange) => {
        const switchingFromThirdParty = formValues?.mode === "thirdParty"
        const initialOriginLocation = {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.origin?.shippingAddress?.address
                            ?.country,
                },
            },
        }
        const initialDestinationLocation = {
            shippingAddress: {
                address: {
                    country:
                        initialValues?.destination?.shippingAddress?.address
                            ?.country,
                },
            },
        }

        const locationPickupAccessorials = (
            location?.pickupAccessorials ?? []
        ).map(entry => entry.value) ?? ["DOCKPU"]

        const locationDeliveryAccessorials = (
            location?.deliveryAccessorials ?? []
        ).map(entry => entry.value) ?? ["DOCKDEL"]

        if (mode === "outbound") {
            changeField("origin", location)
            if (
                locationPickupAccessorials.toString() !== ["DOCKPU"].toString()
            ) {
                changeField("origin.showAccessorials", true)
                changeField("pickupAccessorials", locationPickupAccessorials)
            } else {
                changeField("origin.showAccessorials", false)
            }

            changeField("deliveryAccessorials", ["DOCKDEL"])
            if (!fromBilltoChange) {
                changeField(
                    "destination",
                    switchingFromThirdParty
                        ? initialDestinationLocation
                        : formValues?.origin
                )
            }
        } else if (mode === "inbound") {
            changeField("destination", location)
            if (
                locationDeliveryAccessorials.toString() !==
                ["DOCKDEL"].toString()
            ) {
                changeField("destination.showAccessorials", true)
                changeField(
                    "deliveryAccessorials",
                    locationDeliveryAccessorials
                )
            } else {
                changeField("destination.showAccessorials", false)
            }
            changeField("pickupAccessorials", ["DOCKPU"])
            if (!fromBilltoChange) {
                changeField(
                    "origin",
                    switchingFromThirdParty
                        ? initialOriginLocation
                        : formValues?.destination
                )
            }
        } else if (mode === "thirdParty") {
            changeField("origin", initialOriginLocation)
            changeField("destination", initialDestinationLocation)
            changeField("pickupAccessorials", ["DOCKPU"])
            changeField("deliveryAccessorials", ["DOCKDEL"])
            changeField("origin.showAccessorials", false)
            changeField("destination.showAccessorials", false)
        }
    }

    const getRates = async () => {
        trackGA("Quick Rate Flow", "Rate Request", `Editing: ${editMode}`)
        setIsLoading(true)
        let newFormValues = intraMexicoFreightClassCheck(formValues)
        try {
            await getRatesRequest(newFormValues)
            trackGA("Quick Rate Flow", "Rating Success", `Editing: ${editMode}`)
            setCurrentStep(1)
            setEditMode(false)
        } catch (err) {
            if (err?.props?.id && err?.props?.defaultMessage) {
                trackGA(
                    "Quick Rate Flow",
                    "Rating Error",
                    err?.props?.defaultMessage
                )
                openSnackbar("error", <FormattedMessage {...err?.props} />)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleComplete = () => {
        setIsDialogOpen(true)
        trackGA("Quick Rate Flow", "Dialog Open")
    }

    const confirmDialog = () => {
        setIsBookingQR(true)
        trackGA("Quick Rate Flow", "Dialog Confirm - Book Shipment Conversion")
        changeRoute("/book")
    }

    const handleEdit = () => {
        trackGA("Quick Rate Flow", "Begin Edit")
        setCurrentStep(0)
        setEditMode(true)
    }

    const handleCancel = () => {
        trackGA("Quick Rate Flow", "Cancel Edit")
        setCurrentStep(1)
        setEditMode(false)
    }

    const subProps = {
        currentStep,
        inputProps,
        textInputProps,
        initialValues,
        formValues,
        setIsLoading,
        permissions: formValues?.selectedLocation?.users[0].permissions,
        shipmentId,
        activeQuote,
        changeField,
        performContactSearch,
        contactSearchInProgress,
        intl,
        onPostalCodeChange,
        addNewHandlingUnit,
        handleHUPanel,
    }

    if (isLoadingRate) {
        return <Spinner />
    }

    return (
        <form
            onSubmit={handleSubmit(() => {})}
            className={classes.containerForm}
        >
            <Grid container justify="center" className={classes.baseContainer}>
                <FreightBoxRestrictionDialog
                    open={isFreightBoxDialogOpen}
                    handleClose={() => setIsFreightBoxDialogOpen(false)}
                />
                <Dialog
                    className={classes.dialog}
                    open={isDialogOpen}
                    onClose={() => setIsDialogOpen(false)}
                >
                    <DialogTitle id="alert-dialog-title">
                        <Typography variant="title">
                            <FormattedMessage
                                id="quickRate__instructionsTitle"
                                defaultMessage="Booking with a Quick Rate"
                            />
                        </Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialog__content}>
                        <Typography>
                            <FormattedMessage
                                id="quickRate__instructions"
                                defaultMessage="In order to book a shipment based on this quick rate, you will be taken through the Book Shipment 
                            workflow in order to gather additional information. You will not need to re-enter anything you've already entered, but your rates may be subject to change due to the new information.  Continue?"
                            />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="secondary"
                            onClick={() => {
                                trackGA("Quick Rate Flow", "Dialog Cancel")
                                setIsDialogOpen(false)
                            }}
                        >
                            <FormattedMessage
                                id="generalTerms__cancel"
                                defaultMessage="Cancel"
                            />
                        </Button>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => confirmDialog()}
                        >
                            <FormattedMessage
                                id="generalTerms__continue"
                                defaultMessage="Continue"
                            />
                        </Button>
                    </DialogActions>
                </Dialog>
                {isLoading && <Spinner />}
                <Grid item container justify="center">
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.quickRate__title"
                            defaultMessage="Tell us about your shipment."
                        />
                    </Typography>
                </Grid>
                <Grid item container className={classes.workflowContainer}>
                    {currentStep === 0 && (
                        <Grid item container className={classes.section}>
                            <QuickRateForm
                                {...{
                                    subProps,
                                    changeField,
                                    onBillToLocationChange,
                                    onDirectionChange,
                                    pickupAccessorialsList,
                                    deliveryAccessorialsList,
                                    formattedLocations,
                                    initialValues,
                                    invalid,
                                    inputProps,
                                    intl,
                                    handleHUPanel,
                                    isHUOpen,
                                    getRates,
                                    itemsIsLoaded,
                                    favoriteItems,
                                    doSearch,
                                    addNewHandlingUnit,
                                    trackGA,
                                    handleCancel,
                                    editMode,
                                    submitting,
                                    originCities,
                                    destinationCities,
                                }}
                            />
                        </Grid>
                    )}
                    {currentStep === 1 && (
                        <Grid item container>
                            <Grid
                                item
                                container
                                className={classes.stepContainer}
                            >
                                <QuickRateSummary
                                    handleEdit={handleEdit}
                                    formValues={formValues}
                                    pickupAccessorialsList={
                                        pickupAccessorialsList
                                    }
                                    deliveryAccessorialsList={
                                        deliveryAccessorialsList
                                    }
                                    intl={intl}
                                />
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.stepContainer}
                            >
                                <Rates
                                    {...{
                                        isQuickRate: true,
                                        guaranteedList,
                                        activeQuote,
                                        changeField,
                                        inputProps,
                                        textInputProps,
                                        permissions:
                                            formValues?.selectedLocation
                                                ?.users[0].permissions,
                                        setIsLoading,
                                        shipmentId,
                                        initialValues,
                                        formValues,
                                        handleComplete,
                                        trackGA,
                                        setEditMode,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}

const mapStateToProps = (state, props) => {
    const locations = state?.user?.profile?.locations ?? []

    const formValues = getFormValues("quickRate")(state)

    const formattedLocations = locations
        .filter(
            item =>
                item?.users[0]?.permissions?.createRates?.value &&
                !item?.users[0]?.permissions?.suspended?.value &&
                !item.isPending
        )
        .map(item => {
            const { city, state: addressState } =
                item?.shippingAddress?.address ?? {}
            const defaultNickname = `${item?.shippingAddress?.name} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    props.intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
                isFreightBox: item.locationType === "FEDEX_FREIGHT_BOX",
            }
        })
    const shipmentId = props?.computedMatch?.params?.shipmentId
    const language = state?.user?.profile?.preferences?.language

    const contactSearchInProgress = state?.contact?.searchInProgress

    const originCountry = formValues?.origin?.shippingAddress?.address?.country
    const destinationCountry =
        formValues?.destination?.shippingAddress?.address?.country

    const basePickupAccessorialList = state.accessorials.list.pickup.filter(
        x =>
            originCountry !== "MX" ||
            formattedPriorityPickupAccessorials.some(y => y?.value === x?.value)
    )

    const baseDeliveryAccessorialList = state.accessorials.list.delivery.filter(
        x =>
            destinationCountry !== "MX" ||
            formattedPriorityDeliveryAccessorials.some(
                y => y?.value === x?.value
            )
    )

    return {
        shipmentId,
        contactSearchInProgress,
        locations,
        formattedLocations,
        initialValues: formSelector(state, props),
        formValues,
        guaranteedList: state.accessorials.list.guaranteed,
        activeQuote: state?.redesign?.activeRates[shipmentId],
        pickupAccessorialsList: sortByPriority(
            basePickupAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityPickupAccessorials
        ),
        deliveryAccessorialsList: sortByPriority(
            baseDeliveryAccessorialList.map(entry => ({
                value: entry?.value,
                label: entry?.label[language],
            })),
            formattedPriorityDeliveryAccessorials
        ),
        itemsIsLoaded: state.item.isLoaded,
        favoriteItems: (state.item.elements ?? []).slice(1, 51),
        originCities:
            state?.postalCode[
                `${originCountry}-${formValues?.origin?.shippingAddress?.address?.postal_code}`
            ]?.cities,
        destinationCities:
            state?.postalCode[
                `${destinationCountry}-${formValues?.destination?.shippingAddress?.address?.postal_code}`
            ]?.cities,
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    loadRate: shipmentId => dispatch(populateActiveRate(shipmentId)),
    changeField: (field, value) => dispatch(change("quickRate", field, value)),
    getRatesRequest: async payload => dispatch(requestQuote(payload)),
    loadItems: () => dispatch(requestItems()),
    changeRoute: path => dispatch(changePath(path)),
    resetForm: form => dispatch(reset(form)),
    loadAccessorials: () => dispatch(requestAccessorials()),
    performContactSearch: (value, country) =>
        dispatch(searchContacts(value, "quote", undefined, country)),
    onPostalCodeChange: (value, field, country, fetchTimezone) =>
        dispatch(
            requestPostalCodeQuickRate(value, { field, country, fetchTimezone })
        ),
    doSearch: (value, origin, originAddress) =>
        dispatch(searchItems(value, origin, originAddress)),
    trackGA: (category, action, label, value) =>
        dispatch(trackGAEventRR(category, action, label, value)),
    setIsBookingQR: value => dispatch(setIsBookingQuickRate(value)),
})

export const quickRateFormValidation = (values, props) => {
    const mainValidation = combineValidators({
        billToLocation: isRequired({
            field: {
                id: "getRates.form__billToLocation",
                defaultMessage: "Bill to Location",
            },
        }),
        pickupDate: isRequired({
            field: {
                id: "getRates.form__shipDate",
                defaultMessage: "Ship Date",
            },
        }),
        origin: {
            shippingAddress:
                values?.mode === "outbound"
                    ? addressValidator("origin.shippingAddress")
                    : addressQuickRateValidator(),
            searchContact: isRequiredIfAddressEmpty("origin")({
                field: {
                    id: "bookShipment.form__zipOrCompanyName",
                    defaultMessage: "Search Zip or Company Name",
                },
            }),
        },
        destination: {
            shippingAddress:
                values?.mode === "inbound"
                    ? addressValidator("destination.shippingAddress")
                    : addressQuickRateValidator(),
            searchContact: isRequiredIfAddressEmpty("destination")({
                field: {
                    id: "bookShipment.form__zipOrCompanyName",
                    defaultMessage: "Search Zip or Company Name",
                },
            }),
        },
        totalLinearFeet: isRequiredIf(values => {
            return values?.isAdvancedItems
        })({
            field: {
                id: "getRates.form__estLinearFeet",
                defaultMessage: "Est. Linear Feet",
            },
        }),
        ...handlingUnitsValidator,
    })(values, props)
    return merge({}, mainValidation, nmfcFormValidator(values))
}

export default injectIntl(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(
        reduxForm({
            // a unique name for the form
            form: "quickRate",
            validate: quickRateFormValidation,
            destroyOnUnmount: false,
            forceUnregisterOnUnmount: true,
            enableReinitialize: true,
        })(QuickRate)
    )
)
