import ReactGA from "react-ga"
import moment from "moment-timezone"
import { get } from "lodash"
import uuidv1 from "uuid/v1"
import EventEmitter from "event-emitter"
import { goFetch, goHealthFetch } from "../http"
import { trackGAEvent } from "./user"
import history from "./history"

export const ERROR_MESSAGE = "ERROR_MESSAGE"
export const DISMISS_ERROR = "DISMISS_ERROR"
export const DISMISS_ALL_ERRORS = "DISMISS_ALL_ERRORS"
export const ACCESSORIALS_LOAD_ERROR = "ACCESSORIALS_LOAD_ERROR"
export const ACCESSORIALS_RESULT = "ACCESSORIALS_RESULT"
export const ACCESSORIALS_LOAD = "ACCESSORIALS_LOAD"
export const CHANGE_DASHBOARD_TAB = "CHANGE_DASHBOARD_TAB"
export const SEARCH_TERM_CHANGE = "SEARCH_TERM_CHANGE"
export const SAVE_SEARCH = "SAVE_SEARCH"
export const REMOVE_SEARCH = "REMOVE_SEARCH"
export const START_TIMER = "START_TIMER"
export const END_TIMER = "END_TIMER"
export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const RELEASE_REQUEST = "RELEASE_REQUEST"
export const TOGGLE_TABLE_FILTER = "TOGGLE_TABLE_FILTER"
export const SET_TABLE_FILTER_TO_SHIPMENTS = "SET_TABLE_FILTER_TO_SHIPMENTS"
export const FLAG_NEW_VERSION_AVAILABLE = "FLAG_NEW_VERSION_AVAILABLE"

export const USER_INPUT_ENDED_DELAY = 500

const determineCPG = (state, { shipment, quote } = {}) => {
    if (shipment) {
        const item = state.shipment.list[shipment]
        return item && item.search && item.search.cpg
    } else if (quote) {
        const item = state.quotes.list.items[quote]
        return item && item.search && item.search.cpg
    }
    const { locations = [] } = (state && state.user && state.user.profile) || {}
    const { cpgCode } = locations.find(item => item.isDefault) || {}
    return cpgCode
}

export function createGAEvent(
    category,
    action,
    label = "",
    { shipment, quote, cpg } = {}
) {
    return async (dispatch, getState) => {
        const cpgCode = cpg || determineCPG(getState(), { shipment, quote })
        dispatch(
            trackGAEvent(
                category,
                action,
                [cpgCode, label].filter(x => x).join(", ")
            )
        )
    }
}

export function errorMessage(error = "") {
    return dispatch => {
        if (get(error, "name") === "ResponseError") {
            dispatch(
                createGAEvent("System", "Error", get(error, "trackingLabel"))
            )
        } else {
            dispatch(
                createGAEvent(
                    "System",
                    "Error",
                    `Location: ${window.location.href}, Error:${error}`
                )
            )
        }
        dispatch({ type: ERROR_MESSAGE, error })
    }
}

export function toggleTableFilter(kind) {
    return { type: TOGGLE_TABLE_FILTER, kind }
}

export function setTableFilterToShipments() {
    return { type: SET_TABLE_FILTER_TO_SHIPMENTS }
}

export function flagNewVersionAvailable() {
    return { type: FLAG_NEW_VERSION_AVAILABLE }
}

export function dismissError(index) {
    return { type: DISMISS_ERROR, index }
}

export function dismissAllErrors() {
    return { type: DISMISS_ALL_ERRORS }
}

export function typedError(type, error, ...props) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({ type, error, ...props })
    }
}

function shouldRequestAccessorials(state) {
    return !state.accessorials.isLoaded && !state.accessorials.isFetching
}

function accessorialsError(error) {
    return dispatch => {
        dispatch(errorMessage(error))
        dispatch({
            type: ACCESSORIALS_LOAD_ERROR,
            error,
        })
    }
}

const accessorialsResult = accessorials => ({
    type: ACCESSORIALS_RESULT,
    accessorials,
})
const accessorialsLoad = () => ({ type: ACCESSORIALS_LOAD })

export function requestAccessorials() {
    return async (dispatch, getState) => {
        if (!shouldRequestAccessorials(getState())) return
        const processData = async () => {
            dispatch(accessorialsLoad())
            try {
                const { data: accessorials } = await goFetch(
                    "/accessorial/",
                    { method: "GET", credentials: "same-origin" },
                    true
                )
                dispatch(accessorialsResult(accessorials))
            } catch (err) {
                dispatch(accessorialsError(err))
            }
        }
        const id = "accessorials"
        return dispatch(uniqueRequest(id, processData))
    }
}

export const changeDashboardTab = (active, index) => ({
    type: CHANGE_DASHBOARD_TAB,
    active,
    index,
})

export const removeSearch = index => ({ type: REMOVE_SEARCH, index })

export function checkAPIConnection() {
    return async dispatch => {
        try {
            await goHealthFetch("/actuator/health", {
                method: "GET",
                credentials: "same-origin",
                headers: { "cache-control": "no-cache" },
            })
            return true
        } catch (_) {
            return false
        }
    }
}

export function changePath(path, replace = false, canReload = true) {
    return (dispatch, getState) => {
        const { newVersionAvailable } = getState()
        const lastPath = history?.location?.pathname

        if (lastPath !== path) {
            if (canReload && newVersionAvailable) {
                if (replace) {
                    window.location.replace("/#" + path)
                } else {
                    window.location.assign("/#" + path)
                }
                window.location.reload(true)
            } else {
                if (replace) {
                    history.replace(path)
                } else {
                    history.push(path)
                }
            }
        }
    }
}

export function pushPath(path) {
    return (dispatch, getState) => {
        history.push(path)
    }
}
export function goBack() {
    return (dispatch, getState) => {
        const { router } = getState()
        if (router) history.goBack()
    }
}

export function saveSearch() {
    return (dispatch, getState) => {
        if (getState().dashboard.search.list.length >= 3) return null
        dispatch(trackGAEvent("Dashboard", "Smart search use"))
        return dispatch({ type: SAVE_SEARCH })
    }
}

export function stringLocation(data, includeCountry = true) {
    if (!data) return null
    return [
        data.street1,
        data.street2,
        data.city && `${data.city},`,
        data.state,
        data.postalCode,
        includeCountry ? data?.country ?? "US" : null,
    ]
        .filter(x => x)
        .join(" ")
}

export function startTimer(label) {
    return dispatch => {
        const id = label || uuidv1()
        dispatch({ type: START_TIMER, id, time: moment() })
        return id
    }
}

export function endTimer(id, category, variable, label = "") {
    return (dispatch, getState) => {
        const endTime = moment()
        const startTime = getState().timers[id]
        if (!startTime) return
        const duration = endTime.diff(startTime)
        const cpg = determineCPG(getState())
        ReactGA.timing({
            category,
            variable,
            value: duration,
            label: [endTime.toDate(), cpg, label].filter(x => x).join(", "),
        })
        dispatch({ type: END_TIMER, id })
    }
}

export function uniqueRequest(id, func) {
    return async (dispatch, getState) => {
        const requestInProgress = getState().requests[id]
        if (requestInProgress) {
            return new Promise((resolve, reject) => {
                requestInProgress.on("done", result => resolve(result))
                requestInProgress.on("error", error => reject(error))
            })
        }
        const emitter = new EventEmitter()
        dispatch({ type: REGISTER_REQUEST, id, emitter })
        try {
            const result = await func()
            emitter.emit("done", result)
            return result
        } catch (error) {
            emitter.emit("error", error)
            throw error
        } finally {
            dispatch({ type: RELEASE_REQUEST, id })
        }
    }
}
