import React, { Fragment } from "react"
import {
    Grid,
    Typography,
    Card,
    CardHeader,
    CardContent,
} from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import { Field, FieldArray, FormSection, formValues } from "redux-form"
import FormSelectAutocomplete from "../../form/form-select-autocomplete"
import { formattedPurposeOfShipment } from "../../book/SupportDocumentationForm"
import { FormattedMessage, injectIntl } from "react-intl"
import FormField from "../../form/form-field"
import FormDatePicker from "../../form/form-datepicker"
import ManageSignature from "../ManageSignature"
import { weightUnitPluralCapital } from "../../util/units"

const styles = theme => ({
    card: {
        height: "100%",
        overflow: "visible",
    },
    content: {
        "margin-left": "60px",
    },
    signature_button: {
        marginTop: "12px",
    },
})

const CertificateOfOriginBOLForm = withStyles(styles)(
    formValues({
        preferredSystemOfMeasurement: "preferredSystemOfMeasurement",
        notaryStamp: "notaryStamp",
    })(
        injectIntl(
            ({ classes, intl, preferredSystemOfMeasurement, notaryStamp }) => (
                <Grid xs={12} item alignContent="center" alignItems="center">
                    <Card className={classes.card}>
                        <CardHeader
                            title={
                                <Typography variant="headline">
                                    <FormattedMessage
                                        id="orderDetails.certificate__of__origin__title"
                                        defaultMessage="Certificate of Origin"
                                    />
                                </Typography>
                            }
                        />
                        <CardContent className={classes.content}>
                            <Grid container>
                                <FieldArray
                                    name="certificateOfOriginLineItems"
                                    component={HandlingUnitListPresentation}
                                    props={{
                                        weightUnit: weightUnitPluralCapital(
                                            preferredSystemOfMeasurement
                                        ),
                                    }}
                                />
                                <Grid item container xs={4}>
                                    <Field
                                        component={FormSelectAutocomplete}
                                        name="shipmentPurpose"
                                        options={formattedPurposeOfShipment}
                                        label={
                                            <FormattedMessage
                                                id="certificateOfOrigin.select_purpose"
                                                defaultMessage="Select Purpose Of Shipment:"
                                            />
                                        }
                                        required={notaryStamp}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="datedAt"
                                        component={FormField}
                                        type="text"
                                        label={
                                            <FormattedMessage
                                                id="certificateOfOrigin.datedAt"
                                                defaultMessage="Dated at:"
                                            />
                                        }
                                        required={notaryStamp}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="swornDate"
                                        component={FormDatePicker}
                                        dateFormat="ll"
                                        noMinDate
                                        disableFuture
                                        label={
                                            <FormattedMessage
                                                id="certificateOfOrigin.swornDate"
                                                defaultMessage="Sworn on:"
                                            />
                                        }
                                        required={notaryStamp}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="chamberOfCommerce.name"
                                        type="text"
                                        component={FormField}
                                        label={
                                            <FormattedMessage
                                                id="certificateOfOrigin.chamberOfCommerce__name"
                                                defaultMessage="Chamber of Commerce Name:"
                                            />
                                        }
                                        required={notaryStamp}
                                    />
                                </Grid>
                                <Grid item container xs={4}>
                                    <Field
                                        name="chamberOfCommerce.state"
                                        type="text"
                                        component={FormField}
                                        label={
                                            <FormattedMessage
                                                id="certificateOfOrigin.chamberOfCommerce__state"
                                                defaultMessage="Chamber of Commerce State:"
                                            />
                                        }
                                        required={notaryStamp}
                                    />
                                </Grid>
                                <SignatureDetails />
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            )
        )
    )
)
const SignatureDetails = withStyles(styles)(({ classes }) => (
    <Grid
        className={classes.signature_button}
        item
        container
        alignItems="flex-start"
        spacing={16}
    >
        <Grid item xs={4}>
            <ManageSignature
                fieldName="exporterSignatureImage"
                previewDimensions={{
                    width: 223,
                    height: 44,
                }}
                addTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__addExporterSignature"
                        defaultMessage="Add Exporter Signature"
                    />
                }
                replaceTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__replaceExporterSignature"
                        defaultMessage="Replace Exporter Signature"
                    />
                }
            />
        </Grid>
        <Grid item xs={4}>
            <ManageSignature
                fieldName="notaryStamp"
                previewDimensions={{
                    width: 188,
                    height: 38,
                }}
                addTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__addNotaryStamp"
                        defaultMessage="Add Notary Stamp"
                    />
                }
                replaceTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__replaceNotaryStamp"
                        defaultMessage="Replace Notary Stamp"
                    />
                }
            />
        </Grid>
        <Grid item xs={4}>
            <ManageSignature
                fieldName="secretarySignatureImage"
                previewDimensions={{
                    width: 186,
                    height: 42,
                }}
                addTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__addSecretarySignature"
                        defaultMessage="Add Secretary Signature"
                    />
                }
                replaceTitle={
                    <FormattedMessage
                        id="locations.signatureManagment__replaceSecretarySignature"
                        defaultMessage="Replace Secretary Signature"
                    />
                }
            />
        </Grid>
    </Grid>
))

const HandlingUnitItemFields = formValues({ itemName: "description" })(
    injectIntl(({ intl, itemName, weightUnit }) => (
        <Grid item container direction="row">
            <Grid item container xs={4}>
                <Field
                    name="numberOfPackages"
                    type="number"
                    required={true}
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.no__of__packages_w_item_name"
                            defaultMessage="{itemName} No. of Packages:"
                            values={{ itemName }}
                        />
                    }
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name="grossWeight.amount"
                    type="number"
                    required
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.gross__weight_w_item_name"
                            defaultMessage="{itemName} Gross Weight ({preferredSystemOfMeasurement}):"
                            values={{
                                itemName,
                                preferredSystemOfMeasurement: weightUnit,
                            }}
                        />
                    }
                />
            </Grid>
            <Grid item container xs={4}>
                <Field
                    name="netWeight.amount"
                    type="number"
                    required
                    component={FormField}
                    label={
                        <FormattedMessage
                            id="certificateOfOrigin.net__weight_w_item_name"
                            defaultMessage="{itemName} Net Weight ({preferredSystemOfMeasurement}):"
                            values={{
                                itemName,
                                preferredSystemOfMeasurement: weightUnit,
                            }}
                        />
                    }
                />
            </Grid>
        </Grid>
    ))
)

const HandlingUnitListPresentation = ({ fields, weightUnit }) => (
    <Fragment>
        {fields.map(prefix => (
            <FormSection name={prefix} key={prefix} component={Fragment}>
                <HandlingUnitItemFields weightUnit={weightUnit} />
            </FormSection>
        ))}
    </Fragment>
)

export default CertificateOfOriginBOLForm
