import React from "react"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

export default {
    id: "locations",
    steps: {
        owner: [
            {
                title: (
                    <FormattedMessage
                        id="tutorial.locations__header"
                        defaultMessage="Tutorial: Locations"
                    />
                ),
                target: "#locationLeft",
                content: (
                    <Grid container>
                        <Typography variant="subheading" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations__title"
                                defaultMessage="Welcome to the Locations tutorial"
                            />
                        </Typography>
                        <Typography align="left" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations__content1"
                                defaultMessage="Here's where you'll find your locations on LTL Select. Our new layout will help you organize and set up your locations more easily."
                            />
                        </Typography>
                        <Typography align="left">
                            <FormattedMessage
                                id="tutorial.locations__content2"
                                defaultMessage="On the left you will find a list of all the locations you have set up. Select any location to see its associated account information, addresses, users, and carriers."
                            />
                        </Typography>
                    </Grid>
                ),
                disableBeacon: true,
            },
            {
                title: (
                    <FormattedMessage
                        id="tutorial.locations__header"
                        defaultMessage="Tutorial: Locations"
                    />
                ),
                target: "#locationDetails",
                content: (
                    <Grid container>
                        <Typography variant="subheading" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.details__title"
                                defaultMessage="Location Details"
                            />
                        </Typography>
                        <Typography align="left" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.details__content1"
                                defaultMessage="This is where all the information related to a location is stored. To edit, simply make your changes in the text. Then click the 'Update' button at the top of the right page."
                            />
                        </Typography>
                        <Typography align="left" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.details__content2"
                                defaultMessage="You can designate one location as your default shipment origin. To do so, switch over the 'Set Default' toggle."
                            />
                        </Typography>
                        <Typography align="left" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.details__content3"
                                defaultMessage="If you do not need to have a location listed anymore, you can 'Unsubscribe' from it in the top right corner of the page."
                            />
                        </Typography>
                    </Grid>
                ),
            },
            {
                title: (
                    <FormattedMessage
                        id="tutorial.locations__header"
                        defaultMessage="Tutorial: Locations"
                    />
                ),
                target: "#addCarrierButton",
                content: (
                    <Grid container>
                        <Typography variant="subheading" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.addCarrier__title"
                                defaultMessage="Add Carrier"
                            />
                        </Typography>
                        <Typography align="left">
                            <FormattedMessage
                                id="tutorial.locations.addCarrier__content"
                                defaultMessage="This is where you can add a new carrier to the location. You will be redirected to p44, a FedEx certified Compatible Provider, to add your other carriers into LTL Select."
                            />
                        </Typography>
                    </Grid>
                ),
            },
            {
                title: (
                    <FormattedMessage
                        id="tutorial.locations__header"
                        defaultMessage="Tutorial: Locations"
                    />
                ),
                target: "#manageUsers",
                content: (
                    <Grid container>
                        <Typography variant="subheading" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.manageUsers__title"
                                defaultMessage="Managing Users"
                            />
                        </Typography>
                        <Typography align="left" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.manageUsers__content1"
                                defaultMessage="This box allows you to view, manage, and add users to this location."
                            />
                        </Typography>
                        <Typography align="left">
                            <FormattedMessage
                                id="tutorial.locations.manageUsers__content2"
                                defaultMessage="Clicking on the row of a specific user will allow you to manage their role and permissions."
                            />
                        </Typography>
                    </Grid>
                ),
            },
            {
                title: (
                    <FormattedMessage
                        id="tutorial.locations__header"
                        defaultMessage="Tutorial: Locations"
                    />
                ),
                target: "#addLocationButton",
                content: (
                    <Grid container>
                        <Typography variant="subheading" gutterBottom>
                            <FormattedMessage
                                id="tutorial.locations.addLocation__title"
                                defaultMessage="Add Location"
                            />
                        </Typography>
                        <Typography align="left">
                            <FormattedMessage
                                id="tutorial.locations.addLocation__content"
                                defaultMessage="You can add a new location by clicking this button at the bottom left of the page."
                            />
                        </Typography>
                    </Grid>
                ),
            },
        ],
    },
}
