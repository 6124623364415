import React, { Fragment } from "react"
import HandlingUnit from "./HandlingUnit"

export const QuickHandlingUnits = ({
    fields,
    handlePanel,
    isOpen,
    origin,
    originAddress,
    setHandlingUnitMode,
    determineEstimatedLinearFeet,
    isFreightDirect,
    isFreightDirectReturns,
    isFreightBox,
    ...props
}) => (
    <Fragment>
        {fields.map((hu, index) => (
            <HandlingUnit
                {...props}
                isFreightDirect={isFreightDirect}
                isFreightDirectReturns={isFreightDirectReturns}
                isFreightBox={isFreightBox}
                prefix={hu}
                key={index}
                index={index}
                origin={origin}
                originAddress={originAddress}
                onRemoveClick={e => {
                    e.stopPropagation()
                    fields.remove(index)
                    // setHandlingUnitMode(true)
                    // determineEstimatedLinearFeet()
                }}
                handlePanel={() => handlePanel(index)}
                setHandlingUnitMode={setHandlingUnitMode}
                isOpen={isOpen === index}
            />
        ))}
    </Fragment>
)
