import React, { Component } from "react"
import { get } from "lodash"
import { connect } from "react-redux"
import { withStyles, Grid, Chip, Avatar, Button } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { arrayPush, arrayRemove, Field, resetSection } from "redux-form"
import CancelIcon from "@material-ui/icons/Cancel"
import EmailIcon from "@material-ui/icons/Email"
import Paper from "@material-ui/core/Paper"
import FormField from "../../form/form-field"
import AlertRecipientsField from "../../form/form-select-recipients-alert"
import { requestContactAddresses } from "../../../actions/address"
import createSelector from "re-reselect"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    addRecipients__container: {
        width: "800px",
    },
    button__container: {
        marginTop: "10px",
    },
    margin: {
        margin: theme.spacing.unit,
    },
    chip: {
        margin: "20px 10px",
        backgroundColor: theme.palette.primary.light,
        color: "white",
    },
    chip__avatar: {
        color: "white",
        backgroundColor: "transparent",
    },
    select__container: {
        padding: "10px",
        width: "90%",
        minHeight: "225px",
    },
    select__container__direct: {
        padding: "10px",
        width: "90%",
        minHeight: "105px",
    },
    add__recipient: {
        marginTop: "5px",
    },
    title: {
        marginTop: "10px",
    },
    or__text: {
        marginTop: "18px",
    },
})

const mapRecipients = contacts => {
    const recipients = []
    contacts.forEach(contact => {
        const contactInfo = get(contact, "contact", {})
        recipients.push({
            values: [
                {
                    type: "phone",
                    value: contactInfo.phone.phoneNumber || "",
                },
                {
                    type: "email",
                    value: contactInfo.email.emailAddress || "",
                },
            ],
            label: contactInfo.name,
        })
    })
    return recipients
}

const mapUserLocationRecipients = users => {
    const recipients = []
    users.forEach(user => {
        recipients.push({
            values: [
                {
                    type: "phone",
                    value: user?.user?.phone_number?.phone_number ?? "",
                },
                {
                    type: "email",
                    value: user?.user?.email?.email_address ?? "",
                },
            ],
            label: `${user?.user?.firstname ??
                user?.user?.email?.email_address ??
                ""}  ${user?.lastname ?? ""}`,
        })
    })
    return recipients
}

class AddRecipients extends Component {
    state = {
        contactsLoaded: get(this.props, "contacts.length", 0) > 0,
    }

    async componentDidMount() {
        if (this.state.contactsLoaded) return
        try {
            await this.props.requestContactAddresses()
        } finally {
            this.setState({ contactsLoaded: true })
        }
    }

    render() {
        const {
            classes,
            addRecipient,
            addNewRecipient,
            onDelete,
            contacts,
            selectedRecipients,
            newEmailRecipient,
            emailError,
            isFreightDirect,
        } = this.props
        const { contactsLoaded } = this.state
        return (
            <Grid className={classes.addRecipients__container}>
                <Grid item container xs={12}>
                    <Grid item container xs={5}>
                        <Paper
                            className={
                                isFreightDirect
                                    ? classes.select__container__direct
                                    : classes.select__container
                            }
                        >
                            <Grid item>
                                <Field
                                    component={AlertRecipientsField}
                                    name="recipients"
                                    placeholder={
                                        isFreightDirect ? (
                                            <FormattedMessage
                                                id="locations.addRecipients__selectFromUsers"
                                                defaultMessage="Select From Users"
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id="locations.addRecipients__selectFromContact"
                                                defaultMessage="Select From Contact"
                                            />
                                        )
                                    }
                                    options={contacts}
                                    isLoading={!contactsLoaded}
                                    inputProps={{
                                        isLoading: !contactsLoaded,
                                    }}
                                    onChange={(e, value) => addRecipient(value)}
                                />
                            </Grid>
                            {!isFreightDirect && (
                                <React.Fragment>
                                    <Typography className={classes.or__text}>
                                        <FormattedMessage
                                            id="generalTerms__or"
                                            defaultMessage="Or"
                                        />
                                    </Typography>
                                    <Grid item>
                                        <Field
                                            id="addUser__addNewEmail"
                                            component={FormField}
                                            name="newEmailRecipient"
                                            label={
                                                <FormattedMessage
                                                    id="locations.addRecipients__addNewEmail"
                                                    defaultMessage="Add New Email Address"
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify="flex-end"
                                        className={classes.add__recipient}
                                    >
                                        <Button
                                            variant="contained"
                                            disabled={!!emailError}
                                            onClick={() =>
                                                addNewRecipient(
                                                    newEmailRecipient
                                                )
                                            }
                                            id="addUser__addRecipient"
                                        >
                                            {
                                                <FormattedMessage
                                                    id="locations.addRecipients__addRecipient"
                                                    defaultMessage="Add Recipient"
                                                />
                                            }
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item container direction="column" xs={7}>
                        <Grid item>
                            {selectedRecipients &&
                                selectedRecipients.map((contact, i) => (
                                    <Chip
                                        tabIndex={-1}
                                        avatar={
                                            <Avatar
                                                className={classes.chip__avatar}
                                            >
                                                <EmailIcon />
                                            </Avatar>
                                        }
                                        key={i}
                                        label={contact.label}
                                        className={classes.chip}
                                        deleteIcon={<CancelIcon />}
                                        onDelete={() => onDelete(i)}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const getNonDuplicateContacts = (contactList, selectedRecipients) => {
    const newContacts = []
    contactList.forEach(c => {
        let contactEmail = c.values.find(x => x.type === "email").value
        const emailSelected = selectedRecipients.find(
            s =>
                get(s, "value.type", "") === "email" &&
                s.value.value === contactEmail
        )
        const newOption = {
            values: [
                ...(!emailSelected
                    ? [
                          {
                              value: contactEmail,
                              type: "email",
                          },
                      ]
                    : []),
            ],
            label: c.label,
        }
        if (newOption.values.length) newContacts.push(newOption)
    })
    return newContacts
}

const mapStateToProps = createSelector(
    state => state.addressBook.addresses,
    (state, props) =>
        get(state, `form.${props.formName}.syncErrors.newEmailRecipient`),
    (state, props) =>
        get(state, `form.${props.formName}.values.selectedRecipients`, []),
    (state, props) =>
        get(state, `form.${props.formName}.values.newEmailRecipient`),
    (state, props) => get(props, `location`),
    (state, props) => get(props, `isFreightDirect`),
    (
        contacts,
        emailError,
        selectedRecipients,
        newEmailRecipient,
        location,
        isFreightDirect
    ) => {
        let mappedContacts = mapRecipients(contacts)
        if (isFreightDirect) {
            mappedContacts = mapUserLocationRecipients(location?.users)
        }

        return {
            contacts: getNonDuplicateContacts(
                mappedContacts,
                selectedRecipients
            ),
            emailError,
            selectedRecipients,
            newEmailRecipient,
        }
    }
)((state, props) => props.formName)

const mapDispatchToProps = (dispatch, props) => ({
    addNewRecipient: email => {
        if (!email) return
        dispatch(resetSection(props.formName, "newEmailRecipient"))
        dispatch(
            arrayPush(props.formName, "selectedRecipients", {
                value: { value: email, type: "email" },
                label: email,
            })
        )
    },
    addRecipient: value => {
        if (!value.length) return
        const addedValue = value[value.length - 1]
        dispatch(
            arrayPush(props.formName, "selectedRecipients", {
                ...addedValue,
                label: `${addedValue?.label} (${addedValue?.value?.value})`,
            })
        )
    },
    onDelete: (index, subject) => {
        dispatch(arrayRemove(props.formName, "selectedRecipients", index))
    },
    requestContactAddresses: () => dispatch(requestContactAddresses()),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(AddRecipients)
)
