import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { ValidatedControl } from "./validated-control"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { ContactSmart } from "../track/shipment-images/progressive-email-contact-search"
import {
    editEmailContact,
    emailContactFieldChange,
    removeEmailContact,
    saveEmailContact,
    selectEmailContact,
} from "../../actions/email-image"

class EmailContactPresentation extends Component {
    constructor(props) {
        super(props)
        this.onBlur.bind(this)
        this.onEdit.bind(this)
        this.onKeyDown.bind(this)
        this.ignore = false
    }

    onBlur() {
        if (!this.ignore) this.handleSave()
        this.ignore = false
    }

    onKeyDown(field, e) {
        if (e.key !== "Tab" && e.key !== "Enter") return true
        if (e.key === "Tab" && field === "name") return this.ignoreBlur()
        this.handleSave()
        return true
    }

    onEdit() {
        this.props.onEdit()
    }

    ignoreBlur() {
        this.ignore = true
        setTimeout(() => {
            this.ignore = false
        }, 0)
    }

    handleSave() {
        if (!this.props.name || !this.props.email) return
        this.props.onSave()
    }

    render() {
        const {
            name,
            email,
            editable,
            onChange,
            onRemove,
            onSelect,
            classes,
        } = this.props
        if (editable) {
            return (
                <Grid
                    item
                    container
                    justify="center"
                    onMouseDown={() => this.ignoreBlur()}
                    onBlur={() => this.onBlur()}
                >
                    <Grid
                        item
                        container
                        xs={12}
                        className={classes.separate__email}
                    >
                        <ValidatedControl
                            field="name"
                            name="Name"
                            onChange={onChange}
                            value={name}
                            titleNeeded={false}
                            placeholder="Name"
                            controlProps={{
                                onKeyDown: e => this.onKeyDown("name", e),
                                onSelect: contact =>
                                    this.props.onSelect(contact),
                                autoFocus: true,
                            }}
                            Control={ContactSmart}
                        />
                    </Grid>
                    <Grid item container xs={12}>
                        <ValidatedControl
                            field="email"
                            name="Email"
                            onChange={onChange}
                            controlProps={{
                                onKeyDown: e => this.onKeyDown("email", e),
                                onSelect: contact =>
                                    this.props.onSelect(contact),
                            }}
                            value={email}
                            titleNeeded={false}
                            placeholder="Email"
                            onSelect={onSelect}
                            Control={ContactSmart}
                        />
                    </Grid>
                </Grid>
            )
        }
        return (
            <Grid
                item
                container
                justify="space-between"
                className={classes.separate__email}
            >
                <Grid item container xs={9}>
                    <Grid
                        item
                        xs={12}
                        role="presentation"
                        onClick={() => this.onEdit()}
                    >
                        <Typography>{name}</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        role="presentation"
                        onClick={() => this.onEdit()}
                        className={classes.separate__email}
                    >
                        <Typography>{email}</Typography>
                    </Grid>
                </Grid>
                <Grid item container justify="flex-end" xs={3}>
                    <Button variant="text" onClick={onRemove}>
                        x
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

EmailContactPresentation.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    editable: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch, props) => ({
    onChange: (field, e) =>
        dispatch(emailContactFieldChange(field, e.target.value, props.index)),
    onEdit: () => dispatch(editEmailContact(props.index)),
    onSelect: contact => dispatch(selectEmailContact(contact, props.index)),
    onSave: () => dispatch(saveEmailContact(props.index)),
    onRemove: () => dispatch(removeEmailContact(props.index)),
})

export const EmailContact = connect(
    undefined,
    mapDispatchToProps
)(EmailContactPresentation)
