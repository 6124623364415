import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles } from "@material-ui/core/styles"
import {
    FormHelperText,
    FormControlLabel,
    FormControl,
    Switch,
} from "@material-ui/core"
import FormattedError from "./formatted-error"

const styles = theme => ({
    form__container: {
        width: "auto",
    },
    centered: {
        margin: 0,
    },
})

const FormSwitch = ({
    input,
    label,
    FormLabelProps,
    color,
    onChange,
    value,
    classes,
    centered,
    meta: { touched, error },
    dataTestId,
    ...rest
}) => (
    <div
        className={`${classnames({
            "form-switch": true,
            "form-field-touched": touched,
            "form-field-invalid": touched && error,
            "form-field-valid": !error,
        })} ${classes.form__container}`}
    >
        <FormControl error={!!(touched && error)}>
            <FormControlLabel
                {...input}
                {...rest}
                {...FormLabelProps}
                control={
                    <Switch
                        id={input.name}
                        value="formSwitch"
                        color={color}
                        onChange={onChange}
                        checked={input.value}
                        inputProps={{ dataTestId }}
                        {...rest}
                    />
                }
                id={`${input.name}-label`}
                label={label}
                className={centered && classes.centered}
            />
            {touched && error && (
                <FormHelperText>
                    <FormattedError error={error} />
                </FormHelperText>
            )}
        </FormControl>
    </div>
)

FormSwitch.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.shape({
        // eslint-disable-next-line react/no-unused-prop-types
        touched: PropTypes.bool,
        // eslint-disable-next-line react/no-unused-prop-types
        error: PropTypes.string,
    }),
}

FormSwitch.defaultProps = {
    meta: {},
    label: undefined,
    input: undefined,
}

export default withStyles(styles)(FormSwitch)
