import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Chip } from "@material-ui/core"

const styles = theme => {
    return {
        originSummaryContainer: {
            padding: "0 15%",
        },
        paper: {
            width: "100%",
            display: "flex",
            padding: "15px",
            flexWrap: "wrap",
        },
        header: {
            paddingBottom: "15px",
        },
        section: {
            paddingTop: "15px",
        },
        underline: {
            textDecoration: "underline",
        },
        chip: {
            margin: "5px 10px 5px 0px",
            backgroundColor: theme.palette.primary.main,
            color: "white",
        },
    }
}

const OriginSummary = ({
    handleEdit,
    handleReviewEdit,
    formValues = {},
    classes,
    pickupAccessorialsList,
    isReview,
}) => {
    const {
        origin,
        pickupAccessorials,
        isInBondShipment,
        isFreightDirect,
        isFreightDirectReturns,
    } = formValues

    let selectedArr = []
    if (typeof pickupAccessorials === "string") {
        selectedArr = pickupAccessorials.split(",")
    } else {
        selectedArr = pickupAccessorials ?? []
    }

    return (
        <Grid
            item
            container
            className={!isReview ? classes.originSummaryContainer : ""}
        >
            <Paper className={classes.paper}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    className={classes.header}
                >
                    <Typography variant="title">
                        <FormattedMessage
                            id="bookShipment.originSummary__title"
                            defaultMessage="Origin"
                        />
                    </Typography>
                    <Button
                        id="editOrigin"
                        color="primary"
                        variant="outlined"
                        onClick={
                            isReview
                                ? () => handleReviewEdit("origin")
                                : () => handleEdit(1)
                        }
                    >
                        <FormattedMessage
                            id="generalTerms__edit"
                            defaultMessage="Edit"
                        />
                    </Button>
                </Grid>
                <Grid item container direction="column" xs={6}>
                    <Typography>
                        <FormattedMessage
                            id="generalTerms__address"
                            defaultMessage="Address"
                        />
                    </Typography>
                    <Typography variant="caption">
                        {origin?.shippingAddress?.name}
                    </Typography>
                    <Typography variant="caption">
                        {origin?.shippingAddress?.address.street1}
                    </Typography>
                    <Typography variant="caption">
                        {origin?.shippingAddress?.address.street2}
                    </Typography>
                    <Typography variant="caption">{`${origin?.shippingAddress?.address?.city}, ${origin?.shippingAddress?.address?.state} ${origin?.shippingAddress?.address?.postalCode}`}</Typography>
                    <Typography className={`${classes.section}`}>
                        <FormattedMessage
                            id="orderResults.contact"
                            defaultMessage="Contact"
                        />
                    </Typography>
                    <Typography variant="caption">
                        {origin?.contact?.name}
                    </Typography>
                    <Typography variant="caption">
                        {`${origin?.contact?.phone?.phone_number}${
                            origin?.contact?.phone?.extension
                                ? ` - ${origin?.contact?.phone?.extension}`
                                : ""
                        }`}
                    </Typography>
                    <Typography variant="caption">
                        {origin?.contact?.email?.email_address}
                    </Typography>
                </Grid>
                <Grid item container direction="column" xs={6}>
                    {!isFreightDirect && !isFreightDirectReturns && (
                        <Grid item container direction="column">
                            <Typography>
                                <FormattedMessage
                                    id="generalTerms__accessorials"
                                    defaultMessage="Accessorials"
                                />
                            </Typography>
                            <Grid item container>
                                {selectedArr.map((accessorial, i) => (
                                    <Chip
                                        label={
                                            (
                                                pickupAccessorialsList.find(
                                                    it =>
                                                        it?.value ===
                                                        accessorial
                                                ) || {}
                                            ).label
                                        }
                                        key={i}
                                        className={classes.chip}
                                        tabIndex={-1}
                                    />
                                ))}
                                {isInBondShipment && (
                                    <Grid item>
                                        <Chip
                                            tabIndex={-1}
                                            label={
                                                <FormattedMessage
                                                    id="accessorials.panel__freightMovingInBond"
                                                    defaultMessage="Freight Moving In-Bond"
                                                />
                                            }
                                            className={classes.chip}
                                            backgroundColor="#F4F4F4"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    )
}

export default withStyles(styles)(OriginSummary)
