import React, { useEffect, useState } from "react"
import IconButton from "@material-ui/core/IconButton"
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile"
import { withStyles, Grid, Typography } from "@material-ui/core"
import { useAttachmentsContext } from "../../../../context/providers/attachmentsProvider"
import { attachmentOptions } from "../../../../misc"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    document__icon: {},
    dashboard__itemContainer: {
        margin: "0% 3%",
    },
    progress: {
        margin: theme.spacing.unit * 2,
        top: "50%",
        right: "50%",
        position: "absolute",
    },
    section__title: {
        textDecoration: "underline",
        paddingBottom: "10px",
    },
    labelDoc: {
        textAlign: "center",
    },
})

const iconStyles = {
    button: {
        width: 48,
        height: 48,
        padding: 0,
    },
    icon: {
        fontSize: 30,
        color: "#fffff",
    },
    tooltip: {
        marginLeft: 7,
    },
}

const MiniAttachments = ({
    classes,
    internalTrackingNumber,
    isDashBoard,
    id = "miniAttachments",
}) => {
    const { openDialog, getShipmentAttachments } = useAttachmentsContext()
    const [attachments, setAttachments] = useState([])

    useEffect(() => {
        const shipmentAttachments = getShipmentAttachments(
            internalTrackingNumber
        )
        setAttachments(shipmentAttachments?.attachments)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalTrackingNumber])

    let dashBoardCondition = file =>
        file?.attachmentFileType === "labels" ||
        file?.attachmentFileType === "bol" ||
        file?.attachmentFileType === "POD"

    let attachmentsFiltered = [...attachments].filter(
        file =>
            file?.attachmentFileType !== "systemCertificateOfOrigin" &&
            file?.attachmentFileType !== "systemCommercialInvoice"
    )

    if (isDashBoard) {
        attachmentsFiltered = attachmentsFiltered.filter(dashBoardCondition)
    }

    return (
        <Grid item container>
            <Grid item container>
                <Typography
                    className={classes.section__title}
                    variant="subheading"
                >
                    <FormattedMessage
                        id="documents.track__title"
                        defaultMessage="Documents"
                    />
                </Typography>
            </Grid>
            {attachmentsFiltered.map(file => {
                const categoryLabel = attachmentOptions.find(
                    entry => file.attachmentFileType === entry.value
                )
                const label = categoryLabel ? (
                    <FormattedMessage {...categoryLabel.label} />
                ) : (
                    file.label
                )
                return (
                    <Grid
                        item
                        container
                        className={classes.document__icon}
                        direction="column"
                        alignItems="center"
                        justify="flex-start"
                        xs={2}
                    >
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() =>
                                openDialog(
                                    internalTrackingNumber,
                                    "manage",
                                    file,
                                    label
                                )
                            }
                        >
                            <IconButton
                                id={`${id}__${file.label?.props?.id}`}
                                style={iconStyles.button}
                            >
                                <InsertDriveFileIcon style={iconStyles.icon} />
                            </IconButton>
                        </div>
                        <Typography
                            variant="caption"
                            className={classes.labelDoc}
                        >
                            {label}
                        </Typography>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default withStyles(styles)(MiniAttachments)
