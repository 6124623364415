import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withStyles, Snackbar, Typography, Grid } from "@material-ui/core"
import { requestItems, deleteItem, saveItem } from "../../actions/item"
import LineItem from "./line-item"
import { get } from "lodash"
import TablePagination from "../util/table-pagination-items"
import { createGAEvent } from "../../actions"
import GlobalSpinner from "../util/spinner"
import { ErrorSnackbarContentWrapper } from "../errorSnackbar"
import {
    itemSaveSuccess,
    itemDeleteSuccess,
} from "../../messages/confirmation-constants"
import { itemSaveError, itemDeleteError } from "../../messages/error-constants"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    itemPage__container: {
        margin: "2% 3%",
        minWidth: "898px",
    },
    button__newItem: {
        marginBottom: theme.spacing.unit * 2,
    },
})

export const LineItemControl = () => <div />

export const SubInputControl = () => <div />

class ItemPagePresentation extends Component {
    state = {
        sbOpen: false,
        sbMessage: "",
        sbVariant: "",
        loading: false,
    }

    handleSave = (item, index) => {
        this.setState({ loading: true })
        return this.props
            .handleSave(item, index)
            .then(res =>
                this.setState({
                    sbOpen: true,
                    sbMessage: itemSaveSuccess,
                    sbVariant: "success",
                    loading: false,
                })
            )
            .catch(err =>
                this.setState({
                    sbOpen: true,
                    sbMessage: itemSaveError,
                    sbVariant: "error",
                    loading: false,
                })
            )
    }

    handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        this.setState({ sbOpen: false })
    }

    handleDelete = async id => {
        this.setState({ loading: true })
        this.props
            .handleDelete(id)
            .then(res =>
                this.setState({
                    sbOpen: true,
                    sbMessage: itemDeleteSuccess,
                    sbVariant: "success",
                    loading: false,
                })
            )
            .catch(err =>
                this.setState({
                    sbOpen: true,
                    sbMessage: itemDeleteError,
                    sbVariant: "error",
                    loading: false,
                })
            )
    }

    render() {
        const { data, pageOffset, intl } = this.props
        const { loading } = this.state

        return (
            <Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.sbOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackbarClose}
                >
                    <ErrorSnackbarContentWrapper
                        variant={this.state.sbVariant}
                        onClose={this.handleSnackbarClose}
                        message={
                            <span>
                                <Typography>{this.state.sbMessage}</Typography>
                            </span>
                        }
                    />
                </Snackbar>

                <div>
                    {data.map((item, index) => (
                        <LineItem
                            form={`lineItemForm_${item._id}`}
                            initialValues={item}
                            item={item}
                            index={index + pageOffset}
                            key={item._id}
                            handleDelete={this.handleDelete}
                            handleSave={this.handleSave}
                            intl={intl}
                        />
                    ))}
                </div>
                {loading && <GlobalSpinner />}
            </Fragment>
        )
    }
}

ItemPagePresentation.propTypes = {
    data: PropTypes.array.isRequired,
    pageOffset: PropTypes.number.isRequired,
    handleNewItemClick: PropTypes.func.isRequired,
    addingInProgress: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
}

ItemPagePresentation = withStyles(styles)(ItemPagePresentation)

class ItemsWithPagination extends Component {
    state = {
        isLoaded: false,
    }

    async componentDidMount() {
        await this.props.loadItems()
        this.setState({ isLoaded: true })
    }

    render() {
        const {
            data,
            addingInProgress,
            classes,
            handleNewItemClick,
            handleDelete,
            handleSave,
        } = this.props
        const { isLoaded } = this.state

        return !isLoaded ? (
            <GlobalSpinner />
        ) : (
            <div className={classes.itemPage__container}>
                <TablePagination
                    elements={data}
                    fetchElements={() => {}}
                    pageSize={19}
                    showPageSizeOptions={false}
                    totalElements={get(data, "length", 0)}
                    noElement={() => (
                        <Grid item container justify="center">
                            <Grid item container justify="center">
                                <Typography variant="title">
                                    <FormattedMessage
                                        id="items__welcome"
                                        defaultMessage="Welcome to the items page! You have no items to display."
                                    />
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    render={(elementsToDisplay, pageOffset) => (
                        <ItemPagePresentation
                            data={elementsToDisplay}
                            pageOffset={pageOffset}
                            handleNewItemClick={handleNewItemClick}
                            addingInProgress={addingInProgress}
                            handleDelete={handleDelete}
                            handleSave={handleSave}
                        />
                    )}
                />
            </div>
        )
    }
}

ItemsWithPagination.propTypes = {
    data: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    handleNewItemClick: PropTypes.func.isRequired,
    addingInProgress: PropTypes.bool.isRequired,
}

const stateToProps = state => ({
    data: state.item.elements,
    addingInProgress: state.item.addingInProgress,
})

const mapDispatchToProps = dispatch => ({
    handleSave: (item, index) => dispatch(saveItem(item, index)),
    handleDelete: id => {
        dispatch(createGAEvent("Item", "Items Delete"))
        return dispatch(deleteItem(id))
    },
    loadItems: () => dispatch(requestItems()),
})

export const ItemPage = connect(
    stateToProps,
    mapDispatchToProps
)(withStyles(styles)(ItemsWithPagination))
