import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import GlobalSpinner from "../../components/util/spinner"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import { loadShippingLabels } from "../../actions/modal"

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 3,
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
        outline: "none",
    },
    actions: {
        padding: "15px 0 0 0",
    },
    actions__button: {
        paddingLeft: "15px",
    },
    header: {
        paddingBottom: "8px",
        borderBottom: "solid 1px #D4D4D4",
    },
    previewImage: {
        width: "100%",
    },
    previewImage__container: {
        height: "370px",
        overflowY: "scroll",
        borderBottom: "solid 1px #D4D4D4",
        padding: "5px",
    },
    details__container: {
        padding: "20px 0",
    },
    button__right: {
        paddingLeft: "10px",
    },
})

const BookedShipmentPresentation = ({
    closeModal,
    classes,
    shipment,
    shipmentId,
    loadLabelsData,
    printFile,
    openDialog,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const [documentUnavailable, setDocumentUnavailable] = useState(false)

    const proNumber = shipment?.identifiers?.proNumber
    const isPickupLater = shipment?.shipment?.isPickupLater

    useEffect(() => {
        const makeApiCall = async () => {
            try {
                const result = await loadLabelsData(shipmentId)

                if (result) {
                    setData(result)
                    setIsLoading(false)
                    return result
                } else throw Error
            } catch (error) {
                return false
            }
        }

        const fetchData = async () => {
            setIsLoading(true)

            const firstResult = await makeApiCall()
            if (!firstResult) {
                setTimeout(async () => {
                    const secondResult = await makeApiCall()
                    if (!secondResult) {
                        setIsLoading(false)
                        setDocumentUnavailable(true)
                    }
                }, 5000)
            }
        }

        if (shipmentId) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentId])

    return (
        <Grid item container className={classes.paper}>
            <Grid item container className={classes.header}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                    alignContent="flex-start"
                >
                    <Grid item container xs={7} direction="column">
                        <Typography variant="subheading">
                            {isPickupLater ? (
                                <FormattedMessage
                                    id="bookedShipment.subtitle__created"
                                    defaultMessage="Your shipment has been created. Thank you for using LTL Select."
                                />
                            ) : (
                                <FormattedMessage
                                    id="bookedShipment.subtitle__processed"
                                    defaultMessage="Your shipment has been processed. Thank you for using LTL Select."
                                />
                            )}
                        </Typography>
                        <Typography variant="h4">
                            <FormattedMessage
                                id="bookedShipment.title"
                                defaultMessage="Share or Print Your Labels"
                            />
                        </Typography>
                        <Grid item>
                            <Typography variant="caption">
                                <FormattedMessage
                                    id="documents.labelsInfo"
                                    defaultMessage="Domestic shipping does not require a BOL when using the FedEx shipping label (except for shipments containing hazardous materials)."
                                ></FormattedMessage>
                            </Typography>
                        </Grid>
                        <Typography>
                            <FormattedMessage
                                id="bookedShipment.proNumber"
                                defaultMessage="Tracking Number: {proNumber}"
                                values={{ proNumber }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item container xs={3}>
                        <img
                            className={classes.carrier__logo}
                            src="https://cloud.p-44.com/img/carriers/FXFElogo.png"
                            alt="FXNL"
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={2}
                        alignContent="flex-start"
                        justify="flex-end"
                    >
                        <IconButton onClick={() => closeModal()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container className={classes.details__container}>
                <Grid
                    item
                    container
                    justify="center"
                    className={classes.previewImage__container}
                >
                    {!isLoading && data && !documentUnavailable && (
                        <Grid item>
                            <img
                                className={classes.previewImage}
                                src={data}
                                alt={
                                    <FormattedMessage
                                        id="documents__unavailable"
                                        defaultMessage="Unavailable"
                                    />
                                }
                            />
                        </Grid>
                    )}
                    {documentUnavailable && (
                        <Typography color="secondary">
                            <FormattedMessage
                                id="documents__unavailable"
                                defaultMessage="Unavailable"
                            />
                        </Typography>
                    )}
                    {isLoading && <GlobalSpinner isGlobal={false} />}
                </Grid>
            </Grid>
            <Grid
                item
                container
                className={classes.actions}
                justify="space-between"
                alignItems="center"
            >
                <Grid item container xs={4}>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => closeModal()}
                    >
                        <FormattedMessage
                            id="bookedShipment__notNow"
                            defaultMessage="Not Now"
                        />
                    </Button>
                </Grid>
                <Grid item container xs={8} justify="flex-end">
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                printFile({ attachmentFileType: "labels" })
                            }
                        >
                            <FormattedMessage
                                id="documents.manage__print"
                                defaultMessage="Print"
                            />
                        </Button>
                    </Grid>

                    <Grid item className={classes.button__right}>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => {
                                openDialog(shipmentId, "share", {
                                    attachmentFileType: "labels",
                                    isSystem: true,
                                })
                                closeModal()
                            }}
                        >
                            <FormattedMessage
                                id="documents.manage__share"
                                defaultMessage="Share"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapDispatchToProps = dispatch => ({
    loadLabelsData: shipmentId => dispatch(loadShippingLabels(shipmentId)),
})

const mapStateToProps = (state, props) => {
    const shipment = state?.shipment?.list[props?.shipmentId] ?? {}

    return {
        shipment,
    }
}

export const BookedShipment = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(BookedShipmentPresentation))
