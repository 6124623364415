import React, { useState, useEffect, useContext, createContext } from "react"
import { connect } from "react-redux"
import { reset } from "redux-form"
import { populateTrackForm } from "../../actions/track"
import { goFetch } from "../../http"
import download from "downloadjs"
import printJS from "print-js"
import FileSaver from "file-saver"
import { useSnackbarContext } from "./snackbarProvider"
import { FormattedMessage } from "react-intl"
import { defaultLanguage } from "../../components/constants/preferenceLanguageOptions"
import { trackGAEventNew } from "../../actions/user"

const Context = createContext({
    internalTrackingNumber: null,
    openDialog: () => {},
    closeDialog: () => {},
    attachmentsLoading: false,
    managedFile: {},
    stagedFile: {},
    setStagedFile: () => {},
    shareInitialValues: {
        documents: { labels: true },
        language: defaultLanguage,
    },
    attachments: [],
    contacts: [],
    setInternalTrackingNumber: () => {},
    deleteFile: () => {},
    downloadFile: () => {},
    uploadFile: () => {},
    createFile: () => {},
    getShipmentAttachments: () => {},
    printFile: () => {},
})

export const useAttachmentsContext = () => {
    const attachmentsContext = useContext(Context)
    if (!attachmentsContext) {
        throw new Error(
            "Cannot use attachment context outside of AttachmentsProvider"
        )
    }
    return attachmentsContext
}

const AttachmentsProviderContext = ({
    children,
    state = {},
    resetForm,
    loadShipment,
    authFinished,
    userIsLoaded,
    GAEvent,
}) => {
    const [internalTrackingNumber, setInternalTrackingNumber] = useState(null)
    const [manageDialogOpen, setManageDialogOpen] = useState(false)
    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const [managedFile, setManagedFile] = useState({})
    const [stagedFile, setStagedFile] = useState({})
    const [attachmentsLoading, setAttachmentsLoading] = useState(false)
    const language =
        state?.user?.profile?.preferences?.language || defaultLanguage
    const [shareInitialValues, setShareInitialValues] = useState({
        documents: { labels: true },
        language,
    })
    const [contacts, setContacts] = useState([])
    const [attachments, setAttachments] = useState([])

    const { openSnackbar } = useSnackbarContext()

    const getShipmentAttachments = internalTrackingNumber => {
        if (!state?.shipment?.list) {
            return {
                attachments: [],
            }
        }

        const shipment = state?.shipment?.list?.[internalTrackingNumber]

        const { attachments = [] } = shipment?.shipment ?? {}

        const adjustedAttachments = attachments.map(att => {
            const adjustedAttachment = { ...att }
            switch (att.attachmentFileType) {
                case "labels":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__labels"
                            defaultMessage="Labels"
                        />
                    )
                    break
                case "bol":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__BOL"
                            defaultMessage="Paper BOL"
                        />
                    )
                    break
                case "signedBOL":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__signedBOL"
                            defaultMessage="Signed BOL"
                        />
                    )
                    break
                case "POD":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__POD"
                            defaultMessage="POD"
                        />
                    )
                    break
                case "invoice":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__invoice"
                            defaultMessage="Invoice"
                        />
                    )
                    break
                case "reweigh":
                    adjustedAttachment.label = (
                        <FormattedMessage
                            id="documents__reweigh"
                            defaultMessage="Reweigh"
                        />
                    )
                    break
                case "systemUSMCA":
                    if (att.isSigned) {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__signedUSMCA"
                                defaultMessage="Signed USMCA / CUSMA / ACEUM / T-MEC"
                            />
                        )
                    } else {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__unsignedUSMCA"
                                defaultMessage="Unsigned USMCA / CUSMA / ACEUM / T-MEC"
                            />
                        )
                    }
                    break
                case "systemCertificateOfOrigin":
                    if (att.isSigned) {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__signedCertificateOfOrigin"
                                defaultMessage="Signed Certificate of Origin"
                            />
                        )
                    } else {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__unsignedCertificateOfOrigin"
                                defaultMessage="Unsigned Certificate of Origin"
                            />
                        )
                    }
                    break
                case "systemCommercialInvoice":
                    if (att.isSigned) {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__signedCommercialInvoice"
                                defaultMessage="Signed Commercial Invoice"
                            />
                        )
                    } else {
                        adjustedAttachment.label = (
                            <FormattedMessage
                                id="documents__unsignedCommercialInvoice"
                                defaultMessage="Unsigned Commercial Invoice"
                            />
                        )
                    }
                    break
                default:
                    break
            }
            return adjustedAttachment
        })

        return {
            attachments: adjustedAttachments,
        }
    }

    const attachmentsChanged =
        state?.shipment?.list?.[internalTrackingNumber]?.shipment?.attachments

    useEffect(() => {
        if (internalTrackingNumber && authFinished && userIsLoaded) {
            const shipmentAttachments = getShipmentAttachments(
                internalTrackingNumber
            )

            setAttachments(shipmentAttachments.attachments)
            setContacts(mapRecipients(state?.addressBook?.addresses))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [internalTrackingNumber, attachmentsChanged])

    const mapRecipients = contacts => {
        const recipients = []
        contacts.forEach(contact => {
            const contactInfo = contact?.contact
            recipients.push({
                values: [
                    {
                        type: "phone",
                        value: contactInfo?.phone?.phoneNumber || "",
                    },
                    {
                        type: "email",
                        value: contactInfo?.email?.emailAddress || "",
                    },
                ],
                label: contactInfo?.name,
            })
        })
        return recipients
    }

    const openDialog = (
        internalTrackingNumber,
        type,
        file = {},
        label,
        isShortcut
    ) => {
        closeDialog()
        setInternalTrackingNumber(internalTrackingNumber)
        setManagedFile({ ...file, label })
        switch (type) {
            case "manage":
                setManageDialogOpen(true)
                break
            case "share":
                if (isShortcut) {
                    setShareInitialValues({
                        documents: { labels: true },
                        language:
                            state?.user?.profile?.preferences?.language ||
                            defaultLanguage,
                    })
                }
                if (Object.keys(file).length) {
                    const key = file.isSystem
                        ? file.attachmentFileType
                        : file.attachmentFileName

                    if (file?.attachmentFileType === "bol") {
                        setShareInitialValues({
                            ...shareInitialValues,
                            documents: {
                                [`${key}`]: true,
                                labels: true,
                            },
                            language:
                                state?.user?.profile?.preferences?.language ||
                                defaultLanguage,
                        })
                    } else if (file?.attachmentFileType === "labels") {
                        setShareInitialValues({
                            ...shareInitialValues,
                            documents: {
                                [`${key}`]: true,
                            },
                            language:
                                state?.user?.profile?.preferences?.language ||
                                defaultLanguage,
                        })
                    } else {
                        setShareInitialValues({
                            ...shareInitialValues,
                            documents: {
                                [`${key}`]: true,
                            },
                            language:
                                state?.user?.profile?.preferences?.language ||
                                defaultLanguage,
                        })
                    }
                }
                setShareDialogOpen(true)
                break
            default:
                console.error("Incorrect modal type")
        }
    }

    const closeDialog = type => {
        setStagedFile({})
        setShareDialogOpen(false)
        setManageDialogOpen(false)
        if (type === "success") {
            resetForm()
        }
    }

    const deleteFile = async file => {
        GAEvent("attachments", `delete`)
        setAttachmentsLoading(true)
        try {
            await goFetch(
                `/documents/attachment/${internalTrackingNumber}/${file?.attachmentFileName}`,
                {
                    method: "DELETE",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                },
                true
            )
            openSnackbar(
                "success",
                <FormattedMessage
                    id="documents.delete__success"
                    defaultMessage="Successfully deleted document"
                />
            )
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.delete__error"
                    defaultMessage="Unable to delete document"
                />
            )
        }
        await loadShipment(internalTrackingNumber)

        setAttachmentsLoading(false)
        setManagedFile({})
        setManageDialogOpen(false)
    }

    const downloadFile = async file => {
        GAEvent("attachments", `download-${file?.attachmentFileType}`)
        setAttachmentsLoading(true)

        try {
            let format = file?.attachmentFileName ? undefined : "pdf"
            if (
                ["certificateOfOrigin", "commercialInvoice"].includes(
                    file?.attachmentFileType
                )
            ) {
                if (file?.mimeType === "application/pdf") {
                    format = "pdf"
                } else if (file?.mimeType === "image/png") {
                    format = "png"
                }
            }
            const params = {
                type: file?.attachmentFileType,
                format,
                filename: file?.attachmentFileName,
            }
            const result = await goFetch(
                `/documents/${internalTrackingNumber}`,
                {
                    params,
                    method: "GET",
                    credentials: "same-origin",
                    headers: { "cache-control": "no-cache" },
                    responseType: "arraybuffer",
                },
                true,
                undefined,
                "image/png, application/pdf"
            )

            download(result.data, file?.originalFileName, file?.mimeType)
        } catch (error) {
            openSnackbar(
                "error",
                <FormattedMessage
                    id="documents.manage__downloadError"
                    defaultMessage="Unable to download document"
                />
            )
        }
        setAttachmentsLoading(false)
    }

    const printFile = async (file, shipmentId) => {
        GAEvent("attachments", `print-${file?.attachmentFileType}`)
        let params = { type: file?.attachmentFileType }

        if (
            file?.attachmentFileType === "bol" ||
            file?.attachmentFileType === "labels" ||
            file?.attachmentFileType === "systemCommercialInvoice" ||
            file?.attachmentFileType === "systemCertificateOfOrigin" ||
            file?.attachmentFileType === "systemUSMCA" ||
            file?.attachmentFileType === "USMCA"
        ) {
            params.format = "pdf"
        } else if (
            file?.attachmentFileType === "signedBOL" ||
            file?.attachmentFileType === "POD" ||
            file?.attachmentFileType === "invoice" ||
            file?.attachmentFileType === "reweigh"
        ) {
            if (file?.filePath) {
                params.format = "pdf"
            } else {
                params.format = "png"
            }
        }

        if (
            file?.attachmentFileType === "USMCA" ||
            file?.attachmentFileType === "systemUSMCA"
        ) {
            params.filename = file?.attachmentFileName
        }

        const { data } = await goFetch(
            `/documents/${
                internalTrackingNumber ? internalTrackingNumber : shipmentId
            }`,
            {
                params,
                headers: { Accept: ["application/pdf", "image/png"] },
                responseType: "blob",
            },
            true
        )
        const blobURIData = URL.createObjectURL(data)
        printJS({
            printable: blobURIData,
            type: params.format === "pdf" ? "pdf" : "image",
            onError: () =>
                FileSaver.saveAs(
                    blobURIData,
                    `BOL-${
                        internalTrackingNumber
                            ? internalTrackingNumber
                            : shipmentId
                    }.${params.format}`
                ),
        })
    }

    const uploadFile = payload =>
        goFetch(
            `/documents/attachment/${payload.internalTrackingNumber}`,
            {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "cache-control": "no-cache",
                },
                data: payload,
            },
            true
        )

    return (
        <Context.Provider
            value={{
                internalTrackingNumber,
                openDialog,
                closeDialog,
                manageDialogOpen,
                shareDialogOpen,
                stagedFile,
                managedFile,
                shareInitialValues,
                attachments,
                contacts,
                setInternalTrackingNumber,
                deleteFile,
                attachmentsLoading,
                downloadFile,
                getShipmentAttachments,
                printFile,
                uploadFile,
            }}
        >
            {children}
        </Context.Provider>
    )
}

const mapStateToProps = state => {
    return {
        state,
        authFinished: state?.authorization?.isFinished,
        userIsLoaded: state?.user?.preferences?.isLoaded,
    }
}

const mapDispatchToProps = dispatch => ({
    loadShipment: internalTrackingNumber =>
        dispatch(populateTrackForm(internalTrackingNumber)),
    resetForm: () => dispatch(reset("attachment")),
    GAEvent: (category, action) => dispatch(trackGAEventNew(category, action)),
})

export const AttachmentsProvider = connect(
    mapStateToProps,
    mapDispatchToProps
)(AttachmentsProviderContext)
