import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import { withStyles } from "@material-ui/core/styles"
import SwapVertIcon from "@material-ui/icons/SwapVert"
import SearchIcon from "@material-ui/icons/Search"
import Button from "@material-ui/core/Button"
import FormSelectAutocompleteDark from "../form/form-select-autocomplete-dark"
import {
    Field,
    reduxForm,
    getFormValues,
    formValues,
    reset,
    change,
    FieldArray,
    clearSubmitErrors,
    untouch,
    touch,
} from "redux-form"
import { composeValidators, combineValidators } from "revalidate"
import Popper from "@material-ui/core/Popper"
import Typography from "@material-ui/core/Typography"
import {
    requestQuote,
    resiAddressRequest,
    searchShipmentByProNumber,
} from "../../actions/quote-request"
import FormField from "../form/form-field"
import FormSwitch from "../form/form-switch"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormCheckbox from "../form/form-checkbox"
import FormSelect from "../form/form-select"
import { requestPostalCode } from "../../actions/postal-code"
import { searchContacts } from "../../actions/contact"
import { SuggestionBox } from "../quote/post-code/suggestion-box"
import { searchItems } from "../../actions/item"
import {
    isGreaterThan,
    nmfcSubcodeValidator,
    nmfcValidator,
    pastDateValidator,
    isUSDomesticShipment,
    totalWeightValidator,
    isOneOfSilent,
    constructPostalCode,
    isRequired,
    isRequiredIf,
    isNumeric,
    hasLengthGreaterThan,
    hasLengthLessThan,
    nmfcFormValidator,
    isValidCountryPostalCode,
    invalidInbondShipment,
    hasLength,
    hasHazMatIdPrefix,
} from "../../actions/validation"
import { QuoteHandlingUnits } from "./handlingUnit/index"
import { get, merge } from "lodash"
import FormDatePicker from "../form/form-datepicker"
import {
    hazMatPkgGrp,
    freightClasses,
    packageTypes,
    hazMatClasses,
    hazMatContainers,
} from "../item/line-item"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogTitle from "@material-ui/core/DialogTitle"
import { formSelector, nextBusinessDay } from "./selectors"
import ShipmentSnackbar from "../book/snackbar"
import { changePath } from "../../actions"
import { newHandlingUnit } from "../../reducers/search"
import BillToLocationPicker from "./billToLocationPicker"
import { supportedCountryCodes, supportedCurrencies } from "../../misc"
import { goFetch } from "../../http"
import { FormattedMessage, injectIntl } from "react-intl"
import ResidentialAddressDialog from "./dialog/resiCheckDialog"
import VagueCommodityDialog from "./dialog/vagueCommodityDialog"
import ProNumberResult from "./proNumberResult/proNumberResult"
import moment from "moment"
import { trackGAEventNew, trackGAEventRR } from "../../actions/user"
import { weightUnit } from "../util/units"

const styles = theme => ({
    main__container: {
        width: "100%",
        height: "100%",
        flexGrow: 1,
    },
    quote__form__container: {
        "padding-top": "15px",
        "padding-bottom": "15px",
        "background-color": theme.palette.primary.main,
    },
    quote__form__container__item: {
        "border-bottom": "solid 1px white",
    },
    quote__form__container__item__top: {
        "border-top": "solid 1px white",
        "border-bottom": "solid 1px white",
    },
    input__field: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        minHeight: "20px",
        fontFamily: theme.typography.fontFamily,
    },
    input__field__disabled: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px 18px 6px 6px",
        minHeight: "20px",
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.primary.main,
    },
    input__field__select__autocomplete: {
        color: "white !important",
        textShadow: "none !important",
        padding: "6px",
        height: "20px",
        fontFamily: theme.typography.fontFamily,
    },
    input__field__focused: {
        "background-color": theme.palette.primary.light,
    },
    field__root: {
        "background-color": theme.palette.primary.light,
    },
    multiline__field: {
        color: "white",
        fontFamily: theme.typography.fontFamily,
        width: "inherit",
        "padding-left": "6px",
        "padding-bottom": "6px",
    },
    input__label: {
        color: "white !important",
        width: "162%",
        marginRight: 0,
    },
    input__label__small: {
        color: "white !important",
        width: "100%",
    },
    input__helper: {
        "margin-top": "3px",
    },
    quote__label: {
        color: "white",
        whiteSpace: "pre-wrap",
    },
    quote__label__hazmat: {
        margin: "12px 8px",
        color: theme.palette.secondary.light,
    },
    cssFocused: {},
    hide: {
        display: "none",
    },
    menuButton: {
        position: "absolute",
        top: 100,
        right: 100,
    },
    button__add_item: {
        padding: "5px",
        color: "white",
    },
    button__clear: {
        padding: "5px",
        color: "white",
    },
    button__get_quotes: {
        margin: "15px 5px",
    },
    popper: {
        zIndex: 100,
    },
    item__icon: {
        cursor: "pointer",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
    },
    swap__icon: {
        fontSize: "40px",
        cursor: "pointer",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
        marginTop: "174px",
        paddingRight: "5px",
    },
    search__icon: {
        fontSize: "40px",
        cursor: "pointer",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
        },
        "&:active": {
            color: theme.palette.secondary.light,
        },
        padding: "5%",
        marginTop: "18px",
    },
    search__icon__disabled: {
        fontSize: "40px",
        padding: "5%",
        marginTop: "18px",
        color: "#BDBDBD",
    },
    location__icon__container: {
        marginTop: "110px",
    },
    location__icon__top: {
        color: "white",
    },
    location__icon__middle: {
        color: "white",
        marginTop: "24px",
    },
    location__icon__bottom: {
        color: "white",
        marginTop: "27px",
    },
    item__panel__summary: {
        cursor: "pointer",
    },
    item__panel: {
        "background-color": theme.palette.primary.main,
        margin: 0,
        width: "100%",
    },
    item__panel__details: {
        justifyContent: "center",
        padding: "0 0 10px 0",
    },
    select__icon: {
        color: "white",
    },
    third__party__label: {
        marginLeft: "12px",
        marginTop: "4px",
    },
    third__party__checkbox: {
        marginLeft: "2px",
        height: "32px",
        width: "32px",
    },
    inBond__checkbox: {
        marginLeft: "12px",
        height: "32px",
        width: "32px",
    },
    inBond__checkbox__container: {
        paddingLeft: "12px",
    },
    third__party__hidden: {
        marginLeft: "12px",
        visibility: "hidden",
        cursor: "default",
    },
    checkboxes__container: {
        height: "34px",
        marginLeft: "10px",
    },
    location__container: {
        minHeight: "108px",
        paddingBottom: "15px",
    },
    location__container__header: {
        color: "white",
    },
    location__label: {
        color: "white",
        marginLeft: "7px",
    },
    submit: {
        position: "relative",
    },
    submitLoader: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    shipment__numbers: {
        padding: "10px 0",
    },
    add_item_row: {
        "background-color": theme.palette.primary.main,
        padding: "10px 30px",
    },
    quick_quote_btn: {
        margin: "15px 5px",
    },
    total__weight: {
        color: "white",
    },
    customsValue: {
        color: "white",
    },
    item__title: {
        color: "white",
        paddingLeft: "5px",
    },
    customsValue__container: {
        paddingTop: "16px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        paddingLeft: "3px",
        color: "white",
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
    itemInfo: {
        paddingBottom: "5px",
        paddingLeft: "30px",
    },
    huInfo: {
        borderBottom: "solid 1px white",
        paddingBottom: "10px",
    },
    individualItem: {
        paddingBottom: "12px",
        borderBottom: "dashed 1px #D4D4D4",
    },
    containedButton: {
        backgroundColor: theme.palette.primary.light,
        padding: "5px",
        color: "white",
    },
    addItem__container: {},
    collapsed__info: {
        paddingRight: "5px",
    },
    checkbox: {
        color: "white",
    },
    stackableCheckboxContainer: {
        paddingTop: "10px",
    },
    addPieceButton: {
        paddingBottom: "2px",
    },
    pieceTitle: {
        color: "white",
        textDecoration: "underline",
    },
    piecesCloseButton: {
        paddingTop: "15px",
    },
})

// function getItemWeight(items) {
//     let count = 0
//     items.forEach((item) => {
//         if (item.weight) count += parseInt(item.weight, 10)
//     })
//     return count
// }

class QuotesDrawer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: 0,
            showOriginOverlay: false,
            showDestinationOverlay: false,
            showBillToOverlay: false,
            anchorEl: null,
            origin: "",
            destination: "",
            itemSearch: "",
            noItemsHelper: false,
            hideThirdPartyCheckbox: this.determineIfThirdParty(
                this.props.formValues
            ),
            billToLocation: {},
            isResidentialAddress: false,
            isVagueCommodity: false,
            vagueItemDescriptions: [],
            isProNumberSearch: false,
            shipmentsProNumberSearch: {
                error: null,
                result: null,
                loading: false,
            },
        }
    }

    componentDidMount() {
        this.clickedInside = false
        this.clickTimeout = null

        const billToLocation =
            this.props?.locations ??
            [].find(location => location?.cpgCode === formValues?.cpg)

        this.setState({ billToLocation })
    }

    componentWillUnmount = () => {
        clearTimeout(this.clickTimeout)
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            hideThirdPartyCheckbox: this.determineIfThirdParty(newProps),
        })
    }

    componentDidUpdate(prevProps) {
        const oldFormValues = prevProps?.formValues
        const newFormValues = this.props?.formValues
        if (oldFormValues?.totalLinearFeet !== newFormValues?.totalLinearFeet)
            return

        const relevantFieldsFilled = (newFormValues?.handlingUnits ?? []).every(
            item => item.length && item.width && item.height && item.count
        )

        const handlingUnitCountChanged =
            newFormValues?.handlingUnits?.length !==
            oldFormValues?.handlingUnits?.length

        let relevantFieldsChanged =
            newFormValues?.handlingUnits?.length !==
                oldFormValues?.handlingUnits?.length ||
            newFormValues?.originCountry !== oldFormValues?.originCountry ||
            newFormValues?.destinationCountry !==
                oldFormValues?.destinationCountry

        for (let index in newFormValues?.handlingUnits ?? []) {
            const hu = newFormValues?.handlingUnits?.[index] ?? {}
            const oldHu = oldFormValues?.handlingUnits?.[index] ?? {}

            if (
                hu.length !== oldHu.length ||
                hu.width !== oldHu.width ||
                hu.height !== oldHu.height ||
                hu.stackable !== oldHu.stackable ||
                hu.doNotStack !== oldHu.doNotStack ||
                hu.count !== oldHu.count
            ) {
                relevantFieldsChanged = true
                break
            }
        }

        if (
            relevantFieldsFilled &&
            (relevantFieldsChanged || handlingUnitCountChanged)
        ) {
            this.determineEstimatedLinearFeet()
        }
    }

    handleInputFocus = (field, e) => {
        let country
        if (field === "origin") {
            this.setState({
                showOriginOverlay: true,
                anchorEl: e.currentTarget,
            })
            country = this.props?.formValues?.origin
        } else if (field === "destination") {
            this.setState({
                showDestinationOverlay: true,
                anchorEl: e.currentTarget,
            })

            country = this.props?.formValues?.destinationCountry
        } else if (field === "billToLocation") {
            this.setState({
                showBillToOverlay: true,
                anchorEl: e.currentTarget,
            })
            country = this.props?.formValues?.originCountry
        }
        this.props.onPostalCodeChange(e.target.value, field, country)
    }

    handleContainerMouseDown = () => {
        this.clickedInside = true
        // The input's onBlur method is called from a queue right after onMouseDown event.
        // setTimeout adds another callback in the queue, but is called later than onBlur event
        this.clickTimeout = setTimeout(() => {
            this.clickedInside = false
        }, 0)
    }

    handleInputBlur = (field, e) => {
        const showOverlay = this.clickedInside

        // Force input's focus if blur event was caused by clicking on dropdown
        if (!showOverlay) {
            if (field === "origin") {
                this.setState({
                    showOriginOverlay: false,
                    anchorEl: null,
                })
            } else if (field === "destination") {
                this.setState({
                    showDestinationOverlay: false,
                    anchorEl: null,
                })
            } else if (field === "billToLocation") {
                this.setState({
                    showBillToOverlay: false,
                    anchorEl: null,
                })
            }
        }
    }

    determineEstimatedLinearFeet = async () => {
        const { formValues, change } = this.props
        const {
            handlingUnits,
            preferredSystemOfMeasurement,
            originCountry,
            destinationCountry,
        } = formValues

        try {
            change("totalLinearFeet", 0)
            change("capLoadTotalLinearFeet", 0)
            change("volumeTotalLinearFeet", 0)
            change("userProvidedTotalLinearFeet", false)
            change("isOverLTLLimit", false)

            if (!handlingUnits.length) {
                return
            }

            const {
                data: {
                    totalLinearFeet,
                    capLoadTotalLinearFeet,
                    volumeTotalLinearFeet,
                    isOverLTLLimit,
                },
            } = await goFetch(
                `/quotes/linearfeet`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        handlingUnits,
                        preferredSystemOfMeasurement,
                        originCountry,
                        destinationCountry,
                    },
                },
                true
            )

            // Calculation timeout will return null values
            // Only change values if we got back a valid response with valid data
            if (totalLinearFeet) {
                change("totalLinearFeet", totalLinearFeet)
                change("capLoadTotalLinearFeet", capLoadTotalLinearFeet)
                change("volumeTotalLinearFeet", volumeTotalLinearFeet)
                change("userProvidedTotalLinearFeet", false)
                change("isOverLTLLimit", isOverLTLLimit)
            }
        } catch (error) {
            console.error(error)
        }
    }

    determineIfThirdParty = newProps => {
        const {
            billToLocationAddress,
            originAddress,
            destinationAddress,
            thirdPartyOnly,
        } = get(newProps || this.props, "formValues", {})
        if (
            thirdPartyOnly ||
            (billToLocationAddress !== originAddress &&
                billToLocationAddress !== destinationAddress)
        ) {
            change("includeThirdParty", true)
            return true
        }
        return false
    }

    onBillToLocationChange = option => {
        const { change, initialValues } = this.props
        const selectedLocation = this.props.locations.find(
            location => location.cpgCode === option.value
        )
        const thirdPartyOnly = !get(selectedLocation, "isShipperEnabled")
        const isFreightDirectReturns =
            selectedLocation?.locationType === "FEDEX_DIRECT_RETURNS"

        change("cpg", get(selectedLocation, "cpgCode"))
        change(
            "isFreightDirect",
            selectedLocation?.locationType === "FEDEX_DIRECT"
        )
        change("isFreightDirectReturns", isFreightDirectReturns)
        change("billToLocation", get(selectedLocation, "cpgCode"))
        change(
            "billToLocationAddress",
            get(selectedLocation, "shippingAddress._id")
        )
        change("thirdPartyOnly", thirdPartyOnly)
        change("pickupDate", moment.utc(moment().format("YYYY-MM-DD")))

        if (thirdPartyOnly) {
            change("includeThirdParty", true)
            this.resetSelection("origin")
            this.resetSelection("destination")
            change("originCountry", get(initialValues, "originCountry"))
            change(
                "destinationCountry",
                get(initialValues, "destinationCountry")
            )
            change("origin", "")
            change("destination", "")
            change(
                "isInBondShipment",
                invalidInbondShipment(
                    get(initialValues, "originCountry"),
                    get(initialValues, "destinationCountry")
                )
                    ? false
                    : get(initialValues, "isInBondShipment")
            )
        } else if (isFreightDirectReturns) {
            change("pickupDate", nextBusinessDay())
            this.resetSelection("origin")
            this.resetSelection("destination")

            change("origin", "")
            change("originCity", "")
            change("originState", "")
            change("originStreet", "")
            change("originCountry", initialValues?.originCountry)
            change("originName", "")
            change("originCpgCode", "")
            change("originAddress", "")
            change("originContactInfo", undefined)
            change(
                "destination",
                selectedLocation?.shippingAddress?.address?.postalCode
            )
            change(
                "destinationCity",
                selectedLocation?.shippingAddress?.address?.city
            )
            change(
                "destinationState",
                selectedLocation?.shippingAddress?.address?.state
            )
            change(
                "destinationStreet",
                selectedLocation?.shippingAddress?.address?.street1
            )
            change(
                "destinationCountry",
                selectedLocation?.shippingAddress?.address?.country
            )
            change("destinationName", selectedLocation?.shippingAddress?.name)
            change("destinationCpgCode", selectedLocation?.cpgCode)
            change("destinationAddress", selectedLocation?.shippingAddress?._id)
            change("destinationContactInfo", selectedLocation?.shippingAddress)
            change(
                "isInBondShipment",
                invalidInbondShipment(
                    get(initialValues, "originCountry"),
                    get(selectedLocation, [
                        "shippingAddress",
                        "address",
                        "country",
                    ])
                )
                    ? false
                    : get(initialValues, "isInBondShipment")
            )
        } else {
            change(
                "origin",
                selectedLocation?.shippingAddress?.address?.postalCode
            )
            change(
                "originCity",
                selectedLocation?.shippingAddress?.address?.city
            )
            change(
                "originState",
                selectedLocation?.shippingAddress?.address?.state
            )
            change(
                "originStreet",
                selectedLocation?.shippingAddress?.address?.street1
            )
            change(
                "originCountry",
                selectedLocation?.shippingAddress?.address?.country
            )
            change("originName", selectedLocation?.shippingAddress?.name)
            change("originCpgCode", selectedLocation?.cpgCode)
            change("originAddress", selectedLocation?.shippingAddress?._id)
            change("originContactInfo", selectedLocation?.shippingAddress)

            change(
                "isInBondShipment",
                invalidInbondShipment(
                    get(selectedLocation, [
                        "shippingAddress",
                        "address",
                        "country",
                    ])
                )
                    ? false
                    : get(initialValues, "isInBondShipment")
            )
        }
    }

    handleCitySelect = (field, city, state, country, term) => {
        const {
            formValues,
            change,
            clearSubmitErrors,
            changeField,
        } = this.props
        const billToLocation = this.props.locations.find(
            location => location.cpgCode === formValues.cpg
        )
        clearSubmitErrors()
        change(field, term)
        change(`${field}City`, city)
        change(`${field}State`, state)
        change(`${field}Country`, country)
        change(`${field}Name`, "")
        change(`${field}CpgCode`, "")
        change(`${field}Contact`, "")
        change(`${field}Address`, "")
        change(`${field}ContactInfo`, "")
        change(`${field}AddressInfo`, "")

        if (field === "origin") {
            this.setState(
                () => ({ showOriginOverlay: false }),
                () => {
                    if (
                        !formValues?.destinationState &&
                        billToLocation?.isShipperEnabled
                    ) {
                        change(
                            "destination",
                            billToLocation?.shippingAddress?.address
                                ?.postalCode ?? ""
                        )
                        change(
                            "destinationCity",
                            billToLocation?.shippingAddress?.address?.city ?? ""
                        )
                        change(
                            "destinationState",
                            billToLocation?.shippingAddress?.address?.state ??
                                ""
                        )
                        change(
                            "destinationStreet",
                            billToLocation?.shippingAddress?.address?.street1 ??
                                ""
                        )
                        change(
                            "destinationCountry",
                            billToLocation?.shippingAddress?.address?.country ??
                                ""
                        )

                        change(
                            "destinationName",
                            billToLocation?.shippingAddress?.name
                        )
                        change("destinationCpgCode", billToLocation?.cpgCode)
                        change(
                            "destinationAddress",
                            billToLocation?.shippingAddress?._id
                        )
                        change(
                            "destinationContactInfo",
                            billToLocation?.shippingAddress
                        )

                        change(
                            "isInBondShipment",
                            invalidInbondShipment(
                                null,
                                get(
                                    billToLocation,
                                    ["shippingAddress", "address", "country"],
                                    ""
                                )
                            )
                                ? false
                                : get(formValues, "isInBondShipment")
                        )
                    }
                }
            )
            changeField("accessorials", "pickupAccessorials", [])
        } else if (field === "destination") {
            this.setState({ showDestinationOverlay: false })
            changeField("accessorials", "deliveryAccessorials", [])
        }
        clearSubmitErrors()
    }

    handleAddressSelect = (field, item) => {
        const {
            formValues,
            change,
            clearSubmitErrors,
            changeField,
        } = this.props
        const billToLocation = this.props.locations.find(
            location => location.cpgCode === formValues.cpg
        )
        clearSubmitErrors()
        change(field, get(item, "address.postalCode"))
        change(`${field}Name`, get(item, "name"))
        change(`${field}CpgCode`, "")
        change(`${field}Contact`, get(item, "contact.id"))
        change(`${field}Address`, get(item, "id"))
        change(`${field}City`, get(item, "address.city"))
        change(`${field}Country`, get(item, "address.country"))
        change(`${field}State`, get(item, "address.state"))
        change(
            `${field}Street`,
            `${get(item, "address.street1")} ${get(
                item,
                "address.street2",
                ""
            )}`
        )
        change(`${field}ContactInfo`, item.contact ? item : "")
        change(`${field}AddressInfo`, item.contact ? "" : item)

        if (field === "origin") {
            this.setState(
                () => ({ showOriginOverlay: false }),
                () => {
                    if (
                        !formValues?.destinationState &&
                        billToLocation?.isShipperEnabled
                    ) {
                        change(
                            "destination",
                            billToLocation?.shippingAddress?.address
                                ?.postalCode ?? ""
                        )
                        change(
                            "destinationCity",
                            billToLocation?.shippingAddress?.address?.city ?? ""
                        )
                        change(
                            "destinationState",
                            billToLocation?.shippingAddress?.address?.state ??
                                ""
                        )
                        change(
                            "destinationStreet",
                            billToLocation?.shippingAddress?.address?.street1 ??
                                ""
                        )
                        change(
                            "destinationCountry",
                            billToLocation?.shippingAddress?.address?.country ??
                                ""
                        )
                        change(
                            "destinationName",
                            billToLocation?.shippingAddress?.name
                        )
                        change("destinationCpgCode", billToLocation?.cpgCode)
                        change(
                            "destinationAddress",
                            billToLocation?.shippingAddress?._id
                        )
                        change(
                            "destinationContactInfo",
                            billToLocation?.shippingAddress
                        )

                        change(
                            "isInBondShipment",
                            invalidInbondShipment(
                                null,
                                get(
                                    billToLocation,
                                    ["shippingAddress", "address", "country"],
                                    ""
                                )
                            )
                                ? false
                                : get(formValues, "isInBondShipment")
                        )
                    }
                }
            )
            if (item?.pickupAccessorials?.length) {
                changeField(
                    "accessorials",
                    "pickupAccessorials",
                    item.pickupAccessorials.map(entry => entry.value) ?? []
                )
            } else {
                changeField("accessorials", "pickupAccessorials", [])
            }
        } else if (field === "destination") {
            this.setState({ showDestinationOverlay: false })
            if (item?.deliveryAccessorials?.length) {
                changeField(
                    "accessorials",
                    "deliveryAccessorials",
                    item.deliveryAccessorials.map(entry => entry.value) ?? []
                )
            } else {
                changeField("accessorials", "deliveryAccessorials", [])
            }
        }
        clearSubmitErrors()
    }

    resetSelection = field => {
        const { change } = this.props

        change(`${field}Name`, null)
        change(`${field}City`, null)
        change(`${field}State`, null)
        change(`${field}Street`, null)
        change(`${field}Contact`, null)
        change(`${field}Address`, null)
        change(`${field}ContactInfo`, null)
        change(`${field}AddressInfo`, null)
    }

    resetLocationField = field => {
        const { change } = this.props

        change(field, null)
    }

    swapFields = () => {
        const { formValues, change } = this.props

        change("origin", get(formValues, "destination", ""))
        change("originCity", get(formValues, "destinationCity", ""))
        change("originState", get(formValues, "destinationState", ""))
        change("originCountry", get(formValues, "destinationCountry", ""))
        change("originStreet", get(formValues, "destinationStreet", ""))
        change("originName", get(formValues, "destinationName", ""))
        change("originCpgCode", get(formValues, "destinationCpgCode", ""))
        change("originContact", get(formValues, "destinationContact", ""))
        change(
            "originContactInfo",
            get(formValues, "destinationContactInfo", "")
        )
        change("originAddress", get(formValues, "destinationAddress", ""))
        change(
            "originAddressInfo",
            get(formValues, "destinationAddressInfo", "")
        )
        change("destination", get(formValues, "origin", ""))
        change("destinationCity", get(formValues, "originCity", ""))
        change("destinationState", get(formValues, "originState", ""))
        change("destinationCountry", get(formValues, "originCountry", ""))
        change("destinationStreet", get(formValues, "originStreet", ""))
        change("destinationName", get(formValues, "originName", ""))
        change("destinationCpgCode", get(formValues, "originCpgCode", ""))
        change("destinationContact", get(formValues, "originContact", ""))
        change(
            "destinationContactInfo",
            get(formValues, "originContactInfo", "")
        )
        change("destinationAddress", get(formValues, "originAddress", ""))
        change(
            "destinationAddressInfo",
            get(formValues, "originAddressInfo", "")
        )
    }

    onItemSearchChange = e => {
        this.setState(() => ({ itemSearch: e.target.value }))
    }

    clearAll = obj => {
        const { change, initialValues, untouched } = this.props

        for (let i in obj) {
            switch (i) {
                case "handlingUnits":
                    change(i, [newHandlingUnit])
                    break
                case "billToLocation":
                case "billToLocationAddress":
                case "cpg":
                case "deliveryAccessorials":
                case "pickupAccessorials":
                case "pickupDate":
                case "includeThirdParty":
                    change(i, initialValues[i])
                    untouched(i)
                    break
                default:
                    change(i, null)
                    untouched(i)
            }
        }
        for (let i in newHandlingUnit) {
            untouched(`handlingUnits[0].${i}`)
        }
    }

    submitForm = async values => {
        if (values.handlingUnits.length) {
            try {
                await this.props.onSearchClick(values)
                this.props.getQuotes()
            } catch (err) {
                if (err?.props?.id && err?.props?.defaultMessage) {
                    this.props.setSnackbar(
                        "error",
                        <FormattedMessage {...err?.props} />
                    )
                }
            } finally {
                this.toggleResidentialAddressStatus(false)
            }
        } else {
            this.setState({ noItemsHelper: true })
        }
    }

    toggleResidentialAddressStatus(_isResidentialAddress) {
        this.setState({ isResidentialAddress: _isResidentialAddress })
    }

    toggleVagueCommodityStatus = _isVagueCommodity => {
        this.setState({
            isVagueCommodity: _isVagueCommodity,
        })
    }

    checkVagueCommodities = async values => {
        this.props.trackGA2("Legacy Rates", "Get Rates Clicked")

        const vagueItemDescriptions = []
        if (values.originCountry !== values.destinationCountry) {
            const vagueCommodities = this.props.vagueCommodities
            values.handlingUnits.forEach((hu, huIndex) => {
                hu.items.forEach((item, itemIndex) => {
                    vagueCommodities.forEach(vagueCommodity => {
                        if (
                            vagueCommodity.name.toLowerCase() ===
                            item.description.trim().toLowerCase()
                        ) {
                            const validDescription = {
                                huIndex,
                                itemIndex,
                                oldDescription: item.description,
                                newDescription: item.description,
                                vagueDescription: vagueCommodity.description,
                                class: item.freightClass,
                            }
                            vagueItemDescriptions.push(validDescription)
                        }
                    })
                })
            })
        }
        if (vagueItemDescriptions.length > 0) {
            this.setState({ vagueItemDescriptions: vagueItemDescriptions })
            this.toggleVagueCommodityStatus(true)
            this.props.trackGA2("Legacy Rates", "Vague Commodity Dialog Opened")
        } else {
            await this.checkAddressValidation(values)
        }
    }

    checkAddressValidation = async values => {
        const { formValues, setSnackbar } = this.props

        if (values.handlingUnits.length) {
            const { deliveryAccessorials } = this.props.accessorialValues
            let needsResiModal = false

            if (
                !deliveryAccessorials.includes("RESDEL") &&
                formValues?.destinationContactInfo &&
                !formValues?.isFreightDirect
            ) {
                try {
                    const addr = get(
                        formValues,
                        "destinationContactInfo.address",
                        {}
                    )
                    needsResiModal = await resiAddressRequest(addr)
                } catch (err) {
                    //err for resi check
                }
            }

            if (needsResiModal) {
                this.toggleResidentialAddressStatus(true)
                this.props.trackGA2("Legacy Rates", "Resi Check Modal Opened")
            } else {
                try {
                    await this.submitForm({ ...values, resiAccessorials: [] })
                } catch (err) {
                    if (err?.props?.id && err?.props?.defaultMessage) {
                        setSnackbar(
                            "error",
                            <FormattedMessage {...err?.props} />
                        )
                    }
                }
            }
        } else {
            this.setState({ noItemsHelper: true })
        }
    }

    handleRateModeToggle = () => {
        this.props.changePath("/qrate")
    }

    setTopResult = (result, resultType, field, state, country, term) => {
        if (resultType === "city") {
            this.handleCitySelect(field, result, state, country, term)
        } else if (resultType === "address") {
            this.handleAddressSelect(field, result)
        }
    }

    getShipmentWeight = () => {
        const { formValues } = this.props

        const shipmentWeight = get(formValues, "handlingUnits", []).reduce(
            (acc, hu) => {
                if (hu.isMultiClass) {
                    const result = get(hu, "items", []).reduce((acc, item) => {
                        if (item.weight) {
                            return (acc += parseInt(get(item, "weight", 0), 10))
                        } else return acc
                    }, 0)

                    const finalResult = result * hu.count
                    return acc + finalResult
                } else {
                    const finalResult = parseInt(
                        get(hu, "items[0].weight", 0),
                        10
                    )
                    return acc + (finalResult ? finalResult : 0)
                }
            },
            0
        )

        return shipmentWeight
    }

    getTotalHUCount = () => {
        const { formValues } = this.props

        return get(formValues, "handlingUnits", []).reduce((acc, hu) => {
            if (parseInt(hu.count, 10)) {
                return acc + parseInt(get(hu, "count", 0), 10)
            } else return acc
        }, 0)
    }

    toggleProNumberSearchStatus(_isProNumberSearch) {
        this.setState({ isProNumberSearch: _isProNumberSearch })
    }

    setProNumberSearchResult(_shipmentsProNumberSearch) {
        this.setState({ shipmentsProNumberSearch: _shipmentsProNumberSearch })
    }

    handleCloseProSearchModal = () => {
        this.toggleProNumberSearchStatus(false)
    }

    searchProNumber = async () => {
        this.props.trackGA("FXFD-Returns", "Search Icon Click")
        const proNumber = this.props?.formValues?.proNumber
        if (!proNumber || proNumber.length < 3) return
        this.toggleProNumberSearchStatus(true)
        try {
            this.setProNumberSearchResult({ loading: true })
            const shipments = await searchShipmentByProNumber(proNumber)
            if (shipments.length > 0) {
                this.setProNumberSearchResult({
                    result: shipments[0],
                    loading: false,
                })
            } else {
                this.setProNumberSearchResult({ result: null, loading: false })
            }
        } catch (err) {
            this.setProNumberSearchResult({ error: err?.message })
        }
    }

    inbondCheck = country => {
        const { formValues, change } = this.props
        change(
            "isInBondShipment",
            invalidInbondShipment(country)
                ? false
                : get(formValues, "isInBondShipment")
        )
    }
    render() {
        const {
            classes,
            handleSubmit,
            performContactSearch,
            onPostalCodeChange,
            setHandlingUnitMode,
            searchInProgress,
            changeField,
            doSearch,
            resetSection,
            formValues,
            isUSDomestic,
            submitting,
            isOpen,
            handlePanel,
            addNewHandlingUnit,
            openAddItemDialog,
            handleAddItemDialogClose,
            error,
            formattedLocations,
            change,
            intl,
            deliveryAccessorials,
            resiChips,
            touch,
            vagueCommodities,
        } = this.props
        const {
            anchorEl,
            showOriginOverlay,
            showDestinationOverlay,
            showBillToOverlay,
            noItemsHelper,
            hideThirdPartyCheckbox,
            isResidentialAddress,
            isProNumberSearch,
            shipmentsProNumberSearch,
            isVagueCommodity,
            vagueItemDescriptions,
        } = this.state

        const inputProps = {
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                input: classes.input__field,
                focused: classes.input__field__focused,
            },
        }
        const disabledInputProps = {
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                input: classes.input__field__disabled,
                focused: classes.input__field__focused,
            },
        }
        const inputSelectAutocompleteProps = {
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                input: classes.input__field__select__autocomplete,
                focused: classes.input__field__focused,
            },
        }
        const multilineProps = {
            multiline: true,
            disableUnderline: true,
            classes: {
                root: classes.field__root,
                multiline: classes.multiline__field,
            },
        }
        const inputLabelProps = {
            shrink: true,
            FormLabelClasses: {
                root: classes.input__label,
                focused: classes.input__label,
            },
        }
        const formLabelProps = {
            classes: {
                root: classes.input__label,
                label: classes.input__label,
            },
        }
        const formLabelPropsSmall = {
            classes: {
                root: classes.input__label__small,
                label: classes.input__label__small,
            },
        }
        const inputHelperProps = {
            classes: {
                root: classes.input__helper,
                error: classes.input__helper,
            },
        }

        const selectClasses = {
            icon: classes.select__icon,
        }

        const shipmentWeight = this.getShipmentWeight()
        const totalHUCount = this.getTotalHUCount()

        const isFreightDirectReturns = formValues?.isFreightDirectReturns

        const canSearchPro =
            formValues?.proNumber && formValues?.proNumber?.length >= 3

        return (
            <Fragment>
                <form autoComplete="off" className={classes.main__container}>
                    <ShipmentSnackbar
                        variant="error"
                        content={!submitting && error}
                    />
                    <Grid
                        container
                        justify="center"
                        direction="row"
                        className={classes.quote__form__container}
                    >
                        <Grid xs={1} />
                        <Grid xs={10} item container direction="column">
                            <Field
                                id="billToLocation"
                                component={FormSelect}
                                name="billToLocation"
                                label={
                                    <FormattedMessage
                                        id="getRates.form__billToLocation"
                                        defaultMessage="Bill to Location"
                                    />
                                }
                                options={formattedLocations}
                                billTo
                                dataTestId="billToLocationFigurehead"
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                InputHelperProps={inputHelperProps}
                                selectClasses={selectClasses}
                                disabled
                                onClick={e =>
                                    this.handleInputFocus("billToLocation", e)
                                }
                                onBlur={e =>
                                    this.handleInputBlur("billToLocation", e)
                                }
                            />
                            <BillToLocationPicker
                                onBillToLocationChange={
                                    this.onBillToLocationChange
                                }
                                options={formattedLocations}
                                handleClose={e =>
                                    this.handleInputBlur("billToLocation", e)
                                }
                                anchorEl={anchorEl}
                                showBillToOverlay={showBillToOverlay}
                            />
                            {isFreightDirectReturns && (
                                <Grid item container direction="row">
                                    <Grid
                                        xs={10}
                                        item
                                        container
                                        direction="row"
                                    >
                                        <Field
                                            component={FormField}
                                            name="proNumber"
                                            label={
                                                <FormattedMessage
                                                    id="getRates.form_searchForReturn"
                                                    defaultMessage="Search for a Return (Optional)"
                                                />
                                            }
                                            id="getRates_proNumber"
                                            placeholder={intl.formatMessage({
                                                id: "getRates.form__proNumber",
                                                defaultMessage:
                                                    "Tracking Number",
                                            })}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                        />
                                    </Grid>
                                    <Grid
                                        xs={2}
                                        item
                                        container
                                        justify="center"
                                        alignItems="center"
                                    >
                                        <SearchIcon
                                            className={
                                                canSearchPro
                                                    ? classes.search__icon
                                                    : classes.search__icon__disabled
                                            }
                                            onClick={this.searchProNumber}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                            <Grid
                                item
                                container
                                className={classes.checkboxes__container}
                            >
                                <Grid item>
                                    {hideThirdPartyCheckbox ? (
                                        <Typography
                                            color="secondary"
                                            className={
                                                classes.third__party__label
                                            }
                                        >
                                            <FormattedMessage
                                                id="getRates.form__thirdPartyPricing"
                                                defaultMessage="Third Party Pricing"
                                            />
                                        </Typography>
                                    ) : (
                                        <Grid item>
                                            <Field
                                                component={FormCheckbox}
                                                name="includeThirdParty"
                                                label={
                                                    <FormattedMessage
                                                        id="getRates.form__includeThirdParty"
                                                        defaultMessage="Include Third Party Pricing"
                                                    />
                                                }
                                                FormLabelProps={formLabelProps}
                                                className={
                                                    classes.third__party__checkbox
                                                }
                                                dataTestId="thirdPartyPricing"
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                                {!invalidInbondShipment(
                                    formValues?.originCountry,
                                    formValues?.destinationCountry
                                ) && (
                                    <Grid
                                        item
                                        container
                                        xs={5}
                                        className={
                                            classes.inBond__checkbox__container
                                        }
                                    >
                                        <Grid item>
                                            <Field
                                                component={FormCheckbox}
                                                name="isInBondShipment"
                                                label={
                                                    <FormattedMessage
                                                        id="getRates.form__inBondShipment"
                                                        defaultMessage="In-Bond Shipment"
                                                    />
                                                }
                                                FormLabelProps={formLabelProps}
                                                className={
                                                    classes.inBond__checkbox
                                                }
                                                dataTestId="inBondShipment"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid
                                item
                                container
                                className={classes.location__container}
                                direction="column"
                            >
                                <Grid item container variant="subheading">
                                    <Typography
                                        className={
                                            classes.location__container__header
                                        }
                                    >
                                        *{" "}
                                        <FormattedMessage
                                            id="getRates.form__whereFrom"
                                            defaultMessage="Where From?"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Grid item container xs={3}>
                                        <Field
                                            component={
                                                FormSelectAutocompleteDark
                                            }
                                            name="originCountry"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                            options={supportedCountryCodes}
                                            InputProps={
                                                inputSelectAutocompleteProps
                                            }
                                            InputLabelProps={inputLabelProps}
                                            selectClasses={selectClasses}
                                            onChange={e => {
                                                this.inbondCheck(e)
                                            }}
                                            dataTestId="originCountry"
                                        />
                                    </Grid>
                                    <Grid item container xs={9}>
                                        <Field
                                            component={FormField}
                                            name="origin"
                                            dataTestId="originZipCode"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__zipPostalCode"
                                                    defaultMessage="Zip/Postal Code"
                                                />
                                            }
                                            id="getRates__origin"
                                            placeholder={intl.formatMessage({
                                                id:
                                                    "getRates.form__zipOrCompanyName",
                                                defaultMessage:
                                                    "Zip or Company Name",
                                            })}
                                            inputRef={el =>
                                                (this.originInput = el)
                                            }
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                            onChange={e => {
                                                this.setState({
                                                    origin: e.target.value,
                                                })
                                                performContactSearch(
                                                    e.target.value,
                                                    get(
                                                        formValues,
                                                        "originCountry"
                                                    )
                                                )
                                                onPostalCodeChange(
                                                    e.target.value,
                                                    "origin",
                                                    formValues?.originCountry,
                                                    true
                                                )
                                                this.resetSelection("origin")
                                            }}
                                            onFocus={e =>
                                                this.handleInputFocus(
                                                    "origin",
                                                    e
                                                )
                                            }
                                            onBlur={e =>
                                                this.handleInputBlur(
                                                    "origin",
                                                    e
                                                )
                                            }
                                        />
                                        <Popper
                                            onMouseDown={() =>
                                                this.handleContainerMouseDown()
                                            }
                                            open={showOriginOverlay}
                                            anchorEl={anchorEl}
                                            placement="right-start"
                                            disablePortal
                                            modifiers={{
                                                flip: {
                                                    enabled: true,
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    boundariesElement:
                                                        "scrollParent",
                                                },
                                            }}
                                            className={classes.popper}
                                        >
                                            <SuggestionBox
                                                searchInProgress={
                                                    searchInProgress
                                                }
                                                addressesNeeded
                                                setTopResult={this.setTopResult}
                                                field={"origin"}
                                                country={
                                                    formValues?.originCountry
                                                }
                                                term={constructPostalCode(
                                                    this.state.origin,
                                                    formValues?.originCountry
                                                )}
                                                handleAddressSelect={item =>
                                                    this.handleAddressSelect(
                                                        "origin",
                                                        item
                                                    )
                                                }
                                                handleCitySelect={(
                                                    city,
                                                    state,
                                                    country,
                                                    term
                                                ) =>
                                                    this.handleCitySelect(
                                                        "origin",
                                                        city,
                                                        state,
                                                        country,
                                                        term
                                                    )
                                                }
                                            />
                                        </Popper>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    {get(
                                        formValues,
                                        "originContactInfo.contact.name"
                                    ) && (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(
                                                formValues,
                                                "originContactInfo.contact.name"
                                            )}
                                        </Typography>
                                    )}
                                    {get(formValues, "originName") &&
                                    get(formValues, "originCity") &&
                                    get(formValues, "originState") ? (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(formValues, "originName")}
                                            {`${get(formValues, "originName") &&
                                                " - "}`}{" "}
                                            {get(formValues, "originCity")}
                                            {`${get(formValues, "originCity") &&
                                                ", "}`}{" "}
                                            {get(formValues, "originState")}
                                        </Typography>
                                    ) : (
                                        get(formValues, "originCity") &&
                                        get(formValues, "originState") && (
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.location__label
                                                }
                                            >
                                                {get(formValues, "originCity")}
                                                {`${get(
                                                    formValues,
                                                    "originCity"
                                                ) && ", "}`}{" "}
                                                {get(formValues, "originState")}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                container
                                className={classes.location__container}
                                direction="column"
                            >
                                <Grid item container variant="subheading">
                                    <Typography
                                        className={
                                            classes.location__container__header
                                        }
                                    >
                                        *{" "}
                                        <FormattedMessage
                                            id="getRates.form__whereTo"
                                            defaultMessage="Where To?"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid item container>
                                    <Grid item container xs={3}>
                                        <Field
                                            component={
                                                FormSelectAutocompleteDark
                                            }
                                            name="destinationCountry"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__country"
                                                    defaultMessage="Country"
                                                />
                                            }
                                            options={supportedCountryCodes}
                                            InputProps={
                                                inputSelectAutocompleteProps
                                            }
                                            InputLabelProps={inputLabelProps}
                                            selectClasses={selectClasses}
                                            onChange={e => {
                                                this.resetSelection(
                                                    "destination"
                                                )
                                                this.resetLocationField(
                                                    "destination"
                                                )
                                                this.inbondCheck(e)
                                                this.props.clearSubmitErrors()
                                            }}
                                            dataTestId="destinationCountry"
                                        />
                                    </Grid>
                                    <Grid item container xs={9}>
                                        <Field
                                            component={FormField}
                                            name="destination"
                                            label={
                                                <FormattedMessage
                                                    id="generalTerms__zipPostalCode"
                                                    defaultMessage="Zip/Postal Code"
                                                />
                                            }
                                            id="getRates__destination"
                                            placeholder={intl.formatMessage({
                                                id:
                                                    "getRates.form__zipOrCompanyName",
                                                defaultMessage:
                                                    "Zip or Company Name",
                                            })}
                                            inputRef={el =>
                                                (this.destinationInput = el)
                                            }
                                            dataTestId="destinationZipCode"
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            InputHelperProps={inputHelperProps}
                                            onChange={e => {
                                                this.setState({
                                                    destination: e.target.value,
                                                })
                                                performContactSearch(
                                                    e.target.value,
                                                    get(
                                                        formValues,
                                                        "destinationCountry"
                                                    )
                                                )
                                                onPostalCodeChange(
                                                    e.target.value,
                                                    "destination",
                                                    formValues?.destinationCountry
                                                )
                                                this.resetSelection(
                                                    "destination"
                                                )
                                            }}
                                            onFocus={e =>
                                                this.handleInputFocus(
                                                    "destination",
                                                    e
                                                )
                                            }
                                            onBlur={e =>
                                                this.handleInputBlur(
                                                    "destination",
                                                    e
                                                )
                                            }
                                        />
                                        <Popper
                                            onMouseDown={() =>
                                                this.handleContainerMouseDown()
                                            }
                                            open={showDestinationOverlay}
                                            anchorEl={anchorEl}
                                            placement="right-start"
                                            disablePortal
                                            modifiers={{
                                                flip: {
                                                    enabled: true,
                                                },
                                                preventOverflow: {
                                                    enabled: true,
                                                    boundariesElement:
                                                        "scrollParent",
                                                },
                                            }}
                                            className={classes.popper}
                                        >
                                            <SuggestionBox
                                                searchInProgress={
                                                    searchInProgress
                                                }
                                                addressesNeeded
                                                setTopResult={this.setTopResult}
                                                field={"destination"}
                                                country={
                                                    formValues?.destinationCountry
                                                }
                                                term={constructPostalCode(
                                                    this.state.destination,
                                                    formValues?.destinationCountry
                                                )}
                                                handleAddressSelect={item =>
                                                    this.handleAddressSelect(
                                                        "destination",
                                                        item
                                                    )
                                                }
                                                handleCitySelect={(
                                                    city,
                                                    state,
                                                    country,
                                                    term
                                                ) =>
                                                    this.handleCitySelect(
                                                        "destination",
                                                        city,
                                                        state,
                                                        country,
                                                        term
                                                    )
                                                }
                                            />
                                        </Popper>
                                    </Grid>
                                </Grid>
                                <Grid item container>
                                    {get(
                                        formValues,
                                        "destinationContactInfo.contact.name"
                                    ) && (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(
                                                formValues,
                                                "destinationContactInfo.contact.name"
                                            )}
                                        </Typography>
                                    )}
                                    {get(formValues, "destinationName") &&
                                    get(formValues, "destinationCity") &&
                                    get(formValues, "destinationState") ? (
                                        <Typography
                                            variant="caption"
                                            className={classes.location__label}
                                        >
                                            {get(formValues, "destinationName")}
                                            {`${get(
                                                formValues,
                                                "destinationName"
                                            ) && " - "}`}{" "}
                                            {get(formValues, "destinationCity")}
                                            {`${get(
                                                formValues,
                                                "destinationCity"
                                            ) && ", "}`}{" "}
                                            {get(
                                                formValues,
                                                "destinationState"
                                            )}
                                        </Typography>
                                    ) : (
                                        get(formValues, "destinationCity") &&
                                        get(formValues, "destinationState") && (
                                            <Typography
                                                variant="caption"
                                                className={
                                                    classes.location__label
                                                }
                                            >
                                                {get(
                                                    formValues,
                                                    "destinationCity"
                                                )}
                                                {`${get(
                                                    formValues,
                                                    "destinationCity"
                                                ) && ", "}`}{" "}
                                                {get(
                                                    formValues,
                                                    "destinationState"
                                                )}
                                            </Typography>
                                        )
                                    )}
                                </Grid>
                            </Grid>
                            <Field
                                component={FormDatePicker}
                                name="pickupDate"
                                label={[
                                    "* ",
                                    <FormattedMessage
                                        id="getRates.form__shipDate"
                                        defaultMessage="Ship Date"
                                    />,
                                ]}
                                id="getRates__pickupDate"
                                placeholder={intl.formatMessage({
                                    id: "getRates.form__date",
                                    defaultMessage: "Date",
                                })}
                                InputProps={inputProps}
                                InputLabelProps={inputLabelProps}
                                InputHelperProps={inputHelperProps}
                            />
                            {!isUSDomestic && (
                                <Grid
                                    item
                                    container
                                    direction="row"
                                    className={classes.volumeLTL__container}
                                >
                                    <Grid item xs={6}>
                                        <Field
                                            component={FormSelect}
                                            name="preferredSystemOfMeasurement"
                                            dataTestId="measurement"
                                            label={
                                                <FormattedMessage
                                                    id="getRates.form__measurement"
                                                    defaultMessage="Measurement"
                                                />
                                            }
                                            options={[
                                                {
                                                    value: "IMPERIAL",
                                                    label: "lbs/in",
                                                },
                                                {
                                                    value: "METRIC",
                                                    label: "kg/cm",
                                                },
                                            ]}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            selectClasses={selectClasses}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Field
                                            component={FormSelect}
                                            name="preferredCurrencyCode"
                                            dataTestId="currency"
                                            label={
                                                <FormattedMessage
                                                    id="getRates.form__currency"
                                                    defaultMessage="Currency"
                                                />
                                            }
                                            options={supportedCurrencies}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                            selectClasses={selectClasses}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid xs={1} item container>
                            {!isFreightDirectReturns && (
                                <SwapVertIcon
                                    onClick={() => this.swapFields()}
                                    className={classes.swap__icon}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        className={classes.quote__item__overflow}
                    >
                        <FieldArray
                            name="handlingUnits"
                            component={QuoteHandlingUnits}
                            handlePanel={handlePanel}
                            classes={classes}
                            origin={this.props.origin}
                            originAddress={this.props.originAddress}
                            intraMexicoShipment={
                                formValues?.destinationCountry === "MX" &&
                                formValues?.originCountry === "MX"
                            }
                            selectClasses={selectClasses}
                            changeField={changeField}
                            resetSection={resetSection}
                            isOpen={isOpen}
                            preferredSystemOfMeasurement={
                                formValues?.preferredSystemOfMeasurement
                            }
                            currency={formValues?.currency}
                            doSearch={e =>
                                doSearch(
                                    e,
                                    this.props.origin,
                                    this.props.originAddress
                                )
                            }
                            multilineProps={multilineProps}
                            formLabelProps={formLabelProps}
                            inputProps={inputProps}
                            disabledInputProps={disabledInputProps}
                            inputSelectAutocompleteProps={
                                inputSelectAutocompleteProps
                            }
                            inputHelperProps={inputHelperProps}
                            inputLabelProps={inputLabelProps}
                            setHandlingUnitMode={setHandlingUnitMode}
                            isFreightDirect={formValues?.isFreightDirect}
                            isFreightDirectReturns={
                                formValues?.isFreightDirectReturns
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        className={classes.add_item_row}
                    >
                        <Grid item container xs={12} justify="center">
                            <FieldArray
                                name="handlingUnits"
                                component={({ fields }) => (
                                    <Button
                                        id="getRates__addHu"
                                        className={classes.button__add_item}
                                        onClick={() => {
                                            this.setState(() => ({
                                                noItemsHelper: false,
                                            }))
                                            addNewHandlingUnit(fields)
                                        }}
                                    >
                                        +{" "}
                                        <FormattedMessage
                                            id="getRates.addHandlingUnit"
                                            defaultMessage="Add Handling Unit"
                                        />
                                    </Button>
                                )}
                            />
                        </Grid>
                        <Grid item container direction="row">
                            <Grid item container xs={8}>
                                {!formValues?.isFreightDirect &&
                                    !isFreightDirectReturns && (
                                        <Field
                                            component={FormSwitch}
                                            name="volumeLTL"
                                            label={
                                                <FormattedMessage
                                                    id="getRates.volumeLTLOnly"
                                                    defaultMessage="Volume LTL Only"
                                                />
                                            }
                                            FormLabelProps={formLabelPropsSmall}
                                            dataTestId="volumeLtl"
                                        />
                                    )}
                            </Grid>
                            <Grid item container xs={4}>
                                {!formValues?.isFreightDirect &&
                                    !isFreightDirectReturns && (
                                        <Field
                                            component={FormField}
                                            name="totalLinearFeet"
                                            id="getRates__totalLinearFeet"
                                            label={[
                                                "* ",
                                                <FormattedMessage
                                                    id="getRates.estLinearFeet"
                                                    defaultMessage="Est. Linear Feet"
                                                />,
                                            ]}
                                            onChange={(e, newValue) => {
                                                change(
                                                    "userProvidedTotalLinearFeet",
                                                    true
                                                )
                                                change(
                                                    "isOverLTLLimit",
                                                    newValue >= 15
                                                )
                                            }}
                                            InputProps={inputProps}
                                            InputLabelProps={inputLabelProps}
                                        />
                                    )}
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Typography
                                align="center"
                                className={classes.total__weight}
                            >
                                <FormattedMessage
                                    id="getRates.totalHUCount"
                                    defaultMessage="TOTAL H/U COUNT: {totalHUCount}"
                                    values={{
                                        totalHUCount,
                                    }}
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            justify="center"
                            alignItems="center"
                        >
                            <Typography
                                align="center"
                                className={classes.total__weight}
                            >
                                <FormattedMessage
                                    id="getRates.shipmentWeight"
                                    defaultMessage="SHIPMENT WEIGHT: {shipmentWeight} {weightUnit}"
                                    values={{
                                        shipmentWeight,
                                        weightUnit: weightUnit(
                                            formValues?.preferredSystemOfMeasurement
                                        ),
                                    }}
                                />
                            </Typography>
                        </Grid>
                        {/* <Grid item container xs={8} justify="center" alignItems="center">
                            <Button
                                variant="outlined" color="primary" className={classes.button__clear}
                                onClick={() => {
                                    this.clearAll(initialValues)
                                    this.props.clearSubmitErrors()
                                }}
                            >
                                <FormattedMessage id="getRates.form__clear" defaultMessage="CLEAR"/>
                            </Button>
                        </Grid> */}
                    </Grid>
                    <Grid item container>
                        <Grid item container xs={12}>
                            <Grid item xs={4} container></Grid>
                            <Dialog
                                open={openAddItemDialog}
                                onClose={() => handleAddItemDialogClose()}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    {
                                        <FormattedMessage
                                            id="getRates__completeHandlingUnits"
                                            defaultMessage="Please complete all current handling units before moving on to a new one."
                                        />
                                    }
                                </DialogTitle>
                                <DialogActions>
                                    <Button
                                        onClick={() =>
                                            handleAddItemDialogClose()
                                        }
                                        color="primary"
                                        autoFocus
                                    >
                                        <FormattedMessage
                                            id="getRates.form__ok"
                                            defaultMessage="OK"
                                        />
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            <Grid
                                item
                                container
                                xs={4}
                                alignItems="center"
                                justify="center"
                            />
                            <Grid item container xs={4} justify="flex-end">
                                <div className={classes.submit}>
                                    <Button
                                        onClick={handleSubmit(
                                            this.checkVagueCommodities
                                        )}
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button__get_quotes}
                                        disabled={submitting}
                                        id="getRates__submit"
                                    >
                                        <FormattedMessage
                                            id="getRates__getRates"
                                            defaultMessage="GET RATES"
                                        />
                                    </Button>
                                    {submitting && (
                                        <CircularProgress
                                            className={classes.submitLoader}
                                            size={24}
                                            color="secondary"
                                        />
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            justify="space-between"
                            alignItems="center"
                        >
                            {noItemsHelper && (
                                <Typography color="secondary">
                                    <FormattedMessage
                                        id="getRates.form__itemError2"
                                        defaultMessage="Please add at least one item"
                                    />
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </form>
                <VagueCommodityDialog
                    isVagueCommodity={isVagueCommodity}
                    originalFormValues={formValues}
                    checkAddressValidation={this.checkAddressValidation}
                    vagueItemDescriptions={vagueItemDescriptions}
                    vagueCommodities={vagueCommodities}
                    currentForm={"quote"}
                    toggleVagueCommodityStatus={this.toggleVagueCommodityStatus}
                />
                <ResidentialAddressDialog
                    isResiDialogOpen={isResidentialAddress}
                    getRateSubmit={this.submitForm}
                    onIgnore={this.submitForm}
                    deliveryAccessorials={deliveryAccessorials}
                    currentForm={"quote"}
                    resiChips={resiChips}
                    resiChipsPrefix={"selectedResiAccessorials"}
                />
                <ProNumberResult
                    isProNumberSearch={isProNumberSearch}
                    handleCloseProSearchModal={this.handleCloseProSearchModal}
                    shipmentsProNumberSearch={shipmentsProNumberSearch}
                    proNumber={formValues?.proNumber}
                    changeField={changeField}
                    touchForm={touch}
                />
            </Fragment>
        )
    }
}

QuotesDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    defaultLocation: PropTypes.object.isRequired,
}

const constructLabel = (cpgName, defaultNickname) => cpgName || defaultNickname

const constructSubLabel = (
    fedexFreightAccount,
    fedexBillToAccount,
    intl,
    isFreightDirect
) => {
    if (fedexFreightAccount && !fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount}`
        }
    } else if (fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__shipperAcct",
                defaultMessage: "Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDShipperAcct",
                defaultMessage: "FXFD Shipper Acct",
            })}: ${fedexFreightAccount} | ${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    } else if (!fedexFreightAccount && fedexBillToAccount) {
        if (!isFreightDirect) {
            return `${intl.formatMessage({
                id: "getRates.form__thirdPartyAcct",
                defaultMessage: "Third Party Acct",
            })}: ${fedexBillToAccount}`
        } else {
            return `${intl.formatMessage({
                id: "getRates.form__FXFDThirdPartyAcct",
                defaultMessage: "FXFD Third Party Acct",
            })}: ${fedexBillToAccount}`
        }
    }
}

const mapStateToProps = (state, props) => {
    const requestsInProgress = Object.keys(state.requests)
    const postalCodeLookupInProgress = requestsInProgress.some(key =>
        key.startsWith("lookupPostalCode")
    )
    const contactSearchInProgress = state.contact.searchInProgress
    const locations = get(state, "user.profile.locations", [])

    const formattedLocations = locations
        .filter(
            item =>
                get(item, "users[0].permissions.createRates.value") &&
                !get(item, "users[0].permissions.suspended.value") &&
                item.locationType !== "FEDEX_FREIGHT_BOX"
        )
        .map(item => {
            const { city, state: addressState } = get(
                item,
                "shippingAddress.address",
                {}
            )
            const defaultNickname = `${get(
                item,
                "shippingAddress.name"
            )} - ${city}, ${addressState}`
            return {
                label: constructLabel(item.cpgName, defaultNickname),
                subLabel: constructSubLabel(
                    item.fedexFreightAccount,
                    item.fedexBillToAccount,
                    props.intl,
                    item.locationType === "FEDEX_DIRECT" ||
                        item.locationType === "FEDEX_DIRECT_RETURNS"
                ),
                value: item.cpgCode,
                fedexBillToAccount: item.fedexBillToAccount,
                fedexFreightAccount: item.fedexFreightAccount,
                isDefault: item.isDefault,
                isFreightDirect: item.locationType === "FEDEX_DIRECT",
                isFreightDirectReturns:
                    item.locationType === "FEDEX_DIRECT_RETURNS",
            }
        })

    const formValues = getFormValues("quote")(state)
    const isUSDomestic = isUSDomesticShipment(
        get(formValues, "originCountry"),
        get(formValues, "destinationCountry")
    )

    const deliveryAccessorials =
        state?.form?.accessorials?.values?.deliveryAccessorials

    return {
        initialValues: formSelector(state, props),
        searchInProgress: postalCodeLookupInProgress || contactSearchInProgress,
        locations,
        formattedLocations,
        formValues,
        isUSDomestic,
        accessorialValues: get(state, "form.accessorials.values"),
        deliveryAccessorials,
        resiChips: state?.form?.quote?.values?.selectedResiAccessorials ?? "",
        vagueCommodities: get(state, "vagueCommodities.list"),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    resetForm: () => dispatch(reset("quote")),
    changeField: (quote, field, value) => dispatch(change(quote, field, value)),
    touch: fields => dispatch(touch("quote", fields)),

    onSearchClick: async values =>
        dispatch(
            requestQuote({
                ...values,
                isQuickQuote: false,
                isReturnMode: props.isReturnMode,
            })
        ),
    performContactSearch: (value, country) =>
        dispatch(searchContacts(value, "quote", undefined, country)),
    onPostalCodeChange: (value, field, country, fetchTimezone) =>
        dispatch(requestPostalCode(value, { field, country, fetchTimezone })),
    doSearch: (e, origin, originAddress) =>
        dispatch(searchItems(e.target.value, origin, originAddress)),
    clearSubmitErrors: () => dispatch(clearSubmitErrors("quote")),
    changePath: path => dispatch(changePath(path)),
    untouched: field => dispatch(untouch("quote", field)),
    trackGA: (category, action) => dispatch(trackGAEventNew(category, action)),
    trackGA2: (category, action, label) =>
        dispatch(trackGAEventRR(category, action, label)),
})

const pkgGrpValues = hazMatPkgGrp.map(item => item.value)
const hazClassValues = hazMatClasses.map(item => item.value)
const hazContainersValues = hazMatContainers.map(item => item.value)
const packageTypeValues = packageTypes.map(item => item.value)
const freightClassValues = freightClasses.map(item => item.value)

const widthValidator = () => (field, fieldValue, formValues) =>
    formValues?.preferredSystemOfMeasurement === "METRIC"
        ? isSmallerThan(fieldValue, 238, field)
        : isSmallerThan(fieldValue, 94, field)

const heightValidator = () => (field, fieldValue, formValues) =>
    formValues?.preferredSystemOfMeasurement === "METRIC"
        ? isSmallerThan(fieldValue, 271, field)
        : isSmallerThan(fieldValue, 107, field)

const isSmallerThan = (value, max, field) => {
    if (value !== null && Number(value) <= max) {
        return false
    } else {
        return (
            <FormattedMessage
                id="generalTerms.validation__isSmallerThan"
                defaultMessage="{field} must be smaller than {max}"
                values={{ field: field?.field?.defaultMessage, max: max }}
            />
        )
    }
}

const isRequiredIfNotIntraMexico = isRequiredIf(
    values =>
        !(
            get(values, "destinationCountry") === "MX" &&
            get(values, "originCountry") === "MX"
        )
)

const handlingUnitValidation = {
    "handlingUnits[].items": hasLengthGreaterThan(0)({
        field: { id: "items__item", defaultMessage: "Item" },
    }),
    "handlingUnits[].items[].weight": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].count": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__huCount", defaultMessage: "H/U Count" } }),
    "handlingUnits[].items[].pieces": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__pieces", defaultMessage: "Pieces" } }),
    "handlingUnits[].items[].freightDirectPieces[].count": composeValidators(
        isRequiredIf(values => {
            return values?.isFreightDirect || values.isFreightDirectReturns
        }),
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__count", defaultMessage: "Count" } }),
    "handlingUnits[].items[].freightDirectPieces[].weight": composeValidators(
        isGreaterThan(0),
        isRequiredIf(values => {
            return values?.isFreightDirect || values.isFreightDirectReturns
        }),
        isNumeric
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].packageType": composeValidators(
        isRequired,
        isOneOfSilent(packageTypeValues)
    )({
        field: {
            id: "items__packageType",
            defaultMessage: "Package Type",
        },
    }),
    "handlingUnits[].items[].description": composeValidators(
        isRequired,
        hasLengthLessThan(201)
    )({
        field: {
            id: "items__description",
            defaultMessage: "Description",
        },
    }),
    "handlingUnits[].items[].freightClass": composeValidators(
        isRequiredIfNotIntraMexico,
        isOneOfSilent(freightClassValues)
    )({ field: { id: "items__class", defaultMessage: "Class" } }),
    "handlingUnits[].width": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        widthValidator()
    )({ field: { id: "items__width", defaultMessage: "Width" } }),
    "handlingUnits[].height": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        heightValidator()
    )({ field: { id: "items__height", defaultMessage: "Height" } }),
    "handlingUnits[].length": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__length", defaultMessage: "Length" } }),
    "handlingUnits[].items[].nmfcCode": nmfcValidator,
    "handlingUnits[].items[].nmfcSubclass": nmfcSubcodeValidator,
    "handlingUnits[].items[].hazMatUnNumber": composeValidators(
        hasHazMatIdPrefix(),
        hasLength(6)
    )({
        field: {
            id: "items__unNumber__error",
            defaultMessage:
                "Identification Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code.",
        },
    }),
    "handlingUnits[].items[].hazMatPkgGrp": composeValidators(
        isOneOfSilent(pkgGrpValues)
    )({ field: { id: "items__pkgGrp", defaultMessage: "Pkg Grp" } }),
    "handlingUnits[].items[].hazMatClass": composeValidators(
        isOneOfSilent(hazClassValues)
    )({ field: { id: "items__hazClass", defaultMessage: "Haz Class" } }),
    "handlingUnits[].items[].hazMatContainer": composeValidators(
        isOneOfSilent(hazContainersValues)
    )({
        field: { id: "items__containerType", defaultMessage: "Container Type" },
    }),
}

const isValidPostalCode = countryField => (field, fieldValue, formValues) => {
    const country = get(formValues, countryField)
    if (isValidCountryPostalCode(fieldValue, country)) {
        return false
    } else {
        return (
            <FormattedMessage
                id="getRates.form__invalidPostalCode"
                defaultMessage="Invalid {field} postal code"
                values={{ field: country }}
            />
        )
    }
}

const isValidStop = prefix => (field, fieldValue, formValues) => {
    const city = get(formValues, `${prefix}City`)
    const state = get(formValues, `${prefix}State`)
    const country = get(formValues, `${prefix}Country`)
    if (city && state && country) {
        return false
    } else {
        return (
            <FormattedMessage
                id="getRates.form__invalidLocation"
                defaultMessage="Please select an address or postal code"
            />
        )
    }
}

const formValidation = (values, props) => {
    const mainValidator = combineValidators({
        pickupDate: isRequired({
            field: {
                id: "generalTerms__shipDate",
                defaultMessage: "Ship Date",
            },
        }),
        destination: composeValidators(
            isRequired,
            isValidPostalCode("destinationCountry"),
            isValidStop("destination")
        )({
            field: {
                id: "generalTerms__destination",
                defaultMessage: "Destination",
            },
        }),
        origin: composeValidators(
            isRequired,
            isValidPostalCode("originCountry"),
            isValidStop("origin")
        )({
            field: { id: "generalTerms__origin", defaultMessage: "Origin" },
        }),
        totalLinearFeet: composeValidators(
            isRequired,
            isGreaterThan(0)
        )({
            field: {
                id: "getRates.form__estLinearFeet",
                defaultMessage: "Est. Linear Feet",
            },
        }),
        totalWeight: totalWeightValidator({
            field: {
                id: "getRates.form__shipmentWeight",
                defaultMessage: "Shipment Weight",
            },
        }),
        ...handlingUnitValidation,
    })(values, props)
    return merge({}, mainValidator, nmfcFormValidator(values))
}

export {
    isValidPostalCode,
    isValidStop,
    packageTypeValues,
    freightClassValues,
    pkgGrpValues,
    hazClassValues,
    widthValidator,
    heightValidator,
    hazContainersValues,
}

export default withStyles(styles)(
    injectIntl(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                form: "quote", // a unique identifier for this form
                validate: formValidation,
                asyncValidate: pastDateValidator,
                asyncChangeFields: ["origin", "pickupDate"],
                onSubmitFail: (x, y, z, props) => props.openErrorSections(),
                enableReinitialize: true,
            })(
                formValues({
                    origin: "origin",
                    originAddress: "originAddress",
                })(QuotesDrawer)
            )
        )
    )
)
