import React, { useState } from "react"
import {
    Checkbox,
    Collapse,
    Grid,
    Button,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import moment from "moment"
import { FormattedMessage } from "react-intl"
import RateBreakdown from "../../../../dashboard/components/rateBreakdown"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { currencyLabel } from "../../../../util/currencyLabel"

const styles = theme => ({
    iconButton: {
        paddingLeft: "10px",
    },
    rateBreakdown: {
        paddingTop: "10px",
    },
    FXFDRateGroup: {
        padding: "18px 0",
        borderTop: "dotted 1px #969696",
    },
    FXFDRateGroup__first: {
        padding: "18px 0",
    },
    FXFDRateRow__details: {
        minHeight: "48px",
    },
    isShippedRate: {
        backgroundColor: theme.palette.secondary.light,
        padding: "10px 0",
    },
    exception__container: {
        padding: "15px",
        minHeight: "92px",
    },
})

const FXFDReturnsRateRow = ({
    rate,
    selections,
    isSharing,
    setIsSelected,
    rateIndex,
    canViewRateAmounts,
    selectRate,
    serviceLevelIndex,
    expired,
    isShippedRate,
    shipped,
    classes,
}) => {
    const {
        transitDays,
        deliveryDateTime,
        _id,
        preferredCurrencyCode,
        capacityProviderQuoteNumber,
        accountType,
        errorMessages,
    } = rate

    const [isExpanded, setIsExpanded] = useState(false)

    const isSelected = selections.has(_id)

    const deliveryDate = Number.isInteger(transitDays) && deliveryDateTime

    const isException =
        errorMessages?.length &&
        !rate?.rateQuoteDetail?.totalInPreferredCurrency &&
        !rate?.rateQuoteDetail?.total

    return (
        <Grid
            item
            container
            className={`${
                rateIndex === 0
                    ? classes.FXFDRateGroup__first
                    : classes.FXFDRateGroup
            } ${isShippedRate ? classes.isShippedRate : null}`}
            alignItems="center"
        >
            <Grid item container xs={1}>
                {isSharing && (
                    <Checkbox
                        id={`checkbox-rate-${serviceLevelIndex}-${rateIndex}`}
                        onChange={(e, value) => {
                            setIsSelected(_id, value)
                        }}
                        checked={isSelected}
                    />
                )}
            </Grid>
            <Grid
                item
                container
                xs={5}
                direction="column"
                justify="center"
                className={classes.FXFDRateRow__details}
            >
                <Typography>
                    {rate.carrierCode === "FXFE" ? (
                        <FormattedMessage
                            id="generalTerms__FedExPriority"
                            defaultMessage="FedEx Priority"
                        />
                    ) : rate.carrierCode === "FXNL" ? (
                        <FormattedMessage
                            id="generalTerms__FedExEconomy"
                            defaultMessage="FedEx Economy"
                        />
                    ) : (
                        rate.carrierCode
                    )}
                </Typography>
                <Typography>
                    {!!deliveryDate &&
                        moment(deliveryDate).format("dddd, MMM Do")}
                </Typography>
            </Grid>
            <Grid item container xs={6} justify="flex-end" alignItems="center">
                <Grid item>
                    {isException ? (
                        <Grid
                            item
                            container
                            justify="center"
                            className={classes.exception__container}
                        >
                            {errorMessages.map(error => (
                                <Grid
                                    item
                                    container
                                    justify="center"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant="caption"
                                        color="secondary"
                                    >
                                        {error?.message}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    ) : expired || shipped ? (
                        <Typography variant="subheading">
                            {canViewRateAmounts && accountType !== "VENDOR" ? (
                                currencyLabel(
                                    rate?.preferredCurrencyCode,
                                    rate?.rateQuoteDetail
                                        ?.totalInPreferredCurrency
                                        ? rate?.rateQuoteDetail
                                              ?.totalInPreferredCurrency
                                        : rate?.rateQuoteDetail?.total
                                )
                            ) : (
                                <FormattedMessage
                                    id="rateResults__bookNow"
                                    defaultMessage="Book Now"
                                />
                            )}
                        </Typography>
                    ) : (
                        <Button
                            id={`select-rate-button-${serviceLevelIndex}-${rateIndex}`}
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                selectRate(rate)
                            }}
                            disabled={expired || shipped}
                        >
                            {canViewRateAmounts && accountType !== "VENDOR" ? (
                                currencyLabel(
                                    rate?.preferredCurrencyCode,
                                    rate?.rateQuoteDetail
                                        ?.totalInPreferredCurrency
                                        ? rate?.rateQuoteDetail
                                              ?.totalInPreferredCurrency
                                        : rate?.rateQuoteDetail?.total
                                )
                            ) : (
                                <FormattedMessage
                                    id="rateResults__bookNow"
                                    defaultMessage="Book Now"
                                />
                            )}
                        </Button>
                    )}
                </Grid>
                <Grid item className={classes.iconButton}>
                    {canViewRateAmounts &&
                        !accountType !== "VENDOR" &&
                        !isException && (
                            <IconButton
                                onClick={() => {
                                    setIsExpanded(!isExpanded)
                                }}
                            >
                                {isExpanded ? (
                                    <ExpandLessIcon
                                        id={`select-rate-expandLessIcon-${serviceLevelIndex}-${rateIndex}`}
                                    />
                                ) : (
                                    <ExpandMoreIcon
                                        id={`select-rate-expandMoreIcon-${serviceLevelIndex}-${rateIndex}`}
                                    />
                                )}
                            </IconButton>
                        )}
                </Grid>
            </Grid>

            <Grid
                item
                container
                justify="flex-end"
                className={isExpanded ? classes.rateBreakdown : ""}
            >
                <Collapse in={isExpanded}>
                    <RateBreakdown
                        listView={false}
                        charges={rate?.rateQuoteDetail?.charges}
                        usePreferredCurrency
                        totalInPreferredCurrency={
                            rate?.rateQuoteDetail?.totalInPreferredCurrency
                                ? rate?.rateQuoteDetail
                                      ?.totalInPreferredCurrency
                                : rate?.rateQuoteDetail?.total
                        }
                        preferredCurrencyCode={preferredCurrencyCode}
                        gridSize={12}
                        quoteID={capacityProviderQuoteNumber}
                        isBookShipment
                    />
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(FXFDReturnsRateRow)
