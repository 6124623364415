import { combineValidators, composeValidators } from "revalidate"
import { get } from "lodash"
import {
    countryPhoneValidator,
    emailValidator,
    isRequired,
    isRequiredIf,
    isNumeric,
} from "../../actions/validation"

const isRequiredIfNotSame = isRequiredIf(
    values => values && !values.sameAsShippingAddress
)
const isRequiredIfStreet1Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street1
)
const isRequiredIfStreet2Missing = isRequiredIf(
    values => !values?.shippingAddress?.address?.street2
)
const isRequiredIfStreet1MissingAndIfNotSame = isRequiredIf(
    values => !values.sameAsShippingAddress && !values?.address?.street1
)
const isRequiredIfStreet2MissingAndIfNotSame = isRequiredIf(
    values => !values.sameAsShippingAddress && !values?.address?.street2
)

export const formValidation = combineValidators({
    fedexFreightAccount: composeValidators(
        isNumeric,
        isRequiredIf(values => !get(values, "fedexBillToAccount"))
    )({
        field: {
            id: "locations__shipperAccountNumber",
            defaultMessage: "Shipper Account #",
        },
    }),
    fedexBillToAccount: composeValidators(
        isNumeric,
        isRequiredIf(values => !get(values, "fedexFreightAccount"))
    )({
        field: {
            id: "locations__thirdPartyAccountNumber",
            defaultMessage: "Third Party Account #",
        },
    }),

    locationType: isRequired({
        field: {
            id: "locations__locationType",
            defaultMessage: "FedEx Account Type",
        },
    }),

    shippingAddress: {
        name: isRequired({
            field: {
                id: "generalTerms__companyName",
                defaultMessage: "Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            street2: isRequiredIfStreet1Missing({
                field: {
                    id: "generalTerms__address1OrAddress2",
                    defaultMessage: "Address 1 or Address 2",
                },
            }),
            city: isRequired({
                field: {
                    id: "generalTerms__city",
                    defaultMessage: "City",
                },
            }),
            state: isRequired({
                field: {
                    id: "generalTerms__state",
                    defaultMessage: "State",
                },
            }),
            country: isRequired({
                field: {
                    id: "generalTerms__country",
                    defaultMessage: "Country",
                },
            }),
            postalCode: isRequired({
                field: {
                    id: "generalTerms__zipPostalCode",
                    defaultMessage: "Zip/Postal Code",
                },
            }),
        },
    },
    address: {
        street1: isRequiredIfStreet2MissingAndIfNotSame({
            field: {
                id: "generalTerms__address1OrAddress2",
                defaultMessage: "Address 1 or Address 2",
            },
        }),
        street2: isRequiredIfStreet1MissingAndIfNotSame({
            field: {
                id: "generalTerms__address1OrAddress2",
                defaultMessage: "Address 1 or Address 2",
            },
        }),
        city: isRequiredIfNotSame({
            field: {
                id: "generalTerms__city",
                defaultMessage: "City",
            },
        }),
        state: isRequiredIfNotSame({
            field: {
                id: "generalTerms__state",
                defaultMessage: "State",
            },
        }),
        country: isRequiredIfNotSame({
            field: {
                id: "generalTerms__country",
                defaultMessage: "Country",
            },
        }),
        postalCode: isRequiredIfNotSame({
            field: {
                id: "generalTerms__zipPostalCode",
                defaultMessage: "Zip/Postal Code",
            },
        }),
    },
    billingContact: {
        companyName: isRequiredIfNotSame({
            field: {
                id: "generalTerms__companyName",
                defaultMessage: "Company Name",
            },
        }),
    },
    readyTime: isRequired({
        field: {
            id: "generalTerms.pickUpInformation__readyTime",
            defaultMessage: "Ready Time",
        },
    }),
    closeTime: isRequired({
        field: {
            id: "generalTerms.pickUpInformation__closeTime",
            defaultMessage: "Close Time",
        },
    }),
    pickupContact: {
        name: isRequired({
            field: {
                id: "generalTerms__name",
                defaultMessage: "Name",
            },
        }),
        phone: {
            phone_number: composeValidators(
                isRequired,
                countryPhoneValidator("shippingAddress.address.country")
            )({
                field: {
                    id: "generalTerms__phone",
                    defaultMessage: "Phone",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
        email: {
            email_address: composeValidators(
                isRequired,
                emailValidator
            )({
                field: {
                    id: "generalTerms__email",
                    defaultMessage: "Email",
                },
            }),
        },
    },
})
